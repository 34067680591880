import { Col, DatePicker, Form, Input, message, Modal, ModalProps, Row, Select } from "antd";
import { Console } from "console";
import { useEffect, useState } from "react";
import { AssignRoleToUserApi, GetRolesApi } from "../../Api/RoleApis";
import { ChangePasswordStaffsApi, CreateStaffsApi } from "../../Api/StaffApi";
import { RoleResponse } from "../../Model/RoleModel";
import { StaffResponse } from "../../Model/StaffModel";
import { NewStaffViewModel } from "../../ViewModel/StaffViewModel";
import CCombobox from "../Controls/CCombobox";


const { TextArea } = Input;
const { Option } = Select;

type StaffModalProps = ModalProps &
{
    staffEdit?: StaffResponse;
    okModalFunc: Function,
}

export function StaffModal(props: StaffModalProps) {

    const { staffEdit, okModalFunc, ...rest } = props;
    const [form] = Form.useForm<NewStaffViewModel>();

    const [roles, setRoles] = useState<RoleResponse[]>([]);

    useEffect(() => {
        if (rest.visible) {
            GetRolesApi((res: IListItems<RoleResponse>) => {
                setRoles(res.items);
            });

            //trong trường hợp edit

            if (staffEdit) {
                form.setFieldsValue({
                    name: staffEdit.name,
                    roleId: staffEdit.roleGroup?.id,
                    address: staffEdit.address,
                    phoneNumber: staffEdit.phoneNumber,
                    email: staffEdit.email,
                    identityNumber: staffEdit.identityNumber,
                    //birthday: staffEdit.dateOfBirth ? staffEdit.dateOfBirth : undefined

                })
            }
        }

    }, [rest.visible])

    const applyGroupRole = () => {
        form.validateFields()
            .then((data: NewStaffViewModel) => {

                if (staffEdit) {

                    //Chuyển chức danh
                    AssignRoleToUserApi({
                        userId: staffEdit.id,
                        roleGroupId: data.roleId
                    }, (res: any) => {
                        okModalFunc(res);
                        message.info("Một vai trò đã được chỉnh sửa");
                    })

                    //Cập nhật thông tin

                } else {
                    CreateStaffsApi({
                        email: data.email,
                        address: data.address,
                        phoneNumber: data.phoneNumber,
                        dateOfBirth: data.birthday,
                        sex: true,
                        name: data.name,
                        roleGroupId: data.roleId,
                        joinTime: new Date(),
                        organizationId: "",
                        identityNumber: data.identityNumber,
                        policy: 0

                    }, (res: any) => {
                        okModalFunc(res);
                        message.info("Một nhân viên mới được tạo");
                    });
                }

            })
    }


    return (
        <Modal {...rest}
            centered
            onOk={applyGroupRole}>

            <Form form={form}
                layout="vertical">
                <Form.Item label="Vai trò" key="role" name="roleId" required>
                    <CCombobox placeholder="Chọn vai trò" chooseOptions={roles} id="id" displayName="name" />
                </Form.Item>

                <Row>
                    <Col span={12} style={{ paddingRight: 10 }}>
                        <Form.Item label="Tên nhân viên" name="name" required>
                            <Input placeholder="Nhập tên nhân viên" />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label="CMTND/CCCD" name="identityNumber" required>
                            <Input placeholder="Nhập CMTND/CCCD" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={12} style={{ paddingRight: 10 }}>
                        <Form.Item label="Số điện thoại" name="phoneNumber" required>
                            <Input placeholder="Nhập SĐT nhân viên" />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label="Email" name="email" required>
                            <Input placeholder="Nhập email nhân viên" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={12} style={{ paddingRight: 10 }}>
                        <Form.Item label="Giới tính" name="sex">
                            <Select defaultValue="1">
                                <Option value="1" key="male">Nam</Option>
                                <Option value="2" key="female">Nữ</Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label="Ngày sinh" name="birthday">
                            <DatePicker format='DD/MM/YYYY' placeholder="Chọn ngày" />
                        </Form.Item>
                    </Col>
                </Row>


                <Form.Item label="Địa chỉ" name="address">
                    <Input placeholder="Nhập địa chỉ nhân viên" />
                </Form.Item>

                <Form.Item label="Ghi chú" name="note">
                    <TextArea rows={2} placeholder="Ghi chú" />
                </Form.Item>





            </Form>


        </Modal>
    )
}