import {
    DeleteOutlined, DownOutlined, LeftOutlined, PlusOutlined, CloseCircleOutlined
} from '@ant-design/icons';
import { Affix, Button, Card, Col, Divider, Dropdown, Form, Input, InputNumber, Menu, message, Popover, Row, Select, Space, Steps, Table, Typography } from 'antd';
import { valueType } from 'antd/lib/statistic/utils';
import { ColumnsType } from 'antd/lib/table';
import Link from 'antd/lib/typography/Link';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { GetCities, GetDistricts } from '../../Api/AddressApi';
import { GetAgenciesApi } from '../../Api/AgencyApi';
import { GetCustomerByIdApi, GetCustomersApi } from '../../Api/CustomerApi';
import { CreateOrderFromStaffApi } from '../../Api/OrderApi';
import { GetProductById, GetProducts } from '../../Api/ProductApi';
import { GetSaleChannelsApi } from '../../Api/SaleChannelApi';
import { DiscountType, KeyCode, ProductTypes } from '../../common/type-define';
import { canProcessOrder, isAgencyAccount } from '../../Helper/CommonHelper';
import { MathHelper } from '../../Helper/math.helper';
import { CustomerRes, CustomerType } from '../../Model/CustomerModel';
import { NewOrderData, ProductVersionItem } from "../../Model/OrderModel";
import { ProductDetail } from '../../Model/ProductModel';
import { SaleChanelResponse } from '../../Model/SaleChannel';
import { fetchVoucherProducts } from '../../redux/slices/voucher.slice';
import { NewOrderViewModel, OrderProductViewModel } from '../../ViewModel/OrderViewModel';
import CCombobox from '../Controls/CCombobox';
import CPricePopover from '../Controls/CPricePopover';
import { LeftRightHeaderContent } from '../Controls/LeftRightHeaderContent';
import InputMoney, { InputPercent } from '../Controls/MoneyInput';
import { CustomerModal } from '../Customers/CustomerModal';
import { cellStyle } from '../Helper/UIHelper';
import VoucherSelectionModal from './VoucherSelectionModal';


const { Option } = Select;
const { Step } = Steps;
const types = [{
    name: "Khách lẻ",
    key: "customer"
},
{
    name: "Đại lý",
    key: "agency"
}
];

type ProductCodeResponse = ProductResponse & {
    nameWithCode: string
}
function CreateOrderView() {

    const dispatch = useDispatch();
    const [form] = Form.useForm<NewOrderViewModel>();

    const [customers, setcustomers] = useState<CustomerRes[]>([]);
    const [cities, setCities] = useState<CityResponse[]>([]);
    const [districts, setDistricts] = useState<DistrictResponse[]>([]);
    const [products, setProducts] = useState<ProductCodeResponse[]>([]);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

    const [agencies, setAgencies] = useState<AgencyResponse[]>([]);

    const [orderProducts, setOrderProducts] = useState<OrderProductViewModel[]>([]);
    const [chanels, setChannels] = useState<SaleChanelResponse[]>([]);
    const [chanel, setChannel] = useState<SaleChanelResponse>();

    const [customerTypes, setCustomerTypes] = useState<CustomerType[]>([]);
    const [customerType, setCustomerType] = useState<CustomerType>();
    const [isAgency, setIsAgency] = useState<boolean>(false);
    const [discountType, setDiscountType] = useState<DiscountType>(DiscountType.Value);
    const [totalDiscount, setTotalDiscount] = useState<number>(0);
    const [voucherDiscount, setVoucherDiscount] = useState<number>(0);
    const [shippingFee, setShippingFee] = useState<number>(0);

    useEffect(() => {
        GetCustomersApi((data: IListItems<CustomerRes>) => { setcustomers(data.items); })
        GetCities((data: IListItems<CityResponse>) => { setCities(data.items); })
        GetProducts((data: IListItems<ProductResponse>) => {

            const productCodes = data.items.map(x => (
                {
                    ...x, nameWithCode: `${x.code} - ${x.name}`
                }))

            setProducts(productCodes);
        });

        const agencyAccount = isAgencyAccount();
        setIsAgency(agencyAccount);

        //Nếu là nhân viên công ty tạo đơn hàng thì sẽ có thể bán cho đại lý
        if (agencyAccount === false) {
            GetAgenciesApi((data: IListItems<AgencyResponse>) => { setAgencies(data.items); })

            GetSaleChannelsApi((res: IListItems<SaleChanelResponse>) => {
                setChannels(res.items);
                if (res.items.length > 0)
                    setChannel(res.items[0]);
            })
        }



        setCustomerTypes(types);

        setCustomerType(types[0]);
    }, []);

    //Xóa sản phẩm trong đơn hàng
    const handleRemoveProd = (prod: OrderProductViewModel) => {
        return () => {
            const newVersions = [...orderProducts];
            const findIndex = newVersions.findIndex(cart => cart.productId === prod.productId);
            if (findIndex !== -1) {
                newVersions.splice(findIndex, 1);
                setOrderProducts(newVersions);
            }

        }
    };
    const navigate = useNavigate();

    // Cần thông báo trước khi gọi api
    function validateBefore(data: NewOrderViewModel): boolean {

        return true;
    }
    //Sự kiện bắt hành động tao đơn hàng
    const createOrderSubmit = () => {
        form.validateFields()
            .then((data: NewOrderViewModel) => {

                if (validateBefore(data)) {
                    let productVers: ProductVersionItem[] = [];
                    orderProducts.map(prod => {
                        productVers.push({
                            quantities: prod.quantity,
                            productVersionId: prod.productVerId,
                            discount: prod.reducePrice,
                            discountType: prod.discountType ?? 0,
                            extraWarrantyId: prod.warrantyId
                        })
                    });
                    // call api to create order      
                    const newOrderData: NewOrderData = {
                        saleChannelId: chanel?.id,
                        cityId: data.cityId,
                        districtId: data.districtId,
                        productOrderDetails: productVers,
                        discountType: discountType === DiscountType.Value ? 0 : 1,
                        discount: totalDiscount,
                        addressDetailsExtended: data.addressDetail,
                        shippingFee: shippingFee,

                    };
                    console.log(newOrderData);
                    //Call api to create order
                    CreateOrderFromStaffApi(newOrderData, data.customerId, (res: any) => {
                        message.info("Tạo đơn bán hàng thành công");
                        form.setFieldsValue({});
                        if (canProcessOrder())
                            navigate(`/admin/orders/${res.id}`);
                        else
                            navigate("/admin/orders");
                    });
                }


            })


    };

    // Khi chọn thành phố thì sẽ cập nhật quân/huyện
    function cityChange(value: any) {
        GetDistricts(value, (distrs: DistrictResponse[]) => {
            setDistricts(distrs);
        })
    }

    //Khi chọn khách hàng thì cập nhật luôn thông tin địa chỉ
    function onChangeCustomer(value: any) {

        if (customerType?.key === "agency") {

        }
        else {
            const customer = customers.find(x => x.id === value);
            if (customer) {
                //Khi customer chua đươc load dữ liệu
                if (customer.city == null) {
                    GetCustomerByIdApi(value, (cusRes: CustomerRes) => {
                        if (cusRes.city && cusRes.district) {
                            GetDistricts(cusRes.city.id, (distrs: DistrictResponse[]) => {
                                setDistricts(distrs);
                                form.setFieldsValue({ cityId: cusRes.city.id, districtId: cusRes.district.id, addressDetail: cusRes.address });
                            });
                        }
                    })
                }
                else {
                    if (customer.city && customer.district)
                        GetDistricts(customer.city.id, (data: DistrictResponse[]) => {
                            setDistricts(data);
                            form.setFieldsValue({ cityId: customer.city.id, districtId: customer.district.id, addressDetail: customer.address });
                        });


                }
            }
        }

    }




    //Sau khi tạo ra khách hàng thi add thêm vào danh sách
    const createCustomerHandle = (newCus: CustomerRes) => {
        let newCusrtomer: CustomerRes[] = [...customers];
        newCusrtomer.push(newCus);

        setcustomers(newCusrtomer);
        if (newCus.city?.id)
            cityChange(newCus.city?.id);
        form.setFieldsValue({ customerId: newCus.id, cityId: newCus.city?.id, districtId: newCus.district?.id, addressDetail: newCus.address });
        setIsModalVisible(false);
    };


    //chọn 1 sản phẩm trên danh sách sản phẩm thì sẽ được thêm vào danh sách
    //Sau đấy người dùng chọn phiên bản
    function changeProduct(value: any) {

        const product = products.find(x => x.id === value);

        if (product != null) {
            GetProductById(product.id, (productDetail: ProductDetail) => {
                console.log(productDetail);
                let proVers: ProductVersionResponse[] = [];
                //Loại bỏ các phiên bản mặc định
                if (productDetail.productType === ProductTypes.Main) {

                    for (let index = 0; index < productDetail.productVersions.length; index++) {
                        const vesion = productDetail.productVersions[index];
                        if (vesion.color !== null && vesion.isAvailable)
                            proVers.push(vesion);
                    }
                }

                let newProducts: OrderProductViewModel[] = [...orderProducts];
                const productOrderVersion: OrderProductViewModel = {
                    productId: product.id,
                    code: product.code,
                    name: `[${product.code}]-${product.name}`,
                    productVerId: "",
                    quantity: 1,
                    reducePrice: 0,
                    salePrice: product.salePrice,
                    totalMoney: product.salePrice,
                    vat: product.vat,
                    productVersions: proVers,
                    extraWarranties: productDetail.extraWarranties,
                    extendPrice: 0,
                };

                let allowContinue: boolean = true;
                if (proVers.length === 1) {
                    const productVer = proVers[0];

                    if (checkBuyProductVersion(productVer)) {
                        productOrderVersion.productVerId = productVer.id;

                        productOrderVersion.quantity = 1;
                        productOrderVersion.salePrice = productVer.salePrice;
                        productOrderVersion.totalMoney = (productVer.salePrice) * 1;
                    }
                    else {
                        allowContinue = false;
                        message.info("Sản phẩm không còn trong kho");
                    }

                }

                if (allowContinue) {
                    newProducts.push(productOrderVersion);
                    setOrderProducts(newProducts);
                }


                form.setFieldsValue({ productSearchId: undefined })
            })

        }
    }


    function checkBuyProductVersion(productVer: ProductVersionResponse): boolean {
        if (productVer != null && productVer.isAvailable) {
            let remain = 0;
            if (productVer.productVersionWarehouses.length > 0) {
                remain += productVer.productVersionWarehouses.map(a => a.remainQuantities).reduce(function (a, b) {
                    return a + b;
                })
            }

            if (productVer.productVersionAgencies.length > 0) {
                remain += productVer.productVersionAgencies.map(a => a.remainQuantities).reduce(function (a, b) {
                    return a + b;
                })
            }

            if (remain > 0)
                return true;
            else
                message.error('Sản phẩm này đã hết hàng');
        }
        return false;
    }

    //Thay đổi phiên bản thì cần cập nhật đơn giá và thành tiên cho mỗi sản phẩm
    function onChangeVersion(value: valueType, prodChanged: OrderProductViewModel) {

        const productVersionId = value.toString();
        let newProducts: OrderProductViewModel[] = [...orderProducts];
        const rowIndex = newProducts.indexOf(prodChanged);
        if (rowIndex != -1) {
            let index = prodChanged.productVersions.findIndex(x => x.id == productVersionId);
            if (index != -1) {
                const versionChoosed = prodChanged.productVersions[index];

                if (checkBuyProductVersion(versionChoosed)) {
                    const productOrderVersion: OrderProductViewModel = {
                        ...prodChanged,
                        productVerId: productVersionId,
                        quantity: prodChanged.quantity,
                        salePrice: versionChoosed.salePrice,
                        totalMoney: (versionChoosed.salePrice + prodChanged.extendPrice - prodChanged.reducePrice) * prodChanged.quantity,

                    };
                    newProducts[rowIndex] = productOrderVersion;
                }
                else
                    newProducts[rowIndex] = { ...prodChanged, productVerId: '', salePrice: 0, totalMoney: 0 };

                setOrderProducts(newProducts);
            }

        }
    }


    function onChangeWarranty(value: valueType, record: OrderProductViewModel) {
        if (value == null) {
            record.warrantyId = undefined;
            return;
        }

        record.warrantyId = value.toString();
        const warranty = record.extraWarranties?.find(x => x.id === record.warrantyId)
        if (warranty) {

            let newProducts: OrderProductViewModel[] = [...orderProducts];
            const idx = newProducts.indexOf(record);

            newProducts[idx] = {
                ...record,
                extendPrice: warranty.price,
                totalMoney: (record.salePrice + warranty.price - record.reducePrice) * record.quantity
            }
            setOrderProducts(newProducts);
        }
    }

    function onRemoveWarranty(record: OrderProductViewModel) {
        if (record != null) {
            record.warrantyId = undefined;
            let newProducts: OrderProductViewModel[] = [...orderProducts];
            const idx = newProducts.indexOf(record);

            newProducts[idx] = {
                ...record,
                extendPrice: 0,
                totalMoney: (record.salePrice - record.reducePrice) * record.quantity
            }
            setOrderProducts(newProducts);
        }

    }
    //Thay dổi số lượng mua sản phẩm
    const onChangeQuantity = (rowIndex: number) => {
        return () => {

            let inputQuantity = form.getFieldValue(rowIndex);
            let newQuanity = Number.parseInt(inputQuantity);
            if (rowIndex != -1) {
                let newProducts: OrderProductViewModel[] = [...orderProducts];
                const prodChanged = newProducts[rowIndex];

                const productOrderVersion: OrderProductViewModel = {
                    ...prodChanged,
                    quantity: newQuanity,
                    totalMoney: (prodChanged.salePrice - prodChanged.reducePrice + prodChanged.extendPrice) * newQuanity,
                };
                newProducts[rowIndex] = productOrderVersion;

                setOrderProducts(newProducts);

            }

        }
    };


    const totalMoney = (): number => {
        let total: number = 0;
        orderProducts.map(x => {
            total += ((x.salePrice - x.reducePrice + x.extendPrice) * x.quantity);
        })
        return total;
    }

    const orderSaleMoney = (): number => {
        let total: number = 0;

        return total;
    }

    const shipFee = (): number => {
        let total: number = 0;

        return total;
    }

    const totalTax = (): number => {
        let total: number = 0;
        orderProducts.map(x => {
            total += ((x.salePrice) * x.quantity * x.vat / 100);
        })
        return total;
    }

    const customerPayMoney = (): number => {
        let total: number = totalMoney() + totalTax() - orderSaleMoney() - totalDiscount - voucherDiscount + shippingFee;

        return total;
    }

    const openInputVoucher = () => {
        const productIds = orderProducts.map(v => v.productId)
        dispatch(fetchVoucherProducts(productIds))
    }

    const footerContent = (
        <Row>
            <Col span={12} />
            <Col span={12}>
                <LeftRightHeaderContent leftContent={`Tổng tiền (${orderProducts.length} sản phẩm)`} rightContent={MathHelper.formatPriceVND(totalMoney())} />

                <LeftRightHeaderContent
                    leftContent={
                        <CPricePopover
                            title={"Chiết khấu phân bổ"}
                            total={totalMoney()}
                            onChangeCallback={setTotalDiscount}>
                            <Link>Chiết khấu phân bổ</Link>
                        </CPricePopover>
                    }
                    rightContent={<span style={{ color: 'red', textDecoration: 'line-through' }}>{MathHelper.formatPriceVND(totalDiscount, true)}</span>} />

                <LeftRightHeaderContent
                    leftContent={
                        <>
                            <Link onClick={openInputVoucher}>{`Mã giảm giá`}</Link>
                            <VoucherSelectionModal setVoucherDiscount={setVoucherDiscount} />
                        </>
                    }
                    rightContent={<span style={{ color: 'red', textDecoration: 'line-through' }}>{MathHelper.formatPriceVND(voucherDiscount, true)}</span>} />

                <LeftRightHeaderContent
                    leftContent={
                        <CPricePopover
                            title={"Phí giao hàng"}
                            total={totalMoney()}
                            onChangeCallback={setShippingFee}>
                            <Link>Phí giao hàng</Link>
                        </CPricePopover>
                    }
                    rightContent={<span>{MathHelper.formatPriceVND(shippingFee, true)}</span>} />
                <LeftRightHeaderContent leftContent={<div style={{ color: "#007BFF" }}>Thuế GTGT</div>} rightContent={MathHelper.formatPriceVND(totalTax(), true)} />
                <LeftRightHeaderContent leftContent={<div style={{ color: "#392525", fontWeight: "bold" }}>Khách phải trả</div>} rightContent={MathHelper.formatPriceVND(customerPayMoney())} />
            </Col>
        </Row>
    );


    const selectBefore = (rowIndex: number) => {
        return (
            <Select
                style={{ minWidth: '80px' }}
                dropdownMatchSelectWidth
                defaultValue={DiscountType.Value}
                onChange={(type) => handleDiscountType(type, rowIndex)}>
                <Option value={DiscountType.Value}>
                    <span>Giá trị</span>
                </Option>
                <Option value={DiscountType.Percent}>
                    <span>%</span>
                </Option>
            </Select>
        )
    };


    const handleDiscountType = (type: DiscountType, rowIndex: number) => {
        setDiscountType(type);
        onChangeSaleOff(rowIndex, type);
    }


    //Giảm giá sản phẩm
    const onChangeSaleOff = (rowIndex: number, discountType: DiscountType) => {
        let inputSaleOff = form.getFieldValue(`Sale${rowIndex}`);

        let saleOff = Number.parseInt(inputSaleOff);
        if (rowIndex !== -1) {
            let newProducts: OrderProductViewModel[] = [...orderProducts];
            const prodChanged = newProducts[rowIndex];
            //tim tất cả các sản phẩm cùng version để thay đổi giá
            newProducts.map(x => {
                if (x.productVerId === prodChanged.productVerId) {
                    const reducePrice = discountType === DiscountType.Value ? saleOff : x.salePrice * saleOff / 100;
                    const productOrderVersion: OrderProductViewModel = {
                        ...x,
                        reducePrice,
                        discountType: discountType === DiscountType.Value ? 0 : 1,
                        totalMoney: (x.salePrice - reducePrice + x.extendPrice) * x.quantity,
                    };
                    newProducts[newProducts.indexOf(x)] = productOrderVersion;
                }
            })
            setOrderProducts(newProducts);
        }
    };


    function onChangeVat(value: any, record: OrderProductViewModel) {
        //record.totalMoney = (record.salePrice * (1 + value / 100) - record.reducePrice) * record.quantity;
        record.vat = value;

        const rowIndex = orderProducts.indexOf(record);
        let newProducts: OrderProductViewModel[] = [...orderProducts];
        const prodChanged = newProducts[rowIndex];
        //tim tất cả các sản phẩm cùng version để thay đổi giá
        newProducts.map(x => {
            if (x.productVerId === prodChanged.productVerId) {

                const productOrderVersion: OrderProductViewModel = {
                    ...record,
                };
                newProducts[newProducts.indexOf(x)] = productOrderVersion;
            }
        })
        setOrderProducts(newProducts);
    }

    //Định nghĩa các cột cho bảng sản phẩm
    const ProductCols: ColumnsType<OrderProductViewModel> = [
        {
            title: 'Mã SP',
            dataIndex: 'code',
            key: 'code',
            fixed: 'left',
            onCell: () => cellStyle(),
        },
        {
            title: 'Phiên bản',
            dataIndex: 'productId',
            key: 'productId',
            render: (name, record, index) => (
                <Select style={{ minWidth: 80 }} onChange={(val) => onChangeVersion(val, record)} value={record.productVerId} disabled={record.productVersions.length === 1} >
                    {record.productVersions.map(item => (
                        <Option value={item.id} key={item.id} >{item.name}</Option>
                    ))}
                </Select>
            )
        },
        {
            title: 'Gói BH',
            dataIndex: 'exWarrantyId',
            key: 'warrantyId',
            render: (warrantyId, record, index) => (

                (record.extraWarranties && (record.extraWarranties.length > 0)) ?
                    <Select style={{ minWidth: 80 }} value={record.warrantyId} onChange={(val) => onChangeWarranty(val, record)} >
                        {record.extraWarranties.map(item => (
                            <Option value={item.id} key={item.id} >{item.name ? item.name : ""}</Option>
                        ))}
                        {(record.warrantyId && record.warrantyId !== undefined) ? <Link onClick={() => onRemoveWarranty(record)} style={{ color: 'red' }}>Xóa BH</Link> : ''}
                    </Select> : <div></div>
            )
        },
        {
            title: 'Số lượng',
            dataIndex: 'quantity',
            key: 'quantity',
            render: (quantity, record, index) => (
                <Form.Item name={index} style={{ marginBottom: 0 }}>
                    <InputNumber min={1} defaultValue={quantity} onChange={onChangeQuantity(index)} />
                </Form.Item>
            ),
        },
        {
            title: 'Đơn giá',
            dataIndex: 'salePrice',
            key: 'salePrice',
            render: (salePrice, record, index) => (
                <Popover
                    placement="bottom"
                    title="Chiết khấu"
                    destroyTooltipOnHide
                    content={
                        <Form.Item name={`Sale${index}`} style={{ marginBottom: 0 }}>
                            {
                                discountType === DiscountType.Value ?
                                    <InputMoney
                                        addonBefore={selectBefore(index)}
                                        onChange={() => onChangeSaleOff(index, discountType)}
                                    />
                                    :
                                    <InputPercent
                                        addonBefore={selectBefore(index)}
                                        onChange={() => onChangeSaleOff(index, discountType)}
                                    />
                            }

                        </Form.Item>
                    }
                    trigger="click">
                    <Link>{MathHelper.formatPriceVND(salePrice)}</Link>
                    <div style={{ color: "red", textDecoration: "line-through" }}>{MathHelper.formatPriceVND(record.reducePrice)}</div>
                </Popover>
            )
        },
        {
            title: 'VAT',
            dataIndex: 'vat',
            key: 'vat',
            align: 'center',
            render: (vat, record) => (
                <InputNumber
                    defaultValue={vat}
                    min={0}
                    max={100}
                    formatter={value => `${value}%`}
                    parser={value => value?.replace('%', '')}
                    onChange={(val: any) => onChangeVat(val, record)} />
            )
        },
        {
            title: 'Thành tiền',
            dataIndex: 'totalMoney',
            key: 'totalMoney',
            fixed: 'right',
            align: 'center',
            width: '120px',
            render: (totalMoney) => (
                <div>{MathHelper.formatPriceVND(totalMoney)}</div>
            )
        },
        {
            title: '',
            key: 'action',
            fixed: 'right',
            align: 'center',
            width: '30px',
            render: (record) => (
                <Space size="middle" >
                    <DeleteOutlined style={{ color: 'red' }} onClick={handleRemoveProd(record)} />
                </Space>
            ),
        },
    ];

    const onChooseSaleChannel = (info: any) => {

        setChannel(chanels.find(x => x.id == info.key));

    };

    const menu = (
        <Menu onClick={onChooseSaleChannel}>
            {chanels.map(x => (
                <Menu.Item key={x.id}>{x.name}</Menu.Item>
            ))}
        </Menu>
    );

    const onChooseCustomerType = (info: any) => {

        setCustomerType(customerTypes.find(x => x.key == info.key));
        //Thay đổi khách hàng thì cần đổi lại giá trị
        form.setFieldsValue({ customerId: "" });
    };



    const customerOptions = (
        <Menu onClick={onChooseCustomerType}>
            {customerTypes.map(x => (
                <Menu.Item key={x.key}>{x.name}</Menu.Item>
            ))}
        </Menu>
    );


    return (
        <div>
            <Affix offsetTop={0}>
                <div style={{ textAlign: "left", backgroundColor: '#F0F2F5' }}>
                    <Row>
                        <Col flex="none" >
                            <Button type="text" icon={<LeftOutlined />} style={{ marginTop: 10 }}
                                href="/admin/orders" size="large">
                                Đơn hàng
                            </Button>
                        </Col>
                        <Col flex="auto" style={{ justifyContent: 'center', alignItems: 'center' }}>
                            <Steps size="small" current={0} labelPlacement="vertical" style={{ marginTop: 10, alignItems: 'center' }}>
                                <Step title="Đặt hàng" />
                                <Step title="Duyệt" />
                                <Step title="Đóng gói" />
                                <Step title="Vận chuyển" />
                                <Step title="Thanh toán" />
                            </Steps>
                        </Col>
                        <Col flex="none" >
                            <Button type="primary" icon={<PlusOutlined />}
                                style={{ margin: 5 }}
                                onClick={createOrderSubmit} size="large">
                                Tạo đơn hàng
                            </Button>
                        </Col>
                    </Row>

                </div>
            </Affix>
            <div style={{ backgroundColor: "#FFFFFF" }}>

            </div>


            <Form form={form} style={{ maxHeight: '80vh', overflow: 'auto' }}
                layout="vertical">
                <Row>
                    <Col span={18}>

                        <Card size="small" title={<LeftRightHeaderContent leftContent={
                            <Space direction="horizontal">
                                <div >Thông tin khách hàng</div>
                                <div hidden={isAgency}>
                                    <Dropdown overlay={customerOptions} trigger={['click']}>
                                        <Typography.Link onClick={e => e.preventDefault()}>
                                            {customerType?.name} <DownOutlined />
                                        </Typography.Link>

                                    </Dropdown >
                                </div>
                            </Space>
                        }
                            rightContent={<Space direction="horizontal">
                                <div>Kênh bán hàng</div>
                                <Dropdown overlay={menu}>
                                    <Typography.Link onClick={e => e.preventDefault()}>
                                        {chanel?.name} <DownOutlined />
                                    </Typography.Link>

                                </Dropdown >
                            </Space>} />}>
                            <Row>
                                <Col span={15}>
                                    <Form.Item style={{ marginBottom: 3 }}
                                        label=""
                                        name="customerId">
                                        {
                                            customerType?.key === "agency" ?
                                                <CCombobox
                                                    focusHotkey={KeyCode.F4}
                                                    placeholder="Chọn theo khách (F4)"
                                                    chooseOptions={agencies}
                                                    displayName="name"
                                                    id="id"
                                                    onChange={onChangeCustomer} />
                                                :
                                                <CCombobox
                                                    focusHotkey={KeyCode.F4}
                                                    placeholder="Chọn theo đại lý (F4)"
                                                    chooseOptions={customers}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    displayName="fullName"
                                                    id="id"
                                                    onChange={onChangeCustomer}
                                                    extraContent={
                                                        <div>
                                                            <Divider style={{ margin: '4px 0' }} />
                                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                <Typography.Link onClick={() => setIsModalVisible(true)}
                                                                    style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}>
                                                                    <PlusOutlined />
                                                                    <span>Thêm khách hàng mới</span>
                                                                </Typography.Link>
                                                            </div>
                                                        </div>
                                                    } />
                                        }
                                    </Form.Item>
                                </Col>

                                <Col span={1} />
                                <Col span={8}>

                                </Col>
                            </Row>
                        </Card>


                        <Card size="small" title="Thông tin sản phẩm" >
                            <Form.Item label="" name="productSearchId" style={{ marginBottom: 3 }}>
                                <CCombobox
                                    focusHotkey={KeyCode.F3}
                                    placeholder="Chọn sản phẩm (F3)"
                                    chooseOptions={products}
                                    displayName="nameWithCode"
                                    id="id"
                                    onChange={changeProduct} />
                            </Form.Item>

                            <Table dataSource={orderProducts}
                                columns={ProductCols}
                                scroll={{ x: true }}
                                pagination={false} footer={() => footerContent} />
                        </Card>

                    </Col>
                    <Col span={6} style={{ paddingLeft: 10 }}>
                        <Card size="small" title="Thông tin giao hàng">
                            <Form.Item label="Tỉnh/Thành phố" name="cityId">
                                <CCombobox
                                    placeholder="Chọn Tỉnh/Thành phố"
                                    chooseOptions={cities}
                                    displayName="name"
                                    id="id"
                                    onChange={cityChange} />
                            </Form.Item>

                            <Form.Item label="Quận/Huyện" name="districtId">
                                <CCombobox
                                    placeholder="Chọn Quận/Huyện"
                                    chooseOptions={districts}
                                    displayName="name"
                                    id="id" />
                            </Form.Item>

                            <Form.Item label="Địa chỉ" name="addressDetail">
                                <Input placeholder="Nhập địa chỉ" />
                            </Form.Item>

                            {/* <Form.Item label="Hẹn ngày giao hàng" name="schedule">
                                <DatePicker placeholder="Chọn ngày giao hàng" format="DD/mm/yyyy" />
                            </Form.Item> */}
                        </Card>
                    </Col>
                </Row>



            </Form>

            <CustomerModal okModalFunc={createCustomerHandle}
                onCancel={() => setIsModalVisible(false)}
                cities={cities}
                visible={isModalVisible} />
        </div >
    )
}

export default CreateOrderView;

