import { useEffect, useState } from "react";
import { ColumnsType } from "antd/lib/table";
import { DefectProductOrderResponse } from "../../Model/ProductModel";
import { DefectProductApi } from "../../Api/DefectProductApi";
import ApproveExportDefectProductOrderModal from "./ApproveExportDefectProductOrderModal"
import ApproveImportDefectProductOrderModal from "./ApproveImportDefectProductOrderModal";
import { Table, Space, message, Tag, Tooltip } from "antd";
import {
    CheckOutlined,
    CloseOutlined,
    SearchOutlined,
    RollbackOutlined
} from '@ant-design/icons';
import { DefectProductOrderResponseStatus } from "../../common/type-define";

type DefectProductOrderProps = {
    isActived: boolean
}

export default function DefectProductOrderView(props: DefectProductOrderProps) {
    const { isActived } = props;
    const [loading, setLoading] = useState(false);
    const [approveExportDefectOrderModalVis, setApproveExportDefectOrderModalVis] = useState(false);
    const [approveImportDefectOrderModalVis, setApproveImportDefectOrderModalVis] = useState(false);
    const [defectProductOrders, setdefectProductOrders] = useState<DefectProductOrderResponse[]>([])
    const [activeDefectProductOrder, setActiveDefectProductOrder] = useState<DefectProductOrderResponse>();
    useEffect(() => {
        async function fetch() {
            if (isActived) {
                let res = await DefectProductApi.GetDefectProductOrdersApi()
                if (res?.status === 200) {
                    console.log(res.data.items);
                    setdefectProductOrders(res.data.items)
                }
            }
        }
        fetch();

    }, [isActived]);

    //Reload lại nội dung cho
    async function updateRecordStatus() {
        setLoading(true);
        let res = await DefectProductApi.GetDefectProductOrdersApi()
        if (res?.status === 200) {
            setdefectProductOrders(res.data.items)
        }
    }

    function getStatus(record: DefectProductOrderResponse): JSX.Element {
        if (record.status === DefectProductOrderResponseStatus.Created.valueOf()) {
            return (<Tag color="yellow" >Tiếp nhận</Tag>);
        }
        else if (record.status === DefectProductOrderResponseStatus.Declined.valueOf()) {
            return (<Tag color="Red">Từ chối</Tag>);
        }
        else if (record.status === DefectProductOrderResponseStatus.Approved.valueOf()) {
            return (<Tag color="green">Đã phê duyệt</Tag>);
        }
        else if (record.status === DefectProductOrderResponseStatus.Exported.valueOf()) {
            return (<Tag color="blue">Xuất kho</Tag>);
        }
        else if (record.status === DefectProductOrderResponseStatus.Imported.valueOf()) {
            return (<Tag color="blue">Nhập lại kho</Tag>);
        }
        return (<Tag color="#e4e669">Chờ duyệt</Tag>);
    }

    const decisionOrder = (decision: number, record: DefectProductOrderResponse) => {
        return () => {
            setActiveDefectProductOrder(record);
            if (decision === 1) {
                DefectProductApi.DecisionDefectProductOrderStatus(record.id, { decision: decision }, () => {
                    message.info("Phiếu xuất linh kiện bị từ chối");
                    updateRecordStatus();
                })
            }
            if (decision === 0) {
                setApproveExportDefectOrderModalVis(true);
            }

        }
    }

    //Điều khiển việc ẩn hiện các hành động tương ứng với trạng thái của phiếu bảo hành
    function getControlAction(record: DefectProductOrderResponse): JSX.Element {

        if (record.status === DefectProductOrderResponseStatus.Created.valueOf()
            || record.status === DefectProductOrderResponseStatus.Declined.valueOf()) {
            return (
                <Space direction="horizontal">
                    <Tooltip title="Phê duyệt" color="green">
                        <CheckOutlined style={{ color: "green" }}
                            onClick={decisionOrder(0, record)} />
                    </Tooltip>
                    <Tooltip title="Từ chối" color="red">
                        <CloseOutlined style={{ color: "red" }}
                            onClick={decisionOrder(1, record)} />
                    </Tooltip>
                </Space>

            )
        }
        else if (record.status === DefectProductOrderResponseStatus.Exported.valueOf()) {
            return (
                <Space direction="horizontal">
                    <Tooltip title="Nhập lại kho" color="green">
                        <RollbackOutlined />
                    </Tooltip>

                </Space>
            )

        }
        return (<div><SearchOutlined />Xem lịch sử</div>)
    }

    const cols: ColumnsType<DefectProductOrderResponse> = [
        {
            title: 'Mã Phiếu',
            dataIndex: 'orderCode',
            key: 'orderCode'
        },
        {
            title: 'Lý do',
            dataIndex: 'reason',
            key: 'reason',
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            render: (status, record) => (
                getStatus(record)
            )
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            render: (record) => (
                getControlAction(record)
            )
        }
    ]

    function approveExportDefectProductOrder(waresHouseId: string) {
        let orderId = activeDefectProductOrder ? activeDefectProductOrder?.id : ''

        DefectProductApi.DecisionDefectProductOrderStatus(orderId, { decision: 0 }, () => {
            DefectProductApi.ExportDefectProductOrder(orderId, { warehouseId: waresHouseId }, () => {
                setApproveExportDefectOrderModalVis(false);
                message.success("Linh kiện đã được xuất kho")
                updateRecordStatus();
            })
        })
    }

    function approveImportDefectProductOrder(waresHouseId: string) {
        let orderId = activeDefectProductOrder ? activeDefectProductOrder?.id : ''

        DefectProductApi.ImportDefectProductOrderapi(orderId, { warehouseId: waresHouseId }, () => {
            setApproveExportDefectOrderModalVis(false);
            message.success("Linh kiện đã được nhập lại kho")
            updateRecordStatus();
        })
    }

    return (
        <>
            <div>
                <Table columns={cols}
                    dataSource={defectProductOrders}
                />
            </div>
            <ApproveExportDefectProductOrderModal title="Thông tin xuất kho"
                defectProductOrder={activeDefectProductOrder}
                approveExportOrder={approveExportDefectProductOrder}
                onCancel={() => setApproveExportDefectOrderModalVis(false)}
                visible={approveExportDefectOrderModalVis} />
            <ApproveImportDefectProductOrderModal title="Thông tin nhập kho"
                defectProductOrder={activeDefectProductOrder}
                approveImportOrder={approveImportDefectProductOrder}
                onCancel={() => setApproveImportDefectOrderModalVis(false)}
                visible={approveImportDefectOrderModalVis} />

        </>

    )
}