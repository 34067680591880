import { Form, Input, message, Modal, ModalProps } from "antd";
import { CreateUnitApi } from "../../Api/UnitsApi";

type UnitModalProps = ModalProps & {
    okModalFnc: Function
}
export default function UnitModal(props: UnitModalProps) {

    const { okModalFnc, ...rest } = props;
    const [form] = Form.useForm();

    //Tạo ra đơn vị
    const createUnit = () => {
        const unit = form.getFieldValue("unit");
        if (unit === null || unit === "") {
            message.info("Tên không được bỏ trống");
            return;
        }

        let dataReq: UnitRequest = {
            name: unit,
            description: form.getFieldValue("desc"),
        }

        CreateUnitApi(dataReq, (res: UnitResponse) => {
            //Sau khi tạo xong unit sẽ call back sang đối tượng cha
            okModalFnc(res);
        })
    }
    return (
        <Modal {...rest}
            onOk={createUnit}
            okText="Tạo">
            <Form layout="vertical" form={form}>
                <Form.Item label="Tên đơn vị" name="unit" required>
                    <Input />
                </Form.Item>
                <Form.Item label="Miêu tả" name="desc">
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    )
}