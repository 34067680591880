import { Button, Divider, message, Select, Spin, Table, Tag } from "antd";
import {
    PlusOutlined,
    LeftOutlined
} from '@ant-design/icons';
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { GetAgenciesApi } from "../../Api/AgencyApi";
import { AgencyModal } from "./AgencyModal";
const { Option } = Select;
export function AgenciesView() {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [agencies, setAgencies] = useState<AgencyResponse[]>([]);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [agencyEdit, setAgencyEdit] = useState<AgencyResponse>();

    function loadData() {
        GetAgenciesApi((data: IListItems<AgencyResponse>) => {
            setAgencies(data.items);

        }).finally(() => setIsLoading(false))
    }
    useEffect(() => {
        loadData();
    }, [])

    const ShowCreateAgencyModel = () => {
        setIsModalVisible(true);
    }

    const editAgencyModal = (record: AgencyResponse) => {
        return () => {

            setAgencyEdit(record);
            setIsModalVisible(true);
        }
    }

    const applyAgencyHandle = (res: AgencyResponse) => {

        loadData();
        setIsModalVisible(false);
    }


    const columns: ColumnsType<AgencyResponse> = [
        {
            title: 'Tên đại lý',
            dataIndex: 'name',
            key: 'name',
            render: (name, record) => (
                <a onClick={editAgencyModal(record)}>
                    <span>{name}</span>
                </a>
            )
        },
        {
            title: 'Người đại diện',
            dataIndex: 'representativePerson',
            key: 'representativePerson',
        },
        {
            title: 'Số điện thoại',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'MST',
            dataIndex: 'taxNumber',
            key: 'taxNumber',
        },
        {
            title: 'Địa chỉ',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'trạng thái',
            dataIndex: 'status',
            key: 'status',
            render: () => (
                <Tag color="green">Đang hoạt động</Tag>
            )

        },
    ];


    return (
        <div>
            <div style={{ display: "flex", height: 50, top: 25 }}>

                <Button type="text" icon={<LeftOutlined />} href="/admin/settings" size="large">
                    Quay lại cấu hình
                </Button>
                <div style={{ flexGrow: 1, textAlign: "end" }}>
                    <Button type="primary" icon={<PlusOutlined />} onClick={ShowCreateAgencyModel} size="middle">
                        Thêm mới đại lý
                    </Button>
                </div>
            </div>
            <Divider style={{ marginTop: 0, marginLeft: 20 }} />
            <Spin tip="Đang tải dữ liệu" spinning={isLoading}>
                <Table
                    columns={columns}
                    dataSource={agencies}
                />
            </Spin>

            <AgencyModal visible={isModalVisible}
                title="Đại lý"
                editAgency={agencyEdit}
                onCancel={() => setIsModalVisible(false)}
                okModalFunc={applyAgencyHandle} />


        </div>
    )
}