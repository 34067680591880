import { Button, message, Space, Spin, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { canInputStore, isAgencyAccount } from "../../Helper/CommonHelper";
import { MathHelper } from "../../Helper/math.helper";
import { useSelectorRoot } from "../../redux/configure/store";
import { getFetchStorageLoading, getListStorageProduct } from "../../redux/selectors/storage.selector";
import { fetchListStorage } from "../../redux/slices/storage.slice";
import { getHeightFit } from "../Helper/UIHelper";
import StorageBar from "../Storages/StorageBar";
import { FileAddOutlined, LeftOutlined } from '@ant-design/icons';
import { Link, useNavigate } from "react-router-dom";
import { InventoryCreateItem } from "../../Model/InventoryModel";
import { InventoryApi } from "../../Api/InventoryApi";
import { LeftRightHeaderContent } from "../Controls/LeftRightHeaderContent";

const InventoryCreateComponent = (): JSX.Element => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const listStorageProduct = useSelectorRoot(getListStorageProduct);
    const fetchStorageLoading = useSelectorRoot(getFetchStorageLoading);
    const [dataSource, setDataSource] = useState<ProductVersionResponse[]>([]);
    const [selectedRow, setSelectedRow] = useState<ProductVersionResponse[]>([]);
    const [curWarehouse, setWarehouse] = useState<IStorageItem | undefined>(undefined);
    const [createInvLoading, setCreateInvLoading] = useState<boolean>(false);
    const [InitStore, setInitStore] = useState<boolean>(false);
    useEffect(() => {
        dispatch(fetchListStorage());
    }, [dispatch])

    useEffect(() => {
        const products = Object.values(listStorageProduct).flat();
        setDataSource(products);
    }, [listStorageProduct])


    const getTotalRemain = (record: ProductVersionResponse): number => {
        const sumWarehouse = record.productVersionWarehouses.reduce((p, c) => p + c.remainQuantities, 0);
        const sumAngency = record.productVersionAgencies.reduce((p, c) => p + c.remainQuantities, 0);
        return sumWarehouse + sumAngency;
    }

    const onRowSelection = (record: ProductVersionResponse) => {
        setSelectedRow(pre => {
            const exist = pre.some(v => v.id === record.id);
            if (exist) return pre.filter(v => v.id !== record.id);
            return [...pre, ...[record]];
        })
    }

    const columns: ColumnsType<ProductVersionResponse> = [
        {
            title: 'Tên sản phẩm',
            dataIndex: 'fullName',
            key: 'fullName',
            width: '45%',
            showSorterTooltip: false,
            sorter: (a, b) => a.fullName < b.fullName ? 1 : -1,
            render: (fullName: string, record: ProductVersionResponse) => (
                <Typography.Link>
                    {fullName}
                </Typography.Link>
            )
        },
        {
            title: 'Mã SP',
            dataIndex: 'productCode',
            key: 'productCode',
            width: '15%',
            showSorterTooltip: false,
            sorter: (a, b) => a.productCode < b.productCode ? 1 : -1,
        },
        {
            title: 'Tồn kho',
            dataIndex: 'remain',
            key: 'remain',
            width: '15%',
            showSorterTooltip: false,
            sorter: (a, b) => getTotalRemain(a) < getTotalRemain(b) ? 1 : -1,
            render: (_: any, record: ProductVersionResponse) => (
                <span>{getTotalRemain(record)}</span>
            )
        },
        {
            title: 'Định mức',
            dataIndex: 'productWarningThreshold',
            key: 'productWarningThreshold',
            width: '15%',
            showSorterTooltip: false,
            sorter: (a, b) => a.productWarningThreshold < b.productWarningThreshold ? 1 : -1,
        },
        {
            title: 'Giá bán',
            dataIndex: 'salePrice',
            key: 'salePrice',
            width: '15%',
            align: 'right',
            showSorterTooltip: false,
            sorter: (a, b) => a.salePrice < b.salePrice ? 1 : -1,
            render: (salePrice: number) => (
                <span>{MathHelper.formatPriceVND(salePrice, true)}</span>
            )
        },
        {
            title: 'Giá nhập',
            dataIndex: 'priceInput',
            key: 'priceInput',
            width: '15%',
            align: 'right',
            showSorterTooltip: false,
            sorter: (a, b) => a.priceInput < b.priceInput ? 1 : -1,
            render: (priceInput: number) => (
                <div>{canInputStore() ? MathHelper.formatPriceVND(priceInput, true) : "Khóa"}</div>
            )
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: ProductVersionResponse[]) => setSelectedRow(selectedRows),
    };

    const onCreateInventory = async () => {
        if (!curWarehouse) return;
        const newInventory: InventoryCreateItem = {
            warehouseId: curWarehouse.id,
            productVersionIds: selectedRow.map(r => r.id),
            reason: 'reason inventory'
        }
        if (isAgencyAccount()) {
            delete newInventory.warehouseId;
        }
        setCreateInvLoading(true);
        const res = await InventoryApi.createInventory(newInventory);
        if (res?.status === 200) {
            message.success('Tạo phiếu kiểm thành công.');
            setSelectedRow([]);
            navigate(`/admin/inventory/${res.data.id}`)
        }
        else {
            message.error('Tạo phiếu kiểm không thành công.');
        }
        setCreateInvLoading(false);
    }

    useEffect(() => {
        setSelectedRow([]);
    }, [curWarehouse])

    return (
        <Spin spinning={createInvLoading}>
            <div style={{ margin: '10px auto' }}>
                <Link to="/admin/variants">
                    <LeftOutlined /> Quay lại Quản lý kho
                </Link>
            </div>

            <LeftRightHeaderContent leftContent={<StorageBar displayAll={false} setWarehouse={setWarehouse} />}
                rightContent={

                    <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                        <span style={{ margin: 'auto 8px' }}>
                            <Typography.Text strong>{selectedRow.length} </Typography.Text>
                            sản phẩm được chọn
                        </span>
                        <Button
                            disabled={selectedRow.length === 0}
                            type="primary"
                            onClick={onCreateInventory}
                            icon={<FileAddOutlined />}>
                            Tạo phiếu kiểm
                        </Button>
                    </div>
                }

            />

            <div style={{ height: "100%" }}>
                <Table
                    columns={columns}
                    loading={fetchStorageLoading}
                    dataSource={dataSource}
                    pagination={{ pageSize: 20 }}
                    bordered
                    scroll={{ x: '900px', y: getHeightFit(400) }}
                    rowKey={'id'}
                    title={() => <Typography.Text strong>Chọn kho/đại lý và sản phẩm để tạo phiếu kiểm</Typography.Text>}
                    rowSelection={{
                        type: 'checkbox',
                        selectedRowKeys: selectedRow.map(v => v.id),
                        ...rowSelection,
                    }}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: event => onRowSelection(record)
                        }
                    }}
                />
            </div>
        </Spin>

    )
}

const InventoryCreate = React.memo(InventoryCreateComponent);
export default InventoryCreate;