import { DeleteOutlined, InfoCircleOutlined, LeftOutlined, PlusOutlined, ProfileFilled, SaveOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, Form, Input, message, Row, Select, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { RcFile, UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { GetColorsApi } from "../../Api/AttributesApi";
import { GetCategories } from "../../Api/CategoryApi";
import { GetFactories } from "../../Api/FactoryApi";
import { CreateProductVersionsApi, GetProductById, UpdateProductApi } from "../../Api/ProductApi";
import { GetUnitsApi } from "../../Api/UnitsApi";
import { ProductTypes } from "../../common/type-define";
import Category from "../../Model/CategoryModel";
import Factory from "../../Model/FactoryModel";
import { NewProductVersionData, NewProductVersionMoldel, ProductDetail, ProductVersionUpdateInformationDetailCommand, UpdateProductReq, UpdateProductVersionsCommand } from "../../Model/ProductModel";
import { ExtraWarrantyResponse } from "../../Model/WarrantiesModel";
import { updateProductVersions } from "../../redux/slices/products.slice";
import { ColorVersion, NewProductViewModel } from "../../ViewModel/ProductViewModel";
import CCombobox from "../Controls/CCombobox";
import { LabelColor } from "../Controls/LabelColor";
import InputMoney, { InputPercent } from "../Controls/MoneyInput";
import ColorVersionModal from "./ColorVersionModal";
import ExtraWarrantyModal from "./ExtraWarrantyModal";
import InitStorageModal from "./InitStorageModal";


const { TextArea } = Input;
const { Option } = Select;
export function EditProductView() {

    const dispatch = useDispatch();
    const { id } = useParams();

    const [form] = Form.useForm<NewProductViewModel>();

    const [categories, setCategories] = useState<Category[]>([]);
    const [factories, setFactories] = useState<Factory[]>([]);
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [hasColor, setHasColor] = useState<boolean>(true);
    const [colors, setColors] = useState<ColorVersion[]>([]);
    const [systemColors, setSystemColors] = useState<AttributeResponse[]>([]);
    const [defaultColors, setDefaultColors] = useState<string[]>([]);
    const [units, setUnits] = useState<IUnit[]>([]);
    const [extraWarrantyModal, setExtraWarrantyModal] = useState<boolean>(false);
    const [extraWarranties, setExtraWarranties] = useState<ExtraWarrantyResponse[]>([]);
    const [parentId, SetParentId] = useState<string>('');

    const [oldVersions, setOldversions] = useState<ProductVersionResponse[]>([]);
    const [isAccessory, setIsAccessory] = useState<boolean>(false);
    const [showColorModal, setShowColorModal] = useState<boolean>(false);
    const [showInitStore, setShowInitStore] = useState<boolean>(false);
    //Khởi tạo giao diện
    useEffect(() => {

        //Get all data from id
        if (id)
            GetProductById(id, (result: ProductDetail) => {

                console.log(result);
                let productInfor: NewProductViewModel = {
                    productName: result.name,
                    productCode: result.code,
                    productDesc: result.description,
                    categoryId: '',
                    factoryId: '',
                    sku: result.code,
                    unitId: "",
                    shortDescription: result.shortDescription,
                    warrantyDays: result.defaultWarrantyDays / 30,
                    description: result.description,
                    productType: result.productType,
                    FreeMaintenanceTimes: result.freeMaintenanceTimes,
                    warningThreshold: result.warningThreshold,
                    vat: result.vat,
                    importAndExportTax: result.importAndExportTax,
                    salePrice: result.salePrice,
                    inputPrice: 0, //Cái này sẽ được gán khi đọc các version
                    saleOffPrice: 0,
                    renewInDays: result.renewInDays
                }

                //Cần kiểm tra việc bị thiếu dữ liệu
                if (result.category)
                    productInfor.categoryId = result.category.id;
                if (result.factory)
                    productInfor.factoryId = result.factory.id;
                if (result.unit)
                    productInfor.unitId = result.unit.id;
                //Nêu result.productType === 1 thì là phụ kiện
                setIsAccessory(result.productType === ProductTypes.Accessory);

                setExtraWarranties(result.extraWarranties);

                GetCategories((data: IListItems<Category>) => {
                    setCategories(data.items);
                });
                GetFactories((data: IListItems<Factory>) => {
                    setFactories(data.items);
                });

                GetUnitsApi((data: IListItems<IUnit>) => {
                    setUnits(data.items);
                    if (data.items.length > 0)
                        form.setFieldsValue({ unitId: data.items[0].id });
                });

                //Check version
                if (result.productVersions.length > 0) {

                    setOldversions(result.productVersions);
                    GetColorsApi((res: IListItems<AttributeResponse>) => {
                        setSystemColors(res.items);
                    })

                    //Set default colors
                    let defaultValues: string[] = [];
                    //Set version
                    let productColor: ColorVersion[] = [];
                    let priceSale = 0;
                    let priceInput = 0;
                    result.productVersions.map(ver => {
                        priceSale = ver.salePrice;
                        priceInput = ver.priceInput;
                        if (ver.color) {
                            defaultValues.push(ver.color.id);
                            productColor.push({
                                name: ver.name,
                                colorId: ver.color.id,
                                color: ver.color.code
                            });
                        }
                    });

                    productInfor.salePrice = priceSale;
                    productInfor.inputPrice = priceInput;
                    productInfor.saleOffPrice = result.productVersions[0].saleOffPrice;

                    //if (productColor.length > 0) {
                    setHasColor(true);
                    setDefaultColors(defaultValues);
                    setColors(productColor);
                    //}

                }
                // else {
                //     setHasColor(false);
                // }


                form.setFieldsValue(productInfor);

            })


    }, []);

    const openExtraWarrantyModal = () => {
        setExtraWarrantyModal(true);
    }


    const handleFormSubmit = () => {
        form.validateFields()
            .then((data: NewProductViewModel) => {
                if (id) {

                    console.log(data);



                    if (!data.productName || data.productName.length === 0) {
                        message.info("Tên sản phẩm không được để trống");
                        return;
                    }

                    if (!data.productCode || data.productCode.length === 0) {
                        message.info("Mã sản phẩm không được để trống");
                        return;
                    }

                    //Nếu không phải phụ kiện thì cần kiểm tra thêm màu sắc
                    if (isAccessory === false && colors.length === 0) {
                        message.info("Sản phẩm chính cần có màu sắc");
                        return;
                    }

                    const productData: UpdateProductReq = {

                        name: data.productName,
                        shortDescription: data.shortDescription,
                        code: data.productCode,
                        defaultWarrantyDays: data.warrantyDays * 30,
                        WarningThreshold: 10,
                        FreeMaintenanceTimes: data.FreeMaintenanceTimes,
                        sku: data.productCode,
                        isAvailable: true,
                        UpdateReason: 'chỉnh sửa thông tin',
                        factoryId: data.factoryId,
                        vat: data.vat,
                        productType: data.productType,
                        unitId: data.unitId,
                        importAndExportTax: data.importAndExportTax,
                        renewInDays: data.renewInDays
                    }


                    //Chỉ tạo mới những version khác với cũ. Vì nguyên tắc không được xóa cũ
                    // let colorVers: ColorVersion[] = [];
                    // for (let index = 0; index < colors.length; index++) {
                    //     const element = colors[index];
                    //     if (oldVersions.length == 0 || oldVersions.findIndex(x => x.color?.id == element.colorId) == -1)
                    //         colorVers.push(element);
                    // }
                    // console.log(colorVers);
                    UpdateProductApi(id, productData, (dataRes: ProductResponse) => {

                        let deletedVersions: ProductVersionResponse[] = [];
                        let updateVersions: ProductVersionResponse[] = [];
                        let newVersions: ColorVersion[] = [];
                        console.log(oldVersions);
                        if (colors.length > 0 && hasColor) {

                            //Tìm những cái thêm mới và được update
                            colors.forEach(colorVer => {

                                const idx = oldVersions.findIndex(y => y.color?.id == colorVer.colorId);
                                if (idx != -1) {
                                    updateVersions.push(oldVersions[idx]);
                                } else {
                                    newVersions.push(colorVer);
                                }
                            })

                            //Tìm những cái  bị xóa
                            oldVersions.forEach(oldver => {
                                if (colors.findIndex(y => y.colorId == oldver.color?.id) === -1) {
                                    if (oldver.color)
                                        deletedVersions.push(oldver);
                                    else
                                        updateVersions.push(oldver);
                                }
                            })

                            // Push những cái mới vào
                            const newProductVersionsModels = newVersions.map(x => ({
                                name: x.name,
                                remainQuantities: 0,
                                salePrice: data.salePrice,
                                priceInput: data.inputPrice,
                                colorId: x.colorId,
                                isAvailable: true
                            } as NewProductVersionMoldel));

                            const versionData: NewProductVersionData = {
                                productId: dataRes.id,
                                productVersionDetails: newProductVersionsModels
                            }

                            //message.info(JSON.stringify(versionData), 5000);
                            if (versionData.productVersionDetails.length > 0) {
                                CreateProductVersionsApi(versionData, (res: any) => {

                                });
                            };
                        }
                        else {
                            if (oldVersions.length > 0) {

                                //Cần phải giữ lại mặc định
                                oldVersions.forEach(oldver => {
                                    if (oldver.color)
                                        deletedVersions.push(oldver);
                                    else
                                        updateVersions.push(oldver);
                                })
                            }

                        }


                        const verDetails: ProductVersionUpdateInformationDetailCommand[] = [];
                        updateVersions.forEach(ver => {
                            verDetails.push({
                                id: ver?.id ?? null,
                                name: ver.name ?? '',
                                colorId: ver.color?.id ?? null,
                                materialId: null,
                                sizeId: null,
                                salePrice: data.salePrice,
                                priceInput: data.inputPrice,
                                saleOffPrice: data.saleOffPrice
                            });
                        })

                        deletedVersions.forEach(ver => {
                            verDetails.push({
                                id: ver?.id ?? null,
                                name: ver.name ?? '',
                                colorId: ver.color?.id ?? null,
                                materialId: null,
                                sizeId: null,
                                salePrice: data.salePrice,
                                priceInput: data.inputPrice,
                                isAvailable: false
                            });
                        })

                        const dataRequest: UpdateProductVersionsCommand = {

                            details: verDetails
                        };

                        dispatch(updateProductVersions(dataRequest));

                        message.info("Cập nhật sản phẩm thành công");

                    });
                };

            });
    }

    const onChange = (data: UploadChangeParam) => {
        data.fileList.map(x => x.response = "");
        setFileList(data.fileList);
    };

    //Return false to prevent upload to server
    const onBeforUpload = (file: RcFile, FileList: RcFile[]) => {
        return false;
    }

    //Thay đổi tên của từng version
    const changeVersionName = (e: React.FormEvent<HTMLInputElement>) => {

        let newVersions = [...colors];
        const oldValue = e.currentTarget.defaultValue;
        const newValue = e.currentTarget.value;


        let nIndex = newVersions.findIndex(x => x.name === oldValue);
        const item = newVersions[nIndex];

        newVersions.splice(nIndex, 1, { name: newValue, color: item.color, colorId: item.colorId });
        setColors(newVersions);

    }

    function changeVersionColor(value: string[]) {
        let newVersions: ColorVersion[] = [];
        value.map(x => {
            let index = systemColors.findIndex(y => y.id === x);
            let attrib = systemColors[index];
            index = colors.findIndex(z => z.colorId === x);
            let color = colors[index];
            if (color) {
                newVersions.push({
                    name: color.name, color: color.color, colorId: color.colorId
                })
            }
            else {
                newVersions.push({
                    name: attrib.name, color: attrib.code, colorId: attrib.id
                })
            }
        })

        setColors(newVersions);
    }

    const handleRemoveColorVersion = (prod: ColorVersion) => {
        return () => {
            const newVersions = [...colors];
            const findIndex = newVersions.findIndex(cart => cart.name === prod.name);
            if (findIndex !== -1) {
                newVersions.splice(findIndex, 1);
                setColors(newVersions);
            }

        }
    };

    const onChangeColorStatus = (checked: boolean, event: MouseEvent) => {

        if (checked) {
            GetColorsApi((res: IListItems<AttributeResponse>) => {
                setSystemColors(res.items);
            })

        }

        setHasColor(checked);
    }

    const AddNewColorHandle = () => {
        setShowColorModal(true);
    }

    //Sau khi bật modal và tạo xong thì thêm vào danh sách
    const AfterCreateColor = (attribute: AttributeResponse) => {
        if (attribute) {
            let newsystemColor: AttributeResponse[] = [...systemColors];
            newsystemColor.push(attribute);
            setSystemColors(newsystemColor);

            setShowColorModal(false);
        }
    }

    const colorColumns: ColumnsType<ColorVersion> = [
        {
            title: 'Tên phiên bản',
            dataIndex: 'name',
            key: 'name',
            width: 150,
            align: "left",
            render: (name) => (
                <Input defaultValue={name} onChange={changeVersionName} />
            )
        },
        {
            title: 'Màu sắc',
            dataIndex: 'color',
            key: 'color',
            align: "left",
            render: (color) => (
                <div style={{ width: 20, height: 20, borderRadius: 5, backgroundColor: `${color}` }} />

            )
        },
        {
            title: '',
            key: 'action',
            render: (record) => (
                <Space size="middle" align="center" >
                    <DeleteOutlined style={{ color: 'red', marginTop: 5 }} onClick={handleRemoveColorVersion(record)} />
                </Space>
            ),
        },
    ];

    return (
        <div>
            <div style={{ margin: 10 }}>
                <div style={{ display: "flex", height: 50, top: 25 }}>
                    <Link to="/admin/products">
                        <LeftOutlined /> Quay lại danh sách sản phẩm
                    </Link>
                    <div style={{ flexGrow: 1, textAlign: "end" }}>
                        {/* <Button type="default" style={{ color: '#0088FF', fontWeight: 500, marginRight: 10 }}
                            href="/admin/products" size="large">
                            Thoát
                        </Button> */}
                        {/* <Button type="default" style={{ color: '#FF4D4D', fontWeight: 500, marginLeft: 10, marginRight: 10 }}
                            onClick={handleFormSubmit} size="large">
                            Xóa
                        </Button> */}
                        <Button type="primary" icon={<SaveOutlined />} style={{ fontWeight: 500 }}
                            onClick={handleFormSubmit} size="large">
                            Lưu
                        </Button>
                    </div>
                </div>
            </div>

            <Form form={form}
                layout="vertical">
                <Row >
                    <Col span={16}>
                        <Card title="Thông tin sản phẩm">
                            <Form.Item name="productName" label="Tên sản phẩm" required tooltip="Tên sản phẩm không bao gồm các giá trị thuộc tính như màu sắc, chất liệu">
                                <Input placeholder="Nhập tên sản phẩm" />
                            </Form.Item>
                            <Row>
                                <Col span={11}>
                                    <Form.Item name="productCode" label="Mã sản phẩm" required tooltip="Tên sản phẩm không bao gồm các giá trị thuộc tính như màu sắc, chất liệu">
                                        <Input placeholder="Nhập mã sản phẩm" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>

                                <Col span={11} >
                                    <Form.Item label="Chọn nhóm sản phẩm" name="categoryId">
                                        <CCombobox chooseOptions={categories} displayName="name" id="id" placeholder="Chọn nhóm sản phẩm" />
                                    </Form.Item>
                                </Col>


                                <Col span={1} />
                                <Col span={12} >
                                    <Form.Item name="unitId" label="Đơn vị sản phẩm" >
                                        <CCombobox chooseOptions={units} displayName="name" id="id" placeholder="Chọn đơn vị" extraContent={
                                            <div>
                                                {/* <Divider style={{ margin: '4px 0' }} />
                                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                    <a onClick={AddNewUnitsHandle}
                                                        style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}>
                                                        <PlusOutlined />
                                                        <span>Thêm đơn vị</span>
                                                    </a>
                                                </div> */}
                                            </div>

                                        } />
                                    </Form.Item>
                                </Col>

                            </Row>

                            <Row>
                                <Col span={6} style={{ paddingRight: 5 }}>
                                    <Form.Item label="Giá nhập" name="inputPrice" >
                                        <InputMoney />
                                    </Form.Item>
                                </Col>

                                <Col span={6} style={{ paddingLeft: 5, paddingRight: 30 }}>
                                    <Form.Item label="Giá bán" name="salePrice">
                                        <InputMoney />
                                    </Form.Item>
                                </Col>

                                <Col span={6} >
                                    <Form.Item label="Thuế GTGT" name="vat">
                                        <InputPercent />
                                    </Form.Item>
                                </Col>

                                <Col span={6} style={{ paddingLeft: 5 }}>
                                    <Form.Item label="Thuế XNK" name="importAndExportTax">
                                        <InputPercent />
                                    </Form.Item>
                                </Col>

                            </Row>

                            <Row>
                                <Col span={6} style={{ paddingRight: 5 }}>
                                    <Form.Item label="Giá Khuyến mại" name="saleOffPrice">
                                        <InputMoney />
                                    </Form.Item>
                                </Col>
                            </Row>

                        </Card>
                        <Button onClick={() => setShowInitStore(true)}>Khởi tạo kho</Button>
                    </Col>
                    <Col span={8} style={{ paddingLeft: 10 }}>
                        <Card title="Thông tin khác" >


                            <Form.Item label="Chọn nhà sản xuất" name="factoryId"
                                tooltip={{
                                    title: 'Chọn nhà sản xuất cho sản phẩm',
                                    icon: <InfoCircleOutlined />
                                }}>

                                <CCombobox chooseOptions={factories} displayName="name" id="id" placeholder="Chọn nhà sản xuất" />
                            </Form.Item>

                            <Form.Item name="warrantyDays" label="Thời gian bảo hành(tháng)" >
                                <Input placeholder="Nhập số tháng" />
                            </Form.Item>

                            <Form.Item name="renewInDays" label="Thời gian đổi mới(ngày)" >
                                <Input placeholder="Nhập số ngày" />
                            </Form.Item>

                            <Button type="primary" hidden={isAccessory} onClick={openExtraWarrantyModal} icon={<ProfileFilled />} size="small">
                                Gói BH mở rộng
                            </Button>

                            <Form.Item name="FreeMaintenanceTimes" label="Số lần bảo trì miễn phí" >
                                <Input placeholder="Nhập số lần bảo trì" />
                            </Form.Item>

                            {/* <Space direction="horizontal">
                                <Button type="primary" onClick={openAccessoriesModal} icon={<ProfileFilled />} size="small">
                                    Danh sách phụ kiện
                                </Button>
                                <a href={`create_accessory/${id}`}>
                                    <PlusOutlined style={{ color: '#40A9FF', padding: 5 }} />
                                </a>

                            </Space> */}

                            {/* <Form.Item label={<LabelColor checked={hasColor} onChange={onChangeColorStatus} />} requiredMark={false}> */}
                            <Form.Item>
                                {hasColor ? (
                                    <div>
                                        <Table columns={colorColumns} size="small"
                                            dataSource={colors} pagination={false} />
                                        <Select mode='multiple'
                                            defaultValue={defaultColors}
                                            onChange={changeVersionColor}
                                            dropdownRender={menu => (
                                                <div>
                                                    {menu}
                                                    {<div>
                                                        <Divider style={{ margin: '4px 0' }} />
                                                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                            <a onClick={AddNewColorHandle}
                                                                style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}>
                                                                <PlusOutlined />
                                                                <span>Thêm định nghĩa màu</span>
                                                            </a>
                                                        </div>
                                                    </div>}
                                                </div>
                                            )}>
                                            {systemColors.map(item => (
                                                <Option value={item.id} key={item.id} > {item.name}</Option>
                                            ))}
                                        </Select>
                                    </div>) : ('không phân loại theo màu')}
                            </Form.Item>

                        </Card>
                    </Col>
                </Row>

            </Form>


            <ExtraWarrantyModal title="Danh sách gói mở rộng" centered
                visible={extraWarrantyModal}
                warranties={extraWarranties}
                onCancel={() => setExtraWarrantyModal(false)}
                productId={id} />

            <ColorVersionModal visible={showColorModal}
                title="Tạo màu phiên bản"
                okModalFnc={AfterCreateColor}
                onCancel={() => setShowColorModal(false)} />

            <InitStorageModal visible={showInitStore}
                productVersions={oldVersions}
                title="Khởi tạo kho"
                okModalFnc={() => setShowInitStore(false)}
                onCancel={() => setShowInitStore(false)} />
        </div>

    )

}