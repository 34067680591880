import { AxiosResponse } from 'axios';
import { ProductTypes } from '../common/type-define';

import { NewProductVersionData, UpdateProductReq, UpdateProductVersionsCommand } from '../Model/ProductModel';
import { AxiosRequest } from "./Axios/AxiosRequest";
import { RequestHandler } from './RequestAPI';
import { SERVER_API } from './UrlDefination';


export class ProductApi {
    static async getProducts(productType: ProductTypes | null = null) {
        const queryParams = productType !== null ? `?ProductTypes=${productType}` : '';
        return RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.PRODUCT.GET}${queryParams}`));
    }

    static async updateProductVersions(data: UpdateProductVersionsCommand) {
        return RequestHandler.handleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.PRODUCT.UPDATE_VERS}`, data));
    }

    static async getProductVersionById(id: string): Promise<AxiosResponse<ProductVersionResponse, any>> {
        return RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.PRODUCT.GET_VERSION_BY_ID(id)}`));
    }

    static async getAccessoriesByIds(ids: string[]): Promise<AxiosResponse<IListItems<ProductResponse>, any>> {
        const queryParams = ids.map(id => `ParentIds=${id}`).join('&');
        return RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.PRODUCT.GET_ACCESSORIES}?${queryParams}`));
    }

    static getProductImageUrl(relUrl: string): string {
        return `${SERVER_API.HOST}/images/${relUrl}`;
    }
}
//Tạo ra product Image
export const GetProductImageUrl = (relUrl: string): string => {
    return (`${SERVER_API.HOST}/images/${relUrl}`);
}

// Get all products
export const GetProducts = async (doAfterResponse: Function) => {

    await RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.PRODUCT.GET}`), doAfterResponse);

}

export const GetMainProductsApi = async (doAfterResponse: Function) => {

    await RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.PRODUCT.GET}/?ProductTypes=0`), doAfterResponse);

}

export const GetProductById = async (id: string, doAfterResponse: Function) => {

    await RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.PRODUCT.GET_BY_ID}?Id=${id}`), doAfterResponse);

}

export const GetAccessorisByIds = async (ids: string[], doAfterResponse: Function) => {
    const url: string = `${SERVER_API.HOST}/${SERVER_API.PRODUCT.GET_ACCESSORIES}?${ids.map((n, index) => `ParentIds[${index}]=${n}`).join('&')}`;
    await RequestHandler.handleRequest(AxiosRequest.get(url), doAfterResponse);

}

//Create new product. Need to error handling
export const CreateProductApi = async (data: ProductData, doAfterResponse: Function) => {

    await RequestHandler.handleRequest(AxiosRequest.post(`${SERVER_API.HOST}/${SERVER_API.PRODUCT.CREATE}`, data), doAfterResponse);

}

export const UpdateProductApi = async (id: string, data: UpdateProductReq, doAfterResponse: Function) => {

    await RequestHandler.handleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.PRODUCT.UPDATE}/${id}`, data), doAfterResponse);

}

// Xóa sản phẩm
export const RemoveProductApi = async (id: string, doAfterResponse: Function) => {
    const reqData = { isRemoved: true, updateReason: "Xóa sản phẩm" };
    await RequestHandler.handleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.PRODUCT.UPDATE}/${id}`, reqData), doAfterResponse);

}



export const AddImagesToProductApi = async (data: { productId: string, imageIds: string[] }, doAfterResponse: Function) => {

    await RequestHandler.handleRequest(AxiosRequest.post(`${SERVER_API.HOST}/${SERVER_API.PRODUCT.ADD_IMAGES}`, data), doAfterResponse);

}

export const GetProductVersionsApi = async (doAfterResponse: Function) => {

    await RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.PRODUCT.GET_VERS}`), doAfterResponse);

}


export const CreateProductVersionsApi = async (data: NewProductVersionData, doAfterResponse: Function) => {

    await RequestHandler.handleRequest(AxiosRequest.post(`${SERVER_API.HOST}/${SERVER_API.PRODUCT.CREATE_VERS}`, data), doAfterResponse);

}

export const UpdateProductVersionByIdApi = async (id: string, data: any, doAfterResponse: Function) => {

    await RequestHandler.handleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.PRODUCT.UPDATE_VER_BY_ID(id)}`, data), doAfterResponse);

}

export const UpdateProductVersionsApi = async (data: any, doAfterResponse: Function) => {

    await RequestHandler.handleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.PRODUCT.UPDATE_VERS}`, data), doAfterResponse);

}

export const AssignExtraWarrantiesApi = async (data: any, doAfterResponse: Function) => {

    await RequestHandler.handleRequest(AxiosRequest.post(`${SERVER_API.HOST}/${SERVER_API.PRODUCT.ASSIGN_EXTRA_WARRANTY}`, data), doAfterResponse);

}








