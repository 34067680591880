
import { Button, DatePicker, Space, Table, Tag } from "antd";
import { LeftRightHeaderContent } from "../../Controls/LeftRightHeaderContent";
import {
    ArrowLeftOutlined,
    SearchOutlined, VerticalAlignBottomOutlined
} from '@ant-design/icons';

import { format, subMonths } from 'date-fns';
import ConceptModal, { conceptItem } from "../../Helper/ConceptModal";
import { useEffect, useState } from "react";
import moment, { Moment } from "moment";
import { BillRespone } from "../../../Model/BillModel";
import { ColumnsType } from "antd/lib/table";
import { BillApi } from "../../../Api/BillsApi";
import { MathHelper } from "../../../Helper/math.helper";
import { ChoosFilter, FilterControls } from "../../Controls/FilterControls";
import { headerStyle } from "../../Helper/UIHelper";
const { RangePicker } = DatePicker;

export default function VouchersReportView() {
    const [showConcept, setShowConcept] = useState<boolean>(false);
    const [bills, setBills] = useState<BillRespone[]>([]);
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState<Date>(subMonths(new Date(), 1));
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [filters, setFilters] = useState<FilterItem[]>([]);

    async function fetchData() {
        const res = await BillApi.GetBillsApi(startDate, endDate);

        if (res?.status === 200) {
            setBills(res.data.items);
            setLoading(false);
        }
        else {
        }

    }

    useEffect(() => {
        setLoading(true);

        fetchData();
    }, [])

    const columns: ColumnsType<BillRespone> = [
        {
            title: 'STT',
            dataIndex: 'name',
            key: 'name',
            onHeaderCell: () => headerStyle(),
            render: (val, record, index) => <span>{index + 1}</span>
        },
        {
            title: 'Thời gian',
            dataIndex: 'createdTime',
            key: 'createdTime',
            onHeaderCell: () => headerStyle(),
            render: (createdTime) => (
                format(new Date(createdTime), "dd-MM-yyyy")
            )
        },
        {
            title: 'Loại phiếu',
            dataIndex: 'billingType',
            key: 'billingType',
            onHeaderCell: () => headerStyle(),
            render: (billingType) => (
                billingType == 0 ? <Tag color="green"> Phiếu thu</Tag> : <Tag color="red"> Phiếu chi</Tag>
            )
        },
        {
            title: 'Mã phiếu',
            dataIndex: 'code',
            key: 'code',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Hình thức thanh toán',
            dataIndex: 'paymentType',
            key: 'paymentType',
            onHeaderCell: () => headerStyle(),
            render: (paymentType) => (
                paymentType === 0 ? <span color="green"> Chuyển khoản</span> : <span color="red"> Tiền mặt</span>
            )
        },
        {
            title: 'Số tiền',
            dataIndex: 'amount',
            key: 'amount',
            onHeaderCell: () => headerStyle(),
            render: (amount) => (
                MathHelper.formatPriceVND(amount)
            )
        },
        {
            title: 'Diễn giải',
            dataIndex: 'reason',
            key: 'reason',
            onHeaderCell: () => headerStyle(),
        },
    ]

    function changeRangeTime(values: any, formatString: [string, string]) {
        const startTime: Moment = values[0];
        const endTime: Moment = values[1];

        setStartDate(startTime.toDate());
        setEndDate(endTime.toDate());
        fetchData();
    }


    function onChangeFilter() {

    }

    return (
        <div>
            <Space direction='horizontal'>
                <RangePicker format='DD/MM/YYYY'
                    clearIcon={false}
                    style={{ width: '220px' }}
                    onChange={changeRangeTime}
                    defaultValue={[moment(subMonths(new Date(), 1)), moment()]} />
                <ChoosFilter filterList={filters} onChangeFunc={(items: FilterItem[]) => setFilters(items)} />

            </Space>
            <FilterControls items={filters} afterChangeFnc={onChangeFilter} />

            <Table
                loading={loading}
                columns={columns}
                dataSource={bills}
                size="small" bordered

            />

        </div>
    )
}