import { Button, DatePicker, message, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { addDays, subDays, format } from "date-fns";
import { differenceInDays } from "date-fns/esm";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { SaleReportApi } from "../../../Api/Reports/SaleReportApi";

import {
    ArrowLeftOutlined
} from '@ant-design/icons';
import { MathHelper } from "../../../Helper/math.helper";
import { filterTypes, OrderHistoryStatus, OrderStatus } from "../../../common/type-define";
import { ChoosFilter, FilterControls } from "../../Controls/FilterControls";
import { headerStyle } from "../../Helper/UIHelper";
const { RangePicker } = DatePicker;

export default function ProcessingReport() {

    const [data, setData] = useState<IOderProcess[]>([]);
    const [filters, setFilters] = useState<FilterItem[]>([]);

    useEffect(() => {
        fetchData(subDays(new Date(), 7), new Date())
        setFilters([
            { type: filterTypes.WAREHOUSE, closeable: true },
            { type: filterTypes.STAFF, closeable: true },
            { type: filterTypes.CUSTOMER, closeable: true }]);
    }, [])

    function fetchData(startDate: Date, endDate: Date) {
        const query = {
            QueryFrom: startDate,
            QueryTo: endDate
        }
        let re: IOderProcess[] = [];
        SaleReportApi.GetOrderReportsApi(query).then((Orders) => {
            const orders: IOrderReport[] = Orders.data;

            let timeInMilisec: number = endDate.getTime() - startDate.getTime();
            let days: number = Math.ceil(timeInMilisec / (1000 * 60 * 60 * 24));

            for (let index = 0; index <= days; index++) {
                console.log(index);
                let totalOrder = 0;
                let totalRemain = 0;
                let totalCancel = 0;
                let totalCompletedOrder = 0;
                let processedMoney: number = 0;
                let totalMoney: number = 0;
                let remainMoney: number = 0;
                let checkTime: Date = new Date(addDays(startDate, index));
                let formatCheckTime = format(checkTime, "dd-MM-yyyy")
                orders.forEach(order => {
                    let isRemain: boolean = false;
                    let isCreatedInday: boolean = false;
                    let isCompleteInday: boolean = false;
                    let isCancelledInday: boolean = false;

                    // Nếu là đơn chưa được giao thì tức là còn tồn đọng
                    // Cái tồn đọng có thể từ lâu rồi
                    // Tuy nhiên cũng cần biết số đơn mới trong ngày hôm đấy

                    if (order.orderStatus === OrderStatus.Pending ||
                        order.orderStatus === OrderStatus.Packaging ||
                        order.orderStatus === OrderStatus.Processing) {
                        if (format(new Date(order.orderTime), "dd-MM-yyyy") <= formatCheckTime) {
                            isRemain = true;
                        }
                    }
                    else if (order.orderStatus === OrderStatus.Completed ||
                        order.orderStatus === OrderStatus.ToDeliver ||
                        order.orderStatus === OrderStatus.Activated) {
                        let deliverOrderHis = order.orderHistories.find(
                            p => p.statusCode === OrderHistoryStatus.ToDeliver);
                        if (deliverOrderHis && format(new Date(order.orderTime), "dd-MM-yyyy") <= formatCheckTime &&
                            !(format(new Date(deliverOrderHis.createdTime), "dd-MM-yyyy") <= formatCheckTime))
                            isRemain = true;
                    }





                    //Kiểm tra các đơn trong ngày và hoàn thành trong ngày
                    order.orderHistories.forEach(his => {
                        let formatCreatedTime = format(new Date(his.createdTime), "dd-MM-yyyy");

                        if (formatCreatedTime === formatCheckTime) {

                            //Được hoàn thành trong ngày
                            if (his.statusCode === OrderHistoryStatus.Completed
                                || his.statusCode === OrderHistoryStatus.ToDeliver
                                || his.statusCode === OrderHistoryStatus.Activated) {
                                isCompleteInday = true;
                            }

                            if (his.statusCode === OrderHistoryStatus.Cancelled) {
                                isCancelledInday = true;
                            }

                            // Được tạo trong ngày
                            if (his.statusCode === OrderStatus.Pending) {
                                isCreatedInday = true;
                            }

                        }
                    })


                    if (isRemain) {
                        totalRemain++
                        remainMoney += calculateTotalPriceInOrder(order.productOrders)
                    }

                    if (isCancelledInday) {
                        totalCancel++;
                    }

                    if (isCreatedInday) {
                        totalOrder++
                        totalMoney += calculateTotalPriceInOrder(order.productOrders)
                    }

                    if (isCompleteInday) {
                        totalCompletedOrder++
                        processedMoney += calculateTotalPriceInOrder(order.productOrders)
                    }


                })

                let dayTemp: IOderProcess = {
                    reviewDate: checkTime,
                    processedMoney: processedMoney,
                    processedOrder: totalCompletedOrder,
                    remainMoney: remainMoney,
                    remainOrder: totalRemain,
                    totalMoney: totalMoney,
                    totalOrder: totalOrder,
                    cancelOrder: totalCancel
                }

                re.push(dayTemp);
            }

            setData(re);
        })
    }

    function calculateTotalPriceInOrder(productOrders: IProductOrder[]) {
        return productOrders.reduce((pre, curr) => {
            return (pre + curr.salePrice)
        }, 0);
    }


    function onChangeFilter() {

    }


    const columns: ColumnsType<IOderProcess> = [
        {
            title: 'Ngày tháng',
            dataIndex: 'reviewDate',
            key: 'reviewDate',
            onHeaderCell: () => headerStyle(),
            render: (reviewDate) => (
                format(new Date(reviewDate), "dd-MM-yyyy")
            )
        },
        {
            title: 'SL Đơn',
            dataIndex: 'totalOrder',
            key: 'totalOrder',
            align: 'center',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: (<span>SL đơn<br /> Đã giao</span>),
            dataIndex: 'processedOrder',
            key: 'processedOrder',
            align: 'center',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: (<span>SL đơn<br /> còn lại</span>),
            dataIndex: 'remainOrder',
            key: 'remainOrder',
            align: 'center',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'SL đơn hủy',
            dataIndex: 'cancelOrder',
            key: 'cancelOrder',
            align: 'center',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: (<span>Doanh số<br /> đặt hàng</span>),
            dataIndex: 'totalMoney',
            key: 'totalMoney',
            onHeaderCell: () => headerStyle(),
            render: (totalMoney) => (
                MathHelper.formatPriceVND(totalMoney, true)
            )
        },
        {
            title: (<span>Doanh số<br /> đã thực hiên</span>),
            dataIndex: 'processedMoney',
            key: 'processedMoney',
            onHeaderCell: () => headerStyle(),
            render: (processedMoney) => (
                MathHelper.formatPriceVND(processedMoney, true)
            )
        },
        {
            title: (<span>Doanh số<br /> chưa thực hiên</span>),
            dataIndex: 'remainMoney',
            key: 'remainMoney',
            onHeaderCell: () => headerStyle(),
            render: (remainMoney) => (
                MathHelper.formatPriceVND(remainMoney, true)
            )
        }
    ]

    function changeRangeTime(values: any, formatString: [string, string]) {
        const startTime: Moment = values[0];
        const endTime: Moment = values[1];
        fetchData(startTime.toDate(), endTime.toDate());
    }

    return (
        <div>
            <Space direction='horizontal'>
                <Button type="default" icon={<ArrowLeftOutlined />} href="/admin/report/sale"></Button>
                <RangePicker format='DD/MM/YYYY'
                    onChange={changeRangeTime}
                    clearIcon={false}
                    style={{ width: '220px' }}
                    defaultValue={[moment(subDays(new Date(), 7)), moment()]} />

                <ChoosFilter filterList={filters} onChangeFunc={(items: FilterItem[]) => setFilters(items)} />

            </Space>
            <FilterControls items={filters} afterChangeFnc={onChangeFilter} />
            <Table columns={columns} size="small" bordered
                dataSource={data}
                pagination={false}
            />
        </div>
    )
}