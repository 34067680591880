import { message } from "antd"

export interface ErrorData {
    code: string;
    messages: string[]
}

//Kiểm soát tất cả các lỗi liên quan đến request
export const ErrorHandleFunc = (error: any) => {

    console.log(JSON.stringify(error));
    if (error.response) {
        if (error.response.status === 400) {
            let errorData: ErrorData = error.response.data;
            if (errorData && typeof errorData == 'object')
                message.error(errorData.messages);
            else {
                if (error.response.data)
                    message.error(error.response.data);
                else
                    message.error(error.message);
            }

        }

        else if (error.response.status === 500) {
            message.error("Lỗi 500: Lỗi lấy dữ liệu");
            //message.error(error.message);
            //message.error(error.response.config.url);

        }
        else if (error.response.status === 401) {
            message.error("Lỗi 401: Lỗi xác thực vào hệ thống");
        }
        else if (error.response.status === 404) {
            message.error("Lỗi 404: Kết nối đến server có vấn đề");
        }
        else if (error.response.status === 403) {
            message.error("Lỗi 403: Tài khoản của bạn không được phép truy cập chức năng này");
        }

    }
    else {
        if (error.message)
            message.error(error.message);
        else
            message.error(JSON.stringify(error));
    }



}