import { AxiosRequest } from "./Axios/AxiosRequest";

import { HandleRequest } from "./RequestAPI";
import { SERVER_API } from "./UrlDefination";

export const GetSaleChannelsApi = async (doAfterResponse: Function) => {

    await HandleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.SALE_CHANNEL.GET}`), doAfterResponse);

}

//Create new product. Need to error handling
export const CreateSaleChannelApi = async (data: any, doAfterResponse: Function) => {

    await HandleRequest(AxiosRequest.post(`${SERVER_API.HOST}/${SERVER_API.SALE_CHANNEL.CREATE}`, data), doAfterResponse);

}