import { Modal, ModalProps, Form, Input, message, Row, Col } from "antd";
import { useEffect, useState } from "react";
import { GetCities, GetDistricts } from "../../Api/AddressApi";
import { CreateWareHouseApi, UpdateWareHouseApi } from "../../Api/WarehouseApi";
import CCombobox from "../Controls/CCombobox";


type FormData = {
    branchName: string;
    representativePerson: string;
    hotline: string;
    branchAdress: string;
    description: string;
    cityId: string;
    districtId: string
}

type BranchModalProps = ModalProps &
{
    branch?: WareHouseResponse;
    okModalFunc: Function,
}

export function BranchModal(props: BranchModalProps) {

    const { branch, okModalFunc, ...rest } = props;

    const [cities, setCities] = useState<CityResponse[]>([]);
    const [districts, setDistricts] = useState<DistrictResponse[]>([]);
    const [branchForm] = Form.useForm<FormData>();

    useEffect(() => {
        if (rest.visible) {
            GetCities((data: IListItems<CityResponse>) => { setCities(data.items); });

            if (branch) { //trong trường hợp edit
                let cityId: string = "";
                if (branch.city) {
                    cityId = branch.city.id;
                    cityChange(cityId);
                }

                let districtId: string = "";
                if (branch.district) {
                    districtId = branch.district.id
                }
                branchForm.setFieldsValue({
                    branchName: branch.name,
                    hotline: branch.hotline,
                    branchAdress: branch.location,
                    representativePerson: branch.representativePerson,
                    cityId: cityId,
                    districtId: districtId
                });
            }
        }

    }, [rest.visible])

    function cityChange(value: any) {
        GetDistricts(value, (distrs: DistrictResponse[]) => {
            setDistricts(distrs);
        })
    }

    const handleOk = () => {
        branchForm.validateFields().then((data: FormData) => {

            const requestData: WareHouseRequest = {
                name: data.branchName,
                hotline: data.hotline,
                location: data.branchAdress,
                description: "",
                representativePerson: data.representativePerson,
                cityId: data.cityId,
                districtId: data.districtId,
                isAvailable: true,

            }
            if (branch == null) {
                CreateWareHouseApi(requestData, (res: WareHouseResponse) => {
                    message.info("Chi nhánh được tạo thành công");
                    okModalFunc(res);
                })
            } else {
                UpdateWareHouseApi(branch.id, requestData, (res: WareHouseResponse) => {

                    message.info("Chi nhánh được tạo cập nhật");
                    const city = cities.find(x => x.id == requestData.cityId);
                    if (city)
                        res.city = city;
                    const district = districts.find(x => x.id == requestData.districtId);
                    if (district)
                        res.district = district;
                    okModalFunc(res);
                })
            }

        })


    };


    return (
        <Modal {...rest} centered
            onOk={handleOk}
        >
            <Form layout="vertical"
                form={branchForm}>
                <Form.Item label="Tên chi nhánh" name="branchName">
                    <Input placeholder="Nhập tên chi nhánh" />
                </Form.Item>
                <Row>
                    <Col span={12} style={{ paddingRight: 5 }}>
                        <Form.Item label="Người đại diện" name="representativePerson">
                            <Input placeholder="Nhập tên chi nhánh" />
                        </Form.Item>
                    </Col>
                    <Col span={12} style={{ paddingLeft: 5 }}>
                        <Form.Item label="Số điện thoại" name="hotline">
                            <Input placeholder="Nhập tên chi nhánh" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={12} style={{ paddingRight: 5 }}>
                        <Form.Item label="Tỉnh/Thành phố" name="cityId">
                            <CCombobox chooseOptions={cities} displayName="name" id="id" onChange={cityChange} />
                        </Form.Item>
                    </Col>
                    <Col span={12} style={{ paddingLeft: 5 }}>
                        <Form.Item label="Quận/Huyện" name="districtId">
                            <CCombobox chooseOptions={districts} displayName="name" id="id" />
                        </Form.Item>
                    </Col>
                </Row>



                <Form.Item label="Địa chỉ chi tiết" name="branchAdress">
                    <Input placeholder="Nhập tên chi nhánh" />
                </Form.Item>


            </Form>
        </Modal>
    )
}