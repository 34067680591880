import { Button, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { subMonths } from "date-fns";
import { useEffect, useState } from "react";
import { MathHelper } from "../../../Helper/math.helper";
import { SaleReportApi } from "../../../Api/Reports/SaleReportApi";

import {
    ArrowLeftOutlined
} from '@ant-design/icons';
import { ChoosFilter, FilterControls } from "../../Controls/FilterControls";
import { filterTypes } from "../../../common/type-define";
import { cellStyle, headerStyle } from "../../Helper/UIHelper";
import Link from "antd/lib/typography/Link";
import RangeDateControl from "../../Controls/RangeDateControl";


export default function SummarySaleReport() {

    const [data, setData] = useState<ISaleReport[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [filters, setFilters] = useState<FilterItem[]>([]);

    useEffect(() => {
        fetchData(subMonths(new Date(), 1), new Date());
        setFilters([
            { type: filterTypes.WAREHOUSE, closeable: true },
            { type: filterTypes.AGENCY, closeable: true },
            { type: filterTypes.STAFF, closeable: true },
            { type: filterTypes.CUSTOMER, closeable: true }]);
    }, []);

    function fetchData(fromDate: Date, toDate: Date) {
        let re: ISaleReport[] = [];

        const query = {
            QueryFrom: fromDate,
            QueryTo: toDate
        }
        setLoading(true);
        SaleReportApi.GetOrderReportsApi(query).then((Orders) => {

            SaleReportApi.GetReturnOrderReportsApi(query).then((returnOrderRes) => {
                const orders: IOrderReport[] = Orders.data;

                orders.forEach(order => {

                    let staffId = "";
                    if (order.orderHistories.length > 0) {
                        staffId = order.orderHistories[0].actorId;
                    }
                    const productOrders = order.productOrders;
                    productOrders.forEach(prodOrder => {
                        const temp = re.find(t => t.productId === prodOrder.productCode);
                        if (!temp) {

                            re.push({
                                productId: prodOrder.productCode,
                                productName: prodOrder.productName,
                                productCode: prodOrder.productCode,
                                unit: prodOrder.unit.name,
                                number: 1,
                                numberReturn: 0,
                                reduceMoney: prodOrder.discount,
                                totalMoney: prodOrder.salePrice,
                                totalMoneyReturn: 0,
                                customerId: prodOrder.customer?.id,
                                staffId: staffId
                            })
                        } else {
                            temp.number++;
                            temp.totalMoney += prodOrder.salePrice;
                            temp.reduceMoney += prodOrder.discount;
                        }
                    })
                })

                //Xủ lý hàng trả lại
                if (returnOrderRes.status === 200) {
                    const returnOrders: IOrderReturnReport[] = returnOrderRes.data;
                    returnOrders.forEach(order => {
                        let staffId = "";
                        if (order.order.orderHistories.length > 0) {
                            staffId = order.order.orderHistories[0].actorId;
                        }
                        order.productOrderReturns.forEach(prodReturn => {
                            const existPro = re.find(t => t.productId === prodReturn.productVersionId);

                            if (!existPro) {
                                re.push({
                                    productId: prodReturn.productVersionId,
                                    productName: prodReturn.productVersionName,
                                    productCode: prodReturn.productCode,
                                    unit: prodReturn.unit?.name,
                                    number: 0,
                                    numberReturn: 1,
                                    reduceMoney: 0,
                                    totalMoney: 0,
                                    totalMoneyReturn: prodReturn.refund,
                                    customerId: order.customer?.id,
                                    staffId: staffId
                                })
                            } else {
                                existPro.numberReturn++;
                                existPro.totalMoney += prodReturn.refund;
                            }
                        })
                    })
                }

                setData(re);

            })

        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setLoading(false);
        })


    }

    function changeRangeTime(startTime: Date, endTime: Date) {

        fetchData(startTime, endTime);
    }

    function onChangeFilter() {

    }


    const columns: ColumnsType<ISaleReport> = [
        {
            title: 'Mã hàng',
            dataIndex: 'productCode',
            key: 'code',
            onCell: () => cellStyle(),
            onHeaderCell: () => headerStyle(),
            render: (productCode) => (
                <Link>{productCode}</Link>
            )
        },
        {
            title: 'Tên hàng',
            dataIndex: 'productName',
            key: 'name',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: <span>SL <br /> bán</span>,
            dataIndex: 'number',
            key: 'Imei',
            align: "center",
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'ĐVT',
            dataIndex: 'unit',
            key: 'name',
            align: "center",
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Doanh số bán',
            dataIndex: 'totalMoney',
            key: 'totalMoney',
            onHeaderCell: () => headerStyle(),
            render: (totalMoney) => (
                MathHelper.formatPriceVND(totalMoney, true)
            )
        },
        {
            title: 'Chiết khấu',
            dataIndex: 'reduceMoney',
            key: 'reduceMoney',
            onHeaderCell: () => headerStyle(),
            render: (reduceMoney) => (
                MathHelper.formatPriceVND(reduceMoney, true)
            )
        },
        {
            title: <span>SL <br /> trả lại</span>,
            dataIndex: 'numberReturn',
            key: 'numberReturn',
            align: "center",
            onHeaderCell: () => headerStyle(),
        },
        {
            title: <span>Giá trị <br /> trả lại</span>,
            dataIndex: 'totalMoneyReturn',
            key: 'totalMoneyReturn',
            onHeaderCell: () => headerStyle(),
            render: (totalMoneyReturn) => (
                MathHelper.formatPriceVND(totalMoneyReturn, true)
            )
        },
        {
            title: <span>Giá trị <br /> giảm giá</span>,
            dataIndex: 'Imei',
            key: 'Imei',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Doanh thu',
            dataIndex: 'Imei',
            key: 'Imei',
            onHeaderCell: () => headerStyle(),
            render: (Imei, record) => (
                MathHelper.formatPriceVND(record.totalMoney - record.reduceMoney - record.totalMoneyReturn, true)
            )
        }
    ]
    return (
        <div>
            <Space direction='horizontal'>
                <Button type="default" icon={<ArrowLeftOutlined />} href="/admin/report/sale"></Button>
                <RangeDateControl onChangeDate={changeRangeTime} />
                <ChoosFilter filterList={filters} onChangeFunc={(items: FilterItem[]) => setFilters(items)} />
            </Space>

            <FilterControls items={filters} afterChangeFnc={onChangeFilter} />

            <Table loading={loading}
                onHeaderRow={() => headerStyle()}
                columns={columns}
                dataSource={data}
                size="small"
                bordered
                rowKey={"productId"}
                pagination={false} />

        </div>
    )
}