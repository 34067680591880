import { Button, DatePicker, Space, Table } from "antd";
import { useEffect, useState } from "react";
import { subDays } from "date-fns";
import { SaleReportApi } from "../../../Api/Reports/SaleReportApi";
import { ColumnsType } from "antd/lib/table";
import { format } from 'date-fns';
import moment, { Moment } from "moment";
import {
    ArrowLeftOutlined
} from '@ant-design/icons';
import { MathHelper } from "../../../Helper/math.helper";
import { filterTypes, OrderReturnStatus } from "../../../common/type-define";
import { ChoosFilter, FilterControls } from "../../Controls/FilterControls";
import { headerStyle } from "../../Helper/UIHelper";
const { RangePicker } = DatePicker;

export default function ReturnProductReport() {
    const [data, setData] = useState<IReturnProductReport[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [startDate, setStartDate] = useState<Date>(subDays(new Date(), 7));
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [filters, setFilters] = useState<FilterItem[]>([]);
    useEffect(() => {
        fetchData()
        setFilters([
            { type: filterTypes.WAREHOUSE, closeable: true },
            { type: filterTypes.AGENCY, closeable: true },
            { type: filterTypes.STAFF, closeable: true }]);
    }, []);

    function fetchData() {
        let re: IReturnProductReport[] = [];

        const query = {
            QueryFrom: startDate,
            QueryTo: endDate
        }

        SaleReportApi.GetReturnOrderReportsApi(query).then((returnOrderRes) => {
            if (returnOrderRes.status === 200) {
                const returnOrders: IOrderReturnReport[] = returnOrderRes.data;
                returnOrders.forEach(returnOrder => {
                    returnOrder.productOrderReturns.forEach(p => {
                        if (p.status === OrderReturnStatus.Completed) {
                            let productOrder = returnOrder.order.productOrders.find(productOrder =>
                                productOrder.productCode === p.productCode
                                && productOrder.productVersionId === p.productVersionId);
                            let vat = productOrder ? productOrder.vat : 0;
                            let existedRe = re.find(x => x.orderCode === returnOrder.orderCode &&
                                x.productVersionId === p.productVersionId);
                            if (!existedRe) {
                                re.push({
                                    orderCode: returnOrder.orderCode,
                                    productVersionId: p.productVersionId,
                                    productVersionName: p.productVersionName,
                                    refundMoney: p.refund,
                                    returnAmount: 1,
                                    vat: vat,
                                    requestTime: returnOrder.requestTime
                                })
                            }
                            else {
                                existedRe.refundMoney += p.refund;
                                existedRe.returnAmount++;
                            }
                        }

                    })
                })
                setData(re);
            }
        })
    }

    const columns: ColumnsType<IReturnProductReport> = [
        {
            title: 'Ngày tháng',
            dataIndex: 'requestTime',
            key: 'requestTime',
            onHeaderCell: () => headerStyle(),
            render: (requestTime) => (
                format(new Date(requestTime), "dd-MM-yyyy")
            )
        },
        {
            title: 'Mã đơn hàng',
            dataIndex: 'orderCode',
            key: 'orderCode',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Sản phẩm',
            dataIndex: 'productVersionName',
            key: 'productVersionName',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: (<span>Số lượng<br /> hàng trả</span>),
            dataIndex: 'returnAmount',
            key: 'returnAmount',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: (<span>Tiền hàng<br /> trả lại</span>),
            dataIndex: 'refundMoney',
            key: 'refundMoney',
            onHeaderCell: () => headerStyle(),
            render: (refundMoney) => (
                MathHelper.formatPriceVND(refundMoney, true)
            )
        },
        {
            title: 'VAT',
            dataIndex: 'vat',
            key: 'vat',
            onHeaderCell: () => headerStyle(),
        }

    ]

    function changeRangeTime(values: any, formatString: [string, string]) {
        const startTime: Moment = values[0];
        const endTime: Moment = values[1];

        setStartDate(startTime.toDate());
        setEndDate(endTime.toDate());
        fetchData();
    }

    function onChangeFilter() {

    }

    return (
        <div>
            <Space direction='horizontal'>
                <Button type="default" icon={<ArrowLeftOutlined />} href="/admin/report/sale"></Button>
                <RangePicker format='DD/MM/YYYY'
                    onChange={changeRangeTime}
                    defaultValue={[moment(subDays(new Date(), 7)), moment()]} />

                <ChoosFilter filterList={filters} onChangeFunc={(items: FilterItem[]) => setFilters(items)} />
            </Space>
            <FilterControls items={filters} afterChangeFnc={onChangeFilter} />
            <Table dataSource={data} columns={columns} size="small" bordered

                pagination={false}
            />
        </div>
    )
}