import { createSlice, PayloadAction } from "@reduxjs/toolkit";


const initState = {

};

const authActionSlice = createSlice({
    name: 'authAction',
    initialState: initState,
    reducers: {
        resetState(state, action: PayloadAction<undefined>) { return }, // for logout purpose
    }
});

export const {
    resetState,
} = authActionSlice.actions;
export default authActionSlice.reducer;