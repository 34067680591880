import { InputNumber, Popover, Select } from "antd";
import React, { useState } from "react";
import { DiscountType } from "../../common/type-define";

const { Option } = Select;

interface CPricePopoverProps {
    children: JSX.Element;
    title: string;
    onChangeCallback: (value: number) => void;
    total: number;
    visible?: boolean | undefined
}

const CPricePopoverComponent = ({
    children,
    title,
    onChangeCallback,
    total,
    visible = undefined
}: CPricePopoverProps ): JSX.Element => {
    const [discountType, setDiscountType] = useState<DiscountType>(DiscountType.Value);
    const [displayValue, setDisplayValue] = useState<number>(0);

    const handleDiscountType = (type: DiscountType) => {
        setDiscountType(type);
    }

    const selectBefore = () => {

        return (
            <Select
                style={{ minWidth: '80px' }}
                dropdownMatchSelectWidth
                defaultValue={DiscountType.Value}
                onChange={(type) => handleDiscountType(type)}>
                <Option value={DiscountType.Value}>
                    <span>Giá trị</span>
                </Option>
                <Option value={DiscountType.Percent}>
                    <span>%</span>
                </Option>
            </Select>
        )
    };

    const handleOnChange = (val: number) => {
        const reducedAmount = discountType === DiscountType.Value ? val : total * val /100
        onChangeCallback(reducedAmount);
        setDisplayValue(val)
    }
    
    return (
        <Popover
            placement="bottom"
            title={title}
            trigger="click"
            destroyTooltipOnHide
            visible={visible}
            content={
                <div>
                    {
                        discountType === DiscountType.Value ?
                            <InputNumber
                                addonBefore={selectBefore()}
                                addonAfter={<span>₫</span>}
                                value={displayValue}
                                className="input-number"
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                parser={value => value?.replace(/\$\s?|(\.*)/g, '') as any}
                                onChange={handleOnChange}
                            />
                            :
                            <InputNumber
                                addonBefore={selectBefore()}
                                addonAfter={<span>%</span>}
                                value={displayValue}
                                className="input-number"
                                defaultValue={0}
                                min={0}
                                max={100}
                                formatter={value => `${value}`}
                                parser={value => value?.replace('%', '') as any}
                                onChange={handleOnChange}
                            />
                    }

                </div>
            }>
            {children}
        </Popover>
    )
}

const CPricePopover = React.memo(CPricePopoverComponent);
export default CPricePopover;