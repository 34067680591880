import DownOutlined from "@ant-design/icons/lib/icons/DownOutlined";
import { Modal, ModalProps, Form, Table, Input, Popover, InputNumber, message, Dropdown, Button, Menu } from "antd";
import { ColumnsType, ColumnType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { GetWareHousesApi } from "../../Api/WarehouseApi";
import { DecideStoreTicketApi, ExportStoreTicketApi, GetWarrantyStoreTicketByIdApi, RenewProductWarrantyApi } from "../../Api/WarrantyApi";
import { MathHelper } from "../../Helper/math.helper";
import { WarrantyStoreTicketRes } from "../../Model/WarrantiesModel";
import { ProcessOrderProductViewModel } from "../../ViewModel/OrderViewModel";

type AproveStoreTicketProps = ModalProps & {
    ApprovedFnc: Function;
    storeTicket?: WarrantyStoreTicketRes;
}
export default function AproveStoreTicketModal(props: AproveStoreTicketProps) {
    const { ApprovedFnc, storeTicket, ...rest } = props;

    const [form] = Form.useForm<ProcessOrderProductViewModel>();
    const [orderProducts, setOrderProducts] = useState<ProcessOrderProductViewModel[]>([]);
    const [wareHouseList, setwareHouseList] = useState<WareHouseResponse[]>([]);
    const [wareHouse, setWareHouse] = useState<WareHouseResponse | undefined>(undefined);


    useEffect(() => {
        if (storeTicket && rest.visible) {
            GetWarrantyStoreTicketByIdApi(storeTicket.id, (ticketDetail: WarrantyStoreTicketRes) => {

                let productOrders: ProcessOrderProductViewModel[] = [];
                ticketDetail.productOrders.map(x => {
                    productOrders.push(
                        {
                            imei: "",
                            name: x.productVersion.fullName,
                            productVerId: x.id,
                            productId: "",
                            quantity: 1,
                            reducePrice: x.productVersion.salePrice,
                            salePrice: x.productVersion.salePrice,
                            totalMoney: 0,
                            discountPercent: 0,
                            discountType: 0,
                            vat: 10,
                            extendWarrantyPrice: 0
                        }
                    )
                });

                setOrderProducts(productOrders);

            });

            GetWareHousesApi((res: IListItems<WareHouseResponse>) => {
                setwareHouseList(res.items);
            }).catch((err) => {
                console.log(err);
            });
        }

    }, [rest.visible]);

    const ApproveTicketHandle = () => {
        if (storeTicket) {
            let priceDetails: { warrantyProductOrderId: string, price: number }[] = [];
            orderProducts.map(x => {
                priceDetails.push({
                    warrantyProductOrderId: x.productVerId,
                    price: x.salePrice - x.reducePrice
                })
            })
            let dataReq = {
                decision: 0,
                priceAddedDetails: priceDetails
            }


            DecideStoreTicketApi(storeTicket.id, dataReq, (res: any) => {

                //Gọi api xuất kho
                let dataExportReq = {
                    warehouseId: wareHouse?.id
                }
                ExportStoreTicketApi(storeTicket.id, dataExportReq, () => {
                    message.info("Phiếu xuất kho đã được chấp nhận và xuất kho");
                    ApprovedFnc();
                })

                //Nếu là đổi mới thì call api renew
                if (storeTicket.isReplace) {
                    let dataRenewReq = {
                        imei: "ABC",
                        reason: "",
                        moveWarrantyProductToBlackList: true
                    }

                    RenewProductWarrantyApi(storeTicket.id, dataRenewReq, () => {
                        message.info("Phiếu xuất kho đã được chấp nhận và xuất kho");
                        ApprovedFnc();
                    });
                }
            })
        }

    }


    //Giảm giá sản phẩm
    const onChangeSaleOff = (rowIndex: number) => {
        let inputSaleOff = form.getFieldValue(`Sale${rowIndex}`);

        let saleOff = Number.parseInt(inputSaleOff);
        if (rowIndex !== -1) {
            let newProducts: ProcessOrderProductViewModel[] = [...orderProducts];
            const prodChanged = newProducts[rowIndex];
            //tim tất cả các sản phẩm cùng version để thay đổi giá
            newProducts.map(x => {
                if (x.productVerId === prodChanged.productVerId) {
                    const productOrderVersion: ProcessOrderProductViewModel = {
                        ...x,
                        reducePrice: saleOff,
                        totalMoney: (x.salePrice - saleOff) * x.quantity,
                    };
                    newProducts[newProducts.indexOf(x)] = productOrderVersion;
                }
            })
            setOrderProducts(newProducts);
        }
    };

    const changeImei = (rowIndex: number) => {
        return () => {
            let newImei = form.getFieldValue(rowIndex);

            if (rowIndex !== -1 && newImei != null && orderProducts.length > 0) {
                let newOrderProducts = [...orderProducts];
                const changeProduct = newOrderProducts[rowIndex];

                const newProduct: ProcessOrderProductViewModel = ({ ...changeProduct, imei: newImei });
                newOrderProducts[rowIndex] = newProduct;
                setOrderProducts(newOrderProducts);
            }

        }
    }


    function getColumns(): ColumnsType<ProcessOrderProductViewModel> {

        const nameCol = {
            title: 'Tên sản phẩm',
            dataIndex: 'name',
            key: 'name',
        }


        const imeiCol: ColumnType<ProcessOrderProductViewModel> = {
            title: 'IMEI',
            dataIndex: 'imei',
            key: 'imei',
            render: (imei, record, index) => (
                <Form.Item name={index} style={{ marginBottom: 0 }}>
                    <Input size="small" allowClear
                        onChange={changeImei(index)} />
                </Form.Item>

            )
        }

        const priceCol: ColumnType<ProcessOrderProductViewModel> = {
            title: 'Đơn giá',
            dataIndex: 'salePrice',
            key: 'salePrice',
            render: (salePrice, record, index) => (

                <Popover
                    placement="bottom"
                    title="Chiết khấu"
                    content={
                        <Form.Item name={`Sale${index}`} style={{ marginBottom: 0 }}>
                            <InputNumber
                                addonAfter={<span>₫</span>}
                                className="input-number"
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                parser={value => value?.replace(/\$\s?|(\.*)/g, '') as any}
                                onChange={() => onChangeSaleOff(index)}
                            />
                        </Form.Item>
                    }
                    trigger="click">
                    <div>{MathHelper.formatPriceVND(salePrice)}</div>
                    <div style={{ color: "red", textDecoration: "line-through" }}>{MathHelper.formatPriceVND(record.reducePrice)}</div>
                </Popover>

            )
        }

        const moneyCol: ColumnType<ProcessOrderProductViewModel> = {
            title: 'Thành tiền',
            dataIndex: 'salePrice',
            key: 'salePrice',
            render: (salePrice, record) => (
                <div>{MathHelper.formatPriceVND((salePrice - record.reducePrice) * record.quantity, true)}</div>
            )
        }
        if (storeTicket?.isReplace)  //Nếu là dổi mới thì cần imei mới
            return [nameCol, imeiCol, priceCol, moneyCol];

        return [nameCol, priceCol, moneyCol];


    }


    const onChooseStore = (info: any) => {
        const matchWareHouse = wareHouseList.find(x => x.id === info.key);
        if (matchWareHouse) setWareHouse(matchWareHouse);
        //Thay đổi khách hàng thì cần đổi lại giá trị
    };

    const StoreOptions = (
        <Menu onClick={onChooseStore}>
            {wareHouseList.map(x => (
                <Menu.Item key={x.id}>{x.name}</Menu.Item>
            ))}
        </Menu>
    );

    return (
        <Modal centered {...rest}
            okText="Đồng ý"
            cancelText="Hủy bỏ"
            onOk={ApproveTicketHandle}>
            <Form form={form}>
                <Form.Item label="Kho">
                    <Dropdown
                        overlay={StoreOptions}
                        trigger={['click']}>
                        <Button
                            type="link"
                            style={{ fontWeight: 700 }}>
                            {wareHouse ? wareHouse.name : "Chọn kho"} <DownOutlined />
                        </Button>
                    </Dropdown>
                </Form.Item>
                <Table columns={getColumns()} size="small"
                    dataSource={orderProducts}
                    pagination={false}
                />

            </Form>
        </Modal>
    )
}