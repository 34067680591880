import {
    DeleteOutlined, DownOutlined, EnvironmentOutlined, LeftOutlined, PhoneOutlined, UserOutlined
} from '@ant-design/icons';
import { Affix, Button, Card, Col, Dropdown, Form, Input, InputNumber, Menu, message, Popover, Row, Select, Space, Steps, Table, Tooltip, Typography } from 'antd';
import { valueType } from 'antd/lib/statistic/utils';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { GetCities } from '../../Api/AddressApi';
import { GetCustomersApi } from '../../Api/CustomerApi';
import { EditOrderFromStaffApi, getOrderByIdApi } from '../../Api/OrderApi';
import { GetProductById, GetProducts } from '../../Api/ProductApi';
import { DiscountType, ICustomerInfo, ProductTypes } from '../../common/type-define';
import { MathHelper } from '../../Helper/math.helper';
import { CustomerRes, CustomerType } from '../../Model/CustomerModel';
import { EditOrderData, IOrderRespone, ProductVersionItem } from "../../Model/OrderModel";
import { ProductDetail } from '../../Model/ProductModel';
import { SaleChanelResponse } from '../../Model/SaleChannel';
import { fetchVoucherProducts } from '../../redux/slices/voucher.slice';
import { NewOrderViewModel, OrderProductViewModel } from '../../ViewModel/OrderViewModel';
import CCombobox from '../Controls/CCombobox';
import CPricePopover from '../Controls/CPricePopover';
import { LeftRightHeaderContent } from '../Controls/LeftRightHeaderContent';
import InputMoney, { InputPercent } from '../Controls/MoneyInput';
import VoucherSelectionModal from './VoucherSelectionModal';


const { Option } = Select;
const { Link } = Typography;

function EditOrderView() {
    const dispatch = useDispatch();
    const { id } = useParams();

    const [form] = Form.useForm<NewOrderViewModel>();

    const [customers, setcustomers] = useState<CustomerRes[]>([]);
    const [cities, setCities] = useState<CityResponse[]>([]);
    const [products, setProducts] = useState<ProductResponse[]>([]);

    const [orderProducts, setOrderProducts] = useState<OrderProductViewModel[]>([]);
    const [chanels, setChannels] = useState<SaleChanelResponse[]>([]);
    const [chanel, setChannel] = useState<SaleChanelResponse>();

    const [customerTypes, setCustomerTypes] = useState<CustomerType[]>([]);
    const [customerType, setCustomerType] = useState<CustomerType>();
    const [customerInfo, setCustomerInfo] = useState<ICustomerInfo | null>(null);
    const [orderCode, setOrderCode] = useState<string>('');
    const [discountType, setDiscountType] = useState<DiscountType>(DiscountType.Value);
    const [totalDiscount, setTotalDiscount] = useState<number>(0);
    const [voucherDiscount, setVoucherDiscount] = useState<number>(0);
    const [shippingFee, setShippingFee] = useState<number>(0);


    useEffect(() => {

        if (id) { // VA not sure
            getOrderByIdApi(id, (orderDetail: IOrderRespone) => {
                console.log(orderDetail);
                GetCustomersApi((data: IListItems<CustomerRes>) => { setcustomers(data.items); })
                GetCities((data: IListItems<CityResponse>) => { setCities(data.items); })
                GetProducts((data: IListItems<ProductResponse>) => {
                    setProducts(data.items);
                    setShippingFee(orderDetail.shippingDetail.shippingFee);
                    //update code
                    setOrderCode(orderDetail.orderCode);
                    //Thông tin khách hàng
                    if (orderDetail.customer) {
                        const { fullName, phoneNumber, address } = orderDetail.customer;
                        setCustomerInfo({ fullName, phoneNumber, address })
                    } else if (orderDetail.toAgency) {
                        const { name, phoneNumber, address } = orderDetail.toAgency;
                        setCustomerInfo({ fullName: name, phoneNumber, address });
                    }
                    //Thông tin sản phẩm
                    let orderProducts: OrderProductViewModel[] = [];
                    const productsRes = data.items;
                    orderDetail.productOrders.map(prod => {
                        const productOrder = orderProducts.find(x => x.productVerId == prod.productVersionId);
                        //Nếu đã tồn tại thì tăng lên

                        const selectedProduct = productsRes.find(y => y.productVersions.findIndex(z => z.id === prod.productVersionId) != -1)
                        console.log(selectedProduct);
                        if (productOrder) {
                            productOrder.quantity += 1;
                            productOrder.totalMoney = (prod.salePrice - prod.discount + prod.extendPrice) * productOrder.quantity;
                        } else {
                            orderProducts.push({
                                code: prod.productCode,
                                name: prod.productName,
                                quantity: 1,
                                productVerId: prod.productVersionId,
                                productId: prod.id,
                                salePrice: prod.salePrice,
                                totalMoney: prod.salePrice - prod.discount + prod.extendPrice,
                                versionName: "",
                                vat: prod.vat,
                                discountType: prod.discountType,
                                productVersions: selectedProduct?.productVersions ?? [],
                                reducePrice: prod.discount,
                                extendPrice: prod.extendPrice,
                                extraWarranties: selectedProduct?.extraWarranties,
                                extraWarrantyName: prod.extraWarrantyName
                            })
                        }
                    });

                    setOrderProducts(orderProducts);
                })

            });
        }
    }, []);

    const handleRemoveProd = (prod: OrderProductViewModel) => {
        return () => {
            const newVersions = [...orderProducts];
            const findIndex = newVersions.findIndex(cart => cart.productId === prod.productId);
            if (findIndex !== -1) {
                newVersions.splice(findIndex, 1);
                setOrderProducts(newVersions);
            }

        }
    };
    const navigate = useNavigate();
    //Sự kiện bắt hành động tao đơn hàng
    const EditOrderSubmit = () => {
        form.validateFields()
            .then((data: NewOrderViewModel) => {

                let productVers: ProductVersionItem[] = [];
                orderProducts.map(prod => {
                    productVers.push({
                        quantities: prod.quantity,
                        productVersionId: prod.productVerId,
                        extraWarrantyId: prod.warrantyId,
                        discountType: prod.discountType ?? 0,
                        discount: prod.reducePrice,
                    })
                });
                // call api to create order      
                const editOrderData: EditOrderData = {
                    productOrderDetails: productVers,
                    discountType: discountType === DiscountType.Value ? 0 : 1,
                    discount: totalDiscount,
                };

                //Call api to edit order
                if (id)
                    EditOrderFromStaffApi(id, editOrderData, (res: any) => {
                        message.info("Chỉnh sửa đơn hàng thành công");
                        form.setFieldsValue({});
                        navigate("/admin/orders")
                    });

            })


    };


    //chọn 1 sản phẩm trên danh sách sản phẩm thì sẽ được thêm vào danh sách
    //Sau đấy người dùng chọn phiên bản
    function productChange(value: any) {
        const product = products.find(x => x.id === value);

        if (product != null) {
            GetProductById(product.id, (productDetail: ProductDetail) => {
                console.log(productDetail);
                let proVers: ProductVersionResponse[] = [];
                //Loại bỏ các phiên bản mặc định
                if (productDetail.productType === ProductTypes.Main) {
                    productDetail.productVersions.forEach(x => {
                        if (x.color !== null && x.isAvailable)
                            proVers.push(x);
                    })
                }

                let newProducts: OrderProductViewModel[] = [...orderProducts];
                const productOrderVersion: OrderProductViewModel = {
                    productId: product.id,
                    code: product.code,
                    name: `[${product.code}]-${product.name}`,
                    productVerId: "",
                    quantity: 1,
                    reducePrice: 0,
                    salePrice: product.salePrice,
                    totalMoney: product.salePrice,
                    vat: product.vat,
                    productVersions: proVers,
                    extraWarranties: productDetail.extraWarranties,
                    extendPrice: 0,
                };


                if (productOrderVersion.productVersions.length == 1) {
                    const productVer = productOrderVersion.productVersions[0];
                    productOrderVersion.productVerId = productVer.id;

                    productOrderVersion.quantity = 1;
                    productOrderVersion.salePrice = productVer.salePrice;
                    productOrderVersion.totalMoney = productVer.salePrice * 1;
                }

                newProducts.push(productOrderVersion);
                setOrderProducts(newProducts);

                form.setFieldsValue({ productSearchId: "" })
            })

        }
    }

    function checkBuyProductVersion(productVer: ProductVersionResponse): boolean {
        if (productVer != null && productVer.isAvailable) {
            let remain = 0;
            if (productVer.productVersionWarehouses.length > 0) {
                remain += productVer.productVersionWarehouses.map(a => a.remainQuantities).reduce(function (a, b) {
                    return a + b;
                })
            }

            if (productVer.productVersionAgencies.length > 0) {
                remain += productVer.productVersionAgencies.map(a => a.remainQuantities).reduce(function (a, b) {
                    return a + b;
                })
            }

            if (remain > 0)
                return true;
            else
                message.error('Sản phẩm này đã hết hàng');
        }
        return false;
    }

    //Thay đổi phiên bản thì cần cập nhật đơn giá và thành tiên cho mỗi sản phẩm
    function onChangeVersion(value: valueType, prodChanged: OrderProductViewModel) {
        const productVersionId = value.toString();
        let newProducts: OrderProductViewModel[] = [...orderProducts];
        const rowIndex = newProducts.indexOf(prodChanged);
        if (rowIndex != -1) {
            let index = prodChanged.productVersions.findIndex(x => x.id == productVersionId);
            if (index != -1) {
                const versionChoosed = prodChanged.productVersions[index];

                if (checkBuyProductVersion(versionChoosed)) {
                    const productOrderVersion: OrderProductViewModel = {
                        ...prodChanged,
                        productVerId: productVersionId,
                        quantity: prodChanged.quantity,
                        salePrice: versionChoosed.salePrice,
                        totalMoney: (versionChoosed.salePrice + prodChanged.extendPrice - prodChanged.reducePrice) * prodChanged.quantity,

                    };
                    newProducts[rowIndex] = productOrderVersion;
                }
                else
                    newProducts[rowIndex] = { ...prodChanged, productVerId: '', totalMoney: 0 };

                setOrderProducts(newProducts);
            }

        }
    }

    //Thay dổi số lượng mua sản phẩm
    const onChangeQuantity = (rowIndex: number) => {
        return () => {

            let inputQuantity = form.getFieldValue(rowIndex);
            let newQuanity = Number.parseInt(inputQuantity);
            if (rowIndex != -1) {
                let newProducts: OrderProductViewModel[] = [...orderProducts];
                const prodChanged = newProducts[rowIndex];

                const productOrderVersion: OrderProductViewModel = {
                    ...prodChanged,
                    quantity: newQuanity,
                    totalMoney: (prodChanged.salePrice - prodChanged.reducePrice + prodChanged.extendPrice) * newQuanity,
                };
                newProducts[rowIndex] = productOrderVersion;

                setOrderProducts(newProducts);

            }

        }
    };




    const totalMoney = (): number => {
        let total: number = 0;
        orderProducts.map(x => {
            total += ((x.salePrice - x.reducePrice + x.extendPrice) * x.quantity);
        })
        return total;
    }

    const orderSaleMoney = (): number => {
        let total: number = 0;

        return total;
    }

    const totalTax = (): number => {
        let total: number = 0;
        orderProducts.map(x => {
            total += ((x.salePrice) * x.quantity * x.vat / 100);
        })
        return total;
    }

    const shipFee = (): number => {
        let total: number = 0;

        return total;
    }

    const customerPayMoney = (): number => {
        let total: number = totalMoney() + totalTax() - orderSaleMoney() - totalDiscount + shippingFee - voucherDiscount;

        return total;
    }

    const openInputVoucher = () => {
        const productIds = orderProducts.map(v => v.productId)
        dispatch(fetchVoucherProducts(productIds))
    }

    const footerContent = (
        <Row>
            <Col span={12} />
            <Col span={12}>
                <LeftRightHeaderContent leftContent={`Tổng tiền (${orderProducts.length} sản phẩm)`} rightContent={MathHelper.formatPriceVND(totalMoney())} />
                <LeftRightHeaderContent
                    leftContent={
                        <CPricePopover
                            title={"Chiết khấu phân bổ"}
                            total={totalMoney()}
                            onChangeCallback={setTotalDiscount}>
                            <Link>Chiết khấu phân bổ</Link>
                        </CPricePopover>
                    }
                    rightContent={<span style={{ color: 'red', textDecoration: 'line-through' }}>{MathHelper.formatPriceVND(totalDiscount, true)}</span>} />

                <LeftRightHeaderContent
                    leftContent={
                        <>
                            <Link onClick={openInputVoucher}>{`Mã giảm giá`}</Link>
                            <VoucherSelectionModal setVoucherDiscount={setVoucherDiscount} />
                        </>
                    }
                    rightContent={<span style={{ color: 'red', textDecoration: 'line-through' }}>{MathHelper.formatPriceVND(voucherDiscount, true)}</span>} />
                <LeftRightHeaderContent
                    leftContent={
                        <CPricePopover
                            title={"Phí giao hàng"}
                            total={totalMoney()}
                            onChangeCallback={setShippingFee}>
                            <Link>Phí giao hàng</Link>
                        </CPricePopover>
                    }
                    rightContent={<span>{MathHelper.formatPriceVND(shippingFee, true)}</span>} />
                <LeftRightHeaderContent leftContent={<div style={{ color: "#007BFF" }}>Thuế GTGT</div>} rightContent={MathHelper.formatPriceVND(totalTax(), true)} />
                <LeftRightHeaderContent leftContent={<div style={{ color: "#392525", fontWeight: "bold" }}>Khách phải trả</div>} rightContent={MathHelper.formatPriceVND(customerPayMoney())} />
            </Col>
        </Row>
    );

    const selectBefore = (rowIndex: number) => {
        return (
            <Select
                style={{ minWidth: '80px' }}
                dropdownMatchSelectWidth
                defaultValue={DiscountType.Value}
                onChange={(type) => handleDiscountType(type, rowIndex)}>
                <Option value={DiscountType.Value}>
                    <span>Giá trị</span>
                </Option>
                <Option value={DiscountType.Percent}>
                    <span>%</span>
                </Option>
            </Select>
        )
    };


    const handleDiscountType = (type: DiscountType, rowIndex: number) => {
        setDiscountType(type);
        onChangeSaleOff(rowIndex, type);
    }


    //Giảm giá sản phẩm
    const onChangeSaleOff = (rowIndex: number, discountType: DiscountType) => {
        let inputSaleOff = form.getFieldValue(`Sale${rowIndex}`);

        let saleOff = Number.parseInt(inputSaleOff);
        if (rowIndex !== -1) {
            let newProducts: OrderProductViewModel[] = [...orderProducts];
            const prodChanged = newProducts[rowIndex];
            //tim tất cả các sản phẩm cùng version để thay đổi giá
            newProducts.map(x => {
                if (x.productVerId === prodChanged.productVerId) {
                    const reducePrice = discountType === DiscountType.Value ? saleOff : x.salePrice * saleOff / 100;
                    const productOrderVersion: OrderProductViewModel = {
                        ...x,
                        reducePrice,
                        discountType: discountType === DiscountType.Value ? 0 : 1,
                        totalMoney: (x.salePrice - reducePrice) * x.quantity,
                    };
                    newProducts[newProducts.indexOf(x)] = productOrderVersion;
                }
            })
            setOrderProducts(newProducts);
        }
    };

    function onChangeWarranty(value: valueType, record: OrderProductViewModel) {
        if (value == null) {
            record.warrantyId = undefined;
            return;
        }

        record.warrantyId = value.toString();
        const warranty = record.extraWarranties?.find(x => x.id === record.warrantyId)
        if (warranty) {

            let newProducts: OrderProductViewModel[] = [...orderProducts];
            const idx = newProducts.indexOf(record);

            newProducts[idx] = {
                ...record,
                extendPrice: warranty.price,
                totalMoney: (record.salePrice + warranty.price - record.reducePrice) * record.quantity
            }
            setOrderProducts(newProducts);
        }
    }

    function onRemoveWarranty(record: OrderProductViewModel) {
        if (record != null) {
            record.warrantyId = undefined;
            let newProducts: OrderProductViewModel[] = [...orderProducts];
            const idx = newProducts.indexOf(record);

            newProducts[idx] = {
                ...record,
                extendPrice: 0,
                totalMoney: (record.salePrice - record.reducePrice) * record.quantity
            }
            setOrderProducts(newProducts);
        }

    }



    const ProductCols: ColumnsType<OrderProductViewModel> = [
        {
            title: 'Mã sản phẩm',
            dataIndex: 'name',
            key: 'name',
            render: (name, record) => (
                <Tooltip title={record.name}>
                    <span>{record.code}</span>
                </Tooltip>
            )
        },
        {
            title: 'Phiên bản',
            dataIndex: 'productId',
            key: 'productId',
            render: (name, record, index) => (
                <Select style={{ minWidth: 80 }} value={record.productVerId} onChange={(val) => onChangeVersion(val, record)} disabled={record.productVersions.length == 1} >
                    {record.productVersions.map(item => (
                        <Option value={item.id} key={item.id} >{item.name ? item.name : "Mặc định"}</Option>
                    ))}
                </Select>
            )
        },
        {
            title: 'Gói BH',
            dataIndex: 'name',
            key: 'exWarrantyName',
            render: (name, record) => (
                (record.extraWarranties && (record.extraWarranties.length > 0)) ?
                    <Select style={{ minWidth: 80 }} value={record.warrantyId} onChange={(val) => onChangeWarranty(val, record)} >
                        {record.extraWarranties.map(item => (
                            <Option value={item.id} key={item.id} >{item.name ? item.name : ""}</Option>
                        ))}
                        {(record.warrantyId && record.warrantyId !== undefined) ? <Link onClick={() => onRemoveWarranty(record)} style={{ color: 'red' }}>Xóa BH</Link> : ''}
                    </Select> : <div>{record.extraWarrantyName}</div>

            )
        },
        {
            title: 'Số lượng',
            dataIndex: 'quantity',
            key: 'quantity',
            width: 100,
            render: (quantity, record, index) => (
                <Form.Item name={index} style={{ marginBottom: 0 }}>
                    <InputNumber min={1} defaultValue={quantity} onChange={onChangeQuantity(index)} />
                </Form.Item>
            ),
        },
        {
            title: 'Đơn giá',
            dataIndex: 'salePrice',
            key: 'salePrice',
            width: 150,
            render: (salePrice, record, index) => (
                <Popover
                    placement="bottom"
                    title="Chiết khấu"
                    destroyTooltipOnHide
                    content={
                        <Form.Item name={`Sale${index}`} style={{ marginBottom: 0 }}>
                            {
                                discountType === DiscountType.Value ?
                                    <InputMoney
                                        addonBefore={selectBefore(index)}
                                        onChange={() => onChangeSaleOff(index, discountType)}
                                    />
                                    :
                                    <InputPercent
                                        addonBefore={selectBefore(index)}
                                        onChange={() => onChangeSaleOff(index, discountType)}
                                    />
                            }

                        </Form.Item>
                    }
                    trigger="click">
                    <Link>{MathHelper.formatPriceVND(salePrice)}</Link>
                    <div style={{ color: "red", textDecoration: "line-through" }}>{MathHelper.formatPriceVND(record.reducePrice)}</div>
                </Popover>
            )
        },
        {
            title: 'VAT',
            dataIndex: 'vat',
            key: 'vat',
            align: 'center',
            render: (vat) => (
                <div>{`${vat}%`}</div>
            )
        },
        {
            title: 'Thành tiền',
            dataIndex: 'totalMoney',
            key: 'totalMoney',
            render: (totalMoney) => (
                <div>{MathHelper.formatPriceVND(totalMoney)}</div>
            )
        },
        {
            title: '',
            key: 'action',
            render: (record) => (
                <Space size="middle" >
                    <DeleteOutlined style={{ color: 'red' }} onClick={handleRemoveProd(record)} />
                </Space>
            ),
        },
    ];

    const onChooseSaleChannel = (info: any) => {

        setChannel(chanels.find(x => x.id == info.key));

    };

    const menu = (
        <Menu onClick={onChooseSaleChannel}>
            {chanels.map(x => (
                <Menu.Item key={x.id}>{x.name}</Menu.Item>
            ))}
        </Menu>
    );

    const onChooseCustomerType = (info: any) => {

        setCustomerType(customerTypes.find(x => x.key == info.key));
        //Thay đổi khách hàng thì cần đổi lại giá trị
        form.setFieldsValue({ customerId: "" });
    };



    const customerOptions = (
        <Menu onClick={onChooseCustomerType}>
            {customerTypes.map(x => (
                <Menu.Item key={x.key}>{x.name}</Menu.Item>
            ))}
        </Menu>
    );


    return (
        <div>
            {/* <Affix offsetTop={0}> */}
            <LeftRightHeaderContent leftContent={
                <Button type="text" icon={<LeftOutlined />} style={{ marginTop: 10 }}
                    href={`/admin/orders/${id}`} size="large">
                    {orderCode}
                </Button>
            }

                rightContent={
                    <Button type="primary"
                        style={{ margin: 5 }}
                        onClick={EditOrderSubmit} size="large">
                        Cập nhật
                    </Button>
                }
            />
            {/* </Affix> */}
            <div style={{ backgroundColor: "#FFFFFF" }}>

            </div>


            <Form form={form}
                layout="vertical">
                <Row>
                    <Col span={18}>

                        <Card size="small" title={<LeftRightHeaderContent leftContent={<div>Thông tin sản phẩm</div>}
                            rightContent={<Space direction="horizontal">
                                <div>Kênh bán hàng</div>
                                <Dropdown overlay={menu}>
                                    <Link>
                                        {chanel?.name} <DownOutlined />
                                    </Link>

                                </Dropdown >
                            </Space>} />} >
                            <Form.Item label="Thêm sản phẩm" name="productSearchId">
                                <CCombobox chooseOptions={products} displayName="name" id="id" onChange={productChange} />
                            </Form.Item>

                            <Table dataSource={orderProducts}
                                columns={ProductCols}
                                scroll={{ x: true }}
                                pagination={false} footer={() => footerContent} />
                        </Card>

                    </Col>
                    <Col span={6} style={{ paddingLeft: 10 }}>
                        <Card size="small" title="Thông tin khách hàng">
                            <Space direction="vertical">
                                <Space direction="horizontal">
                                    <UserOutlined />
                                    <div>{customerInfo?.fullName ?? ''}</div>
                                </Space>
                                <Space direction="horizontal">
                                    <PhoneOutlined />
                                    <div>{customerInfo?.phoneNumber ?? ''}</div>
                                </Space>
                                <Space direction="horizontal">
                                    <EnvironmentOutlined />
                                    <div>{customerInfo?.address ?? ''}</div>
                                </Space>
                            </Space>
                        </Card>
                    </Col>
                </Row>

            </Form>


        </div>
    )
}

export default EditOrderView;

