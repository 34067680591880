import { Button, Card, Checkbox, Divider, Form, Input } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CreateRoleGroupCommand, RoleDefine, UpdateRoleGroupCommand } from "../../Model/RoleModel";
import { useSelectorRoot } from "../../redux/configure/store";
import { getRoleGroupDetails, getRoleList } from "../../redux/selectors/roles.selector";
import { createRole, fetchRoleGroupById, fetchRoles, setRoleGroupDetails, setRoleModalVis, updateRole } from "../../redux/slices/roles.slice";

const { TextArea } = Input;
type RoleViewModel = {
    roleId?: string;
    roleName: string;
    description: string;
}

const ROLE_DEFINE: RoleDefine[] = [
    {
        roleCategory: "Sản phẩm",
        rolePermission: [
            {
                title: "Tạo sản phẩm",
                permission: "CreateProduct",
            },
            {
                title: "Xem sản phẩm",
                permission: "ViewProduct",
            },
            {
                title: "Sửa sản phẩm",
                permission: "ModifyProduct",
            },
            {
                title: "Xóa sản phẩm",
                permission: "RemoveProduct",
            },
        ]
    },
    {
        roleCategory: "Bán hàng",
        rolePermission: [
            {
                title: "Xem đơn",
                permission: "ViewOrder",
            },
            {
                title: "Tạo đơn",
                permission: "CreateOrder",
            },
            {
                title: "Xử lý đơn",
                permission: "ProcessOrder",
            },
            {
                title: "Vận chuyển đơn",
                permission: "DeliverOrder",
            },
            {
                title: "Hoàn thành đơn",
                permission: "CompleteOrder",
            },
            {
                title: "Sửa đơn",
                permission: "ModifyOrder",
            },
            {
                title: "Hủy đơn",
                permission: "CancelOrder",
            },
        ]
    },
    {
        roleCategory: "Kho",
        rolePermission: [
            {
                title: "Đóng gói",
                permission: "PackagingOrder",
            },
            {
                title: "Nhập hàng",
                permission: "ImportProduct",
            },
            {
                title: "Xuất hàng",
                permission: "ExportProduct",
            },
        ]
    },
    {
        roleCategory: "Hỗ trợ - Tư vấn",
        rolePermission: [
            {
                title: "Kỹ thuật",
                permission: "Technical",
            },
        ]
    },
    {
        roleCategory: "Quản trị",
        rolePermission: [
            {
                title: "Quản trị nhân sự",
                permission: "SuperAdmin",
            },
            {
                title: "Quản trị website",
                permission: "WebsiteAdmin",
            },
        ]
    },

]

interface RoleModalProp {
    id: string;

}
export function RoleModal({ id }: RoleModalProp) {
    const dispatch = useDispatch();
    const roleGroupDetails = useSelectorRoot(getRoleGroupDetails);
    const roleList = useSelectorRoot(getRoleList);

    const [form] = Form.useForm<RoleViewModel>();
    const [checkedKey, setCheckedKey] = useState<string[]>([])
    const [isDirty, setIsDirty] = useState<boolean>(false);

    useEffect(() => {
        if (!id) return;
        dispatch(fetchRoleGroupById(id))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    useEffect(() => {
        if (!roleGroupDetails) {
            refreshForm();
            setCheckedKey([])
            return;
        };
        form.setFieldsValue({
            roleId: roleGroupDetails.id,
            description: roleGroupDetails.description,
            roleName: roleGroupDetails.name
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, roleGroupDetails])

    useEffect(() => {
        dispatch(fetchRoles());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!roleGroupDetails) return;
        const roleNames = roleGroupDetails.roleGroupIdentityRoles.map(v => v.role.name);
        setCheckedKey(roleNames);
    }, [roleGroupDetails])

    const refreshForm = () => {
        form.setFieldsValue({
            roleId: "",
            description: "",
            roleName: ""
        });
    }

    function onChangeCheckStatus(e: CheckboxChangeEvent) {
        const roleName = e.target.value;
        setIsDirty(true);
        if (e.target.checked) {
            setCheckedKey(pre => [...pre, ...[roleName]]);
        } else {
            setCheckedKey(pre => pre.filter(v => v !== roleName))
        }
    }

    //Trả ra id vào value của checkbox
    function getCheckPermissionId(permission: string): boolean {
        return !!checkedKey.find(v => v === permission);
    }

    const handleOnCancel = () => {
        dispatch(setRoleModalVis(false));
        dispatch(setRoleGroupDetails(null));
    }

    const handleOnOk = async () => {
        const roleIds = roleList.filter(v => checkedKey.includes(v.name)).map(v => v.id);
        if (id) {
            const { description, roleName } = form.getFieldsValue()
            const requestData: UpdateRoleGroupCommand = {
                name: roleName,
                description,
                roleIds,
            };
            dispatch(updateRole({ id, requestData }));
        } else {
            const validated = await form.validateFields();
            const payload: CreateRoleGroupCommand = {
                name: validated.roleName,
                description: validated.description,
                roleIds: roleIds,
            };
            dispatch(createRole(payload));
        }
    }

    return (
        <Form
            form={form}
            onValuesChange={() => setIsDirty(true)}
            layout="vertical">
            <Form.Item
                label="Tên vai trò"
                name="roleName"
                required>
                <Input placeholder="Nhập tên vai trò" />
            </Form.Item>

            <Form.Item
                label="Miêu tả vai trò"
                name="description">
                <TextArea
                    rows={2}
                    showCount
                    allowClear
                    maxLength={100}
                    className="textarea-height"
                    placeholder="Miểu tả vai trò" />
            </Form.Item>

            <Card title="Danh sách quyền mặc định" size="small" style={{ overflow: "auto", height: "50vh" }}>
                {
                    ROLE_DEFINE.map(v =>
                        <Card
                            type="inner"
                            size="small"
                            style={{ margin: '8px auto' }}
                            title={v.roleCategory}
                            key={v.roleCategory}>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {
                                    v.rolePermission
                                        .filter(p => !!roleList.find(r => r.name === p.permission))
                                        .map(p =>
                                            <div
                                                key={p.permission}
                                                style={{ display: 'flex', flex: '30%' }}>
                                                <Checkbox
                                                    onChange={onChangeCheckStatus}
                                                    checked={getCheckPermissionId(p.permission)}
                                                    value={p.permission}>
                                                    <span style={{ userSelect: 'none' }}>{p.title}</span>
                                                </Checkbox>
                                            </div>
                                        )
                                }
                            </div>
                        </Card>
                    )
                }
            </Card>
            <Divider style={{ margin: '8px auto' }} />
            <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Button
                    onClick={handleOnCancel}
                    style={{ margin: 'auto 8px' }}>Đóng</Button>
                <Button
                    disabled={!isDirty}
                    onClick={handleOnOk}
                    type="primary">
                    {id ? "Cập nhật" : "Thêm"}
                </Button>
            </div>
        </Form>
    )
}