import {
    DeleteOutlined, LeftOutlined,
    SaveOutlined
} from '@ant-design/icons';
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import { Affix, Button, Card, Col, Divider, Form, Input, InputNumber, message, Popover, Row, Select, Space, Steps, Table, Tooltip, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import Link from 'antd/lib/typography/Link';
import { useNavigate } from "react-router";
import { GetProductById, GetProducts } from "../../Api/ProductApi";
import { GetProvidersApi } from "../../Api/ProviderApi";
import { CreatePurchaseOrdersApi } from "../../Api/PurchaseApi";
import { GetWareHousesApi } from "../../Api/WarehouseApi";
import { DiscountType, KeyCode } from '../../common/type-define';
import { MathHelper } from "../../Helper/math.helper";
import ProviderResponse from "../../Model/ProviderModel";
import { CreatePurchaseOrderViewModel, PurchaseOrderDataRequest, PurchaseProductVersionViewModel, ReqPurchaseProductData } from "../../Model/PurchaseModel";
import CCombobox from "../Controls/CCombobox";
import { LeftRightHeaderContent } from "../Controls/LeftRightHeaderContent";
import InputMoney, { InputPercent } from '../Controls/MoneyInput';
import { cellStyle, getHeightFit } from "../Helper/UIHelper";
import { ProviderModal } from "../Providers/ProviderModal";
import CPricePopover from '../Controls/CPricePopover';
import { ProductDetail } from '../../Model/ProductModel';

const { TextArea } = Input;
const { Option } = Select;
const { Step } = Steps;
const { Text } = Typography;

function CreatePurchaseOrderView() {
    const [form] = Form.useForm<CreatePurchaseOrderViewModel>();

    const [providers, setProviders] = useState<ProviderResponse[]>([]);
    const [products, setProducts] = useState<ProductResponse[]>([]);
    const [WareHouses, setWareHouses] = useState<WareHouseResponse[]>([]);
    const [ShowProviderModel, setShowProvider] = useState<boolean>(false);
    const [totalMoney, setTotalMoney] = useState<number>(0);
    const [totalVat, setTotalVat] = useState<number>(0);
    const [totalXnk, setTotalXnk] = useState<number>(0);
    const [discountType, setDiscountType] = useState<DiscountType>(DiscountType.Value);
    const [totalDiscount, setTotalDiscount] = useState<number>(0);

    const [productVersions, setproductVersions] = useState<PurchaseProductVersionViewModel[]>([]);

    useEffect(() => {
        GetProvidersApi((data: IListItems<ProviderResponse>) => {
            setProviders(data.items);
        });

        GetProducts((data: IListItems<ProductResponse>) => {
            setProducts(data.items);
        });
        GetWareHousesApi((data: IListItems<WareHouseResponse>) => {
            setWareHouses(data.items);
        })

    }, []);

    //Tính toán lại khi sản phẩm thay đổi
    useEffect(() => {
        CalculateTotalMoney();
    }, [productVersions])

    const navigate = useNavigate();

    // Hàm yêu cầu API tạo ra đơn nhập hàng
    const createPurchaseSubmit = () => {

        for (let index = 0; index < productVersions.length; index++) {
            const element = productVersions[index];
            if (element.price <= 0.1) {
                message.info("Giá nhập cần phải được thiết lập");
                return;
            }

        }

        form.validateFields()
            .then((data: CreatePurchaseOrderViewModel) => {

                if (data.provider === null || data.provider === undefined) {
                    message.info("Cần chọn nhà cung cấp");
                    return;
                }

                if (data.wareHouseId === null || data.wareHouseId === undefined) {
                    message.info("Cần chọn kho nhập hàng");
                    return;
                }

                const prods: ReqPurchaseProductData[] = []
                productVersions.map(x => {
                    prods.push({
                        productVersionId: x.productVersionId,
                        priceInput: x.price,
                        quantities: x.quantity,
                        discountType: x.discountType,
                        discount: x.reducePrice,
                        vat: x.Vat,
                        importAndExportTax: x.Xnk
                    });
                })
                const reqData: PurchaseOrderDataRequest = {
                    providerId: data.provider,
                    warehouseId: data.wareHouseId,
                    refundInDays: 0,
                    createPurchaseProductDetails: prods,
                    totalAmount: totalMoney + totalVat + totalXnk - totalDiscount,
                    discountType: discountType === DiscountType.Value ? 0 : 1,
                    discount: totalDiscount
                }
                //message.info(JSON.stringify(reqData));
                CreatePurchaseOrdersApi(reqData, (res: any) => {
                    message.info("Tạo đơn nhập hàng thành công");
                    navigate(`/admin/purchase_orders/${res.id}`)
                })
            })
            .catch(() => {
                alert("Error");
            });
    };

    /** handle remove product */
    const handleRemoveProd = (prod: PurchaseProductVersionViewModel) => {
        return () => {
            const newVersions = [...productVersions];
            const findIndex = newVersions.findIndex(cart => cart.productVersionId === prod.productVersionId);
            if (findIndex !== -1) {
                newVersions.splice(findIndex, 1);
                setproductVersions(newVersions);
            }

        }
    };

    //Hiển thị hộp thoại nhập nhà cung cấp
    const ShowProviderModal = () => {
        setShowProvider(true);
    }

    const providerModelSumbit = (res: ProviderResponse) => {
        let newProviders: ProviderResponse[] = [...providers];
        if (res) {
            newProviders.push(res);
            setProviders(newProviders);
            setShowProvider(false);
            form.setFieldsValue({ provider: res.id })
        }
    }

    //Khi chọn sản phẩm vào đơn nhập
    function productChange(value: any) {

        const newVersions = [...productVersions];
        const product = products.find(x => x.id === value);
        if (product != null) {
            GetProductById(product.id, (productDetail: ProductDetail) => {

                for (let index = 0; index < productDetail.productVersions.length; index++) {
                    const element = productDetail.productVersions[index];

                    const productOrderVersion: PurchaseProductVersionViewModel = {
                        productCode: product.code,
                        productId: product.id,
                        quantity: 1,
                        Vat: productDetail.vat,
                        Xnk: productDetail.importAndExportTax,
                        versionName: element.name,
                        productName: product.name,
                        price: element.priceInput,
                        versions: productDetail.productVersions,
                        toltalMoney: element.priceInput,
                        productVersionId: element.id,
                        reducePrice: 0,
                        discountType: 0
                    };
                    newVersions.push(productOrderVersion);
                }

                setproductVersions(newVersions);

                form.setFieldsValue({ productSearchId: "" })
            })
        }

    }


    function CalculateTotalMoney() {
        let orderTotalMoney: number = 0;
        let orderTotalVat: number = 0;
        let orderTotalXnk: number = 0;
        for (let index = 0; index < productVersions.length; index++) {
            const element = productVersions[index];

            orderTotalMoney += (element.price - element.reducePrice) * element.quantity;
            orderTotalVat += element.price * (1 + element.Xnk / 100) * element.quantity * element.Vat / 100;
            orderTotalXnk += element.price * element.quantity * element.Xnk / 100;
        }
        setTotalMoney(orderTotalMoney);
        setTotalVat(orderTotalVat);
        setTotalXnk(orderTotalXnk);
    }



    //Thay đổi giá nhập
    const onChangeInputPrice = (val: any, rowIndex: number) => {
        let newPrice = val;
        if (rowIndex != -1) {
            let newProducts: PurchaseProductVersionViewModel[] = [...productVersions];
            const prodChanged = newProducts[rowIndex];

            const productOrderVersion: PurchaseProductVersionViewModel = {
                ...prodChanged,
                price: newPrice,
                toltalMoney: (newPrice - prodChanged.reducePrice) * prodChanged.quantity
            };
            newProducts[rowIndex] = productOrderVersion;

            setproductVersions(newProducts);
            CalculateTotalMoney();

        }

    };

    //Thay đổi số lượng
    const onChangeInputQuantity = (rowIndex: number) => {
        return () => {

            let inputQuantity = form.getFieldValue(`quantity-${rowIndex}`);
            let newQuantity = Number.parseInt(inputQuantity);
            if (rowIndex != -1) {
                let newProducts: PurchaseProductVersionViewModel[] = [...productVersions];
                const prodChanged = newProducts[rowIndex];

                const productOrderVersion: PurchaseProductVersionViewModel = {
                    ...prodChanged,
                    quantity: newQuantity,
                    toltalMoney: newQuantity * (prodChanged.price - prodChanged.reducePrice)
                };
                newProducts[rowIndex] = productOrderVersion;

                setproductVersions(newProducts);
                CalculateTotalMoney();

            }

        }
    };

    //Bảng thanh toán
    const footerContent = (
        <Row>
            <Col span={12} />
            <Col span={12}>
                <LeftRightHeaderContent
                    leftContent={<Text>{`Tổng tiền (${productVersions.length} sản phẩm)`}</Text>}
                    rightContent={MathHelper.formatPriceVND(totalMoney)} />

                <LeftRightHeaderContent
                    leftContent={
                        <CPricePopover
                            title={"Chiết khấu phân bổ"}
                            total={totalMoney}
                            onChangeCallback={setTotalDiscount}>
                            <Link>Chiết khấu phân bổ</Link>
                        </CPricePopover>
                    }
                    rightContent={<span style={{ color: 'red', textDecoration: 'line-through' }}>{MathHelper.formatPriceVND(totalDiscount, true)}</span>} />

                <LeftRightHeaderContent
                    leftContent={<Text strong>Thuế VAT</Text>}
                    rightContent={<span>{MathHelper.formatPriceVND(totalVat)}</span>} />

                <LeftRightHeaderContent
                    leftContent={<Text strong>Thuế XNK</Text>}
                    rightContent={<span>{MathHelper.formatPriceVND(totalXnk)}</span>} />

                <LeftRightHeaderContent
                    leftContent={<Text strong>Tiền phải trả</Text>}
                    rightContent={<span>{MathHelper.formatPriceVND(totalMoney + totalVat + totalXnk - totalDiscount)}</span>} />
            </Col>
        </Row>
    );

    function onChangeXnk(value: any, record: PurchaseProductVersionViewModel) {
        record.Xnk = value;
        CalculateTotalMoney();
    }

    function onChangeVat(value: any, record: PurchaseProductVersionViewModel) {
        record.Vat = value;
        CalculateTotalMoney();
    }

    const selectBefore = (rowIndex: number) => {
        return (
            <Select
                style={{ minWidth: '80px' }}
                dropdownMatchSelectWidth
                defaultValue={DiscountType.Value}
                onChange={(type) => handleDiscountType(type, rowIndex)}>
                <Option value={DiscountType.Value}>
                    <span>Giá trị</span>
                </Option>
                <Option value={DiscountType.Percent}>
                    <span>%</span>
                </Option>
            </Select>
        )
    };


    const handleDiscountType = (type: DiscountType, rowIndex: number) => {
        setDiscountType(type);
        //onChangeSaleOff(rowIndex, type);
    }

    //Giảm giá sản phẩm
    const onChangeSaleOff = (value: any, rowIndex: number, discountType: DiscountType) => {
        let inputSaleOff = form.getFieldValue(`Sale${rowIndex}`);

        let saleOff = Number.parseInt(value);
        if (rowIndex !== -1) {
            let newProducts: PurchaseProductVersionViewModel[] = [...productVersions];
            const prodChanged = newProducts[rowIndex];
            //tim tất cả các sản phẩm cùng version để thay đổi giá
            newProducts.map(x => {
                if (x.productVersionId === prodChanged.productVersionId) {
                    const reducePrice = discountType === DiscountType.Value ? saleOff : x.price * saleOff / 100;
                    const productOrderVersion: PurchaseProductVersionViewModel = {
                        ...x,
                        reducePrice,
                        discountType: discountType === DiscountType.Value ? 0 : 1,
                        toltalMoney: (x.price - reducePrice) * x.quantity,
                    };
                    newProducts[newProducts.indexOf(x)] = productOrderVersion;
                }
            })
            setproductVersions(newProducts);
        }
    };

    const ProductVersionCols: ColumnsType<PurchaseProductVersionViewModel> = [
        {
            title: 'Mã sản phẩm',
            dataIndex: 'productCode',
            key: 'productCode',
            onCell: () => cellStyle(),
            render: (productCode, record) => (
                <Tooltip title={record.productName}>
                    <span>{record.productCode}</span>
                </Tooltip>
            )
        },
        {
            title: 'Phiên bản',
            dataIndex: 'versionName',
            key: 'versionName',
            onCell: () => cellStyle(),
            render: (versionName) => (
                <span>{versionName}</span>
            )
        },
        {
            title: 'Số lượng',
            dataIndex: 'quantity',
            key: 'quantity',
            responsive: ['sm'],
            width: 100,
            render: (quantity, record, index) => (
                <Form.Item name={`quantity-${index}`} style={{ marginBottom: 0 }}>
                    <InputNumber defaultValue={quantity} min={1} onChange={onChangeInputQuantity(index)} />
                </Form.Item>
            )
        },
        {
            title: 'Giá nhập',
            dataIndex: 'price',
            key: 'price',
            width: 140,
            render: (price, record, index) => (

                <Popover placement="bottom" title="Điều chỉnh" content={
                    <div>
                        <Card title="Giá nhập mới" size='small'>
                            <InputMoney defaultValue={price} onChange={(val) => onChangeInputPrice(val, index)} />
                        </Card>

                        <Card title="Chiết khấu" size='small'>
                            {
                                discountType === DiscountType.Value ?
                                    <InputMoney
                                        defaultValue={record.reducePrice}
                                        addonBefore={selectBefore(index)}
                                        onChange={(val) => onChangeSaleOff(val, index, discountType)}
                                    />
                                    :
                                    <InputPercent
                                        addonBefore={selectBefore(index)}
                                        onChange={(val) => onChangeSaleOff(val, index, discountType)}
                                    />
                            }
                        </Card>

                    </div>



                } trigger="click">
                    <Link>{MathHelper.formatPriceVND(price, true)}</Link>
                    <div style={{ color: "red", textDecoration: "line-through" }}>{MathHelper.formatPriceVND(record.reducePrice)}</div>

                </Popover>

            )
        },
        {
            title: 'VAT',
            dataIndex: 'Vat',
            key: 'Vat',
            render: (vat, record) => (
                <InputNumber
                    defaultValue={vat}
                    min={0}
                    max={100}
                    formatter={value => `${value}%`}
                    parser={value => value?.replace('%', '')}
                    onChange={(val: any) => onChangeVat(val, record)} />
            )
        },
        {
            title: 'XNK',
            dataIndex: 'Xnk',
            key: 'Xnk',
            render: (Xnk, record) => (
                <InputNumber
                    defaultValue={Xnk}
                    min={0}
                    max={100}
                    formatter={value => `${value}%`}
                    parser={value => value?.replace('%', '')}
                    onChange={(val: any) => onChangeXnk(val, record)} />
            )
        },
        {
            title: 'Thành tiền',
            dataIndex: 'toltalMoney',
            key: 'toltalMoney',
            render: (toltalMoney) => (
                <div>{MathHelper.formatPriceVND(toltalMoney)}</div>
            )
        },
        {
            title: '',
            key: 'action',
            render: (value, record) => (
                <Space size="middle" >
                    <DeleteOutlined style={{ color: 'red' }} onClick={handleRemoveProd(record)} />
                </Space>
            ),
        },
    ];


    return (
        <div style={{ margin: 10 }}>

            <Affix offsetTop={0}>
                <div style={{ textAlign: "left", backgroundColor: '#F0F2F5' }}>
                    <Row>
                        <Col span={4} >
                            <Button type="text" icon={<LeftOutlined />} href="/admin/purchase_orders" size="large">
                                Đơn nhập hàng
                            </Button>
                        </Col>
                        <Col span={16} >

                            <Steps size="small" current={0} labelPlacement="vertical" style={{ marginTop: 10 }}>
                                <Step title="Đặt hàng" />
                                <Step title="Duyệt" />
                                <Step title="Nhập kho" />
                                <Step title="Hoàn thành" />
                            </Steps>
                        </Col>
                        <Col span={4} >
                            <Button type="primary" icon={<SaveOutlined />}
                                onClick={createPurchaseSubmit} size="large">
                                Tạo đơn nhập
                            </Button>
                        </Col>
                    </Row>

                </div>
            </Affix>


            <div style={{ maxHeight: getHeightFit(100), overflow: 'auto' }}>
                <Form form={form}
                    layout="vertical">
                    <Card>
                        <Row>
                            <Col span={18}>
                                <Card title="Thông tin sản phẩm" size="small">
                                    <Form.Item name="productSearchId">
                                        <CCombobox
                                            focusHotkey={KeyCode.F3}
                                            placeholder="Chọn sản phẩm (F3)"
                                            chooseOptions={products}
                                            displayName="name"
                                            id="id"
                                            onChange={productChange} />
                                    </Form.Item>

                                    <Table size="small" bordered
                                        dataSource={productVersions}
                                        columns={ProductVersionCols}
                                        pagination={false}
                                        scroll={{ x: true }}
                                        footer={() => footerContent} />
                                </Card>

                            </Col>
                            <Col span={6} style={{ paddingLeft: 10 }}>
                                <Card title="Thông tin nhập hàng" size="small">

                                    <Form.Item label="Chi nhánh" name="wareHouseId" required>
                                        <CCombobox chooseOptions={WareHouses} displayName="name" id="id" onChange={productChange} />
                                    </Form.Item>


                                    <Form.Item name="provider" label="Thông tin nhà cung cấp" required >

                                        <CCombobox chooseOptions={providers} displayName="name" id="id" placeholder="tìm kiếm nhà sản xuất theo SĐT, tên, mã nhà cung cấp"
                                            extraContent={
                                                <div>
                                                    <Divider style={{ margin: '4px 0' }} />
                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                        <a onClick={ShowProviderModal}
                                                            style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}>
                                                            <PlusOutlined />
                                                            <span>Thêm nhà cung cấp</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            }
                                        />
                                    </Form.Item>

                                    <Form.Item label="Ghi chú" name="purchaseNote">
                                        <TextArea rows={4} />
                                    </Form.Item>

                                </Card>
                            </Col>
                        </Row>
                    </Card>
                </Form>
            </div>
            <ProviderModal title="Thêm nhà cung cấp"
                editData={null}
                okModalFunc={providerModelSumbit}
                onCancel={() => setShowProvider(false)}
                visible={ShowProviderModel} />

        </div>
    )
}

export default CreatePurchaseOrderView;