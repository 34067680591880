import { Select } from 'antd';
import { RefSelectProps, SelectProps } from 'antd/lib/select';
import { ReactNode, useRef } from 'react';
import { KeyCode } from '../../common/type-define';
import { compareString } from '../../Helper/CommonHelper';
import { useShortKey } from '../../hooks/useShortKey';

const { Option } = Select;

type CComboboxProps = SelectProps<any> & {
    chooseOptions: any[],
    displayName: string,
    id: string,
    extraContent?: ReactNode,
    focusHotkey?: KeyCode,
}

export default function CCombobox(props: CComboboxProps): JSX.Element {
    const { chooseOptions, displayName, id, extraContent, focusHotkey, ...selectProp } = props;
    const ref = useRef<RefSelectProps>(null);
    useShortKey(focusHotkey, () => ref.current?.focus());

    return (
        <Select 
            {...selectProp}
            ref={ref}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
                compareString(String(option?.children), input)
            }
            dropdownRender={menu => (
                <div>
                    {menu}
                    {extraContent ? extraContent : ("")}
                </div>
            )}
        >
            {chooseOptions ? (chooseOptions.map(item => (
                <Option value={item[id]} key={item[id]} >{item[displayName]}</Option>
            ))) : ("No data")}
        </Select>
    )
}