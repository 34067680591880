import { Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { MathHelper } from "../../../Helper/math.helper";
import { CustomerRes } from "../../../Model/CustomerModel";
import { IOrderRespone } from "../../../Model/OrderModel";
import { useSelectorRoot } from "../../../redux/configure/store";
import { getListOrder } from "../../../redux/selectors/orders.selector";
import { setReturnOrderId } from "../../../redux/slices/returnOrders.slice";

const { Link, Text } = Typography;


const ReturnOrderTableComponent = (): JSX.Element => {
    const listOrder = useSelectorRoot(getListOrder);
    const dispatch = useDispatch();
    const [dataSource, setDataSource] = useState<IOrderRespone[]>([]);

    useEffect(() => {
        setDataSource(listOrder.filter(v => !v.isTotalReturn))
    }, [listOrder])

    const handleSetReturnOrder = (orderId: string) => {
        dispatch(setReturnOrderId(orderId))
    }

    const columns: ColumnsType<IOrderRespone> = [
        {
            title: "Mã đơn hàng",
            key: "orderCode",
            dataIndex: "orderCode",
            width: '25%',
            sorter: (a: IOrderRespone, b: IOrderRespone) => a.orderCode > b.orderCode ? -1 : 1,
            render: (orderCode: string, record: IOrderRespone) => (
                <Link onClick={() => handleSetReturnOrder(record.id)}>
                    <span>{orderCode}</span>
                </Link>
            )
        },
        {
            title: "Khách hàng",
            key: "customer",
            width: '35%',
            dataIndex: "customer",
            sorter: (a: IOrderRespone, b: IOrderRespone) => a.customer?.fullName > b.customer?.fullName ? -1 : 1,
            render: (customer: CustomerRes) => (
                <span>{customer?.fullName}</span>
            )
        },
        {
            title: "Ngày tạo",
            key: "orderTime",
            width: '20%',
            dataIndex: "orderTime",
            sorter: (a: IOrderRespone, b: IOrderRespone) => new Date(a.orderTime).getTime() > new Date(b.orderTime).getTime() ? -1 : 1,
            render: (orderTime: string) => (
                <span>{format(new Date(orderTime), MathHelper.DateFormat)}</span>
            )
        },
        {
            title: "Tổng tiền",
            key: "totalAmount",
            width: '20%',
            dataIndex: "totalAmount",
            align: 'right',
            sorter: (a: IOrderRespone, b: IOrderRespone) => a.totalAmount > b.totalAmount ? -1 : 1,
            render: (totalAmount: number) => (
                <Text strong>{MathHelper.formatPriceVND(totalAmount)}</Text>
            )
        },

    ]


    return (
        <Table
            bordered
            columns={columns}
            dataSource={dataSource}
            pagination={{ pageSize: 10 }}
            scroll={{ y: '40vh' }}
        />
    )
}

const ReturnOrderTable = React.memo(ReturnOrderTableComponent);
export default ReturnOrderTable;