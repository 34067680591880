import { Button, Col, Input, Row, Spin, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useRef, useState } from 'react';
import { GetCities } from '../../Api/AddressApi';
import { GetCustomersApi, SearchCustomersApi } from '../../Api/CustomerApi';
import { CustomerRes } from '../../Model/CustomerModel';
import { getHeightFit, headerStyle } from '../Helper/UIHelper';
import { CustomerModal } from './CustomerModal';
import {
    SearchOutlined
} from '@ant-design/icons';
import { compareString } from '../../Helper/CommonHelper';

function CustomersView() {

    const [customers, setCustomers] = useState<CustomerRes[]>([]);
    const [loading, setloading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [cities, setCities] = useState<CityResponse[]>([]);
    const [activeCustomer, setActiveCustomer] = useState<CustomerRes | undefined>();

    function LoadData() {
        setloading(true);
        GetCustomersApi((customers: IListItems<CustomerRes>) => {
            setCustomers(customers.items);
        }
        ).finally(() => setloading(false));
    }
    //Khi bắt đầu render thì nó sẽ vào use effect lần đâu tiên
    // Sau đấy bất kỳ 1 thay đổi nào liên quan đến state thì nó sẽ call vào hàm này

    useEffect(() => {
        LoadData();
    }, []);

    //Hiển thị giao diện để thêm khách hàng
    const editCustomer = (customerModal: CustomerRes) => {
        return () => {
            if (cities.length === 0) {
                GetCities((data: IListItems<CityResponse>) => { setCities(data.items); })
            }
            setActiveCustomer(customerModal);
            setIsModalVisible(true);
        }
    }

    //Sau khi tạo ra khách hàng thi add thêm vào danh sách
    const EditCustomerHandle = (newCus: CustomerRes) => {
        LoadData();
        setIsModalVisible(false);
    };

    const columns: ColumnsType<CustomerRes> = [
        {
            title: 'Tên khách hàng',
            dataIndex: 'fullName',
            key: 'fullName',
            onHeaderCell: () => headerStyle(),
            render: (customerName, record) => (
                <a >
                    <span onClick={editCustomer(record)}>{customerName}</span>
                </a>
            )
        },
        {
            title: 'Số điện thoại',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Tỉnh/Thành phố',
            dataIndex: 'city',
            key: 'city',
            onHeaderCell: () => headerStyle(),
            render: (city: CityResponse) => (
                city ? city.name : ("")
            )
        },
        {
            title: 'Địa chỉ',
            dataIndex: 'address',
            key: 'address',
            onHeaderCell: () => headerStyle(),
            render: (address) => (
                <Tooltip title={address}>
                    <span >{address.length > 20 ? `${address.slice(0, 20)}...` : address}</span>
                </Tooltip>

            )
        }
    ];


    const searchRef = useRef<Input>(null);
    const SearchCustomerSubmit = () => {
        const search = searchRef.current?.input.value.toLowerCase();
        setloading(true);
        SearchCustomersApi(search ?? '', (res: IListItems<CustomerRes>) => {
            setCustomers(res.items);
        }).finally(() => setloading(false));


    };

    //Xử lý cho tình huống xóa hết text
    const searchTextChange = () => {
        try {
            const search = searchRef.current?.input.value.toLowerCase();
            if (search == null || search === "") {
                setloading(true);
                GetCustomersApi((customers: IListItems<CustomerRes>) => {
                    setCustomers(customers.items);
                    setloading(false);
                });
            }

        } catch (error) {

        }

    }

    return (
        <div>
            <div>
                <Spin spinning={loading} tip="đang tải...">

                    <Row wrap={false}>
                        <Col flex="none">

                        </Col>
                        <Col flex="auto" style={{ padding: '0 16px' }}>
                            <Input prefix={<SearchOutlined />} allowClear={true}
                                placeholder="Nhập thông tin cần tìm kiếm"
                                ref={searchRef}
                                onChange={searchTextChange}
                                onPressEnter={SearchCustomerSubmit} />
                        </Col>

                        <Col flex="none" >

                        </Col>
                    </Row>

                    <Table bordered
                        columns={columns}
                        dataSource={customers}
                        pagination={{ pageSize: 25 }}
                        scroll={{ x: true, y: getHeightFit(200) }}
                    />
                </Spin>

            </div>

            <CustomerModal visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                customEditting={activeCustomer}
                cities={cities}
                okModalFunc={EditCustomerHandle} />
        </div>

    )
}

export default CustomersView;