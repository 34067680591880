import { Modal, ModalProps, Form, Input, Row, Col, message } from "antd";
import { useEffect, useState } from "react";
import { DefectProductApi } from "../../Api/DefectProductApi";
import { GetProductById, GetProducts } from "../../Api/ProductApi";
import { GetWareHousesApi } from "../../Api/WarehouseApi";
import { KeyCode } from "../../common/type-define";
import { ProductDetail } from "../../Model/ProductModel";
import CCombobox from "../Controls/CCombobox";

type CreateDefectProduct = ModalProps &
{
    CreatedFunc: Function
}

type ProductCodeResponse = ProductResponse & {
    nameWithCode: string
}
export default function CreateDefectProductModal(props: CreateDefectProduct) {
    const { CreatedFunc, ...rest } = props;

    const [products, setProducts] = useState<ProductCodeResponse[]>([]);
    const [productVersions, setProductVersions] = useState<ProductVersionResponse[]>([]);
    const [wareHouseList, setwareHouseList] = useState<WareHouseResponse[]>([]);
    const [productVerId, setProductVerId] = useState<string>();
    const [wareHouseId, setWareHouseId] = useState<string>();

    const [dataForm] = Form.useForm<any>();

    useEffect(() => {
        GetProducts((data: IListItems<ProductResponse>) => {

            const productCodes = data.items.map(x => (
                {
                    ...x, nameWithCode: `${x.code} - ${x.name}`
                }))

            setProducts(productCodes);
        });

        GetWareHousesApi((res: IListItems<WareHouseResponse>) => {
            setwareHouseList(res.items);
        });

    }, [rest.visible]);



    function ClickCreateDefectProduct() {

        async function fetch() {

            const dataReq = {
                imei: dataForm.getFieldValue("Imei"),
                reason: dataForm.getFieldValue("Reason"),
                productVersionId: productVerId,
                warehouseId: wareHouseId
            }

            message.info(JSON.stringify(dataReq));
            const res = await DefectProductApi.CreateDefectProductApi(dataReq);


            if (res?.status === 200) {
                if (CreatedFunc)
                    CreatedFunc();
            }
            else {

            }
        }
        fetch();




    }

    //chọn 1 sản phẩm trên danh sách sản phẩm thì sẽ được thêm vào danh sách
    //Sau đấy người dùng chọn phiên bản
    function productChange(value: any) {

        const product = products.find(x => x.id === value);

        if (product != null) {
            GetProductById(product.id, (productDetail: ProductDetail) => {
                setProductVersions(productDetail.productVersions);
            })

        }
    }

    function productVersionChange(value: any) {
        if (value != null) {
            setProductVerId(value);
        }
    }

    function chooseWarehouse(value: any) {
        setWareHouseId(value);
    }

    return (
        <Modal {...rest}
            onOk={ClickCreateDefectProduct}>
            <Form layout="vertical" form={dataForm}>
                <Form.Item label="Danh sách sản phẩm">
                    <CCombobox
                        focusHotkey={KeyCode.F3}
                        placeholder="Chọn sản phẩm (F3)"
                        chooseOptions={products}
                        displayName="nameWithCode"
                        id="id"
                        onChange={productChange} />
                </Form.Item>

                <Row>
                    <Col span={11}>
                        <Form.Item label="Phiên bản">
                            <CCombobox
                                chooseOptions={productVersions}
                                displayName="name"
                                id="id"
                                onChange={productVersionChange} />
                        </Form.Item>
                    </Col>
                    <Col span={1} />
                    <Col span={12}>
                        <Form.Item label="Kho">
                            <CCombobox
                                chooseOptions={wareHouseList}
                                displayName="name"
                                id="id"
                                onChange={chooseWarehouse} />
                        </Form.Item>
                    </Col>
                </Row>



                <Form.Item label="Imei" name="Imei">
                    <Input />
                </Form.Item>

                <Form.Item label="Reason" name="Reason">
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    )
}