import { Modal, ModalProps, Table } from "antd";
import { useEffect } from "react";

export interface conceptItem {
    name: string,
    meaning: string
}

type ConceptProps = ModalProps & {
    concepts: conceptItem[]
}

export default function ConceptModal(props: ConceptProps) {
    const { concepts, ...rest } = props;

    useEffect(() => {

    }, [rest.visible])

    const columns = [
        {
            title: 'Thuật ngữ',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Giải thích',
            dataIndex: 'meaning',
            key: 'meaning',
        }
    ]
    return (
        <Modal title="Giải thích thuật ngữ"
            style={{ minWidth: '800px' }}
            footer={false}
            {...rest}
            centered>
            <Table size="small"
                dataSource={concepts}
                columns={columns}
                pagination={false}
            />
        </Modal>
    )
}