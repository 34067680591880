import { AxiosResponse } from "axios";
import { AxiosRequest } from "../Axios/AxiosRequest";
import { RequestHandler } from "../RequestAPI";
import { SERVER_API } from "../UrlDefination";

function getOrderReportFunc(query: QueryOrderReport): string {
    const arrQueryTemp = Object.keys(query).reduce((res, key) => {
        const value = query[key as keyof QueryOrderReport];
        let temp: string = '';
        if (Array.isArray(value)) {
            temp = value.map(t => `${key}=${t}`).join("&");
        } else if (value instanceof Date) {
            temp = `${key}=${value.toISOString()}`
        } else if (typeof value === 'string' || typeof value === 'number') {
            temp = `${key}=${value}`
        }

        if (temp) res.push(temp);
        return res
    }, [] as string[]);

    return arrQueryTemp.join("&");

}

export class SaleReportApi {

    static async GetOrderReportsApi(query: QueryOrderReport): Promise<AxiosResponse<IOrderReport[], any>> {

        const fullQuery = getOrderReportFunc(query);
        console.log(query);
        return RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.REPORT.GET_ORDER}?${fullQuery}`));
    }

    static async GetProductOrderReportsApi(query: QueryOrderReport): Promise<AxiosResponse<IProductOrder[], any>> {

        const fullQuery = getOrderReportFunc(query);
        console.log(query);
        return RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.REPORT.GET_PRODUCT_ORDER}?${fullQuery}`));
    }


    static async GetReturnOrderReportsApi(query: QueryOrderReport): Promise<AxiosResponse<IOrderReturnReport[], any>> {

        const fullQuery = getOrderReportFunc(query);
        console.log(query);
        return RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.REPORT.GET_RETURN_ORDER}?${fullQuery}`));
    }

    static async SyncOrderData() {
        return RequestHandler.handleRequest(AxiosRequest.post(`${SERVER_API.HOST}/${SERVER_API.ORDER.SYNC_DATA}`));
    }

    static async SyncPurchaseData() {
        return RequestHandler.handleRequest(AxiosRequest.post(`${SERVER_API.HOST}/${SERVER_API.PURCHASE.SYNC_DATA}`));
    }

    static async GetDebtInforApi(query: QueryOrderDeptReport): Promise<AxiosResponse<IOrderDeptReport[], any>> {

        const fullQuery = getOrderReportFunc(query);
        console.log(query);
        return RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.REPORT.GET_ORDER_DEBT}?${fullQuery}`));
    }
}