import { message } from "antd"

export const CheckTaxNumberView = (taxNumber: string) => {

    const url = `https://tracuumst.com/tim-kiem?q=${taxNumber}&s=auto`
    var win = window.open(url, '_blank');
    win?.focus();

    // GetTaxNumberInforApi(taxNumber, (res: any) => {
    //     message.info(JSON.stringify(res));
    // })
}