import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { catchError, concat, filter, from, switchMap, withLatestFrom } from "rxjs";
import { ProductOrderReturnApi } from "../../Api/ProductOrderReturnApi";
import { ReturnOrderProcess } from "../../common/type-define";
import { IOrderRespone } from "../../Model/OrderModel";
import { CreateOrderReturnCommand, OrderReturnResponse, ProductOrderReturnResponse, RefundProductOrderReturnsCommand, TechnicianProcessingOrderReturnCommand } from "../../Model/ReturnOrderModel";
import { RootEpic } from "../configure/rootReducer";

interface IReturnOrderState {
    returnOrderModalVis: boolean;
    returnOrderId: string | undefined;
    returnOrderDetail: IOrderRespone | undefined;
    fetchReturnOrderLoading: boolean;
    createReturnOrderLoading: boolean;
    returnOrderList: OrderReturnResponse[];
    returnOrderProcessId: OrderReturnResponse | null,
    returnOrderProcess: ProductOrderReturnResponse[];
    fetchProductLoading: boolean;
    returnOrderProcessLoading: boolean;
}

const initState: IReturnOrderState = {
    returnOrderModalVis: false,
    returnOrderId: undefined,
    returnOrderDetail: undefined,
    fetchReturnOrderLoading: false,
    createReturnOrderLoading: false,
    returnOrderList: [],
    returnOrderProcessId: null,
    returnOrderProcess: [],
    fetchProductLoading: false,
    returnOrderProcessLoading: false,
}

const returnOrderActionSlice = createSlice({
    name: 'returnOrderAction',
    initialState: initState,
    reducers: {
        setReturnOrderModalVis(state, action: PayloadAction<boolean>) {
            state.returnOrderModalVis = action.payload;
        },
        setReturnOrderId(state, action: PayloadAction<string | undefined>) {
            state.returnOrderId = action.payload;
        },
        setReturnOrderDetail(state, action: PayloadAction<IOrderRespone | undefined>) {
            state.returnOrderDetail = action.payload;
        },
        setFetchReturnOrderLoading(state, action: PayloadAction<boolean>) {
            state.fetchReturnOrderLoading = action.payload;
        },
        createReturnOrder(state, action: PayloadAction<CreateOrderReturnCommand>) { return },
        setCreateReturnOrderLoading(state, action: PayloadAction<boolean>) {
            state.createReturnOrderLoading = action.payload;
        },
        fetchReturnOrder(state, action: PayloadAction<undefined>) { return },
        setReturnOrderList(state, action: PayloadAction<OrderReturnResponse[]>) {
            state.returnOrderList = action.payload;
        },
        setReturnOrderProcessId(state, action: PayloadAction<OrderReturnResponse | null>) {
            state.returnOrderProcessId = action.payload;
        },
        setReturnOrderProcess(state, action: PayloadAction<ProductOrderReturnResponse[]>) {
            state.returnOrderProcess = action.payload;
        },
        fetchProductByReturnOrderId(state, action: PayloadAction<string>) { return },
        setFetchProductLoading(state, action: PayloadAction<boolean>) {
            state.fetchProductLoading = action.payload;
        },
        changeOrderToProcess(state, action: PayloadAction<{ orderId: string, status: ReturnOrderProcess }>) { return },
        setReturnOrderProcessLoading(state, action: PayloadAction<boolean>) {
            state.returnOrderProcessLoading = action.payload;
        },
        getReturnOrderById(state, action: PayloadAction<string>) { return },
        techinicianProcessOrderReturn(state, action: PayloadAction<{ orderId: string, dataRequest: TechnicianProcessingOrderReturnCommand }>) { return },
        refundProductOrder(state, action: PayloadAction<{ orderId: string, refundProducts: RefundProductOrderReturnsCommand }>) { return },
        completeOrder(state, action: PayloadAction<string>) { return },
    }
});


const createReturnOrder$: RootEpic = (action$, state$) => action$.pipe(
    filter(createReturnOrder.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const orderCreated = action.payload;
        return concat(
            [returnOrderActionSlice.actions.setCreateReturnOrderLoading(true)],
            from(ProductOrderReturnApi.createReturnOrder(orderCreated)).pipe(
                switchMap(response => {
                    if (response?.status !== 200) return [];
                    return [
                        fetchReturnOrder(),
                        setReturnOrderModalVis(false)
                    ]
                }),
                catchError(err => [
                    returnOrderActionSlice.actions.setCreateReturnOrderLoading(false),
                    setReturnOrderModalVis(false)
                ])
            ),
            [returnOrderActionSlice.actions.setCreateReturnOrderLoading(false)]
        )
    })
)

const fetchReturnOrder$: RootEpic = (action$, state$) => action$.pipe(
    filter(fetchReturnOrder.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {

        return concat(
            [returnOrderActionSlice.actions.setFetchReturnOrderLoading(true)],
            from(ProductOrderReturnApi.getAllOrderReturn(() => null)).pipe(
                switchMap(response => {
                    if (response?.status !== 200) return [];
                    return [
                        setReturnOrderList(response.data.items)
                    ]
                }),
                catchError(err => [returnOrderActionSlice.actions.setFetchReturnOrderLoading(false)])
            ),
            [returnOrderActionSlice.actions.setFetchReturnOrderLoading(false)]
        )
    })
)

const fetchProductByReturnOrderId$: RootEpic = (action$, state$) => action$.pipe(
    filter(fetchProductByReturnOrderId.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const orderId = action.payload;

        return concat(
            [returnOrderActionSlice.actions.setFetchProductLoading(true)],
            from(ProductOrderReturnApi.getProductByOrderReturnId(orderId)).pipe(
                switchMap(response => {
                    if (response?.status !== 200) return [];
                    return [
                        setReturnOrderProcess(response.data.items)
                    ]
                }),
                catchError(err => [returnOrderActionSlice.actions.setFetchProductLoading(false)])
            ),
            [returnOrderActionSlice.actions.setFetchProductLoading(false)]
        )
    })
)

const changeOrderToProcess$: RootEpic = (action$, state$) => action$.pipe(
    filter(changeOrderToProcess.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const { orderId, status } = action.payload;
        const dataRequest = {
            status: status,
        }

        return concat(
            [returnOrderActionSlice.actions.setReturnOrderProcessLoading(true)],
            from(ProductOrderReturnApi.changeOrderReturnToProcess(orderId, dataRequest)).pipe(
                switchMap(response => {
                    if (response?.status !== 200) return [];
                    return [
                        getReturnOrderById(orderId),
                    ]
                }),
                catchError(err => [returnOrderActionSlice.actions.setReturnOrderProcessLoading(false)])
            ),
            [returnOrderActionSlice.actions.setReturnOrderProcessLoading(false)]
        )
    })
)

const getReturnOrderById$: RootEpic = (action$, state$) => action$.pipe(
    filter(getReturnOrderById.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const orderId = action.payload;

        return concat(
            [returnOrderActionSlice.actions.setReturnOrderProcessLoading(true)],
            from(ProductOrderReturnApi.getOrderReturnById(orderId)).pipe(
                switchMap(response => {
                    if (response?.status !== 200) return [];
                    return [
                        setReturnOrderProcessId(response.data)
                    ]
                }),
                catchError(err => [returnOrderActionSlice.actions.setReturnOrderProcessLoading(false)])
            ),
            [returnOrderActionSlice.actions.setReturnOrderProcessLoading(false)]
        )
    })
)

const techinicianProcessOrderReturn$: RootEpic = (action$, state$) => action$.pipe(
    filter(techinicianProcessOrderReturn.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const { orderId, dataRequest } = action.payload;

        return concat(
            [returnOrderActionSlice.actions.setReturnOrderProcessLoading(true)],
            from(ProductOrderReturnApi.technicianProcessingOrder(orderId, dataRequest)).pipe(
                switchMap(response => {
                    if (response?.status === 200) return [
                        getReturnOrderById(orderId),
                    ];
                    return []
                }),
                catchError(err => [returnOrderActionSlice.actions.setReturnOrderProcessLoading(false)])
            ),
        )
    })
)

const refundProductOrder$: RootEpic = (action$, state$) => action$.pipe(
    filter(refundProductOrder.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const { orderId, refundProducts } = action.payload;

        return concat(
            from(ProductOrderReturnApi.refundProductOrder(orderId, refundProducts)).pipe(
                switchMap(response => {
                    if (response?.status !== 200) return [];
                    return [
                        changeOrderToProcess({ orderId, status: ReturnOrderProcess.Processed }),
                        getReturnOrderById(orderId),
                    ]
                }),
                catchError(err => [returnOrderActionSlice.actions.setReturnOrderProcessLoading(false)])
            ),
        )
    })
)

const completeOrder$: RootEpic = (action$, state$) => action$.pipe(
    filter(completeOrder.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const orderId = action.payload;

        return concat(
            from(ProductOrderReturnApi.completeOrder(orderId)).pipe(
                switchMap(response => {
                    if (response?.status !== 200) return [];
                    return [
                        getReturnOrderById(orderId),
                    ]
                }),
                catchError(err => [returnOrderActionSlice.actions.setReturnOrderProcessLoading(false)])
            ),
            [returnOrderActionSlice.actions.setReturnOrderProcessLoading(false)]
        )
    })
)

export const ReturnOrderActionEpics = [
    createReturnOrder$,
    fetchReturnOrder$,
    fetchProductByReturnOrderId$,
    changeOrderToProcess$,
    getReturnOrderById$,
    techinicianProcessOrderReturn$,
    refundProductOrder$,
    completeOrder$,
]

export const {
    setReturnOrderModalVis,
    setReturnOrderId,
    createReturnOrder,
    fetchReturnOrder,
    setReturnOrderList,
    setReturnOrderDetail,
    setReturnOrderProcessId,
    setReturnOrderProcess,
    fetchProductByReturnOrderId,
    changeOrderToProcess,
    getReturnOrderById,
    techinicianProcessOrderReturn,
    refundProductOrder,
    completeOrder,
} = returnOrderActionSlice.actions;

export default returnOrderActionSlice.reducer;