import { Button, Form, Input, message, Modal, Spin, Typography } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { CustomerApi } from "../../Api/CustomerApi";
import { CreateCommissionCodeCommmand } from "../../Model/CustomerModel";
import { useSelectorRoot } from "../../redux/configure/store";
import { getCommissionCustomer } from "../../redux/selectors/customer.selector";
import { setCommissionCustomer } from "../../redux/slices/customer.slice";

const { Text } = Typography;
interface CommissionForm {
    commissionCode: string,
}

interface CommissionModalProps {
    handleRefresh: () => void,
}
const MAX_LENGTH = 10;
const REGEX = /[A-Za-z0-9!@#$%^&*()]{4,}$/g;
function checkRegex(str: string) {
    if(str.length > MAX_LENGTH) return false;
    return Boolean(str.match(REGEX));
}

const AddCommissionModalComponent = ({handleRefresh}: CommissionModalProps): JSX.Element => {
    const dispatch = useDispatch();
    const commissionCustomer = useSelectorRoot(getCommissionCustomer);
    const [form] = Form.useForm<CommissionForm>();
    const [loading, setLoading] = useState<boolean>(false);

    const handleOnCancel = () => {
        dispatch(setCommissionCustomer(null));
        form.resetFields();
    }

    const handleOnCreateCode = async () => {
        setLoading(true)
        const {commissionCode} = form.getFieldsValue();
        const check = checkRegex(commissionCode);
        if(!check){
            message.error('Mã hoa hồng không hợp lệ.');
            setLoading(false);
            return;
        }
        const dataReq: CreateCommissionCodeCommmand = {
            customerId: commissionCustomer!.id,
            commissionCode,
        }
        try {
            const res = await CustomerApi.setCustomerCommissionCode(dataReq);
            if(res?.status === 200){
            message.success('Tạo mã hoa hồng thành công.');
            }
        }catch(e) {
            message.error('Tạo mã hoa hồng không thành công.');
        }
        handleOnCancel();
        handleRefresh();
        setLoading(false);
    }
    
    return (
        <Modal
            visible={commissionCustomer !== null}
            title="Tạo mã hoa hồng"
            onCancel={handleOnCancel}
            footer={null}
            maskClosable={false}
            destroyOnClose>
            <Spin spinning={loading}>
                <Form
                    form={form}
                    onFinish={handleOnCreateCode}
                    >
                    <Form.Item
                        name="commissionCode"
                        label="Nhập mã hoa hồng"
                        rules={[{ required: true, message: 'Mã hoa hồng không được để trống' }]}
                        >
                        <Input placeholder="Mã hoa hồng" allowClear showCount maxLength={MAX_LENGTH}/>
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <Text type="secondary">* Mã không được chứa ký tự Tiếng Việt</Text>
                            <Text type="secondary">* Độ dài của mã phải từ 4-10 ký tự</Text>
                        </div>
                    </Form.Item>
                    <Form.Item style={{marginBottom: '0px', marginTop: '40px'}}>
                        <div style={{display: 'flex', justifyContent: 'end'}}>
                            <Button onClick={handleOnCancel} style={{margin: 'auto 8px'}}>
                                    Đóng
                            </Button>
                                <Button
                                    type="primary"
                                    htmlType="submit"> 
                                    Tạo mã
                                </Button>
                        </div>
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    )
}

const AddCommissionModal = React.memo(AddCommissionModalComponent);
export default AddCommissionModal;