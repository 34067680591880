import {
    DeleteOutlined
} from '@ant-design/icons';
import { Form, Input, InputNumber, message, Modal, ModalProps, Select, Space, Table, Tabs } from "antd";
import { valueType } from "antd/lib/statistic/utils";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { GetAccessorisByIds, GetProductById } from "../../Api/ProductApi";
import { CreateExportStoreTicketApi } from "../../Api/WarrantyApi";
import { ProductDetail } from "../../Model/ProductModel";
import { ActiveWarrantyResponse, WarrantyStoreTicketRequest } from "../../Model/WarrantiesModel";
import { NewOrderViewModel, OrderProductViewModel } from "../../ViewModel/OrderViewModel";
import CCombobox from "../Controls/CCombobox";
const { TabPane } = Tabs;
const { Option } = Select;
const { TextArea } = Input;

type StoreExportTicketModalProps = ModalProps & {
    createdTikectFnc: Function;
    activeWarranty?: ActiveWarrantyResponse;
}
export default function StoreExportTicketModal(props: StoreExportTicketModalProps) {

    const { createdTikectFnc, activeWarranty, ...rest } = props;
    const [form] = Form.useForm<NewOrderViewModel>();
    const [products, setProducts] = useState<ProductResponse[]>([]);
    const [orderProducts, setOrderProducts] = useState<OrderProductViewModel[]>([]);

    const [activeTab, setActiveTab] = useState<number>(1);

    //Tạo giao diện yêu cầu phiếu xuất kho
    useEffect(() => {
        if (props.visible && activeWarranty) {

            //Lấy tất cả phụ kiện
            const productId = activeWarranty.productId;
            GetAccessorisByIds([productId], (res: IListItems<ProductResponse>) => {
                setProducts(res.items);
            });
        }

    }, [props.visible]);

    //chọn 1 sản phẩm trên danh sách sản phẩm thì sẽ được thêm vào danh sách
    //Sau đấy người dùng chọn phiên bản
    function productChange(value: any) {

        const product = products.find(x => x.id === value);

        if (product != null) {
            GetProductById(product.id, (productDetail: ProductDetail) => {

                let newProducts: OrderProductViewModel[] = [...orderProducts];
                const productOrderVersion: OrderProductViewModel = {
                    code: product.code,
                    productId: product.id,
                    name: product.name,
                    productVerId: "",
                    quantity: 1,
                    reducePrice: 0,
                    salePrice: product.salePrice,
                    totalMoney: product.salePrice,
                    vat: product.vat,
                    productVersions: productDetail.productVersions,
                    extendPrice: 0
                };
                if (productOrderVersion.productVersions.length === 1)
                    productOrderVersion.productVerId = productOrderVersion.productVersions[0].id;

                newProducts.push(productOrderVersion);
                setOrderProducts(newProducts);

                form.setFieldsValue({ productSearchId: "" })
            })

        }
    }


    function onChangeVersion(value: valueType) {
        let params = value.toString().split('|');
        const productVersionId = params[0];
        const rowIndex = Number.parseInt(params[1]);

        let newProducts: OrderProductViewModel[] = [...orderProducts];

        if (rowIndex !== -1) {
            const prodChanged = newProducts[rowIndex];
            let index = prodChanged.productVersions.findIndex(x => x.id === productVersionId);
            if (index !== -1) {
                const versionChoosed = prodChanged.productVersions[index];
                const productOrderVersion: OrderProductViewModel = {
                    ...prodChanged,
                    productVerId: productVersionId,
                    quantity: prodChanged.quantity,
                    salePrice: versionChoosed.salePrice,
                    totalMoney: versionChoosed.salePrice * prodChanged.quantity,

                };
                newProducts[rowIndex] = productOrderVersion;

                setOrderProducts(newProducts);
            }

        }
    }

    //Thay dổi số lượng mua sản phẩm
    const onChangeQuantity = (rowIndex: number) => {
        return () => {

            let inputQuantity = form.getFieldValue(rowIndex);
            let newQuanity = Number.parseInt(inputQuantity);
            if (rowIndex !== -1) {
                let newProducts: OrderProductViewModel[] = [...orderProducts];
                const prodChanged = newProducts[rowIndex];

                const productOrderVersion: OrderProductViewModel = {
                    ...prodChanged,
                    quantity: newQuanity,
                    totalMoney: (prodChanged.salePrice - prodChanged.reducePrice) * newQuanity,
                };
                newProducts[rowIndex] = productOrderVersion;

                setOrderProducts(newProducts);

            }

        }
    };

    //Xóa bỏ phụ kiện
    const handleRemoveProd = (prod: OrderProductViewModel) => {
        return () => {
            const newVersions = [...orderProducts];
            const findIndex = newVersions.findIndex(cart => cart.productId === prod.productId);
            if (findIndex !== -1) {
                newVersions.splice(findIndex, 1);
                setOrderProducts(newVersions);
            }

        }
    };

    //Sau khi bấm tạo phiếu
    const CreateTicket = () => {

        const reason = form.getFieldValue("reason");
        if (activeTab === 1) {
            if (activeWarranty && orderProducts.length > 0) {
                const products: { productVersionId: string, quantities: number }[] = [];
                for (let index = 0; index < orderProducts.length; index++) {
                    const element = orderProducts[index];
                    products.push({
                        productVersionId: element.productVerId,
                        quantities: element.quantity
                    });
                }

                const ticket: WarrantyStoreTicketRequest = {
                    reason: reason,
                    warrantyDetailId: activeWarranty.id,
                    warrantyOrderDetails: products
                }

                CreateExportStoreTicketApi(ticket, (res: any) => {
                    createdTikectFnc();
                    message.info("Phiếu kho đã được tạo thành công");
                    createdTikectFnc?.();
                })
            }
            else {
                message.info("Cần thêm phụ kiện để xuất kho");
            }
        } else if (activeTab === 2) { //Đổi trả sản phẩm
            if (activeWarranty) {
                const products: { productVersionId: string, quantities: number }[] = [
                    {
                        productVersionId: activeWarranty.productVersionId,
                        quantities: 1
                    }
                ];

                const ticket: WarrantyStoreTicketRequest = {
                    reason: reason,
                    warrantyDetailId: activeWarranty.id,
                    warrantyOrderDetails: products
                }

                CreateExportStoreTicketApi(ticket, (res: any) => {
                    createdTikectFnc();
                    message.info("Phiếu kho đã được tạo thành công");
                }).then(() => {
                    message.info("Phiếu kho đã tạo không thành công");
                })
            }

        }

    }

    // Số trường trên table
    const ProductCols: ColumnsType<OrderProductViewModel> = [
        {
            title: 'Tên sản phẩm',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Phiên bản',
            dataIndex: 'productId',
            key: 'productId',
            render: (name, record, index) => (
                <Select style={{ minWidth: 80 }} onChange={onChangeVersion}>
                    {record.productVersions.map(item => (
                        <Option value={item.id + "|" + index} key={item.id} >{item.name ? item.name : "Mặc định"}</Option>
                    ))}
                </Select>
            )
        },
        {
            title: 'Số lượng',
            dataIndex: 'quantity',
            key: 'quantity',
            width: 100,
            render: (quantity, record, index) => (
                <Form.Item name={index} style={{ marginBottom: 0 }}>
                    <InputNumber min={1} defaultValue={quantity} onChange={onChangeQuantity(index)} />
                </Form.Item>
            ),
        },
        {
            title: '',
            key: 'action',
            render: (record) => (
                <Space size="middle" >
                    <DeleteOutlined style={{ color: 'red' }} onClick={handleRemoveProd(record)} />
                </Space>
            ),
        },
    ];

    //Thay đổi tab
    const onChangetab = (activeKey: string) => {

        switch (activeKey) {
            case '1':
                setActiveTab(1);
                break;
            case '2':
                setActiveTab(2);
                break;
            default:
                break;
        }
    }

    return (
        <Modal {...rest}
            okText="Tạo phiếu"
            cancelText="Hủy bỏ"
            onOk={CreateTicket}>
            <Tabs defaultActiveKey="1" onChange={onChangetab}>
                <TabPane tab="Cấp phụ kiện" key="1">
                </TabPane>
                <TabPane tab="Đổi mới sản phẩm" key="2">
                </TabPane>
            </Tabs>
            <Form form={form} size="small"
                layout="vertical">
                <Form.Item label="Lý do" name="reason">
                    <TextArea rows={2} />
                </Form.Item>

                {activeTab === 1 ?
                    <div>
                        <Form.Item name="productSearchId">
                            <CCombobox chooseOptions={products}
                                placeholder="Chọn phụ kiện"
                                displayName="name" id="id" onChange={productChange} />
                        </Form.Item>

                        <Table dataSource={orderProducts}
                            style={{ maxHeight: '30vh', overflow: 'auto' }}
                            size="small"
                            columns={ProductCols}
                            pagination={false} />
                    </div> :
                    <div>
                        <Form.Item label="Sản phẩm được đổi trả">
                            {activeWarranty?.productName}
                        </Form.Item>
                    </div>
                }

            </Form>
        </Modal>
    )
}