import { Button, DatePicker, Space, Table } from "antd";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { subDays } from "date-fns";
import { format } from 'date-fns';
import { ColumnsType } from "antd/lib/table";
import { MathHelper } from "../../../Helper/math.helper";
import { SaleReportApi } from "../../../Api/Reports/SaleReportApi";
import {
    ArrowLeftOutlined
} from '@ant-design/icons';
import { filterTypes, OrderHistoryStatus, OrderStatus, PaymentType } from "../../../common/type-define";
import { ChoosFilter, FilterControls } from "../../Controls/FilterControls";
import { headerStyle } from "../../Helper/UIHelper";
const { RangePicker } = DatePicker;

export default function ByPaymentReport() {
    const [data, setData] = useState<IPaymentReport[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [startDate, setStartDate] = useState<Date>(subDays(new Date(), 7));
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [filters, setFilters] = useState<FilterItem[]>([]);
    useEffect(() => {
        fetchData()
        setFilters([
            { type: filterTypes.WAREHOUSE, closeable: true },
            { type: filterTypes.AGENCY, closeable: true },
            { type: filterTypes.STAFF, closeable: true },
            { type: filterTypes.CUSTOMER, closeable: true },
            { type: filterTypes.PAYMENT_TYPES, closeable: true }]);
    }, []);

    function fetchData() {
        let re: IPaymentReport[] = [];

        const query = {
            QueryFrom: startDate,
            QueryTo: endDate
        }
        SaleReportApi.GetOrderReportsApi(query).then((response) => {
            const orders: IOrderReport[] = response.data;
            orders.forEach(order => {
                if (order.orderStatus === OrderStatus.Completed ||
                    order.orderStatus === OrderStatus.Activated) {
                    let orderTime = order.orderTime;
                    let orderCode = order.orderCode;
                    let agencyName = order.fromAgency ? order.fromAgency.name : order.warehouse.name;
                    const orderHis = order.orderHistories.find(p => p.statusCode === OrderHistoryStatus.Approve);
                    let employeeName = orderHis ? orderHis.actorName : '';
                    let customerName = order.customer.fullName;
                    const paymentTypeNum = order.orderPaidHistories[0].paymentType;
                    let paymentType = 'COD';
                    switch (paymentTypeNum) {
                        case PaymentType.COD.valueOf():
                            paymentType = 'COD';
                            break;
                        case PaymentType.Bank.valueOf():
                            paymentType = 'Chuyển khoản';
                            break;
                        case PaymentType.Cash.valueOf():
                            paymentType = 'Tiền mặt';
                            break;
                    }
                    let paidMoney = order.orderPaidHistories.reduce((prev, curr) => {
                        return prev + curr.amount
                    }, 0);

                    re.push({
                        orderTime: orderTime,
                        orderCode: orderCode,
                        agencyName: agencyName,
                        employeeName: employeeName,
                        customerName: customerName,
                        paymentType: paymentType,
                        paidMoney: paidMoney
                    })

                }
            })
            setData(re);
        })
    }
    const columns: ColumnsType<IPaymentReport> = [
        {
            title: 'Ngày tháng',
            dataIndex: 'orderTime',
            key: 'orderTime',
            onHeaderCell: () => headerStyle(),
            render: (orderTimee) => (
                format(new Date(orderTimee), "dd-MM-yyyy")
            )
        },
        {
            title: 'Mã đơn hàng',
            dataIndex: 'orderCode',
            key: 'orderCode',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Tên chi nhánh/Đại lý',
            dataIndex: 'agencyName',
            key: 'agencyName',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Tên nhân viên',
            dataIndex: 'employeeName',
            key: 'employeeName',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Tên khách hàng',
            dataIndex: 'customerName',
            key: 'customerName',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Phương thức thanh toán',
            dataIndex: 'paymentType',
            key: 'paymentType',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Tiền đã thanh toán',
            dataIndex: 'paidMoney',
            key: 'paidMoney',
            onHeaderCell: () => headerStyle(),
            render: (paidMoney) => (
                MathHelper.formatPriceVND(paidMoney)
            )
        }
    ]

    function changeRangeTime(values: any, formatString: [string, string]) {
        const startTime: Moment = values[0];
        const endTime: Moment = values[1];

        setStartDate(startTime.toDate());
        setEndDate(endTime.toDate());
        fetchData();
    }

    function onChangeFilter() {

    }

    return (
        <div>
            <Space direction='horizontal'>
                <Button type="default" icon={<ArrowLeftOutlined />} href="/admin/report/sale"></Button>
                <RangePicker format='DD/MM/YYYY'
                    clearIcon={false}
                    style={{ width: '220px' }}
                    onChange={changeRangeTime}
                    defaultValue={[moment(subDays(new Date(), 7)), moment()]} />

                <ChoosFilter filterList={filters} onChangeFunc={(items: FilterItem[]) => setFilters(items)} />

            </Space>
            <FilterControls items={filters} afterChangeFnc={onChangeFilter} />
            <Table dataSource={data} columns={columns} size="small" bordered

                pagination={false}
            />
        </div>
    )
}