import { AxiosRequest } from "./Axios/AxiosRequest";
import { HandleRequest, RequestHandler } from "./RequestAPI";
import { SERVER_API } from "./UrlDefination";
import { AxiosResponse } from "axios";
import { ProductAttibuteType } from "../common/type-define";

//AttributeType: 0 -> Color, 1 -> Material, 2 -> Size

export class ProductAttributeApi {
    static async getAllAttributes(attrType: ProductAttibuteType): Promise<AxiosResponse<ProductAttributeResponse[], any>> {
        return RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.ATTRIBUTE.GET(attrType)}`));
    }

}
export const GetColorsApi = async (doAfterResponse: Function) => {
    let type: number = 0;
    await HandleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.ATTRIBUTE.GET(type)}`), doAfterResponse);

}

export const GetMaterialsApi = async (doAfterResponse: Function) => {
    let type: number = 1;
    await HandleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.ATTRIBUTE.GET(type)}`), doAfterResponse);

}

export const GetSizesApi = async (doAfterResponse: Function) => {
    let type: number = 2;
    await HandleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.ATTRIBUTE.GET(type)}`), doAfterResponse);
}

//Create new product. Need to error handling
export const CreateAttributeApi = async (data: AttributeReq, doAfterResponse: Function) => {

    await HandleRequest(AxiosRequest.post(`${SERVER_API.HOST}/${SERVER_API.ATTRIBUTE.CREATE}`, data), doAfterResponse);

}
