import { Button, Carousel, Modal, ModalProps, Table, Form, Input, message, Row, Col, InputNumber, Typography } from "antd";
import { CarouselRef } from "antd/lib/carousel";
import { ColumnsType } from "antd/lib/table";

import { useEffect, useRef, useState } from "react";

import { AssignExtraWarrantiesApi } from "../../Api/ProductApi";
import { CreateExtraWarrantyApi, UpdateExtraWarrantyApi } from "../../Api/WarrantyApi";
import { extraWarrantyRequest, ExtraWarrantyResponse } from "../../Model/WarrantiesModel";
import { LeftRightHeaderContent } from "../Controls/LeftRightHeaderContent";
import PriceVND from "../Controls/PriceVND";
const { Link } = Typography;
type ExtraWarrantyProps = ModalProps & {
    productId?: string,
    warranties: ExtraWarrantyResponse[],
}

export default function ExtraWarrantyModal(props: ExtraWarrantyProps) {
    const { productId, warranties, ...rest } = props;
    const [extraWarranties, setExtraWarranties] = useState<ExtraWarrantyResponse[]>([]);
    const [form] = Form.useForm();

    const [isEditing, setEditing] = useState<boolean>(false);
    const [editRecord, setEditRecord] = useState<ExtraWarrantyResponse | null>(null);

    const slider = useRef<CarouselRef>(null);

    useEffect(() => {
        setExtraWarranties(warranties);
    }, [warranties])


    useEffect(() => {
        if (rest.visible) {
            slider.current?.goTo(0);
        }

    }, [rest.visible])

    const showAddHandle = () => {
        slider.current?.next();
        form.setFieldsValue({ name: "", warrantyDay: 1, price: 0 });
    }

    const showEditHandle = (record: ExtraWarrantyResponse) => {
        slider.current?.next();
        setEditing(true);
        setEditRecord(record);
        form.setFieldsValue({ name: record.name, warrantyDay: record.extraDays, price: record.price });
    }

    const backToMain = () => {
        slider.current?.prev();
    }

    function ValidateData(data: any): boolean {
        if (data.name === null || data.name === "") {
            message.warning("Tên gói không được để trắng");
            return false;
        }
        return true;
    }
    // Thêm mới gói bảo hành
    const AddHandle = () => {
        form.validateFields().then((data: any) => {

            if (ValidateData(data) === false) {
                return;
            }

            const dataReq: extraWarrantyRequest = {
                name: data.name,
                extraDays: data.warrantyDay,
                price: data.price,
                freeMaintenanceTimes: data.freeMaintenanceTimes
            };
            CreateExtraWarrantyApi(dataReq, (res: ExtraWarrantyResponse) => {

                let items: string[] = [];
                items.push(res.id);
                AssignExtraWarrantiesApi({
                    productId: productId,
                    extraWarrantyIds: items
                }, (result: any) => {
                    message.info("Một gói BH được tạo");

                    let newExtraWarranties = [...extraWarranties];
                    newExtraWarranties.push(res);
                    setExtraWarranties(newExtraWarranties);
                    slider.current?.prev();
                })

            })

        })

    }

    //Cập nhật gói bảo hành
    const UpdateHandle = () => {
        form.validateFields().then((data: any) => {

            if (ValidateData(data) === false) {
                return;
            }

            if (editRecord) {
                const dataReq: extraWarrantyRequest = {
                    name: data.name,
                    extraDays: data.warrantyDay,
                    price: data.price,
                    freeMaintenanceTimes: data.freeMaintenanceTimes
                };

                UpdateExtraWarrantyApi(editRecord?.id, dataReq, (res: ExtraWarrantyResponse) => {

                    message.info("Gói BH được cập nhật");
                    let newExtraWarranties = [...extraWarranties];
                    const idx = newExtraWarranties.indexOf(editRecord);
                    if (idx !== -1)
                        newExtraWarranties[idx] = res;
                    setExtraWarranties(newExtraWarranties);
                    slider.current?.prev();
                    setEditRecord(null);
                    setEditing(false);

                })
            }


        });
    }

    const columns: ColumnsType<ExtraWarrantyResponse> = [
        {
            title: "Tên",
            dataIndex: "name",
            render: (name, record) => (
                <Link onClick={() => showEditHandle(record)} >
                    <span>{name}</span>
                </Link>
            )
        },
        {
            title: "Số tháng",
            dataIndex: "extraDays"
        },
        {
            title: "Giá",
            dataIndex: "price",
            render: (price) => (
                <PriceVND className="text-gray-900 font-bold" override price={price} />
            )
        },
        {
            title: "Bảo trì miễn phí",
            dataIndex: "freeMaintenanceTimes"
        },

    ]
    return (

        <Modal {...rest}
            footer={false}>
            <Carousel ref={slider} >
                <div>
                    <Table columns={columns}
                        dataSource={extraWarranties}
                        size="small" />
                    <Button onClick={showAddHandle}>Thêm mới gói BH</Button>
                </div>
                <div>
                    <LeftRightHeaderContent
                        leftContent={<Button onClick={backToMain}>Quay lại</Button>}
                        rightContent={isEditing ? <Button onClick={UpdateHandle}>Cập nhật</Button> :
                            <Button onClick={AddHandle}>Thêm mới</Button>} />

                    <Form layout="vertical" size="small"
                        form={form}>
                        <Row>
                            <Col span={12} style={{ paddingRight: 5 }}>
                                <Form.Item name="name" label="Tên gói" required>
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col span={12} style={{ paddingRight: 5 }}>
                                <Form.Item name="warrantyDay" label="Số tháng" required>
                                    <InputNumber min={1} />
                                </Form.Item>
                            </Col>

                        </Row>
                        <Row>
                            <Col span={12} style={{ paddingRight: 5 }}>
                                <Form.Item name="price" label="Giá thành" required>
                                    <InputNumber
                                        min={100000}
                                        addonAfter={<span>₫</span>}
                                        className="input-number"
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                        parser={value => value?.replace(/\$\s?|(\.*)/g, '') as any}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12} style={{ paddingLeft: 5 }}>
                                <Form.Item name="freeMaintenanceTimes" label="Số lần bảo trì miễn phí" required>
                                    <InputNumber min={0} />
                                </Form.Item>
                            </Col>
                        </Row>


                    </Form>
                </div>

            </Carousel>
        </Modal>
    )
}