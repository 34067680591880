import {
    SearchOutlined
} from '@ant-design/icons';
import { Col, Form, Input, message, Modal, ModalProps, Row } from "antd";
import { useEffect, useState } from "react";
import { GetCities, GetDistricts } from "../../Api/AddressApi";
import { CreateCompanyApi, GetCompaniessApi, UpdateCompanyApi } from "../../Api/CompanyApi";
import { CompanyRes } from "../../Model/CompanyModel";
import { CompanyViewModel } from "../../ViewModel/CompanyViewModel";
import CCombobox from "../Controls/CCombobox";
import { CheckTaxNumberView } from "../Helper/CheckTaxNumber";

type CompanyModalProps = ModalProps &
{
    okModalFunc: Function,
}

export function CompanyModal(props: CompanyModalProps) {
    const { okModalFunc, ...rest } = props;

    const [form] = Form.useForm<CompanyViewModel>();
    const [companyId, SetCompanyId] = useState<string>("");
    const [cities, setCities] = useState<CityResponse[]>([]);
    const [districts, setDistricts] = useState<DistrictResponse[]>([]);

    useEffect(() => {
        if (rest.visible) {
            GetCities((data: IListItems<CityResponse>) => { setCities(data.items); })
            GetCompaniessApi((res: IListItems<CompanyRes>) => {
                if (res && res.items.length > 0) {
                    //Get first company to default
                    const firstCpn = res.items[0];

                    form.setFieldsValue({
                        companyName: firstCpn.name,
                        website: firstCpn.website,
                        saleHotline: firstCpn.saleHotline,
                        technicalHotline: firstCpn.technicalHotline,
                        cityId: firstCpn.city.id,
                        address: firstCpn.address,
                        taxNumber: firstCpn.taxNumber

                    });
                    if (firstCpn.city) {
                        GetDistricts(firstCpn.city.id, (distrs: DistrictResponse[]) => {
                            setDistricts(distrs);
                        });
                        if (firstCpn.district)
                            form.setFieldsValue({ districtId: firstCpn.district.id });
                    }
                    SetCompanyId(firstCpn.id);

                }
                else {
                    form.setFieldsValue({
                        companyName: "Công ty Phúc Vinh",
                        website: "phucvinh.tech",
                        saleHotline: "0976.951.718",
                        technicalHotline: "0976.951.718",
                        address: "Season Avenue Mỗ Lao Hà Đông Hà Nội",
                    });

                    SetCompanyId("");

                }
            })
        }


    }, [rest.visible])

    function cityChange(value: any) {
        GetDistricts(value, (distrs: DistrictResponse[]) => {
            setDistricts(distrs);
        })
    }


    const checkTaxNumber = () => {
        const taxNumber = form.getFieldValue("taxNumber");
        if (taxNumber) {
            CheckTaxNumberView(taxNumber);
        }

    }

    const handleOk = () => {
        form.validateFields().then((formData: CompanyViewModel) => {
            // VA not sure
            if (companyId && companyId !== "") {
                UpdateCompanyApi(companyId, {
                    name: formData.companyName,
                    address: formData.address,
                    cityId: formData.cityId,
                    districtId: formData.districtId,
                    saleHotline: formData.saleHotline,
                    technicalHotline: formData.technicalHotline,
                    taxNumber: formData.taxNumber,
                    website: formData.website
                }, (res: any) => {
                    message.info("Thông tin công ty được cập nhật thành công");
                    okModalFunc();
                });
            }
            else {
                CreateCompanyApi({
                    name: formData.companyName,
                    address: formData.address,
                    cityId: formData.cityId,
                    districtId: formData.districtId,
                    saleHotline: formData.saleHotline,
                    technicalHotline: formData.technicalHotline,
                    taxNumber: formData.taxNumber,
                    website: formData.website
                }, (res: CompanyRes) => {
                    message.info("Thông tin công ty được tạo mới thành công")
                    okModalFunc();
                });
            }
        })

    };

    return (
        <Modal centered {...rest}
            cancelButtonProps={{ hidden: true }}
            okText="Đồng ý"
            onOk={handleOk}>

            <Form form={form}
                layout="vertical">

                <Form.Item label="Tên công ty" name="companyName">
                    <Input placeholder="Nhập tên cty" />

                </Form.Item>

                <Row>
                    <Col span={12} style={{ paddingRight: 5 }}>
                        <Form.Item label="Số SĐT nhân viên bán hàng" name="saleHotline">
                            <Input placeholder="Nhập SĐT" />
                        </Form.Item>
                    </Col>
                    <Col span={12} style={{ paddingLeft: 5 }}>
                        <Form.Item label="Số SĐT kỹ thuật" name="technicalHotline">
                            <Input placeholder="Nhập SĐT" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={12} style={{ paddingRight: 5 }}>
                        <Form.Item label="Mã số thuế" name="taxNumber">
                            <Input placeholder="Nhập mã số thuế" addonAfter={<SearchOutlined onClick={checkTaxNumber} style={{ color: "#40A9FF" }} />} />

                        </Form.Item>
                    </Col>
                    <Col span={12} style={{ paddingLeft: 5 }}>
                        <Form.Item label="Website công ty" name="website">
                            <Input placeholder="Nhập website công ty" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={12} style={{ paddingRight: 5 }}>
                        <Form.Item label="Tỉnh/Thành phố" name="cityId">
                            <CCombobox chooseOptions={cities} displayName="name" id="id" onChange={cityChange} />
                        </Form.Item>
                    </Col>

                    <Col span={12}>

                        <Form.Item label="Quận/Huyện" name="districtId">
                            <CCombobox chooseOptions={districts} displayName="name" id="id" />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item label="Địa chỉ " name="address">
                    <Input placeholder="Nhập địa chỉ cty" />
                </Form.Item>


            </Form>
        </Modal>
    )
}