import { Button, DatePicker, Space, Spin, Table } from "antd";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { subDays } from "date-fns";
import { format } from 'date-fns';
import { ColumnsType } from "antd/lib/table";
import { MathHelper } from "../../../Helper/math.helper";
import { PurchaseReportApi } from "../../../Api/Reports/PurchaseReportApi";
import { LeftRightHeaderContent } from "../../Controls/LeftRightHeaderContent";
import {
    LeftOutlined,
    ArrowLeftOutlined,
    SearchOutlined, VerticalAlignBottomOutlined
} from '@ant-design/icons';
import { filterTypes } from "../../../common/type-define";
import { ChoosFilter, FilterControls } from "../../Controls/FilterControls";
import { headerStyle } from "../../Helper/UIHelper";
const { RangePicker } = DatePicker;

export default function DebtReport() {

    const [data, setData] = useState<IOrderDeptReport[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [showConcept, setShowConcept] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<Date>(subDays(new Date(), 7));
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [filters, setFilters] = useState<FilterItem[]>([]);
    useEffect(() => {
        fetchData()
        setFilters([
            { type: filterTypes.PROVIDER, closeable: false },
            { type: filterTypes.CARRIER, closeable: true }]);
    }, []);
    function fetchData() {
        let re: IOrderDeptReport[] = [];

        const query = {
            QueryFrom: startDate,
            QueryTo: endDate
        }
        console.log('test');
        setLoading(true);
        PurchaseReportApi.GetDebtInforApi(query).then(response => {
            const deptInforList: IOrderDeptReport[] = response.data
            deptInforList.forEach(p => {
                re.push(p);
            })
            setData(re);
        }).finally(() => {
            setLoading(false);
        })

    }

    const columns: ColumnsType<IOrderDeptReport> = [
        {
            title: 'Tên NCC',
            dataIndex: 'personInChargeName',
            key: 'personInChargeName',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Công nợ đầu kỳ',
            dataIndex: 'periodAmount',
            key: 'periodAmount',
            onHeaderCell: () => headerStyle(),
            render: (periodAmount) => (
                MathHelper.formatPriceVND(periodAmount, true)
            )
        },
        {
            title: 'Công nợ tăng',
            dataIndex: 'increaseAmount',
            key: 'increaseAmount',
            onHeaderCell: () => headerStyle(),
            render: (increaseAmount) => (
                MathHelper.formatPriceVND(increaseAmount, true)
            )
        },
        {
            title: 'Công nợ giảm',
            dataIndex: 'decreaseAmount',
            key: 'decreaseAmount',
            onHeaderCell: () => headerStyle(),
            render: (decreaseAmount) => (
                MathHelper.formatPriceVND(decreaseAmount, true)
            )
        },
        {
            title: 'Công nợ cuối kỳ',
            dataIndex: 'finalAmount',
            key: 'finalAmount',
            onHeaderCell: () => headerStyle(),
            render: (finalAmount) => (
                MathHelper.formatPriceVND(finalAmount, true)
            )
        }
    ]

    function changeRangeTime(values: any, formatString: [string, string]) {
        const startTime: Moment = values[0];
        const endTime: Moment = values[1];

        setStartDate(startTime.toDate());
        setEndDate(endTime.toDate());
        fetchData();
    }

    function onChangeFilter() {

    }

    return (
        <div>

            <Space direction='horizontal'>
                <Button type="default" icon={<ArrowLeftOutlined />} href="/admin/report/purchase"></Button>
                <RangePicker format='DD/MM/YYYY'
                    clearIcon={false}
                    style={{ width: '220px' }}
                    onChange={changeRangeTime}
                    defaultValue={[moment(subDays(new Date(), 7)), moment()]} />
                <ChoosFilter filterList={filters} onChangeFunc={(items: FilterItem[]) => setFilters(items)} />

            </Space>

            <FilterControls items={filters} afterChangeFnc={onChangeFilter} />

            <Table columns={columns} dataSource={data} size="small" bordered

                pagination={false}
            />
        </div>
    )
}