import { AxiosResponse } from "axios";
import { AxiosRequest } from "../Axios/AxiosRequest";
import { RequestHandler } from "../RequestAPI";
import { SERVER_API } from "../UrlDefination";

export class WarrantyReportApi
{
    static async GetWarrantyReportApi(from?: Date, to?: Date): Promise<AxiosResponse<IWarrantyReport[], any>> {
        let query = '';
        if (from && to)
            query = `QueryFrom=${from.toUTCString()}&QueryTo=${to.toUTCString()}`;
        return RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.REPORT.GET_WARRANTY_REPORTS}?${query}`));
    }
}