import { Select } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { useSelectorRoot } from "../../../redux/configure/store";
import { getListOrder } from "../../../redux/selectors/orders.selector";
import { setReturnOrderId } from "../../../redux/slices/returnOrders.slice";



const ReturnOrderSearchComponent = (): JSX.Element => {
    const listOrder = useSelectorRoot(getListOrder);
    const dispatch = useDispatch();

    const handleSetReturnOrder = (orderId: string) => {
        dispatch(setReturnOrderId(orderId))
    }

    return (
        <Select
            style={{
                width: '100%',
                margin: '20px auto 12px auto'
            }}
            showSearch
            placeholder="Tìm đơn..."
            optionFilterProp="children"
            onChange={(orderCode: string) => handleSetReturnOrder(orderCode)}
            // onSearch={onSearch}
            filterOption={(input, option) => {
                const inputVal = option?.title;
                if (!inputVal) return false;
                return inputVal.toLowerCase().includes(input.toLowerCase());
            }}>
            {
                listOrder.map(v =>
                    <Select.Option
                        key={v.orderCode}
                        value={v.id}
                        title={`${v.orderCode} ${v.customer?.fullName}`}>
                        {`${v.orderCode} - ${v.customer?.fullName}`}
                    </Select.Option>
                )
            }
        </Select>
    )
}

const ReturnOrderSearch = React.memo(ReturnOrderSearchComponent);
export default ReturnOrderSearch;