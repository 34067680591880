import { AxiosResponse } from "axios";
import { BillRequest, BillRespone } from "../Model/BillModel";
import { AxiosRequest } from "./Axios/AxiosRequest";
import { RequestHandler } from "./RequestAPI";
import { SERVER_API } from "./UrlDefination";

export class BillApi {

    static async GetBillsApi(from?: Date, to?: Date): Promise<AxiosResponse<IListItems<BillRespone>, any>> {

        let query = '';
        if (from && to)
            query = `QueryFrom=${from.toUTCString()}&QueryTo=${to.toUTCString()}`;
        return RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.BILL.GET}?${query}`));
    }

    static async GetIncomeBillsApi(from?: Date, to?: Date): Promise<AxiosResponse<IListItems<BillRespone>, any>> {

        let query = 'BillingTypes=0';
        if (from && to)
            query = `QueryFrom=${from.toUTCString()}&QueryTo=${to.toUTCString()}&BillingTypes=0`;
        return RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.BILL.GET}?${query}`));
    }

    static async GetIncomeBillsReportApi(from?: Date, to?: Date): Promise<AxiosResponse<BillRespone[], any>> {

        let query = 'BillingTypes=0';
        if (from && to)
            query = `QueryFrom=${from.toUTCString()}&QueryTo=${to.toUTCString()}&BillingTypes=0`;
        console.log(query);
        return RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.REPORT.GET_BILL}?${query}`));
    }

    static async GetReceptionBillsReportApi(from?: Date, to?: Date): Promise<AxiosResponse<BillRespone[], any>> {

        let query = 'BillingTypes=1';
        if (from && to)
            query = `QueryFrom=${from.toUTCString()}&QueryTo=${to.toUTCString()}&BillingTypes=1`;
        return RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.REPORT.GET_BILL}?${query}`));
    }


    static async GetReceptionBillsApi(from?: Date, to?: Date): Promise<AxiosResponse<IListItems<BillRespone>, any>> {

        let query = 'BillingTypes=1';
        if (from && to)
            query = `QueryFrom=${from.toUTCString()}&QueryTo=${to.toUTCString()}&BillingTypes=1`;
        return RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.BILL.GET}?${query}`));
    }

    static async CreateBillApi(data: BillRequest) {

        return RequestHandler.handleRequest(AxiosRequest.post(`${SERVER_API.HOST}/${SERVER_API.BILL.POST_CREATE}`, data));
    }
}