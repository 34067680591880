import { String } from "lodash";
import { AxiosRequest } from "./Axios/AxiosRequest";

import { HandleRequest } from "./RequestAPI";
import { SERVER_API } from "./UrlDefination";

//Lấy tất cả những đơn hàng đã được kích hoạt BH
export const GetActiveWarrantyOrderApi = async (doAfterResponse: Function, skip?: number, take?: number) => {

    let url = `${SERVER_API.HOST}/${SERVER_API.WARRANTY.GET_ACTIVE_ORDER}`;
    if (skip !== null && skip !== undefined && take !== null && take !== undefined) {
        url = `${SERVER_API.HOST}/${SERVER_API.ORDER.GET}?Skip=${skip}&Take=${take}`
    }
    await HandleRequest(AxiosRequest.get(url), doAfterResponse);

};

export const GetActiveWarrantiesApi = async (doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.WARRANTY.GET_ACTIVE}`), doAfterResponse);

};

export const GetActiveWarrantiesFilterApi = async (warrantyStage: number, doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.WARRANTY.GET_ACTIVE}?WarrantyStage=${warrantyStage}`), doAfterResponse);

};

//Lấy tất cả những đơn hàng chưa được kích hoạt BH
export const GetUnActiveWarrantyOrderApi = async (doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.WARRANTY.GET_UNACTIVE_ORDER}`), doAfterResponse);

};

export const ActiveWarrantyForOrderApi = async (id: string, activatedTime: Date, doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.WARRANTY.PUT_ACTIVE(id)}`, { activatedTime: activatedTime }), doAfterResponse);
}

export const UpdateActiveWarrantyTimeProductOrderApi = async (id: string, activatedTime: Date, doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.WARRANTY.PUT_UPDATE_ACTIVE_TIME(id)}`, { activatedTime: activatedTime }), doAfterResponse);
}

// Khi khách yêu cầu bảo hành. Thì nhân viên sẽ tạo một yêu cầu bảo hành
export const CreateWarrantyApi = async (data: any, doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.post(`${SERVER_API.HOST}/${SERVER_API.WARRANTY.CREATE_REQUEST}`, data), doAfterResponse);
}

////DecisionWarrantyDetail; 0-> Approved, 1-> Declined
export const DecideWarrantyRequestApi = async (warrantyId: string, data: any, doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.WARRANTY.PUT_DECIDE_TICKET(warrantyId)}`, data), doAfterResponse);

}

export const UpdateCustomerForWarrantyApi = async (id: string, customerId: string, doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.WARRANTY.UPDATE_CUSTOMER(id)}`, { CustomerId: customerId }), doAfterResponse);
}




export const WarrantyActionRequestApi = async (warrantyId: string, data: any, doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.WARRANTY.PUT_ACTION_TICKET(warrantyId)}`, data), doAfterResponse);

}

//Tạo phiếu xuất kho
export const CreateExportStoreTicketApi = async (data: any, doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.post(`${SERVER_API.HOST}/${SERVER_API.WARRANTY.CREATE_TICKET_STORE}`, data), doAfterResponse);
}

//Lấy tất cả những phiếu yêu cầu xuất kho
export const GetWarrantyStoreTicketApi = async (doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.WARRANTY.GET_STORE_TICKETS}`), doAfterResponse);

};

export const GetWarrantyStoreTicketByIdApi = async (id: string, doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.WARRANTY.GET_STORE_TICKET_BY_ID(id)}`), doAfterResponse);

};

//Lấy tất cả những phiếu yêu cầu xuất kho theo status:
// 0-> Created, 1-> Approved, 2-> Declined, 3-> Exported;
export const GetWarrantyStoreTicketByStatusApi = async (status: number, doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.WARRANTY.GET_STORE_TICKETS}?Status=${status}`), doAfterResponse);

};


////DecisionWarrantyDetail; 0-> Approved, 1-> Declined
export const DecideStoreTicketApi = async (warrantyOrderId: string, data: any, doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.WARRANTY.PUT_DECIDE_STORE_TICKET(warrantyOrderId)}`, data), doAfterResponse);

}

export const ExportStoreTicketApi = async (warrantyOrderId: string, data: any, doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.WARRANTY.PUT_DECIDE_EXPORT_STORE(warrantyOrderId)}`, data), doAfterResponse);

}

export const ApproveRequestApi = async (warrantyId: string, data: any, doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.WARRANTY.PUT_DECIDE_TICKET(warrantyId)}`, data), doAfterResponse);

}

//Đổi mới sản phẩm cho bảo hành
export const RenewProductWarrantyApi = async (warrantyId: string, data: any, doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.WARRANTY.PUT_RENEW_PRODUCT(warrantyId)}`, data), doAfterResponse);
}

//Trả lại sản phẩm cho khách hàng
export const ReturnProductWarrantyApi = async (warrantyId: string, doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.WARRANTY.PUT_RETURN_PRODUCT(warrantyId)}`, {}), doAfterResponse);
}

//Create new product. Need to error handling
export const CreateExtraWarrantyApi = async (data: any, doAfterResponse: Function) => {

    await HandleRequest(AxiosRequest.post(`${SERVER_API.HOST}/${SERVER_API.WARRANTY.CREATE_EXTRA}`, data), doAfterResponse);

}

export const UpdateExtraWarrantyApi = async (id: string, data: any, doAfterResponse: Function) => {

    await HandleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.WARRANTY.UPDATE_EXTRA(id)}`, data), doAfterResponse);

}

export const GeWarrantyHistoryApi = async (id: string, doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.WARRANTY.GET_HISTORY_BY_ID(id)}`), doAfterResponse);

};

export const RegisterOldWarrantyApi = async (data: FakeOrdersRequest, doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.post(`${SERVER_API.HOST}/${SERVER_API.ORDER.FAKE_ORDER}`, data), doAfterResponse);

}