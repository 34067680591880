import { Button, Spin, Space, Table } from "antd";
import { LeftRightHeaderContent } from "../../Controls/LeftRightHeaderContent";
import {
    ArrowLeftOutlined,
    SearchOutlined, VerticalAlignBottomOutlined
} from '@ant-design/icons';
import ConceptModal, { conceptItem } from "../../Helper/ConceptModal";
import { useEffect, useState } from "react";
import moment from "moment";
import { ColumnsType } from "antd/lib/table";
import { headerStyle } from "../../Helper/UIHelper";
import { InventoryReportApi } from "../../../Api/Reports/InventoryReportApi";
const remainConcepts: conceptItem[] = [
    {
        name: "Tồn kho",
        meaning: "Tổng số lượng hàng hóa có trong kho Tồn kho = Có thể bán + Đang giao dịch."
    },
    {
        name: "Có thể bán",
        meaning: "Tổng số lượng hàng hóa có trong kho và đang chưa có giao dịch gì"
    },
    {
        name: "Đang giao dịch",
        meaning: "Tổng số lượng hàng hóa có trong kho và đang có giao dịch liên quan"
    },
    {
        name: "Hàng đang giao",
        meaning: "Tổng số lượng hàng hóa đang được đưa từ kho đi, chưa đến điểm nhận"
    },
    {
        name: "Hàng đang về",
        meaning: "Tổng số lượng hàng hóa đang chuẩn bị về kho"
    },
]
export default function DetailRemainingReportView() {

    const [showConcept, setShowConcept] = useState<boolean>(false);

    const [data, setData] = useState<IDetailInventoryReport[]>([]);
    const [loading, setLoading] = useState<boolean>(false)
    useEffect(() => {
        fetchData();
    }, [])

    async function fetchData() {
        setLoading(true);
        
        const response = await InventoryReportApi.GetDetailInventoryReportsApi()
        if(response.status !== 200){
            setLoading(false);
            return;
        }
        
        setData(response.data);
        setLoading(false);
    }

    const columns: ColumnsType<IDetailInventoryReport> = [
        {
            title: 'Mã SP',
            dataIndex: 'productCode',
            key: 'productCode',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Tên sản phẩm',
            dataIndex: 'productVersionName',
            key: 'productVersionName',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Kho chi nhánh',
            onHeaderCell: () => headerStyle(),
            children: [
                {
                    title: 'Tồn kho',
                    dataIndex: 'remainQuantity',
                    key: 'remainQuantity',
                    onHeaderCell: () => headerStyle(),
                },
                {
                    title: 'Có thể bán',
                    dataIndex: 'availableSaleQuantity',
                    key: 'availableSaleQuantity',
                    onHeaderCell: () => headerStyle(),
                },
                {
                    title: 'Hàng đang về',
                    dataIndex: 'purchaseQuantity',
                    key: 'purchaseQuantity',
                    onHeaderCell: () => headerStyle(),
                },
                {
                    title: 'Hàng đang giao',
                    dataIndex: 'saleQuantity',
                    key: 'saleQuantity',
                    onHeaderCell: () => headerStyle(),
                },
            ]
        }
    ]
    return (
        <div>
            <LeftRightHeaderContent
                leftContent={
                    <Space direction='horizontal'>
                        <Button type="default" icon={<ArrowLeftOutlined />} href="/admin/report/store"></Button>
                        <h1>Báo cáo tồn kho chi tiết</h1>
                    </Space>}
                rightContent={
                    <Space direction='horizontal'>
                        <Button type="link" onClick={() => setShowConcept(true)}>Giải thích thuật ngữ</Button>
                    </Space>}
            />
            <Spin spinning={loading} tip="đang tải...">
                <Table columns={columns} dataSource={data}
                    size="small"
                    bordered
                />
            </Spin>
            
            <div>
                <ConceptModal visible={showConcept}
                    concepts={remainConcepts}
                    onCancel={() => setShowConcept(false)} />
            </div>
        </div>
    )
}