import { Button, Card, Col, Modal, Popover, Row, Steps, Table, Tag, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { ProductOrderReturnApi } from "../../Api/ProductOrderReturnApi";
import { OrderReturnStatus, ReturnOrderProcess, TechnicalActionEnum } from "../../common/type-define";
import { MathHelper } from "../../Helper/math.helper";
import { OrderReturnResponse, ProductOrderReturnResponse, RefundProductOrderReturnsCommand, TechnicianProcessingOrderReturnCommand } from "../../Model/ReturnOrderModel";
import { useSelectorRoot } from "../../redux/configure/store";
import { getFetchProductLoading, getReturnOrderProcess, getReturnOrderProcessId } from "../../redux/selectors/returnOrders.selector";
import { changeOrderToProcess, completeOrder, fetchProductByReturnOrderId, fetchReturnOrder, getReturnOrderById, refundProductOrder, setReturnOrderProcessId, techinicianProcessOrderReturn } from "../../redux/slices/returnOrders.slice";
import { LeftRightHeaderContent } from "../Controls/LeftRightHeaderContent";
import {
    LeftOutlined
} from '@ant-design/icons';
import InputMoney from "../Controls/MoneyInput";
import Link from "antd/lib/typography/Link";
const { Step } = Steps;

interface ReturnOrderDisplay extends ProductOrderReturnResponse {
    quantity: number;
    key: string;
}

enum ReturnOrderSteps {
    APPROVE = 0,
    PROCESS = 1,
    PAY_BACK_MONEY = 2,
    RETURN_PRODUCT = 3
}

const STEP_DEFINE = ['Duyệt đơn', 'Kỹ thuật xử lý', 'Hoàn tiền', 'Nhận lại hàng'];
const getStepIndexByStatus = (status: OrderReturnStatus): number => {
    switch (status) {
        case OrderReturnStatus.Created:
        case OrderReturnStatus.Processing:
            return status;
        case OrderReturnStatus.NeedRefund:
            return 2;
        case OrderReturnStatus.AllDeclined:
            return 4;
        case OrderReturnStatus.Processed:
            return 3;
        case OrderReturnStatus.Completed:
            return 5;
    }
}

const renderTagTechnicalAction = (status: number) => {
    switch (status) {
        case 2:
            return {
                text: "Chấp nhận",
                color: "green",
            }
        default:
            return {
                text: "Từ chối",
                color: "volcano",
            }
    }
}


function ProcessReturnOrderView() {
    const dispatch = useDispatch();

    const { id } = useParams();
    const returnOrderProcess = useSelectorRoot(getReturnOrderProcess);
    const fetchProductLoading = useSelectorRoot(getFetchProductLoading);
    const returnOrderProcessId = useSelectorRoot(getReturnOrderProcessId);
    const [stepIndex, setStepIndex] = useState<number>(0);
    const [dataSource, setDataSource] = useState<ReturnOrderDisplay[]>([]);
    const [selectedRow, setSelectedRow] = useState<ReturnOrderDisplay[]>([]);
    const [returnOrderRes, setReturnOrderRes] = useState<OrderReturnResponse>();

    function fetchData() {
        if (!id) return;
        ProductOrderReturnApi.getOrderReturnById(id, (response: OrderReturnResponse) => {
            if (response) {
                console.log(response);
                setReturnOrderRes(response);
                dispatch(fetchProductByReturnOrderId(response.id));
                setStepIndex(getStepIndexByStatus(response.status));
            }
        });
    }

    useEffect(() => {
        fetchData();
    }, [dispatch, id])

    useEffect(() => {
        console.log(returnOrderProcess);
        const newDataSource = returnOrderProcess.map(v => ({ ...v, ...{ quantity: 1, key: v.id, refund: v.refund ?? v.salePrice } }));
        console.log(newDataSource);
        setDataSource(newDataSource);
    }, [returnOrderProcess])


    useEffect(() => {
        if (!returnOrderProcessId) return;
        console.log(returnOrderProcessId);
        setReturnOrderRes(returnOrderProcessId);
        dispatch(fetchProductByReturnOrderId(returnOrderProcessId.id));
        setStepIndex(getStepIndexByStatus(returnOrderProcessId.status));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [returnOrderProcessId])


    const onSelectRow = (record: ReturnOrderDisplay) => {
        if (stepIndex !== 1) return;
        setSelectedRow(pre => {
            if (pre.some(v => v.id === record.key)) {
                return pre.filter(v => v.id !== record.key)
            } else {
                return [...pre, ...[record]]
            }
        })
    }

    //Thay đổi giá nhập
    const onChangeRefunPrice = (val: any, rowIndex: number) => {
        let newPrice = val;
        if (rowIndex != -1) {
            let newProducts: ReturnOrderDisplay[] = [...dataSource];
            const prodChanged = newProducts[rowIndex];

            const productOrderVersion: ReturnOrderDisplay = {
                ...prodChanged,
                refund: newPrice,
            };
            newProducts[rowIndex] = productOrderVersion;

            setDataSource(newProducts);


        }

    };


    const columns: ColumnsType<ReturnOrderDisplay> = [
        {
            title: "Tên sản phẩm",
            key: "productVersionName",
            dataIndex: "productVersionName",
            width: "40%",
            render: (name: string, record: ReturnOrderDisplay) => (
                <Typography.Link onClick={() => onSelectRow(record)}>
                    {name}
                </Typography.Link>
            )
        },
        {
            title: "IMEI",
            key: "imei",
            dataIndex: "imei",
            width: "15%",
            render: (imei: string) => (
                <div>
                    {imei}
                </div>
            )
        },
        {
            title: "Trạng thái",
            key: "technicalAction",
            dataIndex: "technicalAction",
            width: "15%",
            render: (technicalAction: number) => {
                const data = renderTagTechnicalAction(technicalAction);
                return <Tag color={data.color}>{data.text}</Tag>
            }
        },
        {
            title: "Số lượng",
            key: "quantity",
            dataIndex: "quantity",
            width: "15%",
            align: 'right',
            render: (quantity: number) => (
                <span>
                    {quantity}
                </span>
            )
        },
        {
            title: "Giá bán",
            key: "salePrice",
            dataIndex: "salePrice",
            width: "15%",
            align: 'right',
            render: (salePrice: number, record, index) => (
                stepIndex === ReturnOrderSteps.PAY_BACK_MONEY ?
                    <Popover placement="bottom" title="Điều chỉnh giá" content={

                        <Card title="Giá khấu hao" size='small'>
                            <InputMoney defaultValue={salePrice} onChange={(val) => onChangeRefunPrice(val, index)} />
                        </Card>

                    } trigger="click">
                        <Link>{MathHelper.formatPriceVND(record.refund ?? 0, true)}</Link>
                        <div style={{ color: "red", textDecoration: "line-through" }}>{MathHelper.formatPriceVND(salePrice)}</div>

                    </Popover> : <div>
                        <Link>{MathHelper.formatPriceVND(record.refund ?? 0, true)}</Link>
                        <div style={{ color: "red", textDecoration: "line-through" }}>{MathHelper.formatPriceVND(salePrice)}</div>
                    </div>
            )
        },
        {
            title: "VAT",
            key: "vat",
            dataIndex: "vat",
            width: "15%",
            render: (vat: number) => (
                <span>
                    {vat}
                </span>
            )
        },
        {
            title: "Hoàn tiền",
            key: "total",
            dataIndex: "total",
            width: "15%",
            align: 'right',
            render: (_: number, record: ReturnOrderDisplay) => (
                <span>
                    {MathHelper.formatPriceVND(record.quantity * (record.refund ?? 0))}
                </span>
            )
        },
    ];


    const getTotalAmount = (): number => {
        if (stepIndex < 2) {
            if (!selectedRow.length) return 0;
            return selectedRow.reduce((p, c) => p + c.quantity * c.salePrice, 0);
        } else {
            return returnOrderProcess.reduce((p, c) => p + (c.technicalAction === 2 ? c.salePrice : 0), 0);
        }
    }

    const getTotalRefundNumber = (): number => {
        if (stepIndex < 2) {
            return selectedRow.length;
        } else {
            return returnOrderProcess.filter(v => v.technicalAction === 2).length;
        }
    }

    const footerContent = () => {
        if (stepIndex < 1) return undefined;
        return (
            <Row>
                <Col span={12} />
                <Col span={12}>
                    <LeftRightHeaderContent
                        leftContent={`Tổng tiền hàng trả (${getTotalRefundNumber()} sản phẩm)`}
                        rightContent={
                            <span style={{ padding: 'auto 4px', fontWeight: 700 }}>{MathHelper.formatPriceVND(getTotalAmount(), true)}</span>
                        } />
                </Col>
            </Row>
        )
    };

    // Qua trình xử lý đơn trả
    const handleOnProcess = () => {
        if (!returnOrderRes || !id) return;
        const orderId = id;

        switch (stepIndex) {
            case ReturnOrderSteps.APPROVE:
                dispatch(changeOrderToProcess({
                    orderId: id,
                    status: ReturnOrderProcess.Processing,
                }));
                break;
            case ReturnOrderSteps.PROCESS:
                const technicalDataRequest: TechnicianProcessingOrderReturnCommand = {
                    actionProductOrderReturnDetails: dataSource.map(v => ({
                        productOrderReturnId: v.id,
                        reason: "Kỹ thuật duyệt",
                        technicalAction: selectedRow.find(row => row.id === v.id) ? 0 : 1,
                    }))
                }
                dispatch(techinicianProcessOrderReturn({
                    orderId,
                    dataRequest: technicalDataRequest,
                }));
                break;
            case ReturnOrderSteps.PAY_BACK_MONEY:
                const refundProducts: RefundProductOrderReturnsCommand = {
                    decisionDetails: dataSource
                        .filter(v => v.technicalAction === TechnicalActionEnum.Passed)
                        .map(v => ({
                            productOrderReturnId: v.id,
                            refund: v.refund ?? v.salePrice
                        }))
                }
                console.log(refundProducts);
                dispatch(refundProductOrder({ orderId, refundProducts }))
                break;
            case ReturnOrderSteps.RETURN_PRODUCT:
                dispatch(completeOrder(orderId));
                break;
        }

        setStepIndex(stepIndex + 1);
    }

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: ReturnOrderDisplay[]) => setSelectedRow(selectedRows),
    };

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Steps
                    style={{ marginBottom: '36px', marginRight: '20px' }}
                    size="small"
                    labelPlacement="vertical"
                    current={stepIndex}>
                    {
                        STEP_DEFINE.map(v => <Step key={v} title={v} />)
                    }
                </Steps>
                <Button
                    disabled={stepIndex > STEP_DEFINE.length - 1}
                    onClick={handleOnProcess}
                    type="primary">
                    Duyệt
                </Button>
            </div>

            <div style={{ marginBottom: '20px' }}>
                <Button type="text" style={{ background: 'green' }} icon={<LeftOutlined color='red' />} href="/admin/orders/order_returns" size="middle" />
                <Typography.Text strong>Đơn hàng: </Typography.Text>
                <span style={{ fontSize: '16px', fontWeight: 700 }}>{returnOrderRes?.orderCode}</span>
            </div>

            <Table
                rowSelection={
                    stepIndex === 1 ?
                        {
                            type: 'checkbox',
                            selectedRowKeys: selectedRow.map(v => v.id),
                            ...rowSelection,
                        }
                        :
                        undefined
                }
                dataSource={dataSource}
                columns={columns.filter(v => !(stepIndex < 2 && v.key === 'technicalAction'))}
                pagination={false}
                scroll={{ y: '500px' }}
                footer={footerContent}
                loading={fetchProductLoading}
            />
        </div>
    )
}

export default ProcessReturnOrderView;

