import { FileAddOutlined } from '@ant-design/icons';
import { Button, Col, message, Row, Table, Tag, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { ProductOrderReturnApi } from '../../../Api/ProductOrderReturnApi';
import { MathHelper } from "../../../Helper/math.helper";
import { ProductOrderResponse } from "../../../Model/OrderModel";
import { CreateOrderReturnCommand } from '../../../Model/ReturnOrderModel';
import { useSelectorRoot } from "../../../redux/configure/store";
import { getCreateReturnOrderLoading, getReturnOrderDetail, getReturnOrderId } from '../../../redux/selectors/returnOrders.selector';
import { fetchOrderById } from '../../../redux/slices/orders.slice';
import { fetchReturnOrder, setReturnOrderModalVis } from '../../../redux/slices/returnOrders.slice';
import { LeftRightHeaderContent } from "../../Controls/LeftRightHeaderContent";

const {Text} = Typography;
interface ReturnProductItem extends ProductOrderResponse {
    // ids: string[],
    total: number,
    quantity: number,
    totalDiscount: number,
    key: string,
}

const ReturnOrderDetailsComponent = (): JSX.Element => {
    const dispatch = useDispatch();
    const returnOrderId = useSelectorRoot(getReturnOrderId);
    const returnOrderDetail = useSelectorRoot(getReturnOrderDetail);
    const createReturnOrderLoading = useSelectorRoot(getCreateReturnOrderLoading);
    const [dataSource, setDataSource] = useState<ReturnProductItem[]>([]);
    const [selectedRow, setSelectedRow] = useState<ReturnProductItem[]>([]);
    const navigate = useNavigate();
    useEffect(() => {
        if (!returnOrderId) return;
        dispatch(fetchOrderById(returnOrderId))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [returnOrderId])

    useEffect(() => {
        const productsList = returnOrderDetail?.productOrders ?? [];
        const newDataSource = productsList.map(v => {
            const { salePrice, extendPrice, discount, voucherDiscount, id } = v;
            return {
                ...v,
                salePrice: salePrice + extendPrice,
                total: salePrice + extendPrice - discount - voucherDiscount,
                totalDiscount: discount + voucherDiscount,
                key: id,
            } as ReturnProductItem;
        })
        setDataSource(newDataSource);
    }, [returnOrderDetail?.productOrders])

    const columns: ColumnsType<ReturnProductItem> = [
        {
            title: "Mã SKU",
            key: "sku",
            dataIndex: "sku",
            width: '15%',
            sorter: (a: ReturnProductItem, b: ReturnProductItem) => a.sku > b.sku ? -1 : 1,
            render: (sku: string, record: ReturnProductItem) => (
                <Typography.Link
                    onClick={() => onSelectRow(record)}>
                    {sku}
                </Typography.Link>
            )
        },
        {
            title: "Tên sản phẩm",
            key: "productName",
            dataIndex: "productName",
            width: '34%',
            sorter: (a: ReturnProductItem, b: ReturnProductItem) => a.productName > b.productName ? -1 : 1,
            render: (name: string) => (
                <span>{name}</span>
            )
        },
        {
            title: "Giá hàng trả",
            key: "salePrice",
            dataIndex: "salePrice",
            width: '17%',
            sorter: (a: ReturnProductItem, b: ReturnProductItem) => a.salePrice > b.salePrice ? -1 : 1,
            render: (salePrice: number, record: ReturnProductItem) => (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span>{MathHelper.formatPriceVND(salePrice)}</span>
                    <span style={{ color: 'red', textDecoration: 'line-through' }}>
                        {MathHelper.formatPriceVND(record.totalDiscount)}
                    </span>
                </div>
            )
        },
        {
            title: "Thành tiền",
            key: "total",
            width: '17%',
            dataIndex: "total",
            sorter: (a: ReturnProductItem, b: ReturnProductItem) => a.total > b.total ? -1 : 1,
            render: (total: number) => (
                <span>{MathHelper.formatPriceVND(total)}</span>
            )
        },
        {
            title: "Trạng thái",
            key: "status",
            width: '17%',
            dataIndex: "status",
            sorter: (a: ReturnProductItem, b: ReturnProductItem) => a.isReturnedHistory > b.isReturnedHistory ? -1 : 1,
            render: (_: any, record: ReturnProductItem) => (
                <Tag color={record.isReturnedHistory ? 'green' : 'blue'}>
                    {record.isReturnedHistory ? 'Đã tạo đơn' : 'Chưa tạo đơn'}
                </Tag>
            )
        },
    ];

    const getTotal = () => {
        return selectedRow.map(v => v.total).reduce((p, c) => p + c, 0);
    }

    const handleCreateReturnOrder = async () => {
        if (!returnOrderId) return;
        const newReturnOrder: CreateOrderReturnCommand = {
            orderId: returnOrderId,
            productOrderIds: selectedRow.map(v => v.id),
            reason: 'test reason',
        };
        const res = await ProductOrderReturnApi.createReturnOrder(newReturnOrder);
        if(res?.status === 200) {
            message.info("Tạo đơn trả thành công.");
            const {id} = res.data;
            navigate(`/admin/orders/order_returns/${id}`);
        }else{
            dispatch(fetchReturnOrder());
            dispatch(setReturnOrderModalVis(false));
            message.error("Có lỗi xảy ra khi tạo đơn trả.")
        }
    }

    const renderFooter = (
        <Row>
            <Col span={12} />
            <Col span={12}>
                <LeftRightHeaderContent
                    leftContent={<span>Số lượng hàng trả</span>}
                    rightContent={
                        <span>{selectedRow.length}</span>
                    }
                />
                <LeftRightHeaderContent
                    leftContent={<Text strong>Tổng tiền trả khách</Text>}
                    rightContent={
                        <Text strong>
                            {MathHelper.formatPriceVND(getTotal(), true)}
                        </Text>
                    } />
                <div style={{ display: 'flex', justifyContent: 'end', marginTop: '30px' }}>
                    <Button
                        type="primary"
                        onClick={handleCreateReturnOrder}
                        icon={<FileAddOutlined />}>
                        Tạo đơn trả
                    </Button>
                </div>
            </Col>
        </Row>
    )

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: ReturnProductItem[]) => setSelectedRow(selectedRows),
        getCheckboxProps: (record: ReturnProductItem) => {
            return {
                disabled: record.isReturnedHistory
            }
        }
    };

    const onSelectRow = (record: ReturnProductItem) => {
        setSelectedRow(pre => {
            if(pre.some(v => v.id === record.key)){
                return pre.filter(v => v.id !== record.key)
            }else{
                return [...pre,...[record]]
            }
        })
    }

    return (
        <div style={{ margin: '20px auto' }}>
            <Table
                loading={createReturnOrderLoading}
                bordered
                title={() => <Typography.Title level={5}>{`Thông tin sản phẩm`}</Typography.Title>}
                dataSource={dataSource}
                columns={columns}
                footer={() => renderFooter}
                pagination={false}
                scroll={{ y: '500px' }}
                rowSelection={{
                    type: 'checkbox',
                    selectedRowKeys: selectedRow.map(v => v.id),
                    ...rowSelection,
                  }}
            />

        </div>
    )
}

const ReturnOrderDetails = React.memo(ReturnOrderDetailsComponent);
export default ReturnOrderDetails;
