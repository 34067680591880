import { Col, Form, Input, message, Modal, ModalProps, Row } from "antd";
import { useEffect, useState } from "react";
import { GetCities, GetDistricts } from "../../Api/AddressApi";
import { CreateAgencyApi, GetAgencyByIdApi, UpdateAgencyApi } from "../../Api/AgencyApi";
import { GetPoliciesApi, GetRolesApi } from "../../Api/RoleApis";
import { CreateUsersApi } from "../../Api/UserApi";
import { CreateAgencyViewModel } from "../../ViewModel/AgencyViewModel";
import CCombobox from "../Controls/CCombobox";

type AgencyModalProps = ModalProps &
{
    editAgency?: AgencyResponse;
    okModalFunc: Function,
}

type policyRes = {
    id: string;
    name: string;
}
export function AgencyModal(props: AgencyModalProps) {

    const { editAgency, okModalFunc, ...rest } = props;
    const [cities, setCities] = useState<CityResponse[]>([]);
    const [districts, setDistricts] = useState<DistrictResponse[]>([]);
    const [policies, setPolicies] = useState<policyRes[]>([]);

    const [agencyForm] = Form.useForm<CreateAgencyViewModel>();

    useEffect(() => {
        if (rest.visible) {

            GetCities((data: IListItems<CityResponse>) => { setCities(data.items); })

            // Tạo mới
            if (editAgency == null) {
                //Sao cần tạo Role cho đại lý (Tạo tài khoản cho đại lý)
                GetPoliciesApi((res: IListItems<policyRes>) => {
                    setPolicies(res.items);
                });

                agencyForm.setFieldsValue({
                    name: "",
                    cityId: "",
                    districtId: "",
                    taxNumber: "",
                    address: "",
                    phone: "",
                    email: ""
                });

            } else { // Chỉnh sửa
                GetAgencyByIdApi(editAgency.id, (res: AgencyResponse) => {
                    let cityId: string = "";
                    if (res.city) {
                        cityId = res.city.id;
                        cityChange(cityId);
                    }


                    let districtId: string = "";
                    if (res.district)
                        districtId = res.district.id;
                    agencyForm.setFieldsValue({
                        name: res.name,
                        cityId: cityId,
                        districtId: districtId,
                        taxNumber: res.taxNumber,
                        address: res.address,
                        phone: res.phoneNumber,
                        email: res.email,
                        representativePerson: res.representativePerson
                    });
                })

            }

        }

    }, [rest.visible])

    //Cập nhật quận
    function cityChange(value: any) {
        GetDistricts(value, (distrs: DistrictResponse[]) => {
            setDistricts(distrs);
        })
    }

    const CreateAgencySubmit = () => {
        agencyForm.validateFields()
            .then((data: CreateAgencyViewModel) => {

                if (!data.name || data.name === "") {
                    message.error("Tên đại lý cần được nhập");
                    return;
                }
                if (!data.email || data.email === "") {
                    message.error("Email không hợp lệ");
                    return;
                }

                if (editAgency) { //Cập nhật
                    UpdateAgencyApi(editAgency.id, {
                        cityId: data.cityId,
                        districtId: data.districtId,
                        email: data.email,
                        taxNumber: data.taxNumber === "" ? undefined : data.taxNumber,
                        address: data.address,
                        name: data.name,
                        phoneNumber: data.phone,
                        representativePerson: data.representativePerson
                    }, (res: AgencyResponse) => {

                        okModalFunc(res);
                    });
                } else { // Tạo mới
                    CreateAgencyApi({
                        cityId: data.cityId,
                        districtId: data.districtId,
                        email: data.email,
                        taxNumber: data.taxNumber === "" ? undefined : data.taxNumber,
                        address: data.address,
                        name: data.name,
                        phoneNumber: data.phone,
                        representativePerson: data.representativePerson
                    }, (res: AgencyResponse) => {

                        let roleIds: string[] = [];

                        for (let index = 0; index < policies.length; index++) {
                            const element = policies[index];
                            roleIds.push(element.id);
                        }

                        CreateUsersApi({
                            email: res.email,
                            address: res.address,
                            phoneNumber: res.phoneNumber,
                            sex: true,
                            name: res.representativePerson,
                            roleIds: roleIds,
                            joinTime: new Date(),
                            identityNumber: "",
                            organizationId: res.id,
                            policy: 1

                        }, (res: any) => {
                            okModalFunc(res);
                        })


                    })
                }

            })
    };


    return (

        <Modal title="Thêm đại lý mới" centered {...rest}
            style={{ paddingTop: 0, paddingBottom: 0 }}
            onOk={CreateAgencySubmit}>
            <Form form={agencyForm}
                layout="vertical" >
                <Form.Item name="name" label="Tên đại lý" required >
                    <Input placeholder="Nhập tên đại lý" />
                </Form.Item>
                <Row>
                    <Col span={12} style={{ paddingRight: 5 }}>
                        <Form.Item name="representativePerson" label="Người đại diện" required >
                            <Input placeholder="Nhập tên đại lý" />
                        </Form.Item>
                    </Col>

                    <Col span={12} style={{ paddingLeft: 5 }}>
                        <Form.Item name="taxNumber" label="MST" >
                            <Input placeholder="Mã số thuế" />
                        </Form.Item>

                    </Col>
                </Row>

                <Row>
                    <Col span={12} style={{ paddingRight: 5 }}>
                        <Form.Item label="Số điện thoại" required name="phone">
                            <Input placeholder="Nhập mã khách hàng" />
                        </Form.Item>
                    </Col>

                    <Col span={12} style={{ paddingLeft: 5 }}>
                        <Form.Item name="email" label="Email" required >
                            <Input placeholder="Nhập Email" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={12} style={{ paddingRight: 5 }}>
                        <Form.Item label="Tỉnh/Thành phố" name="cityId">
                            <CCombobox chooseOptions={cities} displayName="name" id="id" onChange={cityChange} />
                        </Form.Item>
                    </Col>

                    <Col span={12} style={{ paddingLeft: 5 }}>
                        <Form.Item label="Quận/Huyện" name="districtId">
                            <CCombobox chooseOptions={districts} displayName="name" id="id" />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item name="address" label="Địa chỉ" required >
                    <Input placeholder="Nhập địa chỉ" />
                </Form.Item>



            </Form>
        </Modal>
    )
}