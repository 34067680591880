import { AxiosRequest } from "./Axios/AxiosRequest";
import { HandleRequest } from "./RequestAPI";
import { SERVER_API } from "./UrlDefination";

export const GetCarriersApi = async (doAfterResponse: Function) => {

    await HandleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.CARRIER.GET}`), doAfterResponse);

}

//Create new product. Need to error handling
export const CreateCarrierApi = async (data: any, doAfterResponse: Function) => {

    await HandleRequest(AxiosRequest.post(`${SERVER_API.HOST}/${SERVER_API.CARRIER.CREATE}`, data), doAfterResponse);

}