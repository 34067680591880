import { Button, DatePicker, Space, Table } from "antd";
import moment, { Moment } from "moment";
import { subDays } from "date-fns";
import { useEffect, useState } from "react";
import { SaleReportApi } from "../../../Api/Reports/SaleReportApi";
import { ColumnsType } from "antd/lib/table";
import { CommonHelper } from "../../../Helper/CommonHelper";
import {
    ArrowLeftOutlined
} from '@ant-design/icons';
import { filterTypes, OrderStatus } from "../../../common/type-define";
import { ChoosFilter, FilterControls } from "../../Controls/FilterControls";
import { MathHelper } from "../../../Helper/math.helper";
import { headerStyle } from "../../Helper/UIHelper";
const { RangePicker } = DatePicker;

export default function ShipReport() {
    const [data, setData] = useState<IShipReport[]>([])
    const [startDate, setStartDate] = useState<Date>(subDays(new Date(), 7));
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [filters, setFilters] = useState<FilterItem[]>([]);
    useEffect(() => {
        fetchData()
        setFilters([
            { type: filterTypes.WAREHOUSE, closeable: true },
            { type: filterTypes.CARRIER, closeable: true }]);
    }, []);

    function fetchData() {
        let re: IShipReport[] = [];
        const query = {
            QueryFrom: startDate,
            QueryTo: endDate
        }
        SaleReportApi.GetOrderReportsApi(query).then((Orders) => {
            const orders: IOrderReport[] = Orders.data;
            console.log(orders)
            orders.forEach(p => {
                if (p.orderStatus === OrderStatus.Activated ||
                    p.orderStatus === OrderStatus.Completed) {
                    re.push({
                        carrierCode: p.orderCarrier.carrierCode,
                        totalProductAmount: p.productOrders.length,
                        carrierPrice: p.orderCarrier.price,
                        shippingFee: p.shippingDetail.shippingFee,
                        codMoney: p.orderPaidHistories[0].paymentType === 0 ?
                            CommonHelper.calculateTotalPriceInOrder(p.productOrders) : 0
                    })
                }

            })

            setData(re);


        })
    }

    const columns: ColumnsType<IShipReport> = [
        {
            title: 'Mã vận đơn',
            dataIndex: 'carrierCode',
            key: 'carrierCode',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Số lượng hàng giao',
            dataIndex: 'totalProductAmount',
            key: 'totalProductAmount',
            align: 'center',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Phí vận chuyển trả vận chuyển',
            dataIndex: 'carrierPrice',
            key: 'carrierPrice',
            onHeaderCell: () => headerStyle(),
            render: (carrierPrice) => (
                MathHelper.formatPriceVND(carrierPrice, true)
            )
        },
        {
            title: 'Tiền ship',
            dataIndex: 'shippingFee',
            key: 'shippingFee',
            onHeaderCell: () => headerStyle(),
            render: (shippingFee) => (
                MathHelper.formatPriceVND(shippingFee, true)
            )
        },
        {
            title: 'Tiền COD',
            dataIndex: 'codMoney',
            key: 'codMoney',
            onHeaderCell: () => headerStyle(),
            render: (codMoney) => (
                MathHelper.formatPriceVND(codMoney, true)
            )
        }
    ]

    function changeRangeTime(values: any, formatString: [string, string]) {
        const startTime: Moment = values[0];
        const endTime: Moment = values[1];

        setStartDate(startTime.toDate());
        setEndDate(endTime.toDate());
        fetchData();
    }

    function onChangeFilter() {

    }

    return (
        <div>
            <Space direction='horizontal'>
                <Button type="default" icon={<ArrowLeftOutlined />} href="/admin/report/sale"></Button>

                <RangePicker format='DD/MM/YYYY'
                    clearIcon={false}
                    style={{ width: '220px' }}
                    onChange={changeRangeTime}
                    defaultValue={[moment(subDays(new Date(), 7)), moment()]} />

                <ChoosFilter filterList={filters} onChangeFunc={(items: FilterItem[]) => setFilters(items)} />

            </Space>
            <FilterControls items={filters} afterChangeFnc={onChangeFilter} />
            <Table dataSource={data} columns={columns} size="small" bordered

                pagination={false}
            />
        </div>
    )
}