import { AxiosRequest } from "./Axios/AxiosRequest";

import { CompanyReq } from "../Model/CompanyModel";
import { HandleRequest } from "./RequestAPI";
import { MST, SERVER_API } from "./UrlDefination";
import axios from "axios";

export const GetCompaniessApi = async (doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.COMPANY.GET}`), doAfterResponse);
};

export const CreateCompanyApi = async (data: CompanyReq, doAfterResponse: Function) => {

    await HandleRequest(AxiosRequest.post(`${SERVER_API.HOST}/${SERVER_API.COMPANY.CREATE}`, data), doAfterResponse);
}

export const UpdateCompanyApi = async (id: string, data: any, doAfterResponse: Function) => {

    await HandleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.COMPANY.UPDATE(id)}`, data), doAfterResponse);

}

export const GetTaxNumberInforApi = async (taxNumber: string, doAfterResponse: Function) => {
    await axios.get(`https://tracuumst.com/tim-kiem?q=0304787156&s=auto`).then((res: any) => {
        doAfterResponse(res);
    }).catch((error) => {
        doAfterResponse(error);
    });
};