export function getHeightFit(adjust: number): string {
    return `calc(100vh - ${adjust}px)`;
}


export function cellStyle(wrap: boolean = false): React.HTMLAttributes<any> {
    return {
        style: {
            whiteSpace: wrap ? 'normal' : 'nowrap',
            maxWidth: 300,
            minWidth: '100%'
        }
    }
}

export function headerStyle(): React.HTMLAttributes<any> {
    return {
        style: {
            background: '#1870A6',
            color: 'white',
            whiteSpace: 'nowrap',
        }
    }
}