import React from 'react';
import {
    BrowserRouter, Navigate, Route, Routes
} from "react-router-dom";
import './App.css';
import AdminControl from './Components/adminControl';
import { AgenciesView } from './Components/Agencies/AgenciesView';
import IncomesView from './Components/Bills/IncomesView';
import ReceptionsView from './Components/Bills/ReceptionsView';
import AdminConfigure from './Components/configure';
import AddPartner from './Components/Customers/AddPartner';
import CustomersView from './Components/Customers/CustomersView';
import PartnerView from './Components/Customers/PartnerView';
import DefectProductsView from './Components/DefectProducts/DefectProductsView';
import InventoryCreate from './Components/Inventories/InventoryCreate';
import InventoryDetail from './Components/Inventories/InventoryDetail';
import InventoryView from './Components/Inventories/InventoryView';
import LoginView from './Components/LoginView';
import CreateaccessorytView from './Components/Products/CreateaccessorytView';
import CreateProductView from './Components/Products/CreateProductView';
import { EditProductView } from './Components/Products/EditProductView';
import ProductsView from './Components/Products/ProductsView';
import StoreManagerView from './Components/Products/StoreManagerView';
import { ProvidersView } from './Components/Providers/ProvidersView';
import CreatePurchaseOrderView from './Components/Purchase/CreatePurchaseOrderView';
import EditPurchaseOrderView from './Components/Purchase/EditPurchaseOrderView';
import PurchaseOrdersView from './Components/Purchase/PurchaseOrdersView';
import PurchaseProcessView from './Components/Purchase/PurchaseProcessView';
import CustomerReportView from './Components/Reports/CustomerReportView';
import ByProductReport from './Components/Reports/Customers/ByProductReport';
import ByTimeReport from './Components/Reports/Customers/ByTimeReport';
import FinanceReportView from './Components/Reports/Finances/FinanceReportView';
import VouchersReportView from './Components/Reports/Finances/VouchersReportView';
import PurchaseReportView from './Components/Reports/PurchaseReportView';
import DebtReport from './Components/Reports/Purchases/DebtReport';
import PurchaseByPaymentReport from './Components/Reports/Purchases/PurchaseByPaymentReport';
import PurchaseSummaryReport from './Components/Reports/Purchases/PurchaseSummaryReport';
import SaleReportView from './Components/Reports/SaleReportView';
import ByPaymentReport from './Components/Reports/Sales/ByPaymentReport';
import CustomerDebtReport from './Components/Reports/Sales/CustomerDebtReport';
import DetailReport from './Components/Reports/Sales/DetailReport';
import ProcessingReport from './Components/Reports/Sales/ProcessingReport';
import ReturnProductReport from './Components/Reports/Sales/ReturnProductReport';
import ShipReport from './Components/Reports/Sales/ShipReport';
import SummarySaleReport from './Components/Reports/Sales/SummarySaleReport';
import DetailRemainingReportView from './Components/Reports/Store/DetailRemainingReportView';
import InventoryNormReportView from './Components/Reports/Store/InventoryNormReportView';
import InventoryReportView from './Components/Reports/Store/InventoryReportView';
import RemainingReportView from './Components/Reports/Store/RemainingReportView';
import StoreReportView from './Components/Reports/StoreReportView';
import SummaryReport from './Components/Reports/SummaryReport';
import MaintenanceReport from './Components/Reports/Warranties/MaintenanceReport';
import MaintenanceScheduleReport from './Components/Reports/Warranties/MaintenanceScheduleReport';
import WarantyReport from './Components/Reports/Warranties/WarantyReport';
import WarrantyReportView from './Components/Reports/WarrantyReportView';
import CreateOrderView from './Components/Sale/CreateOrderView';
import EditOrderView from './Components/Sale/EditOrderView';
import OrdersView from './Components/Sale/OrdersView';
import ProcessOrderView from './Components/Sale/ProcessOrderView';
import ProcessReturnOrderView from './Components/Sale/ProcessReturnOrderView';
import ReturnOrdersView from './Components/Sale/ReturnOrdersView';
import { RolesView } from './Components/settings/RolesView';
import { SaleChanelsView } from './Components/settings/SaleChanelsView';
import { StaffsView } from './Components/settings/StaffsView';
import SyncReportView from './Components/settings/SyncReportView';
import { VouchersView } from './Components/settings/Voucher/VouchersViews';
import WarrantyCenterView from './Components/settings/WarrantyCenterView';
import { CreateVoucherView } from './Components/Vouchers/CreateVoucherView';
import { BranchsView } from './Components/WareHouses/BranchsView';
import WarrantiesView from './Components/Warranty/WarrantiesView';
import { checkRequireLogin, CommonHelper } from './Helper/CommonHelper';
import Page from './Page';

interface IRouteItem {
    path: string,
    element: JSX.Element,
    subPath?: IRouteItem[],
    headerTitle: string,
}

const ROUTE_ITEMS: IRouteItem[] = [
    // {
    //     path: "login",
    //     element: <Login />
    // },
    {
        path: "/",
        element: <Navigate replace to="/admin/dashboard" />,
        headerTitle: CommonHelper.getDashboardTitle(),
    },
    {
        path: "admin",
        element: <Navigate replace to="/admin/dashboard" />,
        headerTitle: CommonHelper.getDashboardTitle(),
    },
    {
        path: "admin",
        element: <AdminControl />,
        headerTitle: CommonHelper.getDashboardTitle(),
        subPath: [
            {
                path: "dashboard",
                element: <SummaryReport />,
                headerTitle: CommonHelper.getDashboardTitle(),
            },
            {
                path: "products",
                element: <ProductsView />,
                headerTitle: "Danh sách sản phẩm",
            },
            {
                path: "products/:id",
                element: <EditProductView />,
                headerTitle: "Chi tiết sản phẩm",
            },
            {
                path: "products/create",
                element: <CreateProductView />,
                headerTitle: "Thêm sản phẩm",
            },
            {
                path: "products/create_accessory/:id",
                element: <CreateaccessorytView />,
                headerTitle: "Thêm mới phụ kiện",
            },
            // Defect products
            {
                path: "defect_products",
                element: <DefectProductsView />,
                headerTitle: "Danh sách sản phẩm lỗi",
            },
            // orders
            {
                path: "orders",
                element: <OrdersView />,
                headerTitle: "Danh sách đơn hàng"
            },
            {
                path: "orders/create",
                element: <CreateOrderView />,
                headerTitle: "Tạo đơn hàng"

            },
            {
                path: "orders/order_returns",
                element: <ReturnOrdersView />,
                headerTitle: "Danh sách đơn trả hàng"
            },
            {
                path: "orders/order_returns/:id",
                element: <ProcessReturnOrderView />,
                headerTitle: "Chi tiết đơn trả"
            },
            {
                path: "orders/:id",
                element: <ProcessOrderView />,
                headerTitle: "Chi tiết đơn hàng"
            },
            {
                path: "orders/edit/:id",
                element: <EditOrderView />,
                headerTitle: "Chỉnh sửa đơn hàng"
            },
            // customers
            {
                path: "customers",
                element: <CustomersView />,
                headerTitle: "Danh sách khách hàng"
            },
            {
                path: "customers/agencies",
                element: <AgenciesView />,
                headerTitle: "Danh sách đại lý"
            },
            {
                path: "customers/partner",
                element: <PartnerView />,
                headerTitle: "Danh sách cộng tác viên",
            },
            {
                path: "customers/partner/add_partner",
                element: <AddPartner />,
                headerTitle: "Thêm cộng tác viên",
            },
            // Sổ quỹ
            {
                path: "bills/income",
                element: <IncomesView />,
                headerTitle: "Danh sách phiếu thu",
            },
            {
                path: "bills/reception",
                element: <ReceptionsView />,
                headerTitle: "Danh sách phiếu chi",
            },
            // Input into store
            {
                path: "purchase_orders",
                element: <PurchaseOrdersView />,
                headerTitle: "Danh sách đơn nhập hàng",
            },
            {
                path: "purchase_orders/create",
                element: <CreatePurchaseOrderView />,
                headerTitle: "Tạo đơn nhập hàng",
            },
            {
                path: "purchase_orders/:id",
                element: <PurchaseProcessView />,
                headerTitle: "Chi tiết đơn nhập hàng",
            },
            {
                path: "purchase_orders/edit/:id",
                element: <EditPurchaseOrderView />,
                headerTitle: "Chỉnh sửa đơn nhập hàng"
            },
            // Store adjustment - Inventory
            {
                path: "inventory_create",
                element: <InventoryCreate />,
                headerTitle: "Tạo phiếu kiểm",
            },
            {
                path: "inventory",
                element: <InventoryView />,
                headerTitle: "Danh sách phiếu kiểm",
            },
            {
                path: "inventory/:id",
                element: <InventoryDetail />,
                headerTitle: "Chi tiết phiếu kiểm",
            },
            // Store manager
            {
                path: "variants",
                element: <StoreManagerView />,
                headerTitle: "Quản lý kho",
            },
            // Providers
            {
                path: "providers",
                element: <ProvidersView />,
                headerTitle: "Danh sách nhà cung cấp",
            },
            // Warranty manager
            {
                path: "warranties",
                element: <WarrantiesView />,
                headerTitle: "Quản lý bảo hành",
            },
            // Setting
            {
                path: "settings",
                element: <AdminConfigure />,
                headerTitle: "Cấu hình",
            },
            {
                path: "settings/branchs",
                element: <BranchsView />,
                headerTitle: "Danh sách chi nhánh",
            },
            // Setting Role
            {
                path: "settings/staffs",
                element: <StaffsView />,
                headerTitle: "Danh sách nhân viên"
            },
            // Setting Voucher
            {
                path: "settings/vouchers",
                element: <VouchersView />,
                headerTitle: "Quản lý khuyến mại",
            },
            {
                path: "vouchers/Create",
                element: <CreateVoucherView />,
                headerTitle: "Tạo voucher",
            },
            // Setting Role
            {
                path: "settings/roles",
                element: <RolesView />,
                headerTitle: "Danh sách vai trò",
            },
            {
                path: "settings/channels",
                element: <SaleChanelsView />,
                headerTitle: "Danh sách kênh bán hàng"
            },
            {
                path: "settings/warranty_centers",
                element: <WarrantyCenterView />,
                headerTitle: "Danh sách trung tâm bảo hành"
            },
            {
                path: "settings/sync",
                element: <SyncReportView />,
                headerTitle: "Đồng bộ dữ liệu cho báo cáo"
            },
            {
                path: "report/sale",
                element: <SaleReportView />,
                headerTitle: "Báo cáo bán hàng"
            },
            {
                path: "report/sale/summary",
                element: <SummarySaleReport />,
                headerTitle: "Tổng hợp bán hàng"
            },
            {
                path: "report/sale/detail",
                element: <DetailReport />,
                headerTitle: "Chi tiết bán hàng"
            },
            {
                path: "report/sale/ship",
                element: <ShipReport />,
                headerTitle: "Báo cáo tình trạng chuyển đơn hàng"
            },
            {
                path: "report/sale/processing",
                element: <ProcessingReport />,
                headerTitle: "Tình hình xử lý đơn hàng"
            },
            {
                path: "report/sale/return",
                element: <ReturnProductReport />,
                headerTitle: "Tình hình trả lại hàng"
            },
            {
                path: "report/sale/payment",
                element: <ByPaymentReport />,
                headerTitle: "Báo cáo bán hàng theo hình thức thanh toán"
            },
            {
                path: "report/sale/customer_debt",
                element: <CustomerDebtReport />,
                headerTitle: "Công nợ phải thu"
            },
            {
                path: "report/purchase",
                element: <PurchaseReportView />,
                headerTitle: "Danh sách báo cáo nhập hàng",
            },
            {
                path: "report/purchase/summary",
                element: <PurchaseSummaryReport />,
                headerTitle: "Tổng hợp báo cáo nhập hàng",
            },
            {
                path: "report/purchase/by_payment",
                element: <PurchaseByPaymentReport />,
                headerTitle: "Tổng hợp thanh toán nhập hàng",
            },
            {
                path: "report/purchase/debt",
                element: <DebtReport />,
                headerTitle: "Báo cáo công nợ",
            },
            {
                path: "report/store",
                element: <StoreReportView />,
                headerTitle: "Báo cáo kho",
            },
            {
                path: "report/store/remaining",
                element: <RemainingReportView />,
                headerTitle: "Báo cáo tồn kho hệ thống",
            },
            {
                path: "report/store/remaining_detail",
                element: <DetailRemainingReportView />,
                headerTitle: "Báo cáo tồn kho chi tiết",

            },
            {
                path: "report/store/remaining_norm",
                element: <InventoryNormReportView />,
                headerTitle: "Báo cáo tồn kho định mức",

            },
            {
                path: "report/store/inventory",
                element: <InventoryReportView />,
                headerTitle: "Báo cáo kiểm kê hàng hóa",

            },
            {
                path: "report/finance",
                element: <FinanceReportView />,
                headerTitle: "Báo cáo tài chính"
            },
            {
                path: "report/bills",
                element: <VouchersReportView />,
                headerTitle: "Sổ quỹ"
            },
            {
                path: "report/customer",
                element: <CustomerReportView />,
                headerTitle: "Báo cáo khách hàng"
            },
            {
                path: "report/customer/by_time",
                element: <ByTimeReport />,
                headerTitle: "Báo cáo khách hàng theo thời gian"
            },
            {
                path: "report/customer/by_product",
                element: <ByProductReport />,
                headerTitle: "Báo cáo khách hàng theo sản phẩm"
            },
            {
                path: "report/warranty",
                element: <WarrantyReportView />,
                headerTitle: "Báo cáo bảo hành - bảo trì"
            },
            {
                path: "report/warranties",
                element: <WarantyReport />,
                headerTitle: "Báo cáo bảo hành"
            },
            {
                path: "report/maintances",
                element: <MaintenanceReport />,
                headerTitle: "Báo cáo bảo trì"
            },
            {
                path: "report/maintances/schedule",
                element: <MaintenanceScheduleReport />,
                headerTitle: "Báo cáo kế hoạch bảo trì"
            },
        ]
    },
]

const App = () => {

    return (
        <BrowserRouter>
            {
                checkRequireLogin() ?
                    <Routes>
                        {
                            ROUTE_ITEMS.map((item, index) => {
                                if (!item.subPath) {
                                    return (
                                        <Route
                                            key={item.path}
                                            path={item.path}
                                            element={
                                                <Page headerTitle={item.headerTitle}>
                                                    {item.element}
                                                </Page>
                                            } />
                                    )
                                }
                                return (
                                    <Route
                                        key={item.path}
                                        path={item.path}
                                        element={item.element}>
                                        {
                                            item.subPath.map((sub, i) =>
                                                <Route
                                                    key={i}
                                                    path={sub.path}
                                                    element={
                                                        <Page headerTitle={sub.headerTitle}>
                                                            {sub.element}
                                                        </Page>
                                                    } />
                                            )
                                        }
                                    </Route>
                                )
                            })
                        }
                    </Routes>
                    :
                    <LoginView />
            }

        </BrowserRouter>
    );
}

export default App;
