import { AxiosRequest } from "./Axios/AxiosRequest";

import { HandleRequest } from "./RequestAPI";
import { SERVER_API } from "./UrlDefination";

export const GetPaymentsApi = async (doAfterResponse: Function) => {

    await HandleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.PAYMENT.GET}`), doAfterResponse);

}

export const CreatePaymentApi = async (data: any, doAfterResponse: Function) => {

    await HandleRequest(AxiosRequest.post(`${SERVER_API.HOST}/${SERVER_API.PAYMENT.CREATE}`, data), doAfterResponse);
}