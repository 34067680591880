import { Form, Input, Modal, ModalProps } from "antd";
import { useEffect } from "react";
import { CreateCarrierApi } from "../../Api/CarrierApi";
import { CarrierModelReq, CarrierModelResponse } from "../../Model/CarrierModel";

type carrierProps = ModalProps & {
    id?: string,
    okModalFnc: Function
}

export default function CarrierModal(props: carrierProps) {
    const { id, okModalFnc, ...rest } = props;
    const [carrierForm] = Form.useForm<CarrierModelReq>();
    useEffect(() => {

    }, [rest.visible])


    function createCarrier() {
        carrierForm.validateFields().then((data: CarrierModelReq) => {
            data.isAvailable = true;
            data.isDefault = true;
            CreateCarrierApi(data, (res: CarrierModelResponse) => {
                okModalFnc(res);
            })
        })
    }

    return (
        <Modal {...rest}
            okText="Đồng ý"
            onOk={createCarrier}>

            <Form form={carrierForm}>
                <Form.Item label="Tên đơn vị vận chuyển" name="name">
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    )
}