import { Col, Form, Input, message, Modal, ModalProps, Row } from "antd";

import { useEffect } from "react";
import { CreateProviderApi, UpdateProviderApi } from "../../Api/ProviderApi";
import IProviderRespone, { IProviderRequest } from "../../Model/ProviderModel";


import {
    VerticalAlignBottomOutlined,
    SearchOutlined,
    LeftOutlined
} from '@ant-design/icons';
import { CheckTaxNumberView } from "../Helper/CheckTaxNumber";

type ProviderModalProps = ModalProps & {
    editData: IProviderRespone | null,
    okModalFunc: Function
}

export function ProviderModal(props: ProviderModalProps) {

    const { editData, okModalFunc, ...rest } = props;

    const [providerForm] = Form.useForm<IProviderRequest>();

    useEffect(() => {
        if (rest.visible) {
            if (editData != null) {
                providerForm.setFieldsValue(editData)
            } else {
                providerForm.setFieldsValue({
                    name: "",
                    address: "",
                    phoneNumber: "",
                    taxNumber: "",
                    representativePerson: ""
                })
            }
        }
    }, [rest.visible])

    const providerModalSubmit = (e: React.MouseEvent<HTMLElement>) => {
        providerForm.validateFields()
            .then((data: IProviderRequest) => {
                if (editData != null) {
                    UpdateProviderApi(editData.id, data, (res: any) => {
                        okModalFunc(res);
                        message.info("Đã cập nhật thông tin nhà cung cấp");
                    })
                } else {
                    CreateProviderApi(data, (res: any) => {
                        okModalFunc(res);
                        message.info("Đã tạo nhà cung cấp mới");
                    });
                }

            })
            .catch(() => {
                message.error("Hệ thống gặp trục trặc nên reload lại trang")
            });
        //Xử lý logic ở đây


    }


    const checkTaxNumber = () => {
        const taxNumber = providerForm.getFieldValue("taxNumber");
        if (taxNumber)
            CheckTaxNumberView(taxNumber);
    }

    return (
        <Modal {...rest} centered
            style={{ paddingTop: 0, paddingBottom: 0 }}
            onOk={providerModalSubmit} >
            <Form form={providerForm}
                layout="vertical" >

                <Form.Item name="name" label="Tên nhà cung cấp" >
                    <Input placeholder="Nhập tên nhà cung cấp" />

                </Form.Item>

                <Form.Item name="taxNumber" label="Mã số thuế" >
                    <Input placeholder="Nhập mã số thuế nhà cung cấp" addonAfter={<SearchOutlined onClick={checkTaxNumber} style={{ color: "#40A9FF" }} />} />

                </Form.Item>

                <Form.Item name="address" label="Địa chỉ nhà cung cấp" >
                    <Input placeholder="Nhập địa chỉ nhà cung cấp" />

                </Form.Item>

                <Row>
                    <Col span={11}>
                        <Form.Item name="phoneNumber" label="Số điện thoại" >
                            <Input placeholder="Nhập số điện thoại " />
                        </Form.Item>
                    </Col>
                    <Col span={1} />
                    <Col span={12}>
                        <Form.Item name="representativePerson" label="Người đại diện" >
                            <Input placeholder="Nhập người đại diện " />
                        </Form.Item>
                    </Col>
                </Row>



            </Form>
        </Modal>
    )
}