import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { catchError, concat, filter, from, switchMap, withLatestFrom } from "rxjs";
import { RoleApi } from "../../Api/RoleApis";
import { CreateRoleGroupCommand, RoleGroupResponse, RoleResponse, UpdateRolePayload } from "../../Model/RoleModel";
import { RootEpic } from "../configure/rootReducer";


interface RoleActionState {
    roleList: RoleResponse[];
    roleGroupDetails: RoleGroupResponse | null;
    roleGroup: RoleResponse[];
    fetchRoleGroupLoading: boolean;
    roleModalVis: boolean;
}

const initState: RoleActionState = {
    roleList: [],
    roleGroupDetails: null,
    roleGroup: [],
    fetchRoleGroupLoading: false,
    roleModalVis: false,
}

const roleActionSlice = createSlice({
    name: 'roleAction',
    initialState: initState,
    reducers: {
        fetchRoles(state, action: PayloadAction<undefined>) { return },
        setRoleList(state, action: PayloadAction<RoleResponse[]>) {
            state.roleList = action.payload;
        },
        fetchRoleGroupById(state, action: PayloadAction<string>) { return },
        setRoleGroupDetails(state, action: PayloadAction<RoleGroupResponse | null>) {
            state.roleGroupDetails = action.payload;
        },
        fetchRoleGroup(state, action: PayloadAction<undefined>) { return },
        setRoleGroup(state, action: PayloadAction<RoleResponse[]>) {
            state.roleGroup = action.payload;
        },
        setFetchRoleGroupLoading(state, action: PayloadAction<boolean>) {
            state.fetchRoleGroupLoading = action.payload;
        },
        updateRole(state, action: PayloadAction<UpdateRolePayload>) { return },
        createRole(state, action: PayloadAction<CreateRoleGroupCommand>) { return },
        setRoleModalVis(state, action: PayloadAction<boolean>) {
            state.roleModalVis = action.payload;
        },
    }
});

const fetchRoles$: RootEpic = (action$, state$) => action$.pipe(
    filter(fetchRoles.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {

        return concat(
            from(RoleApi.getAllRoles(() => null)).pipe(
                switchMap(response => {
                    if (response?.status === 200) {
                        return [
                            setRoleList(response.data.items)
                        ]
                    }
                    return []
                }),
                catchError(err => [])
            )
        )
    })
)

const fetchRoleGroupById$: RootEpic = (action$, state$) => action$.pipe(
    filter(fetchRoleGroupById.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const roleId = action.payload;
        return concat(
            from(RoleApi.getRoleById(roleId, () => null)).pipe(
                switchMap(response => {
                    if (response?.status === 200) {
                        return [
                            setRoleGroupDetails(response.data),

                        ]
                    }
                    return []
                }),
                catchError(err => [])
            )
        )
    })
)

const fetchRoleGroup$: RootEpic = (action$, state$) => action$.pipe(
    filter(fetchRoleGroup.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {

        return concat(
            [roleActionSlice.actions.setFetchRoleGroupLoading(true)],

            from(RoleApi.getRoleGroup(() => null)).pipe(
                switchMap(response => {
                    if (response?.status === 200) {
                        return [
                            setRoleGroup(response.data.items),
                            roleActionSlice.actions.setFetchRoleGroupLoading(false)
                        ]
                    }
                    return [roleActionSlice.actions.setFetchRoleGroupLoading(false)]
                }),
                catchError(err => [roleActionSlice.actions.setFetchRoleGroupLoading(false)])
            )
        )
    })
)

const updateRole$: RootEpic = (action$, state$) => action$.pipe(
    filter(updateRole.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const { id, requestData } = action.payload;

        return concat(
            from(RoleApi.updateRole(id, requestData, () => null)).pipe(
                switchMap(response => {
                    if (response?.status === 200) {
                        return [
                            fetchRoleGroup(),
                            setRoleModalVis(false)
                        ]
                    }
                    return [setRoleModalVis(false)]
                }),
                catchError(err => [setRoleModalVis(false)])
            )
        )
    })
)

const createRole$: RootEpic = (action$, state$) => action$.pipe(
    filter(createRole.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const payload = action.payload;

        return concat(
            from(RoleApi.createRole(payload, () => null)).pipe(
                switchMap(response => {
                    if (response?.status === 200) {
                        return [
                            fetchRoleGroup(),
                            setRoleModalVis(false)
                        ]
                    }
                    return [setRoleModalVis(false)]
                }),
                catchError(err => [setRoleModalVis(false)])
            )
        )
    })
)


export const RoleActionEpics = [
    fetchRoles$,
    fetchRoleGroupById$,
    fetchRoleGroup$,
    updateRole$,
    createRole$,
]

export const {
    fetchRoles,
    setRoleList,
    fetchRoleGroupById,
    setRoleGroupDetails,
    fetchRoleGroup,
    setRoleGroup,
    updateRole,
    createRole,
    setRoleModalVis,
} = roleActionSlice.actions;

export default roleActionSlice.reducer;