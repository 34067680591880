import React, { useState } from "react";
import { useReactToPrint } from "react-to-print";
import { ICustomerInfo } from "../common/type-define";
import { format } from 'date-fns';
interface PrintResult {
    setPrintContent: (content: React.ReactInstance | null) => void,
    isPrintLoading: boolean,
    callbackHandlePrint: (() => void) | undefined,
    setExtraInfo: (info: ICustomerInfo | null) => void,
}

function renderDivWrap() {
    const divWrap = document.createElement('div');
    divWrap.style.height = '100%';
    divWrap.style.display = 'flex';
    divWrap.style.flexDirection = 'column';
    divWrap.style.alignItems = 'center';
    divWrap.style.padding = "8px 16px";
    divWrap.style.fontFamily = "Time New Roman"
    return divWrap;
}



function renderLogoHeader() {

    const headerDiv = document.createElement('div');
    headerDiv.style.marginTop = "20px";
    headerDiv.style.marginBottom = "20px";
    headerDiv.style.display = 'flex';
    //headerDiv.style.flexDirection = 'column';
    headerDiv.style.justifyContent = 'start';
    //headerDiv.style.alignItems = 'center';

    const header = document.createElement('div');
    header.innerHTML = "<div>Công ty cổ phần thiết bị thông minh Phúc Vinh </div> <div> Season Avenue, Mỗ Lao, Hà Đông, Hà Nội </div> <div>5A Nguyễn Đăng Giai, Thảo Điền, Thủ Đức, TP.HCM</div>";
    header.style.fontSize = '14px';
    header.style.display = "inline-block";

    const logo = document.createElement('div');
    logo.style.display = "inline-block";
    logo.style.marginRight = "30px";
    logo.style.marginRight = "10px";
    //logo.style.position = 'relative';


    logo.innerHTML = '<image src="/static/media/pv-logo-01.7ad61fee.png" display="block" height="60px" margin-bottom="90px" />'

    headerDiv.appendChild(logo);

    headerDiv.appendChild(header);
    return headerDiv
}

function renderHeader() {
    const header = document.createElement('span');
    header.innerHTML = "HÓA ĐƠN BÁN HÀNG";
    header.style.fontSize = '30px';
    return header
}

function renderSubHeader(extraInfo: ICustomerInfo | null) {
    const subHeader = document.createElement('div');
    subHeader.style.display = 'flex';
    subHeader.style.justifyContent = 'space-between';
    subHeader.style.width = '100%';

    subHeader.style.margin = '30px auto';
    if (extraInfo) {
        const customerSection = document.createElement('div');
        customerSection.style.display = 'flex';
        customerSection.style.justifyContent = 'start';
        customerSection.style.width = '100%';
        customerSection.style.flexDirection = 'column';
        [
            { title: 'Tên khách hàng', field: 'fullName' },
            { title: 'Số điện thoại', field: 'phoneNumber' },
            { title: 'Địa chỉ', field: 'address' },
        ].forEach(v => {
            const span = document.createElement('span');
            //span.style.width = '500px'
            span.innerHTML = `${v.title}: <span style="font-weight: 700">${(extraInfo as any)[v.field]}</span>`;
            customerSection.appendChild(span)
        })
        subHeader.appendChild(customerSection)
    }
    return subHeader
}

function renderDateSection() {
    const dateSection = document.createElement('span');
    dateSection.innerHTML = `Ngày: <span style="font-weight: 700">${format(new Date(new Date()), "dd-MM-yyyy")}</span>`;
    dateSection.style.display = 'flex';
    dateSection.style.width = '250px';
    dateSection.style.justifyContent = 'end';
    return dateSection;
}

function renderFooter() {
    const footer = document.createElement('div');
    footer.innerHTML = `<span style="font-style: italic">Cảm ơn quý khách!</span>`;
    //footer.style.position = 'fixed';
    footer.style.bottom = '40px';
    footer.style.width = '100%';
    footer.style.textAlign = 'center';
    return footer
}

function renderHotlineFooter() {
    const footer = document.createElement('div');
    footer.innerHTML = `<span style="font-style: italic;  margin-right:30px"">Hotline: 0912.999.556 </span> <span style="font-style: italic; margin-left:30px"> website:https://phucvinh.tech</span>`;
    //footer.style.position = 'fixed';
    footer.style.bottom = '10px';
    footer.style.width = '100%';
    footer.style.textAlign = 'center';
    return footer
}


function renderTemplate(printContent: React.ReactInstance | null, extraInfo: ICustomerInfo | null) {
    const refClone = (printContent as any).cloneNode(true);

    const contentDiv = document.createElement('div');
    contentDiv.style.fontFamily = "Time New Roman"
    const divWrap = renderDivWrap();
    const logo = renderLogoHeader();
    const header = renderHeader();
    const subHeader = renderSubHeader(extraInfo);
    const dateSection = renderDateSection();
    const footer = renderFooter();
    const hotLine = renderHotlineFooter();
    subHeader.appendChild(dateSection);

    //divWrap.appendChild(logo);
    divWrap.appendChild(header);
    divWrap.appendChild(subHeader);
    divWrap.appendChild(refClone);
    divWrap.appendChild(footer);
    divWrap.appendChild(hotLine);

    contentDiv.appendChild(logo);
    contentDiv.appendChild(divWrap);
    return contentDiv;
}

export const usePrint = () => {
    const [printContent, setPrintContent] = useState<React.ReactInstance | null>(null);
    const [extraInfo, setExtraInfo] = useState<ICustomerInfo | null>(null);
    const [isPrintLoading, setLoading] = useState<boolean>(false);

    const callbackHandlePrint = useReactToPrint({
        content: () => renderTemplate(printContent, extraInfo),
        documentTitle: "Phúc Vinh",
        onBeforeGetContent: () => setLoading(true),
        onAfterPrint: () => setLoading(false),
        removeAfterPrint: true,
    });

    const printResult: PrintResult = {
        setPrintContent,
        isPrintLoading,
        callbackHandlePrint,
        setExtraInfo,
    }

    return printResult;
}