import { Card, Spin, Image, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { ProductApi } from "../../../Api/ProductApi";

const { Meta } = Card;
interface ProductExpandItemProps {
    productResponse: ProductResponse;
}

const ProductExpandItemComponent = ({ productResponse }: ProductExpandItemProps): JSX.Element => {

    const [dataAccessory, setDataAccessory] = useState<ProductResponse[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    useEffect(() => {
        async function fetchAccessory() {
            setLoading(true);
            const res = await ProductApi.getAccessoriesByIds([productResponse.id]);
            if (res?.status === 200) {
                setDataAccessory(res.data.items)
            }
            setLoading(false);
        }

        fetchAccessory();
    }, [productResponse])

    return (
        <Spin spinning={loading}>
            <Typography.Text strong>Sản phẩm có {dataAccessory.length} phụ kiện</Typography.Text>
            <div style={{ display: 'flex', flexWrap: 'wrap', maxHeight: 310, overflow: 'auto', marginTop: '4px' }}>
                {
                    dataAccessory.map(v =>
                        <Card
                            bordered
                            key={v.id}
                            bodyStyle={{ display: 'flex' }}
                            style={{ display: 'flex', flex: '0 1 24%', margin: '4px' }}>
                            {/* <Image
                                style={{height: 100, border: '1px solid rgba(0,0,0,0.1)', aspectRatio: '1', objectFit: 'contain'}}
                                src={v.representativeImage?.url ? ProductApi.getProductImageUrl(v.representativeImage.url) : ""}
                                preview={false}/> */}
                            <Meta
                                style={{ marginLeft: '12px' }}
                                title={<a href={`/admin/products/${v.id}`}><span>{v.sku}</span></a>}
                                description={v.name} />
                        </Card>
                    )
                }
            </div>
        </Spin>
    )
}

const ProductExpandItem = React.memo(ProductExpandItemComponent);
export default ProductExpandItem;