import {
    MoneyCollectOutlined
} from '@ant-design/icons';
import { Card, Col, Row, Select, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { Color } from 'react-color';
import { SaleReportApi } from '../../Api/Reports/SaleReportApi';
import { canViewSumarry } from '../../Helper/CommonHelper';
import { MathHelper } from '../../Helper/math.helper';

const { Option } = Select;



function IndexItem(props: { title: string, url: string, value: string, valuecolor: Color }) {
    const { title, url, value, valuecolor } = props;
    return (
        <a href={url}>
            <Space>
                <MoneyCollectOutlined />
                <div>
                    <div style={{ fontWeight: 'normal', color: 'black' }}>{title}</div>
                    <div style={{ fontWeight: 'bolder', color: `${valuecolor}` }}>{value}</div>
                </div>
            </Space>
        </a>

    )
}

function SummaryReport() {

    const [totalMoneyToday, setTotalMoneyToday] = useState<number>(0);
    const [totalOrderToday, setTotalOrderToday] = useState<number>(0);
    const [totalOrderReturnToday, setTotalOrderReturnToday] = useState<number>(0);
    useEffect(() => {

        if (canViewSumarry()) {
            var date = new Date();

            date.setDate(date.getDate() - 1);
            fetch(date, new Date())
        }

    }, [])

    async function fetch(from: Date, to: Date) {

        // const res = await SaleReportApi.GetOrderReportsApi(from, to);
        // if (res?.status === 200) {
        //     console.log(res.data);
        //     let totalOrder = res.data.length;
        //     let returnCount = 0;
        //     let totalMoney = 0;
        //     res.data.forEach(x => {
        //         if (x.orderStatus == OrderStatus.Cancelled)
        //             returnCount++;
        //         totalMoney += x.totalAmount
        //     });

        //     setTotalOrderToday(totalOrder);
        //     setTotalMoneyToday(totalMoney);
        //     setTotalOrderReturnToday(returnCount);
        // }
        // else {

        // }

    }

    function onChangeDate(val: string) {

        let dayCount = 0;
        if (val == "yesterday")
            dayCount = 1;
        else if (val == "7day")
            dayCount = 7;
        else if (val == "month")
            dayCount = 30;

        var date = new Date();
        date.setDate(date.getDate() - dayCount);
        fetch(date, new Date());

    }

    return (
        <>
            {canViewSumarry() ? <div>
                <Card title="KẾT QUẢ KINH DOANH TRONG NGÀY">
                    <Row>
                        <Col span={4}>
                            <IndexItem title="Doanh thu" url="" value={MathHelper.formatPriceVND(totalMoneyToday)} valuecolor="red" />
                        </Col>
                        <Col span={4}>
                            <IndexItem title="Đơn hàng mới" url="" value={totalOrderToday.toString()} valuecolor="green" />
                        </Col>

                        <Col span={4}>
                            <IndexItem title="Đơn hủy" url="" value={totalOrderReturnToday.toString()} valuecolor="yellow" />
                        </Col>
                    </Row>
                </Card>

                <Card title={
                    <Space>
                        <div>DOANH THU BÁN HÀNG</div>

                        <div><Select defaultValue="7day" onChange={onChangeDate}>
                            <Option value="yesterday">Hôm qua</Option>
                            <Option value="7day">7 ngày qua</Option>
                            <Option value="month">Tháng này</Option>
                            <Option value="preMonth">Tháng trước</Option>
                        </Select></div>

                    </Space>}>
                    {/* <Column  {...config} /> */}
                </Card>
            </div>
                : <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                    <span style={{ fontSize: 30, fontWeight: 'bold', color: "#00A5E3" }}>Chào mừng đến với trang quản trị Phúc Vinh</span>
                </Row>
            }
        </>
    )
}

export default SummaryReport;