import { from, map, Observable } from 'rxjs';
import { SERVER_API } from '../UrlDefination';
import { AxiosRequest } from './../Axios/AxiosRequest';
import { RequestHandler } from './../RequestAPI';

export class ReportApi {
    static getOrderReports(query: QueryOrderReport): Observable<IOrderReport[]> {
        return from(getOrderReportFunc(query)).pipe(map(res => res.data))
    }


}

function getOrderReportFunc(query: QueryOrderReport) {
    const arrQueryTemp = Object.keys(query).reduce((res, key) => {
        const value = query[key as keyof QueryOrderReport];
        let temp: string = '';
        if (Array.isArray(value)) {
            temp = value.map(t => `${key}=${t}`).join("&");
        } else if (value instanceof Date) {
            temp = `${key}=${value.toISOString()}`
        } else if (typeof value === 'string' || typeof value === 'number') {
            temp = `${key}=${value}`
        }

        if (temp) res.push(temp);
        return res
    }, [] as string[]);

    const finalQuery = arrQueryTemp.join("&");
    return RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.REPORT.GET_ORDER}?${finalQuery}`));
}

