import { ReactNode } from "react";

type LeftRightProps = {
    leftContent: ReactNode | string,
    rightContent: ReactNode | string,
}

export function LeftRightHeaderContent(props: LeftRightProps) {
    const { leftContent, rightContent } = props;


    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '6px auto' }}>
            <div>
                {leftContent}
            </div>
            <div>
                {rightContent}
            </div>
        </div>


    )

}