import CreditCardOutlined from "@ant-design/icons/lib/icons/CreditCardOutlined";
import { Button, Col, Form, Input, List, message, Modal, ModalProps, Row } from "antd";
import { useEffect, useState } from "react";
import { CreatePaymentApi, GetPaymentsApi } from "../../Api/PaymentApi";
import { PaymentResponse } from "../../Model/PaymentModel";

type PaymentModalProps = ModalProps & {
    okModalFnc: Function
}



export function PaymentsModal(props: PaymentModalProps) {

    const { okModalFnc, ...rest } = props;

    const [payments, setPayments] = useState<PaymentResponse[]>([]);
    const [action, setAction] = useState<number>(0);
    const [buttonText, setButtonText] = useState<string>("Thêm mới phương thức");

    const [form] = Form.useForm();

    useEffect(() => {
        if (rest.visible) {
            GetPaymentsApi((res: IListItems<PaymentResponse>) => {
                setPayments(res.items);
            })
        }
    }, [rest.visible])

    const okModalhandle = () => {
        okModalFnc();
    }

    const actionHandle = () => {
        if (action === 0) {
            form.setFieldsValue({
                name: "",
                accountNumber: "",
                owner: ""
            })
            setAction(1);
            setButtonText("Tạo phương thức");
        } else if (action === 1) {
            form.validateFields().then(() => {
                const name = form.getFieldValue("name");
                const accountNumber = form.getFieldValue("accountNumber");
                const owner = form.getFieldValue("owner");

                if (name && accountNumber && owner) {
                    CreatePaymentApi({
                        name: name,
                        accountNumber: accountNumber,
                        ownerName: owner,
                        extraInformation: ""
                    }, (res: PaymentResponse) => {
                        let newPayments: PaymentResponse[] = [...payments];
                        newPayments.push(res);
                        setPayments(newPayments);
                        message.info("Đã thêm thành công một phương thức thanh toán");
                        setAction(0);
                    })
                } else {
                    message.info("Cần nhập đủ thông tin");
                }
            })
        }
    }

    const onClickItem = (payment: PaymentResponse) => {
        return () => {
            form.setFieldsValue({
                name: payment.name,
                accountNumber: payment.accountNumber,
                owner: payment.ownerName
            })
            setAction(2);
            setButtonText("Lưu thay đổi");
        }

    }

    const cancelHandle = () => {
        form.setFieldsValue({
            name: "",
            accountNumber: "",
            owner: ""
        })
        setAction(0);
        setButtonText("Thêm mới phương thức");
    }

    return (
        <Modal {...rest}
            // bodyStyle={{ height: 500 }}
            footer={false}
            centered
            onOk={okModalhandle}>
            <div>
                {/* VA not sure */}
                {action === 0 ? ("") : <Form layout="vertical" size="small" form={form}>
                    <Row>
                        <Col span={12} style={{ paddingRight: 5 }}>
                            <Form.Item name="name" label="Tên tài khoản">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12} style={{ paddingLeft: 5 }}>
                            <Form.Item name="accountNumber" label="Số tài khoản">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item name="owner" label="Chủ sở hửu">
                        <Input />
                    </Form.Item>
                </Form>
                }
                {action === 0 ? <List dataSource={payments}
                    renderItem={item => (
                        <List.Item onClick={onClickItem(item)}>
                            <List.Item.Meta
                                avatar={<CreditCardOutlined />}
                                title={`${item.name} - ${item.accountNumber}`}
                                description={item.ownerName}
                            />
                        </List.Item>
                    )}>

                </List> : ("")}

                <Button type="primary" onClick={actionHandle} >{buttonText}</Button>
                {action === 0 ? "" : <Button onClick={cancelHandle} style={{ margin: 5 }}>Hủy</Button>}
            </div>


        </Modal>
    )
}