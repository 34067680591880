import {
    PicCenterOutlined,
    PicRightOutlined
} from '@ant-design/icons';
import { Card, Col, Row } from "antd";
import { CardItem } from "../Controls/LinkItem";


export default function PurchaseReportView() {

    return (
        <>
            <Card title="Báo cáo phân hệ mua hàng" size="small">
                <Row>
                    <Col span={6}>
                        <CardItem title="Báo cáo đơn nhập "
                            icon={<PicCenterOutlined />}
                            desc="Theo dõi doanh thu, chi phí và lợi nhuận của cửa hàng"
                            url="purchase/summary" />
                    </Col>
                    <Col span={6}>
                        <CardItem title="Báo cáo thanh toán"
                            icon={<PicRightOutlined />}
                            desc="Theo dõi các khoản thu chi của cửa hàng"
                            url="purchase/by_payment" />
                    </Col>
                    <Col span={6}>
                        <CardItem title="Báo cáo công nợ phải trả"
                            icon={<PicRightOutlined />}
                            desc="Theo dõi các khoản thu chi của cửa hàng"
                            url="purchase/debt" />
                    </Col>

                </Row>
            </Card>

        </>
    )
}