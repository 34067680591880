import { Button, Col, Modal, Row, Steps, Table, Tag, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { OrderReturnStatus, ReturnOrderProcess, TechnicalActionEnum } from "../../../common/type-define";
import { MathHelper } from "../../../Helper/math.helper";
import { ProductOrderReturnResponse, RefundProductOrderReturnsCommand, TechnicianProcessingOrderReturnCommand } from "../../../Model/ReturnOrderModel";
import { useSelectorRoot } from "../../../redux/configure/store";
import { getFetchProductLoading, getReturnOrderProcess, getReturnOrderProcessId } from "../../../redux/selectors/returnOrders.selector";
import { changeOrderToProcess, completeOrder, fetchProductByReturnOrderId, fetchReturnOrder, getReturnOrderById, refundProductOrder, setReturnOrderProcessId, techinicianProcessOrderReturn } from "../../../redux/slices/returnOrders.slice";
import { LeftRightHeaderContent } from "../../Controls/LeftRightHeaderContent";

const { Step } = Steps;

interface ReturnOrderDisplay extends ProductOrderReturnResponse {
    quantity: number;
    key: string;
}

const STEP_DEFINE = ['Duyệt đơn', 'Kỹ thuật xử lý', 'Hoàn tiền', 'Trả hàng', 'Thành công'];
const getStepIndexByStatus = (status: OrderReturnStatus): number => {
    switch (status) {
        case OrderReturnStatus.Created:
        case OrderReturnStatus.Processing:
            return status;
        case OrderReturnStatus.NeedRefund:
            return 2;
        case OrderReturnStatus.AllDeclined:
            return 4;
        case OrderReturnStatus.Processed:
            return 3;
        case OrderReturnStatus.Completed:
            return 5;
    }
}

const renderTagTechnicalAction = (status: number) => {
    switch (status) {
        case 2:
            return {
                text: "Chấp nhận",
                color: "green",
            }
        default:
            return {
                text: "Từ chối",
                color: "volcano",
            }
    }
}

const ReturnOrderProcessModalComponent = (): JSX.Element => {
    const dispatch = useDispatch();
    const returnOrderProcess = useSelectorRoot(getReturnOrderProcess);
    const returnOrderProcessId = useSelectorRoot(getReturnOrderProcessId);
    const fetchProductLoading = useSelectorRoot(getFetchProductLoading);

    const [stepIndex, setStepIndex] = useState<number>(0);
    const [dataSource, setDataSource] = useState<ReturnOrderDisplay[]>([]);
    const [selectedRow, setSelectedRow] = useState<ReturnOrderDisplay[]>([]);

    useEffect(() => {
        if (returnOrderProcessId) return;
        setSelectedRow([]);
    }, [returnOrderProcessId])
    useEffect(() => {
        if (!returnOrderProcessId) return;
        dispatch(fetchProductByReturnOrderId(returnOrderProcessId.id));
        setStepIndex(getStepIndexByStatus(returnOrderProcessId.status));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [returnOrderProcessId])

    useEffect(() => {
        const newDataSource = returnOrderProcess.map(v => ({ ...v, ...{ quantity: 1, key: v.id } }));
        setDataSource(newDataSource);
    }, [returnOrderProcess])


    const handleOnCancel = () => {
        dispatch(setReturnOrderProcessId(null));
        dispatch(fetchReturnOrder());
    }

    const onSelectRow = (record: ReturnOrderDisplay) => {
        if (stepIndex !== 1) return;
        setSelectedRow(pre => {
            if (pre.some(v => v.id === record.key)) {
                return pre.filter(v => v.id !== record.key)
            } else {
                return [...pre, ...[record]]
            }
        })
    }



    const columns: ColumnsType<ReturnOrderDisplay> = [
        {
            title: "Tên sản phẩm",
            key: "productVersionName",
            dataIndex: "productVersionName",
            width: "40%",
            render: (name: string, record: ReturnOrderDisplay) => (
                <Typography.Link onClick={() => onSelectRow(record)}>
                    {name}
                </Typography.Link>
            )
        },
        {
            title: "IMEI",
            key: "imei",
            dataIndex: "imei",
            width: "15%",
            render: (imei: string) => (
                <div>
                    {imei}
                </div>
            )
        },
        {
            title: "Trạng thái",
            key: "technicalAction",
            dataIndex: "technicalAction",
            width: "15%",
            render: (technicalAction: number) => {
                const data = renderTagTechnicalAction(technicalAction);
                return <Tag color={data.color}>{data.text}</Tag>
            }
        },
        {
            title: "Số lượng",
            key: "quantity",
            dataIndex: "quantity",
            width: "15%",
            align: 'right',
            render: (quantity: number) => (
                <span>
                    {quantity}
                </span>
            )
        },
        {
            title: "Đơn giá",
            key: "salePrice",
            dataIndex: "salePrice",
            width: "15%",
            align: 'right',
            render: (salePrice: number) => (
                <span>
                    {MathHelper.formatPriceVND(salePrice)}
                </span>
            )
        },
        {
            title: "Thành tiền",
            key: "total",
            dataIndex: "total",
            width: "15%",
            align: 'right',
            render: (_: number, record: ReturnOrderDisplay) => (
                <span>
                    {MathHelper.formatPriceVND(record.quantity * record.salePrice)}
                </span>
            )
        },
    ];


    const getTotalAmount = (): number => {
        if (stepIndex < 2) {
            if (!selectedRow.length) return 0;
            return selectedRow.reduce((p, c) => p + c.quantity * c.salePrice, 0);
        } else {
            return returnOrderProcess.reduce((p, c) => p + (c.technicalAction === 2 ? c.salePrice : 0), 0);
        }
    }

    const getTotalRefundNumber = (): number => {
        if (stepIndex < 2) {
            return selectedRow.length;
        } else {
            return returnOrderProcess.filter(v => v.technicalAction === 2).length;
        }
    }

    const footerContent = () => {
        if (stepIndex < 1) return undefined;
        return (
            <Row>
                <Col span={12} />
                <Col span={12}>
                    <LeftRightHeaderContent
                        leftContent={`Tổng tiền hàng trả (${getTotalRefundNumber()} sản phẩm)`}
                        rightContent={
                            <span style={{ padding: 'auto 4px', fontWeight: 700 }}>{MathHelper.formatPriceVND(getTotalAmount(), true)}</span>
                        } />
                </Col>
            </Row>
        )
    };

    const handleOnProcess = () => {
        if (!returnOrderProcessId) return;
        const orderId = returnOrderProcessId.id;
        dispatch(getReturnOrderById(orderId));
        switch (stepIndex) {
            case 0:
                dispatch(changeOrderToProcess({
                    orderId: returnOrderProcessId.id,
                    status: ReturnOrderProcess.Processing,
                }));
                break;
            case 1:
                const technicalDataRequest: TechnicianProcessingOrderReturnCommand = {
                    actionProductOrderReturnDetails: dataSource.map(v => ({
                        productOrderReturnId: v.id,
                        reason: "Kết quả từ kỹ thuật viên",
                        technicalAction: selectedRow.find(row => row.id === v.id) ? 0 : 1,
                    }))
                }
                dispatch(techinicianProcessOrderReturn({
                    orderId,
                    dataRequest: technicalDataRequest,
                }));

                break;
            case 2:
                const refundProducts: RefundProductOrderReturnsCommand = {
                    decisionDetails: returnOrderProcessId.productOrderReturns
                        .filter(v => v.technicalAction === TechnicalActionEnum.Passed)
                        .map(v => ({
                            productOrderReturnId: v.id,
                            refund: v.salePrice
                        }))
                }
                dispatch(refundProductOrder({ orderId, refundProducts }))
                break;
            case 3:
                dispatch(completeOrder(orderId));
                break;
            case 4:
                dispatch(changeOrderToProcess({
                    orderId: returnOrderProcessId.id,
                    status: ReturnOrderProcess.Processed,
                }));
                break
        }
    }

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: ReturnOrderDisplay[]) => setSelectedRow(selectedRows),
    };

    return (
        <Modal
            visible={returnOrderProcessId !== null}
            destroyOnClose
            maskClosable={false}
            style={{ minWidth: '1000px' }}
            closable={false}
            bodyStyle={{
                minHeight: '700px',
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
            }}
            footer={
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <Button
                        onClick={handleOnCancel}
                        style={{ margin: 'auto 8px' }}>
                        Đóng
                    </Button>
                </div>
            }
            onCancel={handleOnCancel}>
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Steps
                        style={{ marginBottom: '36px', marginRight: '20px' }}
                        size="small"
                        labelPlacement="vertical"
                        current={stepIndex}>
                        {
                            STEP_DEFINE.map(v => <Step key={v} title={v} />)
                        }
                    </Steps>
                    <Button
                        disabled={stepIndex > 4}
                        onClick={handleOnProcess}
                        type="primary">
                        Duyệt
                    </Button>
                </div>

                <div style={{ marginBottom: '20px' }}>
                    <Typography.Text strong>Đơn hàng: </Typography.Text>
                    <span style={{ fontSize: '16px', fontWeight: 700 }}>{returnOrderProcessId?.orderCode}</span>
                </div>

                <Table
                    rowSelection={
                        stepIndex === 1 ?
                            {
                                type: 'checkbox',
                                selectedRowKeys: selectedRow.map(v => v.id),
                                ...rowSelection,
                            }
                            :
                            undefined
                    }
                    dataSource={dataSource}
                    columns={columns.filter(v => !(stepIndex < 2 && v.key === 'technicalAction'))}
                    pagination={false}
                    scroll={{ y: '500px' }}
                    footer={footerContent}
                    loading={fetchProductLoading}
                />
            </div>
        </Modal>
    )
}

const ReturnOrderProcessModal = React.memo(ReturnOrderProcessModalComponent);
export default ReturnOrderProcessModal;
