import { Button, DatePicker, Space, Spin, Table } from "antd";
import { LeftRightHeaderContent } from "../../Controls/LeftRightHeaderContent";
import {
    ArrowLeftOutlined,
    SearchOutlined, VerticalAlignBottomOutlined
} from '@ant-design/icons';
import ConceptModal, { conceptItem } from "../../Helper/ConceptModal";
import { useEffect, useState } from "react";
import moment from "moment";
import { ColumnsType } from "antd/lib/table";
import { headerStyle } from "../../Helper/UIHelper";
const remainConcepts: conceptItem[] = [
    {
        name: "Tồn kho (Số lượng tồn kho)",
        meaning: "Số lượng sản phẩm trong kho đến thời điểm hiện tại."
    },
    {
        name: "Giá trị tồn kho",
        meaning: "Tổng giá trị của các sản phẩm tồn kho. Giá trị tồn kho của 1 sản phẩm = Giá vốn * Số lượng tồn kho Ví dụ: Trong kho còn tồn 50 sản phẩm A, giá vốn của sản phẩm A = 150,000đ -> Giá trị tồn kho của sản phẩm A = 150,000 * 50 = 7,500,000đ"
    },
    {
        name: "Tỷ trọng",
        meaning: "= [Giá trị tồn kho của chi nhánh (hoặc tổng nhiều chi nhánh) / Giá trị tồn kho toàn hệ thống] * 100%"
    }
]
export default function RemainingReportView() {

    const [showConcept, setShowConcept] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false)
    useEffect(() => {

    })

    const columns: ColumnsType<any> = [
        {
            title: 'STT',
            dataIndex: 'name',
            key: 'name',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Mã SP',
            dataIndex: 'code',
            key: 'code',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Tên sản phẩm',
            dataIndex: 'name',
            key: 'name',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Kho chi nhánh',
            onHeaderCell: () => headerStyle(),
            children: [
                {
                    title: 'Tồn kho',
                    dataIndex: 'remaining',
                    key: 'remaining',
                    onHeaderCell: () => headerStyle(),
                },
                {
                    title: 'Giá trị',
                    dataIndex: 'value',
                    key: 'value',
                    onHeaderCell: () => headerStyle(),
                },
                {
                    title: 'Giá vốn',
                    dataIndex: 'price',
                    key: 'price',
                    onHeaderCell: () => headerStyle(),
                },
                {
                    title: 'Tỷ trọng',
                    dataIndex: 'rate',
                    key: 'rate',
                    onHeaderCell: () => headerStyle(),
                },
            ]
        },
        {
            title: 'Hệ thống',
            onHeaderCell: () => headerStyle(),
            children: [
                {
                    title: 'Tồn kho',
                    dataIndex: 'SystemRemaining',
                    key: 'SystemRemaining',
                    onHeaderCell: () => headerStyle(),
                },
                {
                    title: 'Giá trị hàng tồn',
                    dataIndex: 'TotalRemaining',
                    key: 'TotalRemaining',
                    onHeaderCell: () => headerStyle(),
                },
            ]
        },
    ]
    return (
        <div>
            <LeftRightHeaderContent
                leftContent={
                    <Space direction='horizontal'>
                        <Button type="default" icon={<ArrowLeftOutlined />} href="/admin/report/store"></Button>
                        <h1>Báo cáo tồn kho</h1>
                    </Space>}
                rightContent={
                    <Space direction='horizontal'>
                        <Button type="link" onClick={() => setShowConcept(true)}>Giải thích thuật ngữ</Button>
                    </Space>}
            />
            <Spin spinning={loading} tip="đang tải...">
                <Table columns={columns} size="small" bordered/>
            </Spin>
            

            <div>
                <ConceptModal visible={showConcept}
                    concepts={remainConcepts}
                    onCancel={() => setShowConcept(false)} />
            </div>
        </div>
    )
}