import { Button, InputNumber, message, Modal, ModalProps, Select, Table, Tooltip } from "antd"
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { GetWareHousesApi } from "../../Api/WarehouseApi";
import { WarehouseApi } from "../../Api/WarehouseApi";
import { LeftRightHeaderContent } from "../Controls/LeftRightHeaderContent";

const { Option } = Select

interface InitStoreProductVersion {
    productVersionId: string,
    versionName: string;
    quantity: number;
}

type InitStorageProps = ModalProps & {
    okModalFnc: Function,
    productVersions: ProductVersionResponse[]
}

export default function InitStorageModal(props: InitStorageProps) {

    const { okModalFnc, productVersions, ...rest } = props;

    const [Versions, setVersions] = useState<InitStoreProductVersion[]>([]);
    const [warehouses, setWarehouses] = useState<WareHouseResponse[]>([]);

    const [selecteddWarehouse, setSelectedWarehouse] = useState<WareHouseResponse | null>(null);

    useEffect(() => {
        if (rest.visible) {
            GetWareHousesApi((res: IListItems<WareHouseResponse>) => {
                setWarehouses(res.items);
            })
            setSelectedWarehouse(null);
            setVersions([]);
        }

    }, [rest.visible])


    const handleChangeStorage = (_: any, opt: any) => {
        const itemSelected = opt.label as WareHouseResponse;

        setSelectedWarehouse(itemSelected);


        WarehouseApi.getProductVersionByWarehouseIds([itemSelected.id]).then((res) => {
            if (res?.status === 200) {
                const newVersions: InitStoreProductVersion[] = [];
                productVersions.forEach(y => {
                    if (res.data.items.findIndex(x => x.id === y.id) === -1) {
                        newVersions.push({
                            productVersionId: y.id,
                            quantity: 0,
                            versionName: y.name
                        })
                    }
                })
                setVersions(newVersions);
            }
        }).catch(() => {
            setVersions([]);
        })
    }

    function InitStoreHandle() {

        const versionData: any[] = [];
        Versions.forEach(x => {
            if (x.quantity > 0) { // Chỉ điều chỉnh với những version nào có quantity
                versionData.push({
                    productVersionId: x.productVersionId,
                    details: [{
                        warehouseId: selecteddWarehouse?.id,
                        quantities: x.quantity
                    }]
                })
            }

        })

        if (versionData.length === 0) {
            message.info("Cần nhập dữ liệu");
            return;
        }

        const data = {
            productVersionToStore: versionData
        }

        WarehouseApi.initProductVersionToWarehouse(data).then((res) => {
            if (res?.status === 200) {
                message.info("Khởi tạo kho thành công");
                okModalFnc?.();
            }
        }).catch(() => {
            message.error("Khởi tạo kho bị lỗi");
        })

    }


    const onChangeInputQuantity = (val: any, rowIndex: number) => {

        let newQuantity = Number.parseInt(val);
        if (rowIndex != -1) {
            let newProducts: InitStoreProductVersion[] = [...Versions];
            const prodChanged = newProducts[rowIndex];

            const productOrderVersion: InitStoreProductVersion = {
                ...prodChanged,
                quantity: newQuantity,
            };
            newProducts[rowIndex] = productOrderVersion;

            setVersions(newProducts);

        }

    };

    const ProductVersionCols: ColumnsType<InitStoreProductVersion> = [
        {
            title: 'Phiên bản',
            dataIndex: 'versionName',
            key: 'versionName',
            render: (versionName) => (
                <span>{versionName}</span>
            )
        },
        {
            title: 'Số lượng',
            dataIndex: 'quantity',
            key: 'quantity',
            responsive: ['sm'],
            width: 100,
            render: (quantity, record, index) => (
                <InputNumber defaultValue={quantity} min={1} onChange={(val) => onChangeInputQuantity(val, index)} />
            )
        },

    ];
    return (<>
        <Modal  {...rest}
            footer={null} >
            <LeftRightHeaderContent leftContent={
                <Select
                    onChange={handleChangeStorage}
                    value={selecteddWarehouse?.name}
                    style={{ width: 120 }}
                    bordered={true}>
                    {
                        warehouses.map(v =>
                            <Option
                                key={v.id}
                                value={v.name}
                                label={v}>{v.name}</Option>
                        )
                    }
                </Select>
            }
                rightContent={
                    <Button type="primary" onClick={InitStoreHandle}>Khởi tạo kho</Button>
                } />
            <Table columns={ProductVersionCols} size='small'
                dataSource={Versions}
                pagination={false}
            />
        </Modal>
    </>)
}