import { LeftOutlined, FileAddOutlined } from '@ant-design/icons';
import { Button, Table, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { InventoryApi } from "../../Api/InventoryApi";
import { InventoryStatusEnum } from '../../common/type-define';
import { CommonHelper } from '../../Helper/CommonHelper';
import { InventoryResponse } from "../../Model/InventoryModel";
import { LeftRightHeaderContent } from '../Controls/LeftRightHeaderContent';
import { getHeightFit } from "../Helper/UIHelper";

const INVENTORY_STATUS: InventoryStatusEnum[] = [InventoryStatusEnum.Created, InventoryStatusEnum.Checked, InventoryStatusEnum.Approved, InventoryStatusEnum.Declined, InventoryStatusEnum.Balanced];

const InventoryViewComponent = (): JSX.Element => {

    const [dataSource, setDataSource] = useState<InventoryResponse[]>([])
    const [loading, setLoading] = useState<boolean>(false);
    useEffect(() => {
        async function fetch() {
            setLoading(true);
            const res = await InventoryApi.getInventories();
            if (res?.status === 200) {
                setDataSource(res.data.items);
            }
            else {

            }
            setLoading(false);
        }

        fetch();
    }, [])

    const columns: ColumnsType<InventoryResponse> = [
        {
            title: 'Mã sản phẩm',
            dataIndex: 'inventoryCode',
            key: 'inventoryCode',
            showSorterTooltip: false,
            sorter: (a, b) => a.inventoryCode < b.inventoryCode ? 1 : -1,
            render: (inventoryCode: string, record: InventoryResponse) => (
                <Link to={`${record.id}`}>
                    {inventoryCode}
                </Link>
            )
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            filters: INVENTORY_STATUS.map(v => {
                const tagInfo = CommonHelper.getInventoryTag(v);
                return {
                    text: <Tag
                        style={{ userSelect: 'none' }}
                        color={tagInfo.color}>
                        {tagInfo.title}
                    </Tag>,
                    value: v,
                }
            }),
            onFilter: (value, record) => record.status === value,
            showSorterTooltip: false,
            sorter: (a, b) => a.status < b.status ? 1 : -1,
            render: (status: number, record: InventoryResponse) => {
                const tagInfo = CommonHelper.getInventoryTag(status);
                return (
                    <Tag style={{ userSelect: 'none' }} color={tagInfo.color}>
                        {tagInfo.title}
                    </Tag>
                )
            }
        },
        {
            title: 'Kho',
            dataIndex: 'warehouse',
            key: 'warehouse',
            showSorterTooltip: false,
            sorter: (a, b) => a.warehouse?.name < b.warehouse?.name ? 1 : -1,
            render: (_: any, record: InventoryResponse) => (
                <span>
                    {record.warehouse?.name ?? '-'}
                </span>
            )
        },
        {
            title: 'Người tạo',
            dataIndex: 'actorName',
            key: 'actorName',
            showSorterTooltip: false,
            sorter: (a, b) => a.inventoryHistories[0].actorName < b.inventoryHistories[0].actorName ? 1 : -1,
            render: (_: any, record: InventoryResponse) => (
                <span>
                    {record.inventoryHistories[0].actorName ?? '-'}
                </span>
            )
        },
        {
            title: 'Thời gian tạo',
            dataIndex: 'createdTime',
            key: 'createdTime',
            showSorterTooltip: false,
            defaultSortOrder: 'ascend',
            sorter: (a, b) => new Date(a.inventoryHistories[0].createdTime).getTime() < new Date(b.inventoryHistories[0].createdTime).getTime() ? 1 : -1,
            render: (_: any, record: InventoryResponse) => (
                <span>
                    {new Date(record.inventoryHistories[0].createdTime).toLocaleString() ?? '-'}
                </span>
            )
        },
    ]

    return (
        <>

            <LeftRightHeaderContent leftContent={<Link to="/admin/variants">
                <LeftOutlined /> Quay lại Quản lý kho
            </Link>}
                rightContent={<Button type="primary" icon={<FileAddOutlined />} href="/admin/inventory_create" size="middle">
                    Thêm phiếu kiểm
                </Button>}
            ></LeftRightHeaderContent>
            <Table
                columns={columns}
                loading={loading}
                dataSource={dataSource}
                pagination={{ pageSize: 20 }}
                bordered
                scroll={{ x: '900px', y: getHeightFit(250) }}
                rowKey={'id'}
            />
        </>
    )
}

const InventoryView = React.memo(InventoryViewComponent);
export default InventoryView;