import {
    PicCenterOutlined,
    PicRightOutlined
} from '@ant-design/icons';
import { Col, Row } from "antd";
import { CardItem } from "../Controls/LinkItem";

export default function CustomerReportView() {

    return (
        <>
            <Row>
                <Col span={12}>
                    <CardItem title="Khách hàng mua theo thời gian"
                        icon={<PicCenterOutlined />}
                        desc="Theo dõi doanh thu, chi phí và lợi nhuận của cửa hàng"
                        url="customer/by_time" />
                </Col>
                <Col span={12}>
                    <CardItem title="Khách hàng mua theo sản phẩm"
                        icon={<PicCenterOutlined />}
                        desc="Theo dõi các khoản phải thu của khách hàng"
                        url="customer/by_product" />
                </Col>
            </Row>
        </>
    )
}