import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import { Button, Table, Tag, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { OrderReturnStatus } from "../../common/type-define";
import { MathHelper } from "../../Helper/math.helper";
import { CustomerRes } from "../../Model/CustomerModel";
import { OrderReturnResponse, ProductOrderReturnResponse } from "../../Model/ReturnOrderModel";
import { useSelectorRoot } from "../../redux/configure/store";
import { getFetchReturnOrderLoading, getReturnOrderList, getReturnOrderModalVis } from "../../redux/selectors/returnOrders.selector";
import { fetchReturnOrder, getReturnOrderById, setReturnOrderId, setReturnOrderModalVis } from "../../redux/slices/returnOrders.slice";
import { headerStyle } from "../Helper/UIHelper";
import ReturnOrderModal from "./ReturnOrder/ReturnOrderModal";
import ReturnOrderProcessModal from "./ReturnOrder/ReturnOrderProcessModal";

const ORDER_STATUS_DEFINE = [OrderReturnStatus.Created, OrderReturnStatus.Processing, OrderReturnStatus.NeedRefund, OrderReturnStatus.AllDeclined, OrderReturnStatus.Processed, OrderReturnStatus.Completed];

const handleRenderStatus = (status: OrderReturnStatus): { text: string, color: string } => {
    switch (status) {
        case OrderReturnStatus.Created:
            return {
                text: "Chờ duyệt",
                color: "blue"
            };
        case OrderReturnStatus.Processing:
            return {
                text: "Xử lý",
                color: "purple"
            };
        case OrderReturnStatus.NeedRefund:
            return {
                text: "Hoàn tiền",
                color: "cyan"
            };
        case OrderReturnStatus.AllDeclined:
            return {
                text: "Từ chối",
                color: "volcano"
            };
        case OrderReturnStatus.Processed:
            return {
                text: "Xử lý xong",
                color: "geekblue"
            };
        case OrderReturnStatus.Completed:
            return {
                text: "Hoàn thành",
                color: "green"
            };
        default: {
            return {
                text: status,
                color: "red"
            };
        }
    }
}
const ReturnOrdersViewComponent = (): JSX.Element => {
    const dispatch = useDispatch();
    const returnOrderModalVis = useSelectorRoot(getReturnOrderModalVis);
    const returnOrderList = useSelectorRoot(getReturnOrderList);
    const fetchReturnOrderLoading = useSelectorRoot(getFetchReturnOrderLoading);
    const [dataSource, setDataSource] = useState<OrderReturnResponse[]>([]);
    const navigate = useNavigate();
    useEffect(() => {
        setDataSource(returnOrderList)
    }, [returnOrderList])
    useEffect(() => {
        dispatch(fetchReturnOrder());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (returnOrderModalVis) return;
        dispatch(setReturnOrderId(undefined))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [returnOrderModalVis])


    const handleOpenModal = () => {
        dispatch(setReturnOrderModalVis(true));
    }

    const handleSelectOrderToProcess = (orderResponse: OrderReturnResponse) => {
        dispatch(getReturnOrderById(orderResponse.id));
    }

    const getTotalRefund = (record: OrderReturnResponse): number => {
        return record.productOrderReturns.reduce((p, c) => p + (c.refund ?? 0), 0);
    }

    const onSelectLink = (id: string) => {
        navigate(`/admin/orders/order_returns/${id}`);
    }

    const columns: ColumnsType<OrderReturnResponse> = [
        {
            title: "Mã đơn trả hàng",
            key: "orderReturnCode",
            dataIndex: "orderReturnCode",
            showSorterTooltip: false,
            onHeaderCell: () => headerStyle(),
            sorter: (a, b) => a.orderReturnCode < b.orderReturnCode ? 1 : -1,
            render: (orderReturnCode: string, record: OrderReturnResponse) => (
                <Typography.Link onClick={() => onSelectLink(record.id)}>
                    <span>{orderReturnCode}</span>
                </Typography.Link>
            )
        },
        {
            title: "Mã đơn hàng",
            key: "orderCode",
            dataIndex: "orderCode",
            showSorterTooltip: false,
            onHeaderCell: () => headerStyle(),
            sorter: (a, b) => a.orderCode < b.orderCode ? 1 : -1,
            render: (orderCode: string, record: OrderReturnResponse) => (
                <Typography.Link onClick={() => onSelectLink(record.id)}>
                    <span>{orderCode}</span>
                </Typography.Link>
            )
        },
        {
            title: "Khách hàng",
            key: "customer",
            dataIndex: "customer",
            onHeaderCell: () => headerStyle(),
            showSorterTooltip: false,
            sorter: (a, b) => a.customer.fullName < b.customer.fullName ? 1 : -1,
            render: (customer: CustomerRes) => (
                <span>{customer?.fullName}</span>
            )
        },
        {
            title: "Trạng thái",
            key: "status",
            dataIndex: "status",
            showSorterTooltip: false,
            onHeaderCell: () => headerStyle(),
            sorter: (a, b) => a.status < b.status ? 1 : -1,
            filters: ORDER_STATUS_DEFINE.map(v => {
                const statusProp = handleRenderStatus(v);
                return {
                    text: <Tag
                        style={{ userSelect: 'none' }}
                        color={statusProp.color}>
                        {statusProp.text}
                    </Tag>,
                    value: v,
                }
            }),
            onFilter: (value, record) => record.status === value,
            render: (status: OrderReturnStatus) => {
                const statusProp = handleRenderStatus(status);
                return (
                    <Tag
                        style={{ userSelect: 'none' }}
                        color={statusProp.color}>
                        {statusProp.text}
                    </Tag>
                )
            }
        },
        {
            title: "Hoàn tiền",
            key: "refundAmount",
            dataIndex: "refundAmount",
            align: 'right',
            showSorterTooltip: false,
            onHeaderCell: () => headerStyle(),
            sorter: (a, b) => getTotalRefund(a) < getTotalRefund(b) ? 1 : -1,
            render: (_: ProductOrderReturnResponse, record: OrderReturnResponse) => (
                <span>{MathHelper.formatPriceVND(getTotalRefund(record), true)}</span>
            )
        },
        {
            title: "Tổng tiền",
            key: "orderTotalAmount",
            dataIndex: "orderTotalAmount",
            align: 'right',
            showSorterTooltip: false,
            onHeaderCell: () => headerStyle(),
            sorter: (a, b) => (a.orderTotalAmount ?? 0) < (b.orderTotalAmount ?? 0) ? 1 : -1,
            render: (orderTotalAmount: number) => (
                <span>{MathHelper.formatPriceVND(orderTotalAmount ?? 0, true)}</span>
            )
        },
    ]

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={handleOpenModal}
                    size="large">
                    Tạo đơn trả
                </Button>
            </div>

            <Table
                columns={columns}
                dataSource={dataSource}
                loading={fetchReturnOrderLoading}
                rowKey={'id'}
            />
            <ReturnOrderModal />
            <ReturnOrderProcessModal />
        </>
    )
}
const ReturnOrdersView = React.memo(ReturnOrdersViewComponent);
export default ReturnOrdersView;