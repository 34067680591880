import { Button, Spin, Table, DatePicker } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react"
import { BillApi } from "../../Api/BillsApi";
import { MathHelper } from "../../Helper/math.helper";
import { BillRespone } from "../../Model/BillModel";
import { LeftRightHeaderContent } from "../Controls/LeftRightHeaderContent";
import { getHeightFit, headerStyle } from "../Helper/UIHelper";
import CreateIncomeModal from "./CreateIncomeModal";
import { format, subMonths } from 'date-fns';
import { Moment } from "moment";
import moment from "moment";

const { RangePicker } = DatePicker;

export default function IncomesView() {

    const [defectProducts, setDefectProducts] = useState<BillRespone[]>([]);
    const [loading, setLoading] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [startDate, setStartDate] = useState<Date>(subMonths(new Date(), 1));
    const [endDate, setEndDate] = useState<Date>(new Date());

    function LoadInitData() {
        setLoading(true);

        async function fetch() {
            const res = await BillApi.GetIncomeBillsApi(startDate, endDate);

            if (res?.status === 200) {
                setDefectProducts(res.data.items);
                setLoading(false);
            }
            else {
            }

        }
        fetch();
    }

    useEffect(() => {
        LoadInitData();
    }, []);

    function changeRangeTime(values: any, formatString: [string, string]) {
        const startTime: Moment = values[0];
        const endTime: Moment = values[1];

        setStartDate(startTime.toDate());
        setEndDate(endTime.toDate());
        LoadInitData();
    }

    const columns: ColumnsType<BillRespone> = [
        {
            title: 'Mã phiếu',
            dataIndex: 'code',
            key: 'code',
            onHeaderCell: () => headerStyle(),
            render: (id) => (
                <span>{id}</span>
            )
        },
        {
            title: 'Nhân viên',
            dataIndex: 'creatorName',
            key: 'creatorName',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Số tiền',
            width: '15%',
            dataIndex: 'amount',
            key: 'amount',
            onHeaderCell: () => headerStyle(),
            render: (amount) => (
                MathHelper.formatPriceVND(amount)
            )
        },
        {
            title: 'Diễn giải',
            dataIndex: 'reason',
            key: 'reason',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Thời điểm',
            dataIndex: 'createdTime',
            key: 'createdTime',
            onHeaderCell: () => headerStyle(),
            render: (createdTime) => (
                format(new Date(createdTime), "dd-MM-yyyy")
            )
        },
    ];

    function afterCreateBill() {
        setShowCreateModal(false);
        LoadInitData();
    }

    return (
        <>

            <LeftRightHeaderContent rightContent={<Button type="primary"
                onClick={() => setShowCreateModal(true)} size="middle">
                Thêm phiếu thu
            </Button>}
                leftContent={<RangePicker format='DD/MM/YYYY'
                    clearIcon={false}
                    style={{ width: '220px' }}
                    onChange={changeRangeTime}
                    defaultValue={[moment(subMonths(new Date(), 1)), moment()]} />}
            ></LeftRightHeaderContent>

            <div>
                <Spin spinning={loading} tip="đang tải...">
                    <Table size="small"
                        columns={columns}
                        dataSource={defectProducts}
                        pagination={{ pageSize: 20 }}

                        scroll={{ x: '1000px', y: getHeightFit(250) }}
                    />
                </Spin>

            </div>

            <CreateIncomeModal title="Tạo phiếu thu"
                visible={showCreateModal}
                afterCreateFnc={afterCreateBill}
                onCancel={() => setShowCreateModal(false)} />
        </>
    )
}