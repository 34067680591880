import { Col, DatePicker, Form, Input, message, Modal, ModalProps, Radio, RadioChangeEvent, Row, Select } from "antd";
import { useEffect, useState } from "react";
import { GetCities, GetDistricts } from "../../Api/AddressApi";
import { CreateCustomerApi, UpdateCustomerApi } from "../../Api/CustomerApi";
import { CustomerRes, CustomerReq } from "../../Model/CustomerModel";
import { CustomerViewModel } from "../../ViewModel/CustomerViewModel";
import CCombobox from "../Controls/CCombobox";
const { Option } = Select;

//Định nghĩa các thuộc tính
type CustomerModalProps = ModalProps &
{
    okModalFunc: Function,
    customEditting?: CustomerRes,
    cities?: CityResponse[] //Cái này nhằm đỡ tốn perfomance
}

//Tạo ra cái modal control
export function CustomerModal(props: CustomerModalProps) {

    const { customEditting, cities, okModalFunc, ...rest } = props;

    const [customerForm] = Form.useForm<CustomerViewModel>();

    const [districts, setDistricts] = useState<DistrictResponse[]>([]);
    const [CustomerType, setCustomerType] = useState<number>(1);

    useEffect(() => {
        //Edit thì cần fill đủ field
        if (customEditting) {
            customerForm.setFieldsValue({
                customerName: customEditting.fullName,
                address: customEditting.address,
                phone: customEditting.phoneNumber,
                cityId: customEditting.city?.id
            });

            if (customEditting.city) {
                GetDistricts(customEditting.city.id, (distrs: DistrictResponse[]) => {
                    setDistricts(distrs);
                });
                if (customEditting.district)
                    customerForm.setFieldsValue({ districtId: customEditting.district.id });
            }


        } else { //clear form
            customerForm.setFieldsValue({ customerName: '', address: '', phone: '', cityId: '', districtId: '' });
        }

    }, [rest.visible]);

    // Khi chọn thành phố thì sẽ cập nhật quân/huyện
    function cityChange(value: any) {
        GetDistricts(value, (distrs: DistrictResponse[]) => {
            setDistricts(distrs);
        })
    }

    //Gọi API trùy dữ liệu từ giao diện lên 
    const CreateCustomerSubmit = () => {

        customerForm.validateFields()
            .then((data: CustomerViewModel) => {

                if (!data.customerName) {
                    message.warning("Tên khách hàng không được để trống");
                    return;
                }

                if (!data.address || !data.cityId || !data.districtId) {
                    message.warning("Thiếu thông tin địa khách hàng");
                    return;
                }

                if (!data.phone) {
                    message.warning("SĐT khách hàng không được để trống");
                    return;
                }

                const customData: CustomerReq = {
                    fullName: data.customerName,
                    address: data.address,
                    phoneNumber: data.phone,
                    cityId: data.cityId,
                    districtId: data.districtId
                };

                if (customEditting) { // Chỉnh sửa
                    UpdateCustomerApi(customEditting.id, customData, (res: CustomerRes) => {
                        let newCus: CustomerRes = {
                            ...res
                        };

                        okModalFunc(newCus);
                    })
                } else { //Thêm mới


                    CreateCustomerApi(customData, (res: CustomerRes) => {
                        let newCus: CustomerRes = {
                            ...res
                        };

                        if (!newCus.city)
                            newCus.city = { name: '', id: data.cityId, slug: '', code: '', nameWithType: '' };
                        if (!newCus.district)
                            newCus.district = { name: '', id: data.districtId, code: '', nameWithType: '', path: '', pathWithType: '', slug: '' };
                        okModalFunc(newCus);

                    })
                }


            });
    };


    const onChangeCustomTye = (e: RadioChangeEvent) => {
        setCustomerType(e.target.value);
    }

    return (
        <Modal title="Thêm khách hàng" {...rest} centered
            style={{ paddingTop: 0, paddingBottom: 0 }}
            onOk={CreateCustomerSubmit} >
            <Form form={customerForm}
                layout="vertical" >
                <Radio.Group onChange={onChangeCustomTye} value={CustomerType}>
                    <Radio value={1}>Cá nhân</Radio>
                    <Radio value={2}>Tổ chức</Radio>
                </Radio.Group>

                <Row>
                    <Col span={12} style={{ paddingRight: 5 }}>
                        <Form.Item name="customerName" label="Tên khách hàng" required >
                            <Input placeholder="Nhập tên khách hàng" />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label="Ngày sinh" name="birthday">
                            <DatePicker format='DD/MM/YYYY' placeholder="Chọn ngày sinh" />
                        </Form.Item>
                    </Col>

                    <Col span={4}>
                        <Form.Item label="Giới tính" name="sex">
                            <Select>
                                <Option key="male" value="male">Nam</Option>
                                <Option key="female" value="female">Nữ</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={11}>
                        <Form.Item label="Số điện thoại" required name="phone">
                            <Input placeholder="Nhập mã khách hàng" />
                        </Form.Item>
                    </Col>
                    <Col span={1} />
                    <Col span={12}>
                        {CustomerType === 2 ? (<Form.Item label="Mã số thuế" name="MST">
                            <Input placeholder="Nhập mã số thuế doanh nghiệp khách hàng" />
                        </Form.Item>) : ("")}
                    </Col>
                </Row>



                <Row>
                    <Col span={12} style={{ paddingRight: 5 }}>
                        <Form.Item label="Tỉnh/Thành phố" name="cityId">
                            <CCombobox chooseOptions={cities ? cities : []} displayName="name" id="id" onChange={cityChange} />
                        </Form.Item>
                    </Col>

                    <Col span={12}>

                        <Form.Item label="Quận/Huyện" name="districtId">
                            <CCombobox chooseOptions={districts} displayName="name" id="id" />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item name="address" label="Địa chỉ" required >
                    <Input placeholder="Nhập địa chỉ" />
                </Form.Item>

            </Form>
        </Modal>
    )
}

