import { ErrorHandleFunc } from "../Helper/ErrorHandling";

export class RequestHandler {
    static handleRequest(promise: Promise<any>, doAfterResponse?: Function) {
        promise.then((response) => {
            if (response.status === 200) {
                doAfterResponse && doAfterResponse(response.data);
            }
            else {

            }
        }).catch((error) => {
            ErrorHandleFunc(error);
        });
        return promise;
    }
}

export const HandleRequest = (promise: Promise<any>, doAfterResponse: Function) => {

    // //Kiểm tra token trước khi promise
    // if (!requireToken || ValidToken()) {
    //     promise.then((response) => {
    //         if (response.status === 200) {
    //             doAfterResponse(response.data);
    //         }
    //         else {

    //         }

    //     }).catch((error) => {
    //         ErrorHandleFunc(error);
    //     });
    //     return promise;
    // }
    // else {
    //     message.info("Get refresh token");
    //     let token = getToken();
    //     let refresh = localStorage.getItem('refreshToken');
    //     if (token && refresh) {
    //         RenewTokenApi(token, refresh, (res: AuthenResponse) => {
    //             if (res != null) {
    //                 localStorage.setItem('token', res.token);
    //                 localStorage.setItem('refreshToken', res.refreshToken);

    //                 promise.then((response) => {
    //                     if (response.status === 200) {
    //                         doAfterResponse(response.data);
    //                     }
    //                     else {

    //                     }

    //                 }).catch((error) => {
    //                     ErrorHandleFunc(error);
    //                 });
    //             }
    //         })
    //     }
    // }

    promise.then((response) => {
        if (response.status === 200) {
            doAfterResponse(response.data);
        }
        else {

        }
    }).catch((error) => {
        ErrorHandleFunc(error);
    });
    return promise;
}



