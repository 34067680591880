import { AxiosRequest } from "./Axios/AxiosRequest";

import { HandleRequest } from "./RequestAPI";
import { SERVER_API } from "./UrlDefination";

//Get Planned Maintenances; null-> all stage, 0-> Request, 1-> Approved, 2 -> Declined
export const GetPlannedMaintenacesApi = async (doAfterResponse: Function) => {
    const query = "MaintenanceStages=0&MaintenanceStages=1&MaintenanceStages=2";
    await HandleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.MAINTENANCE.GET}?${query}`), doAfterResponse);

};

export const GetPlannedMaintenacesFilterApi = async (stage: number, doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.MAINTENANCE.GET}?MaintenanceStage=${stage}`), doAfterResponse);

};

export const GetMaintenancesByIdApi = async (maintenaceId: string, doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.MAINTENANCE.GET_BY_ID(maintenaceId)}`), doAfterResponse);

};

export const GetMaintenancesByProductIdApi = async (productId: string, doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.MAINTENANCE.GET_BY_PRODUCT_ORDER_ID(productId)}`), doAfterResponse);

};

//Create new product. Need to error handling
export const CreateMaitenanceApi = async (data: any, doAfterResponse: Function) => {

    await HandleRequest(AxiosRequest.post(`${SERVER_API.HOST}/${SERVER_API.MAINTENANCE.CREATE_HIGH_PRIORITY}`, data), doAfterResponse);

}

// Chọn 1 maintenance đê chuyển vào kế hoạch bão dưỡng cho khách
export const CreateMaintenancePlanApi = async (id: string, doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.MAINTENANCE.CREATE_PLAN_FOR_ID(id)}`, { plannedTime: new Date() }), doAfterResponse);

}

// Chọn 1 maintenance đê chuyển vào kế hoạch bão dưỡng cho khách  0-> Approved, 1 -> Declined
export const DecideMaintenenceRequestApi = async (data: any, doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.MAINTENANCE.STAGE_MAINTENANCES}`, data), doAfterResponse);

}

// Chọn 1 maintenance đê chuyển vào kế hoạch bão dưỡng cho khách
// Register = 0; Maintaining =1, Completed = 2
export const MaintenanceProcessingApi = async (id: string, action: number, doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.MAINTENANCE.MAINTENANCE_ACTION(id)}`, { maintenanceAction: action }), doAfterResponse);

}
