import { Button, DatePicker, Space, Table } from "antd";
import { LeftRightHeaderContent } from "../../Controls/LeftRightHeaderContent";
import { useEffect, useState } from "react";
import { subDays } from "date-fns";
import {
    ArrowLeftOutlined,
} from '@ant-design/icons';
import moment, { Moment } from "moment";
import { format } from 'date-fns';
import { PurchaseReportApi } from "../../../Api/Reports/PurchaseReportApi";
import { ColumnsType } from "antd/lib/table";
import { CommonHelper } from "../../../Helper/CommonHelper";
import { filterTypes, PurchaseStatus } from "../../../common/type-define";
import { MathHelper } from "../../../Helper/math.helper";
import { ChoosFilter, FilterControls } from "../../Controls/FilterControls";
import { headerStyle } from "../../Helper/UIHelper";
const { RangePicker } = DatePicker;

export default function PurchaseByPaymentReport() {
    const [data, setData] = useState<IPurchasePaymentReport[]>([]);
    const [showConcept, setShowConcept] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<Date>(subDays(new Date(), 7));
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [filters, setFilters] = useState<FilterItem[]>([]);
    useEffect(() => {
        fetchData()
        setFilters([{ type: filterTypes.PROVIDER, closeable: false },
        { type: filterTypes.STAFF, closeable: true },
        { type: filterTypes.PAYMENT_TYPES, closeable: true }]);
    }, []);

    function fetchData() {
        const query = {
            QueryFrom: startDate,
            QueryTo: endDate
        }
        var re: IPurchasePaymentReport[] = [];
        PurchaseReportApi.GetPurchaseReportsApi(query).then(response => {
            const purchaseReportRes: IPurchaseReportResponse[] = response.data;
            purchaseReportRes.forEach(res => {
                if (res.purchasePaidHistories.length > 0) {
                    const orderTime = res.createdTime;
                    const purchasecode = res.purchaseCode;
                    const provider = res.provider.name;
                    var quantity = 0;
                    var vatTax = 0;
                    var importExportTax = 0;
                    var discount = 0;
                    var productMoney = 0;
                    res.purchaseProductVersions.forEach(proV => {
                        quantity += proV.quantities;
                        vatTax += proV.quantities * proV.vat * proV.priceInput / 100;
                        importExportTax += proV.quantities * proV.importAndExportTax * proV.priceInput / 100;
                        discount += proV.discountType === 1 ? proV.discount * proV.quantities * proV.priceInput : proV.discount * proV.quantities;
                        productMoney += proV.quantities * proV.priceInput;
                    })
                    const productMoneyAfterDiscount = productMoney - discount;
                    discount += res.discountType === 1 ? res.discount * productMoneyAfterDiscount : res.discount;
                    const carrierPrice = res.purchaseCarrier.price - res.purchaseCarrier.discount;
                    var totalMoney = productMoney + importExportTax + vatTax + carrierPrice - discount;
                    var paidMoney = res.purchasePaidHistories.reduce((prev, curr) => {
                        return prev + curr.amount
                    }, 0)
                    var remainMoney = totalMoney - paidMoney;
                    const paymentTypeNum = res.purchasePaidHistories[0].paymentType;
                    const paymentType = CommonHelper.getPaymentType(paymentTypeNum)
                    re.push({
                        purchaseCode: purchasecode,
                        provider: provider,
                        employee: res.purchaseHistories.find(p => p.statusCode === PurchaseStatus.Approved)?.actorName,
                        paymentType: paymentType,
                        paidMoney: paidMoney,
                        remainMoney: remainMoney
                    })
                }
            })
            setData(re);
        })
    }
    const columns: ColumnsType<IPurchasePaymentReport> = [
        {
            title: 'Mã đơn nhập',
            dataIndex: 'purchaseCode',
            key: 'purchaseCode',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Tên NCC',
            dataIndex: 'provider',
            key: 'provider',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Nhân viên',
            dataIndex: 'employee',
            key: 'employee',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Hình thức thanh toán',
            dataIndex: 'paymentType',
            key: 'paymentType',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Tiền đã thanh toán',
            dataIndex: 'paidMoney',
            key: 'paidMoney',
            onHeaderCell: () => headerStyle(),
            render: (paidMoney) => (
                MathHelper.formatPriceVND(paidMoney, true)
            )
        },
        {
            title: 'Tiền còn lại',
            dataIndex: 'remainMoney',
            key: 'remainMoney',
            onHeaderCell: () => headerStyle(),
            render: (remainMoney) => (
                MathHelper.formatPriceVND(remainMoney, true)
            )
        }
    ]

    function changeRangeTime(values: any, formatString: [string, string]) {
        const startTime: Moment = values[0];
        const endTime: Moment = values[1];

        setStartDate(startTime.toDate());
        setEndDate(endTime.toDate());
        fetchData();
    }

    function onChangeFilter() {

    }

    return (
        <div>
            <Space direction='horizontal'>
                <Button type="default" icon={<ArrowLeftOutlined />} href="/admin/report/purchase"></Button>
                <RangePicker format='DD/MM/YYYY'
                    clearIcon={false}
                    style={{ width: '220px' }}
                    onChange={changeRangeTime}
                    defaultValue={[moment(subDays(new Date(), 7)), moment()]} />
                <ChoosFilter filterList={filters} onChangeFunc={(items: FilterItem[]) => setFilters(items)} />

            </Space>
            <FilterControls items={filters} afterChangeFnc={onChangeFilter} />

            <Table columns={columns} dataSource={data} size="small" bordered

                pagination={false}
            />
        </div>
    )
}