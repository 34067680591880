import { Button, DatePicker, Space, Table } from "antd";
import { LeftRightHeaderContent } from "../../Controls/LeftRightHeaderContent";
import { useEffect, useState } from "react";
import { subDays, subMonths } from "date-fns";
import {
    ArrowLeftOutlined,
} from '@ant-design/icons';
import moment, { Moment } from "moment";
import { format } from 'date-fns';
import { PurchaseReportApi } from "../../../Api/Reports/PurchaseReportApi";
import { ColumnsType } from "antd/lib/table";
import { filterTypes, PurchaseStatus } from "../../../common/type-define";
import { ChoosFilter, FilterControls } from "../../Controls/FilterControls";
import { MathHelper } from "../../../Helper/math.helper";
import { headerStyle } from "../../Helper/UIHelper";
const { RangePicker } = DatePicker;

export default function PurchaseSummaryReport() {

    const [showConcept, setShowConcept] = useState<boolean>(false);
    const [data, setData] = useState<IPurchaseSummaryReport[]>([]);
    const [startDate, setStartDate] = useState<Date>(subDays(new Date(), 7));
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [filters, setFilters] = useState<FilterItem[]>([]);
    useEffect(() => {
        fetchData()
        setFilters([
            { type: filterTypes.PROVIDER, closeable: false },
            { type: filterTypes.STAFF, closeable: true },
            { type: filterTypes.PAYMENT_TYPES, closeable: true }]);
    }, []);

    function fetchData() {
        let re: IPurchaseSummaryReport[] = [];

        const query = {
            QueryFrom: startDate,
            QueryTo: endDate
        }
        PurchaseReportApi.GetPurchaseReportsApi(query).then(response => {
            const purchaseReportRes: IPurchaseReportResponse[] = response.data;
            purchaseReportRes.forEach(res => {
                if (res.purchaseStatus === PurchaseStatus.Approved
                    || res.purchaseStatus === PurchaseStatus.Stored) {
                    const orderTime = res.createdTime;
                    const purchasecode = res.purchaseCode;
                    const provider = res.provider.name;
                    var quantity = 0;
                    var vatTax = 0;
                    var importExportTax = 0;
                    var discount = 0;
                    var productMoney = 0;
                    res.purchaseProductVersions.forEach(proV => {
                        quantity += proV.quantities;
                        vatTax += proV.quantities * proV.vat * proV.priceInput / 100;
                        importExportTax += proV.quantities * proV.importAndExportTax * proV.priceInput / 100;
                        discount += proV.discountType === 1 ? proV.discount * proV.quantities * proV.priceInput : proV.discount * proV.quantities;
                        productMoney += proV.quantities * proV.priceInput;
                    })
                    const productMoneyAfterDiscount = productMoney - discount;
                    discount += res.discountType === 1 ? res.discount * productMoneyAfterDiscount : res.discount;
                    const carrierPrice = res.purchaseCarrier.price - res.purchaseCarrier.discount;
                    var totalMoney = productMoney + importExportTax + vatTax + carrierPrice - discount;
                    re.push({
                        orderTime: orderTime,
                        purchaseCode: purchasecode,
                        provider: provider,
                        quantity: quantity,
                        carrierPrice: carrierPrice,
                        discount: discount,
                        vat: vatTax,
                        importExportTax: importExportTax,
                        productMoney: productMoney,
                        totalMoney: totalMoney

                    })
                }
            })

            setData(re);
        })


    }

    const columns: ColumnsType<IPurchaseSummaryReport> = [
        {
            title: 'Ngày nhập',
            dataIndex: 'orderTime',
            key: 'orderTime',
            onHeaderCell: () => headerStyle(),
            render: (orderTimee) => (
                format(new Date(orderTimee), "dd-MM-yyyy")
            )
        },
        {
            title: 'Mã đơn nhập',
            dataIndex: 'purchaseCode',
            key: 'purchaseCode',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'NCC',
            dataIndex: 'provider',
            key: 'provider',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Số lượng',
            dataIndex: 'quantity',
            key: 'quantity',
            align: 'center',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Chi phí vận chuyển',
            dataIndex: 'carrierPrice',
            key: 'carrierPrice',
            onHeaderCell: () => headerStyle(),
            render: (carrierPrice) => (
                MathHelper.formatPriceVND(carrierPrice, true)
            )
        },
        {
            title: 'Chiết khấu',
            dataIndex: 'discount',
            key: 'discount',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Thuế VAT',
            dataIndex: 'vat',
            key: 'vat',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Thuế XNK',
            dataIndex: 'importExportTax',
            key: 'importExportTax',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Tiền hàng',
            dataIndex: 'productMoney',
            key: 'productMoney',
            onHeaderCell: () => headerStyle(),
            render: (productMoney) => (
                MathHelper.formatPriceVND(productMoney, true)
            )
        },
        {
            title: 'Tổng tiền',
            dataIndex: 'totalMoney',
            key: 'totalMoney',
            onHeaderCell: () => headerStyle(),
            render: (totalMoney) => (
                MathHelper.formatPriceVND(totalMoney, true)
            )
        },
    ]

    function changeRangeTime(values: any, formatString: [string, string]) {
        const startTime: Moment = values[0];
        const endTime: Moment = values[1];

        setStartDate(startTime.toDate());
        setEndDate(endTime.toDate());
        fetchData();
    }

    function onChangeFilter() {

    }

    return (
        <div>

            <Space direction='horizontal'>
                <Button type="default" icon={<ArrowLeftOutlined />} href="/admin/report/purchase"></Button>
                <RangePicker format='DD/MM/YYYY'
                    clearIcon={false}
                    style={{ width: '220px' }}
                    onChange={changeRangeTime}
                    defaultValue={[moment(subMonths(new Date(), 1)), moment()]} />
                <ChoosFilter filterList={filters} onChangeFunc={(items: FilterItem[]) => setFilters(items)} />

            </Space>


            <FilterControls items={filters} afterChangeFnc={onChangeFilter} />

            <Table columns={columns} dataSource={data} size="small" bordered

                pagination={false}
            />
        </div>
    )
}