import { AxiosResponse } from "axios";
import { AxiosRequest } from "../Axios/AxiosRequest";
import { RequestHandler } from "../RequestAPI";
import { SERVER_API } from "../UrlDefination";

function getQueryParams(query: QueryInventoryReport): string {
    const arrQueryTemp = Object.keys(query).reduce((res, key) => {
        const value = query[key as keyof QueryInventoryReport];
        let temp: string = '';
        if (Array.isArray(value)) {
            temp = value.map(t => `${key}=${t}`).join("&");
        } else if (typeof value === 'string' || typeof value === 'number') {
            temp = `${key}=${value}`
        }

        if (temp) res.push(temp);
        return res
    }, [] as string[]);

    return arrQueryTemp.join("&");
}
export class InventoryReportApi
{
    static async GetInventoryReportsApi(query: QueryInventoryReport): Promise<AxiosResponse<IInventory[], any>> {
        const fullQuery = getQueryParams(query);
        console.log(query);
        return RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.REPORT.GET_INVENTORY_REPORTS}?${fullQuery}`));
    }

    static async GetInventoryNormReportsApi(): Promise<AxiosResponse<IInventoryNormReport[], any>> {
        return RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.REPORT.GET_INVENTORY_NORM_REPORTS}`));
    }

    static async GetDetailInventoryReportsApi(): Promise<AxiosResponse<IDetailInventoryReport[], any>> {
        return RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.REPORT.GET_DETAIL_INVENTORY_REPORTS}`));
    }

}