import { createSelector } from "@reduxjs/toolkit"
import { RootState } from "../configure/rootReducer"

export const getReturnOrderModalVis = createSelector(
    (rootState: RootState) => rootState.returnOrdersAction,
    (state) => state.returnOrderModalVis,
)

export const getReturnOrderId = createSelector(
    (rootState: RootState) => rootState.returnOrdersAction,
    (state) => state.returnOrderId,
)

export const getReturnOrderDetail = createSelector(
    (rootState: RootState) => rootState.returnOrdersAction,
    (state) => state.returnOrderDetail,
)

export const getFetchReturnOrderLoading = createSelector(
    (rootState: RootState) => rootState.returnOrdersAction,
    (state) => state.fetchReturnOrderLoading,
)

export const getCreateReturnOrderLoading = createSelector(
    (rootState: RootState) => rootState.returnOrdersAction,
    (state) => state.createReturnOrderLoading,
)

export const getReturnOrderList = createSelector(
    (rootState: RootState) => rootState.returnOrdersAction,
    (state) => state.returnOrderList,
)

export const getReturnOrderProcessId = createSelector(
    (rootState: RootState) => rootState.returnOrdersAction,
    (state) => state.returnOrderProcessId,
)

export const getReturnOrderProcess = createSelector(
    (rootState: RootState) => rootState.returnOrdersAction,
    (state) => state.returnOrderProcess,
)

export const getFetchProductLoading = createSelector(
    (rootState: RootState) => rootState.returnOrdersAction,
    (state) => state.fetchProductLoading,
)