import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { catchError, concat, filter, from, switchMap, withLatestFrom } from "rxjs";
import { OrderApi } from "../../Api/OrderApi";
import { OrderStatus } from "../../common/type-define";
import { IOrderRespone } from "../../Model/OrderModel";
import { RootEpic } from "../configure/rootReducer";
import { setReturnOrderDetail } from "./returnOrders.slice";

interface IOrderActionState {
    listOrder: IOrderRespone[],
    fetchOrderLoading: boolean,
    fetchOrderByIdLoading: boolean,
}

const initState: IOrderActionState = {
    listOrder: [],
    fetchOrderLoading: false,
    fetchOrderByIdLoading: false,
}

const orderActionSlice = createSlice({
    name: 'orderAction',
    initialState: initState,
    reducers: {
        fetchOrders(state, action: PayloadAction<OrderStatus[] | undefined>) { return },
        setListOrder(state, action: PayloadAction<IOrderRespone[]>) {
            state.listOrder = action.payload;
        },
        setFetchOrderLoading(state, action: PayloadAction<boolean>) {
            state.fetchOrderLoading = action.payload;
        },
        setFetchOrderByIdLoading(state, action: PayloadAction<boolean>) {
            state.fetchOrderByIdLoading = action.payload;
        },
        fetchOrderById(state, action: PayloadAction<string>) { return },
    }
});

const fetchOrders$: RootEpic = (action$, state$) => action$.pipe(
    filter(fetchOrders.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const orderStatusFilter = action.payload;

        return concat(
            [orderActionSlice.actions.setFetchOrderLoading(true)],
            from(OrderApi.getOrders(orderStatusFilter, () => null)).pipe(
                switchMap(response => {
                    if (response?.status !== 200) return [];
                    return [
                        orderActionSlice.actions.setListOrder(response.data.items)
                    ]
                }),
                catchError(err => [orderActionSlice.actions.setFetchOrderLoading(false)])
            ),
            [orderActionSlice.actions.setFetchOrderLoading(false)]
        )
    })
)

const fetchOrderById$: RootEpic = (action$, state$) => action$.pipe(
    filter(fetchOrderById.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const orderCode = action.payload;
        return concat(
            [orderActionSlice.actions.setFetchOrderByIdLoading(true)],
            from(OrderApi.getOrderById(orderCode, () => null)).pipe(
                switchMap(response => {
                    if (response?.status !== 200) return [];
                    return [
                        setReturnOrderDetail(response.data)
                    ]
                }),
                catchError(err => [orderActionSlice.actions.setFetchOrderByIdLoading(false)])
            ),
            [orderActionSlice.actions.setFetchOrderByIdLoading(false)]
        )
    })
)

export const OrderActionEpics = [
    fetchOrders$,
    fetchOrderById$,
]

export const {
    fetchOrders,
    fetchOrderById,
} = orderActionSlice.actions;

export default orderActionSlice.reducer;