import { AxiosRequest } from "./Axios/AxiosRequest";
import { HandleRequest, RequestHandler } from "./RequestAPI";
import { SERVER_API } from "./UrlDefination";
import { AxiosResponse } from "axios";


export class AgencyApi {
    static async getAgencies(): Promise<AxiosResponse<IListItems<AgencyResponse>, any>> {
        return RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.AGENCY.GET}`));
    }
    static async getProductVersionByAgencyIds(agencyIds: string[]): Promise<AxiosResponse<IListItems<ProductVersionResponse>, any>> {
        const params = agencyIds.map(id => `AgencyIds=${id}`).join('&');
        return RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.AGENCY.GET_PRODUCT_VERSION_BY_IDS(params)}`));
    }
}
export const GetAgenciesApi = async (doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.AGENCY.GET}`), doAfterResponse);
};

export const GetAgencyByIdApi = async (id: string, doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.AGENCY.GET_BY_ID(id)}`), doAfterResponse);
};


export const CreateAgencyApi = async (data: AgencyRequest, doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.post(`${SERVER_API.HOST}/${SERVER_API.AGENCY.CREATE}`, data), doAfterResponse);
};

export const UpdateAgencyApi = async (id: string, data: AgencyRequest, doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.AGENCY.UPDATE_BY_ID(id)}`, data), doAfterResponse);
};