import { Form, Input, Modal, ModalProps } from "antd";
import { CreateFactoryApi } from "../../Api/FactoryApi";
import Factory, { FactoryRequest } from "../../Model/FactoryModel";
import { NewFactoryViewModel } from "../../ViewModel/FactoryViewModel";


type FactoryModalProps = ModalProps &
{
    okModalFnc: Function,
    id?: string
}

export default function FactoryModal(props: FactoryModalProps) {
    const { okModalFnc, id, ...rest } = props;

    const [factoryForm] = Form.useForm<NewFactoryViewModel>();
    //Tạo ra 1 nhà sản xuất mới
    const CreateFactorySubmit = () => {

        factoryForm.validateFields()
            .then((data: NewFactoryViewModel) => {
                let factoryData: FactoryRequest = {
                    name: data.factoryName,
                    address: data.address,
                    hotline: data.hotline
                };

                CreateFactoryApi(factoryData, (res: Factory) => {
                    okModalFnc?.(res);

                });

            });


    }

    return (
        <Modal {...rest}
            onOk={CreateFactorySubmit}>
            <Form form={factoryForm}
                layout="vertical">
                <Form.Item name="factoryName" label="Tên nhà sản xuất" required >
                    <Input placeholder="Nhập tên nhà sản xuất" />
                </Form.Item>

                <Form.Item name="address" label="Địa chỉ" required >
                    <Input placeholder="Nhập địa chỉ nhà sản xuất" />
                </Form.Item>

                <Form.Item name="hotline" label="Số điện thoại" required >
                    <Input placeholder="Nhập số hotline" />
                </Form.Item>

            </Form>
        </Modal>
    )
}