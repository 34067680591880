import { Select, Image, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { GetProductImageUrl } from "../../../Api/ProductApi";
import { useSelectorRoot } from "../../../redux/configure/store";
import { getListProduct } from "../../../redux/selectors/products.selector";
import { getProductsByVoucher } from "../../../redux/selectors/voucher.selector";
import { fetchProducts } from "../../../redux/slices/products.slice";
import { getProductsAppliedToVoucher } from "../../../redux/slices/voucher.slice";

const { Option } = Select;

interface VoucherAssignProps {
    setAssignProducts: (productIds: string[]) => void;
    voucherId: string | undefined;
}

const VoucherAssignComponent = ({
    setAssignProducts,
    voucherId,
}: VoucherAssignProps): JSX.Element => {
    const dispatch = useDispatch();
    const listProduct = useSelectorRoot(getListProduct);
    const productsByVoucher = useSelectorRoot(getProductsByVoucher);
    const [defaultValue, setDefaultValue] = useState<string[]>([]);
    useEffect(() => {
        setDefaultValue(productsByVoucher.map(v => v.productId))
    }, [productsByVoucher])

    useEffect(() => {
        dispatch(fetchProducts())
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(voucherId !== undefined){
            dispatch(getProductsAppliedToVoucher(voucherId))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [voucherId])

    const handleChange = (option: any) => {
        const productIds = (option as any[]).map(v => v.value);
        setAssignProducts(productIds);
        setDefaultValue(productIds);
    }

    const tagRender = (props: any) => {
        const {closable, onClose, label } = props;
        return (
            <Tag
                closable={closable}
                onClose={onClose}
                style={{ marginRight: 3, padding: '4px 6px', fontSize: '14px' }}
                >
                {label?.props?.children[1]?.props?.children}
            </Tag>
        )
    }

    return (
        <div>
            <div style={{margin: '4px'}}>Áp dụng Voucher cho sản phẩm</div>
            <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Chọn sản phẩm để áp dụng Voucher"
                onChange={(_, opt) => handleChange(opt)}
                tagRender={tagRender}
                value={defaultValue}
            >
            {
                listProduct.map(v => 
                    <Option
                        label={v.name}
                        value={v.id}
                        key={v.id}>
                        <div style={{display: 'flex', alignItems:'center'}}>
                            <Image 
                                preview={false}
                                style={{objectFit: 'contain', height: '50px',width: '70px'}}
                                src={GetProductImageUrl(v.representativeImage?.url ?? "")} />
                            <span>{v.name}</span>
                        </div>
                    </Option>
                )
            }
            </Select>
        </div>

    )
}

const VoucherAssign = React.memo(VoucherAssignComponent);
export default VoucherAssign;