import { Modal, ModalProps } from "antd";

type ReturnProductToProviderProps = ModalProps & {

}
export default function ReturnProductToProviderModal(props: ReturnProductToProviderProps) {

    const { ...rest } = props;
    return (
        <Modal {...rest}>

        </Modal>
    )
}