import { Modal, ModalProps, Form, Card, Radio, Space, RadioChangeEvent, message, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { CompleteOrderFromStaffApi, OrderApi } from "../../Api/OrderApi";
import { MathHelper } from "../../Helper/math.helper";
import { PAYMEN_OPTIONS } from "../../Model/CommonDefine";
import { paidDataReq, paymentRequest } from "../../Model/OrderModel";
import InputMoney from "../Controls/MoneyInput";

import {
    AccountBookOutlined
} from '@ant-design/icons';

type PaymentProps = ModalProps & {
    id: string,
    remainMoney: number,
    applyHandle: Function
}
export default function PaymentModal(props: PaymentProps) {

    const { id, remainMoney, applyHandle, ...rest } = props;
    const [paymentMethod, setPaymentMethod] = useState<number>(0);
    const [dataForm] = Form.useForm<any>();

    useEffect(() => {
        if (rest.visible) {
            dataForm.setFieldsValue({ money: 0 })
        }
    }, [rest.visible])

    const OrderPayHandle = () => {
        const money = dataForm.getFieldValue("money");

        if (money <= 0) {
            message.warning("Số tiền thanh toán chưa phù hợp");
            return;
        }
        async function fetch() {
            const data: paidDataReq = {
                amount: dataForm.getFieldValue("money"),
                paymentType: paymentMethod,
                isPaidCompleted: false
            }
            const res = await OrderApi.paidOrderById(id, data, () => { });

            if (res?.status === 200) {
                if (applyHandle)
                    applyHandle();
            }

        };

        if (money - remainMoney >= 0) { //Thanh toán toàn bộ
            let paymentReq: paymentRequest = {
                amount: remainMoney,
                isPaidCompleted: true,
                paymentType: paymentMethod
            }

            CompleteOrderFromStaffApi(id, paymentReq, (res: any) => {
                if (applyHandle)
                    applyHandle();
                message.info("Đơn hàng hoàn thành");

            });
        } else
            fetch();
    }

    //Thay đổi hình thức thanh toán
    const onPaymentChange = (e: RadioChangeEvent) => {
        setPaymentMethod(e.target.value);
    }


    return (
        <Modal {...rest}
            onOk={OrderPayHandle}
            okText="Thanh toán">

            <Form layout="vertical" form={dataForm}>

                <Space direction="horizontal">
                    <Form.Item label="Số tiền nợ">
                        {MathHelper.formatPriceVND(remainMoney)}
                    </Form.Item>

                    <Form.Item label="Số tiền" name="money">
                        <InputMoney />
                    </Form.Item>
                    <Tooltip title="Thanh toán hêt">
                        <AccountBookOutlined onClick={() => { dataForm.setFieldsValue({ money: remainMoney }) }} />
                    </Tooltip>

                </Space>

            </Form>

            <Card size="small" title="Hình thức thanh toán">
                <Radio.Group
                    defaultValue={0}
                    size="small"
                    onChange={onPaymentChange}>
                    <Space direction="vertical">
                        {
                            PAYMEN_OPTIONS.map((v, i) =>
                                <Radio
                                    key={i}
                                    value={i}
                                    checked={paymentMethod === i}>{v}</Radio>
                            )
                        }
                    </Space>
                </Radio.Group>
            </Card>

        </Modal>
    )
}