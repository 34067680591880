

export class MathHelper {
    static DateFormat = 'dd-MM-yyyy hh:mm:ss';

    static formatPriceVND(val: number | undefined, neagative: boolean = false) {
        if (typeof val === 'number') {
            if (val > 0)
                return new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(val)
            else if (neagative) {
                if (val === 0)
                    return new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(0)
                else
                    return new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(val)
            }

        }
        return '';
    }

    static formatPercent(val: number) {
        if (typeof val === 'number') {
            if (val > 0)
                return new Intl.NumberFormat('vi-VN', { style: 'percent', maximumFractionDigits: 2 }).format(val)

        }
        return '0%';
    }
}