import { Modal, ModalProps, Space, Steps, Tag } from "antd";
import { useEffect } from "react";

const { Step } = Steps;


type HistoryModalProps = ModalProps & {
    histories: HistoryItem[]
}

export interface HistoryItem {
    time: Date;
    description: string;
    actorName: string;
}

export default function HistoryModal(props: HistoryModalProps) {

    const { histories, ...rest } = props;

    useEffect(() => {

    }, [rest.visible])

    return (<Modal {...rest}>
        <Steps direction="vertical" size="small" current={histories.length}>
            {
                histories.map(x => (
                    <Step title={
                        <Space direction='horizontal'>
                            <Tag >{x.actorName}</Tag>
                            <span>{x.description}</span>
                        </Space>} key={`${histories.indexOf(x)}`} description={
                            <div>{new Date(x.time).toLocaleString()}</div>
                        } />
                ))
            }
        </Steps>
    </Modal>)
}