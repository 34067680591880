import { ProductAttibuteType } from "../common/type-define";

export const SERVER_AUTHEN = {
    //HOST: 'https://auth.phucvinh.tech',
    //HOST: 'http://localhost:5011',
    HOST: process.env.REACT_APP_API_AUTHEN,

    AUTHEN: {
        LOGIN: 'api/Identity/Login',
        TOKEN: 'api/Identity/RefreshToken',
        POST_GENERATE_PW: 'api/Identity/GeneratePasswordToken'
    },
    STAFF: {
        GET: 'api/Admin/GetAllUsers',
        CREATE: 'api/Admin/RegisterForNewUser',
        DELETE: 'api/Admin/DeleteUser',
        UPDATE: 'api/CustomerIdentity/ChangePassword'
    },
    CUSTOMER_IDENTITY: {
        SET_CUSTOMER_COMMISSION_CODE: 'api/CustomerIdentity/SetCustomerCommissionCode'
    },
    POLICY: {
        GET: 'api/Role/GetRoles',
        GET_BY_USER_ID: 'api/Role/GetUserRoles',
        PUT_TO_USER_ID: 'api/Role/SetUserRoles'
    },

    ROLE: {
        GET: 'api/RoleGroup/GetRoleGroups',
        CREATE: 'api/RoleGroup/CreateNewRoleGroup',
        GET_BY_ID: 'api/RoleGroup/GetRoleGroupById',
        UPDATE_BY_ID: (id: string) => `api/RoleGroup/UpdateRoleGroup/${id}`,
        DELETE_BY_ID: (id: string) => `api/RoleGroup/RemoveRoleGroup/${id}`,
        SET_TO_USER_ID: 'api/RoleGroup/SetRoleGroupForUser'
    },
}
export const MST = {
    HOST: 'https://thongtindoanhnghiep.co',
    GET: (mst: string) => `api/company/${mst}`
}

export const SERVER_API = {
    //HOST: 'http://localhost:5021',
    HOST: process.env.REACT_APP_API_MAIN,
    WAREHOUSE:
    {
        GET: 'api/warehouse/GetWarehouses',
        CREATE: 'api/warehouse/CreateNewWarehouse',
        UPDATE: (id: string) => `api/warehouse/UpdateWarehouse/${id}`,
        GET_PRODUCT_VERSION_BY_IDS: (params: string) => `api/product/GetProductVersionsByWarehouseIds?${params}`,
        CREATE_VERSION_TO_STORE: "api/product/CreateNewProductVersionToStore"
    },
    CATEGORY:
    {
        GET: 'api/category/GetCategories',
        CREATE: 'api/category/CreateNewCategory'
    },
    PRODUCT: {
        GET: 'api/product/GetProducts',
        GET_BY_ID: 'api/product/GetProductById',
        CREATE: 'api/product/CreateNewProduct',
        UPDATE: 'api/product/UpdateProduct',
        ADD_IMAGES: 'api/product/AddProductImages',
        GET_VERS: 'api/product/GetProductsWithVersions',
        CREATE_VERS: 'api/product/CreateNewProductVersion',
        UPDATE_VER_BY_ID: (id: string) => `api/product/UpdateProductVersion/${id}`,
        UPDATE_VERS: 'api/product/UpdateProductVersions',
        ASSIGN_EXTRA_WARRANTY: 'api/product/CreateExtraWarrantyToProduct',
        GET_ACCESSORIES: 'api/product/GetProductsByParentIds',
        GET_VERSION_BY_ID: (id: string) => `api/product/GetProductVersionById?Id=${id}`
    },
    PRODUCT_ORDER_RETURN: {
        GET_PRODUCT_ORDER_BY_ID: (id: string) => `api/orderReturn/GetProductOrderReturnById?Id=${id}`,
        GET_ORDER_BY_ID: (orderReturnId: string) => `api/orderReturn/GetOrderReturnById?OrderReturnId=${orderReturnId}`,
        GET_ALL_PRODUCT: `api/orderReturn/GetProductOrderReturns`,
        GET_ALL_ORDER: `api/orderReturn/GetOrderReturns`,
        CREATE: `api/orderReturn/CreateProductOrderReturn`,
        GET_PRODUCT_BY_ORDER_ID: (id: string) => `api/orderReturn/GetProductOrderReturnsByOrderReturnId?OrderReturnId=${id}`,
        TECHNICIAN_PROCESSING_ORDER: (orderReturnId: string) => `api/orderReturn/TechnicianProcessingOrderReturn/${orderReturnId}`,
        DECISION: (orderReturnId: string) => `api/orderReturn/DecisionProductOrderReturn/${orderReturnId}`,
        RESTORE: (orderReturnId: string) => `api/orderReturn/RestoreProductsToStore/${orderReturnId}`,
        CHANGE_TO_PROCESS: (orderReturnId: string) => `api/orderReturn/ChangeOrderReturnToProcess/${orderReturnId}`,
        REFUND_PRODUCTS_ORDER: (orderReturnId: string) => `api/orderReturn/RefundProductOrderReturns/${orderReturnId}`,
        COMPLETE_ORDER: (orderReturnId: string) => `api/orderReturn/CompleteOrderReturn/${orderReturnId}`,
    },
    UNITS: {
        GET: 'api/unit/GetUnits',
        CREATE: 'api/unit/CreateNewUnit',
        PUT_UPDATE: (id: string) => `api/unit/UpdateUnit/${id}`
    },
    ATTRIBUTE: {
        GET: (id: ProductAttibuteType) => `api/productAttribute/GetAttributes?AttributeType=${id}`,
        CREATE: 'api/productAttribute/CreateNewAttribute'
    },
    ADDRESS: {
        GET: 'api/place/GetCities',
        CITY: 'api/place/GetCityById',
        DISTRICT: 'api/place/GetDistrictsCityById'
    },
    ORDER: {
        GET: 'api/order/GetOrders',
        SEARCH: 'api/order/SearchOrders',
        GET_BY_ID: 'api/order/GetOrderById',

        CREAT_BY_CUSTOMER: 'api/order/CustomerCreateNewOrder',
        CREATE_BY_STAFF: 'api/order/StaffCreateNewOrder',
        PUT_APPROVE: (id: string) => `api/order/ApproveOrder/${id}`,
        PUT_UPDATE: (id: string) => `api/order/UpdateOrder/${id}`,
        PUT_PACKAGE: (id: string) => `api/order/PackagingOrder/${id}`,
        PUT_DELIVERY: (id: string) => `api/order/ProcessDeliverOrder/${id}`,
        PUT_COMPLETE: (id: string) => `api/order/CompleteOrder/${id}`,
        PUT_PAID: (id: string) => `api/order/PaidOrder/${id}`,
        REQUEST_CANCEL: (id: string) => `api/order/RequestCancelOrder/${id}`,
        PUT_CANCEL: (id: string) => `api/order/CancelOrder/${id}`,
        PUT_PAID_CARRIER: (id: string) => `api/order/PaidOrderCarrierFee/${id}`,
        SYNC_DATA: "api/order/SyncOrdersData",
        FAKE_ORDER: "api/order/CreateFakeOrders",
    },
    PURCHASE: {
        GET: 'api/purchase/GetPurchases',
        GET_BY_ID: 'api/purchase/GetPurchaseById',
        CREATE: 'api/purchase/CreatePurchase',
        UPDATE: (id: string) => `api/purchase/UpdatePurchase/${id}`,
        PUT_APPROVE: (id: string) => `api/purchase/ApprovingPurchase/${id}`,
        PUT_STORE: (id: string) => `api/purchase/storedPurchase/${id}`,
        PUT_PAID: (id: string) => `api/purchase/PaidPurchase/${id}`,
        PAID_CARRIER: (id: string) => `api/purchase/PaidPurchaseCarrierFee/${id}`,
        SYNC_DATA: "api/purchase/SyncPurchasesData"
    },
    CARRIER: {
        GET: 'api/carrier/GetCarriers',
        GET_BY_ID: (id: string) => `api/carrier/GetCarrierById?Id=${id}`,
        CREATE: 'api/carrier/CreateCarrier',
        UPDATE: (id: string) => `api/carrier/UpdateCarrier/${id}`
    },
    SALE_CHANNEL: {
        GET: 'api/saleChannel/GetSaleChannels',
        GET_BY_ID: (id: string) => `api/saleChannel/GetSaleChannelById?Id=${id}`,
        CREATE: 'api/saleChannel/CreateSaleChannel',
        UPDATE: (id: string) => `api/saleChannel/UpdateSaleChannel/${id}`
    },
    COMPANY: {
        GET: 'api/company/GetCompanies',
        GET_BY_ID: (id: string) => `api/company/GetCompanyById?Id=${id}`,
        CREATE: 'api/company/CreateNewCompany',
        UPDATE: (id: string) => `api/company/UpdateNewCompany/${id}`
    },
    PROVIDER: {
        GET: 'api/provider/GetProviders',
        CREATE: 'api/provider/CreateNewProvider',
        UPDATE: (id: string) => `api/provider/UpdateProvider/${id}`
    },
    FACTORY: {
        GET: 'api/Factory/GetFactories',
        CREATE: 'api/factory/CreateNewFactory'
    },
    CUSTOMER: {
        GET: 'api/customer/GetCustomers',
        SEARCH: 'api/customer/SearchCustomers',
        CREATE: 'api/customer/CreateNewWalkInCustomer',
        GET_BY_ID: (id: string) => `api/customer/GetCustomerById?Id=${id}`,
        UPDATE: (id: string) => `api/customer/UpdateCustomer/${id}`,

        GET_CUSTOMER_COMMISSION: 'api/customer/GetCustomerCommissionCodes'
    },
    AGENCY: {
        GET: 'api/agency/GetAgencies',
        CREATE: 'api/agency/CreateNewAgency',
        GET_BY_ID: (id: string) => `api/agency/GetAgencyById?Id=${id}`,
        UPDATE_BY_ID: (id: string) => `api/agency/UpdateAgency/${id}`,
        GET_PRODUCT_VERSION_BY_IDS: (params: string) => `api/product/GetProductVersionsByAgencyIds?${params}`
    },
    VOUCHER: {
        GET: 'api/voucher/GetVouchers',
        GET_AVAIBLE: 'api/voucher/GetAvailableVouchers', //Tất cả Voucher đang còn tác dụng
        GET_VOUCHER_BY_ID: (id: string) => `api/voucher/GetVoucherById?Id=${id}`, //Lấy chi tiết từng voucher
        GET_PRODUCT_BY_VOUCHER: (id: string) => `api/voucher/GetProductsAppliedToVoucher?VoucherId=${id}`,
        CREATE: 'api/voucher/CreateNewVoucher', //Tạp 1 voucher
        ASSIGN: 'api/voucher/AssignVoucherToProducts',
        UPDATE: (id: string) => `api/voucher/UpdateVoucher/${id}`,
        UPDATE_VOUCHER_PRODUCT: (voucherId: string) => `api/voucher/UpdateVoucherProducts/${voucherId}`,
        GET_VOUCHER_BY_PRODUCT: `api/voucher/GetVouchersProducts`
    },
    WARRANTY: {
        GET_ACTIVE_ORDER: 'api/order/GetActivatedOrders',
        GET_UNACTIVE_ORDER: 'api/order/GetUnactivatedOrders',
        GET_BY_ORDER_CODE: (code: string) => `api/warranty/GetWarrantiesByOrderCode?OrderCode=${code}`,
        GET_HISTORY_BY_ID: (id: string) => `api/warranty/GetWarrantyHistoryById?Id=${id}`,
        GET_ACTIVE: 'api/warranty/GetWarrantyDetails',
        PUT_ACTIVE: (id: string) => `api/order/ActivateOrder/${id}`, //Kích hoạt bảo hành cho 1 đơn hàng
        PUT_UPDATE_ACTIVE_TIME: (id: string) => `api/productOrder/UpdateProductOrderWarrantyTime/${id}`,
        CREATE_REQUEST: 'api/warranty/CreateWarrantyRequest', //Kích hoạt bảo hành cho từng mặt hàng cụ thể dựa trên số IMEI
        //khi khách yêu cầu 1 bảo trì mới thì nó sẽ vào hệ thống và cần được duyệt bởi kỹ thuât
        //DecisionWarrantyDetail; 0-> Approved, 1-> Declined
        PUT_DECIDE_TICKET: (warrantyId: string) => `api/warranty/DecisionWarrantyDetail/${warrantyId}`,
        PUT_ACTION_TICKET: (warrantyId: string) => `api/warranty/ActionWarrantyDetail/${warrantyId}`,

        CREATE_TICKET_STORE: 'api/warranty/CreateWarrantyOrder', //Khi BH/BD sẽ có tình huống phải bổ sung linh kiện nên cần xuất kho
        //Status: null-> all, 0-> Created, 1-> Approved, 2-> Declined, 3-> Exported
        GET_STORE_TICKETS: 'api/warranty/GetWarrantyOrders',
        GET_STORE_TICKET_BY_ID: (id: string) => `api/warranty/GetWarrantyOrderById?Id=${id}`,
        //DecisionWarrantyOrder; 0-> Approved, 1-> Declined; 
        PUT_DECIDE_STORE_TICKET: (warrantyOrderId: string) => `api/warranty/DecisionWarrantyOrder/${warrantyOrderId}`,
        PUT_DECIDE_EXPORT_STORE: (warrantyOrderId: string) => `api/warranty/ExportWarrantyOrder/${warrantyOrderId}`,
        PUT_RENEW_PRODUCT: (warrantyId: string) => `api/warranty/RenewWarrantyOrder/${warrantyId}`,
        PUT_RETURN_PRODUCT: (warrantyOrderId: string) => `api/warranty/ReturnWarranty/${warrantyOrderId}`,


        CREATE_EXTRA: 'api/extraWarranty/CreateNewExtraWarranty',
        UPDATE_EXTRA: (id: string) => `api/extraWarranty/UpdateExtraWarranty/${id}`,
        UPDATE_CUSTOMER: (id: string) => `api/warranty/UpdateWarrantyCustomer?warrantyid=${id}`,

    },
    MAINTENANCE: {
        //all stage, 0-> Request, 1-> Approved, 2 -> Declined
        GET: 'api/maintenance/GetMaintenances',
        GET_BY_ID: (id: string) => `api/maintenance/GetMaintenanceById?Id=${id}`,
        GET_BY_PRODUCT_ORDER_ID: (id: string) => `api/maintenance/GetMaintenancesByProductOrderId?ProductOrderId=${id}`,
        //Khách hàng có thể tao request từ website hoặc gọi điện và nhân viên sẽ tao
        CREATE_HIGH_PRIORITY: 'api/maintenance/CustomerRequestMaintenance',
        CREATE_PLAN_FOR_ID: (id: string) => `api/maintenance/CreateMaintenancePlan/${id}`,
        //Stage To Maintenances; 0-> Approved, 1
        STAGE_MAINTENANCES: 'api/maintenance/StageToMaintenances',
        MAINTENANCE_ACTION: (id: string) => `api/maintenance/ActionToMaintenance/${id}`,
    },
    PAYMENT: {
        GET: 'api/paymentMethod/GetPaymentMethods',
        CREATE: 'api/paymentMethod/CreateNewPaymentMethod',
        UPDATE: (id: string) => `api/paymentMethod/CreateNewPaymentMethod/${id}`,
        DELETE: (id: string) => `api/paymentMethod/RemovePaymentMethod/${id}`,

    },
    IMAGE: {
        UPLOAD_INTERAL: 'api/image/UploadImagesByInternal',
    },
    INVENTORY: {
        GET_BY_ID: (id: string) => `api/inventory/GetInventoryById?Id=${id}`,
        GET: 'api/inventory/GetInventories',
        CREATE: 'api/inventory/CreateInventory',
        CHECK: (id: string) => `api/inventory/CheckInventory/${id}`,
        DECISION: (id: string) => `api/inventory/DecisionInventory/${id}`,
        BALANCE: (id: string) => `api/inventory/BalanceInventory/${id}`,
    },
    REPORT: {
        GET_PRODUCT_ORDER: "api/report/GetProductOrderReports",
        GET_ORDER: "api/report/GetOrderReports",
        GET_RETURN_ORDER: "api/report/GetOrderReturnReports",
        GET_PURCHASE: "api/report/GetPurchaseReports",
        GET_ORDER_DEBT: "api/report/GetOrderDebtInformation",
        GET_PURCHASE_DEBT: "api/report/GetPurchaseDebtInformation",
        GET_BILL: "api/report/GetBillingsReport",
        GET_WARRANTY_ORDERS: "api/report/GetWarrantyOrders",
        GET_MAINTENANCE_REPORTS: "api/report/GetMaintenanceStatusReports",
        GET_SCHEDULE_MAINTENANCE_REPORTS: "api/report/GetScheduleMaintenanceReports",
        GET_WARRANTY_REPORTS: "api/report/GetWarrantyStatusReports",
        GET_INVENTORY_REPORTS: "api/report/GetInventoryReports",
        GET_INVENTORY_NORM_REPORTS: "api/report/GetInventoryNormReports",
        GET_DETAIL_INVENTORY_REPORTS: "api/report/GetDetailInventoryReports"
    },

    PRODUCT_DEFECT: {
        GET: "api/productDefect/GetProductDefects",
        GET_BY_ID: (id: string) => `api/productDefect/GetProductDefectById?Id=${id}`,
        GET_DEFECT_PRODUCT_ORDERS: "api/productDefect/GetProductDefectOrders",
        POST_CREATE_ORDER: "api/productDefect/CreateProductDefectOrder",
        POST_CREATE_ACCESSORY_ORDER: "api/productDefect/CreateProductDefectAccessoryOrder",
        POST_CREATE: "api/productDefect/CreateProductDefect",
        PUT_TECHNICAL_SUPPORT: (id: string) => `api/productDefect/TechnicalProductDefectStatus/${id}`,
        PUT_STORE_DECISION: (id: string) => `api/productDefect/DecisionProductDefectOrder/${id}`,
        PUT_STORE_EXPORT_ACCESSORY: (id: string) => `api/productDefect/ExportProductDefectOrder/${id}`,
        PUT_STORE_RETURN_ACCESSORY: (id: string) => `api/productDefect/ImportProductDefectOrder/${id}`,
        PUT_IMPORT_TO_STORE: (id: string | undefined) => `api/productDefect/ImportProductDefect/${id}`,
        PUT_RETURN_PROVIDER: (id: string) => `api/productDefect/ReturnProductDefect/${id}`,
        PUT_EXPORT_DEFECT_PRODUCT_ORDER: (id: string) => `api/productDefect/ExportProductDefectOrder/${id}`,
        PUT_IMPORT_DEFECT_PRODUCT_ORDER: (id: string) => `api/productDefect/ImportProductDefectOrder/${id}`,
        PUT_ACCESSORY_DECISION: (id: string) => `api/productDefect/DecisionProductDefectAccessoryOrder/${id}`,
        PUT_IMPORT_DEFECT_PRODUCT_ACCESSORY_ORDER: (id: string) => `api/productDefect/ImportProductDefectAccessoryOrder/${id}`
    },

    BILL: {
        GET: 'api/billing/GetBillings',
        GET_BY_ID: (id: string) => `api/billing/GetBillingById?id=${id}`,
        POST_CREATE: 'api/billing/CreateBilling'
    },
    WARRANTY_CENTER: {
        GET: 'api/warrantyCenter/GetWarrantyCenters',
        GET_BY_ID: (id: string) => `api/warrantyCenter/GetWarrantyCenterById?Id=${id}`,
        CREATE: 'api/warrantyCenter/CreateNewWarrantyCenter',
        UPDATE: (id: string) => `api/warrantyCenter/UpdateWarrantyCenter/${id}`,
        DELETE: (id: string) => `api/warrantyCenter/RemoveWarrantyCenter/${id}`
    },

};
