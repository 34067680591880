import { UsergroupAddOutlined } from '@ant-design/icons';
import { Button, Empty, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { CustomerApi } from "../../Api/CustomerApi";
import { CustomerTypeEnum } from '../../common/type-define';
import { CustomerRes } from "../../Model/CustomerModel";

const PartnerViewComponent = () => {

    const [partnerList, setPartnerList] = useState<CustomerRes[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        async function fetchPartnerCustomer() {
            setLoading(true);
            const res = await CustomerApi.getCustomer([CustomerTypeEnum.Loyal]);
            if (res?.status === 200) {
                const result = res.data.items.filter(v => !!v.commissionCode);
                setPartnerList(result);
            }
            setLoading(false);
        }

        fetchPartnerCustomer()
    }, [])


    const columns: ColumnsType<CustomerRes> = [
        {
            title: 'Tên đầy đủ',
            dataIndex: 'fullName',
            key: 'fullName',
            width: '30%',
            sorter: (a: CustomerRes, b: CustomerRes) => a.fullName > b.fullName ? -1 : 1,
            render: (fullName: string) => (
                <Typography.Link>
                    <span>{fullName}</span>
                </Typography.Link>
            )
        },
        {
            title: 'Số điện thoại',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            width: '20%',
            sorter: (a: CustomerRes, b: CustomerRes) => a.phoneNumber > b.phoneNumber ? -1 : 1,
        },
        {
            title: 'Địa chỉ',
            dataIndex: 'address',
            key: 'address',
            width: '20%',
            sorter: (a: CustomerRes, b: CustomerRes) => a.address > b.address ? -1 : 1,
        },
        {
            title: 'Mã hoa hồng',
            dataIndex: 'commissionCode',
            key: 'commissionCode',
            width: '20%',
            sorter: (a: CustomerRes, b: CustomerRes) => a.commissionCode! > b.commissionCode! ? -1 : 1,
            render: (commissionCode: string) => (
                <Typography.Text strong>
                    {commissionCode}
                </Typography.Text>
            )
        },
    ]

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'end', margin: '12px auto' }}>
                <Button
                    type="primary"
                    href={`/admin/customers/partner/add_partner`}
                    icon={<UsergroupAddOutlined />}>
                    Thêm cộng tác viên
                </Button>
            </div>
            <Table
                loading={loading}
                columns={columns}
                dataSource={partnerList}
                pagination={{ pageSize: 20 }}
                locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_DEFAULT} description="Không tìm thấy khách hàng nào." /> }}
                rowKey="id"
                scroll={{ x: 800, y: 'calc(100vh - 200px)' }} />
        </>
    )
}

const PartnerView = React.memo(PartnerViewComponent);
export default PartnerView;