import {
    LeftOutlined,
    SaveOutlined,
    PlusOutlined,
    EditOutlined
} from '@ant-design/icons';
import { Affix, Button, Card, Col, Divider, Form, Input, message, Radio, RadioChangeEvent, Row, Select, Space, Steps, Table, Tooltip, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GetCarriersApi } from '../../Api/CarrierApi';
import { ApprovePurchaseApi, GetPurchaseOrderByIdsApi, StoredPurchaseApi } from "../../Api/PurchaseApi";
import { PaymentOptionEnum, PurchaseStatus } from '../../common/type-define';
import { MathHelper } from '../../Helper/math.helper';
import { CarrierModelResponse } from '../../Model/CarrierModel';
import { PurchaseOrderModelResponse } from "../../Model/PurchaseModel";
import CCombobox from '../Controls/CCombobox';
import CPricePopover from '../Controls/CPricePopover';
import { LeftRightHeaderContent } from '../Controls/LeftRightHeaderContent';
import InputMoney from '../Controls/MoneyInput';
import CarrierModal from '../Sale/CarrierModal';
import PurchasePaymentModal from './PurchasePaymentModal';
const { Text, Link } = Typography;
const { TextArea } = Input;
const { Option } = Select;
const { Step } = Steps;

type ProductVersionRow = {
    productCode: string,
    productName: string,
    versionName: string,
    quantity: number,
    price: number,
    toltalMoney: number,
    productsMoney: number,
    vat: number,
    xnk: number,
    discount: number,
    discountType: number,
}

const PAYMEN_OPTIONS = [
    PaymentOptionEnum.Cod,
    PaymentOptionEnum.Banking,
    PaymentOptionEnum.Cash
];

enum ProcessIndex {
    Created = 0,
    Confirmed = 1,
    Stored = 2,
    Finish = 3
}



function GetStepByStatus(purchaseStatus: number): number {

    if (purchaseStatus === PurchaseStatus.Created ||
        purchaseStatus === PurchaseStatus.Updated)
        return ProcessIndex.Confirmed;
    else if (purchaseStatus === PurchaseStatus.Approved)
        return ProcessIndex.Stored;
    else if (purchaseStatus === PurchaseStatus.Stored ||
        purchaseStatus === PurchaseStatus.Cancelled)
        return ProcessIndex.Finish;
    return ProcessIndex.Finish;
}

function GetButtonTittle(processIndex: number): string {
    let result: string = "";
    switch (processIndex) {
        case ProcessIndex.Confirmed:
            result = "Xác nhận"
            break;
        case ProcessIndex.Stored:
            result = "Nhập kho"
            break;
        case ProcessIndex.Finish:
            result = "Thanh toán"
            break;
        default:
            result = "Hoàn thành";
            break;

    }
    return result;
}

export default function PurchaseProcessView() {
    const { id } = useParams();

    const [form] = Form.useForm<any>();
    const [processIndex, setProcessIndex] = useState<number>(1);
    const [productVersionRows, setProductVersionRows] = useState<ProductVersionRow[]>([]);
    const [totalDiscount, setTotalDiscount] = useState<number>(0);
    const [totalMoney, setTotalMoney] = useState<number>(0);
    const [totalProductMoney, setTotalProductMoney] = useState<number>(0);
    const [totalVat, setTotalVatMoney] = useState<number>(0);
    const [totalXnk, setTotalXnkMoney] = useState<number>(0);
    const [paymentMethod, setPaymentMethod] = useState<number>(0);

    const [isCarrierModal, setIsCarrierModal] = useState<boolean>(false);
    const [carriers, setCarriers] = useState<CarrierModelResponse[]>([])
    const [selectedCarrier, setSelectedCarrier] = useState<CarrierModelResponse>()
    const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false);
    const [paidMoney, setPaidMoney] = useState<number>(0);
    const [isPaidComplete, setPaidCompleted] = useState<boolean>(false);

    function LoadInitData() {
        if (id) {
            GetPurchaseOrderByIdsApi(id, (orderDetail: PurchaseOrderModelResponse) => {

                setProcessIndex(GetStepByStatus(orderDetail.purchaseStatus));

                let orderTotalMoney: number = 0;
                let productTotalMoney: number = 0;
                let orderVatMoney: number = 0;
                let orderXnkMoney: number = 0;
                //Hiển thị danh sách đơn hàng vào trong bảng
                let productRows: ProductVersionRow[] = [];

                orderDetail.purchaseProductVersions.map(proVer => {
                    let vat = proVer.vat * proVer.priceInput * (1 + proVer.importAndExportTax / 100) * proVer.quantities / 100;
                    let xnk = proVer.importAndExportTax * proVer.priceInput * proVer.quantities / 100;

                    const newRow: ProductVersionRow = {
                        productCode: proVer.productVersion?.productCode,
                        productName: proVer.productName,
                        versionName: proVer.productVersion.name,
                        price: proVer.priceInput,
                        quantity: proVer.quantities,
                        productsMoney: (proVer.priceInput - proVer.discount) * proVer.quantities,
                        toltalMoney: (proVer.priceInput - proVer.discount) * proVer.quantities + vat + xnk,
                        vat: proVer.vat,
                        xnk: proVer.importAndExportTax,
                        discount: proVer.discount,
                        discountType: proVer.discountType
                    };
                    productRows.push(newRow);
                    productTotalMoney += newRow.productsMoney;
                    orderTotalMoney += newRow.toltalMoney;
                    orderVatMoney += vat;
                    orderXnkMoney += xnk;
                })

                setProductVersionRows(productRows);
                setTotalMoney(orderTotalMoney - orderDetail.discount);
                setTotalVatMoney(orderVatMoney);
                setTotalXnkMoney(orderXnkMoney);
                setTotalProductMoney(productTotalMoney);
                setTotalDiscount(orderDetail.discount);
                setPaidCompleted(orderDetail.isPaidCompleted);
                form.setFieldsValue({
                    wareHouse: orderDetail.warehouse.name,
                    provider: orderDetail.provider.name,
                    carrierCode: orderDetail.purchaseCarrier?.carrierCode,
                    carrierPrice: orderDetail.purchaseCarrier?.price
                });

                if (orderDetail.purchasePaidHistories.length > 0) {
                    let paidMoney = 0;
                    orderDetail.purchasePaidHistories.map(y => {
                        paidMoney += y.amount;
                    });
                    setPaidMoney(paidMoney);
                }


                GetCarriersApi((res: IListItems<CarrierModelResponse>) => {
                    setCarriers(res.items);
                })
                console.log(orderDetail.purchaseCarrier);
                setSelectedCarrier(carriers.find(x => x.id == orderDetail.purchaseCarrier?.id));
            })
        }
    }
    useEffect(() => {
        //Read order information
        LoadInitData();

    }, []);

    //Hoàn thành 1 quá trình
    const onPurchaseProcessSubmit = () => {
        if (id) {
            if (processIndex === ProcessIndex.Confirmed) {

                let dataPackage = {
                    carrierId: selectedCarrier?.id ?? '',
                    carrierCode: form.getFieldValue("carrierCode"),
                    price: form.getFieldValue("carrierPrice") ?? 0,
                    discount: 0
                }

                ApprovePurchaseApi(id, dataPackage, (res: any) => {
                    message.info("Nhà cung cấp đã xác nhận đơn hàng");
                    setProcessIndex(processIndex + 1);
                })
            }
            else if (processIndex === ProcessIndex.Stored) {
                StoredPurchaseApi(id, (res: any) => {
                    message.info("Đã nhận hàng vào kho");
                    setProcessIndex(processIndex + 1);
                })
            }
            else if (processIndex == ProcessIndex.Finish) {

                setShowPaymentModal(true);

            }
        }

    }

    //Thay đổi hình thức thanh toán
    const onPaymentChange = (e: RadioChangeEvent) => {
        setPaymentMethod(e.target.value);
    }

    //Danh sách sản phẩm
    const ProductVersionCols: ColumnsType<ProductVersionRow> = [
        {
            title: 'Mã sản phẩm',
            dataIndex: 'productCode',
            key: 'productCode',
            render: (productCode, record) => (
                <Tooltip title={record.productName}>
                    <span>{record.productCode}</span>
                </Tooltip>
            )
        },
        {
            title: 'Phiên bản',
            dataIndex: 'versionName',
            key: 'versionName',

        },
        {
            title: 'Số lượng',
            dataIndex: 'quantity',
            key: 'quantity',
            responsive: ['sm'],
        },
        {
            title: 'Giá nhập',
            dataIndex: 'price',
            key: 'price',
            render: (price, record) => (
                <div>
                    <Link>{MathHelper.formatPriceVND(price, true)}</Link>
                    <div style={{ color: "red", textDecoration: "line-through" }}>{MathHelper.formatPriceVND(record.discount)}</div>
                </div>
            )

        },
        {
            title: 'VAT',
            dataIndex: 'vat',
            key: 'Vat',
            render: (vat) => (
                <div>{`${vat}%`}</div>
            )
        },
        {
            title: 'XNK',
            dataIndex: 'xnk',
            key: 'Xnk',
            render: (xnk) => (
                <div>{`${xnk}%`}</div>
            )
        },
        {
            title: 'Thành tiền',
            dataIndex: 'toltalMoney',
            key: 'toltalMoney',
            render: (toltalMoney) => (
                <div>{MathHelper.formatPriceVND(toltalMoney)}</div>
            )
        },

    ];

    //Hiển thị giao diện để thêm đơn vị vận chuyển
    const AddNewCarrierHandle = () => {
        setIsCarrierModal(true);
    };

    // Sau khi tạo ra carrier thì đóng modal và hiển thị trong combobox
    const afterCreateCarrier = (newCarrier: CarrierModelResponse) => {
        let newCarriers = [...carriers];
        newCarriers.push(newCarrier);
        setCarriers(newCarriers);
        setIsCarrierModal(false);
    }

    //Thay đổi carrier
    function onChangeCarrier(value: string) {
        const selectedCarrier = carriers.find(x => x.id == value);
        setSelectedCarrier(selectedCarrier);

    }

    function afterPaidMoney() {
        setShowPaymentModal(false);
        LoadInitData();
    }

    //Bảng thanh toán
    const footerContent = (
        <Row>
            <Col span={12} />
            <Col span={12}>
                <LeftRightHeaderContent
                    leftContent={<Text>{`Tổng tiền (${productVersionRows.length} sản phẩm)`}</Text>}
                    rightContent={MathHelper.formatPriceVND(totalProductMoney)} />
                <LeftRightHeaderContent
                    leftContent={
                        <CPricePopover
                            title={"Chiết khấu"}
                            visible={processIndex === 1 ? undefined : false}
                            total={totalMoney}
                            onChangeCallback={setTotalDiscount}>
                            <Link>Chiết khấu</Link>
                        </CPricePopover>
                    }
                    rightContent={<span style={{ color: 'red', textDecoration: 'line-through' }}>{MathHelper.formatPriceVND(totalDiscount, true)}</span>} />

                <LeftRightHeaderContent
                    leftContent={<Text strong>Thuế VAT</Text>}
                    rightContent={<span>{MathHelper.formatPriceVND(totalVat)}</span>} />

                <LeftRightHeaderContent
                    leftContent={<Text strong>Thuế XNK</Text>}
                    rightContent={<span>{MathHelper.formatPriceVND(totalXnk)}</span>} />

                <LeftRightHeaderContent
                    leftContent={<Text strong>Tiền phải trả</Text>}
                    rightContent={
                        <div>
                            <span>{MathHelper.formatPriceVND(totalMoney)}</span>
                            {paidMoney > 0 ? <div style={{ color: "red", textDecoration: "line-through" }}>{MathHelper.formatPriceVND(paidMoney)}</div> : ""}
                        </div>
                    } />
                <div style={{ margin: '8px auto' }}>
                    {
                        processIndex >= 2 &&
                        <Card size="small" title="Hình thức thanh toán">
                            <Radio.Group
                                defaultValue={0}
                                size="small"
                                onChange={onPaymentChange}>
                                <Space direction="vertical">
                                    {
                                        PAYMEN_OPTIONS.map((v, i) =>
                                            <Radio value={i} checked={paymentMethod === i}>{v}</Radio>
                                        )
                                    }
                                </Space>
                            </Radio.Group>
                        </Card>
                    }
                </div>

            </Col>
        </Row>
    );


    return (
        <div style={{ margin: 10 }}>

            <Affix offsetTop={0}>
                <div style={{ textAlign: "left", backgroundColor: '#F0F2F5' }}>
                    <Row>
                        <Col span={4} >
                            <Button type="text" icon={<LeftOutlined />} href="/admin/purchase_orders" size="large">
                                Đơn nhập hàng
                            </Button>
                        </Col>
                        <Col span={16} >

                            <Steps size="small" current={processIndex} labelPlacement="vertical" style={{ marginTop: 10 }}>
                                <Step title="Đặt hàng" />
                                <Step title="Xác nhận" />
                                <Step title="Nhập kho" />
                                <Step title="Hoàn thành" />
                            </Steps>
                        </Col>
                        <Col span={4} >
                            <Button type="primary" icon={<SaveOutlined />} disabled={isPaidComplete}
                                onClick={onPurchaseProcessSubmit} size="large">
                                {GetButtonTittle(processIndex)}
                            </Button>
                        </Col>
                    </Row>

                </div>
            </Affix>


            <div>
                <Form form={form}
                    layout="vertical">
                    <Card>
                        <Row>
                            <Col span={16}>
                                <Card title={<Space direction="horizontal">
                                    <span> Thông tin sản phẩm</span>
                                    {
                                        (processIndex === ProcessIndex.Confirmed) ? <Link href={`edit/${id}`}><EditOutlined /></Link> : ""
                                    }
                                </Space>}
                                    size="small">

                                    <Table size="small" bordered
                                        dataSource={productVersionRows}
                                        columns={ProductVersionCols}
                                        pagination={false}
                                        footer={() => footerContent} />
                                </Card>

                            </Col>
                            <Col span={8} style={{ paddingLeft: 10 }}>

                                <Card size="small" title="Thông đơn vị vận chuyển" >
                                    <Space direction="vertical">
                                        <CCombobox
                                            placeholder="Chọn đơn vị vận chuyển"
                                            chooseOptions={carriers}
                                            showSearch
                                            optionFilterProp="children"
                                            displayName="name"
                                            value={selectedCarrier?.id}
                                            id="id"
                                            onChange={onChangeCarrier}
                                            extraContent={
                                                <div>
                                                    <Divider style={{ margin: '4px 0' }} />
                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                        <Typography.Link onClick={AddNewCarrierHandle}
                                                            style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}>
                                                            <PlusOutlined />
                                                            <span>Thêm đơn vị vận chuyển</span>
                                                        </Typography.Link>
                                                    </div>
                                                </div>
                                            } />
                                    </Space>

                                    <Form.Item label="Mã vận đơn" name="carrierCode" style={{ marginTop: 0, marginBottom: 3 }}>
                                        <Input placeholder="Nhập mã vận đơn" />
                                    </Form.Item>
                                    <Form.Item label="Tiền vận chuyển" name="carrierPrice" style={{ marginTop: 0, marginBottom: 0 }}>
                                        <InputMoney placeholder="Nhập tiền vận chuyển" />
                                    </Form.Item>
                                </Card>

                                <Card title="Thông tin nhập hàng" size="small">

                                    <Form.Item label="Chi nhánh" name="wareHouse" required>
                                        <Input readOnly={true} />
                                    </Form.Item>

                                    <Form.Item name="provider" label="Thông tin nhà cung cấp" required >
                                        <Input readOnly={true} />
                                    </Form.Item>

                                    <Form.Item label="Ghi chú" name="purchaseNote">
                                        <TextArea rows={4} />
                                    </Form.Item>

                                </Card>
                            </Col>
                        </Row>
                    </Card>
                </Form>
            </div>

            <CarrierModal visible={isCarrierModal}
                title="Thêm đơn vị vận chuyển"
                okModalFnc={afterCreateCarrier}
                onCancel={() => setIsCarrierModal(false)} />

            <PurchasePaymentModal visible={showPaymentModal}
                title="Thanh toán tiền hàng"
                applyHandle={afterPaidMoney}
                onCancel={() => setShowPaymentModal(false)}
                id={id ? id : ""}
                remainMoney={totalMoney - paidMoney}
            />

        </div>
    )
}