import {
    InfoCircleOutlined, LeftOutlined, PlusOutlined, SaveOutlined
} from '@ant-design/icons';
import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';
import { Button, Card, Col, Divider, Form, Input, message, Row, Select, Space, Spin, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { UploadFile } from 'antd/lib/upload/interface';
import React, { useEffect, useState } from 'react';
import { RGBColor } from 'react-color';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { GetColorsApi } from '../../Api/AttributesApi';
import { GetCategories } from '../../Api/CategoryApi';
import { GetFactories } from '../../Api/FactoryApi';
import { GetUnitsApi } from '../../Api/UnitsApi';
import Category from '../../Model/CategoryModel';
import Factory from '../../Model/FactoryModel';
import { ColorVersion, CreateNewProductFnc, NewProductViewModel } from '../../ViewModel/ProductViewModel';
import CCombobox from '../Controls/CCombobox';
import InputMoney, { InputPercent } from '../Controls/MoneyInput';
import CategoryModal from './CategoryModal';
import ColorVersionModal from './ColorVersionModal';
import FactoryModal from './FactoryModal';
import UnitModal from './UnitModal';

const { Option } = Select;



function convertHexToRgb(hex: string): RGBColor {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result && result.length === 4)
        return {
            a: 1,
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        };

    return { a: 1, r: 255, g: 16, b: 20 };
}

function CustomTag(props: any) {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
    };
    return (
        <Tag
            color={value}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{ marginRight: 3 }}
        >
            {label}
        </Tag>
    )
}

function CreateProductView() {

    const [form] = Form.useForm<NewProductViewModel>();

    const [categories, setCategories] = useState<Category[]>([]);
    const [factories, setFactories] = useState<Factory[]>([]);
    const [units, setUnits] = useState<IUnit[]>([]);
    const [systemColors, setSystemColors] = useState<AttributeResponse[]>([]);
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [hasColor, setHasColor] = useState<boolean>(true);

    const [isCreating, setIsCreating] = useState<boolean>(false);

    const [colors, setColors] = useState<ColorVersion[]>([]);

    const [showNewCategoryModal, setShowNewCategoryModal] = useState<boolean>(false);
    const [showNewFactoryModal, setShowNewFactoryModal] = useState<boolean>(false);
    const [showNewUnitModal, setShowNewUnitModal] = useState<boolean>(false);
    const [showColorModal, setShowColorModal] = useState<boolean>(false);


    useEffect(() => {
        GetCategories((data: IListItems<Category>) => {
            setCategories(data.items);
        });
        GetFactories((data: IListItems<Factory>) => {
            setFactories(data.items);
        });

        GetUnitsApi((data: IListItems<IUnit>) => {
            setUnits(data.items);
            if (data.items.length > 0)
                form.setFieldsValue({ unitId: data.items[0].id });
        });


        setHasColor(true);
        GetColorsApi((res: IListItems<AttributeResponse>) => {
            setSystemColors(res.items);
        })

        form.setFieldsValue({ warrantyDays: 15, FreeMaintenanceTimes: 3, renewInDays: 30, vat: 10, importAndExportTax: 10 });

    }, []);

    const navigate = useNavigate();
    //Lấy dữ liệu từ form --> call API để tạo sản phẩm trên server
    const createProductSubmit = () => {
        form.validateFields()
            .then((data: NewProductViewModel) => {
                if (colors.length === 0) {
                    message.info("Sản phẩm chính cần có màu sắc");
                    return;
                }
                setIsCreating(true);
                CreateNewProductFnc(data, fileList, colors, (res: string | null) => {
                    if (res) {
                        message.info(res);
                    } else {
                        message.info("Tạo sản phẩm thành công");
                        navigate("/admin/products");
                    }

                    setIsCreating(false);

                });
            });
    };

    //Thay đổi màu của version
    const onChangeColorStatus = (checked: boolean, event: MouseEvent) => {

        if (checked) {
            let choosedColors: ColorVersion[] = [];
            GetColorsApi((res: IListItems<AttributeResponse>) => {
                setSystemColors(res.items);
            })

        }
        setHasColor(checked);
    }

    //Sau khi bật modal và tạo xong thì thêm vào danh sách
    const AfterCreateUnit = (newUnit: IUnit) => {
        if (newUnit) {
            let newUnits: IUnit[] = [...units];
            newUnits.push(newUnit);
            setUnits(newUnits);
            form.setFieldsValue({ unitId: newUnit.id });
            setShowNewUnitModal(false);
        }
    }

    //Sẽ hiển thị một hộp thoại cho phép thêm màu
    const AddNewColorHandle = () => {
        setShowColorModal(true);
    }
    //Sau khi bật modal và tạo xong thì thêm vào danh sách
    const AfterCreateColor = (attribute: AttributeResponse) => {
        if (attribute) {
            let newsystemColor: AttributeResponse[] = [...systemColors];
            newsystemColor.push(attribute);
            setSystemColors(newsystemColor);

            setShowColorModal(false);
        }
    }


    const AfterCreateFactory = (res: Factory) => {
        let newfactories: Factory[] = [...factories];
        newfactories.push(res);

        setFactories(newfactories);
        form.setFieldsValue({ factoryId: res.id });

        setShowNewFactoryModal(false);
    }

    // Gọi API để tạo nhóm sản phẩm từ dữ liệu trên form
    const AfterCreateCategory = (res: Category) => {
        let newCategories: Category[] = [...categories];
        newCategories.push(res);

        setCategories(newCategories);
        form.setFieldsValue({ categoryId: res.id });

        setShowNewCategoryModal(false);
    }

    const handleRemoveColorVersion = (prod: ColorVersion) => {
        return () => {
            const newVersions = [...colors];
            const findIndex = newVersions.findIndex(cart => cart.name === prod.name);
            if (findIndex !== -1) {
                newVersions.splice(findIndex, 1);
                setColors(newVersions);
            }

        }
    };


    //Thay đổi tên của từng version
    const changeVersionName = (e: React.FormEvent<HTMLInputElement>) => {

        let newVersions = [...colors];
        const oldValue = e.currentTarget.defaultValue;
        const newValue = e.currentTarget.value;


        let nIndex = newVersions.findIndex(x => x.name === oldValue);
        const item = newVersions[nIndex];

        newVersions.splice(nIndex, 1, { name: newValue, color: item.color, colorId: item.colorId });
        setColors(newVersions);

    }

    function changeVersionColor(value: string[]) {
        let newVersions: ColorVersion[] = [];
        value.map(x => {
            let index = systemColors.findIndex(y => y.id === x);
            let attrib = systemColors[index];
            index = colors.findIndex(z => z.colorId === x);
            let color = colors[index];
            if (color) {
                newVersions.push({
                    name: color.name, color: color.color, colorId: color.colorId
                })
            }
            else {
                newVersions.push({
                    name: attrib.name, color: attrib.code, colorId: attrib.id
                })
            }
        })

        setColors(newVersions);
    }

    const colorColumns: ColumnsType<ColorVersion> = [
        {
            title: 'Tên phiên bản',
            dataIndex: 'name',
            key: 'name',
            width: 150,
            align: "left",
            render: (name) => (
                <Input defaultValue={name} onChange={changeVersionName} />
            )
        },
        {
            title: 'Màu sắc',
            dataIndex: 'color',
            key: 'color',
            align: "left",
            render: (color) => (
                <div style={{ width: 20, height: 20, borderRadius: 5, backgroundColor: `${color}` }} />

            )
        },
        {
            title: '',
            key: 'action',
            render: (record) => (
                <Space size="middle" align="center" >
                    <DeleteOutlined style={{ color: 'red', marginTop: 5 }} onClick={handleRemoveColorVersion(record)} />
                </Space>
            ),
        },
    ];


    return (
        <div style={{ margin: 10 }}>
            <div style={{ display: "flex", height: 50, top: 25 }}>
                <Link to="/admin/products">
                    <LeftOutlined /> Quay lại danh sách sản phẩm
                </Link>
                <div style={{ flexGrow: 1, textAlign: "end" }}>
                    <Button type="primary" icon={<SaveOutlined />}
                        onClick={createProductSubmit} size="large">
                        Tạo sản phẩm
                    </Button>
                </div>
            </div>

            <Spin spinning={isCreating} tip="Đang tạo sản phẩm">
                <Form form={form}
                    layout="vertical">
                    <Row >
                        <Col span={16}>
                            <Card title="Thông tin sản phẩm" size="small">
                                <Form.Item name="productName" label="Tên sản phẩm" required tooltip="Tên sản phẩm không bao gồm các giá trị thuộc tính như màu sắc, chất liệu">
                                    <Input placeholder="Nhập tên sản phẩm" />
                                </Form.Item>
                                <Row>
                                    <Col span={11}>
                                        <Form.Item name="productCode" label="Mã sản phẩm" required >
                                            <Input placeholder="Nhập mã sản phẩm" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={1} />
                                    <Col span={12} >
                                        <Form.Item name="barCode" label="Mã Barcode" >
                                            <Input placeholder="Nhập mã barcode trên sản phẩm" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>

                                    <Col span={11} >
                                        <Form.Item label="Chọn nhóm sản phẩm" name="categoryId"
                                            tooltip={{
                                                title: 'Miêu tả thông tin chi tiết sản phẩm',
                                                icon: <InfoCircleOutlined />
                                            }}>

                                            <CCombobox chooseOptions={categories} displayName="name" id="id" placeholder="Chọn nhóm sản phẩm" extraContent={
                                                <div>
                                                    <Divider style={{ margin: '4px 0' }} />
                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                        <a onClick={() => setShowNewCategoryModal(true)}
                                                            style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}>
                                                            <PlusOutlined />
                                                            <span>Thêm nhóm sản phẩm</span>
                                                        </a>
                                                    </div>
                                                </div>

                                            } />
                                        </Form.Item>


                                    </Col>


                                    <Col span={1} />
                                    <Col span={12} >
                                        <Form.Item name="unitId" label="Đơn vị sản phẩm" >
                                            <CCombobox chooseOptions={units} displayName="name" id="id" placeholder="Chọn đơn vị" extraContent={
                                                <div>
                                                    <Divider style={{ margin: '4px 0' }} />
                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                        <a onClick={() => setShowNewUnitModal(true)}
                                                            style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}>
                                                            <PlusOutlined />
                                                            <span>Thêm đơn vị</span>
                                                        </a>
                                                    </div>
                                                </div>

                                            } />
                                        </Form.Item>
                                    </Col>

                                </Row>
                                {/* Giá nhập - giá bán */}
                                <Row>
                                    <Col span={6} style={{ paddingRight: 5 }}>
                                        <Form.Item label="Giá nhập" name="inputPrice">
                                            <InputMoney />
                                        </Form.Item>
                                    </Col>

                                    <Col span={6} style={{ paddingLeft: 5, paddingRight: 30 }}>
                                        <Form.Item label="Giá bán" name="salePrice">
                                            <InputMoney />
                                        </Form.Item>
                                    </Col>

                                    <Col span={6} >
                                        <Form.Item label="Thuế GTGT" name="vat">
                                            <InputPercent />
                                        </Form.Item>
                                    </Col>

                                    <Col span={6} style={{ paddingLeft: 5 }}>
                                        <Form.Item label="Thuế XNK" name="importAndExportTax">
                                            <InputPercent />
                                        </Form.Item>
                                    </Col>

                                </Row>

                                <Row>
                                    <Col span={6} style={{ paddingRight: 5 }}>
                                        <Form.Item label="Giá Khuyến mại" name="SaleOffPrice">
                                            <InputMoney />
                                        </Form.Item>
                                    </Col>
                                </Row>


                            </Card>
                        </Col>
                        <Col span={8} style={{ paddingLeft: 10 }}>
                            <Card title="Thông tin khác" size="small">

                                <Form.Item label="Chọn nhà sản xuất" name="factoryId">

                                    <CCombobox chooseOptions={factories} displayName="name" id="id" placeholder="Chọn nhà sản xuất" extraContent={
                                        <div>
                                            <Divider style={{ margin: '4px 0' }} />
                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                <a onClick={() => setShowNewFactoryModal(true)}
                                                    style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}>
                                                    <PlusOutlined />
                                                    <span>Thêm nhà sản xuất</span>
                                                </a>
                                            </div>
                                        </div>

                                    } />
                                </Form.Item>

                                <Form.Item name="warrantyDays" label="Thời gian bảo hành(tháng)" >
                                    <Input placeholder="Nhập số tháng bảo hành" />
                                </Form.Item>

                                <Form.Item name="renewInDays" label="Thời gian đổi mới(ngày)" >
                                    <Input placeholder="Nhập số ngày" />
                                </Form.Item>

                                <Form.Item name="FreeMaintenanceTimes" label="Số lần bảo trì miễn phí" >
                                    <Input placeholder="Nhập số lần bảo trì" />
                                </Form.Item>

                                {/* <Form.Item label={<LabelColor checked={hasColor} onChange={onChangeColorStatus} />} requiredMark={false}>     */}
                                <Form.Item>
                                    {hasColor ? (
                                        <div>
                                            <Table columns={colorColumns}
                                                dataSource={colors} bordered />
                                            <Select mode='multiple'
                                                tagRender={CustomTag}
                                                onChange={changeVersionColor}
                                                dropdownRender={menu => (
                                                    <div>
                                                        {menu}
                                                        {<div>
                                                            <Divider style={{ margin: '4px 0' }} />
                                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                <a onClick={AddNewColorHandle}
                                                                    style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}>
                                                                    <PlusOutlined />
                                                                    <span>Thêm định nghĩa màu</span>
                                                                </a>
                                                            </div>
                                                        </div>}
                                                    </div>
                                                )}>
                                                {systemColors.map(item => (
                                                    <Option value={item.id} key={item.id} > {item.name}</Option>
                                                ))}
                                            </Select>
                                        </div>) : ('không phân loại theo màu')}
                                </Form.Item>

                            </Card>
                        </Col>
                    </Row>
                </Form>
            </Spin>


            <CategoryModal title="Thêm nhóm sản phẩm"
                visible={showNewCategoryModal}
                onCancel={() => setShowNewCategoryModal(false)}
                okModalFnc={AfterCreateCategory}
            />

            <FactoryModal visible={showNewFactoryModal}
                onCancel={() => setShowNewFactoryModal(false)}
                okModalFnc={AfterCreateFactory}
                title="Thêm nhà sản xuât"

            />

            <UnitModal visible={showNewUnitModal}
                title="Tạo đơn vị sản phẩm"
                okModalFnc={AfterCreateUnit}
                onCancel={() => setShowNewUnitModal(false)} />

            <ColorVersionModal visible={showColorModal}
                title="Tạo màu phiên bản"
                okModalFnc={AfterCreateColor}
                onCancel={() => setShowColorModal(false)} />
        </div >
    )
}

export default CreateProductView;