import {
    DownOutlined,
    EditOutlined, EnvironmentOutlined,
    LeftOutlined, PhoneOutlined, PlusOutlined, PrinterOutlined, UserOutlined
} from '@ant-design/icons';
import { Affix, Button, Card, Col, Divider, Dropdown, Form, Input, Menu, message, Popover, Radio, RadioChangeEvent, Row, Select, Space, Steps, Table, Tag, Tooltip, Typography } from 'antd';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import { format } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { GetCarriersApi } from '../../Api/CarrierApi';
import { ApproveOrderFromStaffApi, DeliveryFromStaffApi, getOrderByIdApi, PackageOrderFromStaffApi } from '../../Api/OrderApi';
import { GetWareHousesApi } from '../../Api/WarehouseApi';
import { DiscountType, ICustomerInfo, IStepAction, OrderHistoryStatus, OrderStatus } from '../../common/type-define';
import { isAgencyAccount } from '../../Helper/CommonHelper';
import { MathHelper } from '../../Helper/math.helper';
import { usePrint } from '../../hooks/usePrint';
import { CarrierModelResponse } from '../../Model/CarrierModel';
import { PAYMEN_OPTIONS } from '../../Model/CommonDefine';
import { ApproveOrderData, IOrderRespone, OrderHistory, PackageOrderData } from '../../Model/OrderModel';
import { fetchVoucherProducts } from '../../redux/slices/voucher.slice';
import { ProcessOrderProductViewModel } from '../../ViewModel/OrderViewModel';
import CCombobox from '../Controls/CCombobox';
import CPricePopover from '../Controls/CPricePopover';
import { LeftRightHeaderContent } from '../Controls/LeftRightHeaderContent';
import InputMoney, { InputPercent } from '../Controls/MoneyInput';
import CarrierModal from './CarrierModal';
import PaymentModal from './PaymentModal';
import VoucherSelectionModal from './VoucherSelectionModal';
const { Text, Link } = Typography;

const { Step } = Steps;
const { Option } = Select;

enum ProcessIndex {
    Created = 0,
    Confirmed = 1,
    Stored = 2,
    Finish = 3
}

const STEP_DEFINE: IStepAction[] = [
    {
        title: "Đặt hàng",
        btnAction: "Duyệt",
        status: -1
    },
    {
        title: "Duyệt đơn",
        btnAction: "Duyệt",
        status: OrderStatus.Pending
    },
    {
        title: "Đóng gói",
        btnAction: "Đóng gói",
        status: OrderStatus.Processing
    },
    {
        title: "Giao hàng",
        btnAction: "Xuất kho",
        status: OrderStatus.Packaging
    },
    {
        title: "Hoàn thành",
        btnAction: "Thanh toán",
        status: OrderStatus.ToDeliver
    },
]

function ProcessOrderView() {
    const dispatch = useDispatch();
    const { id } = useParams();

    const [form] = Form.useForm<ProcessOrderProductViewModel>();
    const [orderProducts, setOrderProducts] = useState<ProcessOrderProductViewModel[]>([]);
    const [orderCode, setOrderCode] = useState<string>('');
    const [stepIndex, setstepIndex] = useState<number>(1);

    const [customerInfo, setCustomerInfo] = useState<ICustomerInfo | null>(null);
    const [wareHouseList, setwareHouseList] = useState<WareHouseResponse[]>([]);

    const [wareHouse, setWareHouse] = useState<WareHouseResponse | undefined>(undefined);
    const [agencyId, setAgencyId] = useState<string>('');

    const [carriers, setCarriers] = useState<CarrierModelResponse[]>([])
    const [selectedCarrier, setSelectedCarrier] = useState<CarrierModelResponse>()

    const [orderHistories, setOrderHistories] = useState<OrderHistory[]>([]);

    const [paymentMethod, setPaymentMethod] = useState<number>(0);
    const [paidMoney, setPaidMoney] = useState<number>(0);
    const [saleChanel, setSaleChanel] = useState<string>("");

    const [loading, setLoading] = useState<boolean>(true);
    const [discountType, setDiscountType] = useState<DiscountType>(DiscountType.Value);
    const [isCarrierModal, setIsCarrierModal] = useState<boolean>(false);
    const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false);

    const printRef = useRef(null);
    const {
        setPrintContent,
        callbackHandlePrint,
        setExtraInfo,
    } = usePrint();
    useEffect(() => {
        setExtraInfo(customerInfo)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerInfo])

    useEffect(() => {
        setPrintContent(printRef.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [printRef.current])


    function LoadInitData() {
        if (id) { // VA not sure
            getOrderByIdApi(id, (orderDetail: IOrderRespone) => {
                console.log(orderDetail);
                if (orderDetail.saleChannel)
                    setSaleChanel(orderDetail.saleChannel.name);

                if (orderDetail.warehouse)
                    setWareHouse(orderDetail.warehouse);

                setOrderCode(orderDetail.orderCode);
                if (orderDetail.customer) {
                    const { fullName, phoneNumber, address } = orderDetail.customer;
                    setCustomerInfo({ fullName, phoneNumber, address })
                    if (orderDetail.fromAgency) setAgencyId(orderDetail.fromAgency.id);
                } else if (orderDetail.toAgency) {
                    const { name, phoneNumber, address } = orderDetail.toAgency;
                    setCustomerInfo({ fullName: name, phoneNumber, address });
                }

                const sortedHistory = orderDetail.orderHistories.sort((a, b) =>
                    new Date(a.createdTime).getTime() - new Date(b.createdTime).getTime()
                );
                //console.log(orderDetail.productOrders);
                setOrderHistories(sortedHistory);
                const initOrderProducts = orderDetail.productOrders.map(prod => ({
                    code: prod.productCode,
                    name: prod.productName,
                    versionName: prod.productVersionName,
                    salePrice: prod.salePrice,
                    productId: prod.id,
                    quantity: 1,
                    productVerId: prod.productVersionId,
                    reducePrice: prod.discount,
                    imei: prod.imei,
                    totalMoney: prod.salePrice - prod.discount,
                    isAccessory: prod.productType === 1,
                    discountType: prod.discountType,
                    discountPercent: prod.discount,
                    vat: prod.vat,
                    warantyName: prod.extraWarrantyName,
                    extendWarrantyPrice: prod.extendPrice
                }));

                setShippingFee(orderDetail.shippingDetail.shippingFee);
                setTotalDiscount(orderDetail.discount ?? 0);
                setDiscountType(orderDetail.discountType == 0 ? DiscountType.Value : DiscountType.Percent);

                setOrderProducts(initOrderProducts);
                let stepIndex = STEP_DEFINE.findIndex(x => x.status === orderDetail.orderStatus);
                if (stepIndex === -1 && orderDetail.orderStatus === OrderStatus.Updated)
                    stepIndex = 1;
                setstepIndex(stepIndex);

                if (stepIndex === 4) {
                    let paidMoney = 0;
                    orderDetail.orderPaidHistories.map(y => {
                        paidMoney += y.amount;
                    });
                    setPaidMoney(paidMoney);
                }

                if (stepIndex > 4) {
                    setPaymentMethod(orderDetail.paymentType);
                }


                //Load ware house
                if (isAgencyAccount() === false && (agencyId === "" || agencyId === undefined)) {
                    GetWareHousesApi((res: IListItems<WareHouseResponse>) => {
                        setwareHouseList(res.items);
                    });
                }

                setLoading(false);
            })
        }
    }
    useEffect(() => {
        //Read order information
        LoadInitData();

    }, []);

    //Áp dụng use efect cho đơn vị vận chuyển
    useEffect(() => {

        if (stepIndex == 2) {
            GetCarriersApi((res: IListItems<CarrierModelResponse>) => {
                setCarriers(res.items);
            })
        }
    }, [stepIndex])

    // Validate dữ liệu và có message trước khi gọi API
    function validateBefore(data: ProcessOrderProductViewModel): boolean {

        return true;
    }

    // Tiến hành call api để xử lý đơn hàng
    const processSubmit = () => {
        let newState: number = stepIndex;
        //Nếu không phải đơn hàng của đại lý thì cần phải có kho
        if ((agencyId == null || agencyId == "") && (wareHouse == undefined)) {
            message.warning("Chọn kho để xuất đơn hàng");
            return;
        }

        if (id) {
            form.validateFields()
                .then((data: ProcessOrderProductViewModel) => {
                    //message.info(JSON.stringify(data));
                    if (stepIndex === 1) {

                        const orderDetails: any[] = [];
                        for (var i = 0; i < orderProducts.length; i++) {
                            const productOrder = orderProducts[i];

                            if (productOrder.isAccessory)
                                orderDetails.push({
                                    productVersionId: productOrder.productVerId,
                                    discount: productOrder.reducePrice,
                                    discountType: productOrder.discountType ?? 0
                                });
                            else
                                orderDetails.push({
                                    productVersionId: productOrder.productVerId,
                                    discount: productOrder.reducePrice,
                                    discountType: productOrder.discountType ?? 0
                                });
                        }


                        let dataApprove: ApproveOrderData = {
                            warehouseId: wareHouse?.id,
                            discount: totalDiscount,
                            shippingFee: shippingFee,
                            shippingDiscount: 0,
                            discountType: discountType === DiscountType.Value ? 0 : 1,
                            productOrderDetails: orderDetails,
                            totalAmount: customerPayMoney()
                        }

                        if (agencyId) {
                            dataApprove = {
                                discount: totalDiscount,
                                shippingFee: shippingFee,
                                shippingDiscount: 0,
                                discountType: discountType === DiscountType.Value ? 0 : 1,
                                productOrderDetails: orderDetails,
                                totalAmount: customerPayMoney()
                            }
                        }
                        //message.info(JSON.stringify(dataApprove));
                        ApproveOrderFromStaffApi(id, dataApprove, (res: any) => {
                            setstepIndex(newState + 1);
                            message.info("Đơn hàng được duyệt");
                        })
                    }
                    else if (stepIndex === 2) {
                        const orderDetails: any[] = [];


                        for (var i = 0; i < orderProducts.length; i++) {
                            const order = orderProducts[i];
                            if (order.isAccessory == false && (order.imei == null || order.imei.length == 0)) {
                                message.warning("Số IMEI không được bỏ sót");
                                return;
                            }

                            if (order.isAccessory)
                                orderDetails.push({
                                    productVersionId: order.productVerId,
                                });
                            else
                                orderDetails.push({
                                    productVersionId: order.productVerId,
                                    imei: order.imei
                                });
                        }

                        var valueArr = orderProducts.map(function (item) { return item.imei });
                        var isDuplicate = valueArr.some(function (item, idx) {
                            return valueArr.indexOf(item) != idx
                        });

                        if (isDuplicate) {
                            message.warning("Số IMEI bị trùng lặp");
                            return;
                        }


                        if (data.carrierCode) {
                            let dataPackage: PackageOrderData = {
                                productOrderDetails: orderDetails,
                                carrierId: selectedCarrier?.id ?? '',
                                carrierCode: data.carrierCode,
                                price: data.carrierPrice ?? 0,
                                discount: 0
                            }

                            PackageOrderFromStaffApi(id, dataPackage, (res: any) => {
                                setstepIndex(newState + 1);
                                message.info("Đóng gói xong");
                            })
                        } else {
                            message.error("Mã vận đơn bị thiếu");
                        }

                    }
                    else if (stepIndex === 3) {
                        DeliveryFromStaffApi(id, (res: any) => {
                            setstepIndex(newState + 1);
                            message.info("Hàng đã xuất kho");
                        })
                    }
                    else if (stepIndex === 4) {
                        if (paymentMethod == -1) {
                            setPaymentMethod(0);
                        }

                        setShowPaymentModal(true);
                        // let paymentReq: paymentRequest = {
                        //     amount: totalMoney,
                        //     isPaidCompleted: true,
                        //     paymentType: paymentMethod
                        // }

                        // CompleteOrderFromStaffApi(id, paymentReq, (res: any) => {
                        //     setstepIndex(newState + 1);
                        //     message.info("Đơn hàng hoàn thành");
                        // });
                    }
                })
        }
    }

    //Thay đổi số imei của từng sản phẩm
    const changeImei = (rowIndex: number) => {
        return () => {

            let newImei = form.getFieldValue(rowIndex);
            if (rowIndex !== -1 && newImei != null && orderProducts.length > 0) {
                let newOrderProducts = [...orderProducts];
                const changeProduct = newOrderProducts[rowIndex];

                const newProduct: ProcessOrderProductViewModel = ({ ...changeProduct, imei: newImei });
                newOrderProducts[rowIndex] = newProduct;
                setOrderProducts(newOrderProducts);
            }
            else {
                message.info("not found");
            }
        }
    }


    //Giảm giá sản phẩm
    const onChangeSaleOff = (rowIndex: number, discountType: DiscountType) => {
        let inputSaleOff = form.getFieldValue(`Sale${rowIndex}`);

        let saleOff = Number.parseInt(inputSaleOff);
        if (rowIndex !== -1) {
            let newProducts: ProcessOrderProductViewModel[] = [...orderProducts];
            const prodChanged = newProducts[rowIndex];
            //tim tất cả các sản phẩm cùng version để thay đổi giá
            newProducts.map(x => {
                if (x.productVerId === prodChanged.productVerId) {
                    const reducePrice = discountType === DiscountType.Value ? saleOff : x.salePrice * saleOff / 100;
                    const productOrderVersion: ProcessOrderProductViewModel = {
                        ...x,
                        reducePrice,
                        discountType: discountType === DiscountType.Value ? 0 : 1,
                        totalMoney: (x.salePrice - reducePrice) * x.quantity,
                    };
                    newProducts[newProducts.indexOf(x)] = productOrderVersion;
                }
            })
            setOrderProducts(newProducts);
        }
    };

    const handleDiscountType = (type: DiscountType, rowIndex: number) => {
        setDiscountType(type);
        onChangeSaleOff(rowIndex, type);
    }

    const selectBefore = (rowIndex: number) => {
        return (
            <Select
                style={{ minWidth: '80px' }}
                dropdownMatchSelectWidth
                defaultValue={DiscountType.Value}
                onChange={(type) => handleDiscountType(type, rowIndex)}>
                <Option value={DiscountType.Value}>
                    <span>Giá trị</span>
                </Option>
                <Option value={DiscountType.Percent}>
                    <span>%</span>
                </Option>
            </Select>
        )
    };

    //Định nghia columns cho table
    function getColumns(): ColumnsType<ProcessOrderProductViewModel> {

        const nameCol: ColumnType<ProcessOrderProductViewModel> = {
            title: 'Mã sản phẩm',
            dataIndex: 'name',
            key: 'name',
            render: (name, record) => (
                <Tooltip title={record.name}>
                    <span>{record.code}</span>
                </Tooltip>
            )
        };

        const versionCol = {
            title: 'Phiên bản',
            dataIndex: 'versionName',
            key: 'versionName',

        };

        const warrantyCol: ColumnType<ProcessOrderProductViewModel> = {
            title: 'Gói BH',
            dataIndex: 'warantyName',
            key: 'warantyName',
            render: (warantyName) => (
                <span>{warantyName}</span>
            )
        };

        const vatCol: ColumnType<ProcessOrderProductViewModel> = {
            title: 'VAT',
            dataIndex: 'vat',
            key: 'vat',
            render: (vat) => (
                `${vat}%`
            )
        };

        const IMEICol: ColumnType<ProcessOrderProductViewModel> = {
            title: 'IMEI',
            dataIndex: 'imei',
            key: 'imei',
            render: (imei, record, index) => (
                record.isAccessory === false ?
                    (stepIndex === 2 ?
                        <Form.Item name={index} style={{ marginBottom: 0 }}>
                            <Input size="small"
                                defaultValue={imei}
                                onChange={changeImei(index)} />
                        </Form.Item>
                        :
                        <div>{imei}</div>) : (<div></div>)
            )
        };

        const priceCol: ColumnType<ProcessOrderProductViewModel> = {
            title: 'Đơn giá',
            dataIndex: 'salePrice',
            key: 'salePrice',
            render: (salePrice, record, index) => (
                <Popover
                    placement="bottom"
                    title="Chiết khấu"
                    destroyTooltipOnHide
                    content={
                        <Form.Item name={`Sale${index}`} style={{ marginBottom: 0 }}>
                            {
                                discountType === DiscountType.Value ?
                                    <InputMoney
                                        addonBefore={selectBefore(index)}
                                        onChange={() => onChangeSaleOff(index, discountType)}
                                    />
                                    :
                                    <InputPercent
                                        addonBefore={selectBefore(index)}
                                        onChange={() => onChangeSaleOff(index, discountType)}
                                    />
                            }

                        </Form.Item>
                    }
                    visible={stepIndex === 1 ? undefined : false}
                    trigger="click">
                    <Link>{MathHelper.formatPriceVND(salePrice)}</Link>
                    <div style={{ color: "red", textDecoration: "line-through" }}>{MathHelper.formatPriceVND(record.reducePrice)}</div>
                </Popover>
            )
        };

        const totalMoneyCol: ColumnType<ProcessOrderProductViewModel> = {
            title: 'Thành tiền',
            dataIndex: 'salePrice',
            key: 'salePrice',
            render: (salePrice, record) => (
                <div>{MathHelper.formatPriceVND((salePrice - record.reducePrice + record.extendWarrantyPrice) * record.quantity)}</div>
            )
        };

        //Tại bước duyệt thì không cần imei
        if (stepIndex === 1) {
            return [nameCol, versionCol, warrantyCol, priceCol, vatCol, totalMoneyCol];
        }

        //Sang bước đóng gói là cần có imei
        return [nameCol, versionCol, IMEICol, warrantyCol, priceCol, vatCol, totalMoneyCol];

    }


    const [totalDiscount, setTotalDiscount] = useState<number>(0);
    const [voucherDiscount, setVoucherDiscount] = useState<number>(0);
    const [shippingFee, setShippingFee] = useState<number>(0);
    const [totalMoney, setTotalMoney] = useState<number>(0);

    useEffect(() => {
        const total = orderProducts.reduce((p, c) => p + c.salePrice - c.reducePrice + c.extendWarrantyPrice, 0);
        setTotalMoney(total)
    }, [orderProducts])


    const totalTax = (): number => {
        let total: number = 0;
        orderProducts.map(x => {
            total += ((x.salePrice) * x.quantity * x.vat / 100);
        })
        return total;
    }

    const customerPayMoney = (): number => {
        return totalMoney + totalTax() + shippingFee - totalDiscount - voucherDiscount;
    }

    //Thay đổi hình thức thanh toán
    const onPaymentChange = (e: RadioChangeEvent) => {
        setPaymentMethod(e.target.value);
    }

    const handleOnPrint = () => {
        if (!wareHouse) {
            message.warn("Bạn hãy chọn kho.");
            return;
        }
        callbackHandlePrint && callbackHandlePrint();
    }

    //Trạng thái lich sử của đơn hàng
    function getHistoryStatus(status: number): JSX.Element {
        if (status === OrderHistoryStatus.created) {
            return (
                <Tag color="green">
                    Tạo đơn
                </Tag>
            )
        } else if (status === OrderHistoryStatus.Approve) {
            return (
                <Tag color="green">
                    Duyệt đơn
                </Tag>
            )
        } else if (status === OrderHistoryStatus.Updated) {
            return (
                <Tag color="warning">
                    Sửa đơn
                </Tag>
            )
        }
        else if (status === OrderHistoryStatus.Packaging) {
            return (
                <Tag color="yellow">
                    Đóng gói
                </Tag>
            )
        } else if (status === OrderHistoryStatus.ToDeliver) {
            return (
                <Tag color="green">
                    Giao hàng
                </Tag>
            )
        }
        else if (status === OrderHistoryStatus.Completed) {
            return (
                <Tag color="green">
                    Thanh toán
                </Tag>
            )
        }
        else if (status === OrderHistoryStatus.Cancelled) {
            return (
                <Tag color="red">
                    Hủy đơn
                </Tag>
            )
        }

        return (<div>{status}</div>);
    }

    //Hiển thị history
    function getHistoryItem(history: OrderHistory): JSX.Element {
        if (history) {
            return (<Space direction="horizontal">
                <Tag color="green">
                    {history.actorName}
                </Tag>
                {getHistoryStatus(history.statusCode)}
            </Space>)
        }
        return (<div></div>);
    }

    const openInputVoucher = () => {
        const productIds = orderProducts.map(v => v.productId)
        dispatch(fetchVoucherProducts(productIds))
    }

    // Sau khi tạo ra carrier thì đóng modal và hiển thị trong combobox
    const afterCreateCarrier = (newCarrier: CarrierModelResponse) => {
        let newCarriers = [...carriers];
        newCarriers.push(newCarrier);
        setCarriers(newCarriers);
        setIsCarrierModal(false);
    }

    //Thay đổi carrier
    function onChangeCarrier(value: string) {
        const selectedCarrier = carriers.find(x => x.id == value);
        setSelectedCarrier(selectedCarrier);

    }

    //Bảng thanh toán
    const footerContent = (
        <Row>
            <Col span={12} />
            <Col span={12}>
                <LeftRightHeaderContent
                    leftContent={<Text>{`Tổng tiền (${orderProducts.length} sản phẩm)`}</Text>}
                    rightContent={MathHelper.formatPriceVND(totalMoney)} />
                <LeftRightHeaderContent
                    leftContent={
                        <CPricePopover
                            title={"Chiết khấu phân bổ"}
                            visible={stepIndex === 1 ? undefined : false}
                            total={totalMoney}
                            onChangeCallback={setTotalDiscount}>
                            <Link>Chiết khấu phân bổ</Link>
                        </CPricePopover>
                    }
                    rightContent={<span style={{ color: 'red', textDecoration: 'line-through' }}>{MathHelper.formatPriceVND(totalDiscount, true)}</span>} />
                <LeftRightHeaderContent
                    leftContent={
                        <>
                            <Link onClick={openInputVoucher}>{`Mã giảm giá`}</Link>
                            <VoucherSelectionModal setVoucherDiscount={setVoucherDiscount} />
                        </>
                    }
                    rightContent={<span style={{ color: 'red', textDecoration: 'line-through' }}>{MathHelper.formatPriceVND(voucherDiscount, true)}</span>} />
                <LeftRightHeaderContent leftContent={<div style={{ color: "#007BFF" }}>Thuế GTGT</div>} rightContent={MathHelper.formatPriceVND(totalTax(), true)} />
                <LeftRightHeaderContent
                    leftContent={
                        <CPricePopover
                            title={"Phí giao hàng"}
                            visible={stepIndex === 1 ? undefined : false}
                            total={totalMoney}
                            onChangeCallback={setShippingFee}>
                            <Link>Phí giao hàng</Link>
                        </CPricePopover>
                    }
                    rightContent={<span>{MathHelper.formatPriceVND(shippingFee, true)}</span>} />
                <LeftRightHeaderContent
                    leftContent={<Text strong>Khách phải trả</Text>}
                    rightContent={<div>
                        <span>{MathHelper.formatPriceVND(customerPayMoney())}</span>
                        <div style={{ color: "red", textDecoration: "line-through" }}>{MathHelper.formatPriceVND(paidMoney)}</div>
                    </div>} />

                <div style={{ margin: '8px auto' }}>
                    {
                        stepIndex >= 4 &&
                        <Card size="small" title="Hình thức thanh toán">
                            <Radio.Group
                                defaultValue={0}
                                size="small"
                                onChange={onPaymentChange}>
                                <Space direction="vertical">
                                    {
                                        PAYMEN_OPTIONS.map((v, i) =>
                                            <Radio
                                                key={i}
                                                value={i}
                                                checked={paymentMethod === i}>{v}</Radio>
                                        )
                                    }
                                </Space>
                            </Radio.Group>
                        </Card>
                    }
                </div>

            </Col>
        </Row>
    );

    // Chọn kho hàng                  
    const onChooseStore = (info: any) => {
        const matchWareHouse = wareHouseList.find(x => x.id === info.key);
        if (matchWareHouse) setWareHouse(matchWareHouse);
        //Thay đổi khách hàng thì cần đổi lại giá trị
    };

    //Danh sách chọn kho hàng
    const StoreOptions = (
        <Menu onClick={onChooseStore}>
            {wareHouseList.map(x => (
                <Menu.Item key={x.id}>{x.name}</Menu.Item>
            ))}
        </Menu>
    );

    //Hiển thị giao diện để thêm đơn vị vận chuyển
    const AddNewCarrierHandle = () => {
        setIsCarrierModal(true);
    };

    function afterPaidMoney() {
        setShowPaymentModal(false);
        LoadInitData();
    }

    return (
        <div>
            <Affix offsetTop={0}>
                <div style={{ textAlign: "left", backgroundColor: '#F0F2F5' }}>
                    <Row>
                        <Col span={4} >
                            <Button type="text" style={{ marginTop: 10 }} icon={<LeftOutlined />} href="/admin/orders" size="large">
                                Đơn hàng
                            </Button>
                        </Col>
                        <Col span={16} >
                            <Steps
                                size="small"
                                current={stepIndex}
                                labelPlacement="vertical"
                                style={{ marginTop: 10 }}>
                                {
                                    STEP_DEFINE.map(v => <Step title={v.title} />)
                                }
                            </Steps>
                        </Col>
                        <Col span={4} >
                            {
                                STEP_DEFINE[stepIndex] &&
                                <Button
                                    type="primary"
                                    style={{ margin: 5 }}
                                    onClick={processSubmit} size="large">
                                    {STEP_DEFINE[stepIndex]?.btnAction}
                                </Button>
                            }
                        </Col>
                    </Row>

                </div>
            </Affix>
            <Form form={form}
                layout="vertical">

                <Card size="small" title={<LeftRightHeaderContent leftContent={<Space direction="horizontal">
                    {orderCode}
                    {
                        (stepIndex === 2 || stepIndex === 1) &&
                        <Link href={`edit/${id}`}><EditOutlined /></Link>
                    }
                </Space>}
                    rightContent={saleChanel} />}>
                    <Row>
                        <Col span={18}>
                            {/* <div ref={printRef}> */}
                            <Card size="small"
                                title={
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span>Thông tin sản phẩm</span>
                                        {
                                            !agencyId &&
                                            <Dropdown disabled={(stepIndex != 2 && stepIndex != 1)}
                                                overlay={StoreOptions}
                                                trigger={['click']}>
                                                <Button
                                                    type="link"
                                                    style={{ fontWeight: 700 }}>
                                                    {wareHouse ? wareHouse.name : "Chọn kho"} <DownOutlined />
                                                </Button>
                                            </Dropdown>
                                        }
                                    </div>
                                }>
                                <Table
                                    ref={printRef}
                                    size="middle"
                                    dataSource={orderProducts}
                                    columns={getColumns()}
                                    pagination={false}
                                    // loading={isPrintLoading}
                                    bordered
                                    footer={() => footerContent} />
                                <div style={{ margin: '8px auto' }}>
                                    {
                                        stepIndex >= 2 &&
                                        <div style={{ textAlign: 'end' }}>
                                            <Button
                                                onClick={handleOnPrint}
                                                icon={<PrinterOutlined />}>
                                                <span>
                                                    In hóa đơn
                                                </span>
                                            </Button>
                                        </div>
                                    }
                                </div>

                            </Card>
                            {/* </div> */}
                        </Col>
                        <Col span={6} style={{ paddingLeft: 10 }}>
                            <Card size="small" title="Thông tin khách hàng">
                                <Space direction="vertical">
                                    <Space direction="horizontal">
                                        <UserOutlined />
                                        <div>{customerInfo?.fullName ?? ''}</div>
                                    </Space>
                                    <Space direction="horizontal">
                                        <PhoneOutlined />
                                        <div>{customerInfo?.phoneNumber ?? ''}</div>
                                    </Space>
                                    <Space direction="horizontal">
                                        <EnvironmentOutlined />
                                        <div>{customerInfo?.address ?? ''}</div>
                                    </Space>
                                </Space>
                            </Card>

                            <Card size="small" title="Thông đơn vị vận chuyển" hidden={stepIndex != 2}>
                                <Space direction="vertical">
                                    <CCombobox
                                        placeholder="Chọn đơn vị vận chuyển"
                                        chooseOptions={carriers}
                                        showSearch
                                        optionFilterProp="children"
                                        displayName="name"
                                        id="id"
                                        onChange={onChangeCarrier}
                                        extraContent={
                                            <div>
                                                <Divider style={{ margin: '4px 0' }} />
                                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                    <Typography.Link onClick={AddNewCarrierHandle}
                                                        style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}>
                                                        <PlusOutlined />
                                                        <span>Thêm đơn vị vận chuyển</span>
                                                    </Typography.Link>
                                                </div>
                                            </div>
                                        } />
                                </Space>

                                <Form.Item label="Mã vận đơn" name="carrierCode">
                                    <Input placeholder="Nhập mã vận đơn" />
                                </Form.Item>
                                <Form.Item label="Tiền vận chuyển" name="carrierPrice">
                                    <InputMoney placeholder="Nhập tiền vận chuyển" />
                                </Form.Item>
                            </Card>

                            <Card size="small" title="Lịch sử đơn hàng">
                                <Steps direction="vertical" size="small" current={orderHistories.length}>
                                    {
                                        orderHistories.sort((a, b) => a.statusCode - b.statusCode).map(x => (
                                            <Step title={getHistoryItem(x)} key={`${x.id}`} description={
                                                <Space direction="horizontal">
                                                    {format(new Date(x.createdTime), "dd-MM-yyyy")}
                                                    {format(new Date(x.createdTime), "hh:mm:ss")}
                                                </Space>
                                            } />
                                        ))
                                    }
                                </Steps>
                            </Card>
                        </Col>
                    </Row>
                </Card>

            </Form>

            <CarrierModal visible={isCarrierModal}
                title="Thêm đơn vị vận chuyển"
                okModalFnc={afterCreateCarrier}
                onCancel={() => setIsCarrierModal(false)} />

            <PaymentModal visible={showPaymentModal}
                title="Thanh toán tiền hàng"
                applyHandle={afterPaidMoney}
                onCancel={() => setShowPaymentModal(false)}
                id={id ? id : ""}
                remainMoney={totalMoney - paidMoney}
            />

        </div>
    )
}

export default ProcessOrderView;

