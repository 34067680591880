import { Modal, ModalProps, Form, Input, Row, Col, DatePicker, Divider, Typography, Select, Table, Space, message, InputNumber } from "antd";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { GetCities, GetDistricts } from "../../Api/AddressApi";
import { GetCustomerByIdApi, GetCustomersApi } from "../../Api/CustomerApi";
import { GetProductById, GetProducts } from "../../Api/ProductApi";
import { KeyCode, ProductTypes } from "../../common/type-define";
import { CustomerRes } from "../../Model/CustomerModel";
import { ProductDetail } from "../../Model/ProductModel";
import CCombobox from "../Controls/CCombobox";
import {
    DeleteOutlined, DownOutlined, LeftOutlined, PlusOutlined, CloseCircleOutlined
} from '@ant-design/icons';
import { ColumnsType } from "antd/lib/table";
import { OrderProductWarranty } from "../../ViewModel/OrderViewModel";
import { valueType } from "antd/lib/statistic/utils";
import { RegisterOldWarrantyApi } from "../../Api/WarrantyApi";
import { CustomerModal } from "../Customers/CustomerModal";
import { addMonths } from "date-fns";


const { Option } = Select;

type CreateOldWarranty = ModalProps & {
    AfterCreateFnc: Function
}

type ProductCodeResponse = ProductResponse & {
    nameWithCode: string
}

export default function CreateOldWarrantyModal(props: CreateOldWarranty) {
    const { AfterCreateFnc, ...rest } = props;

    const [customers, setcustomers] = useState<CustomerRes[]>([]);
    const [products, setProducts] = useState<ProductCodeResponse[]>([]);
    const [cities, setCities] = useState<CityResponse[]>([]);
    const [districts, setDistricts] = useState<DistrictResponse[]>([]);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [warrantyProducts, setWarrantyProducts] = useState<OrderProductWarranty[]>([]);

    const [form] = Form.useForm<any>();
    useEffect(() => {
        if (rest.visible) {
            GetCustomersApi((data: IListItems<CustomerRes>) => { setcustomers(data.items); })
            GetCities((data: IListItems<CityResponse>) => { setCities(data.items); })
            GetProducts((data: IListItems<ProductResponse>) => {

                const productCodes = data.items.map(x => (
                    {
                        ...x, nameWithCode: `${x.code} - ${x.name}`
                    }))

                setProducts(productCodes);

                resetForm();
            });
        }

    }, [rest.visible])
    const createWarrantyHandle = () => {

        form.validateFields().then((data: any) => {

            const customerId = form.getFieldValue("customerId");
            if (customerId == null || customerId === "" || customerId == undefined) {
                message.warning("Thiếu thông tin khách hàng");
                return;
            }

            if (warrantyProducts.length === 0) {
                message.warning("Đơn hàng không có sản phẩm");
                return;
            }

            const cityId = form.getFieldValue("cityId");
            const districtId = form.getFieldValue("districtId");
            const addressDetail = form.getFieldValue("addressDetail");
            const createdOrderTime: Date = new Date(form.getFieldValue("orderTime"));

            for (var i = 0; i < warrantyProducts.length; i++) {
                const order = warrantyProducts[i];
                if ((order.imei == null || order.imei.length == 0)) {
                    message.warning("Số IMEI không được bỏ sót");
                    return;
                }
            }

            var valueArr = warrantyProducts.map(function (item) { return item.imei });
            var isDuplicate = valueArr.some(function (item, idx) {
                return valueArr.indexOf(item) != idx
            });

            if (isDuplicate) {
                message.warning("Số IMEI bị trùng lặp");
                return;
            }

            const warrantyProductsReq: WarratyProductRequest[] = [];
            warrantyProducts.forEach(x => {
                warrantyProductsReq.push({
                    imei: x.imei,
                    productVersionId: x.productVerId,
                    endWarrantyTime: addMonths(createdOrderTime, x.warrantyMonth),
                    remainMaintenances: x.maintainaceTimes
                })
            })
            const ordersRqes: FakeOrderRequest[] = [];

            const orderReq: FakeOrderRequest = {
                productOrders: warrantyProductsReq,
                customerId: customerId,
                cityId: cityId,
                districtId: districtId,
                addressDetailsExtended: addressDetail,
                orderTime: createdOrderTime
            }

            ordersRqes.push(orderReq);
            const dataReq: FakeOrdersRequest = {
                orders: ordersRqes
            }

            RegisterOldWarrantyApi(dataReq, (res: any) => {
                message.info("Create successful");
                resetForm();
                AfterCreateFnc?.();
            })

        })

    }

    function resetForm() {
        setWarrantyProducts([]);
        form.setFieldsValue({ customerId: "", cityId: "", districtId: "", addressDetail: "", orderTime: moment(), phoneNumber: "" });
    }


    function changeProduct(value: any) {

        const product = products.find(x => x.id === value);

        if (product != null) {
            GetProductById(product.id, (productDetail: ProductDetail) => {
                let proVers: ProductVersionResponse[] = [];
                //Loại bỏ các phiên bản mặc định
                if (productDetail.productType === ProductTypes.Main) {

                    for (let index = 0; index < productDetail.productVersions.length; index++) {
                        const vesion = productDetail.productVersions[index];
                        if (vesion.color !== null)
                            proVers.push(vesion);
                    }
                }

                let newProducts: OrderProductWarranty[] = [...warrantyProducts];
                const productOrderVersion: OrderProductWarranty = {
                    productId: product.id,
                    code: product.code,
                    name: `[${product.code}]-${product.name}`,
                    productVerId: "",
                    productVersions: proVers,
                    imei: "",
                    warrantyMonth: product.defaultWarrantyDays / 30,
                    maintainaceTimes: product.freeMaintenanceTimes
                };

                newProducts.push(productOrderVersion);
                setWarrantyProducts(newProducts);
                form.setFieldsValue({ productSearchId: "" })
            })
        }
    }

    function onChangeVersion(value: valueType, prodChanged: OrderProductWarranty) {
        const productVersionId = value.toString();
        let newProducts: OrderProductWarranty[] = [...warrantyProducts];
        const rowIndex = newProducts.indexOf(prodChanged);
        if (rowIndex != -1) {
            let index = prodChanged.productVersions.findIndex(x => x.id == productVersionId);
            if (index != -1) {
                const versionChoosed = prodChanged.productVersions[index];
                const productOrderVersion: OrderProductWarranty = {
                    ...prodChanged,
                    productVerId: productVersionId,

                };
                newProducts[rowIndex] = productOrderVersion;

                setWarrantyProducts(newProducts);
            }

        }
    }
    function onChangeImei(e: React.FormEvent<HTMLInputElement>, prodChanged: OrderProductWarranty) {
        const newValue = e.currentTarget.value;

        let newProducts: OrderProductWarranty[] = [...warrantyProducts];
        const rowIndex = newProducts.indexOf(prodChanged);
        if (rowIndex != -1) {
            const productOrderVersion: OrderProductWarranty = {
                ...prodChanged,
                imei: newValue,

            };
            newProducts[rowIndex] = productOrderVersion;

            setWarrantyProducts(newProducts);
        }

    }

    function changeWarrantyMonth(val: number, prodChanged: OrderProductWarranty) {

        let newProducts: OrderProductWarranty[] = [...warrantyProducts];
        const rowIndex = newProducts.indexOf(prodChanged);
        if (rowIndex != -1) {
            const productOrderVersion: OrderProductWarranty = {
                ...prodChanged,
                warrantyMonth: val,

            };
            newProducts[rowIndex] = productOrderVersion;

            setWarrantyProducts(newProducts);
        }

    }

    function changeMaintenaceTime(val: number, prodChanged: OrderProductWarranty) {

        let newProducts: OrderProductWarranty[] = [...warrantyProducts];
        const rowIndex = newProducts.indexOf(prodChanged);
        if (rowIndex != -1) {
            const productOrderVersion: OrderProductWarranty = {
                ...prodChanged,
                maintainaceTimes: val,

            };
            newProducts[rowIndex] = productOrderVersion;

            setWarrantyProducts(newProducts);
        }

    }

    //Xóa sản phẩm trong đơn hàng
    const handleRemoveProd = (prod: OrderProductWarranty) => {
        return () => {
            const newVersions = [...warrantyProducts];
            const findIndex = newVersions.findIndex(cart => cart.productId === prod.productId);
            if (findIndex !== -1) {
                newVersions.splice(findIndex, 1);
                setWarrantyProducts(newVersions);
            }

        }
    };


    function onChangeCustomer(value: any) {
        const customer = customers.find(x => x.id === value);
        if (customer) {
            //Khi customer chua đươc load dữ liệu
            if (customer.city == null) {
                GetCustomerByIdApi(value, (cusRes: CustomerRes) => {
                    if (cusRes.city && cusRes.district) {
                        GetDistricts(cusRes.city.id, (distrs: DistrictResponse[]) => {
                            setDistricts(distrs);
                            form.setFieldsValue({ cityId: cusRes.city.id, districtId: cusRes.district.id, addressDetail: cusRes.address });
                        });
                    }
                })
            }
            else {
                if (customer.city && customer.district)
                    GetDistricts(customer.city.id, (data: DistrictResponse[]) => {
                        setDistricts(data);
                        form.setFieldsValue({ cityId: customer.city.id, phoneNumber: customer.phoneNumber, districtId: customer.district.id, addressDetail: customer.address });
                    });


            }
        }

    }

    function AfterCreateCustomerHandle(newCus: CustomerRes) {
        let newCusrtomer: CustomerRes[] = [...customers];
        newCusrtomer.push(newCus);

        setcustomers(newCusrtomer);
        if (newCus.city?.id)
            cityChange(newCus.city?.id);
        form.setFieldsValue({ customerId: newCus.id, phoneNumber: newCus.phoneNumber, cityId: newCus.city?.id, districtId: newCus.district?.id, addressDetail: newCus.address });
        setIsModalVisible(false);
    }

    // Khi chọn thành phố thì sẽ cập nhật quân/huyện
    function cityChange(value: any) {
        GetDistricts(value, (distrs: DistrictResponse[]) => {
            setDistricts(distrs);
        })
    }



    const ProductCols: ColumnsType<OrderProductWarranty> = [
        {
            title: 'Mã SP',
            dataIndex: 'code',
            key: 'code'
        },
        {
            title: 'Phiên bản',
            dataIndex: 'productId',
            key: 'productId',
            render: (name, record, index) => (
                <Select style={{ minWidth: 80 }} onChange={(val) => onChangeVersion(val, record)} value={record.productVerId} >
                    {record.productVersions.map(item => (
                        <Option value={item.id} key={item.id} >{item.name}</Option>
                    ))}
                </Select>
            )
        },
        {
            title: 'Số IMEI',
            dataIndex: 'imei',
            key: 'imei',
            render: (imei, record) => (
                <Input value={record.imei} onChange={(val) => onChangeImei(val, record)} />
            )
        },
        {
            title: 'Bảo hành',
            dataIndex: 'warrantyMonth',
            key: 'warrantyMonth',
            render: (warrantyMonth, record) => (
                <InputNumber min={1} value={record.warrantyMonth} onChange={(val) => changeWarrantyMonth(val, record)} />
            )
        },
        {
            title: 'Bảo trì',
            dataIndex: 'maintainaceTimes',
            key: 'maintainaceTimes',
            render: (maintainaceTimes, record) => (
                <InputNumber min={1} value={record.maintainaceTimes} onChange={(val) => changeMaintenaceTime(val, record)} />
            )
        },
        {
            title: '',
            key: 'action',
            fixed: 'right',
            align: 'center',
            width: '30px',
            render: (record) => (
                <Space size="middle" >
                    <DeleteOutlined style={{ color: 'red' }} onClick={handleRemoveProd(record)} />
                </Space>
            ),
        },
    ]

    return (
        <Modal {...rest} width={1000} cancelText="Đóng"
            okText="Tạo và tiếp tục"
            centered
            onOk={createWarrantyHandle}
        >
            <Form layout="vertical" form={form}>
                <Row>
                    <Col span={18} style={{ paddingRight: 10 }}>
                        <Form.Item label="Thêm sản phẩm" name="productSearchId">
                            <CCombobox
                                focusHotkey={KeyCode.F3}
                                placeholder="Chọn sản phẩm (F3)"
                                chooseOptions={products}
                                displayName="nameWithCode"
                                id="id"
                                onChange={changeProduct} />
                        </Form.Item>

                        <Table columns={ProductCols}
                            dataSource={warrantyProducts}
                            pagination={false}
                        />
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Ngày tạo đơn hàng" name="orderTime">
                            <DatePicker format='DD/MM/YYYY'
                                showToday={true} defaultValue={moment()} />
                        </Form.Item>
                        <Form.Item label="Khách hàng" name="customerId" style={{ marginBottom: '0px', marginTop: '10px' }}>
                            <CCombobox
                                focusHotkey={KeyCode.F4}
                                placeholder="Chọn theo khách (F4)"
                                chooseOptions={customers}
                                showSearch
                                optionFilterProp="children"
                                displayName="fullName"
                                id="id"
                                onChange={onChangeCustomer}
                                extraContent={
                                    <div>
                                        <Divider style={{ margin: '4px 0' }} />
                                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                            <Typography.Link onClick={() => setIsModalVisible(true)}
                                                style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}>
                                                <PlusOutlined />
                                                <span>Thêm khách hàng mới</span>
                                            </Typography.Link>
                                        </div>
                                    </div>
                                } />
                        </Form.Item>
                        <Form.Item label="Sô điện thoại" name="phoneNumber" style={{ marginBottom: '0px', marginTop: '10px' }}>
                            <Input />
                        </Form.Item>

                        <Form.Item label="Tỉnh/Thành phố" name="cityId" style={{ marginBottom: '0px', marginTop: '10px' }}>
                            <CCombobox
                                placeholder="Chọn Tỉnh/Thành phố"
                                chooseOptions={cities}
                                displayName="name"
                                id="id"
                                onChange={cityChange} />
                        </Form.Item>

                        <Form.Item label="Quận/Huyện" name="districtId" style={{ marginBottom: '0px', marginTop: '10px' }}>
                            <CCombobox
                                placeholder="Chọn Quận/Huyện"
                                chooseOptions={districts}
                                displayName="name"
                                id="id" />
                        </Form.Item>

                        <Form.Item label="Địa chỉ" name="addressDetail" style={{ marginBottom: '0px', marginTop: '10px' }}>
                            <Input placeholder="Nhập địa chỉ" />
                        </Form.Item>
                    </Col>
                </Row>

            </Form>

            <CustomerModal okModalFunc={AfterCreateCustomerHandle}
                onCancel={() => setIsModalVisible(false)}
                cities={cities}
                visible={isModalVisible} />
        </Modal>
    )
}