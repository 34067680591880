import {
    LockOutlined, LogoutOutlined,
    BellOutlined,
    ProfileFilled, UserOutlined
} from '@ant-design/icons';
import { Avatar, Badge, Button, Card, Dropdown, Form, Input, Menu, message, Modal, Space, Typography } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router";
import { ChangePasswordStaffsApi } from '../Api/StaffApi';
import { canViewSumarry, getCurrentName, getEmail, getRoleAccount, removeUserSession } from "../Helper/CommonHelper";
import { resetState } from '../redux/slices/auth.slice';
import { ProfileViewModel } from "../ViewModel/ProfileViewModel";
import { MenuOutlined } from '@ant-design/icons';
import { toggleCollapsedMenu } from '../redux/slices/pages.slice';
import { SaleReportApi } from '../Api/Reports/SaleReportApi';
import { OrderStatus } from '../common/type-define';

const { Link } = Typography;

type NotifyItem = {
    name: string;
    id: string;
}
interface AdminHeaderProps {
    title: string
}
function AdminHeader({ title }: AdminHeaderProps) {
    const dispatch = useDispatch();
    const [isShowProfie, setIsShowProfie] = useState<boolean>(false);

    const [notifyItems, setNotifyItems] = useState<NotifyItem[]>([]);
    const [profileForm] = Form.useForm<ProfileViewModel>();
    const navigate = useNavigate();

    const [counter, setCounter] = useState(0);

    // Third Attempts
    useEffect(() => {
        if (canViewSumarry()) {
            const time = setInterval(() => {
                var date = new Date();

                date.setDate(date.getDate() - 1);
                const query = {
                    QueryFrom: date,
                    QueryTo: new Date()
                }
                // SaleReportApi.GetOrderReportsApi(query).then((orders) => {

                //     if (orders?.status === 200 && orders.data.length > 0) {

                //         const items: NotifyItem[] = [];
                //         orders.data.map(x => {
                //             if (x.orderStatus === OrderStatus.Pending) {
                //                 items.push({
                //                     name: x.orderCode,
                //                     id: x.id
                //                 })
                //             }
                //         });
                //         setNotifyItems(items);
                //         setCounter(items.length);

                //     }

                // }).catch((error) => {
                //     console.log(error);
                // })

            }, 10000);
            return () => clearInterval(time);
        }
    }, [counter]);

    const logout = () => {
        removeUserSession();
        navigate("/admin");
        navigate(0);
        dispatch(resetState());
    }
    const viewProfile = () => {
        setIsShowProfie(true);
    }

    const closeProfileModal = () => {
        setIsShowProfie(false);
    };

    const menu = (
        <Menu>
            <Menu.Item key="0" onClick={viewProfile}>
                <ProfileFilled style={{ color: '#40A9FF', padding: 5 }} />
                <span>Tài khoản của tôi</span>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="1" onClick={logout}>
                <LogoutOutlined style={{ color: 'red', padding: 5 }} />
                <span>Đăng xuất</span>
            </Menu.Item>

        </Menu>
    );

    const Notify = (
        <Menu>
            {
                notifyItems.map(x => (
                    <Menu.Item key={x.id} >
                        <Link href={`/admin/orders/${x.id}`}>{`Đơn hàng ${x.name}`}</Link>
                    </Menu.Item>
                ))
            }
        </Menu>
    );

    const changePassword = () => {
        profileForm.validateFields().then((data: ProfileViewModel) => {
            ChangePasswordStaffsApi({ currentPassword: data.password, newPassword: data.newPassword }, () => {
                profileForm.resetFields();
                message.info("Đổi mật khẩu thành công");
            })
        })
    }

    const handleCollapseMenu = () => {
        dispatch(toggleCollapsedMenu())
    }

    return (

        <div style={{ padding: '12px 20px', background: 'white', marginBottom: '12px', borderBottom: '1px solid #dee2e6', minWidth: 600 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex' }}>
                    <Button
                        type="text"
                        onClick={handleCollapseMenu}
                        style={{ marginRight: 8 }}
                        icon={<MenuOutlined />} />
                    <div style={{ fontSize: 16, fontWeight: "bold", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {title}
                    </div>
                </div>

                <Space direction='horizontal'>
                    <Dropdown overlay={menu} trigger={['click']}>
                        <Link>
                            <span style={{ margin: 'auto 8px' }}>{getCurrentName()} </span>

                            <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
                        </Link>
                    </Dropdown>

                    <Dropdown overlay={Notify} trigger={['click']} >
                        <Link>
                            <Badge count={counter}>
                                <Avatar icon={<BellOutlined color='yellow' />} size="default" />
                            </Badge>
                        </Link>
                    </Dropdown>
                </Space>

            </div>

            <Modal
                title="Thông tin của bạn"
                centered
                style={{ paddingTop: 0, paddingBottom: 0 }}
                visible={isShowProfie}
                footer=""
                cancelButtonProps={{ hidden: true }}
                okButtonProps={{ hidden: true }}
                onCancel={closeProfileModal}>
                <Form form={profileForm}
                    layout="vertical" >
                    <Card style={{ borderRadius: 5 }} size="small">
                        <div>
                            <Space direction="horizontal">
                                <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
                                {getCurrentName()}
                            </Space>
                        </div>
                        <div>
                            {getEmail()}
                        </div>

                        <div>
                            {getRoleAccount()}
                        </div>

                    </Card>

                    <Card title="Thay đổi mật khẩu" style={{ marginTop: 10, borderRadius: 5 }} size="small">
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Nhập mật khẩu hiện tại của bạn!' }]}>
                            <Input
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="Mật khẩu hiện tại"
                            />
                        </Form.Item>

                        <Form.Item
                            name="newPassword"
                            rules={[{ required: true, message: 'Nhập mật khẩu mới' }]}>
                            <Input
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="Mật khẩu mới"
                            />
                        </Form.Item>

                        <Button type="primary" htmlType="submit" onClick={changePassword}>
                            Thay đổi
                        </Button>
                    </Card>
                </Form>
            </Modal>

        </div>


    )
}

export default AdminHeader;
