import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../configure/rootReducer";


export const getListOrder = createSelector(
    (rootState: RootState) => rootState.ordersAction,
    (state) => state.listOrder,
)

export const getFetchOrderLoading = createSelector(
    (rootState: RootState) => rootState.ordersAction,
    (state) => state.fetchOrderLoading,
)

