import { Form, Input, Modal, ModalProps } from "antd";
import { CreateCategoryApi } from "../../Api/CategoryApi";
import Category, { CategoryRequest } from "../../Model/CategoryModel";
import NewCategorViewModel from "../../ViewModel/CategoryViewModel";
import {
    InfoCircleOutlined
} from '@ant-design/icons';
const { TextArea } = Input;

type CategoryModalProps = ModalProps & {
    okModalFnc: Function,
    id?: string
}

export default function CategoryModal(props: CategoryModalProps) {
    const { okModalFnc, id, ...rest } = props;

    const [categoryForm] = Form.useForm<NewCategorViewModel>();

    const CreateCategorySubmit = () => {

        categoryForm.validateFields()
            .then((data: NewCategorViewModel) => {

                let categoryData: CategoryRequest = {
                    name: data.categoryName,
                    description: data.description,
                };

                CreateCategoryApi(categoryData, (res: Category) => {
                    okModalFnc?.(res);
                });
            });
    };

    return (
        <Modal {...rest}
            centered
            onOk={CreateCategorySubmit}>
            <Form form={categoryForm}
                layout="vertical">
                <Form.Item name="categoryName" label="Tên nhóm sản phẩm" required tooltip="Tên sản phẩm không bao gồm các giá trị thuộc tính như màu sắc, chất liệu">
                    <Input placeholder="Nhập tên nhóm sản phẩm" />
                </Form.Item>

                <Form.Item label="Miêu tả sản phẩm" name="description"
                    tooltip={{
                        title: 'Miêu tả thông tin chi tiết nhóm sản phẩm',
                        icon: <InfoCircleOutlined />
                    }}>
                    <TextArea placeholder="Nhập miêu tả nhóm sản phẩm" rows={2} />
                </Form.Item>
            </Form>
        </Modal>
    )
}