import { Button, Space, Table, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { subMonths } from "date-fns";
import { format } from 'date-fns';
import { ColumnsType } from "antd/lib/table";
import { MathHelper } from "../../../Helper/math.helper";
import { SaleReportApi } from "../../../Api/Reports/SaleReportApi";
import Link from "antd/lib/typography/Link";
import {
    ArrowLeftOutlined
} from '@ant-design/icons';
import { filterTypes } from "../../../common/type-define";
import { ChoosFilter, FilterControls } from "../../Controls/FilterControls";
import { cellStyle, getHeightFit, headerStyle } from "../../Helper/UIHelper";
import RangeDateControl from "../../Controls/RangeDateControl";

export default function DetailReport() {
    const [data, setData] = useState<IDetailReport[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [filters, setFilters] = useState<FilterItem[]>([]);

    useEffect(() => {
        fetchData(subMonths(new Date(), 1), new Date())
        setFilters([
            { type: filterTypes.WAREHOUSE, closeable: true },
            { type: filterTypes.STAFF, closeable: true },
            { type: filterTypes.CUSTOMER, closeable: true }]);
    }, []);


    //Lấy dữ liệu
    function fetchData(fromDate: Date, toDate: Date) {
        let re: IDetailReport[] = [];

        const query = {
            QueryFrom: fromDate,
            QueryTo: toDate
        }
        setLoading(true);
        SaleReportApi.GetOrderReportsApi(query).then((Orders) => {
            SaleReportApi.GetReturnOrderReportsApi(query).then((returnOrderRes) => {
                const orders: IOrderReport[] = Orders.data;
                orders.forEach(order => {
                    const productOrders = order.productOrders;

                    let pricing = productOrders.reduce((prev, curr) => {
                        let discount = curr.discountType == 1 ? curr.discount * curr.salePrice / 100 : curr.discount
                        let singlePriceAfterDiscount = curr.salePrice - discount;
                        prev.totalPriceAfterDiscount += singlePriceAfterDiscount;
                        prev.totalPrice += curr.salePrice;
                        return prev;
                    }, { totalPrice: 0, totalPriceAfterDiscount: 0 })

                    let totalPrice = pricing.totalPrice;
                    let totalPriceAfterDiscount = pricing.totalPriceAfterDiscount;



                    let orderDiscount = order.discountType == 1 ? order.discount * totalPriceAfterDiscount / 100 : order.discount
                    totalPriceAfterDiscount = totalPriceAfterDiscount - orderDiscount
                    productOrders.forEach(prodOrder => {
                        let discount = prodOrder.discountType == 1 ? prodOrder.discount * prodOrder.salePrice / 100 : prodOrder.discount
                        let singlePriceAfterDiscount = prodOrder.salePrice - discount;
                        const temp = re.find(t => t.productCode === prodOrder.productCode && t.orderCode === order.orderCode);
                        if (!temp) {

                            re.push({
                                orderTime: order.orderTime,
                                orderCode: order.orderCode,
                                customerCode: order?.customer?.commissionCode,
                                customerName: order?.customer?.fullName,
                                productCode: prodOrder.productCode,
                                productName: prodOrder.productName,
                                unit: prodOrder.unit.name,
                                totalSaleAmount: 1,
                                price: prodOrder.salePrice,
                                numberReturn: 0,
                                discount: (prodOrder.salePrice / totalPrice) * orderDiscount + discount,
                                reduceMoney: 0,
                                totalMoney: prodOrder.salePrice,
                                totalMoneyReturn: 0,
                                totalDiscountPrice: singlePriceAfterDiscount
                            })
                        } else {
                            temp.totalSaleAmount++;
                            temp.discount *= temp.totalSaleAmount;
                            temp.totalMoney += prodOrder.salePrice;
                        }
                    })

                })

                //Xủ lý hàng trả lại
                if (returnOrderRes.status === 200) {
                    const returnOrders: IOrderReturnReport[] = returnOrderRes.data;
                    returnOrders.forEach(order => {
                        let staffId = "";
                        if (order.order.orderHistories.length > 0) {
                            staffId = order.order.orderHistories[0].actorId;
                        }
                        order.productOrderReturns.forEach(prodReturn => {
                            const existPro = re.find(t => t.productCode === prodReturn.productCode && t.orderCode === order.orderCode);

                            if (existPro) {
                                existPro.numberReturn++;
                                existPro.totalMoneyReturn += prodReturn.refund;
                            }
                        })
                    })
                }

                setData(re);
            })
        }).finally(() => {
            setLoading(false);
        })

    }


    const columns: ColumnsType<IDetailReport> = [
        {
            title: (<span style={{ whiteSpace: 'nowrap' }}>Ngày tháng</span>),
            dataIndex: 'orderTime',
            key: 'orderTimee',
            onCell: () => cellStyle(),
            onHeaderCell: () => headerStyle(),
            render: (orderTimee) => (
                format(new Date(orderTimee), "dd-MM-yyyy")
            )
        },
        {
            title: 'Mã đơn hàng',
            dataIndex: 'orderCode',
            key: 'orderCode',
            onHeaderCell: () => headerStyle(),
        },
        // {
        //     title: 'Mã khách hàng',
        //     dataIndex: 'customerCode',
        //     key: 'customerCode',
        //     width: '10%',
        //     onCell: () => cellStyle(),
        //     onHeaderCell: () => headerStyle()
        // },
        {
            title: 'Tên khách hàng',
            dataIndex: 'customerName',
            key: 'customerName',
            width: '10%',
            onCell: () => cellStyle(),
            onHeaderCell: () => headerStyle()
        },
        {
            title: 'Mã hàng',
            dataIndex: 'productCode',
            key: 'productCode',
            width: '10%',
            onCell: () => cellStyle(),
            onHeaderCell: () => headerStyle(),
            render: (productCode, record) => (
                <Tooltip title={record.productName}>
                    <Link>{record.productCode}</Link>
                </Tooltip>
            )
        },
        {
            title: <span>SL <br /> bán</span>,
            dataIndex: 'totalSaleAmount',
            key: 'totalSaleAmount',
            align: 'center',
            width: '15%',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'ĐVT',
            dataIndex: 'unit',
            key: 'unit',
            align: 'center',
            width: '10%',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Đơn giá',
            dataIndex: 'price',
            key: 'price',
            onHeaderCell: () => headerStyle(),
            render: (price) => (
                MathHelper.formatPriceVND(price)
            )
        },
        {
            title: 'Doanh số bán',
            dataIndex: 'totalMoney',
            key: 'totalMoney',
            onHeaderCell: () => headerStyle(),
            render: (totalMoney) => (
                MathHelper.formatPriceVND(totalMoney)
            )
        },
        {
            title: 'Chiết khấu',
            dataIndex: 'discount',
            key: 'discount',
            onHeaderCell: () => headerStyle(),
            render: (discount) => (
                MathHelper.formatPriceVND(discount, true)
            )
        },
        {
            title: <span>SL <br /> trả lại</span>,
            dataIndex: 'numberReturn',
            key: 'numberReturn',
            width: '10%',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: <span>Giá trị <br /> trả lại</span>,
            dataIndex: 'totalMoneyReturn',
            key: 'totalMoneyReturn',
            width: '15%',
            onHeaderCell: () => headerStyle(),
            render: (totalMoneyReturn) => (
                MathHelper.formatPriceVND(totalMoneyReturn, true)
            )
        },
        {
            title: <span>Giá trị <br /> giảm giá</span>,
            dataIndex: 'Imei',
            key: 'Imei',
            width: '15%',
            onHeaderCell: () => headerStyle(),
        },
    ]

    function changeRangeTime(startTime: Date, endTime: Date) {

        fetchData(startTime, endTime);
    }


    function onChangeFilter() {

    }

    return (
        <div>
            <Space direction='horizontal'>
                <Button type="default" icon={<ArrowLeftOutlined />} href="/admin/report/sale"></Button>
                <RangeDateControl onChangeDate={changeRangeTime} />
                <ChoosFilter filterList={filters} onChangeFunc={(items: FilterItem[]) => setFilters(items)} />
            </Space>
            <FilterControls items={filters} afterChangeFnc={onChangeFilter} />

            <Table loading={loading}
                dataSource={data}
                columns={columns}
                size="small" bordered
                scroll={{ x: true, y: getHeightFit(200) }}
                pagination={false}
            />
        </div>
    )
}