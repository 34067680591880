import {
    CheckOutlined, CloseOutlined
} from '@ant-design/icons';
import { message, Space, Table, Tag, Tooltip } from 'antd';
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { DecideStoreTicketApi, GetWarrantyStoreTicketApi, GetWarrantyStoreTicketByIdApi } from "../../Api/WarrantyApi";
import { WarrantyOrderStatus } from '../../common/type-define';
import { WarrantyStoreTicketRes } from "../../Model/WarrantiesModel";
import AproveStoreTicketModal from "./AproveStoreTicketModal";



type StoreTicketTabProps = {
    isActived: boolean
}


export default function StoreTicketTabView(props: StoreTicketTabProps) {
    const { isActived } = props;

    const [tickets, setTickets] = useState<WarrantyStoreTicketRes[]>([]);
    const [showApproveModal, setShowApproveModal] = useState<boolean>(false);
    const [ticketApproved, setTicketApproved] = useState<WarrantyStoreTicketRes>();

    useEffect(() => {
        if (isActived) {
            GetWarrantyStoreTicketApi((res: IListItems<WarrantyStoreTicketRes>) => {
                setTickets(res.items);
            })
        }
    }, [isActived]);


    //Hiện thị hộp thoại để chấp nhận phiếu xuất kho
    const ApproveExportStore = (record: WarrantyStoreTicketRes) => {
        return () => {
            if (record) {
                setTicketApproved(record);
                setShowApproveModal(true);
            }

        }
    }

    //Sau khi chấp nhận xuất kho thì cập nhật lại trạng thái
    const ApprovedCallback = () => {
        setShowApproveModal(false);
        UpdateStatus();
    }

    //Từ chối xuất kho
    const DenyExportStore = (record: WarrantyStoreTicketRes) => {
        return () => {

            if (record) {
                let dataReq = {
                    decision: 1
                }
                DecideStoreTicketApi(record.id, dataReq, (res: any) => {
                    message.info("Phiếu xuất kho đã bị từ chối");
                    UpdateStatus();
                })
            }

        }
    }

    //Cập nhật lại toàn bộ record trên table
    function UpdateStatus() {
        GetWarrantyStoreTicketApi((res: IListItems<WarrantyStoreTicketRes>) => {
            setTickets(res.items);
        });
    }

    //View lịch sử của phiếu kho
    const ViewHistoryStoreTicket = (record: WarrantyStoreTicketRes) => {
        return () => {
            GetWarrantyStoreTicketByIdApi(record.id, (ticketDetail: WarrantyStoreTicketRes) => {
                message.info(JSON.stringify(ticketDetail));

            })

        }
    }

    // Điều khiển giao diện cho phù hợp
    function getControlAction(record: WarrantyStoreTicketRes): JSX.Element {
        //Quá trình maintance đã bắt đầu
        if (record.status === WarrantyOrderStatus.Created) {
            return (
                <Space direction="horizontal">
                    <Tooltip title="Chấp nhận xuất kho">
                        <CheckOutlined onClick={ApproveExportStore(record)} style={{ color: "green" }} />
                    </Tooltip>
                    <Tooltip title="Từ chối xuất kho">
                        <CloseOutlined onClick={DenyExportStore(record)} style={{ color: "red" }} />
                    </Tooltip>
                </Space>
            )
        } else if (record.status === WarrantyOrderStatus.Approved) {

        }
        else if (record.status === WarrantyOrderStatus.Decline) {

        }
        else {

        }


        return (<div></div>)
    }

    function getStatusTicket(record: WarrantyStoreTicketRes): JSX.Element {
        if (record.status === WarrantyOrderStatus.Created) {
            return (
                <Tag color="orange">
                    Chờ duyệt
                </Tag>
            )
        } else if (record.status === WarrantyOrderStatus.Approved) {
            return (
                <Tag color="green">
                    Đã được chấp nhận
                </Tag>
            )
        }
        else if (record.status === WarrantyOrderStatus.Decline) {
            return (
                <Tag color="red">
                    Từ chối
                </Tag>
            )
        }
        else if (record.status === WarrantyOrderStatus.Exported) {
            return (
                <Tag color="green">
                    Đã xuất kho
                </Tag>
            )
        }
        else {
            return (<div></div>)
        }

    }

    const cols: ColumnsType<WarrantyStoreTicketRes> = [
        {
            title: 'Mã Phiếu',
            dataIndex: 'orderCode',
            key: 'orderCode',
            render: (orderCode, record) => (
                <a>

                    <span onClick={ViewHistoryStoreTicket(record)}>{orderCode}</span>
                </a>
            )
        },
        {
            title: 'Lý do',
            dataIndex: 'reason',
            key: 'reason',
        },
        {
            title: 'Yêu cầu',
            dataIndex: 'isReplace',
            key: 'isReplace',
            render: (isReplace) => (
                isReplace ? ("Đổi mới") : ("Thay phụ kiện")
            )
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            render: (status, record) => (
                getStatusTicket(record)
            )
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            render: (record) => (
                getControlAction(record)
            )
        }
    ]
    return (
        <div>
            <Table columns={cols}
                dataSource={tickets}
            />
            <AproveStoreTicketModal visible={showApproveModal}
                title="Duyệt phiếu xuất kho"
                ApprovedFnc={ApprovedCallback}
                storeTicket={ticketApproved}
                onCancel={() => setShowApproveModal(false)}
            />
        </div>

    )
}