import { Button, Form, Input, InputNumber, Modal, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { UpdateProductVersionsCommand } from "../../Model/ProductModel";
import { useSelectorRoot } from "../../redux/configure/store";
import { getUpdateProductLoading } from "../../redux/selectors/products.selector";
import { getUpdateProductSelected } from "../../redux/selectors/storage.selector";
import { updateProductVersions } from "../../redux/slices/products.slice";
import { setUpdateProductSelected } from "../../redux/slices/storage.slice";
import InputMoney from "../Controls/MoneyInput";

const { Option } = Select;
interface IStorageUpdateItem {
    id: string;
    name: string;
    fullName: string;
    salePrice: number;
    priceInput: number;
}

const StorageProductUpdateModalComponent = (): JSX.Element => {
    const dispatch = useDispatch();
    const updateProductSelected = useSelectorRoot(getUpdateProductSelected);
    const updateProductLoading = useSelectorRoot(getUpdateProductLoading);
    const [form] = Form.useForm<IStorageUpdateItem>();
    const [isDirty, setIsDirty] = useState<boolean>(false);
    useEffect(() => {
        if (!updateProductSelected) return;
        const { id, name, fullName, salePrice, priceInput } = updateProductSelected;
        form.setFieldsValue({ id, name, fullName, salePrice, priceInput });
    }, [form, updateProductSelected])

    const onCancel = () => {
        dispatch(setUpdateProductSelected(null));
    }

    const onFinish = async () => {
        const validatedData = await form.validateFields();
        const { name, salePrice, priceInput } = validatedData;
        const dataRequest: UpdateProductVersionsCommand = {
            details: [{
                id: updateProductSelected?.id ?? null,
                salePrice: salePrice,
                priceInput: priceInput
            }]
        };
        dispatch(updateProductVersions(dataRequest));
    }

    return (
        <Modal centered
            visible={updateProductSelected !== null}
            destroyOnClose
            onCancel={onCancel}
            maskClosable={false}
            footer={null}
            title="Sửa chi tiết sản phẩm">
            <Spin spinning={updateProductLoading}>
                <Form
                    onValuesChange={() => setIsDirty(true)}
                    onFinish={onFinish}
                    layout="vertical"
                    form={form}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                        <div>
                            <Form.Item
                                label="Tên sản phẩm"
                                name="fullName">
                                <Input style={{ color: '#000' }} disabled />
                            </Form.Item>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>

                                <Form.Item
                                    label="Giá nhập"
                                    name="priceInput"
                                    rules={[{ required: true, message: 'Hãy nhập giá nhập' }]}>
                                    <InputMoney />

                                </Form.Item>

                                <Form.Item
                                    label="Giá bán"
                                    name="salePrice"
                                    rules={[{ required: true, message: 'Hãy nhập giá bán' }]}>
                                    <InputMoney />

                                </Form.Item>
                            </div>
                        </div>

                        <Form.Item style={{ margin: '20px 0 0 0' }}>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'end', height: '100%' }}>
                                <Button
                                    onClick={onCancel}
                                    style={{ margin: 'auto 8px' }}>
                                    Đóng
                                </Button>
                                <Button type="primary" htmlType="submit" disabled={!isDirty}>
                                    Cập nhật
                                </Button>
                            </div>
                        </Form.Item>
                    </div>
                </Form>
            </Spin>
        </Modal>
    )
}

const StorageProductUpdateModal = React.memo(StorageProductUpdateModalComponent);

export default StorageProductUpdateModal;