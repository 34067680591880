import { Modal, ModalProps, Input, Form, message } from "antd";
import React from "react";

const { TextArea } = Input;

type DecisionModalProps = ModalProps & {

    okModalFnc: Function
}
export default function DecisionModal(props: DecisionModalProps) {
    const { okModalFnc, ...rest } = props;

    const [form] = Form.useForm();

    const okHandle = () => {
        const reason = form.getFieldValue("cause");
        if (reason == null || reason === "") {
            message.info("Lý do không được để trống");
            return;
        }

        okModalFnc(reason);
        rest.visible = false;
    }

    return (
        <Modal {...rest}
            centered
            onOk={okHandle}
        >
            <Form form={form} layout="vertical" >
                <Form.Item label="Lý do" name="cause" required>
                    <TextArea rows={2} />
                </Form.Item>
            </Form>
        </Modal>
    )
}