import { Button, DatePicker, Space, Table } from "antd";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { format } from 'date-fns';
import {
    ArrowLeftOutlined
} from '@ant-design/icons';
import { ChoosFilter, FilterControls } from "../../Controls/FilterControls";
import { ColumnsType } from "antd/lib/table";
import { subMonths } from "date-fns";
import { headerStyle } from "../../Helper/UIHelper";
import { MaintenanceReportApi } from "../../../Api/Reports/MaintenanceReportApi";
import { ColumnType } from "antd/lib/table";

const { RangePicker } = DatePicker;
export default function MaintenanceScheduleReport() {
    const [data, setData] = useState<IScheduleMaintenanceReport[]>([]);
    const [startDate, setStartDate] = useState<Date>(subMonths(new Date(), 1));
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [filters, setFilters] = useState<FilterItem[]>([]);

    useEffect(() => {
        fetchData();
        
    }, []);
    async function fetchData() {
        const res = await MaintenanceReportApi.GetScheduleMaintenanceReportApi(startDate, endDate);
        if(res?.status === 200){
            setData(res.data);
        }
    }

    const columns: ColumnsType<IScheduleMaintenanceReport> = [
        {
            title: 'Ngày Tháng',
            dataIndex: 'reportTime',
            key: 'reportTime',
            onHeaderCell: () => headerStyle(),
            render: (reportTime) => (
                format(new Date(reportTime), "dd-MM-yyyy")
            )
        },
        {
            title: 'Sản phẩm',
            dataIndex: 'productName',
            key: 'productName',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'IMEI',
            dataIndex: 'imei',
            key: 'imei',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Khách hàng',
            dataIndex: 'customerName',
            key: 'customerName',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Trong kế hoạch',
            dataIndex: 'inSchedule',
            key: 'inSchedule',
            onHeaderCell: () => headerStyle(),
            render: (inSchedule) => {
                if(inSchedule){
                    return 'x'
                }
            }
        },
        {
            title: 'Ngoài kế hoạch',
            dataIndex: 'outSchedule',
            key: 'outSchedule',
            onHeaderCell: () => headerStyle(),
            render: (outSchedule) => {
                if(outSchedule){
                    return 'x'
                }
            }
        },
        {
            title: 'Người tiếp nhận',
            dataIndex: 'receiver',
            key: 'receiver',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Người thực hiện',
            dataIndex: 'maintainceActor',
            key: 'maintainceActor',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Ghi chú',
            dataIndex: 'note',
            key: 'note',
            onHeaderCell: () => headerStyle(),
        },
    ]

    function changeRangeTime(values: any, formatString: [string, string]) {
        const startTime: Moment = values[0];
        const endTime: Moment = values[1];

        setStartDate(startTime.toDate());
        setEndDate(endTime.toDate());
        fetchData();
    }

    function onChangeFilter() {

    }
    return (
        <div>
            <Space direction='horizontal'>
                <Button type="default" icon={<ArrowLeftOutlined />} href="/admin/report/warranty"></Button>
                <RangePicker format='DD/MM/YYYY' showTime={false}
                    clearIcon={false}
                    style={{ width: '220px' }}
                    onChange={changeRangeTime}
                    defaultValue={[moment(subMonths(new Date(), 1)), moment()]} />
                <ChoosFilter filterList={filters} onChangeFunc={(items: FilterItem[]) => setFilters(items)} />

            </Space>
            <FilterControls items={filters} afterChangeFnc={onChangeFilter} />
            <Table columns={columns} size="small" bordered dataSource={data}

                pagination={false}
            />
        </div>
    )
}