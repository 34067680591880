import { AxiosRequest } from "./Axios/AxiosRequest";
import { IVoucherItem, VoucherResponse, VoucherUpdateData } from "../common/type-define";

import { CreateVoucherProductsCommand, UpdateVoucherProducts } from "../Model/VoucherModel";
import { SERVER_API } from "./UrlDefination";
import { RequestHandler } from "./RequestAPI";
import { AxiosResponse } from "axios";


export class VoucherApi {
    static async getVouchers() {
        return RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.VOUCHER.GET}`));
    }
    static async createVoucher(voucherItem: IVoucherItem,) {
        return RequestHandler.handleRequest(AxiosRequest.post(`${SERVER_API.HOST}/${SERVER_API.VOUCHER.CREATE}`, voucherItem));
    }
    static async assignVoucher(data: CreateVoucherProductsCommand,) {
        return RequestHandler.handleRequest(AxiosRequest.post(`${SERVER_API.HOST}/${SERVER_API.VOUCHER.ASSIGN}`, data));
    }
    static async updateVoucher(data: VoucherUpdateData,) {
        const { id, requestData } = data;
        return RequestHandler.handleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.VOUCHER.UPDATE(id)}`, requestData));
    }
    static async getProductsAppliedToVoucher(voucherId: string,) {
        return RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.VOUCHER.GET_PRODUCT_BY_VOUCHER(voucherId)}`));
    }

    static async updateVoucherProduct(voucherId: string, requestData: UpdateVoucherProducts,) {
        return RequestHandler.handleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.VOUCHER.UPDATE_VOUCHER_PRODUCT(voucherId)}`, requestData));
    }

    static async getVoucherByProducts(productsId: string[]): Promise<AxiosResponse<VoucherResponse[], any>> {
        const queryParam = productsId.map(id => `ProductIds=${id}`).join('&');
        return RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.VOUCHER.GET_VOUCHER_BY_PRODUCT}?${queryParam}`));
    }
}