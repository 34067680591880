import { AxiosRequest } from "./Axios/AxiosRequest";
import { RequestHandler } from "./RequestAPI";
import { SERVER_API } from "./UrlDefination";

export class WarrantyCenterApi {

    static CreateWarrantyCenter = async (data: any, doAfterResponse: Function) => {
        await RequestHandler.handleRequest(AxiosRequest.post(`${SERVER_API.HOST}/${SERVER_API.WARRANTY_CENTER.CREATE}`, data), doAfterResponse);
    }
    //Lấy toàn bộ warranty center
    static GetWarrantyCenters = async (doAfterResponse: Function) => {
        await RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.WARRANTY_CENTER.GET}`), doAfterResponse);
    }

    static GetWarrantyCenterById = async (id: string, doAfterResponse: Function) => {
        await RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.WARRANTY_CENTER.GET_BY_ID(id)}`), doAfterResponse);
    }

    static UpdateWarrantyCenterById = async (id: string, data: any, doAfterResponse: Function) => {
        await RequestHandler.handleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.WARRANTY_CENTER.UPDATE(id)}`, data), doAfterResponse);
    }
}