import { Button, Card, Col, message, Row } from "antd";
import { SaleReportApi } from "../../Api/Reports/SaleReportApi";

export default function SyncReportView() {

    const SyncOrderData = () => {

        SaleReportApi.SyncOrderData().then(() => {
            message.info("Đã đồng bộ bán hàng");
        })
    }

    const SyncPurchaseData = () => {

        SaleReportApi.SyncPurchaseData().then(() => {
            message.info("Đã đồng bộ nhập hàng");
        })
    }

    return (
        <div>
            <Card title="Đồng bộ báo cáo" >
                <Row>
                    <Col span={6}>
                        <Button onClick={SyncOrderData}>Đồng bộ bán hàng</Button>
                    </Col>
                    <Col span={6}>
                        <Button onClick={SyncPurchaseData}>Đồng bộ nhập hàng</Button>
                    </Col>
                    <Col span={6}>

                    </Col>
                    <Col span={6}>

                    </Col>
                </Row>

            </Card>

        </div>

    )
}