import { MoneyCollectOutlined, PicCenterOutlined, PicRightOutlined } from '@ant-design/icons';
import { Card, Col, Row, Select, Space } from "antd";
import { Color } from "react-color";
import { CardItem } from "../Controls/LinkItem";


const { Option } = Select;
function IndexItem(props: { title: string, url: string, value: string, valuecolor: Color }) {
    const { title, url, value, valuecolor } = props;
    return (
        <a href={url}>
            <Space>
                <MoneyCollectOutlined />
                <div>
                    <div style={{ fontWeight: 'normal', color: 'black' }}>{title}</div>
                    <div style={{ fontWeight: 'bolder', color: `${valuecolor}` }}>{value}</div>
                </div>
            </Space>
        </a>

    )
}

export default function SaleReportView() {

    return (
        <>
            <Card title="Báo cáo doanh thu" size="small">
                <Row>
                    <Col span={6}>
                        <CardItem title="Tổng hợp bán hàng"
                            icon={<PicCenterOutlined />}
                            desc="Tổng hợp mua bán các sản phẩm"
                            url="sale/summary" />
                    </Col>
                    <Col span={6}>
                        <CardItem title="Chi tiết bán hàng"
                            icon={<PicRightOutlined />}
                            desc="Theo dõi các khoản thu chi của cửa hàng"
                            url="sale/detail" />
                    </Col>

                </Row>
            </Card>

            <Card title="Báo cáo tình trạng đơn hàng" size="small">
                <Row>

                    <Col span={6}>
                        <CardItem title="Tình hình thực hiện đơn hàng"
                            icon={<PicRightOutlined />}
                            desc="Theo dõi các khoản thu chi của cửa hàng"
                            url="sale/processing" />
                    </Col>

                    <Col span={6}>
                        <CardItem title="Báo cáo giao hàng"
                            icon={<PicRightOutlined />}
                            desc="Theo dõi các khoản thu chi của cửa hàng"
                            url="sale/ship" />
                    </Col>

                    <Col span={6}>
                        <CardItem title="Báo cáo trả hàng"
                            icon={<PicRightOutlined />}
                            desc="Theo dõi các khoản thu chi của cửa hàng"
                            url="sale/return" />
                    </Col>

                </Row>
            </Card>

            <Card title="Báo cáo thanh toán" size="small">
                <Row>
                    <Col span={6}>
                        <CardItem title="Phương thức thanh toán"
                            icon={<PicRightOutlined />}
                            desc="Tổng hợp bán hàng theo các phương thức thanh toán"
                            url="sale/payment" />
                    </Col>
                    <Col span={6}>
                        <CardItem title="Báo cáo công nợ phải thu "
                            icon={<PicRightOutlined />}
                            desc="Theo dõi các khoản tiền còn nợ lại của khách hàng"
                            url="sale/customer_debt" />
                    </Col>
                </Row>
            </Card>

        </>
    )
}