
import { Button, DatePicker, Form, Input, InputNumber, Modal } from "antd";
import _ from "lodash";
import moment from 'moment';
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IVoucherItem, VoucherModalAction } from "../../../common/type-define";
import { useSelectorRoot } from "../../../redux/configure/store";
import { getVoucherCreateModalVis } from "../../../redux/selectors/voucher.selector";
import { createVoucher, setProductsByVoucher, setVoucherCreateModalVis, updateVoucher, updateVoucherProducts } from "../../../redux/slices/voucher.slice";
import VoucherAssign from "./VoucherAssign";

const { RangePicker } = DatePicker;

const DATE_FORMAT = 'DD/MM/YYYY hh:mm:ss';
const FORM_FIELDS = {
    name: 'name',
    date: 'date',
    code: 'code',
    saleOff: 'saleOff'
}

const VoucherCreateModalComponent = (): JSX.Element => {
    const [form] = Form.useForm();
    const voucherCreateModalVis = useSelectorRoot(getVoucherCreateModalVis);
    const [assignProducts, setAssignProducts] = useState<string[]>([])
    const dispatch = useDispatch();

    useEffect(() => {
        if(voucherCreateModalVis){
            const initData = handleSetInitValue(voucherCreateModalVis);
            initData && form.setFieldsValue(initData);
            return;
        }else{
            handleResetFields();
            dispatch(setProductsByVoucher([]));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [voucherCreateModalVis])

    const handleResetFields = () => {
        form.resetFields();
    }

    const handleOnCloseModal = () => {
        dispatch(setVoucherCreateModalVis(null));
    }

    const handleOnSubmitVoucher = () => {
        const actionUpdate = voucherCreateModalVis?.data;
        
        const formValue = form.getFieldsValue();
        const newVoucher: IVoucherItem = {
            name: formValue[FORM_FIELDS.name] as string,
            code: formValue[FORM_FIELDS.code] as string,
            startTime: moment(formValue[FORM_FIELDS.date][0]).toISOString(),
            endTime: moment(formValue[FORM_FIELDS.date][1]).toISOString(),
            saleOff: formValue[FORM_FIELDS.saleOff] as number,
        };
        if(actionUpdate){
            let cloneVoucherResponse: any = _.cloneDeep(actionUpdate);
            delete cloneVoucherResponse.id;
            const isFieldUnchange = _.isEqual(cloneVoucherResponse, newVoucher);
            if(isFieldUnchange){
                handleOnCloseModal();
            }else{
                dispatch(updateVoucher({
                    id: actionUpdate.id,
                    requestData: newVoucher,
                }));
            }
            dispatch(updateVoucherProducts({
                voucherId: actionUpdate.id,
                updateProducts: {
                    productIds: assignProducts,
                }
            }))
        }else{
            dispatch(createVoucher({voucherData: newVoucher, assignProducts}));
        }
    }

    const handleSetInitValue = (voucherData: VoucherModalAction) => {
        if(!voucherData?.data) return undefined;
        const {name, code, startTime, endTime, saleOff } = voucherData.data;
        const result = {
            [FORM_FIELDS.name]: name,
            [FORM_FIELDS.code]: code,
            [FORM_FIELDS.date]: [moment(startTime), moment(endTime)],
            [FORM_FIELDS.saleOff]: saleOff,
        }
        return result;
    }

    return (
        <Modal 
            title={voucherCreateModalVis?.data ? "Cập nhật Voucher" : "Tạo Voucher"}
            visible={!!voucherCreateModalVis}
            maskClosable={false}
            destroyOnClose
            onCancel={handleOnCloseModal}
            footer={false}
            style={{userSelect: 'none', minWidth: '800px'}}>
            <Form 
                form={form}
                layout={"vertical"}
                onFinish={handleOnSubmitVoucher}>
                <div 
                    style={{
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'space-between'
                    }}>
                    <div 
                        style={{
                            flexBasis: '33%', 
                            flexGrow: 1, 
                            padding: '8px 4px'
                        }}>
                        <Form.Item   
                            label="Tên Voucher"
                            name={FORM_FIELDS.name}
                            rules={[{ required: true, message: 'Hãy nhập tên voucher' }]}>
                            <Input/>
                        </Form.Item>
                    </div>
                    <div 
                        style={{
                            flexBasis: '33%', 
                            flexGrow: 1, 
                            padding: '8px 4px'
                        }}>
                        <Form.Item 
                            label="Mã KM"
                            name={FORM_FIELDS.code} 
                            rules={[{ required: true, message: 'Hãy nhập mã KM' }]}>
                            <Input/>
                        </Form.Item>
                    </div>
                    <div 
                        style={{
                            flexBasis: '25%', 
                            flexGrow: 1, 
                            padding: '8px 4px'
                        }}>
                        <Form.Item 
                            label="Giá KM"
                            name={FORM_FIELDS.saleOff} 
                            rules={[{ required: true, message: 'Hãy nhập giá KM' }]}>
                           <InputNumber
                                addonAfter={<span>₫</span>}
                                className="input-number"
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                parser={value => value?.replace(/\$\s?|(\.*)/g, '') as any}
                            />
                        </Form.Item>
                    </div>
                    
                </div>
                <div style={{ padding: '8px 4px'}}>
                    <Form.Item 
                        label="Chọn ngày"
                        name={FORM_FIELDS.date} 
                        rules={[{ required: true, message: 'Hãy chọn ngày bắt đầu và kết thúc' }]}>
                        <RangePicker 
                            format={DATE_FORMAT}
                            showTime 
                            placeholder={["Ngày bắt đầu", "Ngày kết thúc"]}
                            style={{
                                width: '100%'}}/>
                    </Form.Item>
                   
                </div>
                <VoucherAssign 
                    setAssignProducts={setAssignProducts}
                    voucherId={voucherCreateModalVis?.data?.id}/>
                <Form.Item style={{marginBottom: '0px', marginTop: '40px'}}>
                    <div style={{display:'flex', justifyContent:'flex-end'}}>
                        <Button 
                            type="default" 
                            style={{margin: 'auto 4px'}}
                            onClick={handleOnCloseModal}>
                            Đóng
                        </Button>
                        <Button type="primary" htmlType="submit">
                            {voucherCreateModalVis?.data ? 'Cập nhật' : 'Tạo Voucher'}
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </Modal>
    )
}

const VoucherCreateModal = React.memo(VoucherCreateModalComponent);
export default VoucherCreateModal