import { useEffect, useState } from "react";
import styled from "styled-components"
import { SketchPicker, Color, ColorResult, RGBColor } from 'react-color'

type state = {
    displayColorPicker: boolean,
    color: RGBColor,

};

interface ISelectFunction {
    (value: ColorResult): void;
}

type PickerColorProps = {
    color?: RGBColor,
    ColorChanged?: ISelectFunction,
}

function ColorPickerComponent(props: PickerColorProps) {
    const { color, ColorChanged } = props;
    const [statePallet, setStatePallet] = useState<state>({
        displayColorPicker: false,
        color: color ? color : { r: 241, g: 112, b: 19, a: 1 }
    });
    useEffect(() => {

    }, []);
    const handleClick = () => {
        setStatePallet({ displayColorPicker: !statePallet.displayColorPicker, color: statePallet.color })
    };

    const handleClose = () => {
        setStatePallet({ displayColorPicker: false, color: statePallet.color })
    };

    //Change color
    const handleChange = (newcolor: ColorResult) => {
        setStatePallet({ displayColorPicker: false, color: newcolor.rgb });
        //If event is asign then will be call

        if (ColorChanged != null)
            ColorChanged(newcolor);
    };

    const SwatchDiv = styled.div`
        padding: 2px;
        background: #001529;
        borderRadius: 3px;
        boxShadow: 0 0 0 1px rgba(0,0,0,.1);
        display: inline-block;
        cursor: pointer;
    `
    const ColorDiv = styled.div`
        width: 20px;
        height: 20px;
        borderRadius: 5px;
        background: rgba(${statePallet.color.r},${statePallet.color.g},${statePallet.color.b},${statePallet.color.a})  
    `

    const PopoverDiv = styled.div`
        position: absolute;
        zIndex: 100;
    `

    const CoverDiv = styled.div`
    position: fixed;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
    `

    return (
        <div style={{ zIndex: 100 }}>
            <SwatchDiv onClick={handleClick}>
                <ColorDiv />
            </SwatchDiv>
            {statePallet.displayColorPicker ? <PopoverDiv >
                <CoverDiv onClick={handleClose} />
                <SketchPicker color={statePallet.color} onChange={handleChange} />
            </PopoverDiv> : null}

        </div>
    )
}


export default ColorPickerComponent;