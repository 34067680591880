import { Button, message, Space, Spin, Table, Tooltip, Tag, Tabs } from "antd";
import { ColumnsType } from "antd/lib/table";
import Link from "antd/lib/typography/Link";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DefectProductResponse, } from "../../Model/ProductModel";
import { setLoading } from "../../redux/slices/report.slice";
import { getHeightFit } from "../Helper/UIHelper";
import DefectProductOrderView from "./DefectProductOrder";

import {
    SearchOutlined,
    ToolOutlined,
    SnippetsOutlined,
    RollbackOutlined,
    CheckCircleOutlined,
    FileExcelOutlined
} from '@ant-design/icons';
import ReturnProductToProviderModal from "./ReturnProductToProviderModal";
import CreateDefectProductOrderModal from "./CreateDefectProductOrderModal";
import CreateDefectProductModal from "./CreateDefectProductModal";
import DefectProductAccessoryOrderModal from "./DefectProductAccessoryOrderModal";
import ReturnDefectProductToStoreModal from "./ReturnProductToStoreModel";
import { LeftRightHeaderContent } from "../Controls/LeftRightHeaderContent";
import { DefectProductApi } from "../../Api/DefectProductApi";
import HistoryModal, { HistoryItem } from "../Helper/HistoryModal";
import { DefectProductResponseStatus, DefectProductStatusUpdate } from "../../common/type-define";
const { TabPane } = Tabs;
function DefectProductsView() {

    const [defectProducts, setDefectProducts] = useState<DefectProductResponse[]>([]);
    const [loading, setLoading] = useState(true);
    const [tabActive, setTabActive] = useState<number>(0);
    const [returnProviderModal, setReturnProviderModal] = useState(false);
    const [returnProductToStoreModal, setReturnProductToStoreModal] = useState(false);
    const [createDefectModal, setCreateDefectModal] = useState(false);
    const [activeDefectProduct, setActiveDefectProduct] = useState<DefectProductResponse>();
    const [createDefectProductOrderModalVis, setcreateDefectProductOrderModalVis] = useState<boolean>(false)
    const [defectProductAccessoryOrderVis, setDefectProductAccessoryOrderVis] = useState<boolean>(false);
    const [histortItems, setHistoryItems] = useState<HistoryItem[]>([]);
    const [showHistoryModal, setShowHistoryModal] = useState<boolean>(false);

    const navigate = useNavigate();

    useEffect(() => {

        async function fetch() {
            const res = await DefectProductApi.DefectProductsApi();

            if (res?.status === 200) {
                setDefectProducts(res.data.items);
                setLoading(false);
            }
            else {

            }

        }

        fetch();

    }, [])

    const onClickDefectProductCode = (id: string) => {
        DefectProductApi.GetProductDefectByIdApi(id, () => { }).then((res) => {
            if (res?.status === 200) {

                const defectData: DefectProductResponse = res.data;
                const defectHistories = defectData.histories.sort((a, b) =>
                    new Date(a.createdTime).getTime() - new Date(b.createdTime).getTime()
                );
                let histories: HistoryItem[] = [];
                for (let index = 0; index < defectHistories.length; index++) {
                    const element = defectHistories[index];
                    const code = element.statusCode;
                    const reason = element.reason;
                    let des: string = `${code} - ${reason} -${element.content}`;
                    if (code === DefectProductResponseStatus.Inquiry.valueOf()) {
                        des = "Yêu cầu sản phẩm lỗi"
                    } else if (code === DefectProductResponseStatus.Fixing.valueOf()) {
                        des = "Đang sửa chữa"
                    } else if (code === DefectProductResponseStatus.Fixed.valueOf()) {
                        des = "Đã sửa chữa"
                    } else if (code === DefectProductResponseStatus.Imported.valueOf()) {
                        des = "Trả kho tiếp tục kinh doanh"
                    } else if (code === DefectProductResponseStatus.Blacklisted.valueOf()) {
                        des = "Không sửa được bung máy lấy phụ kiện"
                    } else if (code === DefectProductResponseStatus.Returned.valueOf()) {
                        des = "Trả lại nhà cung cấp"
                    }
                    histories.push({
                        actorName: element.actorName,
                        time: element.createdTime,
                        description: des
                    })
                }

                setHistoryItems(histories);

                setShowHistoryModal(true);

            }
        })
    }

    const ViewDefectContent = (record: DefectProductResponse) => {
        return () => {
            message.info(JSON.stringify(record));
        }
    }

    //Reload lại nội dung cho
    async function updateRecordStatus() {
        setLoading(true);
        const res = await DefectProductApi.DefectProductsApi();

        if (res?.status === 200) {
            setDefectProducts(res.data.items);
            setLoading(false);
        }
    }

    const techinalUpdateDefectProductStatus = (status: number, record: DefectProductResponse) => {
        return () => {
            DefectProductApi.TechnicalUpdateStatus(record.id, { ProductDefectStatus: status }, (res: any) => {
                if (status === DefectProductStatusUpdate.Fixing.valueOf())
                    message.info("Kỹ thuật viên đã tiến hành sửa chữa");
                else if (status === DefectProductStatusUpdate.Fixed.valueOf())
                    message.info("Kỹ thuật viên đã sửa sản phẩm");
                else if (status === DefectProductStatusUpdate.Blacklisted.valueOf())
                    message.info("Kỹ thuật viên dã đưa sản phẩm lỗi vào black list");

                updateRecordStatus();
            })
        }
    }

    const returnProvider = (record: DefectProductResponse) => {
        return () => {
            DefectProductApi.ReturnProductDefectToProviderApi(record.id, () => {
                message.info("Sản phẩm lỗi đã được trả lại nhà cung cấp")
                updateRecordStatus();
            })
        }
    }

    const returnDefectProductToStoreInvoke = (record: DefectProductResponse) => {
        return () => {
            setActiveDefectProduct(record)
            setReturnProductToStoreModal(true)
        }
    }

    //Điều khiển việc ẩn hiện các hành động tương ứng với trạng thái của phiếu bảo hành
    function getControlAction(record: DefectProductResponse): JSX.Element {

        //Tiếp nhận --> có thể bắt đầu sửa chữa
        if (record.status === DefectProductResponseStatus.Inquiry.valueOf()) {
            return (
                <Space direction="horizontal">
                    <Tooltip title="Đưa vào black list" color="green" >
                        <FileExcelOutlined onClick={techinalUpdateDefectProductStatus(DefectProductStatusUpdate.Blacklisted.valueOf(), record)} />
                    </Tooltip>

                    <Tooltip title="Bắt đầu sửa" color="green">
                        <ToolOutlined onClick={techinalUpdateDefectProductStatus(DefectProductStatusUpdate.Fixing.valueOf(), record)} />
                    </Tooltip>

                    {/* <SearchOutlined onClick={ViewDefectContent(record)} /> */}
                </Space>

            )
        }
        //Đang xử lý : có quyến request 1 phiếu xuất hoặc hoàn thành 
        else if (record.status === DefectProductResponseStatus.Fixing.valueOf()) {
            return (
                <Space direction="horizontal">
                    <Tooltip title="Tạo đơn xuất linh kiện" color="green">
                        <SnippetsOutlined onClick={() => {
                            setActiveDefectProduct(record)
                            setcreateDefectProductOrderModalVis(true)
                        }} />
                    </Tooltip>
                    <Tooltip title="Hoàn thành sửa chữa" color="green">
                        <CheckCircleOutlined onClick={techinalUpdateDefectProductStatus(DefectProductStatusUpdate.Fixed.valueOf(), record)}
                            style={{ color: "green" }} />
                    </Tooltip>
                    <Tooltip title="Đưa vào black list" color="green">
                        <FileExcelOutlined onClick={techinalUpdateDefectProductStatus(DefectProductStatusUpdate.Blacklisted.valueOf(), record)} />
                    </Tooltip>
                </Space>
            )

        }
        //Hoàn thành sửa chữa
        else if (record.status === DefectProductResponseStatus.Fixed.valueOf()) {

            return (
                <Space direction="horizontal">
                    <Tooltip title="Nhập lại kho để kinh doanh" color="green">
                        <RollbackOutlined onClick={returnDefectProductToStoreInvoke(record)} />
                    </Tooltip>
                    {/* <SearchOutlined onClick={ViewDefectContent(record)} /> */}
                </Space>
            )
        }
        else if (record.status === DefectProductResponseStatus.Blacklisted.valueOf()) {
            return (
                <Space direction="horizontal">
                    <Tooltip title="Bóc máy" color="green">
                        <ToolOutlined onClick={() => {
                            setActiveDefectProduct(record)
                            setDefectProductAccessoryOrderVis(true)
                        }} />
                    </Tooltip>
                    <Tooltip title="Trả nhà cung cấp" color="green">
                        <RollbackOutlined onClick={returnProvider(record)} />
                    </Tooltip>
                </Space>

            )
        }

        return (<div></div>)
    }

    function getStatus(record: DefectProductResponse): JSX.Element {
        if (record.status === DefectProductResponseStatus.Inquiry.valueOf()) {
            return (<Tag color="yellow" >Tiếp nhận</Tag>);
        }
        else if (record.status === DefectProductResponseStatus.Fixing.valueOf()) {
            return (<Tag color="yellowgreen">Đang sửa</Tag>);
        }
        else if (record.status === DefectProductResponseStatus.Fixed.valueOf()) {
            return (<Tag color="green">Đã sửa</Tag>);
        }
        else if (record.status === DefectProductResponseStatus.Imported.valueOf()) {
            return (<Tag color="blue">Tiếp tục kinh doanh</Tag>);
        }
        else if (record.status === DefectProductResponseStatus.Returned.valueOf()) {
            return (<Tag color="blue">Trả nhà cung cấp</Tag>);
        }
        else if (record.status === DefectProductResponseStatus.Blacklisted.valueOf()) {
            return (<Tag color="red">Không thể sửa</Tag>);
        }
        else if (record.status === DefectProductResponseStatus.RemoveAccessory.valueOf()) {
            return (<Tag color="red">Đã tháo máy</Tag>);
        }
        return (<Tag color="#e4e669">Chờ duyệt</Tag>);
    }

    const columns: ColumnsType<DefectProductResponse> = [
        {
            title: 'Mã sản phẩm',
            dataIndex: 'imei',
            key: 'imei',
            width: '15%',
            render: (imei, record) => (
                <Link onClick={() => onClickDefectProductCode(record.id)} >
                    <span>{imei}</span>
                </Link>
            )
        },
        {
            title: 'Tên sản phẩm',
            dataIndex: 'name',
            key: 'id',
            width: '25%',
            render: (id, record) => (
                <span>{record.productVersion.fullName}</span>
            )
        },
        {
            title: 'Trạng thái',
            width: '15%',
            dataIndex: 'status',
            key: 'status',
            render: (status, record: DefectProductResponse) => (
                <div>{getStatus(record)}</div>
            )
        },
        {
            title: 'Hành động',
            key: 'id',
            width: '15%',
            render: (_: any, record: DefectProductResponse) => (
                getControlAction(record)
            )
        },
    ];

    const onChangetab = (activeKey: string) => {
        switch (activeKey) {
            case '1':
                setTabActive(1);
                break;
            case '2':
                setTabActive(2);
                break;
        }
    }

    return (
        <>
            <Tabs defaultActiveKey="1" onChange={onChangetab}>
                <TabPane key="1" tab="Sản phảm lỗi">
                    <LeftRightHeaderContent rightContent={<Button type="primary"
                        onClick={() => setCreateDefectModal(true)} size="middle">
                        Thêm sản phẩm lỗi
                    </Button>}
                        leftContent={<div></div>}
                    ></LeftRightHeaderContent>
                    <div>
                        <Spin spinning={loading} tip="đang tải...">
                            <Table
                                columns={columns}
                                dataSource={defectProducts}
                                pagination={{ pageSize: 20 }}

                                scroll={{ x: '1000px', y: getHeightFit(250) }}
                            />
                        </Spin>
                    </div>
                </TabPane>
                <TabPane key="2" tab="Phiếu xuất kho">
                    <DefectProductOrderView isActived={tabActive === 2} />
                </TabPane>
            </Tabs>


            <ReturnProductToProviderModal title="Trả lại nhà cung cấp"
                onCancel={() => { setReturnProviderModal(false) }}
                visible={returnProviderModal} />


            <CreateDefectProductModal title="Thêm sản phẩm lỗi"
                onCancel={() => { setCreateDefectModal(false) }}
                CreatedFunc={() => {
                    setCreateDefectModal(false)
                    updateRecordStatus();
                }}
                visible={createDefectModal} />

            <ReturnDefectProductToStoreModal title="Nhập sản phẩm đã sửa về kho"
                defectProduct={activeDefectProduct}
                returnDefectProductToStoreSucessHandle={() => {
                    setReturnProductToStoreModal(false)
                    updateRecordStatus()
                }}
                onCancel={() => { setReturnProductToStoreModal(false) }}
                visible={returnProductToStoreModal} />

            <CreateDefectProductOrderModal title="Tạo phiếu xuất linh kiện"
                CreatedFunc={() => setcreateDefectProductOrderModalVis(false)}
                defectProduct={activeDefectProduct}
                onCancel={() => { setcreateDefectProductOrderModalVis(false) }}
                visible={createDefectProductOrderModalVis} />

            <DefectProductAccessoryOrderModal title="Tạo phiếu nhập linh kiện bóc máy"
                importAccessoryOrderHandler={() => {
                    setDefectProductAccessoryOrderVis(false)
                    updateRecordStatus();
                }}
                defectProduct={activeDefectProduct}
                onCancel={() => { setDefectProductAccessoryOrderVis(false) }}
                visible={defectProductAccessoryOrderVis} />

            <HistoryModal
                visible={showHistoryModal}
                footer={false}
                onCancel={() => setShowHistoryModal(false)}
                histories={histortItems}
                title="Lịch sử sửa máy" />

        </>
    )
}

export default DefectProductsView;