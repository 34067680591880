
import axios from "axios";
import { CommonHelper } from "../../Helper/CommonHelper";
import { AuthenResponse } from "../../Model/LoginModel";
import { SERVER_AUTHEN } from "../UrlDefination";



export class AxiosRequest {
    static TOKEN_CALL: Promise<string | null> | null = null;

    static async getLatestToken(): Promise<string | null> {
        const isTokenValid = CommonHelper.validToken();
        const token = localStorage.getItem('token');
        if (isTokenValid)
            return new Promise((resolve, reject) => resolve(token));
        const refreshToken = localStorage.getItem('refreshToken');
        const requestData = { token, refreshToken };
        const newTokenResponse = await axios.post<AuthenResponse>(`${SERVER_AUTHEN.HOST}/${SERVER_AUTHEN.AUTHEN.TOKEN}`, requestData);
        if (newTokenResponse.status !== 200 || !newTokenResponse?.data) {

            return null;
        }

        localStorage.setItem('token', newTokenResponse.data.token);
        localStorage.setItem('refreshToken', newTokenResponse.data.refreshToken);
        return new Promise((resolve, reject) => resolve(newTokenResponse.data.token));
    }

    static async getConfig() {
        let latestToken: string | null = null;
        if (this.TOKEN_CALL) {
            latestToken = await this.TOKEN_CALL;
        } else {
            const callPromise = this.getLatestToken();
            this.TOKEN_CALL = callPromise;
            latestToken = await callPromise;
        }
        this.TOKEN_CALL = null;
        return {
            Accept: "application/json, text/plain, */*",
            headers: { Authorization: `Bearer ${latestToken}` }
        }
    }

    static async get(url: string): Promise<unknown> {
        const config = await this.getConfig();
        return axios.get(url, config);
    }

    static async post(url: string, data?: unknown, requireToken = true): Promise<unknown> {
        const config = requireToken ? await this.getConfig() : {};
        return axios.post(url, data, config);
    }

    static async put(url: string, data?: unknown): Promise<unknown> {
        const config = await this.getConfig();
        return axios.put(url, data, config);
    }
    static async delete(url: string): Promise<unknown> {
        const config = await this.getConfig();
        return axios.delete(url, config);
    }
}