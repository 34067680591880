import { AxiosResponse } from "axios";
import { AxiosRequest } from "../Axios/AxiosRequest";
import { RequestHandler } from "../RequestAPI";
import { SERVER_API } from "../UrlDefination";

export class MaintenanceReportApi
{
    static async GetMaintenanceReportApi(from?: Date, to?: Date): Promise<AxiosResponse<IMaintenanceReport[], any>> {
        let query = '';
        if (from && to)
            query = `QueryFrom=${from.toUTCString()}&QueryTo=${to.toUTCString()}`;
        return RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.REPORT.GET_MAINTENANCE_REPORTS}?${query}`));
    }

    static async GetScheduleMaintenanceReportApi(from?: Date, to?: Date): Promise<AxiosResponse<IScheduleMaintenanceReport[], any>> {
        let query = '';
        if (from && to)
            query = `QueryFrom=${from.toUTCString()}&QueryTo=${to.toUTCString()}`;
        return RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.REPORT.GET_SCHEDULE_MAINTENANCE_REPORTS}?${query}`));
    }
}