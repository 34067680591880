import { Empty, Typography } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CustomerApi } from "../../Api/CustomerApi";
import { CustomerTypeEnum } from "../../common/type-define";
import { CustomerRes } from "../../Model/CustomerModel";
import { setCommissionCustomer } from "../../redux/slices/customer.slice";
import AddCommissionModal from "./AddCommissionModal";

const { Link } = Typography;

const AddPartnerComponent = (): JSX.Element => {
    const dispatch = useDispatch();
    const [customerList, setCustomerList] = useState<CustomerRes[]>([])
    const [refresh, setRefresh] = useState<boolean>(false);
    useEffect(() => {
        async function fetchPartnerCustomer() {
            const res = await CustomerApi.getCustomer([CustomerTypeEnum.Loyal]);
            if (res?.status === 200) {
                const result = res.data.items.filter(v => !v.commissionCode);
                setCustomerList(result);
            }
        }

        fetchPartnerCustomer()
    }, [refresh])

    const handleAddPartner = (record: CustomerRes) => {
        dispatch(setCommissionCustomer(record));
    }

    const handleRefresh = () => {
        setRefresh(pre => !pre);
    }

    const columns: ColumnsType<CustomerRes> = [
        {
            title: 'Tên đầy đủ',
            dataIndex: 'fullName',
            key: 'fullName',
            width: '30%',
            sorter: (a: CustomerRes, b: CustomerRes) => a.fullName > b.fullName ? -1 : 1,
            render: (fullName: string, record: CustomerRes) => (
                <Link onClick={() => handleAddPartner(record)}>
                    <span>{fullName}</span>
                </Link>
            )
        },
        {
            title: 'Số điện thoại',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            width: '25%',
            sorter: (a: CustomerRes, b: CustomerRes) => a.phoneNumber > b.phoneNumber ? -1 : 1,
        },
        {
            title: 'Địa chỉ',
            dataIndex: 'address',
            key: 'address',
            width: '25%',
            sorter: (a: CustomerRes, b: CustomerRes) => a.address > b.address ? -1 : 1,
        },
        {
            title: 'Hành động',
            dataIndex: 'action',
            key: 'action',
            width: '20%',
            render: (_: any, record: CustomerRes) => (
                <Link onClick={() => handleAddPartner(record)}>
                    Tạo mã hoa hồng
                </Link>
            )
        },
    ]

    return (
        <>
            <Table
                columns={columns}
                dataSource={customerList}
                pagination={{ pageSize: 20 }}
                rowKey="id"
                locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_DEFAULT} description="Không tìm thấy khách hàng nào." /> }}
                scroll={{ x: 800, y: 'calc(100vh - 200px)' }} />
            <AddCommissionModal handleRefresh={handleRefresh} />
        </>
    )
}

const AddPartner = React.memo(AddPartnerComponent);
export default AddPartner;