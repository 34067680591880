import { DatePicker, Space, Table, Spin } from "antd";
import { conceptItem } from "../../Helper/ConceptModal";
import { SaleReportApi } from "../../../Api/Reports/SaleReportApi";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { subDays, subMonths } from "date-fns";
import { ColumnsType } from "antd/lib/table";
import { CommonHelper } from "../../../Helper/CommonHelper";
import { OrderStatus } from "../../../common/type-define";
import { format } from 'date-fns';
import { PurchaseReportApi } from "../../../Api/Reports/PurchaseReportApi";
import { PurchaseStatus } from "../../../common/type-define";
import { BillApi } from "../../../Api/BillsApi";
import { WarrantyOrderReportApi } from "../../../Api/Reports/WarrantyOrderReportApi"
import { WarrantyOrderStatus } from "../../../common/type-define";

import { MathHelper } from "../../../Helper/math.helper";
import { headerStyle } from "../../Helper/UIHelper";
const { RangePicker } = DatePicker;
const remainConcepts: conceptItem[] = [
    {
        name: "Tổng thu",
        meaning: "Là số tiền cửa hàng thu về trong khoảng ngày lọc Ví dụ: Thời gian được lọc từ ngày 02/11/2020 - 15/11/2020 (Ngày ghi nhận hoặc Ngày tạo) -> Tổng thu là số tiền tính của hàng thu được trong thời gian từ 02/11/2020 - 15/11/2020"
    },
    {
        name: "Tổng chi",
        meaning: "Tổng giá trị của các sản phẩm tồn kho. Giá trị tồn kho của 1 sản phẩm = Giá vốn * Số lượng tồn kho Ví dụ: Trong kho còn tồn 50 sản phẩm A, giá vốn của sản phẩm A = 150,000đ -> Giá trị tồn kho của sản phẩm A = 150,000 * 50 = 7,500,000đ"
    },
    {
        name: "Tồn quỹ",
        meaning: "= [Giá trị tồn kho của chi nhánh (hoặc tổng nhiều chi nhánh) / Giá trị tồn kho toàn hệ thống] * 100%"
    }
]

interface ReportItem {
    order: number,
    level: number,
    name: string,
    prev: number,
    current: number,
    rate: number
}


export default function FinanceReportView() {

    const [showConcept, setShowConcept] = useState<boolean>(false);
    const [startDateCurPeriod, setStartDateCurPeriod] = useState<Date>(subDays(new Date(), 7));
    const [endDateCurPeriod, setEndDateCurPeriod] = useState<Date>(new Date());

    const [endDatePrevPeriod, setEndDatePrevPeriod] = useState<Date>(subDays(startDateCurPeriod, 1));
    const [startDatePrevPeriod, setStartDatePrevPeriod] = useState<Date>(subDays(endDatePrevPeriod, 7));

    const [endDatePrevPeriodFormat, setEndDatePrevPeriodFormat] = useState<string>(format(new Date(endDatePrevPeriod), "dd-MM-yyyy"));
    const [startDatePrevPeriodFormat, setStartDatePrevPeriodFormat] = useState<string>(format(new Date(startDatePrevPeriod), "dd-MM-yyyy"));
    const [startDateCurPeriodFormat, setStartDateCurPeriodFormat] = useState<string>(format(new Date(startDateCurPeriod), "dd-MM-yyyy"));
    const [loading, setLoading] = useState<boolean>(false)

    const [reportItems, setReportItems] = useState<ReportItem[]>([]);

    useEffect(() => {
        let items: ReportItem[] = [];
        items.push({
            order: 1,
            level: 0,
            name: "I. Doanh thu bán hàng",
            prev: 0,
            current: 0,
            rate: 1
        });
        items.push({
            order: 2,
            level: 1,
            name: "1. Tiền hàng thực bán (1a - 1b)",
            prev: 0,
            current: 0,
            rate: 1
        });

        items.push({
            order: 3,
            level: 2,
            name: "a. Tiền hàng bán ra",
            prev: 0,
            current: 0,
            rate: 1
        });
        items.push({
            order: 4,
            level: 2,
            name: "b. Tiền hàng trả lại",
            prev: 0,
            current: 0,
            rate: 1
        });

        items.push({
            order: 5,
            level: 1,
            name: "3. Phí giao hàng thu của khách",
            prev: 0,
            current: 0,
            rate: 1
        });

        items.push({
            order: 6,
            level: 0,
            name: "II. Chi phí bán hàng (1+2)",
            prev: 0,
            current: 0,
            rate: 1
        });
        items.push({
            order: 7,
            level: 1,
            name: "1. Chi phí giá vốn hàng hóa",
            prev: 0,
            current: 0,
            rate: 1
        });
        items.push({
            order: 8,
            level: 1,
            name: "2. Phí giao hàng trả đối tác",
            prev: 0,
            current: 0,
            rate: 1
        });

        items.push({
            order: 9,
            level: 0,
            name: "III. Thu nhập khác (1+2)",
            prev: 0,
            current: 0,
            rate: 1
        });

        items.push({
            order: 10,
            level: 1,
            name: "1. Thu nhập phí",
            prev: 0,
            current: 0,
            rate: 1
        });

        items.push({
            order: 11,
            level: 1,
            name: "2. Phí khách trả",
            prev: 0,
            current: 0,
            rate: 1
        });

        items.push({
            order: 12,
            level: 0,
            name: "IV. Chi phí khác",
            prev: 0,
            current: 0,
            rate: 1
        });

        items.push({
            order: 13,
            level: 0,
            name: "Lợi nhuận (I + III - II - IV)",
            prev: 0,
            current: 0,
            rate: 1
        });

        fetchData(items);

    }, [])

    async function fetchData(items: ReportItem[]) {
        try {
            setLoading(true);
            const duration = CommonHelper.calculateDiffDayDuration(startDateCurPeriod, endDateCurPeriod);
            const endDatePrev = duration > 0 ? subDays(startDateCurPeriod, 1) : startDateCurPeriod;
            const startDatePrev = subDays(endDatePrev, duration);
            setStartDatePrevPeriod(startDatePrev);
            setEndDatePrevPeriod(endDatePrev);
            setEndDatePrevPeriodFormat(format(endDatePrev, "dd-MM-yyyy"))
            setStartDatePrevPeriodFormat(format(startDatePrev, "dd-MM-yyyy"))
            const query = {
                QueryFrom: startDatePrev,
                QueryTo: endDateCurPeriod
            }

            await updateRevenue(query, items);
            await updateCostCapital(query, items);
            await updateOtherIncome(query, items);
            await updateExpense(query, items);



            var saleRevenue = items.find(p => p.order === 1)!;
            var otherRevenue = items.find(p => p.order === 9)!;
            var saleCost = items.find(p => p.order === 6)!;
            var otherCost = items.find(p => p.order === 12)!;
            var totalRevenue = items.find(p => p.order === 13)!;
            totalRevenue.current = saleRevenue.current + otherRevenue.current - saleCost.current - otherCost.current;
            totalRevenue.prev = saleRevenue.prev + otherRevenue.prev - saleCost.prev - otherCost.prev;
            totalRevenue.rate = calculateReportItemRate(totalRevenue)

            setReportItems(items);
        } catch (error) {

        } finally {
            setLoading(false);
        }

    }

    async function updateRevenue(query: QueryOrderReport, reportItems: ReportItem[]) {
        const orderData = await SaleReportApi.GetOrderReportsApi(query);
        const orderReturnData = await SaleReportApi.GetReturnOrderReportsApi(query);
        var revenue = reportItems.find(p => p.order === 1)!;
        var realSaleProductMoney = reportItems.find(p => p.order === 2)!;
        var saleProductMoney = reportItems.find(p => p.order === 3)!;
        var returnProductMoney = reportItems.find(p => p.order === 4)!;
        var deliveryCharges = reportItems.find(p => p.order === 5)!;
        if (orderData.status === 200 && orderReturnData.status === 200) {
            const orders = orderData.data;
            const returnOrders = orderReturnData.data;
            orders.forEach(order => {
                const orderTimeFormat = format(new Date(order.orderTime), "dd-MM-yyyy")
                if (order.orderStatus !== OrderStatus.Completed.valueOf()
                    && order.orderStatus !== OrderStatus.Activated.valueOf()) return;
                var singleDistributeDiscount = order.productOrders.length > 0 ? order.discount / order.productOrders.length : 0
                order.productOrders.forEach(productOrder => {
                    const returnOrder = returnOrders.find(p => p.orderCode === order.orderCode);
                    const returnProductOrder = returnOrder?.productOrderReturns.find(p => p.imei === productOrder.imei);
                    if (returnOrder && returnProductOrder &&
                        (returnOrder.status === OrderStatus.Completed.valueOf() || returnOrder.status === OrderStatus.Activated.valueOf())) return

                    const productOrderDiscount = CommonHelper.calculateDiscount(productOrder.discount, productOrder.discountType, productOrder.salePrice);
                    var priceAfterDiscount = productOrderDiscount - productOrderDiscount;
                    priceAfterDiscount -= CommonHelper.calculateDiscount(singleDistributeDiscount, order.discountType, priceAfterDiscount);
                    if (orderTimeFormat <= endDatePrevPeriodFormat) {
                        saleProductMoney.prev += priceAfterDiscount
                    }
                    else {
                        saleProductMoney.current += priceAfterDiscount
                    }
                })
                if (order.shippingDetail) {
                    deliveryCharges.prev += orderTimeFormat <= endDatePrevPeriodFormat ? order.shippingDetail.shippingFee : 0;
                    deliveryCharges.current += orderTimeFormat >= startDateCurPeriodFormat ? order.shippingDetail.shippingFee : 0;
                }

            })
            returnOrders.forEach(order => {
                if (order.status !== OrderStatus.Completed.valueOf()) return;
                order.productOrderReturns.forEach(p => {
                    if (format(new Date(order.requestTime), "dd-MM-yyyy") <= endDatePrevPeriodFormat) {
                        returnProductMoney.prev += p.refund
                    }
                    else {
                        returnProductMoney.current += p.refund
                    }
                })
            })

            saleProductMoney.rate = calculateReportItemRate(saleProductMoney);
            returnProductMoney.rate = calculateReportItemRate(returnProductMoney);
            realSaleProductMoney.current = saleProductMoney.current - returnProductMoney.current;
            realSaleProductMoney.prev = saleProductMoney.prev - returnProductMoney.prev;
            realSaleProductMoney.rate = calculateReportItemRate(realSaleProductMoney);
            deliveryCharges.rate = calculateReportItemRate(deliveryCharges);
            revenue.current = realSaleProductMoney.current - deliveryCharges.current;
            revenue.prev = realSaleProductMoney.prev - deliveryCharges.prev;
            revenue.rate = calculateReportItemRate(revenue);
        }
    }

    async function updateCostCapital(query: QueryOrderReport, reportItems: ReportItem[]) {
        const purchaseReportResponse = await PurchaseReportApi.GetPurchaseReportsApi(query);
        if (purchaseReportResponse.status !== 200) return;
        const purchaseReports = purchaseReportResponse.data;
        var sellingCost = reportItems.find(p => p.order === 6)!;
        var purchaseImportPrice = reportItems.find(p => p.order === 7)!;
        var purchaseCarrierPrice = reportItems.find(p => p.order === 8)!;
        purchaseReports.forEach(p => {
            const orderTimeFormat = format(new Date(p.createdTime), "dd-MM-yyyy")
            if (p.purchaseStatus !== PurchaseStatus.Stored) return;
            var totalProductPrice = 0;
            var vatTax = 0;
            var importExportTax = 0;
            var discount = 0;
            p.purchaseProductVersions.forEach(prodVer => {
                vatTax += prodVer.quantities * prodVer.vat * prodVer.priceInput / 100;
                importExportTax += prodVer.quantities * prodVer.importAndExportTax * prodVer.priceInput / 100;
                totalProductPrice += prodVer.priceInput * prodVer.quantities;
                discount += CommonHelper.calculateDiscount(prodVer.discount, prodVer.discountType, prodVer.priceInput, prodVer.quantities);
            })
            const productMoneyAfterDiscount = totalProductPrice - discount;
            discount += CommonHelper.calculateDiscount(p.discount, p.discountType, productMoneyAfterDiscount);
            var totalProductPrice = totalProductPrice + importExportTax + vatTax - discount;
            purchaseImportPrice.prev += orderTimeFormat <= endDatePrevPeriodFormat ? totalProductPrice : 0;
            purchaseImportPrice.current += orderTimeFormat >= startDateCurPeriodFormat ? totalProductPrice : 0;
            if (p.purchaseCarrier) {
                var carrierFee = p.purchaseCarrier.price - p.purchaseCarrier.discount;
                purchaseCarrierPrice.prev += orderTimeFormat <= endDatePrevPeriodFormat ? carrierFee : 0;
                purchaseCarrierPrice.current += orderTimeFormat >= startDateCurPeriodFormat ? carrierFee : 0;
            }
        })
        purchaseImportPrice.rate = calculateReportItemRate(purchaseImportPrice);
        purchaseCarrierPrice.rate = calculateReportItemRate(purchaseCarrierPrice);
        sellingCost.current += purchaseImportPrice.current + purchaseCarrierPrice.current;
        sellingCost.prev += purchaseImportPrice.prev + purchaseCarrierPrice.prev;
        sellingCost.rate = calculateReportItemRate(sellingCost);
    }

    async function updateOtherIncome(query: QueryOrderReport, reportItems: ReportItem[]) {
        const res = await BillApi.GetIncomeBillsReportApi(query.QueryFrom, query.QueryTo);
        var totalOtherIncome = reportItems.find(p => p.order === 9)!;
        var extraIncome = reportItems.find(p => p.order === 10)!;
        var serviceIncome = reportItems.find(p => p.order === 11)!;
        if ((res?.status === 200)) {
            var bills = res.data;
            bills.forEach(bill => {
                const createdTime = format(new Date(bill.createdTime), "dd-MM-yyyy");
                extraIncome.prev += createdTime <= endDatePrevPeriodFormat ? bill.amount : 0;
                extraIncome.current += createdTime >= startDateCurPeriodFormat ? bill.amount : 0;
            })
            extraIncome.rate = calculateReportItemRate(extraIncome);
        }
        const queryForm = query.QueryFrom
        const queryTo = query.QueryTo
        var warrantyOrderQuery = {
            QueryFrom: queryForm,
            QueryTo: queryTo,
            Statuses: [WarrantyOrderStatus.Approved.valueOf(), WarrantyOrderStatus.Exported.valueOf()]
        }
        const warrantyOrderRes = await WarrantyOrderReportApi.GetWarrantyOrdersReportApi(warrantyOrderQuery);
        if (warrantyOrderRes?.status === 200) {
            const warrantyOrders = warrantyOrderRes.data;
            warrantyOrders.forEach(p => {
                if (p.status !== WarrantyOrderStatus.Approved &&
                    p.status !== WarrantyOrderStatus.Exported) return;
                const history = p.histories.find(x => x.statusCode === p.status);
                if (!history) return;
                const ocreatedTimeFormat = format(new Date(history.createdTime), "dd-MM-yyyy")
                var totalPrice = p.productOrders.reduce((prev, curr) => {
                    return prev + curr.price
                }, 0)
                serviceIncome.prev += ocreatedTimeFormat <= endDatePrevPeriodFormat ? totalPrice : 0;
                serviceIncome.current += ocreatedTimeFormat >= startDateCurPeriodFormat ? totalPrice : 0;
            })
            serviceIncome.rate = calculateReportItemRate(serviceIncome);
        }
        totalOtherIncome.current = extraIncome.current + serviceIncome.current;
        totalOtherIncome.prev = extraIncome.prev + serviceIncome.prev;
        totalOtherIncome.rate = calculateReportItemRate(totalOtherIncome);
    }

    async function updateExpense(query: QueryOrderReport, reportItems: ReportItem[]) {
        const res = await BillApi.GetReceptionBillsReportApi(query.QueryFrom, query.QueryTo)
        var expense = reportItems.find(p => p.order === 12)!;
        if (res.status !== 200) return;
        var bills = res.data;
        bills.forEach(bill => {
            const createdTime = format(new Date(bill.createdTime), "dd-MM-yyyy");
            expense.prev += createdTime <= endDatePrevPeriodFormat ? bill.amount : 0;
            expense.current += createdTime >= startDateCurPeriodFormat ? bill.amount : 0;
        })
        expense.rate = calculateReportItemRate(expense);
    }



    function calculateReportItemRate(reportItem: ReportItem) {
        return reportItem.current !== 0 ? (reportItem.current - reportItem.prev) * 100 / reportItem.prev : 0;
    }

    const columns: ColumnsType<ReportItem> = [
        {
            title: 'Chi tiêu báo cáo',
            dataIndex: 'name',
            key: 'name',
            onHeaderCell: () => headerStyle(),
            render: (name, record) => (
                (record.level === 0) ? <div style={{ fontWeight: 'bold' }}>{name}</div> :
                    (record.level == 1 ? <div style={{ paddingLeft: 15 }}>{name}</div> :
                        (<div style={{ paddingLeft: 35 }}>{name}</div>))

            )

        },
        {
            title: 'Kỳ trước',
            dataIndex: 'prev',
            key: 'prev',
            onHeaderCell: () => headerStyle(),
            render: (prev) => (
                MathHelper.formatPriceVND(prev, true)
            )
        },
        {
            title: 'Kỳ hiện tại',
            dataIndex: 'current',
            key: 'current',
            onHeaderCell: () => headerStyle(),
            render: (current) => (
                MathHelper.formatPriceVND(current, true)
            )
        },
        {
            title: 'Tỷ trọng',
            dataIndex: 'rate',
            key: 'rate',
            onHeaderCell: () => headerStyle(),
            render: (rate) => (
                MathHelper.formatPercent(rate / 100)
            )
        }
    ]

    function changeRangeTime(values: any, formatString: [string, string]) {
        const startTime: Moment = values[0];
        const endTime: Moment = values[1];

        setStartDateCurPeriod(startTime.toDate());
        setEndDateCurPeriod(endTime.toDate());
        // fetchData();
    }

    return (
        <div>
            <Space direction='horizontal'>
                <RangePicker format='DD/MM/YYYY'
                    clearIcon={false}
                    style={{ width: '220px' }}
                    onChange={changeRangeTime}
                    defaultValue={[moment(subMonths(new Date(), 1)), moment()]} />

            </Space>

            <Spin spinning={loading} tip="đang tải...">
                <Table columns={columns} size="small" bordered
                    dataSource={reportItems}
                    pagination={false}
                />
            </Spin>


            {/* <div>
                <ConceptModal visible={showConcept}
                    concepts={remainConcepts}
                    onCancel={() => setShowConcept(false)} />
            </div> */}
        </div>
    )
}