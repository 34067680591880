import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { catchError, concat, concatMap, filter, from, map, of, switchMap, withLatestFrom, zip } from "rxjs";
import { AgencyApi } from "../../Api/AgencyApi";
import { ProductApi } from "../../Api/ProductApi";
import { WarehouseApi } from "../../Api/WarehouseApi";
import { StorageType } from "../../common/type-define";
import { RootEpic } from "../configure/rootReducer";
import { setUpdateProductLoading } from "./products.slice";


interface IStorageActionState {
    listStorageProduct: ProductVersionResponse[];
    fetchStorageLoading: boolean;
    listStorage: IStorageItem[];
    updateProductSelected: ProductVersionResponse | null;
}

const initState: IStorageActionState = {
    listStorageProduct: [],
    fetchStorageLoading: false,
    listStorage: [],
    updateProductSelected: null,
}

const storageActionSlice = createSlice({
    name: 'storageAction',
    initialState: initState,
    reducers: {
        fetchProductByWarehouseIds(state, action: PayloadAction<string[]>) { return },
        fetchProductByAgencyIds(state, action: PayloadAction<string[]>) { return },
        setFetchStorageLoading(state, action: PayloadAction<boolean>) {
            state.fetchStorageLoading = action.payload;
        },
        fetchListStorage(state, action: PayloadAction<undefined>) { return },
        setListStorage(state, action: PayloadAction<IStorageItem[]>) {
            state.listStorage = action.payload;
        },
        fetchMultiStorage(state, action: PayloadAction<{ warehouseIds: string[], agencyIds: string[] }>) { return },
        setListStorageProduct(state, action: PayloadAction<ProductVersionResponse[]>) {
            state.listStorageProduct = action.payload;
        },
        setUpdateProductSelected(state, action: PayloadAction<ProductVersionResponse | null>) {
            state.updateProductSelected = action.payload;
        },
        productIdSeleted(state, action: PayloadAction<string>) { return },
    }
});

const fetchProductByWarehouseIds$: RootEpic = (action$, state$) => action$.pipe(
    filter(fetchProductByWarehouseIds.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const warehouseIds = action.payload;
        if (!warehouseIds.length) return [];

        return concat(
            [storageActionSlice.actions.setFetchStorageLoading(true)],
            from(WarehouseApi.getProductVersionByWarehouseIds(warehouseIds)).pipe(
                switchMap(response => {
                    if (response?.status !== 200) return [];
                    return [
                        storageActionSlice.actions.setListStorageProduct(response.data.items)
                    ]
                }),
                catchError(err => [storageActionSlice.actions.setFetchStorageLoading(false)])
            ),
            [storageActionSlice.actions.setFetchStorageLoading(false)]
        )
    })
)

const fetchProductByAgencyIds$: RootEpic = (action$, state$) => action$.pipe(
    filter(fetchProductByAgencyIds.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const agencyIds = action.payload
        if (!agencyIds.length) return [];

        return concat(
            [storageActionSlice.actions.setFetchStorageLoading(true)],
            from(AgencyApi.getProductVersionByAgencyIds(agencyIds)).pipe(
                switchMap(response => {
                    if (response?.status !== 200) return [];
                    return [
                        storageActionSlice.actions.setListStorageProduct(response.data.items)
                    ]
                }),
                catchError(err => [storageActionSlice.actions.setFetchStorageLoading(false)])
            ),
            [storageActionSlice.actions.setFetchStorageLoading(false)]
        )
    })
)

const fetchListStorage$: RootEpic = (action$, state$) => action$.pipe(
    filter(fetchListStorage.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {

        const combineApi = zip(
            from(WarehouseApi.getWarehouses()).pipe(
                map(response => {
                    if (response?.status !== 200) return { warehouses: [] };
                    const warehouseItems: IStorageItem[] = response.data.items.map(v => ({
                        id: v.id,
                        name: v.name,
                        type: StorageType.Warehouse
                    }));
                    return { warehouses: warehouseItems }
                }),
                catchError(e => of({ warehouses: [] }))
            ),
            from(AgencyApi.getAgencies()).pipe(
                map(response => {
                    if (response?.status !== 200) return { agencies: [] };
                    const agencyItems: IStorageItem[] = response.data.items.map(v => ({
                        id: v.id,
                        name: v.name,
                        type: StorageType.Agency
                    }));
                    return { agencies: agencyItems }
                }),
                catchError(e => of({ agencies: [] }))
            ),
        )

        return concat(
            [storageActionSlice.actions.setFetchStorageLoading(true)],
            combineApi.pipe(
                concatMap(val => {
                    const result = Object.assign({}, ...val) as any;
                    return [
                        storageActionSlice.actions.setListStorage([...result.warehouses, ...result.agencies])
                    ]
                })
            ),
            [storageActionSlice.actions.setFetchStorageLoading(false)]
        )
    })
)

const fetchMultiStorage$: RootEpic = (action$, state$) => action$.pipe(
    filter(fetchMultiStorage.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const { warehouseIds, agencyIds } = action.payload;
        const warehouseObs = warehouseIds.length ?
            from(WarehouseApi.getProductVersionByWarehouseIds(warehouseIds)).pipe(
                map(response => {
                    if (response?.status !== 200) return { warehouseProducts: {} };

                    return { warehouseProducts: response.data.items }
                }),
                catchError(e => of({ warehouseProducts: {} }))
            )
            :
            of({ warehouseProducts: {} });

        const agencyObs = agencyIds.length ?
            from(AgencyApi.getProductVersionByAgencyIds(agencyIds)).pipe(
                map(response => {
                    if (response?.status !== 200) return { agencyProducts: {} };
                    return { agencyProducts: response.data.items }
                }),
                catchError(e => of({ agencyProducts: {} }))
            )
            :
            of({ agencyProducts: {} });

        const combineApi = zip(warehouseObs, agencyObs);

        return concat(
            [storageActionSlice.actions.setFetchStorageLoading(true)],
            combineApi.pipe(
                concatMap(val => {
                    const result = Object.assign({}, ...val) as any;

                    const agencyProducts: ProductVersionResponse[] = result.agencyProducts;
                    const wareHouseProducts: ProductVersionResponse[] = result.warehouseProducts;
                    let combineProducts: ProductVersionResponse[] = [];

                    for (let index = 0; index < wareHouseProducts.length; index++) {
                        const element = wareHouseProducts[index];
                        const proVer = agencyProducts.find(y => element.id == y.id);
                        if (proVer !== null)
                            element.productVersionAgencies = proVer?.productVersionAgencies ?? [];
                        combineProducts.push(element);

                    }

                    return [
                        storageActionSlice.actions.setListStorageProduct(combineProducts)
                    ]
                })
            ),
            [storageActionSlice.actions.setFetchStorageLoading(false)]
        )
    })
)

const productIdSeleted$: RootEpic = (action$, state$) => action$.pipe(
    filter(productIdSeleted.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const productId = action.payload;

        return concat(
            [setUpdateProductLoading(true)],
            from(ProductApi.getProductVersionById(productId)).pipe(
                switchMap(response => {
                    if (response?.status !== 200) return [];
                    return [
                        storageActionSlice.actions.setUpdateProductSelected(response.data),
                        setUpdateProductLoading(false)
                    ]
                }),
                catchError(err => [setUpdateProductLoading(false)])
            ),
            [setUpdateProductLoading(false)]
        )
    })
)

export const StorageActionEpics = [
    fetchProductByWarehouseIds$,
    fetchProductByAgencyIds$,
    fetchListStorage$,
    fetchMultiStorage$,
    productIdSeleted$,
]

export const {
    fetchProductByWarehouseIds,
    fetchProductByAgencyIds,
    fetchListStorage,
    fetchMultiStorage,
    setUpdateProductSelected,
    productIdSeleted,
} = storageActionSlice.actions;

export default storageActionSlice.reducer;