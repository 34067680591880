import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../configure/rootReducer";


export const getListVoucher = createSelector(
    (rootState: RootState) => rootState.voucherAction,
    (state) => state.listVoucher,
)
export const getVoucherCreateModalVis = createSelector(
    (rootState: RootState) => rootState.voucherAction,
    (state) => state.voucherCreateModalVis,
)

export const getProductsByVoucher = createSelector(
    (rootState: RootState) => rootState.voucherAction,
    (state) => state.productsByVoucher,
)

export const getListVoucherByProducts = createSelector(
    (rootState: RootState) => rootState.voucherAction,
    (state) => state.listVoucherByProducts,
)

export const getInputVoucherVis = createSelector(
    (rootState: RootState) => rootState.voucherAction,
    (state) => state.inputVoucherVis,
)

export const getInputVoucherProductLoading = createSelector(
    (rootState: RootState) => rootState.voucherAction,
    (state) => state.inputVoucherProductLoading,
)

