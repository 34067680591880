import { Button, DatePicker, Space, Table, Spin } from "antd";
import {
    ArrowLeftOutlined
} from '@ant-design/icons';
import { useEffect, useState } from "react";
import { ColumnsType } from "antd/lib/table";
import { headerStyle } from "../../Helper/UIHelper";
import { InventoryReportApi } from "../../../Api/Reports/InventoryReportApi";


export default function InventoryNormReportView() {
    const [showConcept, setShowConcept] = useState<boolean>(false);
    const [data, setData] = useState<IInventoryNormReport[]>([]);
    const [loading, setLoading] = useState<boolean>(false)
    useEffect(() => {
        fetchData();
    }, [])
    async function fetchData() {
        setLoading(true);

        const response = await InventoryReportApi.GetInventoryNormReportsApi()
        if (response.status !== 200) {
            setLoading(false);
            return;
        }

        setData(response.data);
        setLoading(false);
    }

    const columns: ColumnsType<IInventoryNormReport> = [
        {
            title: 'Mã sản phẩm',
            dataIndex: 'productCode',
            key: 'productCode',
            onHeaderCell: () => headerStyle()
        },
        {
            title: 'Tên sản phẩm',
            dataIndex: 'productVersionName',
            key: 'productVersionName',
            onHeaderCell: () => headerStyle()
        },
        {
            title: 'Tồn kho',
            dataIndex: 'remainQuantity',
            key: 'remainQuantity',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Định mức tối thiếu',
            dataIndex: 'minimunNorm',
            key: 'minimunNorm',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Cần thêm',
            dataIndex: 'needSupplementQuantity',
            key: 'needSupplementQuantity',
            onHeaderCell: () => headerStyle()
        }
    ]

    return (
        <div>

            <Space direction='horizontal'>
                <Button type="default" icon={<ArrowLeftOutlined />} href="/admin/report/store"></Button>
                <h1>Báo cáo tồn kho định mức</h1>
            </Space>

            <Spin spinning={loading} tip="đang tải...">
                <Table columns={columns} dataSource={data} size="small" bordered
                    pagination={false}
                />
            </Spin>
        </div>
    )
}