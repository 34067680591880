import {
    LeftOutlined,
    DeleteOutlined,
    PlusOutlined
} from '@ant-design/icons';
import { Button, Col, Divider, Modal, Row, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { format } from 'date-fns';
import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { DeleteRoleApi } from '../../Api/RoleApis';
import { RoleResponse } from "../../Model/RoleModel";
import { useSelectorRoot } from '../../redux/configure/store';
import { getFetchRoleGroupLoading, getRoleGroup, getRoleModalVis } from '../../redux/selectors/roles.selector';
import { fetchRoleGroup, setRoleGroupDetails, setRoleModalVis } from '../../redux/slices/roles.slice';
import { RoleModal } from "../Roles/RoleModal";

export function RolesView() {
    const dispatch = useDispatch();
    const roleGroup = useSelectorRoot(getRoleGroup);
    const roleModalVis = useSelectorRoot(getRoleModalVis);
    const fetchRoleGroupLoading = useSelectorRoot(getFetchRoleGroupLoading);
    const [roleModelTitle, setRoleModelTitle] = useState<string>("Sửa vai trò");
    const [roleId, setRoleId] = useState<string>("");

    useEffect(() => {
        dispatch(fetchRoleGroup())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const showAddModal = () => {
        setRoleId("");
        setRoleModelTitle("Thêm vai trò");
        dispatch(setRoleModalVis(true));
    }

    const editRoleModel = (record: RoleResponse) => {
        setRoleId(record.id);
        setRoleModelTitle("Sửa vai trò");
        dispatch(setRoleModalVis(true));
    }

    //Hủy một đơn hàng
    const DeleteRoleGroup = (record: RoleResponse) => {
        return () => {
            DeleteRoleApi(record.id, () => {
                dispatch(fetchRoleGroup())
            })
        }
    }

    const columns: ColumnsType<RoleResponse> = [
        {
            title: 'Vai trò',
            dataIndex: 'name',
            key: 'name',
            render: (name, record) => (
                <Typography.Link onClick={() => editRoleModel(record)}>
                    <span>{name}</span>
                </Typography.Link>
            )
        },
        {
            title: 'Miêu tả',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Lần cập nhật cuối',
            dataIndex: 'registerTime',
            key: 'registerTime',
            render: (registerTime) => (
                <div>
                    {format(new Date(registerTime), "dd-MM-yyyy")}
                </div>
            )
        },
        {
            title: 'Hành động',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
            render: (name, record) => (
                <DeleteOutlined onClick={DeleteRoleGroup(record)} style={{ color: "red" }} />
            )
        },
    ];

    const handleOnCancel = () => {
        dispatch(setRoleModalVis(false));
        dispatch(setRoleGroupDetails(null));
    }

    return (
        <div>
            <div>
                <Button type="text" icon={<LeftOutlined />} href="/admin/settings" size="large">
                    Quay lại cấu hình
                </Button>
            </div>

            <div style={{ display: "flex", height: 50, top: 25 }}>

                <div style={{ fontWeight: 500, fontSize: 25, marginLeft: 20 }}>
                    Danh sách vai trò
                </div>
                <div style={{ flexGrow: 1, textAlign: "end" }}>
                    <Button type="primary" icon={<PlusOutlined />} onClick={showAddModal} size="middle">
                        Thêm vai trò
                    </Button>
                </div>
            </div>
            <Divider style={{ marginTop: 0, marginLeft: 20 }} />

            <Row style={{ marginLeft: 20, marginRight: 20 }}>
                <Col span={8}>
                    <div style={{ fontWeight: 600, marginBottom: 10 }}>Quản lý vai trò và phân quyền</div>
                    <div>Hỗ trợ thêm mới, phân quyền và quản lý sửa xóa các vai trò của cửa hàng</div>
                </Col>
                <Col span={16}>
                    <Table
                        loading={fetchRoleGroupLoading}
                        columns={columns}
                        dataSource={roleGroup}>
                    </Table>
                </Col>
            </Row>

            <Modal
                width={700}
                centered
                destroyOnClose
                onCancel={handleOnCancel}
                title={roleModelTitle}
                visible={roleModalVis}
                maskClosable={false}
                cancelText={"Đóng"}
                footer={null}>
                <RoleModal id={roleId} />
            </Modal>
        </div>
    )
}