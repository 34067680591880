import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { StorageType } from "../../common/type-define";
import { getCurrentName, isAgencyAccount } from "../../Helper/CommonHelper";
import { useSelectorRoot } from "../../redux/configure/store";
import { getListStorage } from "../../redux/selectors/storage.selector";
import { fetchMultiStorage, fetchProductByAgencyIds, fetchProductByWarehouseIds } from "../../redux/slices/storage.slice";

const { Option } = Select;

interface StorageBarProps {
    displayAll?: boolean,
    setWarehouse?: (warehouse: IStorageItem) => void;
}

const ALL_STORAGE: IStorageItem = {
    id: '',
    name: 'Tất cả kho',
    type: StorageType.All
}

const StorageBarComponent = ({
    displayAll = true,
    setWarehouse
}: StorageBarProps): JSX.Element => {
    const dispatch = useDispatch();
    const listStorage = useSelectorRoot(getListStorage);
    const [listWarehouseAgency, setListWarehouseAgency] = useState<IStorageItem[]>([]);
    const [storageFilterValue, setStorageFilterValue] = useState<IStorageItem | undefined>(displayAll ? ALL_STORAGE : undefined);

    useEffect(() => {

        let allStore = listStorage;
        if (isAgencyAccount()) {
            allStore = listStorage.filter(v => v.type === StorageType.Warehouse || v.name === getCurrentName())
        }

        if (displayAll) {
            setListWarehouseAgency([...[ALL_STORAGE], ...allStore]);
        } else {
            setListWarehouseAgency(allStore);
            if (isAgencyAccount()) {
                const agency = allStore.find(v => v.type === StorageType.Agency);
                setStorageFilterValue(agency);
            }
            else
                setStorageFilterValue(allStore[0]);
        }
    }, [listStorage, displayAll])

    useEffect(() => {
        if (!storageFilterValue) return;
        setWarehouse && setWarehouse(storageFilterValue);
        switch (storageFilterValue.type) {
            case StorageType.All: {
                const warehouseIds = listWarehouseAgency.filter(v => v.type === StorageType.Warehouse).map(v => v.id);
                const agencyIds = listWarehouseAgency.filter(v => v.type === StorageType.Agency).map(v => v.id);
                dispatch(fetchMultiStorage({ warehouseIds, agencyIds }));
                break;
            }
            case StorageType.Warehouse: {
                const ids = listWarehouseAgency.filter(v => v.type === StorageType.Warehouse && v.id === storageFilterValue.id).map(v => v.id);
                dispatch(fetchProductByWarehouseIds(ids));
                break;
            }
            case StorageType.Agency: {
                const ids = listWarehouseAgency.filter(v => v.type === StorageType.Agency && v.id === storageFilterValue.id).map(v => v.id);
                dispatch(fetchProductByAgencyIds(ids));
                break;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, listWarehouseAgency, storageFilterValue])

    const handleChangeStorage = (_: any, opt: any) => {
        const itemSelected = opt.label as IStorageItem;
        setStorageFilterValue(itemSelected);
        setWarehouse && setWarehouse(itemSelected);
    }

    return (
        <div>
            <Select
                onChange={handleChangeStorage}
                value={storageFilterValue?.name}
                style={{ width: 120 }}
                bordered={true}>
                {
                    listWarehouseAgency.map(v =>
                        <Option
                            key={v.id}
                            value={v.name}
                            label={v}>{v.name}</Option>
                    )
                }
            </Select>
        </div>
    )
}

const StorageBar = React.memo(StorageBarComponent);
export default StorageBar;