import { Input, InputNumber, InputNumberProps, Select } from "antd";

type MoneyInputProps = InputNumberProps & {

}

export default function InputMoney(props: MoneyInputProps) {

    return (<InputNumber {...props}
        addonAfter={<span>₫</span>}
        className="input-number"
        min={0}
        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
        parser={value => value?.replace(/\$\s?|(\.*)/g, '') as any}

    />)
}

export function InputPercent(props: InputNumberProps) {
    return (
        <InputNumber {...props}
            addonAfter={<span>%</span>}
            className="input-number"
            defaultValue={0}
            min={0}
            max={100}
            formatter={value => `${value}`}
            parser={value => value?.replace('%', '') as any}
        />
    )
}