import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../configure/rootReducer";


export const getListStorageProduct = createSelector(
    (rootState: RootState) => rootState.storageAction,
    (state) => state.listStorageProduct,
)

export const getListStorage = createSelector(
    (rootState: RootState) => rootState.storageAction,
    (state) => state.listStorage,
)

export const getFetchStorageLoading = createSelector(
    (rootState: RootState) => rootState.storageAction,
    (state) => state.fetchStorageLoading,
)
export const getUpdateProductSelected = createSelector(
    (rootState: RootState) => rootState.storageAction,
    (state) => state.updateProductSelected,
)