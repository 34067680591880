import { AxiosRequest } from "./Axios/AxiosRequest";

import { CreateRoleGroupCommand, UpdateRoleGroupCommand } from "../Model/RoleModel";
import { HandleRequest } from "./RequestAPI";
import { SERVER_AUTHEN } from "./UrlDefination";

export class RoleApi {
    static async getAllRoles(doAfterResponse: Function) {
        return HandleRequest(AxiosRequest.get(`${SERVER_AUTHEN.HOST}/${SERVER_AUTHEN.POLICY.GET}`), doAfterResponse);
    }

    static async getRoleById(id: string, doAfterResponse: Function) {
        return HandleRequest(AxiosRequest.get(`${SERVER_AUTHEN.HOST}/${SERVER_AUTHEN.ROLE.GET_BY_ID}?Id=${id}`), doAfterResponse);
    }

    static async getRoleGroup(doAfterResponse: Function) {
        return HandleRequest(AxiosRequest.get(`${SERVER_AUTHEN.HOST}/${SERVER_AUTHEN.ROLE.GET}`), doAfterResponse);
    }

    static async updateRole(id: string, data: UpdateRoleGroupCommand, doAfterResponse: Function) {
        return HandleRequest(AxiosRequest.put(`${SERVER_AUTHEN.HOST}/${SERVER_AUTHEN.ROLE.UPDATE_BY_ID(id)}`, data), doAfterResponse);
    }

    static async createRole(data: CreateRoleGroupCommand, doAfterResponse: Function) {
        return HandleRequest(AxiosRequest.post(`${SERVER_AUTHEN.HOST}/${SERVER_AUTHEN.ROLE.CREATE}`, data), doAfterResponse);
    }
}

export const GetPoliciesApi = async (doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.get(`${SERVER_AUTHEN.HOST}/${SERVER_AUTHEN.POLICY.GET}`), doAfterResponse);
}
export const GetRolesApi = async (doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.get(`${SERVER_AUTHEN.HOST}/${SERVER_AUTHEN.ROLE.GET}`), doAfterResponse);

}

export const GetRoleByIdApi = async (id: string, doAfterResponse: Function) => {

    await HandleRequest(AxiosRequest.get(`${SERVER_AUTHEN.HOST}/${SERVER_AUTHEN.ROLE.GET_BY_ID}?Id=${id}`), doAfterResponse);

}

export const CreateRoleApi = async (data: any, doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.post(`${SERVER_AUTHEN.HOST}/${SERVER_AUTHEN.ROLE.CREATE}`, data), doAfterResponse);
}

export const UppdateRoleApi = async (id: string, data: any, doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.put(`${SERVER_AUTHEN.HOST}/${SERVER_AUTHEN.ROLE.UPDATE_BY_ID(id)}`, data), doAfterResponse);
}

export const DeleteRoleApi = async (id: string, doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.delete(`${SERVER_AUTHEN.HOST}/${SERVER_AUTHEN.ROLE.DELETE_BY_ID(id)}`), doAfterResponse);
}

export const AssignRoleToUserApi = async (data: any, doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.post(`${SERVER_AUTHEN.HOST}/${SERVER_AUTHEN.ROLE.SET_TO_USER_ID}`, data), doAfterResponse);
}