import { Button, Col, Row, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import {
    PlusOutlined,
    LeftOutlined,
    CheckCircleFilled
} from '@ant-design/icons';
import { useEffect, useState } from "react";
import { ChanelSaleModal } from "../Sale/ChanelSaleModal";
import { GetSaleChannelsApi } from "../../Api/SaleChannelApi";

type ChannelRes = {
    id: string,
    name: string,
    description: string,
    isAvailable: boolean
}

export function SaleChanelsView() {

    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [channelTitle, setchannelTitle] = useState<string>("");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [chanelId, setChanelId] = useState<string>("");

    const [channels, setChannels] = useState<ChannelRes[]>([]);

    useEffect(() => {
        GetSaleChannelsApi((res: IListItems<ChannelRes>) => {
            setChannels(res.items);
        })
    }, [])

    const showAddModal = () => {
        setChanelId("");
        setchannelTitle("Thêm kênh bán hàng");
        setIsModalVisible(true);
    }


    const applyModal = (res: ChannelRes) => {
        let newChannels: ChannelRes[] = [...channels];
        newChannels.push(res);
        setChannels(newChannels);
        setIsModalVisible(false);
    }

    const closeModal = () => {
        setIsModalVisible(false);
    }


    const columns: ColumnsType<ChannelRes> = [
        {
            title: "Tên kênh bán hàng",
            dataIndex: "name",
            key: "name"
        },
        {
            title: "Miêu tả",
            dataIndex: "description",
            key: "description"
        },
        {
            title: "Trạng thái",
            dataIndex: "isAvailable",
            key: "isAvailable",
            align: "center",
            render: (isAvailable) => (
                isAvailable ? <CheckCircleFilled style={{ fontSize: '16px', color: '#08c' }} /> : ("")
            )
        }
    ]


    return (
        <div>

            <div>
                <Button type="text" icon={<LeftOutlined />} href="/admin/settings" size="large">
                    Quay lại cấu hình
                </Button>
            </div>

            <div style={{ display: "flex", height: 50, top: 25 }}>

                <div style={{ fontWeight: 500, fontSize: 25, marginLeft: 20 }}>
                    Danh sách kênh bán hàng
                </div>
                <div style={{ flexGrow: 1, textAlign: "end" }}>
                    <Button type="primary" icon={<PlusOutlined />} onClick={showAddModal} size="middle">
                        Thêm kênh bán hàng
                    </Button>
                </div>
            </div>
            <Row style={{ marginLeft: 20, marginRight: 20 }}>
                <Col span={8}>
                    <div style={{ fontWeight: 600, marginBottom: 10 }}>Quản lý kênh bán hàng</div>
                    <div>Bên cạnh một số nguồn phổ biến nhất đã có sẵn, bạn có thể cập nhật hoặc thêm mới các nguồn tạo ra đơn hàng cho cửa hàng của bạn.</div>
                </Col>
                <Col span={16}>
                    <Table bordered
                        columns={columns}
                        dataSource={channels} />
                </Col>
            </Row>

            <ChanelSaleModal
                title={channelTitle}
                visible={isModalVisible}
                closeModalFunc={closeModal}
                okModalFunc={applyModal} />
        </div>

    )
}
