import {
    ExclamationCircleOutlined, InfoCircleOutlined, LeftOutlined, PlusOutlined, SaveOutlined
} from '@ant-design/icons';
import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';
import { Button, Card, Col, Divider, Form, Input, message, Modal, Row, Select, Space, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { UploadChangeParam } from 'antd/lib/upload';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import React, { useEffect, useState } from 'react';
import { RGBColor } from 'react-color';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { GetColorsApi } from '../../Api/AttributesApi';
import { CreateCategoryApi, GetCategories } from '../../Api/CategoryApi';
import { CreateFactoryApi, GetFactories } from '../../Api/FactoryApi';
import { GetUnitsApi } from '../../Api/UnitsApi';
import Category, { CategoryRequest } from '../../Model/CategoryModel';
import Factory, { FactoryRequest } from '../../Model/FactoryModel';
import NewCategorViewModel from '../../ViewModel/CategoryViewModel';
import { NewFactoryViewModel } from '../../ViewModel/FactoryViewModel';
import { ColorVersion, CreateNewProductFnc, NewProductViewModel } from '../../ViewModel/ProductViewModel';
import CCombobox from '../Controls/CCombobox';
import { LabelColor } from '../Controls/LabelColor';
import InputMoney, { InputPercent } from '../Controls/MoneyInput';


const { confirm } = Modal;

const { TextArea } = Input;

const { Option } = Select;




function convertHexToRgb(hex: string): RGBColor {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result && result.length === 4)
        return {
            a: 1,
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        };

    return { a: 1, r: 255, g: 16, b: 20 };
}

function CreateaccessorytView() {

    const { id } = useParams();

    const [form] = Form.useForm<NewProductViewModel>();
    const [categoryForm] = Form.useForm<NewCategorViewModel>();
    const [factoryForm] = Form.useForm<NewFactoryViewModel>();

    const [categories, setCategories] = useState<Category[]>([]);
    const [factories, setFactories] = useState<Factory[]>([]);
    const [units, setUnits] = useState<IUnit[]>([]);
    const [systemColors, setSystemColors] = useState<AttributeResponse[]>([]);
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [hasColor, setHasColor] = useState<boolean>(false);

    const [colors, setColors] = useState<ColorVersion[]>([]);
    //const [configGroups, setConfigGroups] = useState<ConfigGroup[]>([]);

    const [showNewCategoryModal, setShowNewCategoryModal] = useState<boolean>(false);
    const [showNewFactoryModal, setShowNewFactoryModal] = useState<boolean>(false);
    const [showNewUnitModal, setShowNewUnitModal] = useState<boolean>(false);


    useEffect(() => {
        GetCategories((data: IListItems<Category>) => {
            setCategories(data.items);
        });
        GetFactories((data: IListItems<Factory>) => {
            setFactories(data.items);
        });

        GetUnitsApi((data: IListItems<IUnit>) => {
            setUnits(data.items);
            if (data.items.length > 0)
                form.setFieldsValue({ unitId: data.items[0].id });
        });


        setHasColor(false);
        form.setFieldsValue({ warrantyDays: 6 });

    }, []);


    function showConfirm() {
        confirm({
            title: 'Bạn có muốn tạo thêm phụ kiện?',
            icon: <ExclamationCircleOutlined />,
            content: 'Phụ kiện đã tạo thành công',
            onOk() {
                navigate(`/admin/products/create_accessory/${id}`);

            },
            onCancel() {
                navigate(`/admin/products`);
            },
        });
    }

    const navigate = useNavigate();
    //Lấy dữ liệu từ form --> call API để tạo sản phẩm trên server
    const createProductSubmit = () => {
        form.validateFields()
            .then((data: NewProductViewModel) => {
                data.productType = 1; //Chuyển cái này về là phụ kiện
                data.parentId = id; //Để biết đây là phụ kiện
                CreateNewProductFnc(data, fileList, colors, () => {
                    message.info("Tạo phụ kiện thành công");
                    showConfirm();

                });
            });
    };

    //Thay đổi danh sách ảnh
    const onChange = (data: UploadChangeParam) => {
        data.fileList.map(x => x.response = "");
        setFileList(data.fileList);
    };

    //Return false to prevent upload to server
    const onBeforUpload = (file: RcFile, FileList: RcFile[]) => {
        return false;
    }


    //Thay đổi màu của version
    const onChangeColorStatus = (checked: boolean, event: MouseEvent) => {

        if (checked) {
            let choosedColors: ColorVersion[] = [];
            GetColorsApi((res: IListItems<AttributeResponse>) => {
                setSystemColors(res.items);
            })

        }
        setHasColor(checked);
    }

    const AddNewUnitsHandle = () => {

    }

    //Hiển thị giao diện để thêm nhà sản xuất mới
    const AddNewFactoryHandle = () => {
        setShowNewFactoryModal(true);
    }

    const AddNewColorHandle = () => {
        message.info("Sẽ hiển thị một hộp thoại cho phép thêm màu")
    }

    //Tạo ra 1 nhà sản xuất mới
    const CreateFactorySubmit = () => {

        factoryForm.validateFields()
            .then((data: NewFactoryViewModel) => {
                let factoryData: FactoryRequest = {
                    name: data.factoryName,
                    address: data.address,
                    hotline: data.hotline
                };

                CreateFactoryApi(factoryData, (res: Factory) => {

                    let newfactories: Factory[] = [...factories];
                    newfactories.push(res);

                    setFactories(newfactories);
                    form.setFieldsValue({ factoryId: res.id });

                    setShowNewFactoryModal(false);
                });

            });


    }

    //Đóng cửa sổ giao diện của thêm nhà sản xuất
    const CloseFactoryModal = () => {
        setShowNewFactoryModal(false);
    };

    //Hiển thị giao diện để thêm nhóm sản phẩm
    const AddNewCategoryHandle = () => {
        setShowNewCategoryModal(true);
    };

    // Gọi API để tạo nhóm sản phẩm từ dữ liệu trên form
    const CreateCategorySubmit = () => {

        categoryForm.validateFields()
            .then((data: NewCategorViewModel) => {

                let categoryData: CategoryRequest = {
                    name: data.categoryName,
                    description: data.description,
                };

                CreateCategoryApi(categoryData, (res: Category) => {

                    let newCategories: Category[] = [...categories];
                    newCategories.push(res);

                    setCategories(newCategories);
                    form.setFieldsValue({ categoryId: res.id });

                    setShowNewCategoryModal(false);
                });
            });
    };

    //Đóng cửa sổ giao diện của thêm nhóm sản phẩm
    const closeCateogryModal = () => {
        setShowNewCategoryModal(false);
    };

    const handleRemoveColorVersion = (prod: ColorVersion) => {
        return () => {
            const newVersions = [...colors];
            const findIndex = newVersions.findIndex(cart => cart.name === prod.name);
            if (findIndex !== -1) {
                newVersions.splice(findIndex, 1);
                setColors(newVersions);
            }

        }
    };


    //Thay đổi tên của từng version
    const changeVersionName = (e: React.FormEvent<HTMLInputElement>) => {

        let newVersions = [...colors];
        const oldValue = e.currentTarget.defaultValue;
        const newValue = e.currentTarget.value;


        let nIndex = newVersions.findIndex(x => x.name === oldValue);
        const item = newVersions[nIndex];

        newVersions.splice(nIndex, 1, { name: newValue, color: item.color, colorId: item.colorId });
        setColors(newVersions);

    }

    function changeVersionColor(value: string[]) {
        let newVersions: ColorVersion[] = [];
        value.map(x => {
            let index = systemColors.findIndex(y => y.id === x);
            let attrib = systemColors[index];
            index = colors.findIndex(z => z.colorId === x);
            let color = colors[index];
            if (color) {
                newVersions.push({
                    name: color.name, color: color.color, colorId: color.colorId
                })
            }
            else {
                newVersions.push({
                    name: attrib.name, color: attrib.code, colorId: attrib.id
                })
            }
        })

        setColors(newVersions);
    }

    const colorColumns: ColumnsType<ColorVersion> = [
        {
            title: 'Tên phiên bản',
            dataIndex: 'name',
            key: 'name',
            width: 150,
            align: "left",
            render: (name) => (
                <Input defaultValue={name} onChange={changeVersionName} />
            )
        },
        {
            title: 'Màu sắc',
            dataIndex: 'color',
            key: 'color',
            align: "left",
            render: (color) => (
                <div style={{ width: 20, height: 20, borderRadius: 5, backgroundColor: `${color}` }} />

            )
        },
        {
            title: '',
            key: 'action',
            render: (record) => (
                <Space size="middle" align="center" >
                    <DeleteOutlined style={{ color: 'red', marginTop: 5 }} onClick={handleRemoveColorVersion(record)} />
                </Space>
            ),
        },
    ];


    return (
        <div style={{ margin: 10 }}>
            <div style={{ display: "flex", height: 50, top: 25 }}>
                <Link to="/admin/products">
                    <LeftOutlined /> Quay lại danh sách sản phẩm
                </Link>
                <div style={{ flexGrow: 1, textAlign: "end" }}>
                    <Button type="primary" icon={<SaveOutlined />}
                        onClick={createProductSubmit} size="large">
                        Lưu
                    </Button>
                </div>
            </div>

            <div>
                <Form form={form}
                    layout="vertical">
                    <Row >
                        <Col span={16}>
                            <Card title="Thông tin phụ kiện">
                                <Form.Item name="productName" label="Tên phụ kiện" required tooltip="Tên sản phẩm không bao gồm các giá trị thuộc tính như màu sắc, chất liệu">
                                    <Input placeholder="Nhập tên phụ kiện" />
                                </Form.Item>
                                <Row>
                                    <Col span={11}>
                                        <Form.Item name="productCode" label="Mã phụ kiện" required >
                                            <Input placeholder="Nhập mã phụ kiện" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={1} />
                                    <Col span={12} >
                                        <Form.Item name="barCode" label="Mã Barcode" >
                                            <Input placeholder="Nhập mã barcode trên phụ kiện" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>

                                    <Col span={11} >
                                        <Form.Item label="Chọn nhóm phụ kiện" name="categoryId">

                                            <CCombobox chooseOptions={categories} displayName="name" id="id" placeholder="Chọn nhóm sản phẩm" extraContent={
                                                <div>
                                                    <Divider style={{ margin: '4px 0' }} />
                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                        <a onClick={AddNewCategoryHandle}
                                                            style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}>
                                                            <PlusOutlined />
                                                            <span>Thêm nhóm phụ kiện</span>
                                                        </a>
                                                    </div>
                                                </div>

                                            } />
                                        </Form.Item>


                                    </Col>

                                    <Col span={1} />
                                    <Col span={12} >
                                        <Form.Item name="unitId" label="Đơn vị sản phẩm" >
                                            <CCombobox chooseOptions={units} displayName="name" id="id" placeholder="Chọn đơn vị" extraContent={
                                                <div>
                                                    <Divider style={{ margin: '4px 0' }} />
                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                        <a onClick={AddNewUnitsHandle}
                                                            style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}>
                                                            <PlusOutlined />
                                                            <span>Thêm đơn vị</span>
                                                        </a>
                                                    </div>
                                                </div>

                                            } />
                                        </Form.Item>
                                    </Col>

                                </Row>

                                <Row>
                                    <Col span={6} style={{ paddingRight: 5 }}>
                                        <Form.Item label="Giá nhập" name="inputPrice">
                                            <InputMoney />
                                        </Form.Item>
                                    </Col>

                                    <Col span={6} style={{ paddingLeft: 5, paddingRight: 30 }}>
                                        <Form.Item label="Giá bán" name="salePrice">
                                            <InputMoney />
                                        </Form.Item>
                                    </Col>

                                    <Col span={6} >
                                        <Form.Item label="Thuế GTGT" name="vat">
                                            <InputPercent />
                                        </Form.Item>
                                    </Col>

                                    <Col span={6} style={{ paddingLeft: 5 }}>
                                        <Form.Item label="Thuế XNK" name="importAndExportTax">
                                            <InputPercent />
                                        </Form.Item>
                                    </Col>

                                </Row>

                            </Card>
                        </Col>
                        <Col span={8} style={{ paddingLeft: 10 }}>
                            <Card title="Thông tin khác" >

                                <Form.Item label="Chọn nhà sản xuất" name="factoryId">

                                    <CCombobox chooseOptions={factories} displayName="name" id="id" placeholder="Chọn nhà sản xuất" extraContent={
                                        <div>
                                            <Divider style={{ margin: '4px 0' }} />
                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                <a onClick={AddNewFactoryHandle}
                                                    style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}>
                                                    <PlusOutlined />
                                                    <span>Thêm nhà sản xuất</span>
                                                </a>
                                            </div>
                                        </div>

                                    } />
                                </Form.Item>

                                <Form.Item name="warrantyDays" label="Thời gian bảo hành(tháng)" >
                                    <Input placeholder="Nhập số tháng bảo hành" />
                                </Form.Item>

                                <Form.Item label={<LabelColor checked={hasColor} onChange={onChangeColorStatus} />} requiredMark={false}>
                                    {hasColor ? (
                                        <div>
                                            <Table columns={colorColumns}
                                                dataSource={colors} bordered />
                                            <Select mode='multiple'
                                                onChange={changeVersionColor}
                                                dropdownRender={menu => (
                                                    <div>
                                                        {menu}
                                                        {<div>
                                                            <Divider style={{ margin: '4px 0' }} />
                                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                <a onClick={AddNewColorHandle}
                                                                    style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}>
                                                                    <PlusOutlined />
                                                                    <span>Thêm định nghĩa màu</span>
                                                                </a>
                                                            </div>
                                                        </div>}
                                                    </div>
                                                )}>
                                                {systemColors.map(item => (
                                                    <Option value={item.id} key={item.id} > {item.name}</Option>
                                                ))}
                                            </Select>
                                        </div>) : ('không phân loại theo màu')}
                                </Form.Item>

                            </Card>
                        </Col>
                    </Row>
                </Form>
            </div>

            <Modal title="Thêm nhóm phụ kiện"
                centered
                visible={showNewCategoryModal}
                onOk={CreateCategorySubmit}
                onCancel={closeCateogryModal}>
                <Form form={categoryForm}
                    layout="vertical">
                    <Form.Item name="categoryName" label="Tên nhóm phụ kiện" required tooltip="Tên sản phẩm không bao gồm các giá trị thuộc tính như màu sắc, chất liệu">
                        <Input placeholder="Nhập tên nhóm phụ kiện" />
                    </Form.Item>

                    <Form.Item label="Miêu tả phụ kiện" name="description"
                        tooltip={{
                            title: 'Miêu tả thông tin chi tiết nhóm phụ kiện',
                            icon: <InfoCircleOutlined />
                        }}>
                        <TextArea placeholder="Nhập miêu tả nhóm phụ kiện" rows={2} />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal title="Thêm nhà sản xuât" visible={showNewFactoryModal}
                onOk={CreateFactorySubmit}
                onCancel={CloseFactoryModal}>
                <Form form={factoryForm}
                    layout="vertical">
                    <Form.Item name="factoryName" label="Tên nhà sản xuất" required >
                        <Input placeholder="Nhập tên nhà sản xuất" />
                    </Form.Item>

                    <Form.Item name="address" label="Địa chỉ" required >
                        <Input placeholder="Nhập địa chỉ nhà sản xuất" />
                    </Form.Item>

                    <Form.Item name="hotline" label="Số điện thoại" required >
                        <Input placeholder="Nhập số hotline" />
                    </Form.Item>

                </Form>
            </Modal>

        </div >
    )
}

export default CreateaccessorytView;