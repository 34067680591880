import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setHeaderTitle } from "./redux/slices/pages.slice";

interface PageProps {
    headerTitle: string,
    children: React.ReactNode,
}

const PageComponent = ({headerTitle, children}: PageProps) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setHeaderTitle(headerTitle));
    }, [dispatch, headerTitle])
    
    return (
        <>
            {children}
        </>
    )
}

const Page = React.memo(PageComponent);
export default Page;