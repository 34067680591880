import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../configure/rootReducer";



export const getListProduct = createSelector(
    (rootState: RootState) => rootState.productsAction,
    (state) => state.listProduct,
)

export const getUpdateProductLoading = createSelector(
    (rootState: RootState) => rootState.productsAction,
    (state) => state.updateProductLoading,
)