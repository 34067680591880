import { AxiosResponse } from "axios";
import { ReturnOrderProcess } from "../common/type-define";
import { CreateOrderReturnCommand, OrderReturnResponse, RefundProductOrderReturnsCommand, TechnicianProcessingOrderReturnCommand } from "../Model/ReturnOrderModel";
import { AxiosRequest } from "./Axios/AxiosRequest";
import { RequestHandler } from "./RequestAPI";
import { SERVER_API } from "./UrlDefination";


export class ProductOrderReturnApi {
    static async getProductOrderReturnById(orderId: string) {
        return RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.PRODUCT_ORDER_RETURN.GET_PRODUCT_ORDER_BY_ID(orderId)}`));
    };

    static async getOrderReturnById(orderId: string, doAfterResponse?: Function) {
        return RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.PRODUCT_ORDER_RETURN.GET_ORDER_BY_ID(orderId)}`), doAfterResponse);
    };

    static async getAllProductReturn(doAfterResponse: Function) {
        return RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.PRODUCT_ORDER_RETURN.GET_ALL_PRODUCT}`));
    };

    static async createReturnOrder(dataRequest: CreateOrderReturnCommand): Promise<AxiosResponse<OrderReturnResponse, any>> {
        return RequestHandler.handleRequest(AxiosRequest.post(`${SERVER_API.HOST}/${SERVER_API.PRODUCT_ORDER_RETURN.CREATE}`, dataRequest));
    };

    static async getAllOrderReturn(doAfterResponse: Function) {
        return RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.PRODUCT_ORDER_RETURN.GET_ALL_ORDER}`));
    };

    static async getProductByOrderReturnId(orderId: string) {
        return RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.PRODUCT_ORDER_RETURN.GET_PRODUCT_BY_ORDER_ID(orderId)}`));
    };

    static async changeOrderReturnToProcess(orderId: string, dataRequest: { status: ReturnOrderProcess }) {
        return RequestHandler.handleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.PRODUCT_ORDER_RETURN.CHANGE_TO_PROCESS(orderId)}`, dataRequest));
    };

    static async technicianProcessingOrder(orderId: string, dataRequest: TechnicianProcessingOrderReturnCommand) {
        return RequestHandler.handleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.PRODUCT_ORDER_RETURN.TECHNICIAN_PROCESSING_ORDER(orderId)}`, dataRequest));
    };

    static async refundProductOrder(orderId: string, dataRequest: RefundProductOrderReturnsCommand) {
        return RequestHandler.handleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.PRODUCT_ORDER_RETURN.REFUND_PRODUCTS_ORDER(orderId)}`, dataRequest));
    };

    static async completeOrder(orderId: string) {
        return RequestHandler.handleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.PRODUCT_ORDER_RETURN.COMPLETE_ORDER(orderId)}`, null));
    };

    static async RestorProductToStore(orderId: string) {
        return RequestHandler.handleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.PRODUCT_ORDER_RETURN.RESTORE(orderId)}`, null));
    };
}