import { PlusOutlined, SearchOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Input, message, Popover, Row, Space, Table, Tag, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import _ from 'lodash';
import { useEffect, useRef, useState } from "react";
import { useDispatch } from 'react-redux';
import { KeyCode } from '../../common/type-define';
import { canInputStore } from "../../Helper/CommonHelper";
import { MathHelper } from "../../Helper/math.helper";
import { useShortKey } from '../../hooks/useShortKey';
import { useSelectorRoot } from '../../redux/configure/store';
import { getFetchStorageLoading, getListStorageProduct } from '../../redux/selectors/storage.selector';
import { fetchListStorage, productIdSeleted } from '../../redux/slices/storage.slice';
import { ChoosFilter, FilterControls } from '../Controls/FilterControls';
import { getHeightFit } from '../Helper/UIHelper';
import StorageBar from '../Storages/StorageBar';
import StorageProductUpdateModal from '../Storages/StorageProductUpdateModal';

export default function StoreManagerView() {
    const dispatch = useDispatch();
    const listStorageProduct = useSelectorRoot(getListStorageProduct);
    const fetchStorageLoading = useSelectorRoot(getFetchStorageLoading);
    const [productVersions, setProductVersions] = useState<IStoreProductVersion[]>([]);
    const [dataSource, setDataSource] = useState<ProductVersionResponse[]>([]);
    const searchRef = useRef<Input>(null);
    const [selectedRow, setSelectedRow] = useState<ProductVersionResponse[]>([]);

    const [filters, setFilters] = useState<FilterItem[]>([]);

    useShortKey(KeyCode.F3, () => searchRef.current?.focus());

    useEffect(() => {
        dispatch(fetchListStorage());
        setFilters([{ type: 0, closeable: false }, { type: 1, closeable: true }, { type: 2, closeable: true }]);
    }, [dispatch])

    useEffect(() => {

        if (Object.keys(listStorageProduct as any).length !== 0) {
            const products = Object.values(listStorageProduct);

            console.log(products);
            const groupBy = _.groupBy(products, 'id');
            const result = Object.values(groupBy).map(v => {
                const productVersionWarehousesFlat = v.map(v => v.productVersionWarehouses).flat();
                const groupByWarehouse = _.groupBy(productVersionWarehousesFlat, 'id');
                //const productVersionWarehouses = Object.values(groupByWarehouse).map(x => ({ ...x[0], remainQuantities: x.reduce((p, c) => p + c.remainQuantities, 0) }));
                const productVersionWarehouses = Object.values(groupByWarehouse).map(x => ({ ...x[0], remainQuantities: x.reduce((p, c) => p + (c?.remainQuantities ?? 0), 0) }));
                return {
                    ...v[0],
                    productVersionWarehouses,
                }
            });
            setDataSource(result);
        }
        else
            setDataSource([]);
    }, [listStorageProduct])


    const getTotalRemain = (record: ProductVersionResponse): number => {
        const sumWarehouse = record.productVersionWarehouses.reduce((p, c) => p + c.remainQuantities, 0);
        const sumAngency = record.productVersionAgencies.reduce((p, c) => p + c.remainQuantities, 0);
        return sumWarehouse + sumAngency;
    }

    const handleSelectRecord = (record: ProductVersionResponse) => {
        dispatch(productIdSeleted(record.id))
    }

    const columns: ColumnsType<ProductVersionResponse> = [
        {
            title: 'Tên sản phẩm',
            dataIndex: 'fullName',
            key: 'fullName',
            width: '45%',
            showSorterTooltip: false,
            sorter: (a, b) => a.fullName < b.fullName ? 1 : -1,
            render: (fullName: string, record: ProductVersionResponse) => (
                <Typography.Link>
                    <span onClick={() => handleSelectRecord(record)}>{fullName}</span>
                </Typography.Link>
            )
        },
        {
            title: 'Mã SP',
            dataIndex: 'productCode',
            key: 'productCode',
            width: '15%',
            showSorterTooltip: false,
            sorter: (a, b) => a.productCode < b.productCode ? 1 : -1,
        },
        {
            title: 'Tồn kho',
            dataIndex: 'remain',
            key: 'remain',
            width: '15%',
            showSorterTooltip: false,
            sorter: (a, b) => getTotalRemain(a) < getTotalRemain(b) ? 1 : -1,
            render: (_: any, record: ProductVersionResponse) => (
                <span>{getTotalRemain(record)}</span>
            )
        },
        {
            title: 'Định mức',
            dataIndex: 'productWarningThreshold',
            key: 'productWarningThreshold',
            width: '15%',
            showSorterTooltip: false,
            sorter: (a, b) => a.productWarningThreshold < b.productWarningThreshold ? 1 : -1,
        },
        {
            title: 'Giá bán',
            dataIndex: 'salePrice',
            key: 'salePrice',
            width: '15%',
            align: 'right',
            showSorterTooltip: false,
            sorter: (a, b) => a.salePrice < b.salePrice ? 1 : -1,
            render: (salePrice: number) => (
                <span>{MathHelper.formatPriceVND(salePrice, true)}</span>
            )
        },
        {
            title: 'Giá nhập',
            dataIndex: 'priceInput',
            key: 'priceInput',
            width: '15%',
            align: 'right',
            showSorterTooltip: false,
            sorter: (a, b) => a.priceInput < b.priceInput ? 1 : -1,
            render: (priceInput: number) => (
                <div>{canInputStore() ? MathHelper.formatPriceVND(priceInput, true) : "Khóa"}</div>
            )
        },
    ];

    const SearchWarrantiesSubmit = () => {
        // VA not sure
        const search = searchRef.current?.input.value.toLowerCase();
        if (search == null || search === "") { }
        else {
            const result = productVersions.filter(x => x.productName.toLowerCase().includes(search) ||
                x.productCode.toLowerCase().includes(search))

            setProductVersions(result);
        }


    };

    //Xử lý cho tình huống xóa hết text
    const searchTextChange = () => {
        try {
            const search = searchRef.current?.input.value.toLowerCase();
            // VA not sure
            if (search == null || search === "")
                setProductVersions(productVersions);
        } catch (error) {

        }

    }

    function onChangeFilter() {

    }

    return (
        < div>
            <Row wrap={false}>
                <Col flex="none" >
                    <Space direction="horizontal" style={{ margin: '0px' }}>
                        <StorageBar />

                        <ChoosFilter filterList={filters} onChangeFunc={(items: FilterItem[]) => setFilters(items)} />

                    </Space>
                </Col>
                <Col flex="auto" style={{ padding: '0 16px' }}>
                    <Input
                        prefix={<SearchOutlined />}
                        allowClear={true}
                        ref={searchRef}
                        placeholder="Tìm kiếm theo kho (F3)"
                        onChange={searchTextChange}
                        onPressEnter={SearchWarrantiesSubmit} />
                </Col>

                <Col flex="none" >
                    <Button type="primary" icon={<PlusOutlined />} href="/admin/inventory" size="middle">
                        Kiểm hàng
                    </Button>
                </Col>
            </Row>

            <FilterControls items={filters} afterChangeFnc={onChangeFilter} />

            <div style={{ height: "100%" }}>
                <Table
                    columns={columns}
                    loading={fetchStorageLoading}
                    dataSource={dataSource}
                    pagination={{ pageSize: 20 }}
                    bordered
                    scroll={{ x: '900px', y: getHeightFit(300) }}
                    rowKey={'id'}
                />
            </div>

            <StorageProductUpdateModal />

        </div>

    )
}