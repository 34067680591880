


export interface IVoucherItem {
    name: string,
    code: string,
    startTime: string,
    endTime: string,
    saleOff: number,
}

export type VoucherResponse = {
    id: string,
    name: string,
    code: string,
    startTime: Date,
    endTime: Date,
    saleOff: number,
}

export interface VoucherModalAction {
    data: VoucherResponse | undefined,
    action: 'create' | 'update'
}

export interface VoucherUpdateData {
    id: string,
    requestData: IVoucherItem,
}

export enum PaymentOptionEnum {
    Cod = "Thanh toán khi nhận hàng (COD)",
    Banking = "Chuyển khoản ngân hàng",
    Cash = "Tiền mặt",
}

export interface IStepAction {
    title: string,
    btnAction: string,
    status: number,
}

export interface ICustomerInfo {
    fullName: string,
    phoneNumber: string,
    address: string,
}

export enum DiscountType {
    Value = 'value',
    Percent = 'percent',
}

export enum KeyCode {
    F3 = 'F3',
    F4 = 'F4',
}


export enum MaintenanceStage {
    Request = 0,
    Approved = 1,
    Decline = 2,
}

export enum MaintenanceAction {
    Register = 3,
    Maintaining = 4,
    Completed = 5
}

export enum WarrantyStageStatus {
    Request = 0,
    Approved = 1,
    Declined = 2
}

export enum WarrantyActionStatus {
    Received = 3,
    Processing = 4,
    Completed = 5,
    Returned = 6
}

export enum WarrantyOrderStatus {
    Created = 0,
    Approved = 1,
    Decline = 2,
    Exported = 3,
}

export enum ProductAttibuteType {
    Color = 0,
    Material,
    Sizem
}

export enum CustomerTypeEnum {
    WalkIn,
    Loyal,
    Unknown
}

export enum InventoryStatusEnum {
    Created = 0,
    Checked,
    Approved,
    Declined,
    Balanced,
}

export enum InventoryStatusEnumCommand {
    Approve = 0,
    Decline
}

export enum ProductTypes {
    Main = 0,
    Accessory = 1
}

export enum DefectProductResponseStatus {
    Inquiry,
    Fixing,
    Fixed,
    Blacklisted,
    WhiteListed,
    Returned,
    Imported,
    RemoveAccessory
}

export enum DefectProductOrderResponseStatus {
    Created,
    Updated,
    Approved,
    Declined,
    Exported,
    Imported
}

export enum DefectProductStatusUpdate {
    Fixing,
    Fixed,
    Blacklisted,
    WhiteListed
}

export enum ProductDefectOrderStatusEnumQuery {
    Created,
    Approved,
    Decline,
    Exported
}


export enum PurchaseStatus {
    Created = 0,
    Updated = 1,
    Approved = 2,
    Stored = 3,
    Cancelled = 4
}

export enum OrderReturnStatus {
    Created = 0,
    Processing,
    NeedRefund,
    AllDeclined,
    Processed,
    Completed,
}

export enum ReturnOrderProcess {
    Processing = 0,
    Processed,
}

export enum TechnicalActionEnumCommandEnum {
    Passed = 0,
    Failed,
}

export enum TechnicalActionEnum {
    Passed = 2,
    Failed = 3,
}

export enum StorageType {
    Warehouse = 'Warehouse',
    Agency = 'Agency',
    All = 'All',
}

export enum OrderStatus {
    Pending = 0,
    Updated,
    Processing,
    Packaging,
    ToDeliver,
    Completed,
    Cancelled,
    Activated
}

export enum PaymentType {
    COD,
    Bank,
    Cash
}

export enum OrderHistoryStatus {
    created = 0,
    Updated,
    Approve,
    Packaging,
    ToDeliver,
    Completed,
    Cancelled,
    Activated
}

export enum filterTypes {
    WAREHOUSE = 0,
    STAFF = 1,
    CUSTOMER = 2,
    CATEGORY = 3,
    AGENCY = 4,
    PROVIDER = 5,
    PAYMENT_TYPES = 6,
    CARRIER = 7,
    PRODUCT = 8,
}

export enum InventoryStatus
{
    Created,
    Checked,
    Approved,
    Declined,
    Balanced
}