import { Button, Divider, Form, message, Popover, PopoverProps, Select, Space, Tag } from "antd";
import { CaretDownOutlined } from '@ant-design/icons';
import { ReactNode, useEffect, useState } from "react";
import CCombobox from "./CCombobox";
import { GetWareHousesApi } from "../../Api/WarehouseApi";
import { GetAllStaffsApi } from "../../Api/StaffApi";
import { StaffResponse } from "../../Model/StaffModel";
import { filterTypes } from "../../common/type-define";
import { GetCustomersApi } from "../../Api/CustomerApi";
import { CustomerRes } from "../../Model/CustomerModel";
import { GetAgenciesApi } from "../../Api/AgencyApi";
import { ProviderResponse } from "../../Model/ProductModel";
import { GetProvidersApi } from "../../Api/ProviderApi";
import { CarrierModelResponse } from "../../Model/CarrierModel";
import { GetCarriersApi } from "../../Api/CarrierApi";

type FilerProps = {
    items: FilterItem[],
    afterChangeFnc?: Function
}
export function FilterControls(props: FilerProps) {
    const { items, afterChangeFnc } = props;

    const [redisplay, setRedisplay] = useState<boolean>(true);
    useEffect(() => {

    }, [items, redisplay])

    function display(item: FilterItem): string {

        if (item.type === filterTypes.WAREHOUSE) {
            let content: string = "";
            item.defaultValue?.forEach(x => {
                content += x.name
            })
            return `Chi nhánh:${content}`;
        }
        else if (item.type === filterTypes.STAFF) {
            let content: string = "";
            item.defaultValue?.forEach(x => {
                content += x.name
            })
            return `NV:${content}`;
        }
        else if (item.type === filterTypes.AGENCY) {
            let content: string = "";
            item.defaultValue?.forEach(x => {
                content += x?.name
            })
            return `Đại Lý:${content}`;
        }
        else if (item.type === filterTypes.CUSTOMER) {
            let content: string = "";
            item.defaultValue?.forEach(x => {
                content += x.fullName
            })
            return `Khách:${content}`;
        }
        else if (item.type === filterTypes.PROVIDER) {
            let content: string = "";
            item.defaultValue?.forEach(x => {
                content += x.name
            })
            return `NCC:${content}`;
        }
        else if (item.type === filterTypes.CARRIER) {
            let content: string = "";
            item.defaultValue?.forEach(x => {
                content += x.name
            })
            return `NCC:${content}`;
        }
        return ""
    }


    function closeFilterHandle(item: FilterItem) {
        item.defaultValue = []
        setRedisplay(!redisplay);
        afterChangeFnc?.(items);

    }

    return (
        <div style={{ marginTop: 5 }}>
            {items.map(x => {
                if (x.defaultValue) {
                    return (
                        <Tag style={{ borderRadius: '10px', borderWidth: 1, padding: 5 }}
                            visible={x.defaultValue?.length > 0}
                            closable={x.closeable} onClose={(e) => closeFilterHandle(x)}> {display(x)}</Tag>
                    )
                }
            })}
        </div>
    )
}





type ChooseFilerProps = PopoverProps & {
    filterList: FilterItem[],
    onChangeFunc: Function,
}
export function ChoosFilter(props: ChooseFilerProps) {

    const [formData] = Form.useForm();
    const { filterList, onChangeFunc, ...rest } = props;
    const [visible, setVisible] = useState<boolean>(false);
    const [wareHouses, setWarehouses] = useState<WareHouseResponse[]>([]);
    const [staffs, setStaffs] = useState<StaffResponse[]>([]);
    const [agencies, setAggencies] = useState<AgencyResponse[]>([]);
    const [customers, setCustomers] = useState<CustomerRes[]>([]);
    const [providers, setProviders] = useState<ProviderResponse[]>([]);
    const [carriers, setCarriers] = useState<CarrierModelResponse[]>([]);
    useEffect(() => {
        if (visible) {
            filterList.forEach(x => {
                if (x.type == filterTypes.WAREHOUSE) {
                    GetWareHousesApi((data: IListItems<WareHouseResponse>) => {
                        setWarehouses(data.items);
                    })

                    if (x.defaultValue && x.defaultValue.length > 0)
                        formData.setFieldsValue({ warehosueId: x.defaultValue[0].id })
                    else
                        formData.setFieldsValue({ warehosueId: "" })
                }
                else if (x.type == filterTypes.STAFF) {
                    GetAllStaffsApi((data: IListItems<StaffResponse>) => {
                        setStaffs(data.items);
                    })

                    if (x.defaultValue && x.defaultValue.length > 0)
                        formData.setFieldsValue({ staffId: x.defaultValue[0].id })
                    else
                        formData.setFieldsValue({ staffId: "" })
                }
                else if (x.type == filterTypes.CUSTOMER) {
                    GetCustomersApi((data: IListItems<CustomerRes>) => {
                        setCustomers(data.items);
                    })

                    if (x.defaultValue && x.defaultValue.length > 0)
                        formData.setFieldsValue({ customerId: x.defaultValue[0].id })
                    else
                        formData.setFieldsValue({ customerId: "" })
                }
                else if (x.type == filterTypes.AGENCY) {
                    GetAgenciesApi((data: IListItems<AgencyResponse>) => {
                        setAggencies(data.items);
                    })

                    if (x.defaultValue && x.defaultValue.length > 0)
                        formData.setFieldsValue({ agencyId: x.defaultValue[0].id })
                    else
                        formData.setFieldsValue({ agencyId: "" })
                }
                else if (x.type == filterTypes.PROVIDER) {
                    GetProvidersApi((data: IListItems<ProviderResponse>) => {
                        setProviders(data.items);
                    })

                    if (x.defaultValue && x.defaultValue.length > 0)
                        formData.setFieldsValue({ providerId: x.defaultValue[0].id })
                    else
                        formData.setFieldsValue({ providerId: "" })
                }
                else if (x.type == filterTypes.CARRIER) {
                    GetCarriersApi((data: IListItems<CarrierModelResponse>) => {
                        setCarriers(data.items);
                    })

                    if (x.defaultValue && x.defaultValue.length > 0)
                        formData.setFieldsValue({ providerId: x.defaultValue[0].id })
                    else
                        formData.setFieldsValue({ providerId: "" })
                }
            })
        }

    }, [visible])

    function getWidthPopup(): number {
        if (filterList.length < 3)
            return filterList.length * 210;

        return 3 * 210;
    }

    // Áp dụng lọc
    function filterhandle() {
        const filteredItems: FilterItem[] = [];
        formData.validateFields().then((data) => {

            filterList.forEach(x => {
                if (x.type === filterTypes.WAREHOUSE) {
                    if (data.warehosueId) {
                        filteredItems.push({
                            type: x.type,
                            closeable: x.closeable,
                            defaultValue: [wareHouses.find(y => y.id == data.warehosueId)]
                        });
                    }

                }
                else if (x.type === filterTypes.STAFF) {
                    if (data.staffId) {
                        filteredItems.push({
                            type: x.type,
                            closeable: x.closeable,
                            defaultValue: [staffs.find(y => y.id == data.staffId)]
                        });
                    }
                }
                else if (x.type === filterTypes.AGENCY) {
                    if (data.agencyId) {
                        filteredItems.push({
                            type: x.type,
                            closeable: x.closeable,
                            defaultValue: [agencies.find(y => y.id == data.agencyId)]
                        });
                    }
                }
                else if (x.type === filterTypes.CUSTOMER) {
                    if (data.customerId) {
                        filteredItems.push({
                            type: x.type,
                            closeable: x.closeable,
                            defaultValue: [customers.find(y => y.id == data.customerId)]
                        });
                    }
                }
                else if (x.type === filterTypes.PROVIDER) {
                    if (data.providerId) {
                        filteredItems.push({
                            type: x.type,
                            closeable: x.closeable,
                            defaultValue: [providers.find(y => y.id == data.providerId)]
                        });
                    }
                }
                else if (x.type === filterTypes.CARRIER) {
                    if (data.staffId) {
                        filteredItems.push({
                            type: x.type,
                            closeable: x.closeable,
                            defaultValue: [carriers.find(y => y.id == data.carrierId)]
                        });
                    }
                }
                else {
                    filteredItems.push(x);
                }
            })
            onChangeFunc?.(filteredItems);
        })


        setVisible(false);
    }

    function filterUI(): JSX.Element {
        return (
            <Form layout="vertical" form={formData}>
                <Space direction="horizontal" style={{ maxWidth: 900, width: getWidthPopup() }} wrap>

                    {
                        filterList.map(x => {
                            if (x.type == filterTypes.WAREHOUSE) {
                                return (<Form.Item label="Chi nhánh" name="warehosueId">
                                    <CCombobox chooseOptions={wareHouses} displayName="name" id="id" style={{ width: 200 }} />
                                </Form.Item>)
                            }
                            else if (x.type == filterTypes.STAFF) {
                                return (<Form.Item label="Nhân Viên" name="staffId">
                                    <CCombobox chooseOptions={staffs} displayName="name" id="id" style={{ width: 200 }} />
                                </Form.Item>)
                            }
                            else if (x.type == filterTypes.CUSTOMER) {
                                return (<Form.Item label="Khách hàng" name="customerId">
                                    <CCombobox chooseOptions={customers} displayName="fullName" id="id" style={{ width: 200 }} />
                                </Form.Item>)
                            }
                            else if (x.type == filterTypes.AGENCY) {
                                return (<Form.Item label="Đại lý" name="agencyId">
                                    <CCombobox chooseOptions={agencies} displayName="name" id="id" style={{ width: 200 }} />
                                </Form.Item>)
                            }
                            else if (x.type == filterTypes.PAYMENT_TYPES) {
                                return (<Form.Item label="Phương thức thành toán" name="paymentType">
                                    <CCombobox chooseOptions={agencies} displayName="name" id="id" style={{ width: 200 }} />
                                </Form.Item>)
                            }
                            else if (x.type == filterTypes.PROVIDER) {
                                return (<Form.Item label="Nhà cung cấp" name="providerId">
                                    <CCombobox chooseOptions={providers} displayName="name" id="id" style={{ width: 200 }} />
                                </Form.Item>)
                            }
                            else if (x.type == filterTypes.CARRIER) {
                                return (<Form.Item label="Đơn vị vận chuyển" name="carrierId">
                                    <CCombobox chooseOptions={carriers} displayName="name" id="id" style={{ width: 200 }} />
                                </Form.Item>)
                            }
                        })
                    }

                </Space>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'end'
                    }}>
                    <Space direction="horizontal">
                        <Button
                            onClick={() => { setVisible(false) }}>
                            Thoát
                        </Button>

                        <Button type="primary"
                            onClick={filterhandle}>
                            Lọc
                        </Button>
                    </Space>

                </div>

            </Form>
        )
    }

    function handleVisibleChange(vis: boolean) {
        setVisible(vis);
    }
    return (
        <Popover placement="bottomRight" {...rest}
            visible={visible}
            onVisibleChange={handleVisibleChange}
            getPopupContainer={(triggerNode) => triggerNode} title={null} trigger="click" content={filterUI()}>

            <Button>
                <Space direction="horizontal">
                    <span>Bộ lọc</span>
                    <CaretDownOutlined />
                </Space>
            </Button>

        </Popover>
    )
}