import { AxiosRequest } from "./Axios/AxiosRequest";

import { IProviderRequest } from "../Model/ProviderModel";
import { HandleRequest } from "./RequestAPI";
import { SERVER_API } from "./UrlDefination";


export const GetProvidersApi = async (doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.PROVIDER.GET}`), doAfterResponse);
}

export const CreateProviderApi = async (data: IProviderRequest, doAfterResponse: Function) => {

    await HandleRequest(AxiosRequest.post(`${SERVER_API.HOST}/${SERVER_API.PROVIDER.CREATE}`, data), doAfterResponse);
}

export const UpdateProviderApi = async (id: string, data: IProviderRequest, doAfterResponse: Function) => {

    await HandleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.PROVIDER.UPDATE(id)}`, data), doAfterResponse);
}