import { AxiosResponse } from "axios";
import { AxiosRequest } from "./Axios/AxiosRequest";
import { RequestHandler } from "./RequestAPI";
import { SERVER_API } from "./UrlDefination";
import { HandleRequest } from "./RequestAPI";

export class WarehouseApi {

    static async getWarehouses(): Promise<AxiosResponse<IListItems<WareHouseResponse>, any>> {
        return RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.WAREHOUSE.GET}`));
    }
    static async getProductVersionByWarehouseIds(warehouseIds: string[]): Promise<AxiosResponse<IListItems<ProductVersionResponse>, any>> {
        const params = warehouseIds.map(id => `WarehouseIds=${id}`).join('&');
        return RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.WAREHOUSE.GET_PRODUCT_VERSION_BY_IDS(params)}`));
    }

    static async initProductVersionToWarehouse(data: any): Promise<AxiosResponse<any>> {
        return RequestHandler.handleRequest(AxiosRequest.post(`${SERVER_API.HOST}/${SERVER_API.WAREHOUSE.CREATE_VERSION_TO_STORE}`, data));
    }
}

export const GetWareHousesApi = async (doAfterResponse: Function) => {

    await HandleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.WAREHOUSE.GET}`), doAfterResponse);

}

export const CreateWareHouseApi = async (data: WareHouseRequest, doAfterResponse: Function) => {

    await HandleRequest(AxiosRequest.post(`${SERVER_API.HOST}/${SERVER_API.WAREHOUSE.CREATE}`, data), doAfterResponse);

}

export const UpdateWareHouseApi = async (id: string, data: WareHouseRequest, doAfterResponse: Function) => {

    await HandleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.WAREHOUSE.UPDATE(id)}`, data), doAfterResponse);
}