import { useEffect } from "react";
import { filter, fromEvent, map } from "rxjs";
import { KeyCode } from "../common/type-define";

export const useShortKey = (keyBind: KeyCode | undefined, action: Function) => {

    useEffect(() => {
        if (!keyBind) return;

        const keyDown = fromEvent<KeyboardEvent>(document, 'keydown').pipe(
            filter(e => e.key === keyBind),
            map(key => {
                key.preventDefault();
                action && action();
            })
        ).subscribe();

        return () => keyDown && keyDown.unsubscribe();

    }, [action, keyBind])
}