import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../configure/rootReducer";


export const getRoleList = createSelector(
    (rootState: RootState) => rootState.rolesAction,
    (state) => state.roleList,
)

export const getRoleGroupDetails = createSelector(
    (rootState: RootState) => rootState.rolesAction,
    (state) => state.roleGroupDetails,
)

export const getRoleGroup = createSelector(
    (rootState: RootState) => rootState.rolesAction,
    (state) => state.roleGroup,
)

export const getFetchRoleGroupLoading = createSelector(
    (rootState: RootState) => rootState.rolesAction,
    (state) => state.fetchRoleGroupLoading,
)

export const getRoleModalVis = createSelector(
    (rootState: RootState) => rootState.rolesAction,
    (state) => state.roleModalVis,
)