import {
    BarChartOutlined, MoneyCollectOutlined,
    SettingOutlined, ShopOutlined,
    SkinOutlined, UserOutlined,
    SnippetsOutlined, RedditOutlined,
    AccountBookOutlined
} from '@ant-design/icons';
import { Image, Layout, Menu, Row } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, Outlet } from 'react-router-dom';
import { CommonHelper } from '../Helper/CommonHelper';
import { useSelectorRoot } from '../redux/configure/store';
import { getCollapsedMenu, getHeaderTitle } from '../redux/selectors/pages.selector';
import { toggleCollapsedMenu } from '../redux/slices/pages.slice';
import bigLogo from '../resources/pv-logo-01.png';
import smallLogo from '../resources/pv-logo-small.png';
import AdminHeader from './adminHeader';

const { Content, Sider } = Layout;
const { SubMenu } = Menu;

interface NaviMenu {
    icon: React.ReactNode,
    title: React.ReactNode,
    linkTo: string,
    permission: string[],
    checkAgencyAccount?: 'agency' | 'roleAgency',
}

interface NaviSubMenu {
    icon: React.ReactNode,
    title: React.ReactNode,
    menus: NaviMenu[],
}

const MENU_DEFINE: (NaviMenu | NaviSubMenu)[] = [
    {
        icon: <BarChartOutlined />,
        title: CommonHelper.getDashboardTitle(),
        linkTo: "/admin/dashboard",
        permission: ["CreateOrder", "ViewOrder", "ProcessOrder"],
    },
    {
        icon: <ShopOutlined />,
        title: "Bán hàng",
        menus: [
            {
                icon: null,
                title: "Tạo đơn và giao hàng",
                linkTo: "/admin/orders/create",
                permission: [],
            },
            {
                icon: null,
                title: "Danh sách đơn hàng",
                linkTo: "/admin/orders",
                permission: [],
            },
            {
                icon: null,
                title: "Danh sách trả hàng",
                linkTo: "/admin/orders/order_returns",
                permission: ["CreateOrder", "CancelOrder", "Technical"],
            },

        ]
    },
    {
        icon: <SkinOutlined />,
        title: "Sản Phẩm",
        menus: [
            {
                icon: null,
                title: "Tạo sản phẩm",
                linkTo: "/admin/products/create",
                permission: ["CreateProduct"],
                checkAgencyAccount: 'roleAgency',
            },
            {
                icon: null,
                title: "Danh sách sản phẩm",
                linkTo: "/admin/products",
                permission: [],
            }
        ]
    },
    {
        icon: <MoneyCollectOutlined />,
        title: "Nhập hàng",
        menus: [
            {
                icon: null,
                title: "Tạo đơn nhập hàng",
                linkTo: "/admin/purchase_orders/create",
                permission: ["ImportProduct"],
                checkAgencyAccount: 'roleAgency',
            },
            {
                icon: null,
                title: "Danh sách nhập hàng",
                linkTo: "/admin/purchase_orders",
                permission: ["ImportProduct"],
                checkAgencyAccount: 'roleAgency',
            },
            {
                icon: null,
                title: "Quản lý kho",
                linkTo: "/admin/variants",
                permission: [],
            },
            {
                icon: null,
                title: "Nhà cung cấp",
                linkTo: "/admin/providers",
                permission: ["ImportProduct"],
                checkAgencyAccount: 'roleAgency',
            },
        ]
    },
    {
        icon: <RedditOutlined />,
        title: "Kỹ thuật",
        menus: [
            {
                icon: null,
                title: "Bảo hành-Bảo trì",
                linkTo: "/admin/warranties",
                permission: ["Technical", "CancelOrder"],
                checkAgencyAccount: 'roleAgency',
            },
            {
                icon: null,
                title: "Sản phẩm lỗi",
                linkTo: "/admin/defect_products",
                permission: ["Technical", "SuperAdmin"],
                checkAgencyAccount: 'roleAgency',
            },
        ]
    },
    {
        icon: <UserOutlined />,
        title: "Khách hàng",
        menus: [
            {
                icon: null,
                title: "Danh sách khách hàng",
                linkTo: "/admin/customers",
                permission: [],
            },
            {
                icon: null,
                title: "Đại lý",
                linkTo: "/admin/customers/agencies",
                permission: ["SuperAdmin"],
                checkAgencyAccount: 'roleAgency',
            },
            {
                icon: null,
                title: "Cộng tác viên",
                linkTo: "/admin/customers/partner",
                permission: ["SuperAdmin"],
                checkAgencyAccount: 'roleAgency',
            },
        ]
    },
    {
        icon: <AccountBookOutlined />,
        title: "Sổ quỹ",
        checkAgencyAccount: 'roleAgency',
        menus: [
            {
                icon: null,
                title: "Phiếu thu",
                linkTo: "/admin/bills/income",
                permission: [],
                checkAgencyAccount: 'roleAgency',
            },
            {
                icon: null,
                title: "phiếu chi",
                linkTo: "/admin/bills/reception",
                permission: [],
                checkAgencyAccount: 'roleAgency',
            },
            {
                icon: null,
                title: "Sổ quỹ",
                linkTo: "/admin/report/bills",
                permission: [],
                checkAgencyAccount: 'roleAgency',
            },
        ]
    },
    {
        icon: <SnippetsOutlined />,
        title: "Báo cáo",
        permission: ["SuperAdmin"],
        checkAgencyAccount: 'roleAgency',
        menus: [
            {
                icon: null,
                title: "Báo cáo bán hàng",
                linkTo: "/admin/report/sale",
                permission: ["SuperAdmin"],
                checkAgencyAccount: 'roleAgency',
            },
            {
                icon: null,
                title: "Báo cáo nhập hàng",
                linkTo: "/admin/report/purchase",
                permission: ["SuperAdmin"],
                checkAgencyAccount: 'roleAgency',
            },
            {
                icon: null,
                title: "Báo cáo kho",
                linkTo: "/admin/report/store",
                permission: ["SuperAdmin"],
                checkAgencyAccount: 'roleAgency',
            },
            {
                icon: null,
                title: "Báo cáo tài chính",
                linkTo: "/admin/report/finance",
                permission: ["SuperAdmin"],
                checkAgencyAccount: 'roleAgency',
            },
            {
                icon: null,
                title: "Báo cáo khách hàng",
                linkTo: "/admin/report/customer",
                permission: ["SuperAdmin"],
                checkAgencyAccount: 'roleAgency',
            },
            {
                icon: null,
                title: "Báo cáo bảo hành-bảo trì",
                linkTo: "/admin/report/warranty",
                permission: ["SuperAdmin"],
                checkAgencyAccount: 'roleAgency',
            },
        ]
    },
    {
        icon: <SettingOutlined />,
        title: "Cấu hình",
        linkTo: "/admin/settings",
        permission: ["SuperAdmin"],
        checkAgencyAccount: 'roleAgency'
    },

]

function AdminControl() {
    const dispatch = useDispatch();
    const collapsedMenu = useSelectorRoot(getCollapsedMenu);
    const headerTitle = useSelectorRoot(getHeaderTitle);

    const onCollapse = () => {
        dispatch(toggleCollapsedMenu());
    }

    function ExpandContent() {
        return (
            <Row justify="center" style={{ height: 80, display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                <Image src={bigLogo} preview={false} onClick={onCollapse} style={{ padding: 4, height: 60 }} />
            </Row>
        )
    }
    function CollapseContent() {
        return (
            <Row justify="center" style={{ height: 80, display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                <Image src={smallLogo} onClick={onCollapse} preview={false} style={{ padding: 4, height: 40 }} />
            </Row>

        )
    }

    return (
        <Layout style={{ minHeight: '100vh', overflow: 'hidden' }}>
            <Sider trigger={null} collapsible collapsed={collapsedMenu} width={250}>
                <div style={{ display: "flex", color: "white" }} >
                    <div style={{ flexGrow: 1, textAlign: "end" }}>
                        {React.createElement(collapsedMenu ? CollapseContent : ExpandContent, {
                            className: 'trigger',
                            onClick: onCollapse,
                        })}
                    </div>

                </div>
                <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" style={{ maxHeight: '100vh', overflow: 'auto', userSelect: 'none' }}>
                    {
                        MENU_DEFINE.map((val, i) => {
                            if ('menus' in val) {
                                return (
                                    <SubMenu key={`menu${i}`} icon={val.icon}
                                        title={val.title} >
                                        {
                                            val.menus.map(item => (
                                                <Menu.Item
                                                    key={item.linkTo}
                                                    icon={item.icon}
                                                    hidden={!CommonHelper.checkMenuPermission(item.permission, item.checkAgencyAccount)}>
                                                    <span>{item.title}</span>
                                                    <Link to={item.linkTo} />
                                                </Menu.Item>
                                            ))
                                        }
                                    </SubMenu>
                                )
                            } else {
                                return (
                                    <Menu.Item
                                        key={val.linkTo}
                                        icon={val.icon}
                                        hidden={!CommonHelper.checkMenuPermission(val.permission, val.checkAgencyAccount)}>
                                        <span>{val.title}</span>
                                        <Link to={val.linkTo} />
                                    </Menu.Item>
                                )
                            }
                        })
                    }
                </Menu>
            </Sider>
            <Layout className="site-layout">
                <AdminHeader title={headerTitle} />
                <Content style={{ margin: '0 16px' }}>
                    <Outlet />
                </Content>
            </Layout>
        </Layout>

    )
}

export default AdminControl;