import { AxiosRequest } from "./Axios/AxiosRequest";

import { StaffRequest } from "../Model/StaffModel";
import { HandleRequest } from "./RequestAPI";
import { SERVER_AUTHEN } from "./UrlDefination";

export const GetAllStaffsApi = async (doAfterResponse: Function) => {

    HandleRequest(AxiosRequest.get(`${SERVER_AUTHEN.HOST}/${SERVER_AUTHEN.STAFF.GET}`), doAfterResponse);

}

export const CreateStaffsApi = async (data: StaffRequest, doAfterResponse: Function) => {

    HandleRequest(AxiosRequest.post(`${SERVER_AUTHEN.HOST}/${SERVER_AUTHEN.STAFF.CREATE}`, data), doAfterResponse);

}

export const ChangePasswordStaffsApi = async (data: any, doAfterResponse: Function) => {

    HandleRequest(AxiosRequest.put(`${SERVER_AUTHEN.HOST}/${SERVER_AUTHEN.STAFF.UPDATE}`, data), doAfterResponse);

}

export const DeletUsersApi = async (id: string, doAfterResponse: Function) => {

    HandleRequest(AxiosRequest.delete(`${SERVER_AUTHEN.HOST}/${SERVER_AUTHEN.STAFF.DELETE}`), doAfterResponse);
}
