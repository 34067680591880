import { UploadFile } from "antd/lib/upload/interface";
import { UploadImagesApi } from "../Api/ImagesApi";
import { CreateProductApi, CreateProductVersionsApi } from "../Api/ProductApi";
import { ProductTypes } from "../common/type-define";
import { NewProductVersionData, NewProductVersionMoldel } from "../Model/ProductModel";

export interface NewProductViewModel {
    productName: string;
    productCode: string;
    sku: string;
    categoryId: string;
    productDesc: string;
    factoryId: string;
    warrantyDays: number;
    description: string;
    shortDescription: string;
    unitId: string;
    productType: number; //Cái này sẽ là phụ kiện hoặc sản phẩm chính
    FreeMaintenanceTimes: number;
    warningThreshold: number;
    parentId?: string;
    importAndExportTax: number;
    vat: number;
    salePrice: number;
    inputPrice: number;
    renewInDays: number;
    saleOffPrice: number;
}

export interface ImageInfo {

    imageId: string;
    imagePurpose: number;
    alt: string;
    url: string;
    order: number;
}

export interface ColorVersion {
    colorId: string;
    name: string;
    color: string;
    price?: number;
}

export interface ConfigGroup {
    name: string;
    children: { name: string, value: string }[]
}
function uploadImage(images: UploadFile[], callbackFnc: Function) {
    const formData = new FormData();
    formData.append("UploadForExternalPurpose", '0');
    images.forEach(x => {
        if (x.originFileObj) formData.append("ImageFiles", x.originFileObj);
    });
    // Call Api upload image
    UploadImagesApi(formData, (res: ImageInfo[]) => {
        //After upload image sucess then update product
        const imageIds = res.map(img => img.imageId);
        callbackFnc(imageIds);
    })
}

//Tạo sản phẩm mới thông qua API
export const CreateNewProductFnc = (vmData: NewProductViewModel, images: UploadFile[], colorVersions: ColorVersion[], callbackFnc: Function) => {

    if (!vmData.productName || vmData.productName.length === 0) {
        callbackFnc("Tên sản phẩm không được để trống");
        return;
    }

    if (!vmData.productCode || vmData.productCode.length === 0) {
        callbackFnc("Mã sản phẩm không được để trống");
        return;
    }

    if (!vmData.categoryId || vmData.categoryId.length === 0) {
        callbackFnc("Nhóm sản phẩm không được để trống");
        return;
    }



    const productData: ProductData = {
        categoryId: vmData.categoryId,
        name: vmData.productName,
        description: vmData.productDesc,
        unitId: vmData.unitId,
        code: vmData.productCode,
        factoryId: vmData.factoryId,
        saleable: true,
        defaultWarrantyDays: vmData.warrantyDays * 30,
        shortDescription: vmData.shortDescription,
        sku: vmData.productCode,
        FreeMaintenanceTimes: vmData.FreeMaintenanceTimes,
        productType: vmData.productType,
        warningThreshold: 10,
        vat: vmData.vat,
        importAndExportTax: vmData.importAndExportTax
    }

    if (vmData.productType === ProductTypes.Accessory && vmData.parentId) {
        productData.parentId = vmData.parentId;
        productData.FreeMaintenanceTimes = 1;
        productData.warningThreshold = 1;
    }

    //Tạo ra product và product version
    CreateProductApi(productData, (data: ProductResponse) => {
        const newProductVersionsModels = colorVersions.map(x => ({
            name: x.name,
            remainQuantities: 0,
            salePrice: vmData.salePrice,
            priceInput: vmData.inputPrice,
            saleOffPrice: vmData.saleOffPrice,
            colorId: x.colorId
        } as NewProductVersionMoldel));


        //Sản phẩm mặc định
        // if (newProductVersionsModels.length === 0 && vmData.productType === ProductTypes.Accessory) {
        //     newProductVersionsModels.push({
        //         name: "mặc định",
        //         remainQuantities: 0,
        //         salePrice: vmData.salePrice,
        //         priceInput: vmData.inputPrice,
        //         isAvailable: true
        //     })
        // }

        if (newProductVersionsModels.length > 0) {
            const versionData: NewProductVersionData = {
                productId: data.id,
                productVersionDetails: newProductVersionsModels
            }

            CreateProductVersionsApi(versionData, (res: any) => {
                callbackFnc();
            });
        }

    })
}