import { Card, Col, Row } from "antd";
import { useState } from "react";
import { canAccessConfigure } from "../Helper/CommonHelper";
import LinkItem from "./Controls/LinkItem";
import { CompanyModal } from "./settings/CompanyModal";
import { PaymentsModal } from "./settings/PaymentsModal";

function AdminConfigure() {

    const [isCompanyVisible, setIsCompanyVisible] = useState<boolean>(false);
    const [isPaymentVisible, setIsPaymentVisible] = useState<boolean>(false);

    return (
        <div>
            <Card title="Thông tin về công ty" hidden={!canAccessConfigure()}>
                <Row>
                    <Col span={6}>
                        <LinkItem title="Quản lý chi nhánh"
                            desc="Thêm và điều chỉnh thông tin chi nhánh"
                            url="settings/branchs" />
                    </Col>
                    <Col span={6}>
                        <LinkItem title="Thông tin công ty"
                            desc="Quản lý thông tin liên hệ và địa chỉ của cửa hàng"
                            onClick={() => setIsCompanyVisible(true)} />
                    </Col>
                    <Col span={6}>
                        <LinkItem title="Quản lý nhân viên"
                            desc="Tạo và quản lý tất cả tài khoản nhân viên"
                            url="settings/staffs" />
                    </Col>
                    <Col span={6}>
                        <LinkItem title="Phân quyền vai trò"
                            desc="Tạo, phân quyền và quản lý các vai trò của cửa hàng"
                            url="settings/roles" />
                    </Col>
                </Row>

                <Row>
                    <Col span={6}>
                        <LinkItem title="Trung tâm bảo hành"
                            desc="Tạo,chỉnh sửa thông tin các trung tâm bảo hành"
                            url="settings/warranty_centers" />
                    </Col>
                </Row>
            </Card>

            <Card title="Thông tin về bán hàng">
                <Row>
                    <Col span={6}>
                        <LinkItem title="Kênh bán hàng"
                            desc="Quản lý các kênh bạn sử dụng để bán hàng"
                            url="settings/channels" />
                    </Col>

                    <Col span={6}>
                        <LinkItem title="Khuyến mại"
                            desc="Thiết lập và tuỳ chỉnh khuyến mại"
                            url="settings/vouchers" />
                    </Col>

                    <Col span={6}>
                        <LinkItem title="Thanh toán"
                            desc="Thiết lập các hình thức thanh toán"
                            onClick={() => setIsPaymentVisible(true)} />
                    </Col>
                </Row>

            </Card>
            <CompanyModal title="Thông tin công ty"
                visible={isCompanyVisible}
                okModalFunc={() => setIsCompanyVisible(false)}
                onCancel={() => setIsCompanyVisible(false)}

            />

            <PaymentsModal visible={isPaymentVisible}
                onCancel={() => setIsPaymentVisible(false)}
                okModalFnc={() => setIsPaymentVisible(false)}
                title="Phương thức thanh toán" />

            {/* <div>Đây là mã xác thực dùng trong quá trình phát triển sẽ loại bỏ khi triển khai thực tế</div>
            <TextArea defaultValue={getBearerToken()} rows={4} /> */}

        </div>

    )
}

export default AdminConfigure;