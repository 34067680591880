import jwt_decode from "jwt-decode";
import { AxiosRequest } from "../Api/Axios/AxiosRequest";
import { InventoryStatusEnum, PaymentType } from "../common/type-define";
import { AuthenResponse } from "../Model/LoginModel";

type tokenInfo = {
    email: string,
    exp: number,
    role: string,
    given_name: string,
    organizationId: string,

}

export class CommonHelper {
    static validToken(): boolean {
        try {
            const token = localStorage.getItem('token') || null;
            if (token != null) {
                const token_infor: tokenInfo = jwt_decode(token);
                const date = new Date(0);
                date.setUTCSeconds(token_infor.exp);
                const isValid = new Date().getTime() < date.getTime();
                return isValid;
            }
        } catch (error) {
        }
        return false;
    }

    static getToken(): string | null {
        const token = localStorage.getItem('token') || null;
        return token;
    }

    static isAgencyAccount(): boolean {
        const token = localStorage.getItem('token');
        if (token) {
            const token_infor: tokenInfo = jwt_decode(token);
            if (token_infor.organizationId) return true;
        }
        return false;
    }

    static getRoleAccount(): string {
        let token = localStorage.getItem('token');
        if (token) {
            const token_infor: tokenInfo = jwt_decode(token);
            if (token_infor) return token_infor.role;
        }
        return "";
    }

    static checkMenuPermission(permissions: string[], checkType: 'agency' | 'roleAgency' | undefined): boolean {
        let checkRoleAgency: boolean = false;
        if (checkType === 'agency')
            checkRoleAgency = !this.isAgencyAccount();
        const role = this.getRoleAccount();
        checkRoleAgency = checkType === 'roleAgency' ? !this.isAgencyAccount() : true;
        if (checkType === undefined || (role && checkRoleAgency)) {
            if (!permissions.length)
                return true;
            return permissions.some(p => role.includes(p));
        }
        return false;
    }

    static calculateTotalPriceInOrder(productOrders: IProductOrder[]) {
        return productOrders.reduce((pre, curr) => {
            return (pre + curr.salePrice)
        }, 0);
    }

    static getDashboardTitle(): string {
        return this.isAgencyAccount() ? "Tổng quan đại lý" : "Tổng quan";
    }

    static getPaymentType(paymentTypeNum: number) {
        let paymentType = 'COD';
        switch (paymentTypeNum) {
            case PaymentType.COD.valueOf():
                paymentType = 'COD';
                break;
            case PaymentType.Bank.valueOf():
                paymentType = 'Chuyển khoản';
                break;
            case PaymentType.Cash.valueOf():
                paymentType = 'Tiền mặt';
                break;
        }
        return paymentType;
    }

    static getInventoryTag(status: InventoryStatusEnum) {
        switch (status) {
            case InventoryStatusEnum.Created:
                return {
                    title: "Đã tạo",
                    color: "blue"
                }
            case InventoryStatusEnum.Checked:
                return {
                    title: "Đã kiểm tra",
                    color: "purple"
                }
            case InventoryStatusEnum.Approved:
                return {
                    title: "Chấp nhận",
                    color: "cyan"
                }
            case InventoryStatusEnum.Declined:
                return {
                    title: "Từ chối",
                    color: "red"
                }
            case InventoryStatusEnum.Balanced:
                return {
                    title: "Đã cân bằng",
                    color: "green"
                }
        }
    }

    static calculateDiffDayDuration(startDate: Date, endDate: Date) {
        const one_day = 1000 * 60 * 60 * 24;
        let result
        result = Math.ceil((endDate.getTime() - startDate.getTime()) / (one_day))
        console.log('date Converter result', result)
        if (result < 0) { return 0 }
        return result;
    }

    static calculateDiscount(discount: number, discountType: number, salePrice: number, quanitity = 1) {
        return discountType === 1 ? discount * quanitity * salePrice / 100 : discount * quanitity
    }
}
// return the user data from the session storage
export const getEmail = () => {
    const userStr = localStorage.getItem('email');
    if (userStr) return JSON.parse(userStr);
    else return null;
}

// return the token from the session storage
export const getToken = () => {
    let token = localStorage.getItem('token') || null;
    return token;
}

export const getBearerToken = () => {
    let token = localStorage.getItem('token') || null;
    if (token) {
        return `Bearer ${token}`
    }
    return "";
}

//Check expired of token
export const ValidToken = (): boolean => {

    try {
        let token = localStorage.getItem('token') || null;
        if (token != null) {
            const token_infor: tokenInfo = jwt_decode(token);
            const date = new Date(0);
            date.setUTCSeconds(token_infor.exp);
            const isValid = new Date().getTime() < date.getTime();
            return isValid;
        }
    } catch (error) {

    }

    return false;
}

export const checkRequireLogin = (): boolean => {

    let result = ValidToken();

    //Thêm 1 lần lấy token
    if (result === false) {
        AxiosRequest.getLatestToken().then(() => {
            result = ValidToken();
        });
    }

    return result;
}

export const getRefreshToken = () => {

    let token = sessionStorage.getItem('refreshToken') || null;
    if (token != null) {
        //Kiểm tra xem token còn hạn dùng không

    }
    return token;
}

export const config = {
    Accept: "application/json, text/plain, */*",
    headers: { Authorization: `Bearer ${getToken()}` }
};

// remove the token and user from the session storage
export const removeUserSession = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('email');
}

// set the token and user from the session storage
export const setUserSession = (infor: AuthenResponse, email: string) => {
    localStorage.setItem('token', infor.token);
    localStorage.setItem('refreshToken', infor.refreshToken);
    localStorage.setItem('email', JSON.stringify(email));
}

export const getCurrentName = (): string => {
    let token = localStorage.getItem('token');
    if (token) {
        const token_infor: tokenInfo = jwt_decode(token);
        return token_infor.given_name;
    }

    return "";
}

export const isAgencyAccount = (): boolean => {

    let token = localStorage.getItem('token');
    if (token) {
        const token_infor: tokenInfo = jwt_decode(token);
        if (token_infor.organizationId)
            return true;
    }

    return false;
}

export const getRoleAccount = (): string => {

    let token = localStorage.getItem('token');
    if (token) {
        const token_infor: tokenInfo = jwt_decode(token);
        if (token_infor)
            return token_infor.role;
    }
    return "";
}

export const canCreateOrder = (): boolean => {

    const role = getRoleAccount();
    if (role)
        return role.includes("CreateOrder");
    return false;
}

export const canProcessOrder = (): boolean => {

    const role = getRoleAccount();
    if (role)
        return role.includes("ProcessOrder");
    return false;
}

export const canWarrantyAction = (): boolean => {

    const role = getRoleAccount();
    if (role && !isAgencyAccount())
        return role.includes("Technical") || role.includes("ExportProduct");
    return false;
}

export const canViewSumarry = (): boolean => {

    const role = getRoleAccount();
    if (role)
        return role.includes("CreateOrder") ||
            role.includes("ViewOrder") ||
            role.includes("ProcessOrder");
    return false;

}

export const canReturnOrder = (): boolean => {

    const role = getRoleAccount();
    if (role)
        return role.includes("CreateOrder") ||
            role.includes("CancelOrder") || role.includes("Technical");
    return false;
}

export const isTechnicalRole = (): boolean => {

    const role = getRoleAccount();
    if (role)
        return role.includes("Technical");
    return false;
}

export const canInputStore = (): boolean => {

    const role = getRoleAccount();
    if (role && !isAgencyAccount())
        return role.includes("ImportProduct");
    return false;
}


export const canAccessConfigure = (): boolean => {

    const role = getRoleAccount();
    if (role && !isAgencyAccount())
        return role.includes("SuperAdmin");
    return false;
}




function removeVietnameseTones(str: string) {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");
    // Some system encode vietnamese combining accent as individual utf-8 characters
    // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
    // Remove extra spaces
    // Bỏ các khoảng trắng liền nhau
    str = str.replace(/ + /g, " ");
    str = str.trim();
    // Remove punctuations
    // Bỏ dấu câu, kí tự đặc biệt
    // eslint-disable-next-line no-useless-escape
    str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, " ");
    return str;
}

export function compareString(input: string, search: string): boolean {
    if (input && search) {
        return removeVietnameseTones(input.toLocaleLowerCase()).includes(removeVietnameseTones(search.toLocaleLowerCase()));
    }

    return false;
}


export function convertToNumber(input: string | null): number {
    if (input !== undefined && input !== null) {

        try {
            return Number(input);
        } catch (error) {
            return 0;
        }
    }
    return 0;
}

export function hasNumber(str: string) {
    return /\d/.test(str);
}



