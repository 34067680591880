import {
    LeftOutlined,
    PlusOutlined,
    EditOutlined
} from '@ant-design/icons';
import { Button, Spin, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { GetWareHousesApi } from "../../Api/WarehouseApi";
import { BranchModal } from "./BranchModal";



export function BranchsView() {

    const [branchs, setBranchs] = useState<WareHouseResponse[]>([]);

    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [branchTitle, setbranchTitle] = useState<string>("");
    const [branhcEdit, setbranchEdit] = useState<WareHouseResponse | undefined>()
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        GetWareHousesApi((res: IListItems<WareHouseResponse>) => {

            setBranchs(res.items);
        })
    }, [])

    const showAddModal = () => {

        setbranchTitle("Chi nhánh mới");
        setIsModalVisible(true);
    }

    const applyBranch = (res: WareHouseResponse) => {

        let existBranchs: WareHouseResponse[] = [...branchs];
        if (branhcEdit) {
            const index = existBranchs.findIndex(x => x.id == branhcEdit.id);
            if (index != -1) {
                existBranchs[index] = res;
            }
        }
        else {
            existBranchs.push(res);
        }

        setBranchs(existBranchs);
        setIsModalVisible(false);
        setbranchEdit(undefined);
    }

    const handleUpdateBranchModal = (res: WareHouseResponse) => {

        setbranchTitle("Chỉnh sửa chi nhánh");
        setbranchEdit(res);
        setIsModalVisible(true);
    }


    const columns: ColumnsType<WareHouseResponse> = [
        {
            title: "Tên chi nhánh",
            dataIndex: "name",
            key: "name"
        },
        {
            title: "Người đại diện",
            dataIndex: "representativePerson",
            key: "representativePerson"
        },
        {
            title: "SĐT",
            dataIndex: "hotline",
            key: "hotline"
        },
        {
            title: "Địa chỉ",
            dataIndex: "location",
            key: "location"
        },
        {
            title: "Hành động",
            dataIndex: "id",
            key: "id",
            render: (id, record) => (
                <Tooltip placement="top" title={"Cập nhật thông tin chi nhánh"}>
                    <Button
                        size="small"
                        type="link"
                        onClick={() => handleUpdateBranchModal(record)}
                        icon={<EditOutlined />}>
                        Cập nhật
                    </Button>
                </Tooltip>
            )
        },
    ]
    return (
        <div>
            <div>
                <Button type="text" icon={<LeftOutlined />} href="/admin/settings" size="large">
                    Quay lại cấu hình
                </Button>
            </div>

            <div style={{ display: "flex", height: 50, top: 25 }}>

                <div style={{ fontWeight: 500, fontSize: 25, marginLeft: 20 }}>
                    Danh sách chi nhánh
                </div>
                <div style={{ flexGrow: 1, textAlign: "end" }}>
                    <Button type="primary" icon={<PlusOutlined />} onClick={showAddModal} size="middle">
                        Thêm chi nhánh
                    </Button>
                </div>
            </div>
            <Spin tip="Đang tải dữ liệu" spinning={loading}>
                <Table bordered
                    columns={columns}
                    dataSource={branchs} />
            </Spin>


            <BranchModal title={branchTitle}
                branch={branhcEdit}
                okText="Đồng ý"
                cancelText="Hủy bỏ"
                visible={isModalVisible}
                okModalFunc={applyBranch}
                onCancel={() => setIsModalVisible(false)} />

        </div>
    )
}