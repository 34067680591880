import { DatePicker } from "antd";
import { DatePickerProps, RangePickerProps } from "antd/lib/date-picker";
import { RangePickerDateProps } from "antd/lib/date-picker/generatePicker";
import { subMonths } from "date-fns";
import moment from "moment";
import { Moment } from "moment";
const { RangePicker } = DatePicker;

type RangeDateControlProps = RangePickerDateProps<Moment> &
{
    onChangeDate?: (startDate: Date, endDate: Date) => void;
}

export default function RangeDateControl(props: RangeDateControlProps) {
    const { onChangeDate, ...rest } = props;
    function changeRangeTime(values: any, formatString: [string, string]) {
        const startTime: Moment = values[0];
        const endTime: Moment = values[1];

        onChangeDate?.(startTime.toDate(), endTime.toDate());

    }

    return (
        <RangePicker   {...rest}
            format='DD/MM/YYYY'
            showTime={false}
            clearIcon={false}
            style={{ width: '220px' }}
            onChange={changeRangeTime}
            defaultValue={[moment(subMonths(new Date(), 1)), moment()]} />
    )
}