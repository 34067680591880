
import { CategoryRequest } from '../Model/CategoryModel';
import { AxiosRequest } from './Axios/AxiosRequest';
import { HandleRequest } from './RequestAPI';
import { SERVER_API } from './UrlDefination';


export const GetCategories = async (doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.CATEGORY.GET}`), doAfterResponse);
};


export const CreateCategoryApi = async (data: CategoryRequest, doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.post(`${SERVER_API.HOST}/${SERVER_API.CATEGORY.CREATE}`, data), doAfterResponse);
};