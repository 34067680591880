// import axios from "axios";
import { AxiosResponse } from "axios";
import {
    AuthenResponse,
    // AuthenResponse, 
    LoginInfor
} from "../Model/LoginModel";
import { AxiosRequest } from "./Axios/AxiosRequest";
import { HandleRequest, RequestHandler } from "./RequestAPI";
import { SERVER_AUTHEN } from "./UrlDefination";

export class AuthenApi {
    static async LoginApi(data: LoginInfor): Promise<AxiosResponse<AuthenResponse, any>> {
        return RequestHandler.handleRequest(AxiosRequest.post(`${SERVER_AUTHEN.HOST}/${SERVER_AUTHEN.AUTHEN.LOGIN}`, data, false));
    }

    static async GeneratePasswordTokenApi(email: string): Promise<any> {
        const data = { email: email };
        return RequestHandler.handleRequest(AxiosRequest.post(`${SERVER_AUTHEN.HOST}/${SERVER_AUTHEN.AUTHEN.POST_GENERATE_PW}`, data, false));
    }
}