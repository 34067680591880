import { Modal, Table, ModalProps, Form, Input, Row, Col, message } from "antd";
import { useEffect, useState } from "react";
import { GetWareHousesApi } from "../../Api/WarehouseApi";
import { ColumnsType } from "antd/lib/table";
import CCombobox from "../Controls/CCombobox";
import { DefectProductOrderResponse, DefectProductIOrderResponeponse } from "../../Model/ProductModel";

type ApproveImportDefectProductOrderProps = ModalProps &
{
    approveImportOrder: Function;
    defectProductOrder: DefectProductOrderResponse | undefined;
}

export default function ApproveImportDefectProductOrderModal(props: ApproveImportDefectProductOrderProps) {
    const { approveImportOrder, defectProductOrder, ...rest } = props
    const [wareHouseList, setwareHouseList] = useState<WareHouseResponse[]>([]);
    const [wareHouseId, setWareHouseId] = useState<string>();

    useEffect(() => {
        GetWareHousesApi((res: IListItems<WareHouseResponse>) => {
            setwareHouseList(res.items);
        });

    }, [rest.visible]);

    function chooseWarehouse(value: any) {
        setWareHouseId(value);
    }

    const orderDetails: ColumnsType<DefectProductIOrderResponeponse> = [
        {
            title: 'Tên sản phẩm',
            render: (quantities, record, index) => (
                <span>{record?.productVersion?.fullName}</span>
            ),
        },
        {
            title: 'Số lượng',
            dataIndex: 'quantities',
            key: 'quantities',
            width: 100
        }
    ];

    function approveExportOrderInvoke() {
        approveImportOrder(wareHouseId);
    }

    return (
        <Modal {...rest}
            onOk={approveExportOrderInvoke}>
            <Form layout="vertical">
                <Form.Item label="Mã phiếu">
                    <span>{defectProductOrder?.orderCode}</span>
                </Form.Item>
                <Form.Item label="Kho">
                    <CCombobox
                        chooseOptions={wareHouseList}
                        displayName="name"
                        id="id"
                        onChange={chooseWarehouse} />
                </Form.Item>
            </Form>
            <Form.Item>
                <Table dataSource={defectProductOrder?.orderDetails}
                    style={{ maxHeight: '30vh', overflow: 'auto' }}
                    size="small"
                    columns={orderDetails}
                    pagination={false} />
            </Form.Item>
        </Modal>
    )
}