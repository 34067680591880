import {
    AndroidOutlined, CheckCircleOutlined,
    CheckOutlined, ExclamationCircleOutlined, SearchOutlined, ToolOutlined, VerticalAlignBottomOutlined,
    CloseOutlined
} from '@ant-design/icons';
import { Button, Col, Input, List, message, Modal, Row, Space, Spin, Table, Tag, Tooltip, Form, InputNumber, Popover } from "antd";
import { ColumnsType } from "antd/lib/table";
import { format } from 'date-fns';
import { useEffect, useRef, useState } from "react";
import { DecideMaintenenceRequestApi, GetMaintenancesByIdApi, GetPlannedMaintenacesApi, MaintenanceProcessingApi } from "../../Api/MaintenanceApi";
import { MaintenanceAction, MaintenanceStage } from '../../common/type-define';
import { CustomerRes } from "../../Model/CustomerModel";
import { highMaintenanceRequest, maintenancesResponse } from "../../Model/WarrantiesModel";
import HistoryModal, { HistoryItem } from '../Helper/HistoryModal';
import DecisionModal from "./DecisionModal";

const { confirm } = Modal;

type ActiveMaintenanceTabProps = {
    isActived: boolean
}



export default function ActiveMaintenanceTabView(props: ActiveMaintenanceTabProps) {

    const { isActived } = props;
    const [loading, setloading] = useState(true);
    const [isShowDecision, setIsShowDecision] = useState<boolean>(false);
    const [activeMaintenaces, setActiveMaintenances] = useState<maintenancesResponse[]>([]);
    const [maintenantDecision, setMaintenantDecision] = useState<maintenancesResponse>();
    const [histortItems, setHistoryItems] = useState<HistoryItem[]>([]);
    const [showHistoryModal, setShowHistoryModal] = useState<boolean>(false);

    const [price, setPrice] = useState<number>(0);
    useEffect(() => {
        if (isActived) {
            setloading(true);
            GetPlannedMaintenacesApi((res: IListItems<maintenancesResponse>) => {
                setActiveMaintenances(res.items);
                setloading(false);
            });
        }

    }, [isActived]);


    const ViewHistory = (record: maintenancesResponse) => {
        return () => {

            GetMaintenancesByIdApi(record.id, (res: maintenancesResponse) => {

                const newWarranties = res.maintenanceHistories.sort((a, b) =>
                    new Date(a.createdTime).getTime() - new Date(b.createdTime).getTime()
                );
                let histories: HistoryItem[] = [];
                for (let index = 0; index < newWarranties.length; index++) {
                    const element = newWarranties[index];
                    const code = element.statusCode;
                    const reason = element.reason;
                    let des: string = `${code} - ${reason} -${element.content}`;

                    if (code === MaintenanceStage.Request) {
                        if (res.isHighPriority)
                            des = "Tạo yêu cầu bảo dưỡng mới";
                        else
                            des = "Lên kế hoạch bão dưỡng";
                    }
                    else if (code === MaintenanceStage.Decline) {
                        des = "Từ chối yêu cầu - " + reason;
                    }
                    else if (code === MaintenanceStage.Approved) {
                        des = "Chấp nhận yêu cầu";
                    }
                    else if (code === MaintenanceAction.Register) {
                        des = "Tiếp nhận kiểm tra";
                    }
                    else if (code === MaintenanceAction.Maintaining) {
                        des = "Đang  kiểm tra";
                    }
                    else if (code === MaintenanceAction.Completed) {
                        des = "Hoàn thành kiểm tra";
                    }

                    histories.push({
                        actorName: element.actorName,
                        time: element.createdTime,
                        description: des
                    })
                }

                setHistoryItems(histories);
                setShowHistoryModal(true);

            })

        }
    }
    //Register = 0; Maintaining =1, Completed = 2
    const AssignSupportMaintenace = (record: maintenancesResponse) => {
        return () => {
            MaintenanceProcessingApi(record.id, 0, (res: maintenancesResponse) => {
                message.info("Đã bàn giao cho kỹ thuật viên");
                updateRecordStartus();
            })
        }
    }

    //Register = 0; Maintaining =1, Completed = 2
    const StartMaintenace = (record: maintenancesResponse) => {
        return () => {
            MaintenanceProcessingApi(record.id, 1, (res: maintenancesResponse) => {
                message.info("Kỹ thuật viên bắt đầu sửa chữa");
                updateRecordStartus();

            })
        }
    }

    //Register = 0; Maintaining =1, Completed = 2
    const CompleteMaintenace = (record: maintenancesResponse) => {
        return () => {
            MaintenanceProcessingApi(record.id, 2, (res: maintenancesResponse) => {
                message.info("Đã hoàn thành");
                updateRecordStartus();

            })
        }
    }

    const DenyMaintenance = (record: maintenancesResponse) => {
        return () => {
            //Cần form
            if (record) {
                setMaintenantDecision(record);
                setIsShowDecision(true);
            }

        }
    }

    //Xác nhận từ chối bảo dưỡng
    const afterConfirm = (res: any) => {
        if (maintenantDecision) {
            const data = {
                maintenanceDetails: [{
                    maintenanceId: maintenantDecision.id
                }],
                reason: res,
                maintenanceStage: 1 // Từ chối (cái này hơi ngược định nghĩa)
            }

            DecideMaintenenceRequestApi(data, (res: maintenancesResponse) => {
                setIsShowDecision(false);
                message.info("Phiếu bảo trì bị từ chối");
                updateRecordStartus();

            });
        }

    }

    const ApproveMaintenance = (record: maintenancesResponse) => {
        return () => {
            if (record) {
                callAproveMaitenainApi(price, record);
                updateRecordStartus();
            }

        }
    }

    function callAproveMaitenainApi(price: number, record: maintenancesResponse) {
        const data = {
            maintenanceDetails: [{
                maintenanceId: record.id,
                price: price
            }],
            reason: "Đã sẳn sàng bảo trì",
            maintenanceStage: 0 // Cái này là chấp nhận
        }

        DecideMaintenenceRequestApi(data, (res: maintenancesResponse) => {
            message.info("Phiếu bảo trì được chấp nhận");
            updateRecordStartus();
        });
    }


    function getStatus(record: maintenancesResponse): JSX.Element {
        if (record.maintenanceAction) {
            if (record.maintenanceAction === MaintenanceAction.Register) {
                return (<Tag color="#0075ca">
                    Tiếp nhận
                </Tag>)

            } else if (record.maintenanceAction === MaintenanceAction.Maintaining) {
                return (<Tag color="#a2eeef">
                    Đang xử lý
                </Tag>)

            }
            else if (record.maintenanceAction === MaintenanceAction.Completed) {
                return (<Tag color="#008672">
                    Hoàn thành
                </Tag>)

            }
        } else {
            if (record.maintenanceStage === MaintenanceStage.Approved) {
                return (<Tag color="#7057ff">
                    Chấp nhận
                </Tag>)

            } else if (record.maintenanceStage === MaintenanceStage.Decline) {
                return (<Tag color="#d73a4a">
                    Từ chối
                </Tag>)

            }

        }
        return (<Tag color="#e4e669">
            Chờ duyệt
        </Tag>)

    }

    function getControlAction(record: maintenancesResponse): JSX.Element {

        //Quá trình maintance đã bắt đầu
        if (record.maintenanceAction) {
            //Tiếp nhận --> có thể bắt đầu sửa chữa
            if (record.maintenanceAction === MaintenanceAction.Register) {
                return (
                    <Tooltip title="Tiến hành bảo trì" color="green">
                        <ToolOutlined onClick={StartMaintenace(record)} />
                    </Tooltip>
                )

            }
            //Đang xử lý
            else if (record.maintenanceAction === MaintenanceAction.Maintaining) {
                return (
                    <Tooltip title="Hoàn thành bảo hành" color="green">
                        <CheckCircleOutlined onClick={CompleteMaintenace(record)} style={{ color: "green" }} />
                    </Tooltip>
                )
            }

        } else if (record.maintenanceStage) {
            //Chấp nhận : Cần 1 kỹ thuật viên tiếp nhận
            if (record.maintenanceStage === MaintenanceStage.Approved) {
                return (
                    <Tooltip title="Tôi tiếp nhận" color="green">
                        <AndroidOutlined onClick={AssignSupportMaintenace(record)} />
                    </Tooltip>
                )
            }
            //Từ chối --> tiến hành trả lại khách
            else if (record.maintenanceStage === MaintenanceStage.Decline) {
                return (<Space direction="horizontal">
                    {/* <Tooltip title="Hoàn trả khách">
                        <RollbackOutlined onClick={CompleteMaintenace(record)} style={{ color: "red" }} />
                    </Tooltip> */}

                </Space>)
            }

        }
        else if (record.isHighPriority) {
            return (

                <Space direction="horizontal">
                    <Tooltip title="Chấp nhận bảo hành">
                        <Popover placement="bottom"
                            title="Giá bảo trì"
                            trigger="click"
                            content={<Form.Item>
                                <InputNumber min={10000}
                                    addonAfter={<span>₫</span>}
                                    className="input-number"
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                    parser={value => value?.replace(/\$\s?|(\.*)/g, '') as any}
                                    onChange={(e) => setPrice(e as number)}
                                />
                                <Button type="primary" onClick={ApproveMaintenance(record)}>Chấp nhận</Button>
                            </Form.Item>} >
                            <CheckOutlined style={{ color: "green" }} />
                        </Popover>

                    </Tooltip>
                    <Tooltip title="Từ chối bảo hành">
                        <CloseOutlined onClick={DenyMaintenance(record)} style={{ color: "red" }} />
                    </Tooltip>

                </Space>
            )
        }


        return (<div></div>)
    }

    //Udate trạng thái thay đổi
    function updateRecordStartus() {
        setloading(true);
        GetPlannedMaintenacesApi((res: IListItems<maintenancesResponse>) => {
            setActiveMaintenances(res.items);
            setloading(false);
        });
    }

    const maintenaceColumns: ColumnsType<maintenancesResponse> = [
        {
            title: 'IMEI',
            dataIndex: 'imei',
            key: 'imei',
            render: (imei, record) => (
                <a onClick={ViewHistory(record)}>
                    <span>{imei}</span>
                </a>
            )
        },
        {
            title: 'Tên sản phẩm',
            dataIndex: 'productName',
            key: 'productName',
        },
        {
            title: 'Kỹ thuật viên',
            dataIndex: 'maintenanceHistories',
            key: 'support',
            align: 'center',
            render: (maintenanceHistories: highMaintenanceRequest[]) => (
                <div>{maintenanceHistories.length > 0 ? maintenanceHistories[0].actorName : "-"}</div>
            )
        },
        {
            title: 'Trạng thái',
            dataIndex: 'maintenanceStage',
            key: 'maintenanceStage',
            align: 'center',
            render: (maintenanceStage, record) => (
                <div>{getStatus(record)}</div>
            )
        },
        {
            title: 'Tên khách hàng',
            dataIndex: 'customer',
            key: 'customer_name',
            render: (customer: CustomerRes) => (
                <div>{customer.fullName}</div>
            )
        },
        {
            title: 'SĐT/Zalo',
            dataIndex: 'customer',
            key: 'customer_phone',
            render: (customer: CustomerRes) => (
                <div>{customer.phoneNumber}</div>
            )
        },
        {
            title: 'Ngày tiếp nhận',
            dataIndex: 'plannedTime',
            key: 'plannedTime',
            render: (plannedTime) => (
                format(new Date(plannedTime), "dd-MM-yyyy")
            )
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            render: (record) => (
                getControlAction(record)
            )
        }
    ];


    const searchRef = useRef<Input>(null);
    const SearchWarrantiesSubmit = () => {
        const search = searchRef.current?.input.value.toLowerCase();
        setloading(true);
        GetPlannedMaintenacesApi((res: maintenancesResponse[]) => {

            if (search == null || search === "") {
                setActiveMaintenances(res);
            }

            else {
                const result = res.filter(x => x.customer.fullName.toLowerCase().includes(search) ||
                    x.customer.phoneNumber.toLowerCase().includes(search) ||
                    x.imei.toLowerCase().includes(search) ||
                    (x.maintenanceHistories.length > 0 && x.maintenanceHistories[0].actorName.toLowerCase().includes(search)))

                setActiveMaintenances(result);
            }
            setloading(false);
        });


    };

    //Xử lý cho tình huống xóa hết text
    const searchTextChange = () => {
        try {
            const search = searchRef.current?.input.value.toLowerCase();
            if (search == null || search === "") {
                setloading(true);
                GetPlannedMaintenacesApi((res: maintenancesResponse[]) => {
                    setActiveMaintenances(res);
                    setloading(false);
                });
            }

        } catch (error) {

        }

    }

    return (

        <Spin spinning={loading} tip="đang tải...">
            <Row wrap={false}>
                <Col flex="none">
                    <Button type="link" icon={<VerticalAlignBottomOutlined />} size="middle">
                        Xuất file
                    </Button>
                </Col>
                <Col flex="auto" style={{ padding: '0 16px' }}>
                    <Input prefix={<SearchOutlined />} allowClear={true}
                        ref={searchRef}
                        onChange={searchTextChange}
                        onPressEnter={SearchWarrantiesSubmit} />
                </Col>

                <Col flex="none" >

                </Col>
            </Row>

            <Table
                columns={maintenaceColumns}
                dataSource={activeMaintenaces}
                scroll={{ x: true }}
            />


            <DecisionModal title="Từ chối bảo dưỡng"
                onCancel={() => setIsShowDecision(false)}
                okModalFnc={afterConfirm}
                visible={isShowDecision} />

            <HistoryModal
                visible={showHistoryModal}
                footer={false}
                onCancel={() => setShowHistoryModal(false)}
                histories={histortItems}
                title="Lịch sử bảo hành" />
        </Spin>
    )
}