import { Button, DatePicker, Space, Table } from "antd";
import moment, { Moment } from "moment";
import {
    ArrowLeftOutlined
} from '@ant-design/icons';
import { useEffect, useState } from "react";
import { ChoosFilter, FilterControls } from "../../Controls/FilterControls";
import { addDays, subDays, format, subMonths } from "date-fns";
import { ColumnsType } from "antd/lib/table";
import { headerStyle } from "../../Helper/UIHelper";
import { filterTypes } from "../../../common/type-define";
import { SaleReportApi } from "../../../Api/Reports/SaleReportApi";
import { OrderStatus } from "../../../common/type-define";
import RangeDateControl from "../../Controls/RangeDateControl";
const { RangePicker } = DatePicker;

export default function ByTimeReport() {

    const [data, setData] = useState<ICustomerByTimeReport[]>([]);
    //const [startDate, setStartDate] = useState<Date>(subDays(new Date(), 7));
    //const [endDate, setEndDate] = useState<Date>(new Date());
    const [filters, setFilters] = useState<FilterItem[]>([]);

    useEffect(() => {
        fetchData(subMonths(new Date(), 1), new Date());
        setFilters([
            { type: filterTypes.WAREHOUSE, closeable: true },
            { type: filterTypes.AGENCY, closeable: true }]);
    }, [])

    function fetchData(fromDate: Date, toDate: Date) {
        let re: ICustomerByTimeReport[] = [];

        const query = {
            OrderStatuses: [OrderStatus.Completed, OrderStatus.Activated],
            QueryFrom: fromDate,
            QueryTo: toDate
        }
        SaleReportApi.GetOrderReportsApi(query).then(response => {
            const orders: IOrderReport[] = response.data;
            console.log(orders);
            let timeInMilisec: number = toDate.getTime() - fromDate.getTime();
            let days: number = Math.ceil(timeInMilisec / (1000 * 60 * 60 * 24));
            for (let index = 0; index <= days; index++) {
                var newCustomerQuantity = 0;
                var loyalCustomerQuantity = 0;
                var retailCustomerQuantity = 0;
                var totalCustomerQuantity = 0;
                var uniqueCustomerIds: string[] = [];
                const checkTime: Date = new Date(addDays(fromDate, index));
                const formatCheckTime = format(checkTime, "dd-MM-yyyy")
                const inDayOrders = orders.filter(p => {
                    if (p.orderTime) {
                        const orderTimeFormat = format(new Date(p.orderTime), "dd-MM-yyyy");
                        return orderTimeFormat === formatCheckTime;
                    }
                })
                inDayOrders.forEach(p => {
                    if (p.customer) {
                        const sameCustomers = orders.filter(o => o.customer && o.customer.id === p.customer.id);
                        if (sameCustomers.length >= 2) {
                            loyalCustomerQuantity++;
                        }
                        else {
                            newCustomerQuantity++;
                        }
                        if (!uniqueCustomerIds.find(id => id === p.customer.id)) {
                            uniqueCustomerIds.push(p.customer.id);
                            retailCustomerQuantity++;
                            totalCustomerQuantity++;
                        }
                    }
                    else if (p.toAgency) {
                        const sameCustomers = orders.filter(o => o.toAgency && o.toAgency.id === p.toAgency.id);
                        if (sameCustomers.length >= 2) {
                            loyalCustomerQuantity++;
                        }
                        else {
                            newCustomerQuantity++;
                        }
                        if (!uniqueCustomerIds.find(id => id === p.toAgency.id)) {
                            uniqueCustomerIds.push(p.toAgency.id);
                            totalCustomerQuantity++;
                        }
                    }
                })
                re.push({
                    orderTime: checkTime,
                    firstTimeCustomerQuantity: newCustomerQuantity,
                    loyalCustomerQuantity: loyalCustomerQuantity,
                    retailCustomerQuantity: retailCustomerQuantity,
                    totalCustomerQuantity: totalCustomerQuantity
                })
            }
            setData(re);
        });
    }

    const columns: ColumnsType<ICustomerByTimeReport> = [
        {
            title: 'Ngày tháng',
            dataIndex: 'orderTime',
            onHeaderCell: () => headerStyle(),
            render: (orderTime) => (
                format(new Date(orderTime), "dd-MM-yyyy")
            )
        },
        {
            title: 'Mua lần đầu',
            dataIndex: 'firstTimeCustomerQuantity',
            key: 'firstTimeCustomerQuantity',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Khách cũ',
            dataIndex: 'loyalCustomerQuantity',
            key: 'loyalCustomerQuantity',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Số lượng khách lẻ',
            dataIndex: 'retailCustomerQuantity',
            key: 'retailCustomerQuantity',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Tổng số lượng khách',
            dataIndex: 'totalCustomerQuantity',
            key: 'totalCustomerQuantity',
            onHeaderCell: () => headerStyle(),
        },

    ]


    function changeRangeTime(startTime: Date, endTime: Date) {

        fetchData(startTime, endTime);
    }



    function onChangeFilter() {

    }
    return (
        <div>

            <Space direction='horizontal'>
                <Button type="default" icon={<ArrowLeftOutlined />} href="/admin/report/customer"></Button>
                <RangeDateControl onChangeDate={changeRangeTime} />
                <ChoosFilter filterList={filters} onChangeFunc={(items: FilterItem[]) => setFilters(items)} />

            </Space>
            <FilterControls items={filters} afterChangeFnc={onChangeFilter} />

            <Table columns={columns} dataSource={data} size="small" bordered

                pagination={false}
            />
        </div>
    )
}