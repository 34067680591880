import React, { useState } from 'react';

import { config, setUserSession } from '../Helper/CommonHelper';

import { useNavigate } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { AuthenResponse, LoginInfor } from '../Model/LoginModel';
import { message, Spin, Image, Form, Input, Button, Space } from 'antd';
import styled from 'styled-components';
import { AuthenApi } from '../Api/AuthenticationApi';
import bigLogo from '../resources/pv-logo-01.png';


const CenterDiv = styled.div`
position: absolute;
top: 50%;
left: 50%;
height: 200px;
margin-top: -130px;/* height/2 */
width: 220px;
margin-left: -110px; /* width/2 */
`


const BorderDiv = styled.div`
position: absolute;
/* border: 1px solid; */
box-shadow: 2px 2px #F0F2F5;
border-radius: 25px;
background:#F0F2F5;
top: 50%;
left: 50%;
height: 350px;
margin-top: -180px;/* height/2 */
width: 300px;
margin-left: -150px; /* width/2 */
`

function LoginView() {
  const [loading, setLoading] = useState(false);

  const [profileForm] = Form.useForm<LoginInfor>();


  let navigate = useNavigate();
  // handle button click of login form
  const OnSubmit = () => {

    profileForm.validateFields().then((data: LoginInfor) => {
      setLoading(true);

      async function fetch() {
        const res = await AuthenApi.LoginApi(data);

        if (res?.status === 200) {
          setUserSession(res.data, data.email);
          message.info("Đăng nhập thành công");
          navigate("/admin/dashboard/");
          navigate(0);
          setLoading(false);
        }
        else {
          setLoading(false);
          message.info("Đăng nhập thất bại");
        }
      }
      fetch().catch(() => {
        setLoading(false);
      });
    });
  }


  const forgotPassword = () => {
    profileForm.validateFields().then((data: LoginInfor) => {
      async function fetch() {
        const res = await AuthenApi.GeneratePasswordTokenApi(data.email);
        if (res?.status === 200) {
          message.info("Đã gửi yêu cầu thành công.Kiểm tra email");
        }
        else {
          message.info("Gửi yêu cầu thất bại");
        }
      }
      fetch();
    });
  }


  return (
    <BorderDiv>
      <CenterDiv>
        <Image src={bigLogo} preview={false} style={{}} />
        <Form form={profileForm} layout="vertical">
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Nhập email!' }]}
          >
            <Input />
          </Form.Item>


          <Form.Item
            label="Mật khẩu"
            name="password"
            rules={[{ required: false, message: 'Nhập mật khẩu!' }]}
          >
            <Input.Password />
          </Form.Item>

          <Spin spinning={loading}>

            <Space direction="horizontal">
              <Button type="primary" htmlType="submit" onClick={OnSubmit}>
                Đăng nhập
              </Button>

              <a onClick={forgotPassword}>
                <span>Quên mật khẩu</span>
              </a>

            </Space>
          </Spin>


        </Form>
      </CenterDiv>
    </BorderDiv>

  );
}

export default LoginView;