import { Card, Input, Spin, Tag } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { KeyCode, VoucherResponse } from "../../common/type-define";
import { MathHelper } from "../../Helper/math.helper";
import { useShortKey } from "../../hooks/useShortKey";
import { useSelectorRoot } from "../../redux/configure/store";
import { getInputVoucherProductLoading, getInputVoucherVis, getListVoucherByProducts } from "../../redux/selectors/voucher.selector";
import { setInputVoucherVis } from "../../redux/slices/voucher.slice";
import { CheckOutlined } from '@ant-design/icons';
interface VoucherSelectionProps {
    setVoucherDiscount: (amount: number) => void;
}
const VoucherSelectionModalComponent = ({
    setVoucherDiscount
}: VoucherSelectionProps): JSX.Element => {
    const dispatch = useDispatch();
    const searchRef = useRef<Input>(null);
    const inputVoucherVis = useSelectorRoot(getInputVoucherVis);
    const listVoucherByProducts = useSelectorRoot(getListVoucherByProducts);
    const inputVoucherProductLoading = useSelectorRoot(getInputVoucherProductLoading);
    const [dataSource, setDataSource] = useState<VoucherResponse[]>([]);
    const [selectedVoucher, setSelectedVoucher] = useState<VoucherResponse | null>(null);
    useShortKey(KeyCode.F3, () => searchRef.current?.focus());

    useEffect(() => {
        if(inputVoucherVis) return;
        setDataSource([]);
        setSelectedVoucher(null);
    }, [inputVoucherVis])
    useEffect(() => {
        setDataSource(listVoucherByProducts)
    }, [listVoucherByProducts])

    const onCancel = () => {
        dispatch(setInputVoucherVis(false));
    }

    const onSearch = (e: any) => {
        const inputValue: string = e.target.value;
        if(inputValue && inputValue !== '') {
            const filtered = listVoucherByProducts.filter(v => v.code.toLowerCase().includes(inputValue.toLowerCase()) || v.name.toLowerCase().includes(inputValue.toLowerCase()))
            setDataSource(filtered)
        }else{
            setDataSource(listVoucherByProducts)
        }
    }

    const onSelectVoucher = (voucher: VoucherResponse) => {
        setSelectedVoucher(pre => {
            if(pre?.id === voucher.id){
                return null;
            }
            return voucher;
        })
    }

    const onApplyVoucher = () => {
        if(selectedVoucher) {
            setVoucherDiscount(selectedVoucher.saleOff);
        }
        onCancel();
    }

    return (
        <Modal
            title="Chọn mã giảm giá"
            visible={inputVoucherVis}
            okButtonProps={{disabled: selectedVoucher === null}}
            okText="Áp dụng mã"
            cancelText="Đóng"
            onOk={onApplyVoucher}
            maskClosable={false}
            destroyOnClose
            onCancel={onCancel}>
            <Spin spinning={inputVoucherProductLoading}>
                <Input
                    ref={searchRef}
                    allowClear
                    onChange={onSearch}
                    style={{marginBottom: '8px'}}
                    placeholder="Tìm mã giảm giá"/>
                <div
                    style={{
                        margin: '8px auto', 
                        background: '#ececec', 
                        padding: '4px 8px',
                        overflow: 'auto',
                        maxHeight: '500px',
                        border: '1px solid #ececec',
                    }}>
                {
                    dataSource.length ? 
                        dataSource.map(v => 
                            <Card
                                bordered
                                className="voucher-item"
                                onClick={() => onSelectVoucher(v)}
                                style={{margin: '8px auto', pointerEvents: 'auto', cursor: 'pointer'}}
                                key={v.id}
                                size="small"
                                title={
                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <span>{v.code}</span>
                                        {
                                            selectedVoucher?.id === v.id && <Tag color="green"><CheckOutlined/></Tag>
                                        }
                                    </div>
                                }
                                >
                                <div style={{fontSize: '16px', fontWeight: 'bold', color: 'gray'}}>
                                    Giảm
                                    <Tag color="green" style={{margin: 'auto 4px'}}>{MathHelper.formatPriceVND(v.saleOff)}</Tag>
                                </div>
                                <div>HSD: {v.endTime.toLocaleString()}</div>
                            </Card>
                        )
                        :
                        <Card style={{margin: '4px auto', userSelect: 'none'}}>
                            <div style={{display: 'flex', justifyContent: 'center'}}>Không tìm thấy mã giảm giá phù hợp</div>
                        </Card>
                }
                </div>
            </Spin>
        </Modal>
    )
}

const VoucherSelectionModal = React.memo(VoucherSelectionModalComponent);
export default VoucherSelectionModal;