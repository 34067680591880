import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../configure/rootReducer";


export const getCollapsedMenu = createSelector(
    (rootState: RootState) => rootState.pagesAction,
    (state) => state.collapsedMenu,
)

export const getHeaderTitle = createSelector(
    (rootState: RootState) => rootState.pagesAction,
    (state) => state.headerTitle,
)