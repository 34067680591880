import { EditOutlined, LeftOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import { format } from 'date-fns';
import { useEffect } from "react";
import { useDispatch } from 'react-redux';
import { VoucherResponse } from '../../../common/type-define';
import { useSelectorRoot } from '../../../redux/configure/store';
import { getListVoucher } from '../../../redux/selectors/voucher.selector';
import { fetchVoucher, setVoucherCreateModalVis } from '../../../redux/slices/voucher.slice';
import VoucherCreateModal from './VoucherCreateModal';

export function VouchersView() {
    const dispatch = useDispatch();
    const listVoucher = useSelectorRoot(getListVoucher);
    useEffect(() => {
        dispatch(fetchVoucher())
    }, [dispatch])

    const handleCreateVoucherModal = () => {
        dispatch(setVoucherCreateModalVis({
            data: undefined,
            action: 'create',
        }));
    }


    const handleUpdateVoucherModal = (res: VoucherResponse) => {
        dispatch(setVoucherCreateModalVis({
            data: res,
            action: 'update',
        }));
    }

    const columns: ColumnsType<VoucherResponse> = [
        {
            title: 'Tên Voucher',
            dataIndex: 'name',
            key: 'name',
            render: (name, record) => (
                <span>{name}</span>
            )
        },
        {
            title: 'Mã KM',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'Bắt đầu',
            dataIndex: 'startTime',
            key: 'startTime',
            render: (startTime) => (
                <div>
                    {format(new Date(startTime), "dd-MM-yyyy hh:mm:ss")}
                </div>
            )
        },
        {
            title: 'Kết thúc',
            dataIndex: 'endTime',
            key: 'endTime',
            render: (endTime) => (
                <div>
                    {format(new Date(endTime), "dd-MM-yyyy hh:mm:ss")}
                </div>
            )
        },
        {
            title: 'Hành động',
            dataIndex: 'actions',
            key: 'actions',
            render: (_, record) => (
                <div>
                    <Tooltip placement="top" title={"Cập nhật thông tin voucher"}>
                        <Button 
                            size="small" 
                            type="link"
                            onClick={() => handleUpdateVoucherModal(record)}
                            icon={<EditOutlined />}>
                                Cập nhật
                        </Button>
                    </Tooltip>
                </div>
            )
        }
    ];

    return (
        <div>
            <div>
                <Button type="text" icon={<LeftOutlined />} href="/admin/settings" size="large">
                    Quay lại cấu hình
                </Button>
            </div>
            <div style={{ display: "flex", height: 50, top: 25 }}>
                <div style={{ fontWeight: 500, fontSize: 25, marginLeft: 20 }}>
                    Quản lý khuyến mại
                </div>
                <div style={{ flexGrow: 1, textAlign: "end" }}>
                    <Button 
                        type="primary" 
                        icon={<PlusOutlined />} 
                        onClick={handleCreateVoucherModal} 
                        size="middle">
                        Tạo Voucher
                    </Button>
                    <VoucherCreateModal/>
                </div>
            </div>
            <Divider style={{ marginTop: 0, marginLeft: 20 }} />
            <Table
                columns={columns}
                dataSource={listVoucher}
                rowKey={"id"}
            />
        </div>
    )
}