import { AxiosRequest } from "./Axios/AxiosRequest";

import { HandleRequest } from "./RequestAPI";
import { SERVER_API } from './UrlDefination';

// Get all products
export const GetCities = async (doAfterResponse: Function) => {

    await HandleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.ADDRESS.GET}`), doAfterResponse);

}

export const GetDistricts = async (cityId: string, doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.ADDRESS.DISTRICT}?CityId=${cityId}`), doAfterResponse);

}