import { AxiosResponse } from "axios";
import { ProductDefectOrderStatusEnumQuery } from "../common/type-define";
import { DefectProductResponse } from "../Model/ProductModel";
import { AxiosRequest } from "./Axios/AxiosRequest";
import { RequestHandler, HandleRequest } from "./RequestAPI";
import { SERVER_API } from "./UrlDefination";

export class DefectProductApi {
    static async DefectProductsApi(from?: Date, to?: Date): Promise<AxiosResponse<IListItems<DefectProductResponse>, any>> {
        return RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.PRODUCT_DEFECT.GET}`));
    }

    static async CreateDefectProductApi(data: any): Promise<AxiosResponse<DefectProductResponse>> {
        return RequestHandler.handleRequest(AxiosRequest.post(`${SERVER_API.HOST}/${SERVER_API.PRODUCT_DEFECT.POST_CREATE}`, data));
    }

    static async TechnicalUpdateStatus(id: string, data: any, doAfterResponse: Function) {
        await HandleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.PRODUCT_DEFECT.PUT_TECHNICAL_SUPPORT(id)}`, data), doAfterResponse);
    }

    static async ImportProductToStoreApi(id: string | undefined, data: any, doAfterResponse: Function) {
        return RequestHandler.handleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.PRODUCT_DEFECT.PUT_IMPORT_TO_STORE(id)}`, data), doAfterResponse);
    }

    static async CreateDefectProductOrderApi(data: any, doAfterResponse: Function) {
        return RequestHandler.handleRequest(AxiosRequest.post(`${SERVER_API.HOST}/${SERVER_API.PRODUCT_DEFECT.POST_CREATE_ORDER}`, data), doAfterResponse);
    }

    static async CreateDefectProductAccessoryOrderApi(data: any, doAfterResponse: Function) {
        return RequestHandler.handleRequest(AxiosRequest.post(`${SERVER_API.HOST}/${SERVER_API.PRODUCT_DEFECT.POST_CREATE_ACCESSORY_ORDER}`, data), doAfterResponse);
    }

    static async GetDefectProductOrdersApi(orderStatusList?: ProductDefectOrderStatusEnumQuery[], doAfterResponse?: Function) {
        const params = orderStatusList?.map(p => `Statuses=${p.valueOf()}`).join('&');
        return RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.PRODUCT_DEFECT.GET_DEFECT_PRODUCT_ORDERS}?${params}`), doAfterResponse);
    }

    static async DecisionDefectProductOrderStatus(id: string, data: any, doAfterResponse: Function) {
        await HandleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.PRODUCT_DEFECT.PUT_STORE_DECISION(id)}`, data), doAfterResponse);
    }

    static async DecisionDefectProductAccessoryOrderStatus(id: string, data: any, doAfterResponse: Function) {
        await HandleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.PRODUCT_DEFECT.PUT_ACCESSORY_DECISION(id)}`, data), doAfterResponse);
    }

    static async ExportDefectProductOrder(id: string, data: any, doAfterResponse: Function) {
        await HandleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.PRODUCT_DEFECT.PUT_EXPORT_DEFECT_PRODUCT_ORDER(id)}`, data), doAfterResponse);
    }

    static async ImportDefectProductOrderapi(id: string, data: any, doAfterResponse: Function) {
        await HandleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.PRODUCT_DEFECT.PUT_IMPORT_DEFECT_PRODUCT_ORDER(id)}`, data), doAfterResponse);
    }

    static async ImportDefectProductAccessoryOrderApi(id: string, data: any, doAfterResponse: Function) {
        await HandleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.PRODUCT_DEFECT.PUT_IMPORT_DEFECT_PRODUCT_ACCESSORY_ORDER(id)}`, data), doAfterResponse);
    }

    static async ReturnProductDefectToProviderApi(id: string, doAfterResponse: Function) {
        await HandleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.PRODUCT_DEFECT.PUT_RETURN_PROVIDER(id)}`), doAfterResponse);
    }

    static async GetProductDefectByIdApi(id: string, doAfterResponse: Function): Promise<AxiosResponse<DefectProductResponse>> {
        return HandleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.PRODUCT_DEFECT.GET_BY_ID(id)}`), doAfterResponse);
    }
}