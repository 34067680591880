import { Button, Divider, Modal, Steps } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { OrderStatus } from "../../../common/type-define";
import { useSelectorRoot } from "../../../redux/configure/store";
import { getReturnOrderId, getReturnOrderModalVis } from "../../../redux/selectors/returnOrders.selector";
import { fetchOrders } from "../../../redux/slices/orders.slice";
import { setReturnOrderId, setReturnOrderModalVis } from "../../../redux/slices/returnOrders.slice";
import ReturnOrderDetails from "./ReturnOrderDetails";
import ReturnOrderSearch from "./ReturnOrderSearch";
import ReturnOrderTable from "./ReturnOrderTable";

const STEP_TITLE = ['Chọn đơn trả', 'Thông tin đơn'];

const ReturnOrderModalComponent = (): JSX.Element => {
    const dispatch = useDispatch();
    const returnOrderModalVis = useSelectorRoot(getReturnOrderModalVis);
    const returnOrderId = useSelectorRoot(getReturnOrderId)
    const [stepIndex, setStepIndex] = useState<number>(0);
    useEffect(() => {
        if (returnOrderModalVis) {
            dispatch(fetchOrders([OrderStatus.Completed, OrderStatus.Activated]))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [returnOrderModalVis])

    useEffect(() => {
        const checkId = returnOrderId !== undefined;
        setStepIndex(checkId ? 1 : 0)
    }, [returnOrderId])

    const handleOnCancel = () => {
        dispatch(setReturnOrderModalVis(false))
    }

    const renderContentStep = () => {
        switch (stepIndex) {
            case 0:
                return (
                    <>
                        <ReturnOrderSearch />
                        <ReturnOrderTable />
                    </>
                )
            default:
                return (
                    <ReturnOrderDetails />
                )
        }
    }

    const handleOnSelectStep = (index: number) => {
        if (index === 0) {
            dispatch(setReturnOrderId(undefined));
        }
    }

    return (
        <Modal
            title="Tạo đơn trả"
            centered
            width={'50vw'}

            visible={returnOrderModalVis}
            style={{ minWidth: '800px' }}
            bodyStyle={{
                minHeight: '70vh',
                maxHeight: '90vh',
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
                paddingTop: '12px',
                position: 'relative',
            }}
            maskClosable={false}
            onCancel={handleOnCancel}
            destroyOnClose
            footer={null}
        >
            <div style={{ margin: '10px', position: 'relative', height: '100%' }}>
                <Steps
                    labelPlacement="vertical"
                    size="small"
                    current={stepIndex}
                    onChange={handleOnSelectStep}>
                    {
                        STEP_TITLE.map((v, i) =>
                            <Steps.Step
                                disabled={i === 1}
                                key={v}
                                title={v} />
                        )
                    }
                </Steps>
                {renderContentStep()}

                <div>
                    <Divider style={{ margin: '8px auto' }} />
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'end'
                        }}>
                        <Button
                            onClick={handleOnCancel}>
                            Đóng
                        </Button>
                    </div>
                </div>

            </div>

        </Modal>
    )
}

const ReturnOrderModal = React.memo(ReturnOrderModalComponent);
export default ReturnOrderModal;