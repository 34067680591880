import { AxiosRequest } from "./Axios/AxiosRequest";

import { PurchaseOrderDataRequest, PaidPurchaseRequest } from "../Model/PurchaseModel";
import { HandleRequest } from "./RequestAPI";
import { SERVER_API } from "./UrlDefination";


export const GetPurchaseOrderssApi = async (doAfterResponse: Function) => {

    await HandleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.PURCHASE.GET}`), doAfterResponse);

}

export const GetPurchaseOrderByIdsApi = async (id: string, doAfterResponse: Function) => {

    await HandleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.PURCHASE.GET_BY_ID}?Id=${id}`), doAfterResponse);
}



export const CreatePurchaseOrdersApi = async (data: PurchaseOrderDataRequest, doAfterResponse: Function) => {

    await HandleRequest(AxiosRequest.post(`${SERVER_API.HOST}/${SERVER_API.PURCHASE.CREATE}`, data), doAfterResponse);
}

export const UpdatePurchaseOrdersApi = async (id: string, data: PurchaseOrderDataRequest, doAfterResponse: Function) => {

    await HandleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.PURCHASE.UPDATE(id)}`, data), doAfterResponse);
}


export const ApprovePurchaseApi = async (id: string, carrier: any, doAfterResponse: Function) => {

    await HandleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.PURCHASE.PUT_APPROVE(id)}`, carrier), doAfterResponse);
}

export const StoredPurchaseApi = async (id: string, doAfterResponse: Function) => {

    await HandleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.PURCHASE.PUT_STORE(id)}`, null), doAfterResponse);
}

export const PaidPurchaseApi = async (id: string, data: PaidPurchaseRequest, doAfterResponse: Function) => {

    await HandleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.PURCHASE.PUT_PAID(id)}`, data), doAfterResponse);
}


