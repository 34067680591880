import { Button, Col, Input, message, Modal, Row, Space, Spin, Table, Tag, Tooltip } from "antd";
import {
    VerticalAlignBottomOutlined,
    ShoppingCartOutlined,
    ToolOutlined,
    CheckOutlined,
    CloseOutlined,
    AndroidOutlined,
    RollbackOutlined,
    CheckCircleOutlined,
    SearchOutlined
} from '@ant-design/icons';
import { ColumnsType } from "antd/lib/table";
import { ActiveWarrantyResponse, WarrantyHistoryResponse } from "../../Model/WarrantiesModel";
import { CustomerRes } from "../../Model/CustomerModel";
import { useEffect, useRef, useState } from "react";
import { WarrantyActionRequestApi, DecideWarrantyRequestApi, GetActiveWarrantiesApi, GeWarrantyHistoryApi, ReturnProductWarrantyApi } from "../../Api/WarrantyApi";
import StoreExportTicketModal from "./StoreExportTicketModal";
import { ColumnFilterItem } from "antd/lib/table/interface";
import DecisionModal from "./DecisionModal";
import RenewProductModal from "./RenewProductModal";
import HistoryModal, { HistoryItem } from "../Helper/HistoryModal";
import { WarrantyActionStatus, WarrantyOrderStatus, WarrantyStageStatus } from "../../common/type-define";

type ActiveWarrantyTabProps = {
    isActived: boolean
}


export default function ActiveWarrantyTabView(props: ActiveWarrantyTabProps) {

    const { isActived } = props;

    const [activeWarranties, setActiveWarranties] = useState<ActiveWarrantyResponse[]>([]);

    const [loading, setloading] = useState(true);
    const [isShowStoreTicket, setIsShowStoreTicket] = useState<boolean>(false);
    const [warrantyRequest, setWarrantyRequest] = useState<ActiveWarrantyResponse>();
    const [isShowDecision, setIsShowDecision] = useState<boolean>(false);
    const [showRenewModal, setShowRenewModal] = useState<boolean>(false);
    const [histortItems, setHistoryItems] = useState<HistoryItem[]>([]);
    const [showHistoryModal, setShowHistoryModal] = useState<boolean>(false);

    //Load trạng thái ban đầu khi
    useEffect(() => {
        if (isActived) {
            setloading(true);
            GetActiveWarrantiesApi((res: IListItems<ActiveWarrantyResponse>) => {
                setActiveWarranties(res.items);
                setloading(false);
            });
        }

    }, [isActived]);

    //Từ chối yêu cầu bảo hành
    const DenyWarrantyRequest = (record: ActiveWarrantyResponse) => {
        return () => {

            if (record) {

                setIsShowDecision(true);
                setWarrantyRequest(record);

            }
        }
    }

    //Xác nhận từ chối bảo hành
    const afterConfirm = (res: any) => {

        if (warrantyRequest) {

            const data = {
                reason: res,
                stage: 1
            }
            DecideWarrantyRequestApi(warrantyRequest.id, data, (res: ActiveWarrantyResponse) => {
                setIsShowDecision(false);
                message.info("Phiếu bảo hành đã bị từ chối");
                updateRecordStartus();
            })
        }

    }

    //Chấp nhận yêu cầu bảo hành
    const AcceptWarrantyRequest = (record: ActiveWarrantyResponse) => {
        return () => {

            if (record) {
                const data = {
                    reason: "Đồng ý",
                    stage: 0
                }

                DecideWarrantyRequestApi(record.id, data, (res: ActiveWarrantyResponse) => {
                    message.info("Phiếu bảo hành đã được chấp nhận");
                    updateRecordStartus();
                })
            }
        }
    }
    //Reload lại nội dung cho
    function updateRecordStartus() {
        setloading(true);
        GetActiveWarrantiesApi((res: IListItems<ActiveWarrantyResponse>) => {
            setActiveWarranties(res.items);
            setloading(false);
        });
    }


    //0-> Received, 1 -> Processing, 2 -> Return
    const WarrantyActionHandle = (action: number, record: ActiveWarrantyResponse) => {
        return () => {
            WarrantyActionRequestApi(record.id, { warrantyAction: action }, (res: any) => {
                if (action === 0)
                    message.info("Kỹ thuật viên đã chấp nhận cho việc bảo hành");
                else if (action === 1)
                    message.info("Kỹ thuật viên đang xử lý");
                else if (action === 2)
                    message.info("Trả lại khách");

                updateRecordStartus();
            })
        }
    }

    const CompleteRepair = (record: ActiveWarrantyResponse) => {
        return () => {
            WarrantyActionRequestApi(record.id, { warrantyAction: 2 }, (res: any) => {
                message.info("Kỹ thuật viên hoành thành sửa chữa");
                updateRecordStartus();
            })
        }
    }




    //Sau khi đã hoàn thành quá trình kiểm tra sửa chữa thì trả về cho khách
    const ReturnWarrantyToCustomer = (record: ActiveWarrantyResponse) => {
        return () => {

            ReturnProductWarrantyApi(record.id, () => {
                message.info("Đã hoàn thành trả cho khách");
                updateRecordStartus();
            })
        }
    }

    //Hiển thị lịch sử của từng phiếu bảo hành
    const ViewHistoryWarranty = (record: ActiveWarrantyResponse) => {
        return () => {

            GeWarrantyHistoryApi(record.id, (res: ActiveWarrantyResponse) => {

                const newWarranties = res.warrantyHistories.sort((a, b) =>
                    new Date(a.createdTime).getTime() - new Date(b.createdTime).getTime()
                );
                let histories: HistoryItem[] = [];
                for (let index = 0; index < newWarranties.length; index++) {
                    const element = newWarranties[index];
                    const code = element.statusCode;
                    const reason = element.reason;
                    let des: string = `${code} - ${reason} -${element.content}`;
                    if (code === WarrantyStageStatus.Request) {
                        des = "Tạo phiếu yêu cầu bảo hành";
                    } else if (code === WarrantyStageStatus.Approved) {
                        des = "Chấp nhận bảo hành";
                    }
                    else if (code === WarrantyStageStatus.Declined) {
                        des = "Từ chối bảo hành";
                    }
                    else if (code === WarrantyActionStatus.Received) {
                        des = "Tiếp nhận bảo hành";
                    }
                    else if (code === WarrantyActionStatus.Processing) {
                        des = "Tiến hành sửa chữa";
                    }
                    else if (code === WarrantyActionStatus.Completed) {
                        des = "Hoàn thành sửa chữa";
                    }
                    else if (code == WarrantyActionStatus.Returned) {
                        des = "Trả lại cho khách";
                    }
                    histories.push({
                        actorName: element.actorName,
                        time: element.createdTime,
                        description: des
                    })
                }

                setHistoryItems(histories);
                setShowHistoryModal(true);


            })

        }
    }

    //Yêu cầu 1 phiếu xuất kho
    const RequestExportStoreTicket = (record: ActiveWarrantyResponse) => {
        return () => {
            setWarrantyRequest(record);
            setIsShowStoreTicket(true);
        }
    }

    // Phiếu xuất kho được chấp nhận
    const CreateStoreTicketCallback = () => {
        return () => {
            setShowRenewModal(false);
            updateRecordStartus();
        }
    }

    //Đổi cho khách
    const afterRenewProduct = () => {
        message.info("Đã chấp nhận đổi sản phẩm mới cho khách");
        setShowRenewModal(false);
    }

    //Trả về trạng thái hiện tại của từng phiếu bảo hành
    function getStatus(record: ActiveWarrantyResponse): JSX.Element {
        if (record.warrantyActionStatus) {
            if (record.warrantyActionStatus === WarrantyActionStatus.Received) {
                return (<Tag color="yellow" >Tiếp nhận</Tag>);
            } else if (record.warrantyActionStatus === WarrantyActionStatus.Processing) {
                if (record.warrantyOrder == null) {
                    return (<Tag color="yellowgreen">Đang xử lý</Tag>);
                } else {
                    if (record.warrantyOrder.status === WarrantyOrderStatus.Created) {
                        return (<Tag color="orange">Chờ xuất kho</Tag>);
                    } else if (record.warrantyOrder.status === WarrantyOrderStatus.Exported) {
                        if (record.warrantyOrder.isReplace)
                            return (<Tag color="Blue">Đổi mới</Tag>);
                        else
                            return (<Tag color="Blue">Đã xuất kho</Tag>);
                    }
                }

            }
            else if (record.warrantyActionStatus === WarrantyActionStatus.Completed) {
                return (<Tag color="lime" >KT Hoàn thành</Tag>);
            }
            else if (record.warrantyActionStatus === WarrantyActionStatus.Returned) {
                return (<Tag color="green" >Hoàn thành</Tag>);
            }
        } else {
            if (record.warrantyStageStatus === 1) {
                return (<Tag color="Blue" >Chấp nhận</Tag>);
            } else if (record.warrantyStageStatus === 2) {
                return (<Tag color="red">Từ chối</Tag>);
            }

        }
        return (<Tag color="#e4e669">Chờ duyệt</Tag>);
    }

    //Lấy các control number để quản lý filter
    function getControlNumber(record: ActiveWarrantyResponse): number {

        if (record.warrantyActionStatus) {
            //nhân viên tiếp nhận --> có thể bắt đầu sửa chữa
            if (record.warrantyActionStatus === WarrantyActionStatus.Received) {
                return 2;
            }
            //Đang xử lý : có quyến request 1 phiếu xuất hoặc hoàn thành 
            else if (record.warrantyActionStatus === WarrantyActionStatus.Processing) {
                if (record.warrantyOrder === null) {
                    return 3;
                } else {
                    if (record.warrantyOrder.status === 0) {
                        return 4;
                    } else if (record.warrantyOrder.status === 1) {
                        return 5;
                    }
                }

            }
            //Hoàn thành
            else if (record.warrantyActionStatus === WarrantyActionStatus.Completed) {
                return 6;
            }
        } else {
            //Chấp nhận : Cần 1 kỹ thuật viên tiếp nhận
            if (record.warrantyStageStatus === 1) {
                return 1;
            }
            //Từ chối --> tiến hành trả lại khách
            else if (record.warrantyStageStatus === 2) {
                return -1;
            }

        }
        return -1;
    }

    const getColumnFileter = (): ColumnFilterItem[] => {

        return [
            { text: 'Đổi mới', value: '0' },
            { text: 'Chấp nhận', value: '1' },
            { text: 'Từ chối', value: '-1' },
            { text: 'Tiếp nhận', value: '2' },
            { text: 'Đang xử lý', value: '3' },
            { text: 'Chờ xuất kho', value: '4' },
            { text: 'Thay thế', value: '5' },
            { text: 'Hoàn thành', value: '6' },
        ];
    }

    //Điều khiển việc ẩn hiện các hành động tương ứng với trạng thái của phiếu bảo hành
    function getControlAction(record: ActiveWarrantyResponse): JSX.Element {

        if (record.warrantyActionStatus) {
            //Tiếp nhận --> có thể bắt đầu sửa chữa
            if (record.warrantyActionStatus === WarrantyActionStatus.Received) {
                return (
                    <Tooltip title="Bắt đầu sửa chữa" color="green">
                        <ToolOutlined onClick={WarrantyActionHandle(1, record)} />
                    </Tooltip>
                )
            }
            //Đang xử lý : có quyến request 1 phiếu xuất hoặc hoàn thành 
            else if (record.warrantyActionStatus === WarrantyActionStatus.Processing) {
                if (record.warrantyOrder === null) {
                    return (<Space direction="horizontal">
                        <Tooltip title="Tạo phiếu xuất kho" color="green">
                            <ShoppingCartOutlined onClick={RequestExportStoreTicket(record)} style={{ color: "red" }} />
                        </Tooltip>
                        <Tooltip title="Hoàn thành sửa chữa" color="green">
                            <CheckCircleOutlined onClick={CompleteRepair(record)} style={{ color: "green" }} />
                        </Tooltip>

                    </Space>)
                } else { //Đã xuất kho xong
                    if (record.warrantyOrder.status === WarrantyOrderStatus.Exported) {

                        //Đã đổi mới trả lại cho khách
                        return (
                            <Tooltip title="Hoàn thành sửa chữa" color="green">
                                <CheckCircleOutlined onClick={CompleteRepair(record)} style={{ color: "green" }} />
                            </Tooltip>)

                    }
                    else { //vẫn chờ xuất kho nên kỹ thuật viên sẽ không làm gì
                        return (<div></div>)
                    }
                }

            }
            //Hoàn thành sửa chữa
            else if (record.warrantyActionStatus === WarrantyActionStatus.Completed) {

                return (
                    <Tooltip title="Trả sản phẩm lại cho khách" color="green">
                        <RollbackOutlined onClick={ReturnWarrantyToCustomer(record)} style={{ color: "green" }} />
                    </Tooltip>)
            }
            else if (record.warrantyActionStatus === WarrantyActionStatus.Returned) {
                return (<div></div>)
            }
        } else {
            //Chấp nhận : Cần 1 kỹ thuật viên tiếp nhận
            if (record.warrantyStageStatus === WarrantyStageStatus.Approved) {
                return (
                    <Tooltip title="Tôi tiếp nhận" color="green">
                        <AndroidOutlined onClick={WarrantyActionHandle(0, record)} />
                    </Tooltip>
                )
            }
            //Từ chối 
            else if (record.warrantyStageStatus == WarrantyStageStatus.Declined) {
                return (
                    <Tooltip title="Trả sản phẩm lại cho khách">
                        <RollbackOutlined onClick={ReturnWarrantyToCustomer(record)} style={{ color: "orange" }} />
                    </Tooltip>
                )
            }

        }
        return (
            <Space direction="horizontal">
                <Tooltip title="Chấp nhận bảo hành">
                    <CheckOutlined onClick={AcceptWarrantyRequest(record)} style={{ color: "green" }} />
                </Tooltip>
                <Tooltip title="Từ chối bảo hành">
                    <CloseOutlined onClick={DenyWarrantyRequest(record)} style={{ color: "red" }} />
                </Tooltip>

            </Space>
        )
    }



    const WarrantyColumns: ColumnsType<ActiveWarrantyResponse> = [
        {
            title: 'IMEI',
            dataIndex: 'imei',
            key: 'imei',
            render: (imei, record) => (
                <a>
                    <span onClick={ViewHistoryWarranty(record)}>{imei}</span>
                </a>

            )
        },
        {
            title: 'Tên sản phẩm',
            dataIndex: 'productName',
            key: 'productName',
        },
        {
            title: 'Kỹ thuật viên',
            dataIndex: 'warrantyHistories',
            key: 'support',
            align: 'center',
            render: (warrantyHistories: WarrantyHistoryResponse[]) => (
                <div>{warrantyHistories.length > 0 ? warrantyHistories[0].actorName : "-"}</div>
            )
        },
        {
            title: 'Trạng thái',
            dataIndex: 'warrantyStageStatus',
            key: 'warrantyStageStatus',
            align: 'center',
            filters: getColumnFileter(),

            onFilter: (value, record) => getControlNumber(record) === (value),
            render: (warrantyStageStatus, record) => (
                <div>{getStatus(record)}</div>
            )
        },
        {
            title: 'Tên khách hàng',
            dataIndex: 'customer',
            key: 'customer_name',
            render: (customer: CustomerRes) => (
                <div>{customer ? customer.fullName : ""}</div>
            )
        },
        {
            title: 'SĐT/Zalo',
            dataIndex: 'customer',
            key: 'customer_phone',
            render: (customer: CustomerRes) => (
                <div>{customer ? customer.phoneNumber : ""}</div>
            )
        },
        {
            title: 'Ngày tiếp nhận',
            dataIndex: 'plannedTime',
            key: 'plannedTime',

        }
        ,
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            render: (record) => (
                getControlAction(record)
            )
        }
    ];

    const searchRef = useRef<Input>(null);
    const SearchWarrantiesSubmit = () => {
        const search = searchRef.current?.input.value.toLowerCase();
        setloading(true);
        GetActiveWarrantiesApi((res: IListItems<ActiveWarrantyResponse>) => {
            if (!search) {
                setActiveWarranties(res.items);
            }

            else {
                const result = res.items.filter(x => x.customer.fullName.toLowerCase().includes(search) ||
                    x.customer.phoneNumber.toLowerCase().includes(search) ||
                    x.imei.toLowerCase().includes(search) ||
                    (x.warrantyHistories.length > 0 && x.warrantyHistories[0].actorName.toLowerCase().includes(search)))

                setActiveWarranties(result);
            }
            setloading(false);
        });


    };

    //Xử lý cho tình huống xóa hết text
    const searchTextChange = () => {
        try {
            const search = searchRef.current?.input.value.toLowerCase();
            if (search === null || search === "") {
                setloading(true);
                GetActiveWarrantiesApi((res: IListItems<ActiveWarrantyResponse>) => {
                    setActiveWarranties(res.items);
                    setloading(false);
                });
            }

        } catch (error) {

        }

    }

    return (
        <div>
            <Row wrap={false}>
                <Col flex="none">
                    <Button type="link" icon={<VerticalAlignBottomOutlined />} size="middle">
                        Xuất file
                    </Button>
                </Col>
                <Col flex="auto" style={{ padding: '0 16px' }}>
                    <Input prefix={<SearchOutlined />} allowClear={true}
                        ref={searchRef}
                        onChange={searchTextChange}
                        onPressEnter={SearchWarrantiesSubmit} />
                </Col>

                <Col flex="none" >

                </Col>
            </Row>

            <Spin spinning={loading} tip="đang tải...">
                <Table
                    columns={WarrantyColumns}
                    dataSource={activeWarranties}
                />
                <StoreExportTicketModal centered
                    activeWarranty={warrantyRequest}
                    title="Tạo phiếu xuất kho" width={900}
                    createdTikectFnc={CreateStoreTicketCallback}
                    onCancel={() => setIsShowStoreTicket(false)}
                    visible={isShowStoreTicket} />
            </Spin>

            <DecisionModal title="Từ chối bảo hành"
                onCancel={() => setIsShowDecision(false)}
                okModalFnc={afterConfirm}
                visible={isShowDecision} />

            <RenewProductModal title="Đổi trả sản phẩm"
                visible={showRenewModal}
                ActiveWarranty={warrantyRequest}
                AcceptFunc={afterRenewProduct}
                onCancel={() => setShowRenewModal(false)} />

            <HistoryModal
                visible={showHistoryModal}
                footer={false}
                onCancel={() => setShowHistoryModal(false)}
                histories={histortItems}
                title="Lịch sử bảo hành" />
        </div>

    )
}