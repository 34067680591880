import { Button, DatePicker, Input, message, Modal, ModalProps, Popover, Spin, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import Link from "antd/lib/typography/Link";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { ActiveWarrantyForOrderApi, GetUnActiveWarrantyOrderApi } from "../../Api/WarrantyApi";
import { IOrderRespone } from "../../Model/OrderModel";



type FinishedOrder = {
    id: string,
    orderCode: string,
    customer: string,
    phone: string,
    address: string,
}

type WarranityModalProps = ModalProps &
{
    id?: string;
    okModalFnc: Function
}

export function ActiveWarranityModal(props: WarranityModalProps) {
    const { id, okModalFnc, ...rest } = props;

    const [filteredOrders, setFilteredOrders] = useState<FinishedOrder[]>([]);
    const [finishedOrders, setFinishOrder] = useState<FinishedOrder[]>([]);
    const [isLoadingData, setIsLoadingData] = useState<boolean>(false);

    const [activeTime, setActivateTime] = useState<Date>(new Date());
    const [popoverVis, setPopoverVis] = useState<boolean>(false);
    const [clickedRecord, setClickedRecord] = useState<FinishedOrder | null>(null);
    useEffect(() => {
        // VA not sure
        if (rest.visible !== false)
            return;

        GetUnActiveWarrantyOrderApi((res: IListItems<IOrderRespone>) => {
            const orders = res.items
                .filter(x => !!x.customer)
                .map(x => ({
                    customer: x.customer.fullName,
                    id: x.id,
                    orderCode: x.orderCode,
                    phone: x.customer.phoneNumber,
                    address: x.customer.address
                }))
            setFinishOrder(orders);
            setFilteredOrders(orders);
        })
    }, [rest.visible])

    const okInvoke = () => {
        okModalFnc()
    }

    const searchRef = useRef<Input>(null);
    const SearchReturnOrderSubmit = () => {
        const search = searchRef.current?.input.value;
        if (search == null || search === "")
            setFilteredOrders(finishedOrders);
        else {
            const result = finishedOrders.filter(x => x.customer.includes(search) ||
                x.orderCode.includes(search))
            setFilteredOrders(result);
        }


    };

    //Kích hoạt bảo hành và các lần bảo trì miễn phí
    const ActiveOrderHandle = (record: FinishedOrder) => {
        return () => {
            setPopoverVis(false);
            if (activeTime > new Date()) {
                message.error("Ngày kích hoạt bảo hành phải nhỏ hơn ngày hiện tại");
            }
            else {
                ActiveWarrantyForOrderApi(record.id, activeTime, (res: any) => {
                    message.info("Kích hoạt bảo hành thành công");

                    okModalFnc();
                })
            }


        }
    }

    function handleVisibleChange(vis: boolean) {
        setPopoverVis(vis);
    }

    function PopoverVis(record: FinishedOrder): boolean {

        return (record.id === clickedRecord?.id && popoverVis);

    }
    const FinishedOrderColumns: ColumnsType<FinishedOrder> = [
        {
            title: "Mã đơn hàng",
            key: "orderCode",
            dataIndex: "orderCode",
            render: (orderCode, record) => (
                <Popover placement="bottom"
                    title="Ngày kích hoạt bảo hành"
                    visible={PopoverVis(record)}
                    onVisibleChange={handleVisibleChange}
                    trigger="click"
                    content={
                        <div>
                            <DatePicker format='DD/MM/YYYY' placeholder="Chọn ngày"
                                onChange={(val) => {
                                    setActivateTime((val?.toDate()) ?? (new Date))
                                }}
                                showToday={true} defaultValue={moment()} />
                            <Button type="primary" onClick={ActiveOrderHandle(record)}>Kích hoạt</Button>
                        </div>
                    }>

                    <Link onClick={() => {
                        setClickedRecord(record)
                        setPopoverVis(true)
                    }}>{orderCode}</Link>
                </Popover >
            )
        },
        {
            title: "Khách hàng",
            key: "customer",
            dataIndex: "customer",
        },
        {
            title: "SĐT",
            key: "phone",
            dataIndex: "phone",
        },
        {
            title: "Địa chỉ",
            key: "address",
            dataIndex: "address",
        },

    ]

    return (
        <Modal {...rest}
            centered
            onOk={okInvoke}
            footer={false}
            bodyStyle={{ overflowY: 'scroll' }}
        >
            <Input.Search allowClear placeholder="Tìm kiếm đơn hàng đã hoàn thành"
                ref={searchRef}
                onSearch={SearchReturnOrderSubmit}
                onPressEnter={SearchReturnOrderSubmit} />
            <Spin spinning={isLoadingData} tip="Đang tải">
                <Table bordered

                    columns={FinishedOrderColumns}
                    dataSource={filteredOrders}
                    pagination={{ pageSize: 25 }}

                />
            </Spin>

        </Modal>
    )
}