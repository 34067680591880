import { DatePicker, Dropdown, Form, Input, message, Modal, ModalProps, Space, Menu } from "antd";

import {
    CheckCircleOutlined,
    DownOutlined
} from '@ant-design/icons';
import { useState } from "react";
import { CreateMaitenanceApi } from "../../Api/MaintenanceApi";
import { maintenancesResponse } from "../../Model/WarrantiesModel";
import { CreateWarrantyApi } from "../../Api/WarrantyApi";
import { WarrantyViewModel } from "../../ViewModel/WarrantyViewModel";


const { TextArea } = Input;
type MaintainProps = ModalProps & {
    warrantyOrder: WarrantyViewModel | undefined;
    okModalFnc: Function
}

type warrantyType = {
    type: number,
    name: string
}

export function MaintainModal(props: MaintainProps) {

    const { warrantyOrder, okModalFnc, ...rest } = props;

    const [currentType, setCurrentType] = useState<warrantyType>({ type: 1, name: "Bảo hành" })

    const [form] = Form.useForm();
    const okInvoke = () => {

        form.validateFields().then((data: any) => {

            const reason = form.getFieldValue("reason");
            const expectTime = form.getFieldValue("expectTime");
            if (reason == null || reason === "") {
                message.warning("Cần nhập lý do");
                return;
            }
            if (currentType.type === 1) { //Bảo hành
                if (warrantyOrder) {
                    CreateWarrantyApi({
                        productOrderId: warrantyOrder.productId,
                        description: "",
                        reason: reason,
                        externalInformation: reason
                    }, (res: any) => {
                        message.info("Phiếu bảo hành đã được tạo");
                    })
                }

            } else if (currentType.type === 2) { //Bảo dưỡng
                if (warrantyOrder) {
                    CreateMaitenanceApi({
                        productOrderId: warrantyOrder.productId,
                        expectedTime: expectTime,
                        highPriorityNote: reason
                    }, (res: maintenancesResponse) => {
                        message.info("Phiếu yêu cầu bảo trì đã được tạo");
                    })
                }

            }
            okModalFnc();
        })
    }

    const onChooseCustomerType = (info: any) => {
        if (info.key === "1") {
            setCurrentType({ type: 1, name: "Bảo hành" });
        } else if (info.key === "2") {
            setCurrentType({ type: 2, name: "Bảo dưỡng" });
        }

    };

    return (
        <Modal {...rest} centered
            okText="Đồng ý"
            cancelText="Đóng"
            onOk={okInvoke}>

            <Form layout="vertical" form={form}>

                <Space direction="horizontal">
                    <CheckCircleOutlined style={{ color: "green" }} />
                    <div style={{ fontWeight: 'bold', padding: 5 }}>{warrantyOrder?.imei}</div>
                    <Dropdown overlay={<Menu onClick={onChooseCustomerType} >
                        <Menu.Item key="1">Bảo hành</Menu.Item>
                        <Menu.Item key="2">Bảo dưỡng</Menu.Item>
                    </Menu>}>
                        <div className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                            {currentType.name} <DownOutlined />
                        </div>

                    </Dropdown >
                </Space>

                <Form.Item label="Lý do" name="reason" required>
                    <TextArea rows={2} />
                </Form.Item>
                {currentType.type === 1 ? ("") : (<Form.Item label="Ngày mong muốn" name="expectTime" required >
                    <DatePicker format='DD/MM/YYYY' placeholder="Chọn ngày" />
                </Form.Item>)}

            </Form>
        </Modal>
    )
}