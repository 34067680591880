import { Switch, SwitchProps } from "antd";

export function LabelColor(props: SwitchProps) {

    return (
        <div className="flex items-center justify-between py-1">
            <span>Màu sản phẩm</span>
            <span>
                <Switch  {...props} size="small" style={{ marginLeft: 5 }} />
            </span>

        </div>
    )
}