import { Modal, Spin, ModalProps, Form, Input, Table, InputNumber, Space, Row, Col, message } from "antd";
import {
    DeleteOutlined
} from '@ant-design/icons';

import { useEffect, useState } from "react";
import { KeyCode } from "../../common/type-define";
import { ColumnsType } from "antd/lib/table";
import { DefectProductOrderDetail } from "../../Model/ProductModel";
import { DefectProductResponse } from "../../Model/ProductModel";
import { GetWareHousesApi } from "../../Api/WarehouseApi";
import { WarehouseApi } from "../../Api/WarehouseApi";
import { DefectProductApi } from "../../Api/DefectProductApi";
import CCombobox from "../Controls/CCombobox";
const { TextArea } = Input;

type CreateDefectProductOrderModalProps = ModalProps &
{
    CreatedFunc: Function;
    defectProduct: DefectProductResponse | undefined;
}

export default function CreateDefectProductOrderModal(props: CreateDefectProductOrderModalProps) {
    const { CreatedFunc, defectProduct, ...rest } = props;
    const [dataForm] = Form.useForm<any>();
    const [loading, setLoading] = useState(false);
    const [wareHouseList, setwareHouseList] = useState<WareHouseResponse[]>([]);
    const [orderDetails, setOrderDetailList] = useState<DefectProductOrderDetail[]>([]);
    const [wareHouseId, setWareHouseId] = useState<string>();
    const [productVersions, setProductVersionList] = useState<ProductVersionResponse[]>([]);

    useEffect(() => {

        GetWareHousesApi((res: IListItems<WareHouseResponse>) => {
            setwareHouseList(res.items);
        });
        let newOrderDetails: DefectProductOrderDetail[] = []
        setOrderDetailList(newOrderDetails);
        setProductVersionList([])
        setWareHouseId('')

    }, [rest.visible]);

    function chooseWarehouse(value: any) {
        setWareHouseId(value);

        async function fetchProductVersion() {
            setLoading(true);
            let res = await WarehouseApi.getProductVersionByWarehouseIds([value])
            if (res?.status === 200) {
                setProductVersionList(res.data.items);
                let newOrderDetails: DefectProductOrderDetail[] = []
                setOrderDetailList(newOrderDetails);
            }
            setLoading(false);
        }

        fetchProductVersion();

    }

    //Thay dổi số lượng mua sản phẩm
    const onChangeQuantity = (rowIndex: number) => {
        return () => {

            let inputQuantity = dataForm.getFieldValue(rowIndex);
            let newQuanity = Number.parseInt(inputQuantity);
            if (rowIndex !== -1) {
                let newOrderDetails: DefectProductOrderDetail[] = [...orderDetails];
                const prodChanged = newOrderDetails[rowIndex];

                const newOrderDetail: DefectProductOrderDetail = {
                    ...prodChanged,
                    quantities: newQuanity
                };
                newOrderDetails[rowIndex] = newOrderDetail;
                setOrderDetailList(newOrderDetails);
            }
        }
    };

    //Xóa bỏ phụ kiện
    const handleRemoveOrderDetail = (orderDetail: DefectProductOrderDetail) => {
        return () => {
            const newOrderDetails = [...orderDetails];
            const findIndex = newOrderDetails.findIndex(p => p.productVersionId === orderDetail.productVersionId);
            if (findIndex !== -1) {
                newOrderDetails.splice(findIndex, 1);
                setOrderDetailList(newOrderDetails);
            }
        }
    };

    function productVersionChange(value: any) {
        let selectedPrdVer = productVersions.find(p => p.id === value);
        if (selectedPrdVer != null) {
            let newOrderDetails: DefectProductOrderDetail[] = [...orderDetails]
            const newOrderDetail: DefectProductOrderDetail = {
                productVersionId: selectedPrdVer.id,
                productName: selectedPrdVer.fullName,
                quantities: 1
            }
            newOrderDetails.push(newOrderDetail);
            setOrderDetailList(newOrderDetails);
            dataForm.setFieldsValue({ productSearchId: "" })
        }
    }

    function createDefectProductOrderInvoke() {
        const reason = dataForm.getFieldValue("reason");
        if (!reason) {
            message.info('Bạn cần điền lý do')
            return;
        }
        if (orderDetails.length === 0) {
            message.info("Cần thêm phụ kiện để xuất kho")
            return;
        }
        let data = {
            productDefectId: defectProduct?.id,
            reason: reason,
            orderDetails: orderDetails
        }
        DefectProductApi.CreateDefectProductOrderApi(data, () => {
            message.success("Đã tạo phiếu xuất kho");
            CreatedFunc();
        })

    }

    // Số trường trên table
    const ProductCols: ColumnsType<DefectProductOrderDetail> = [
        {
            title: 'Tên sản phẩm',
            dataIndex: 'productName',
            key: 'productName'
        },
        {
            title: 'Số lượng',
            dataIndex: 'quantities',
            key: 'quantities',
            width: 100,
            render: (quantities, record, index) => (
                <Form.Item name={index} style={{ marginBottom: 0 }}>
                    <InputNumber min={1} defaultValue={quantities} onChange={onChangeQuantity(index)} />
                </Form.Item>
            ),
        },
        {
            title: '',
            key: 'action',
            render: (record) => (
                <Space size="middle" >
                    <DeleteOutlined style={{ color: 'red' }} onClick={handleRemoveOrderDetail(record)} />
                </Space>
            ),
        },
    ];

    return (
        <Modal {...rest}
            onOk={createDefectProductOrderInvoke}>
            <Spin spinning={loading} tip="đang tải...">
                <Form layout="vertical" form={dataForm}>
                    <Form.Item label="Lý do" name="reason">
                        <TextArea rows={2} />
                    </Form.Item>
                    <Form.Item label="Chọn kho">
                        <CCombobox
                            chooseOptions={wareHouseList}
                            displayName="name"
                            id="id"
                            onChange={chooseWarehouse} />
                    </Form.Item>
                    <Form.Item name="productSearchId" label="Chọn linh kiện">
                        <CCombobox chooseOptions={productVersions}
                            placeholder="Chọn linh kiện"
                            displayName="fullName" id="id" onChange={productVersionChange} />
                    </Form.Item>
                    <Table dataSource={orderDetails}
                        style={{ maxHeight: '30vh', overflow: 'auto' }}
                        size="small"
                        columns={ProductCols}
                        pagination={false} />
                </Form>
            </Spin>

        </Modal>
    )
}
