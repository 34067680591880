import {
    PicCenterOutlined,
    PicRightOutlined
} from '@ant-design/icons';
import { Col, Row } from "antd";
import { CardItem } from "../Controls/LinkItem";


export default function StoreReportView() {

    return (
        <>
            <Row>
                <Col span={12}>
                    <CardItem title="Báo cáo tồn kho hệ thống"
                        icon={<PicCenterOutlined />}
                        desc="Quản lý số lượng và giá trị tồn kho của chi nhánh và toàn hệ thống"
                        url="store/remaining" />
                </Col>
                <Col span={12}>
                    <CardItem title="Báo cáo tồn kho chi tiết"
                        icon={<PicRightOutlined />}
                        desc="Quản lý hàng hóa ở các trạng thái khác nhau"
                        url="store/remaining_detail" />
                </Col>
            </Row>

            <Row>
                <Col span={12}>
                    <CardItem title="Báo cáo tồn kho theo định mức"
                        icon={<PicCenterOutlined />}
                        desc="Quản lý các sản phẩm có tồn kho theo định mức"
                        url="store/remaining_norm" />
                </Col>
            </Row>

            <Row>
                <Col span={12}>
                    <CardItem title="Báo cáo kiểm kê hàng hóa"
                        icon={<PicRightOutlined />}
                        desc="Quản lý các thông tin khi kiểm hàng, số lượng hàng hỏng và lý do"
                        url="store/inventory" />
                </Col>
            </Row>
        </>
    )
}