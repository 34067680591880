import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CustomerRes } from "../../Model/CustomerModel";


interface ICustomerActionState {
    commissionCustomer: CustomerRes | null,
}

const initState: ICustomerActionState = {
    commissionCustomer: null,
}

const customerActionSlice = createSlice({
    name: 'customerAction',
    initialState: initState,
    reducers: {
        setCommissionCustomer(state, action: PayloadAction<CustomerRes | null>) {
            state.commissionCustomer = action.payload;
        },
    }
});

export const {
    setCommissionCustomer
} = customerActionSlice.actions;

export default customerActionSlice.reducer;