import { Card, Space } from "antd";
import { ReactNode } from "react";



export default function LinkItem(props: { title: string, desc: string, url?: string, onClick?: Function }) {
    const { title, url, desc, onClick } = props;

    const onClickHandle = (e: any) => {

        if (onClick)
            onClick();
    }
    return (
        <div className="flex" onClick={onClickHandle}>
            <div style={{ padding: 10 }}>
                <a href={url}>
                    <span style={{ fontWeight: 'bold' }}>{title}</span></a>
                <div>{desc}</div>
            </div>
        </div>

    )
}


export function CardItem(props: { title: string, icon: ReactNode, desc: string, url?: string, onClick?: Function }) {
    const { title, url, desc, onClick, icon } = props;

    const onClickHandle = (e: any) => {

        if (onClick)
            onClick();
    }
    return (
        <Card onClick={onClickHandle} size="small" style={{ margin: 10 }}>
            <Space direction="horizontal">
                {icon}
                <div style={{ padding: 10 }}>
                    <a href={url}>
                        <span style={{ fontWeight: 'bold' }}>{title}</span></a>
                    <div>{desc}</div>
                </div>
            </Space>

        </Card>

    )
}