import {
    PlusOutlined, SearchOutlined,
    VerticalAlignBottomOutlined,
    AppstoreAddOutlined, DeleteOutlined,
    ExclamationCircleOutlined
} from '@ant-design/icons';
import { Button, Col, Input, Row, Space, Spin, Table, Tooltip, Typography, Modal } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetMainProductsApi, GetProducts, RemoveProductApi } from '../../Api/ProductApi';
import { KeyCode, ProductTypes } from '../../common/type-define';
import { compareString, isAgencyAccount } from '../../Helper/CommonHelper';
import { useShortKey } from '../../hooks/useShortKey';
import { cellStyle, getHeightFit, headerStyle } from '../Helper/UIHelper';
import ProductExpandItem from './ProductExpand/ProductExpandItem';
const { confirm } = Modal;
const { Link } = Typography;
function ProductsView() {
    const [state, setstate] = useState<ProductResponse[]>([]);
    const [loading, setloading] = useState(true);
    const searchRef = useRef<Input>(null);
    const navigate = useNavigate();
    useShortKey(KeyCode.F3, () => searchRef.current?.focus());

    //Lấy toàn bộ sản phẩm
    function fetchProducts() {
        GetMainProductsApi((data: IListItems<ProductResponse>) => {
            const dataMapKeys = data.items.map(v => ({ ...v, key: v.id }));
            setstate(dataMapKeys);
        }).finally(() => {
            setloading(false);
        })
    }

    // Áp dụng khi của sổ bắt đầu được xuất hiện
    useEffect(() => {
        fetchProducts();
    }, []);

    const onClickProductCode = (id: string) => {

        if (isAgencyAccount() === false)
            navigate(`${id}`);
    }

    const onClickCreateAccessory = (id: string) => {
        navigate(`create_accessory/${id}`);
    }

    const onClickRemoveProduct = (product: ProductResponse) => {
        confirm({
            title: 'Bạn có muốn tạo xóa sản phẩm?',
            icon: <ExclamationCircleOutlined />,
            content: 'Xóa sản phẩm' + product.name,
            onOk() {
                RemoveProductApi(product.id, () => {
                    setloading(true);
                    fetchProducts();
                })

            },
            onCancel() {

            },
        });
    }

    const columns: ColumnsType<ProductResponse> = [
        {
            title: 'Mã sản phẩm',
            dataIndex: 'code',
            key: 'code',
            width: '10%',
            onCell: () => cellStyle(),
            onHeaderCell: () => headerStyle(),
            render: (code, record) => (
                <Link onClick={() => onClickProductCode(record.id)} >
                    <span>{code}</span>
                </Link>
            )
        },
        {
            title: 'Tên sản phẩm',
            dataIndex: 'name',
            key: 'name',
            onCell: () => cellStyle(true),
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Đơn vị',
            dataIndex: 'unit',
            key: 'unit',
            onHeaderCell: () => headerStyle(),
            render: (unit) => (
                <span>{unit ? unit.name : ""}</span>
            )
        },
        {
            title: 'Bảo hành',
            dataIndex: 'defaultWarrantyDays',
            key: 'warranty',
            onHeaderCell: () => headerStyle(),
            render: (defaultWarrantyDays) => (
                <span>{`${defaultWarrantyDays / 30} Tháng`}</span>
            )
        },
        {
            title: 'Bảo trì',
            dataIndex: 'freeMaintenanceTimes',
            key: 'maintenance',
            width: '10%',
            onHeaderCell: () => headerStyle(),
            render: (freeMaintenanceTimes) => (
                <span>{`${freeMaintenanceTimes} lần`}</span>
            )
        },
        {
            title: 'Hành động',
            key: 'action',
            align: 'center',
            fixed: 'right',
            width: '10%',
            onHeaderCell: () => headerStyle(),
            render: (_: any, record: ProductResponse) => (
                <Space direction="horizontal">
                    <Tooltip title="Thêm phụ kiện" color="green">
                        <AppstoreAddOutlined style={{ color: "green" }} onClick={() => onClickCreateAccessory(record.id)} />
                    </Tooltip>

                    <Tooltip title="Xóa sản phẩm" color="red">
                        <DeleteOutlined style={{ color: "red" }} onClick={() => onClickRemoveProduct(record)} />
                    </Tooltip>
                </Space>

            )
        },
    ];


    const SearchWarrantiesSubmit = () => {
        setloading(true);
        const search = searchRef.current?.input.value.toLowerCase();
        let newProducts: ProductResponse[] = [];
        GetProducts((data: IListItems<ProductResponse>) => {
            setloading(false);

            if (search == null || search === "") {
                newProducts = data.items.filter(x => x.productType === 0);
            }
            else {

                data.items.map(product => {
                    if (product.productType === ProductTypes.Main) {
                        if (compareString(product.name, search) ||
                            compareString(product.code, search))
                            newProducts.push(product);
                    }
                })
            }
            setstate(newProducts);
        })
    };

    //Xử lý cho tình huống xóa hết text
    const searchTextChange = () => {
        try {
            const search = searchRef.current?.input.value.toLowerCase();
            // VA not sure
            if (search == null || search === "") {
                GetProducts((data: IListItems<ProductResponse>) => {
                    setloading(false);

                    let newProducts = data.items.filter(x => x.productType === ProductTypes.Main);
                    setstate(newProducts);
                });
            }
        } catch (error) { }
    }

    const onClickCreateProduct = () => {
        navigate("/admin/products/create", { state: "Thêm sản phẩm" })
    }

    return (
        <>
            <Row wrap={false} style={{ marginBottom: '16px' }}>
                <Col flex="none">
                    {/* <Button type="link" icon={<VerticalAlignBottomOutlined />} size="middle">
                        Xuất file
                    </Button> */}
                </Col>
                <Col flex="auto" style={{ padding: '0 16px' }}>
                    <Input
                        prefix={<SearchOutlined />}
                        allowClear={true}
                        ref={searchRef}
                        placeholder="Tìm kiếm sản phẩm (F3)"
                        onChange={searchTextChange}
                        onPressEnter={SearchWarrantiesSubmit} />
                </Col>

                <Col flex="none" >
                    <Button
                        onClick={onClickCreateProduct}
                        type="primary"
                        icon={<PlusOutlined />}
                        size="middle">
                        Thêm sản phẩm
                    </Button>
                </Col>
            </Row>

            <div>
                <Spin spinning={loading} tip="đang tải...">
                    <Table bordered
                        columns={columns}
                        dataSource={state}
                        pagination={{ pageSize: 20 }}
                        expandable={{
                            rowExpandable: record => true,
                            expandedRowRender: (record: ProductResponse, index: number, indent: number, expanded: boolean) =>
                                <>
                                    {expanded ? <ProductExpandItem productResponse={record} /> : <></>}
                                </>,
                        }}
                        scroll={{ x: true, y: getHeightFit(250) }}
                    />
                </Spin>

            </div>
        </>
    )
}

export default ProductsView;