import { Col, Form, Input, message, Modal, ModalProps, Row } from "antd";
import { useEffect, useState } from "react";
import { GetCities, GetDistricts } from "../../../Api/AddressApi";
import { WarrantyCenterApi } from "../../../Api/WarrantyCenterApi";
import { WarrantyCenterRequest, WarrantyCenterResponse } from "../../../Model/WarrantyCenterModal";
import CCombobox from "../../Controls/CCombobox";

type WarrantyModalProps = ModalProps & {
    data: WarrantyCenterResponse | null,
    applyFunc: Function
}
export default function WarrantyModal(props: WarrantyModalProps) {
    const { data, applyFunc, ...rest } = props;
    const [warrantyForm] = Form.useForm<WarrantyCenterRequest>();
    const [districts, setDistricts] = useState<DistrictResponse[]>([]);
    const [cities, setCities] = useState<CityResponse[]>([]);

    useEffect(() => {
        if (rest.visible) {
            GetCities((data: IListItems<CityResponse>) => { setCities(data.items); })

            if (data != null) {
                if (data.city) {
                    cityChange(data.city?.id);
                }

                warrantyForm.setFieldsValue({
                    name: data.name,
                    cityId: data.city?.id,
                    districtId: data.district?.id,
                    address: data.address,
                    hotline: data.hotline
                })
            } else {
                warrantyForm.setFieldsValue({
                    name: "",
                    cityId: "",
                    districtId: "",
                    address: "",
                    hotline: ""
                })
            }
        }

    }, [rest.visible])


    // Khi chọn thành phố thì sẽ cập nhật quân/huyện
    function cityChange(value: any) {
        GetDistricts(value, (distrs: DistrictResponse[]) => {
            setDistricts(distrs);
        })
    }

    const onApplyWarrantyCenter = () => {
        warrantyForm.validateFields().then((newData: WarrantyCenterRequest) => {

            if (!newData.name || newData.name === '') {
                message.warn("Tên trung tâm bị trống");
                return;
            }

            if (!newData.hotline || newData.hotline === '') {
                message.warn("Số điện thoại trung tâm bị trống");
                return;
            }

            if (!newData.address || newData.address === '') {
                message.warn("Địa chỉ trung tâm bị trống");
                return;
            }

            if (data != null) {
                WarrantyCenterApi.UpdateWarrantyCenterById(data.id, newData, (res: any) => {
                    message.info("Cập nhật dữ liệu thành công");
                    applyFunc?.(data);
                })
            } else {
                WarrantyCenterApi.CreateWarrantyCenter(newData, (res: any) => {
                    message.info("Cập nhật dữ liệu thành công");
                    applyFunc?.(res);
                })
            }
        })

    }

    return (
        <Modal {...rest}
            onOk={onApplyWarrantyCenter} >
            <Form form={warrantyForm}
                layout="vertical" >
                <Form.Item name="name" label="Tên trung tâm bảo hành" required >
                    <Input placeholder="Nhập tên trung tâm bảo hành" />
                </Form.Item>
                <Form.Item label="Số điện thoại" required name="hotline">
                    <Input placeholder="Nhập mã khách hàng" />
                </Form.Item>

                <Row>
                    <Col span={12} style={{ paddingRight: 5 }}>
                        <Form.Item label="Tỉnh/Thành phố" name="cityId">
                            <CCombobox chooseOptions={cities ? cities : []} displayName="name" id="id" onChange={cityChange} />
                        </Form.Item>
                    </Col>

                    <Col span={12}>

                        <Form.Item label="Quận/Huyện" name="districtId">
                            <CCombobox chooseOptions={districts} displayName="name" id="id" />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item name="address" label="Địa chỉ" required >
                    <Input placeholder="Nhập địa chỉ" />
                </Form.Item>

            </Form>
        </Modal>
    )
}