import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { catchError, filter, switchMap } from 'rxjs';
import { ReportApi } from '../../Api/Reports/report.api';
import { RootEpic } from '../configure/rootReducer';

interface IReportState {
    data: IOrderReport[],
    loading: boolean
}

const init: IReportState = {
    data: [],
    loading: false
}

const reportSlice = createSlice({
    name: "report",
    initialState: init,
    reducers: {
        setData(state, action: PayloadAction<IOrderReport[]>) {
            state.data = action.payload
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload
        },
        fetchReportData(state, action: PayloadAction<QueryOrderReport>) {
            state.loading = true;
        }
    }
})

const fetchData$: RootEpic = (action$, state$) => action$.pipe(
    filter(fetchReportData.match),
    switchMap(({payload}) => {
        return ReportApi.getOrderReports(payload).pipe(
            switchMap(data => [
                setData(data),
                setLoading(false)
            ]),
            catchError(err => [setLoading(false)])
        )
    })
)

export const ReportEpics = [
    fetchData$
]

export const {setData, fetchReportData, setLoading} = reportSlice.actions;
export default reportSlice.reducer;