import { AxiosRequest } from "./Axios/AxiosRequest";

import { FactoryRequest } from "../Model/FactoryModel";
import { HandleRequest } from "./RequestAPI";
import { SERVER_API } from "./UrlDefination";


export const GetFactories = async (doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.FACTORY.GET}`), doAfterResponse);
};

export const CreateFactoryApi = async (data: FactoryRequest, doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.post(`${SERVER_API.HOST}/${SERVER_API.FACTORY.CREATE}`, data), doAfterResponse);
};