import { Spin, Table, Button, Divider } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { WarrantyCenterApi } from "../../Api/WarrantyCenterApi";
import { WarrantyCenterResponse } from "../../Model/WarrantyCenterModal";
import { getHeightFit } from "../Helper/UIHelper";
import WarrantyModal from "./WarrantyCenter/WarrantyModal";
import {
    PlusOutlined,
    LeftOutlined
} from '@ant-design/icons';
import Link from "antd/lib/typography/Link";

export default function WarrantyCenterView() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [warrantyCenters, setWarrantyCenter] = useState<WarrantyCenterResponse[]>([]);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [editData, setEditData] = useState<WarrantyCenterResponse | null>(null);

    const fetchData = () => {
        setIsLoading(true);
        WarrantyCenterApi.GetWarrantyCenters((res: IListItems<WarrantyCenterResponse>) => {
            setWarrantyCenter(res.items);
        }
        ).finally(() => {
            setIsLoading(false);
        });
    }
    useEffect(() => {
        fetchData()
    }, []);


    const editCenter = (center: WarrantyCenterResponse) => {
        return () => {

            setEditData(center);
            setIsModalVisible(true);
        }
    }

    const AfterApplyFromModal = () => {
        setIsModalVisible(false);
        fetchData();
    }

    const columns: ColumnsType<WarrantyCenterResponse> = [
        {
            title: 'Tên trung tâm',
            dataIndex: 'name',
            key: 'name',
            render: (customerName, record) => (
                <Link onClick={editCenter(record)}>
                    {customerName}
                </Link>
            )
        },
        {
            title: 'Tỉnh/Thành phố',
            dataIndex: 'city',
            key: 'city',
            render: (city, record) => (
                <span>{record.city?.name}</span>
            )
        },
        {
            title: 'Số điện thoại',
            dataIndex: 'hotline',
            key: 'hotline',
        },
        {
            title: 'Địa chỉ',
            dataIndex: 'address',
            key: 'address',

        }
    ];

    return (
        <div>
            <div style={{ display: "flex", height: 50, top: 25 }}>

                <Button type="text" icon={<LeftOutlined />} href="/admin/settings" size="large">
                    Quay lại cấu hình
                </Button>
                <div style={{ flexGrow: 1, textAlign: "end" }}>
                    <Button type="primary" icon={<PlusOutlined />} onClick={() => { setEditData(null); setIsModalVisible(true) }} size="middle">
                        Thêm trung tâm bảo hành
                    </Button>
                </div>
            </div>
            <Divider style={{ marginTop: 0, marginLeft: 20 }} />
            <div>
                <Spin spinning={isLoading} tip="đang tải...">
                    <Table
                        columns={columns}
                        dataSource={warrantyCenters}
                        pagination={{ pageSize: 25 }}
                        scroll={{ x: true, y: getHeightFit(200) }}
                    />
                </Spin>

            </div>

            <WarrantyModal visible={isModalVisible}
                title="Trung tâm bảo hành"
                onCancel={() => setIsModalVisible(false)}
                data={editData}
                applyFunc={AfterApplyFromModal} />
        </div>
    )
}