import { Modal, ModalProps, Form, Input, message } from "antd";
import { useState } from "react";
import { ColorResult } from "react-color";
import { CreateAttributeApi } from "../../Api/AttributesApi";
import ColorPickerComponent from "../Controls/ColorPickerComponent";

type ColorVersionProps = ModalProps & {
    okModalFnc: Function
}

export default function ColorVersionModal(props: ColorVersionProps) {

    const { okModalFnc, ...rest } = props;
    const [form] = Form.useForm();
    const [colorCode, setColorCode] = useState<string>("");

    const CreateColor = () => {
        const name = form.getFieldValue("color");
        if (colorCode == null || name == null) {
            message.info("Thông tin chưa đủ");
            return;
        }
        let colorReq: AttributeReq = {
            name: name,
            attributeType: 0,
            description: "",
            code: colorCode
        }
        CreateAttributeApi(colorReq, (res: AttributeResponse) => {
            okModalFnc(res);
        })
    }

    const changeColor = (value: ColorResult) => {
        setColorCode(value.hex);
    }
    return (
        <Modal {...rest}
            onOk={CreateColor}
            okText="Tạo màu"
            cancelText="Hủy bỏ"
        >
            <Form layout="vertical" form={form}>
                <Form.Item label="Màu phiên bản" name="color" required>
                    <Input />
                </Form.Item>

                <ColorPickerComponent ColorChanged={changeColor} />
            </Form>
        </Modal>
    )
}