import clsx from "clsx";
import { useEffect, useState } from "react";
import { MathHelper } from "../../Helper/math.helper";

type PriceVNDProps = { className: string, price: number, override?: boolean }
export default function PriceVND({ price, className, override }: PriceVNDProps): JSX.Element {
    const [priceFinal, setPrice] = useState<string>();

    useEffect(() => {
        if (price !== 0) {
            setPrice(MathHelper.formatPriceVND(price))
        } else {
            setPrice('0 đ')
        }
    }, [price])

    return (
        <span className={clsx(
            className,
            !override && "text-red-600 text-lg font-bold",
        )}>{priceFinal}</span>
    )
}