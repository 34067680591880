import { AxiosRequest } from "./Axios/AxiosRequest";

import { ApproveOrderData, EditOrderData, NewOrderData, PackageOrderData, paidDataReq, paymentRequest } from "../Model/OrderModel";
import { HandleRequest, RequestHandler } from "./RequestAPI";
import { SERVER_API } from "./UrlDefination";
import { OrderStatus } from "../common/type-define";


export class OrderApi {
    static async getOrders(orderStatusFilter: OrderStatus[] | undefined, doAfterResponse: Function) {
        let extraQueryParam = '';
        if (orderStatusFilter !== undefined) {
            extraQueryParam = orderStatusFilter.map(v => `OrderStatuses=${v}`).join('&');
        }
        return RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.ORDER.GET}?${extraQueryParam}`), doAfterResponse);
    };

    static async getOrderById(id: string, doAfterResponse: Function) {
        return RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.ORDER.GET_BY_ID}?Id=${id}`), doAfterResponse);
    };

    static async paidOrderById(id: string, data: paidDataReq, doAfterResponse: Function) {
        return RequestHandler.handleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.ORDER.PUT_PAID(id)}`, data), doAfterResponse);
    };

}


export const getOrdersApi = async (doAfterResponse: Function, skip?: number, take?: number) => {

    let url = `${SERVER_API.HOST}/${SERVER_API.ORDER.GET}`;
    if (skip !== null && skip !== undefined && take !== null && take !== undefined) {
        url = `${SERVER_API.HOST}/${SERVER_API.ORDER.GET}?Skip=${skip}&Take=${take}`
    }

    await HandleRequest(AxiosRequest.get(url), doAfterResponse);
};

export const searchOrdersApi = async (doAfterResponse: Function, name?: string, phone?: string, skip?: number, take?: number) => {

    let url = `${SERVER_API.HOST}/${SERVER_API.ORDER.SEARCH}`;
    if (skip !== null && skip !== undefined) {
        url = url + `?Skip=${skip}`
    }

    if (take !== null && take !== undefined) {
        url = url + `&Take=${take}`;
    }

    if (name !== null && name !== undefined) {
        url = url + `&CustomerName=${name}`;
    }

    if (phone !== null && phone !== undefined) {
        url = url + `&PhoneNumber=${phone}`;
    }

    await HandleRequest(AxiosRequest.get(url), doAfterResponse);
};

export const getOrdersByDateApi = async (doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.ORDER.GET}`), doAfterResponse);
};


export const getOrderByIdApi = async (id: string, doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.ORDER.GET_BY_ID}?Id=${id}`), doAfterResponse);
};


export const CreateOrderFromStaffApi = async (orderData: NewOrderData, customerId: string, doAfterResponse: Function) => {

    await HandleRequest(AxiosRequest.post(`${SERVER_API.HOST}/${SERVER_API.ORDER.CREATE_BY_STAFF}/${customerId}`, orderData), doAfterResponse);
}

export const ApproveOrderFromStaffApi = async (id: string, data: ApproveOrderData, doAfterResponse: Function) => {

    await HandleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.ORDER.PUT_APPROVE(id)}`, data), doAfterResponse);
}

export const EditOrderFromStaffApi = async (id: string, orderData: EditOrderData, doAfterResponse: Function) => {

    await HandleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.ORDER.PUT_UPDATE(id)}`, orderData), doAfterResponse);
}

export const PackageOrderFromStaffApi = async (id: string, data: PackageOrderData, doAfterResponse: Function) => {

    await HandleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.ORDER.PUT_PACKAGE(id)}`, data), doAfterResponse);
}
export const DeliveryFromStaffApi = async (id: string, doAfterResponse: Function) => {

    await HandleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.ORDER.PUT_DELIVERY(id)}`, null), doAfterResponse);
}

export const CompleteOrderFromStaffApi = async (id: string, payment: paymentRequest, doAfterResponse: Function) => {

    await HandleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.ORDER.PUT_COMPLETE(id)}`, payment), doAfterResponse);
}

export const CompletePaidCarrierApi = async (id: string, paymentType: number, doAfterResponse: Function) => {

    await HandleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.ORDER.PUT_PAID_CARRIER(id)}`, { orderId: id, paymentType: paymentType }), doAfterResponse);
}


export const CancelOrderFromStaffApi = async (id: string, reason: string, doAfterResponse: Function) => {

    await HandleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.ORDER.PUT_CANCEL(id)}`, { reason: reason }), doAfterResponse);
}

export const CustomerRequestCancelOrderApi = async (id: string, reason: string, doAfterResponse: Function) => {

    await HandleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.ORDER.REQUEST_CANCEL(id)}`, { reason: reason }), doAfterResponse);
}
