import {
    DeleteOutlined, PlusOutlined,
    SearchOutlined, VerticalAlignBottomOutlined, CheckOutlined
} from '@ant-design/icons';
import { Button, Card, Col, Input, message, Row, Space, Spin, Table, Tag, Tooltip, Modal, Dropdown, Menu, Pagination } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { error } from 'console';
import { format } from 'date-fns';
import { isNumber } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { CancelOrderFromStaffApi, CompletePaidCarrierApi, CustomerRequestCancelOrderApi, getOrdersApi, searchOrdersApi } from '../../Api/OrderApi';
import { KeyCode, PaymentOptionEnum, OrderStatus } from '../../common/type-define';
import { canCreateOrder, compareString, convertToNumber, hasNumber } from '../../Helper/CommonHelper';
import { MathHelper } from '../../Helper/math.helper';
import { useShortKey } from '../../hooks/useShortKey';
import { OrderCarrier, IOrderRespone } from '../../Model/OrderModel';
import { OrderViewModel } from '../../ViewModel/OrderViewModel';
import { cellStyle, getHeightFit, headerStyle } from '../Helper/UIHelper';

const { confirm } = Modal;
function OrdersView() {


    const [orders, setOrders] = useState<IOrderRespone[]>([]);
    const [loading, setloading] = useState(false);
    //const [searchParams, setSearchParams] = useSearchParams();

    const [totalOrder, setTotalOrder] = useState<number>(0);
    const searchRef = useRef<Input>(null);
    useShortKey(KeyCode.F3, () => searchRef.current?.focus());

    function fetchData(page: number) {
        const take = 25;
        let skip = take * (page - 1);
        if (skip < 0)
            skip = 0;

        setloading(true);

        getOrdersApi((data: IListItems<IOrderRespone>) => {
            setloading(false);
            setOrders(data.items);
            setTotalOrder(take * (data.totalPage ?? 0))
        }, skip, take)
            .catch(error => console.log(error))
            .finally(() => setloading(false));
    }
    //Sẽ khởi tạo ngay từ khi view được mở
    useEffect(() => {
        //const page = searchParams.get('page');
        //const pageNumber = convertToNumber(page);

        fetchData(1);

    }, []);


    //Tìm kiếm và lọc
    const SearchOrderSubmit = () => {

        const search = searchRef.current?.input.value.toLowerCase();
        if (search == null || search === "" || search === undefined) {
            fetchData(1);
        }
        else {
            const take = 25;
            let skip = 0;
            let name = undefined;
            let phone = undefined;

            if (hasNumber(search)) {
                phone = search;
            }
            else {
                name = search;
            }
            searchOrdersApi((data: IListItems<IOrderRespone>) => {
                setloading(false);
                setOrders(data.items);
                setTotalOrder(take * (data.totalPage ?? 0))
            }, name, phone, skip, take)
                .catch(error => console.log(error))
                .finally(() => setloading(false));
        }


    };

    //Xử lý cho tình huống xóa hết text
    const searchTextChange = () => {
        try {
            const search = searchRef.current?.input.value.toLowerCase();
            // VA not sure
            if (search == null || search === "") {
                fetchData(1);
            }
        } catch (error) {

        }

    }

    //Hủy một đơn hàng
    const CancelOrder = (record: IOrderRespone) => {
        return () => {
            confirm({
                title: 'Hủy đơn hàng',
                icon: <DeleteOutlined />,
                content: 'Bạn có muốn hủy đơn hàng ' + record.orderCode + ' ?',
                onOk() {

                    CustomerRequestCancelOrderApi(record.id, "Khách muốn hủy", () => {
                        CancelOrderFromStaffApi(record.id, "Khách muốn hủy", () => {
                            message.info("Đơn hàng đã được hủy");
                            record.orderStatus = OrderStatus.Cancelled;
                        });

                    })

                },
                onCancel() {

                },
            });


        }
    }

    const CustomerCancelOrder = (record: IOrderRespone) => {
        return () => {
            confirm({
                title: 'Khách hủy đơn hàng',
                icon: <DeleteOutlined />,
                content: 'Bạn có chắc khách muốn hủy đơn hàng ' + record.orderCode + ' ?',
                onOk() {
                    CustomerRequestCancelOrderApi(record.id, "Khách muốn hủy", () => {
                        message.info("Đơn hàng đã được hủy");
                        record.orderStatus = OrderStatus.Cancelled;
                    });

                },
                onCancel() {

                },
            });


        }
    }



    //Lấy các trạng thái đơn hàng
    function GetOrderStatus(status: number): JSX.Element | null {
        switch (status) {
            case OrderStatus.Pending:
            case OrderStatus.Updated:
                return (
                    <Tag color="orange">
                        Chờ duyệt
                    </Tag>
                );
            case OrderStatus.Processing:
                return (<Tag color="orange">
                    Đã duyệt
                </Tag>
                );
            case OrderStatus.Packaging:
                return (<Tag color="orange">
                    Đang giao
                </Tag>
                );
            case OrderStatus.ToDeliver:
                return (<Tag color="orange">
                    Đã giao
                </Tag>);
            case OrderStatus.Completed:
                return (<Tag color="green">
                    Hoàn thành
                </Tag>);

            case OrderStatus.Activated:
                return (<Tag color="green">
                    Hoàn thành
                </Tag>);
            case OrderStatus.Cancelled:
                return (<Tag color="red">
                    Hủy đơn
                </Tag>);
            default:
                return (<Tag color="red">
                    {status}
                </Tag>);
        }
    }


    function GetShipStatusUI(record: IOrderRespone): JSX.Element {
        if (record.orderCarrier) {
            if (record.orderCarrier.isPaid || record.orderCarrier?.price === 0) {
                return <span style={{ color: 'green' }}>{MathHelper.formatPriceVND(record.orderCarrier?.price, true)}</span>
            }
            else {
                if (record.orderStatus === OrderStatus.Completed || record.orderStatus === OrderStatus.Activated) {
                    return (
                        <Space direction='horizontal'>
                            <Dropdown overlay={paidShipOption(record)} trigger={['click']}>
                                <CheckOutlined />
                            </Dropdown>
                        </Space>
                    )
                }
            }
        }
        return (<></>)
    }

    const PaidShipMoney = (record: IOrderRespone, option: number) => {
        CompletePaidCarrierApi(record.id, option, () => {
            message.info("Đã thanh toán tiền vận chuyển");
        })
    }

    const paidShipOption = (record: IOrderRespone) => (
        <Menu>
            <Menu.Item key="0" onClick={() => PaidShipMoney(record, 0)}>
                <span>Tiền mặt</span>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="1" onClick={() => PaidShipMoney(record, 1)}>
                <span>Chuyển khoản</span>
            </Menu.Item>

        </Menu>
    );

    function GetActionUI(record: IOrderRespone): JSX.Element {
        if (record.orderStatus === OrderStatus.Pending ||
            record.orderStatus === OrderStatus.Updated ||
            record.orderStatus === OrderStatus.Processing
        ) {
            return (
                <Space direction="horizontal">
                    <Tooltip title="Hủy đơn hàng" color="red">
                        <DeleteOutlined onClick={CancelOrder(record)} style={{ color: "red" }} />
                    </Tooltip>

                    {/* <DeleteOutlined onClick={viewData(record)} style={{ color: "green" }} /> */}
                </Space>)
        }
        else if (record.orderStatus === OrderStatus.Packaging) {
            return (<Space direction="horizontal">

                <Tooltip title="Khách hủy đơn hàng" color="red">
                    <DeleteOutlined onClick={CustomerCancelOrder(record)} style={{ color: "orange" }} />
                </Tooltip>
                <Tooltip title="Hủy đơn hàng" color="red">
                    <DeleteOutlined onClick={CancelOrder(record)} style={{ color: "red" }} />
                </Tooltip>
            </Space>)

        }
        return (<></>)
    }

    function CustomerNameCellUI(order: IOrderRespone): JSX.Element {
        if (order) {
            if (order.customer) {
                return (<span>{order.customer.fullName}</span>)
            } else if (order.toAgency) {
                return (<span>{order.toAgency.name}</span>)
            }
        }
        return <></>
    }

    function CustomerPhoneCellUI(order: IOrderRespone): JSX.Element {
        if (order) {
            if (order.customer) {
                return (<span>{order.customer.phoneNumber}</span>)
            } else if (order.toAgency) {
                return (<span>{order.toAgency.phoneNumber}</span>)
            }
        }
        return <></>
    }

    const columns: ColumnsType<IOrderRespone> = [
        {
            title: 'Mã đơn hàng',
            dataIndex: 'orderCode',
            key: 'orderCode',
            width: '10%',
            onHeaderCell: () => headerStyle(),
            render: (orderCode, record) => (
                <a href={`orders/${record.id}`}>
                    <span>{orderCode}</span>
                </a>
            )
        },
        {
            title: 'Tên khách hàng',
            dataIndex: 'id',
            key: 'customerName',
            onCell: () => cellStyle(),
            onHeaderCell: () => headerStyle(),
            render: (id, record) => (
                CustomerNameCellUI(record)
            )
        },
        {
            title: 'SĐT khách',
            dataIndex: 'id',
            key: 'customerPhone',
            width: '10%',
            onHeaderCell: () => headerStyle(),
            render: (id, record) => (
                CustomerPhoneCellUI(record)
            )
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'orderTime',
            key: 'orderTime',
            onCell: () => cellStyle(),
            width: '10%',
            onHeaderCell: () => headerStyle(),
            render: (createdTime) => (
                format(new Date(createdTime), "dd-MM-yyyy")
            )
        },
        {
            title: 'Trạng thái',
            dataIndex: 'orderStatus',
            key: 'orderStatus',
            width: '10%',
            onHeaderCell: () => headerStyle(),
            render: (orderStatus) => (
                GetOrderStatus(orderStatus)
            )
        },
        {
            title: 'Thanh toán',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            width: '10%',
            onHeaderCell: () => headerStyle(),
            render: (totalAmount) => (
                MathHelper.formatPriceVND(totalAmount)
            )
        },
        {
            title: 'Tiền Ship',
            dataIndex: 'orderCarrier',
            key: 'orderCarrier',
            width: '10%',
            onHeaderCell: () => headerStyle(),
            render: (orderCarrier, record) => (
                GetShipStatusUI(record)
            )
        },
        {
            title: 'Hành động',
            dataIndex: 'orderStatus',
            key: 'orderStatus',
            align: 'center',
            fixed: 'right',
            width: '10%',
            onHeaderCell: () => headerStyle(),
            render: (orderStatus, record) => (
                GetActionUI(record)
            )
        },

    ];

    function onChangePage(page: number, pageSize: number) {
        fetchData(page);
    }

    return (
        <>
            <Card size="small">
                <Row wrap={false}>
                    <Col flex="none">
                        {/* <Button type="link" icon={<VerticalAlignBottomOutlined />} size="middle">
                            Xuất file
                        </Button> */}
                    </Col>
                    <Col flex="auto" style={{ padding: '0 16px' }}>
                        <Input
                            prefix={<SearchOutlined />}
                            allowClear={true}
                            ref={searchRef}
                            placeholder="Tìm kiếm đơn hàng (F3)"
                            onChange={searchTextChange}
                            onPressEnter={SearchOrderSubmit} />
                    </Col>

                    <Col flex="none" hidden={!canCreateOrder()}>
                        <Button type="primary" icon={<PlusOutlined />} href="/admin/orders/create" size="middle">
                            Tạo đơn hàng
                        </Button>
                    </Col>
                </Row>
            </Card>

            <div>
                <Spin spinning={loading} tip="đang tải...">
                    <Table bordered
                        columns={columns}
                        dataSource={orders}
                        pagination={{ pageSize: 25, showSizeChanger: false, total: totalOrder, onChange: onChangePage }}
                        scroll={{ x: true, y: getHeightFit(250) }}
                    />


                </Spin>
            </div>
        </>

    )
}

export default OrdersView;

