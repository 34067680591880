import { Modal, ModalProps, Form, Input, message } from "antd";
import { useEffect, useState } from "react";
import { RenewProductWarrantyApi } from "../../Api/WarrantyApi";
import { ActiveWarrantyResponse } from "../../Model/WarrantiesModel";

const { TextArea } = Input;

type RenewProductProps = ModalProps & {
    AcceptFunc: Function,
    ActiveWarranty?: ActiveWarrantyResponse
}
export default function RenewProductModal(props: RenewProductProps) {
    const { AcceptFunc, ActiveWarranty, ...rest } = props;

    const [form] = Form.useForm();

    useEffect(() => {
        if (rest.visible) {

        }
    }, [rest.visible])

    //Đồng ý đổi sản phẩm mới
    const AcceptRenew = () => {
        const imei = form.getFieldValue("imei");
        const reason = form.getFieldValue("reason");
        if (imei == null || imei === "") {
            message.info("Số Imei không được bỏ trống");
            return;
        }
        if (ActiveWarranty) {
            const dataReq = {
                imei: imei,
                reason: reason,
                moveWarrantyProductToBlackList: true
            }
            RenewProductWarrantyApi(ActiveWarranty.id, dataReq, (res: any) => {
                AcceptFunc();
            })

        }

    }
    return (
        <Modal
            onOk={AcceptRenew}
            okText="Đồng ý đổi"
            cancelText="Hủy bỏ" {...rest}>
            <Form form={form} layout="vertical">
                <Form.Item name="imei" label="Số IMEI">
                    <Input />
                </Form.Item>
                <Form.Item name="reason" label="Lý do">
                    <TextArea rows={2} />
                </Form.Item>

            </Form>
        </Modal>
    )
}