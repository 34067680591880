import { Modal, Form, ModalProps, Input } from "antd";
import { useEffect } from "react";
import { BillApi } from "../../Api/BillsApi";
import { BillRequest } from "../../Model/BillModel";
import InputMoney from "../Controls/MoneyInput";


type CreateIncomeProps = ModalProps & {
    afterCreateFnc: Function
}
export default function CreateIncomeModal(props: CreateIncomeProps) {

    const { afterCreateFnc, ...rest } = props;

    const [dataForm] = Form.useForm<any>();

    useEffect(() => {
        if (rest.visible) {
            dataForm.setFieldsValue({ money: 0, reason: "" })
        }
    }, [rest.visible])

    function clickCreateBill() {

        const money = dataForm.getFieldValue("money");
        const reason = dataForm.getFieldValue("reason");
        async function fetch() {

            const data: BillRequest = {
                amount: money,
                billingType: 0,
                paymentType: 0,
                reason: reason
            };
            const res = await BillApi.CreateBillApi(data);
            if (res?.status === 200) {
                if (afterCreateFnc)
                    afterCreateFnc();
            }
        }

        fetch();
    }
    return (
        <Modal {...rest}
            onOk={clickCreateBill}
            okText="Tạo">
            <Form layout="vertical" form={dataForm}>
                <Form.Item label="Số tiền" name="money">
                    <InputMoney />
                </Form.Item>

                <Form.Item label="Lý do" name="reason">
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    )
}