import { AxiosRequest } from "./Axios/AxiosRequest";
import { HandleRequest } from "./RequestAPI";
import { SERVER_API } from "./UrlDefination";

export const GetUnitsApi = async (doAfterResponse: Function) => {

    await HandleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.UNITS.GET}`), doAfterResponse);

}

export const CreateUnitApi = async (data: IUnitRequest, doAfterResponse: Function) => {

    await HandleRequest(AxiosRequest.post(`${SERVER_API.HOST}/${SERVER_API.UNITS.CREATE}`, data), doAfterResponse);
}