import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { catchError, concat, filter, from, switchMap, withLatestFrom } from "rxjs";
import { ProductApi } from "../../Api/ProductApi";
import { UpdateProductVersionsCommand } from "../../Model/ProductModel";
import { RootEpic } from "../configure/rootReducer";
import { fetchListStorage, setUpdateProductSelected } from "./storage.slice";

interface IProductActionState {
    listProduct: ProductResponse[];
    fetchProductLoading: boolean;
    updateProductLoading: boolean;
}

const initState: IProductActionState = {
    listProduct: [],
    fetchProductLoading: false,
    updateProductLoading: false,
}

const productActionSlice = createSlice({
    name: 'productAction',
    initialState: initState,
    reducers: {
        fetchProducts(state, action: PayloadAction<undefined>) { return },
        setListProduct(state, action: PayloadAction<ProductResponse[]>) {
            state.listProduct = action.payload;
        },
        setFetchProductLoading(state, action: PayloadAction<boolean>) {
            state.fetchProductLoading = action.payload;
        },
        updateProductVersions(state, action: PayloadAction<UpdateProductVersionsCommand>) { return },
        setUpdateProductLoading(state, action: PayloadAction<boolean>) {
            state.updateProductLoading = action.payload;
        },
    }
});

const fetchProducts$: RootEpic = (action$, state$) => action$.pipe(
    filter(fetchProducts.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {

        return concat(
            [productActionSlice.actions.setUpdateProductLoading(true)],
            from(ProductApi.getProducts()).pipe(
                switchMap(response => {
                    // if (response?.status !== 200) return [];
                    return [
                        setListProduct(response.data.items),
                        setUpdateProductSelected(null),
                        productActionSlice.actions.setUpdateProductLoading(false)
                    ]
                }),
                catchError(err => [
                    productActionSlice.actions.setUpdateProductLoading(false),
                    setUpdateProductSelected(null)
                ])
            )
        )
    })
)

const updateProductVersions$: RootEpic = (action$, state$) => action$.pipe(
    filter(updateProductVersions.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const requestData = action.payload;

        return concat(
            from(ProductApi.updateProductVersions(requestData)).pipe(
                switchMap(response => {
                    if (response?.status !== 200) return [];
                    return [
                        setListProduct(response.data),
                        setUpdateProductSelected(null),
                        fetchListStorage(),
                    ]
                }),
                catchError(err => [
                    productActionSlice.actions.setFetchProductLoading(false),
                    setUpdateProductSelected(null)
                ])
            ),
        )
    })
)

export const ProductActionEpics = [
    fetchProducts$,
    updateProductVersions$
]

export const {
    fetchProducts,
    setListProduct,
    updateProductVersions,
    setUpdateProductLoading,
} = productActionSlice.actions;

export default productActionSlice.reducer;