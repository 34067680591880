import { Button, DatePicker, Space, Table, Spin } from "antd";
import {
    ArrowLeftOutlined
} from '@ant-design/icons';
import { useEffect, useState } from "react";
import { ColumnsType } from "antd/lib/table";
import { headerStyle } from "../../Helper/UIHelper";
import { InventoryStatus } from "../../../common/type-define";
import { InventoryReportApi } from "../../../Api/Reports/InventoryReportApi";


export default function InventoryReportView() {
    const [showConcept, setShowConcept] = useState<boolean>(false);
    const [data, setData] = useState<IInventoryReport[]>([]);
    const [loading, setLoading] = useState<boolean>(false)
    useEffect(() => {
        fetchData();
    }, [])
    async function fetchData() {
        setLoading(true);
        let re: IInventoryReport[] = [];
        const query = {
            InventoryStatuses: [InventoryStatus.Approved, InventoryStatus.Balanced]
        }
        console.log(query);
        const inventoryResponse = await InventoryReportApi.GetInventoryReportsApi(query)
        if (inventoryResponse.status !== 200) {
            setLoading(false);
            return;
        }

        const inventoryReports = inventoryResponse.data;
        inventoryReports.forEach(i => {
            i.inventoryStores.forEach(p => {
                re.push({
                    inventoryCode: i.inventoryCode,
                    productCode: p.productVersion.productCode,
                    productVersionName: p.productVersion.fullName,
                    beforeAdjustQuantity: p.dataQuantities,
                    afterAdjustQuantity: p.realQuantities,
                    adjustQuantity: Math.abs(p.dataQuantities - p.realQuantities)
                })
            })
        })
        setData(re);
        setLoading(false);
    }

    const columns: ColumnsType<IInventoryReport> = [
        {
            title: 'Mã phiếu kiểm',
            dataIndex: 'inventoryCode',
            key: 'inventoryCode',
            onHeaderCell: () => headerStyle()
        },
        {
            title: 'Tên sản phẩm',
            dataIndex: 'productVersionName',
            key: 'productVersionName',
            onHeaderCell: () => headerStyle()
        },
        {
            title: 'SL trước điều chỉnh',
            dataIndex: 'beforeAdjustQuantity',
            key: 'beforeAdjustQuantity',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'SL sau điều chỉnh',
            dataIndex: 'afterAdjustQuantity',
            key: 'afterAdjustQuantity',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'SL điều chỉnh',
            dataIndex: 'adjustQuantity',
            key: 'adjustQuantity',
            onHeaderCell: () => headerStyle()
        }
    ]

    return (
        <div>

            <Space direction='horizontal'>
                <Button type="default" icon={<ArrowLeftOutlined />} href="/admin/report/store"></Button>
                <h1>Báo cáo kiểm kê hàng hóa</h1>
            </Space>

            <Spin spinning={loading} tip="đang tải...">
                <Table columns={columns} dataSource={data} size="small" bordered
                    pagination={false}
                />
            </Spin>
        </div>
    )
}