import { Button, Spin, Table } from "antd";
import AdminHeader from "../adminHeader";
import {
    VerticalAlignBottomOutlined,
    PlusOutlined
} from '@ant-design/icons';
import { useEffect, useState } from "react";
import { GetProvidersApi } from "../../Api/ProviderApi";
import ProviderModel from "../../Model/ProviderModel";
import { ProviderModal } from "./ProviderModal";
import { ColumnsType } from "antd/lib/table";


export function ProvidersView() {

    const [providers, setProviders] = useState<ProviderModel[]>([]);
    const [loading, setloading] = useState(true);

    const [ShowProviderModel, setShowProvider] = useState<boolean>(false);
    const [editData, setEditData] = useState<ProviderModel | null>(null);

    function fetchProviders() {
        GetProvidersApi((data: IListItems<ProviderModel>) => {
            setProviders(data.items);
        }).finally(() => {
            setloading(false);
        })
    }
    useEffect(() => {
        fetchProviders();
    }, []);


    //Tạo xong nhà cung cấp
    const providerModelSumbit = (res: ProviderModel) => {
        fetchProviders();
        setEditData(null);
        setShowProvider(false);
    }

    const editProvider = (center: ProviderModel) => {
        return () => {
            setEditData(center);
            setShowProvider(true);
        }
    }

    const ProvidersCols: ColumnsType<ProviderModel> = [
        {
            title: 'Tên nhà cung cấp',
            dataIndex: 'name',
            key: 'name',
            render: (name, record) => (
                <a >
                    <span onClick={editProvider(record)}>{name}</span>
                </a>
            )
        },
        {
            title: 'Người đại diện',
            dataIndex: 'representativePerson',
            key: 'representativePerson',
        },
        {
            title: 'Số điện thoại',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
        },
        {
            title: 'Mã số thuế',
            dataIndex: 'taxNumber',
            key: 'taxNumber',
        },
    ];
    return (
        <>
            <div style={{ display: "flex", height: 50, top: 25 }}>
                <div style={{ flexGrow: 1, textAlign: "end" }}>
                    <Button type="primary" icon={<PlusOutlined />} onClick={() => { setEditData(null); setShowProvider(true) }} size="middle">
                        Thêm nhà cung cấp
                    </Button>
                </div>
            </div>
            <div>
                <Spin spinning={loading} tip="đang tải...">
                    <Table
                        columns={ProvidersCols}
                        dataSource={providers}
                        pagination={{ pageSize: 10 }}

                    />
                </Spin>

            </div>
            <ProviderModal
                editData={editData}
                onCancel={() => setShowProvider(false)}
                okModalFunc={providerModelSumbit}
                visible={ShowProviderModel} />
        </>
    )
}