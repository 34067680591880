import {
    LeftOutlined,
    CheckCircleOutlined,
    QuestionCircleOutlined
} from '@ant-design/icons';

import { Button, Card, Col, Form, InputNumber, message, Row, Spin, Steps, Tag, Typography } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { InventoryApi } from "../../Api/InventoryApi";
import { InventoryStatusEnum, InventoryStatusEnumCommand } from '../../common/type-define';
import { CommonHelper } from '../../Helper/CommonHelper';
import { MathHelper } from '../../Helper/math.helper';
import { InventoryCheck, InventoryResponse, InventoryStoreResponse } from "../../Model/InventoryModel";
import { getHeightFit } from '../Helper/UIHelper';

const { Text, Title } = Typography;
const { Step } = Steps;

const STEP_TITLE = ["Kiểm tra", "Quyết định", "Cân bằng"];

const InventoryDetailComponent = (): JSX.Element => {
    const { id } = useParams();
    const [dataInventory, setDataInventory] = useState<InventoryResponse | undefined>(undefined);
    const [stepIndex, setStepIndex] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    useEffect(() => {
        async function fetch(id: string) {
            setLoading(true);
            const res = await InventoryApi.getById(id);
            if (res?.status === 200) {
                setDataInventory(res.data);
            }
            else {

            }
            setLoading(false);
        }

        if (!id) return;
        fetch(id)
    }, [id, refresh])

    useEffect(() => {
        if (!dataInventory) return;
        const index = dataInventory.status > 2 ? dataInventory.status - 1 : dataInventory.status;
        setStepIndex(index);
    }, [dataInventory])

    const columns: ColumnsType<InventoryResponse> = [
        {
            title: 'Mã phiếu',
            dataIndex: 'inventoryCode',
            key: 'inventoryCode',
            width: '35%',
            render: (inventoryCode: string, record: InventoryResponse) => (
                <Text>
                    {inventoryCode}
                </Text>
            )
        },
        {
            title: 'Tên kho',
            dataIndex: 'warehouseName',
            key: 'warehouseName',
            render: (_: any, record: InventoryResponse) => (
                <Text>
                    {record.warehouse.name}
                </Text>
            )
        },
        {
            title: 'Mã kho',
            dataIndex: 'warehouseCode',
            key: 'warehouseCode',
            render: (_: any, record: InventoryResponse) => (
                <Text>
                    {record.warehouse.code}
                </Text>
            )
        },
    ]

    const columnProducts: ColumnsType<InventoryStoreResponse> = [
        {
            title: 'Tên sản phẩm',
            dataIndex: 'productName',
            key: 'productName',
            showSorterTooltip: false,
            width: '35%',
            sorter: (a, b) => a.productVersion.fullName < b.productVersion.fullName ? 1 : -1,
            render: (_: any, record: InventoryStoreResponse) => (
                <Text>
                    {record.productVersion.fullName}
                </Text>
            )
        },
        {
            title: 'Tên phiên bản',
            dataIndex: 'versionName',
            key: 'versionName',
            showSorterTooltip: false,
            sorter: (a, b) => a.productVersion.name < b.productVersion.name ? 1 : -1,
            render: (_: any, record: InventoryStoreResponse) => (
                <Text>
                    {record.productVersion.name}
                </Text>
            )
        },
        {
            title: 'Định mức',
            dataIndex: 'productWarningThreshold',
            key: 'productWarningThreshold',
            showSorterTooltip: false,
            sorter: (a, b) => a.productVersion.productWarningThreshold < b.productVersion.productWarningThreshold ? 1 : -1,
            render: (_: any, record: InventoryStoreResponse) => (
                <Text>
                    {record.productVersion.productWarningThreshold}
                </Text>
            )
        },
        {
            title: 'SL thực tế/ SL kho',
            dataIndex: 'dataQuantities',
            key: 'dataQuantities',
            width: '15%',
            showSorterTooltip: false,
            sorter: (a, b) => a.dataQuantities < b.dataQuantities ? 1 : -1,
            render: (dataQuantities: number, record: InventoryStoreResponse) => (
                <Form.Item name={record.id} rules={[{ required: true, message: "Nhập số liệu thực tế" }]}>
                    <div style={{ display: 'flex' }}>
                        <InputNumber
                            disabled={stepIndex > 0}
                            size="small"
                            min={0}
                            style={{ width: 80, marginRight: 5 }}
                            className="input-number"
                            defaultValue={dataQuantities}
                            onChange={() => onChangeQuantity(record)} addonAfter={form.getFieldValue(record.id) ? <CheckCircleOutlined style={{ color: 'green' }} /> : <QuestionCircleOutlined style={{ color: 'red' }} />} />
                        <Text style={{ width: 'auto' }}>{`/${dataQuantities}`}</Text>
                    </div>
                </Form.Item>
            )
        },
        {
            title: 'Giá nhập',
            dataIndex: 'priceInput',
            key: 'priceInput',
            align: 'right',
            showSorterTooltip: false,
            sorter: (a, b) => a.productVersion.priceInput < b.productVersion.priceInput ? 1 : -1,
            render: (_: any, record: InventoryStoreResponse) => (
                <Text>
                    {MathHelper.formatPriceVND(record.productVersion.priceInput, true)}
                </Text>
            )
        },
        {
            title: 'Giá bán',
            dataIndex: 'salePrice',
            key: 'salePrice',
            align: 'right',
            showSorterTooltip: false,
            sorter: (a, b) => a.productVersion.salePrice < b.productVersion.salePrice ? 1 : -1,
            render: (_: any, record: InventoryStoreResponse) => (
                <Text>
                    {MathHelper.formatPriceVND(record.productVersion.salePrice, true)}
                </Text>
            )
        },
    ];

    const onChangeQuantity = (record: InventoryStoreResponse) => {

    }

    const refreshData = () => {
        setRefresh(pre => !pre);
    }

    const handleOnProcess = async (decision?: InventoryStatusEnumCommand) => {
        if (!dataInventory || !id) return;
        setLoading(true);
        try {
            switch (dataInventory.status) {
                case InventoryStatusEnum.Created:
                    const fields = form.getFieldsValue();
                    console.log(fields);
                    const checkRequired = Object.values(fields).every(v => v !== undefined);
                    if (!checkRequired) {
                        message.warning("Bạn hãy nhập số lượng thực tế cho tất cả sản phẩm.");
                        break;
                    }
                    const checkItem: InventoryCheck = {
                        details: dataInventory.inventoryStores.map(v => ({
                            productVersionId: v.productVersion.id,
                            realQuantities: Number(fields[v.id]),
                        }))
                    }
                    const resCreate = await InventoryApi.checkInventory(id, checkItem);
                    if (resCreate?.status === 200) {
                        message.success("Kiểm tra phiếu thành công.");
                    } else {
                        message.error("Kiểm tra phiếu không thành công.");
                    }
                    break;
                case InventoryStatusEnum.Checked:
                    if (decision === undefined) break;
                    const resCheck = await InventoryApi.decisionInventory(id, decision);
                    if (resCheck?.status === 200) {
                        message.success(`${decision === InventoryStatusEnumCommand.Approve ? "Chấp nhận" : "Từ chối"} thành công`);
                    } else {
                        message.error(`${decision === InventoryStatusEnumCommand.Approve ? "Chấp nhận" : "Từ chối"} không thành công`);
                    }
                    break;
                case InventoryStatusEnum.Approved:
                case InventoryStatusEnum.Declined:
                    const resBalance = await InventoryApi.balanceInventory(id);
                    if (resBalance?.status === 200) {
                        message.success(`Cân bằng thành công`);
                    } else {
                        message.error(`Cân bằng không thành công`);
                    }
                    break;
                case InventoryStatusEnum.Balanced:
                    break;
            }
        } catch (e) { };
        setLoading(false);
        refreshData();
    }
    const [form] = Form.useForm();

    return (
        <>
            <div style={{ margin: '10px auto' }}>
                <Link to="/admin/inventory">
                    <LeftOutlined /> Quay lại Danh sách phiếu kiểm
                </Link>
            </div>
            <div style={{ width: '95%', margin: '16px auto' }}>
                {
                    dataInventory ?
                        <Spin spinning={loading}>
                            <div style={{ display: 'flex', marginBottom: '32px', justifyContent: 'space-between' }}>
                                <Steps
                                    style={{ width: '85%' }}
                                    labelPlacement="vertical"
                                    size="small"
                                    current={stepIndex}>
                                    {
                                        STEP_TITLE.map((v, i) =>
                                            <Step
                                                key={v}
                                                title={v} />
                                        )
                                    }
                                </Steps>
                                {
                                    stepIndex !== 1 ?
                                        <Button
                                            disabled={dataInventory.status >= 4}
                                            onClick={() => handleOnProcess()}
                                            type="primary">
                                            Duyệt
                                        </Button>
                                        :
                                        <div>
                                            <Button
                                                onClick={() => handleOnProcess(InventoryStatusEnumCommand.Decline)}
                                                danger
                                                style={{ margin: 'auto 8px' }}>
                                                Từ chối
                                            </Button>
                                            <Button
                                                onClick={() => handleOnProcess(InventoryStatusEnumCommand.Approve)}
                                                type="primary">
                                                Chấp nhận
                                            </Button>
                                        </div>
                                }
                            </div>
                            <Row gutter={[16, 16]}>
                                <Col span={16}>
                                    <Form form={form}>
                                        <Table size="small"
                                            title={() => <Title level={5}>{dataInventory.inventoryCode}-{dataInventory.warehouse.name}</Title>}
                                            dataSource={dataInventory.inventoryStores}
                                            columns={columnProducts}
                                            pagination={dataInventory.inventoryStores.length >= 40 ? { pageSize: 40 } : false}
                                            bordered
                                            scroll={{ x: '900px', y: getHeightFit(400) }}
                                            rowKey={'id'} />
                                    </Form>
                                </Col>

                                <Col span={8}>
                                    {/* <Row gutter={[16, 8]}>
                                        <Col span={24}>
                                            <Table size="small"
                                                title={() => <Title level={5} >Thông tin phiếu</Title>}
                                                dataSource={[dataInventory]}
                                                columns={columns}
                                                pagination={false}
                                                scroll={{ x: '400px' }}
                                                bordered
                                                rowKey={'id'} />
                                        </Col>
                                    </Row> */}
                                    <Row gutter={[16, 8]}>
                                        <Col span={24} style={{ marginTop: 16 }}>
                                            <Card title={"Lịch sử phiếu"}>
                                                <Steps direction="vertical" size="small" current={dataInventory.inventoryHistories.length}>
                                                    {
                                                        dataInventory.inventoryHistories
                                                            .sort((a, b) => new Date(a.createdTime) > new Date(b.createdTime) ? 1 : -1)
                                                            .map(v => {
                                                                const tagInfo = CommonHelper.getInventoryTag(v.statusCode);
                                                                return (
                                                                    <Step
                                                                        title={<>{v.actorName} <Tag color={tagInfo.color}>{tagInfo.title}</Tag></>}
                                                                        description={new Date(v.createdTime).toLocaleString()} />
                                                                )
                                                            })
                                                    }
                                                </Steps>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Spin>
                        :
                        <span>Phiếu kiểm không tồn tại</span>
                }
            </div>
        </>
    )
}

const InventoryDetail = React.memo(InventoryDetailComponent);
export default InventoryDetail;