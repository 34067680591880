import {
    PicCenterOutlined,
    PicRightOutlined
} from '@ant-design/icons';
import { Col, Row } from "antd";
import { CardItem } from "../Controls/LinkItem";


export default function WarrantyReportView() {

    return (
        <>
            <Row>
                <Col span={12}>
                    <CardItem title="Báo cáo tình trạng bảo hành "
                        icon={<PicCenterOutlined />}
                        desc="Theo dõi doanh thu, chi phí và lợi nhuận của cửa hàng"
                        url="warranties" />
                </Col>
                <Col span={12}>
                    <CardItem title="Báo cáo tình trạng bảo trì "
                        icon={<PicRightOutlined />}
                        desc="Theo dõi các khoản thu chi của cửa hàng"
                        url="maintances" />
                </Col>
            </Row>

            <Row>
                <Col span={12}>
                    <CardItem title="Kế hoạch bảo trì sản phẩm"
                        icon={<PicCenterOutlined />}
                        desc="Theo dõi các khoản phải thu của khách hàng"
                        url="maintances/schedule" />
                </Col>

            </Row>


        </>
    )
}