import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface IPageActionState {
    collapsedMenu: boolean,
    headerTitle: string,
}

const initState: IPageActionState = {
    collapsedMenu: false,
    headerTitle: '',
}

const pageActionSlice = createSlice({
    name: 'pageAction',
    initialState: initState,
    reducers: {
        toggleCollapsedMenu(state, action: PayloadAction<undefined>) {
            state.collapsedMenu = !state.collapsedMenu;
        },
        setHeaderTitle(state, action: PayloadAction<string>) {
            state.headerTitle = action.payload;
        },
    }
});

export const {
    toggleCollapsedMenu,
    setHeaderTitle
} = pageActionSlice.actions;

export default pageActionSlice.reducer;