import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { catchError, concat, filter, from, switchMap, withLatestFrom } from "rxjs";
import { VoucherApi } from "../../Api/VoucherApi";
import { IVoucherItem, VoucherModalAction, VoucherResponse, VoucherUpdateData } from "../../common/type-define";
import { CreateVoucherProductsCommand, UpdateVoucherProducts, VoucherProductResponse } from "../../Model/VoucherModel";
import { RootEpic } from "../configure/rootReducer";

interface IVoucherActionState {
    listVoucher: VoucherResponse[];
    voucherCreateModalVis: VoucherModalAction | null,
    productsByVoucher: VoucherProductResponse[],
    listVoucherByProducts: VoucherResponse[],
    inputVoucherProductLoading: boolean,
    inputVoucherVis: boolean,
}

const initState: IVoucherActionState = {
    listVoucher: [],
    voucherCreateModalVis: null,
    productsByVoucher: [],
    listVoucherByProducts: [],
    inputVoucherProductLoading: false,
    inputVoucherVis: false,
}

const voucherActionSlice = createSlice({
    name: 'voucherAction',
    initialState: initState,
    reducers: {
        fetchVoucher(state, action: PayloadAction<undefined>) { return },
        setListVoucher(state, action: PayloadAction<VoucherResponse[]>) {
            state.listVoucher = action.payload;
        },
        createVoucher(state, action: PayloadAction<{ voucherData: IVoucherItem, assignProducts: string[] }>) { return },
        setVoucherCreateModalVis(state, action: PayloadAction<VoucherModalAction | null>) {
            state.voucherCreateModalVis = action.payload;
        },
        updateVoucher(state, action: PayloadAction<VoucherUpdateData>) { return },
        assignVoucher(state, action: PayloadAction<CreateVoucherProductsCommand>) { return },
        getProductsAppliedToVoucher(state, action: PayloadAction<string>) { return },
        setProductsByVoucher(state, action: PayloadAction<VoucherProductResponse[]>) {
            state.productsByVoucher = action.payload;
        },
        updateVoucherProducts(state, action: PayloadAction<{ voucherId: string, updateProducts: UpdateVoucherProducts }>) { return },
        setListVoucherByProducts(state, action: PayloadAction<VoucherResponse[]>) {
            state.listVoucherByProducts = action.payload;
        },
        fetchVoucherProducts(state, action: PayloadAction<string[]>) { return },
        setInputVoucherProductLoading(state, action: PayloadAction<boolean>) {
            state.inputVoucherProductLoading = action.payload;
        },
        setInputVoucherVis(state, action: PayloadAction<boolean>) {
            state.inputVoucherVis = action.payload;
        },
    }
});

const fetchVoucher$: RootEpic = (action$, state$) => action$.pipe(
    filter(fetchVoucher.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {

        return concat(
            from(VoucherApi.getVouchers()).pipe(
                switchMap(response => {
                    if (response?.status === 200) {
                        return [
                            voucherActionSlice.actions.setListVoucher(response.data.items)
                        ]
                    }
                    return []
                }),
                catchError(err => [])
            )
        )
    })
)

const createVoucher$: RootEpic = (action$, state$) => action$.pipe(
    filter(createVoucher.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const { voucherData, assignProducts } = action.payload;
        return concat(
            from(VoucherApi.createVoucher(voucherData)).pipe(
                switchMap(response => {
                    if (response?.status === 200) {
                        const newVoucher = response.data;
                        const { id } = newVoucher;
                        const newAssign: CreateVoucherProductsCommand = {
                            voucherId: id,
                            productIds: assignProducts
                        }
                        return [
                            voucherActionSlice.actions.assignVoucher(newAssign),
                            voucherActionSlice.actions.setVoucherCreateModalVis(null),
                            voucherActionSlice.actions.fetchVoucher(),
                        ]
                    }
                    return []
                }),
                catchError(err => [])
            )
        )
    })
)

const updateVoucher$: RootEpic = (action$, state$) => action$.pipe(
    filter(updateVoucher.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        return concat(
            from(VoucherApi.updateVoucher(action.payload)).pipe(
                switchMap(response => {
                    if (response?.status === 200) {
                        return [
                            voucherActionSlice.actions.setVoucherCreateModalVis(null),
                            voucherActionSlice.actions.fetchVoucher(),
                        ]
                    }
                    return []
                }),
                catchError(err => [])
            )
        )
    })
)

const assignVoucher$: RootEpic = (action$, state$) => action$.pipe(
    filter(assignVoucher.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const dataRequest = action.payload;

        return concat(
            from(VoucherApi.assignVoucher(dataRequest)).pipe(
                switchMap(response => {
                    if (response?.status === 200) {
                        return []
                    }
                    return []
                }),
                catchError(err => [])
            )
        )
    })
)


const getProductsAppliedToVoucher$: RootEpic = (action$, state$) => action$.pipe(
    filter(getProductsAppliedToVoucher.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const voucherId = action.payload;

        return concat(
            from(VoucherApi.getProductsAppliedToVoucher(voucherId)).pipe(
                switchMap(response => {
                    if (response?.status === 200) {
                        const voucherProducts = response.data as VoucherProductResponse[];
                        return [
                            setProductsByVoucher(voucherProducts)
                        ]
                    }
                    return []
                }),
                catchError(err => [])
            )
        )
    })
)

const updateVoucherProducts$: RootEpic = (action$, state$) => action$.pipe(
    filter(updateVoucherProducts.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const { voucherId, updateProducts } = action.payload;

        return concat(
            from(VoucherApi.updateVoucherProduct(voucherId, updateProducts)).pipe(
                switchMap(response => {
                    if (response?.status === 200) {
                        return []
                    }
                    return []
                }),
                catchError(err => [])
            )
        )
    })
)

const fetchVoucherProducts$: RootEpic = (action$, state$) => action$.pipe(
    filter(fetchVoucherProducts.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const productIds = action.payload;

        return concat(
            [setInputVoucherVis(true)],
            [voucherActionSlice.actions.setInputVoucherProductLoading(true)],
            from(VoucherApi.getVoucherByProducts(productIds)).pipe(
                switchMap(response => {
                    if (response?.status === 200) {
                        return [
                            voucherActionSlice.actions.setListVoucherByProducts(response.data),
                            voucherActionSlice.actions.setInputVoucherProductLoading(false)
                        ]
                    }
                    return [voucherActionSlice.actions.setInputVoucherProductLoading(false)]
                }),
                catchError(err => [voucherActionSlice.actions.setInputVoucherProductLoading(false)])
            ),
        )
    })
)

export const VoucherActionEpics = [
    fetchVoucher$,
    createVoucher$,
    updateVoucher$,
    assignVoucher$,
    getProductsAppliedToVoucher$,
    updateVoucherProducts$,
    fetchVoucherProducts$,
]

export const {
    fetchVoucher,
    createVoucher,
    setVoucherCreateModalVis,
    updateVoucher,
    assignVoucher,
    getProductsAppliedToVoucher,
    setProductsByVoucher,
    updateVoucherProducts,
    fetchVoucherProducts,
    setInputVoucherVis,
} = voucherActionSlice.actions;

export default voucherActionSlice.reducer;