import {
    PlusOutlined,
    SearchOutlined, VerticalAlignBottomOutlined
} from '@ant-design/icons';
import { Button, Card, Col, Input, Row, Spin, Table, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import { format } from 'date-fns';
import { useEffect, useRef, useState } from "react";
import { GetPurchaseOrderssApi } from "../../Api/PurchaseApi";
import { KeyCode, PurchaseStatus } from "../../common/type-define";
import { compareString } from "../../Helper/CommonHelper";
import { MathHelper } from "../../Helper/math.helper";
import { useShortKey } from "../../hooks/useShortKey";
import { PurchaseOrderModelResponse, } from "../../Model/PurchaseModel";
import { cellStyle, getHeightFit, headerStyle } from "../Helper/UIHelper";



function GetPaymentStatus(record: PurchaseOrderModelResponse): JSX.Element {
    if (record.isPaidCompleted === true) {
        return <Tag color="green">Đã TT</Tag>;
    }

    return <Tag color="blue">Chưa TT</Tag>;
}

function PurchaseOrderStatus(purchaseStatus: number): JSX.Element {
    if (purchaseStatus == PurchaseStatus.Stored) {
        return <Tag color="green">Hoàn thành</Tag>;
    } else if (purchaseStatus == PurchaseStatus.Cancelled) {
        return <Tag color="red">Hủy</Tag>;
    } else if (purchaseStatus == PurchaseStatus.Stored) {
        return <Tag color="pink">Đã nhập kho</Tag>;
    }

    return <Tag color="blue">Đang giao dịch</Tag>;
}

function CalculateMoney(record: PurchaseOrderModelResponse): number {
    if (record != null) {
        if (record.totalAmount > 0)
            return record.totalAmount;
    }
    return 0;
}


function PurchaseOrdersView() {

    const [purchaseOrders, setPurchaseOrders] = useState<PurchaseOrderModelResponse[]>([]);
    const [loading, setloading] = useState(true);
    const searchRef = useRef<Input>(null);
    useShortKey(KeyCode.F3, () => searchRef.current?.focus());

    //Init giao diện
    useEffect(() => {

        GetPurchaseOrderssApi((data: IListItems<PurchaseOrderModelResponse>) => {
            setPurchaseOrders(data.items);
            setloading(false);
        })
    }, []);


    const SearchPurchaseSubmit = () => {
        // VA not sure
        const search = searchRef.current?.input.value.toLowerCase();
        setloading(true);
        let dataVm: PurchaseOrderModelResponse[] = [];
        GetPurchaseOrderssApi((data: IListItems<PurchaseOrderModelResponse>) => {
            data.items.map(purchase => {
                if (search == null || search === "") {
                    dataVm = data.items;
                } else {
                    if (compareString(purchase.purchaseCode, search) ||
                        compareString(purchase.provider.name, search)) {
                        dataVm.push(purchase);
                    }
                }
            });
            setPurchaseOrders(dataVm);
            setloading(false);
        });

    }


    //Xử lý cho tình huống xóa hết text
    const searchTextChange = () => {
        try {
            const search = searchRef.current?.input.value.toLowerCase();
            // VA not sure
            if (search == null || search === "") {
                setloading(true);

                GetPurchaseOrderssApi((data: IListItems<PurchaseOrderModelResponse>) => {
                    setPurchaseOrders(data.items);
                }).finally(() => setloading(false));

            }
        } catch (error) {
            setloading(false);
        }

    }

    //Các cột trong phần nhập hàng
    const InputBillCols: ColumnsType<PurchaseOrderModelResponse> = [
        {
            title: 'Mã đơn',
            dataIndex: 'purchaseCode',
            key: 'purchaseCode',
            width: '10%',
            onHeaderCell: () => headerStyle(),
            render: (purchaseCode, record) => (
                <a href={`purchase_orders/${record.id}`}>
                    <span>{purchaseCode}</span>
                </a>
            )
        },
        {
            title: 'Tên nhà cung cấp',
            dataIndex: 'provider',
            key: 'provider',
            onHeaderCell: () => headerStyle(),
            render: (id, record) => (
                <span>{record.provider?.name}</span>
            )
        },
        {
            title: 'Chi nhánh',
            dataIndex: 'wareHouse',
            key: 'wareHouse',
            width: '10%',
            onHeaderCell: () => headerStyle(),
            render: (id, record) => (
                <span>{record.warehouse?.name}</span>
            )
        },
        {
            title: 'trạng thái',
            dataIndex: 'purchaseStatus',
            key: 'purchaseStatus',
            width: '10%',
            onHeaderCell: () => headerStyle(),
            render: (purchaseStatus) => (
                PurchaseOrderStatus(purchaseStatus)
            )
        },
        {
            title: 'Thanh toán',
            dataIndex: 'paymentState',
            key: 'paymentState',
            width: '10%',
            onHeaderCell: () => headerStyle(),
            render: (id, record) => (
                GetPaymentStatus(record)
            )
        },
        {
            title: 'Tổng tiền',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            width: '10%',
            onHeaderCell: () => headerStyle(),
            render: (id, record) => (
                <span>{MathHelper.formatPriceVND(CalculateMoney(record))}</span>
            )
        },
        {
            title: 'Ngày tạo đơn',
            dataIndex: 'createdTime',
            key: 'createdTime',
            width: '10%',
            onCell: () => cellStyle(),
            onHeaderCell: () => headerStyle(),
            render: (createdTime) => (
                format(new Date(createdTime), "dd-MM-yyyy")
            )
        },
        {
            title: 'Ngày hoàn thành',
            dataIndex: 'refundTime',
            key: 'refundTime',
            width: '10%',
            onCell: () => cellStyle(),
            onHeaderCell: () => headerStyle(),
            render: (refundTime) => (
                format(new Date(refundTime), "dd-MM-yyyy")
            )
        }
    ];


    //Sẽ vẽ toàn bộ giao diện
    return (
        <>
            <Card size="small">
                <Row wrap={false}>
                    <Col flex="none">
                        {/* <Button type="link" icon={<VerticalAlignBottomOutlined />} size="middle">
                            Xuất file
                        </Button> */}
                    </Col>
                    <Col flex="auto" style={{ padding: '0 16px' }}>
                        <Input
                            prefix={<SearchOutlined />}
                            allowClear={true}
                            placeholder="Tìm kiếm đơn nhập hàng (F3)"
                            ref={searchRef}
                            onChange={searchTextChange}
                            onPressEnter={SearchPurchaseSubmit} />
                    </Col>

                    <Col flex="none" >
                        <Button type="primary" icon={<PlusOutlined />} href="/admin/purchase_orders/create" size="middle">
                            Tạo đơn nhập hàng
                        </Button>
                    </Col>
                </Row>
            </Card>


            <div>
                <Spin spinning={loading} tip="đang tải...">
                    <Table bordered
                        scroll={{ x: true, y: getHeightFit(250) }}
                        columns={InputBillCols}
                        dataSource={purchaseOrders}
                        pagination={{ pageSize: 20 }}

                    />
                </Spin>

            </div>
        </>
    )

}

export default PurchaseOrdersView;


