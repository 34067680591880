import { AnyAction, combineReducers } from "@reduxjs/toolkit";
import { combineEpics, Epic } from "redux-observable";
import { Subject, concatMap, tap } from "rxjs";
import authSlice from "../slices/auth.slice";
import customerSlice from "../slices/customer.slice";
import ordersSlice, { OrderActionEpics } from "../slices/orders.slice";
import pagesSlice from "../slices/pages.slice";
import productsSlice, { ProductActionEpics } from "../slices/products.slice";
import reportSlice, { ReportEpics } from "../slices/report.slice";
import returnOrdersSlice, { ReturnOrderActionEpics } from "../slices/returnOrders.slice";
import rolesSlice, { RoleActionEpics } from "../slices/roles.slice";
import storageSlice, { StorageActionEpics } from "../slices/storage.slice";
import voucherSlice, { VoucherActionEpics } from "../slices/voucher.slice";
export type RootEpic = Epic<AnyAction, AnyAction, RootState>;
const allAction$ = new Subject<AnyAction>();
const handleAllAction$: RootEpic = (action$) => action$.pipe(
    tap(allAction$),
    concatMap(_ => [])
)

const allReducers = combineReducers({
    voucherAction: voucherSlice,
    ordersAction: ordersSlice,
    returnOrdersAction: returnOrdersSlice,
    productsAction: productsSlice,
    rolesAction: rolesSlice,
    authAction: authSlice,
    storageAction: storageSlice,
    customerAction: customerSlice,
    pagesAction: pagesSlice,
    report: reportSlice
});

const rootReducer = (state: any, action: any) => {
    if (action.type === 'authAction/resetState') {
        state = undefined;
    }

    return allReducers(state, action);
}
export const rootEpic = combineEpics(
    handleAllAction$,
    ...VoucherActionEpics,
    ...OrderActionEpics,
    ...ReturnOrderActionEpics,
    ...ProductActionEpics,
    ...RoleActionEpics,
    ...StorageActionEpics,
    ...ReportEpics
);

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;