import { AxiosRequest } from "./Axios/AxiosRequest";

import { CommissionCodeResponse, CreateCommissionCodeCommmand, CustomerReq, CustomerRes } from "../Model/CustomerModel";
import { HandleRequest, RequestHandler } from "./RequestAPI";
import { SERVER_API, SERVER_AUTHEN } from "./UrlDefination";
import { AxiosResponse } from "axios";
import { CustomerTypeEnum } from "../common/type-define";
import { identity } from "lodash";


export class CustomerApi {

    static async getCustomer(customerType?: CustomerTypeEnum[]): Promise<AxiosResponse<IListItems<CustomerRes>, any>> {
        let queryParams = '';
        if (customerType) {
            queryParams = customerType.map(v => `CustomerTypes=${v}`).join('&');
        }
        return RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.CUSTOMER.GET}?${queryParams}`));
    }

    static async getCustomerCommissionCodes(customerIds: string[]): Promise<AxiosResponse<CommissionCodeResponse[], any>> {
        let queryParams = '';
        if (customerIds) {
            queryParams = customerIds.map(v => `CustomerIds=${v}`).join('&');
        }
        return RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.CUSTOMER.GET_CUSTOMER_COMMISSION}?${queryParams}`));
    }

    static async setCustomerCommissionCode(data: CreateCommissionCodeCommmand): Promise<AxiosResponse<boolean, any>> {
        const api = `${SERVER_AUTHEN.HOST}/${SERVER_AUTHEN.CUSTOMER_IDENTITY.SET_CUSTOMER_COMMISSION_CODE}`;
        return RequestHandler.handleRequest(AxiosRequest.post(api, data));
    }
}

export const GetCustomersApi = async (doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.CUSTOMER.GET}`), doAfterResponse);
};

export const SearchCustomersApi = async (keyword: string, doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.CUSTOMER.SEARCH}?Keyword=${keyword}`), doAfterResponse);
};

export const CreateCustomerApi = async (data: CustomerReq, doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.post(`${SERVER_API.HOST}/${SERVER_API.CUSTOMER.CREATE}`, data), doAfterResponse);
}

export const UpdateCustomerApi = async (id: string, data: CustomerReq, doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.CUSTOMER.UPDATE(id)}`, data), doAfterResponse);
}

export const GetCustomerByIdApi = async (id: string, doAfterResponse: Function) => {
    await HandleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.CUSTOMER.GET_BY_ID(id)}`), doAfterResponse);
}