import { Modal, ModalProps, Form, message, Spin } from "antd";
import { useEffect, useState } from "react";
import { DefectProductApi } from "../../Api/DefectProductApi";
import { GetWareHousesApi } from "../../Api/WarehouseApi";
import { DefectProductResponse } from "../../Model/ProductModel";
import CCombobox from "../Controls/CCombobox";

type ReturnDefectProductToStore = ModalProps &
{
    returnDefectProductToStoreSucessHandle: Function;
    defectProduct: DefectProductResponse | undefined
}

export default function ReturnDefectProductToStoreModal(props: ReturnDefectProductToStore) {
    const { returnDefectProductToStoreSucessHandle, defectProduct, ...rest } = props;
    const [loading, setLoading] = useState(false);
    const [wareHouseList, setwareHouseList] = useState<WareHouseResponse[]>([]);
    const [wareHouseId, setWareHouseId] = useState<string>();
    const [dataForm] = Form.useForm<any>();

    useEffect(() => {

        GetWareHousesApi((res: IListItems<WareHouseResponse>) => {
            setwareHouseList(res.items);
        });

    }, [rest.visible]);

    function applyReturnDefectProductToStore() {
        if (!wareHouseId) {
            message.info('Chưa kho nào được chọn')
            return
        }
        setLoading(true);
        DefectProductApi.ImportProductToStoreApi(defectProduct?.id, { warehouseId: wareHouseId }, () => {
            message.success('Sản phẩm đã được nhập lại kho')
            returnDefectProductToStoreSucessHandle();
        })
        setLoading(false);
    }

    function chooseWarehouse(value: any) {
        setWareHouseId(value);
    }

    return (
        <Modal {...rest}
            onOk={applyReturnDefectProductToStore}>
            <Spin spinning={loading} tip="đang tải...">
                <Form layout="vertical">
                    <Form.Item label="Tên sản phẩm">
                        <span>{defectProduct?.productVersion.fullName}</span>
                    </Form.Item>
                    <Form.Item label="Kho">
                        <CCombobox
                            chooseOptions={wareHouseList}
                            displayName="name"
                            id="id"
                            onChange={chooseWarehouse} />
                    </Form.Item>
                </Form>
            </Spin>

        </Modal>
    )
}