import { Button, DatePicker, Space, Table } from "antd";
import { subMonths } from "date-fns";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { format } from 'date-fns';
import {
    ArrowLeftOutlined
} from '@ant-design/icons';
import { ColumnsType } from "antd/lib/table";
import { ChoosFilter, FilterControls } from "../../Controls/FilterControls";
import { headerStyle } from "../../Helper/UIHelper";
import { WarrantyReportApi } from "../../../Api/Reports/WarrantyReportApi";
const { RangePicker } = DatePicker;

export default function WarantyReport() {
    const [data, setData] = useState<IWarrantyReport[]>([]);
    const [startDate, setStartDate] = useState<Date>(subMonths(new Date(), 1));
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [filters, setFilters] = useState<FilterItem[]>([]);

    useEffect(() => {
        fetchData();
        
    }, []);
    async function fetchData() {
        const res = await WarrantyReportApi.GetWarrantyReportApi(startDate, endDate);
        if(res?.status === 200){
            setData(res.data);
        }
    }


    const columns: ColumnsType<IWarrantyReport> = [
        {
            title: 'Ngày Tháng',
            dataIndex: 'reportTime',
            key: 'reportTime',
            onHeaderCell: () => headerStyle(),
            render: (reportTime) => (
                format(new Date(reportTime), "dd-MM-yyyy")
            )
        },
        {
            title: 'Sản phẩm',
            dataIndex: 'productName',
            key: 'productName',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Đơn vị SP',
            dataIndex: 'productUnit',
            key: 'productUnit',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'SL Y/C đầu ngày',
            dataIndex: 'beginDayQuantity',
            key: 'beginDayQuantity',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'SL hư hỏng',
            dataIndex: 'damageQuantity',
            key: 'damageQuantity',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Số bổ sung',
            dataIndex: 'inDayQuantity',
            key: 'inDayQuantity',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'SL cuối ngày',
            dataIndex: 'endDayQuantity',
            key: 'endDayQuantity',
            onHeaderCell: () => headerStyle(),
        }
    ]

    function changeRangeTime(values: any, formatString: [string, string]) {
        const startTime: Moment = values[0];
        const endTime: Moment = values[1];

        setStartDate(startTime.toDate());
        setEndDate(endTime.toDate());
        fetchData();
    }

    function onChangeFilter() {

    }

    return (
        <div>
            <Space direction='horizontal'>
                <Button type="default" icon={<ArrowLeftOutlined />} href="/admin/report/warranty"></Button>
                <RangePicker format='DD/MM/YYYY' showTime={false}
                    clearIcon={false}
                    style={{ width: '220px' }}
                    onChange={changeRangeTime}
                    defaultValue={[moment(subMonths(new Date(), 1)), moment()]} />
                <ChoosFilter filterList={filters} onChangeFunc={(items: FilterItem[]) => setFilters(items)} />

            </Space>
            <FilterControls items={filters} afterChangeFnc={onChangeFilter} />

            <Table columns={columns} dataSource={data} size="small" bordered

                pagination={false}
            />
        </div>
    )
}