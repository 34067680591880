import {
    LeftOutlined, PlusOutlined
} from '@ant-design/icons';
import { Button, Divider, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { GetAllStaffsApi } from "../../Api/StaffApi";
import { StaffResponse } from "../../Model/StaffModel";
import { StaffModal } from "../StaffView/StaffModal";


export function StaffsView() {

    const [staffs, setStaffs] = useState<StaffResponse[]>([]);
    const [isShowModal, setIsShowModel] = useState<boolean>(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars

    const [staff, setStaff] = useState<StaffResponse | undefined>();
    const [modalTitle, setModalTitle] = useState<string>('');

    useEffect(() => {
        loadStaff();
    }, []);

    function loadStaff() {
        GetAllStaffsApi((data: IListItems<StaffResponse>) => {

            const staffsVm = data.items.filter(x => x.roleGroup && x.isAvailable);
            // .map(x => ({
            //     id: x.id,
            //     fullName: x.name ? x.name : "Thiếu tên",
            //     address: x.address,
            //     email: x.email,
            //     phoneNumber: x.phoneNumber,
            //     jobPosition: x.roleGroup!.name,
            //     dateOfBirth: x.dateOfBirth,
            //     status: "Đang làm việc"
            // }));
            setStaffs(staffsVm);
        });
    }

    const CreateStaffModal = () => {
        setModalTitle("Thêm nhân viên");
        setIsShowModel(true);
        setStaff(undefined);
    }

    const editStuffModal = (staff: StaffResponse) => {
        return () => {
            setModalTitle("Chỉnh sửa thông tin nhân viên");
            setStaff(staff);
            setIsShowModel(true);

        }
    }

    //Lưu lại thông tin nhân viên
    const saveStaffModal = (res: StaffResponse) => {

        if (res) {
            loadStaff();
        }

        setIsShowModel(false);
    }

    const columns: ColumnsType<StaffResponse> = [
        {
            title: 'Tên nhân viên',
            dataIndex: 'name',
            key: 'name',
            render: (name, record) => (
                <Typography.Link>
                    <span onClick={editStuffModal(record)}>{name}</span>
                </Typography.Link>

            )
        },
        {
            title: 'Vị trí công việc',
            dataIndex: 'roleGroup',
            key: 'roleGroup',
            render: (roleGroup) => (
                roleGroup ? <div>{roleGroup.name}</div> : <div></div>
            )
        },
        {
            title: 'Số điện thoại',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Địa chỉ',
            dataIndex: 'address',
            key: 'address',
        },
    ];

    return (
        <div>
            <div>
                <Button type="text" icon={<LeftOutlined />} href="/admin/settings" size="large">
                    Quay lại cấu hình
                </Button>
            </div>

            <div style={{ display: "flex", height: 50, top: 25 }}>

                <div style={{ fontWeight: 500, fontSize: 25, marginLeft: 20 }}>
                    Danh sách nhân viên
                </div>
                <div style={{ flexGrow: 1, textAlign: "end" }}>
                    <Button type="primary" icon={<PlusOutlined />} onClick={CreateStaffModal} size="middle">
                        Thêm mới nhân viên
                    </Button>
                </div>
            </div>
            <Divider style={{ marginTop: 0, marginLeft: 20 }} />

            <Table
                columns={columns}
                dataSource={staffs}
            />

            <StaffModal staffEdit={staff}
                title={modalTitle}
                centered
                visible={isShowModal}
                okModalFunc={saveStaffModal}
                onCancel={() => setIsShowModel(false)} />

        </div>
    )
}