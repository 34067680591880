import { Button, DatePicker, Space, Table, Spin } from "antd";
import moment, { Moment } from "moment";
import {
    ArrowLeftOutlined
} from '@ant-design/icons';
import { useEffect, useState } from "react";
import { ChoosFilter, FilterControls } from "../../Controls/FilterControls";
import { addDays, subDays, format, subMonths } from "date-fns";
import { ColumnsType } from "antd/lib/table";
import { headerStyle } from "../../Helper/UIHelper";
import { filterTypes } from "../../../common/type-define";
import { OrderStatus } from "../../../common/type-define";
import { SaleReportApi } from "../../../Api/Reports/SaleReportApi";
import { OrderReturnStatus } from "../../../common/type-define";
import { CommonHelper } from "../../../Helper/CommonHelper";
import RangeDateControl from "../../Controls/RangeDateControl";
import { MathHelper } from "../../../Helper/math.helper";
const { RangePicker } = DatePicker;

export default function ByProductReport() {

    const [data, setData] = useState<ICustomerByProductReport[]>([]);
    //const [startDate, setStartDate] = useState<Date>(subDays(new Date(), 7));
    //const [endDate, setEndDate] = useState<Date>(new Date());
    const [loading, setLoading] = useState<boolean>(false)
    const [filters, setFilters] = useState<FilterItem[]>([]);

    useEffect(() => {
        setLoading(true);
        fetchData(subMonths(new Date(), 1), new Date());
        setLoading(false);
        setFilters([
            { type: filterTypes.WAREHOUSE, closeable: true },
            { type: filterTypes.AGENCY, closeable: true }]);
    }, [])

    async function fetchData(fromDate: Date, toDate: Date) {
        setLoading(true);
        let re: ICustomerByProductReport[] = [];

        const query = {
            OrderStatuses: [OrderStatus.Completed, OrderStatus.Activated],
            QueryFrom: fromDate,
            QueryTo: toDate
        }
        try {
            const orderReportResponse = await SaleReportApi.GetOrderReportsApi(query)
            const returnOrderReportResponse = await SaleReportApi.GetReturnOrderReportsApi(query)
            if (orderReportResponse.status !== 200 || returnOrderReportResponse.status !== 200) return
            const orderReports = orderReportResponse.data
            const returnOrderReports = returnOrderReportResponse.data
            orderReports.forEach(o => {
                let customerId = ''
                let customerName = ''
                if (o.customer) {
                    customerId = o.customer.id
                    customerName = o.customer.fullName
                }
                if (o.toAgency) {
                    customerId = o.toAgency.id
                    customerName = o.toAgency.name
                }
                var existCustomerReport = re.find(p => p.customerId === customerId);
                if (!existCustomerReport) {
                    existCustomerReport = ({
                        order: re.length + 1,
                        customerId: customerId,
                        customerName: customerName,
                        saleProductQuantity: 0,
                        productPrice: 0,
                        discount: 0,
                        grossRevenue: 0,
                        returnProductQuantity: 0,
                        netRevenue: 0
                    })
                    re.push(existCustomerReport);
                }
                const returnOrders = returnOrderReports.filter(returnOrder => returnOrder.orderCode === o.orderCode &&
                    returnOrder.status === OrderReturnStatus.Completed.valueOf())
                const distributeDiscountEachProduct = o.discount / o.productOrders.length
                var netRevenue = 0;
                o.productOrders.forEach(product => {
                    if (!existCustomerReport) return;
                    var isReturn = false;
                    existCustomerReport.grossRevenue += product.salePrice;
                    existCustomerReport.productPrice += product.salePrice
                    netRevenue += product.salePrice
                    returnOrders.forEach(returnO => {
                        var returnProduct = returnO.productOrderReturns.find(returnP => returnP.productVersionId === product.productVersionId);
                        if (!returnProduct) return;
                        if (!existCustomerReport) return;
                        existCustomerReport.returnProductQuantity++;
                        isReturn = true;
                        existCustomerReport.grossRevenue -= returnProduct.refund;
                        existCustomerReport.productPrice -= returnProduct.refund;
                        netRevenue -= returnProduct.refund
                    })
                    if (!isReturn) {
                        const singleDiscount = CommonHelper.calculateDiscount(product.discount, product.discountType, product.salePrice)
                        netRevenue -= singleDiscount;
                        existCustomerReport.discount += singleDiscount
                        const priceAfterDiscount = product.salePrice - singleDiscount
                        var singleDistributeDiscount = CommonHelper.calculateDiscount(distributeDiscountEachProduct, o.discountType, priceAfterDiscount);
                        netRevenue -= singleDistributeDiscount;
                        existCustomerReport.discount += singleDistributeDiscount
                        existCustomerReport.saleProductQuantity++;
                        netRevenue -= product.vat;
                    }
                })
                existCustomerReport.netRevenue += netRevenue;
            })
            setData(re);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }


    }

    const columns: ColumnsType<ICustomerByProductReport> = [
        {
            title: 'STT',
            dataIndex: 'order',
            key: 'order',
            onHeaderCell: () => headerStyle(),

        },
        {
            title: 'Tên KH',
            dataIndex: 'customerName',
            key: 'customerName',
            onHeaderCell: () => headerStyle()
        },
        {
            title: (<span>Số lượng <br /> bán ra</span>),
            dataIndex: 'saleProductQuantity',
            key: 'saleProductQuantity',
            onHeaderCell: () => headerStyle(),
            align: 'center'
        },
        {
            title: 'Tiền hàng',
            dataIndex: 'productPrice',
            key: 'productPrice',
            onHeaderCell: () => headerStyle(),
            render: (productPrice) => (
                MathHelper.formatPriceVND(productPrice, true)
            )
        },
        {
            title: 'Doanh thu',
            dataIndex: 'grossRevenue',
            key: 'grossRevenue',
            onHeaderCell: () => headerStyle(),
            render: (grossRevenue) => (
                MathHelper.formatPriceVND(grossRevenue, true)
            )
        },
        {
            title: 'Chiết khấu',
            dataIndex: 'discount',
            key: 'discount',
            onHeaderCell: () => headerStyle(),
            render: (discount) => (
                MathHelper.formatPriceVND(discount, true)
            )
        },
        {
            title: (<span>Số lượng <br /> hàng trả</span>),
            dataIndex: 'returnProductQuantity',
            key: 'returnProductQuantity',
            onHeaderCell: () => headerStyle(),
            align: 'center'
        },
        {
            title: 'Doanh thu thuần',
            dataIndex: 'netRevenue',
            key: 'netRevenue',
            onHeaderCell: () => headerStyle(),
            render: (netRevenue) => (
                MathHelper.formatPriceVND(netRevenue, true)
            )
        },
    ]

    function changeRangeTime(startTime: Date, endTime: Date) {

        fetchData(startTime, endTime);
    }

    function onChangeFilter() {

    }


    return (
        <div>

            <Space direction='horizontal'>
                <Button type="default" icon={<ArrowLeftOutlined />} href="/admin/report/customer"></Button>
                <RangeDateControl onChangeDate={changeRangeTime} />
                <ChoosFilter filterList={filters} onChangeFunc={(items: FilterItem[]) => setFilters(items)} />

            </Space>
            <FilterControls items={filters} afterChangeFnc={onChangeFilter} />

            <Spin spinning={loading} tip="đang tải...">
                <Table columns={columns} dataSource={data} size="small" bordered

                    pagination={false}
                />
            </Spin>
        </div>
    )
}