import { Modal, ModalProps, Form, Table, Input, message, Checkbox } from "antd";
import { ColumnsType } from "antd/lib/table";
import { CreateSaleChannelApi } from "../../Api/SaleChannelApi";

const { TextArea } = Input;

type ChanelSaleModalProps = ModalProps &
{
    id?: string;
    closeModalFunc: Function,
    okModalFunc: Function,
}

type FormData = {
    name: string,
    description: string,
    isAvailable: boolean
}

export function ChanelSaleModal(props: ChanelSaleModalProps) {
    const { closeModalFunc, okModalFunc, ...rest } = props;

    const [form] = Form.useForm<FormData>();

    const okInvoke = () => {
        form.validateFields().then((formdata: FormData) => {

            CreateSaleChannelApi(formdata, (res: any) => {
                okModalFunc(res);
            })
        })
    }
    const onCancel = () => {
        closeModalFunc();
    }


    return (
        <Modal {...rest}
            centered
            onOk={okInvoke}
            onCancel={onCancel}>
            <Form form={form} layout="vertical">
                <Form.Item label="Tên kênh bán hàng" name="name" required>
                    <Input />
                </Form.Item>
                <Form.Item label="Miêu tả" name="description" required>
                    <TextArea rows={2} />
                </Form.Item>

                <Form.Item name="isAvailable" valuePropName="checked" >
                    <Checkbox>Đang vận hành</Checkbox>
                </Form.Item>

            </Form>


        </Modal>
    )
}