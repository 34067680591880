import {
    AlertOutlined, BellOutlined, CheckCircleOutlined, PlusOutlined,
    CheckOutlined, ClearOutlined, DollarCircleOutlined, ExclamationCircleOutlined, SearchOutlined, VerticalAlignBottomOutlined
} from '@ant-design/icons';
import { Button, Col, DatePicker, Divider, Input, List, message, Modal, Popover, Row, Space, Spin, Table, Tabs, Tag, Tooltip, Typography } from "antd";
import { Excel } from 'antd-table-saveas-excel';
import { ColumnsType } from "antd/lib/table";
import Link from 'antd/lib/typography/Link';
import { format } from 'date-fns';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { GetCities } from '../../Api/AddressApi';
import { GetCustomersApi } from '../../Api/CustomerApi';
import { CreateMaintenancePlanApi, DecideMaintenenceRequestApi, GetMaintenancesByProductIdApi } from "../../Api/MaintenanceApi";
import { GetActiveWarrantyOrderApi, UpdateActiveWarrantyTimeProductOrderApi, UpdateCustomerForWarrantyApi } from "../../Api/WarrantyApi";
import { KeyCode } from '../../common/type-define';
import { useShortKey } from '../../hooks/useShortKey';
import { CustomerRes } from '../../Model/CustomerModel';
import { IOrderRespone } from "../../Model/OrderModel";
import { maintenancesResponse } from "../../Model/WarrantiesModel";
import { WarrantyViewModel } from "../../ViewModel/WarrantyViewModel";
import CCombobox from '../Controls/CCombobox';
import { CustomerModal } from '../Customers/CustomerModal';
import { headerStyle } from '../Helper/UIHelper';
import ActiveMaintenanceTabView from "./ActiveMaintenanceTabView";
import { ActiveWarranityModal } from "./ActiveWarranityModal";
import ActiveWarrantyTabView from "./ActiveWarrantyTabView";
import CreateOldWarrantyModal from './CreateOldWarrantyModal';
import DecisionModal from "./DecisionModal";
import { MaintainModal } from "./MaintainModal";
import StoreTicketTabView from './StoreTicketTabView';


const { TabPane } = Tabs;
const { confirm } = Modal;

export default function WarrantiesView() {

    const [Warranties, setWarranties] = useState<WarrantyViewModel[]>([]);
    const [filteredWarranties, setFilteredWarranties] = useState<WarrantyViewModel[]>([]);
    const [loading, setloading] = useState(true);

    const [isMaintanModal, setIsMaintanModal] = useState<boolean>(false);
    const [isWarrantyModal, setIsWarrantyModal] = useState<boolean>(false);
    const [activeWarranty, setActiveWarranty] = useState<WarrantyViewModel>();
    const [isShowDecision, setIsShowDecision] = useState<boolean>(false);
    const [oldWarrantyModal, setoldWarrantyModal] = useState<boolean>(false);
    const [activeTime, setActivateTime] = useState<Date>(new Date());

    const [popoverVis, setPopoverVis] = useState<boolean>(false);
    const [popoverCustomerVis, setPopoverCustomerVis] = useState<boolean>(false);
    const [clickedRecord, setClickedRecord] = useState<WarrantyViewModel | null>(null);
    const [customerModalVisible, setCustomerModalVisible] = useState<boolean>(false);
    const [customers, setcustomers] = useState<CustomerRes[]>([]);
    const [chooseCustomer, setChooseCustomer] = useState<CustomerRes | null>(null);
    const [cities, setCities] = useState<CityResponse[]>([]);
    const [districts, setDistricts] = useState<DistrictResponse[]>([]);

    const [tabActive, setTabActive] = useState<number>(0);
    const searchRef = useRef<Input>(null);

    const [totalOrder, setTotalOrder] = useState<number>(0);

    useShortKey(KeyCode.F3, () => searchRef.current?.focus());

    const AddWarrantyHandle = (record: WarrantyViewModel) => {
        return () => {
            //Check hạn bảo hành
            setActiveWarranty(record);
            setIsMaintanModal(true);
        }
    }

    useEffect(() => {
        GetWarranties(true);
        //fetchData(1);
        //setFilteredWarranties(Warranties);
        setTabActive(3);
    }, []);

    function fetchData(page: number) {
        const take = 25;
        let skip = take * (page - 1);
        if (skip < 0)
            skip = 0;

        setloading(true);

        GetActiveWarrantyOrderApi((data: IListItems<IOrderRespone>) => {
            setloading(false);

            let warrantiesVm: WarrantyViewModel[] = [];
            data.items.forEach(x => {
                if (x.toAgency == null && x.customer) {

                    x.productOrders.forEach(y => {
                        if (y.warranty)
                            warrantiesVm.push({
                                productId: y.id,
                                warrantyId: y.warranty.id,
                                key: y.warranty.id,
                                kickOff: y.warranty.startTime,
                                expriedDate: y.warranty.endTime,
                                productName: y.productVersionFullName,
                                imei: y.imei,
                                customerName: x.customer.fullName,
                                customerPhone: x.customer.phoneNumber
                            })
                    })
                }
            })
            setWarranties(warrantiesVm);

            setTotalOrder(take * (data.totalPage ?? 0))
        }, skip, take)
            .catch(error => console.log(error))
            .finally(() => setloading(false));
    }


    //Lấy tất cả các phiếu bảo hành đang được 
    function GetWarranties(init: boolean = false) {
        GetActiveWarrantyOrderApi((res: IListItems<IOrderRespone>) => {
            let warrantiesVm: WarrantyViewModel[] = [];
            res.items.forEach(x => {
                if (x.toAgency == null && x.customer) {

                    x.productOrders.forEach(y => {
                        if (y.warranty)
                            warrantiesVm.push({
                                productId: y.id,
                                warrantyId: y.warranty.id,
                                key: y.warranty.id,
                                kickOff: y.warranty.startTime,
                                expriedDate: y.warranty.endTime,
                                productName: y.productVersionFullName,
                                imei: y.imei,
                                customerName: x.customer.fullName,
                                customerPhone: x.customer.phoneNumber
                            })
                    })
                }
            })
            setWarranties(warrantiesVm);
            if (init) {
                setFilteredWarranties(warrantiesVm);
            }
            setloading(false);
        })
    }

    const onChangetab = (activeKey: string) => {
        setloading(true);
        switch (activeKey) {
            case '1':
                setTabActive(1);
                break;
            case '2':
                setTabActive(2);
                break;
            case '3':
                GetWarranties();
                setTabActive(3);
                break;

            case '4':
                setTabActive(4);
                break;
            default:
                break;
        }
    }

    const ChangeActiveTimeHandle = (record: WarrantyViewModel) => {

        return () => {
            setPopoverVis(false);
            if (activeTime > new Date()) {
                message.error("Ngày kích hoạt bảo hành phải nhỏ hơn ngày hiện tại");
            }
            else {
                UpdateActiveWarrantyTimeProductOrderApi(record.productId, activeTime, () => {
                    message.info("Cập nhật thành công ngày kích hoạt bảo hành");
                    GetWarranties(true);
                })
            }

        }
    }

    function handleVisibleChange(vis: boolean) {
        setPopoverVis(vis);
    }

    function PopoverVis(record: WarrantyViewModel): boolean {

        return (record.imei === clickedRecord?.imei && popoverVis);

    }

    function PopoverCustomerVis(record: WarrantyViewModel): boolean {

        return (record.imei === clickedRecord?.imei && popoverCustomerVis);

    }

    const UpdateCustomerForWarranty = (record: WarrantyViewModel) => {

        return () => {
            setPopoverCustomerVis(false);
            if (chooseCustomer) {
                UpdateCustomerForWarrantyApi(record.productId, chooseCustomer.id, () => {
                    message.info("Cập nhật thành công khách hàng mới cho sản phẩm");
                    GetWarranties(true);
                })

                setChooseCustomer(null);
            }

        }
    }

    function onChangeCustomer(value: any) {
        const customer = customers.find(x => x.id === value);
        if (customer) {
            setChooseCustomer(customer);
        }

    }

    function handleVisibleCusChange(vis: boolean) {

        if (vis) {
            GetCustomersApi((data: IListItems<CustomerRes>) => { setcustomers(data.items); })
        }
        setPopoverCustomerVis(vis);
    }

    const columns: ColumnsType<WarrantyViewModel> = [
        {
            title: 'IMEI',
            dataIndex: 'imei',
            key: 'imei',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Tên sản phẩm',
            dataIndex: 'productName',
            key: 'productName',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Tên khách hàng',
            dataIndex: 'customerName',
            key: 'customerName',
            onHeaderCell: () => headerStyle(),
            render: (customerName, record) => (
                <Popover placement="bottom"
                    title="Thay đổi khách hàng sở hữu"
                    trigger="click"
                    visible={PopoverCustomerVis(record)}
                    onVisibleChange={handleVisibleCusChange}
                    content={
                        <div>
                            <CCombobox
                                focusHotkey={KeyCode.F4}
                                placeholder="Chọn theo khách (F4)"
                                chooseOptions={customers}
                                showSearch
                                optionFilterProp="children"
                                displayName="fullName"
                                id="id"
                                onChange={onChangeCustomer}
                                extraContent={
                                    <div>
                                        <Divider style={{ margin: '4px 0' }} />
                                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                            <Typography.Link onClick={() => {
                                                setCustomerModalVisible(true)
                                                setPopoverCustomerVis(false)
                                                GetCities((data: IListItems<CityResponse>) => { setCities(data.items); })
                                            }
                                            }
                                                style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}>
                                                <PlusOutlined />
                                                <span>Thêm khách hàng mới</span>
                                            </Typography.Link>
                                        </div>
                                    </div>
                                } />
                            <Button type="primary" onClick={UpdateCustomerForWarranty(record)}>Cập nhật</Button>
                        </div>
                    }>

                    <Link onClick={() => {
                        setClickedRecord(record)
                        setPopoverCustomerVis(true)
                    }}>{customerName}</Link>
                </Popover >


            )
        },
        {
            title: 'SĐT/Zalo',
            dataIndex: 'customerPhone',
            key: 'customerPhone',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Ngày lắp đặt',
            dataIndex: 'kickOff',
            key: 'kickOff',
            onHeaderCell: () => headerStyle(),
            render: (kickoff, record) => (
                <Popover placement="bottom"
                    title="Ngày kích hoạt bảo hành"
                    trigger="click"
                    visible={PopoverVis(record)}
                    onVisibleChange={handleVisibleChange}
                    content={
                        <div>
                            <DatePicker format='DD/MM/YYYY' placeholder="Chọn ngày"
                                onChange={(val) => {
                                    setActivateTime((val?.toDate()) ?? (new Date))
                                }}
                                showToday={true} defaultValue={moment(new Date(kickoff))} />
                            <Button type="primary" onClick={ChangeActiveTimeHandle(record)}>Cập nhật</Button>
                        </div>
                    }>

                    <Link onClick={() => {
                        setClickedRecord(record)
                        setPopoverVis(true)
                    }}>{format(new Date(kickoff), "dd-MM-yyyy")}</Link>
                </Popover >


            )
        },
        {
            title: 'Hạn bảo hành',
            dataIndex: 'expriedDate',
            key: 'expriedDate',
            onHeaderCell: () => headerStyle(),
            render: (expriedDate) => (
                format(new Date(expriedDate), "dd-MM-yyyy")
            )
        },
        {
            title: '',
            dataIndex: 'imei',
            key: 'x',
            onHeaderCell: () => headerStyle(),
            render: (imei, record) => (
                <Tooltip title="Tạo phiếu yêu cầu">
                    <AlertOutlined onClick={AddWarrantyHandle(record)} />
                </Tooltip>
            )
        },
    ];

    const SearchWarrantiesSubmit = () => {
        // VA not sure
        const search = searchRef.current?.input.value.toLowerCase();
        if (search == null || search === "")
            setFilteredWarranties(Warranties);
        else {
            const result = Warranties.filter(x => x.customerName.toLowerCase().includes(search) ||
                x.customerPhone.toLowerCase().includes(search) || x.imei.toLowerCase().includes(search))

            setFilteredWarranties(result);
        }


    };

    //Xử lý cho tình huống xóa hết text
    const searchTextChange = () => {
        try {
            const search = searchRef.current?.input.value.toLowerCase();
            // VA not sure
            if (search == null || search === "")
                setFilteredWarranties(Warranties);
        } catch (error) {

        }

    }


    const excelColumns = [
        {
            title: 'IMEI',
            dataIndex: 'imei',
            key: 'imei',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Tên sản phẩm',
            dataIndex: 'productName',
            key: 'productName',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Tên khách hàng',
            dataIndex: 'customerName',
            key: 'customerName',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'SĐT/Zalo',
            dataIndex: 'customerPhone',
            key: 'customerPhone',
            onHeaderCell: () => headerStyle(),
        },
        {
            title: 'Ngày lắp đặt',
            dataIndex: 'kickOff',
            key: 'kickOff',
            onHeaderCell: () => headerStyle(),
            render: (kickOff: any) => (
                format(new Date(kickOff), "dd-MM-yyyy")
            )

        },
        {
            title: 'Hạn bảo hành',
            dataIndex: 'expriedDate',
            key: 'expriedDate',
            onHeaderCell: () => headerStyle(),
            render: (expriedDate: any) => (
                format(new Date(expriedDate), "dd-MM-yyyy")
            )
        }
    ];

    const exportExcel = () => {
        const excel = new Excel();
        excel
            .addSheet("Bảo hành")
            .addColumns(excelColumns)
            .addDataSource(filteredWarranties, {
                str2Percent: true
            })
            .saveAs("BaoHanh.xlsx");
    };

    function onChangePage(page: number, pageSize: number) {
        fetchData(page);
    }

    function AfterCreateCustomerHandle(newCus: CustomerRes) {
        let newCusrtomer: CustomerRes[] = [...customers];
        newCusrtomer.push(newCus);

        setcustomers(newCusrtomer);
    }

    return (
        <>
            <div>
                <Tabs defaultActiveKey="3" onChange={onChangetab}>

                    <TabPane tab="Sản phẩm bảo hành" key="3">

                        <Row wrap={false}>
                            <Col flex="none">
                                <Button type="link" onClick={exportExcel} icon={<VerticalAlignBottomOutlined />} size="middle">
                                    Xuất file
                                </Button>
                            </Col>
                            <Col flex="auto" style={{ padding: '0 16px' }}>
                                <Input
                                    prefix={<SearchOutlined />}
                                    allowClear={true}
                                    ref={searchRef}
                                    placeholder="Tìm kiếm bảo hành (F3)"
                                    onChange={searchTextChange}
                                    onPressEnter={SearchWarrantiesSubmit} />
                            </Col>

                            <Col flex="none" >
                                <Space direction="horizontal">
                                    <Button type="primary" icon={<CheckCircleOutlined />} onClick={() => setIsWarrantyModal(true)} size="middle">
                                        Kích hoạt bảo hành
                                    </Button>

                                    <Button type="primary" icon={<CheckCircleOutlined />} onClick={() => setoldWarrantyModal(true)} size="middle">
                                        Đơn cũ
                                    </Button>
                                </Space>

                            </Col>
                        </Row>


                        <Spin spinning={loading} tip="đang tải...">
                            <Table
                                columns={columns}

                                pagination={{ pageSize: 25, showSizeChanger: false, total: totalOrder, onChange: onChangePage }}
                                expandable={{
                                    expandedRowRender: record => <p style={{ marginLeft: 50 }}>
                                        {<ExpanderView content={record} />}</p>,
                                    rowExpandable: record => true,
                                }}

                                dataSource={filteredWarranties}
                            />
                        </Spin>
                    </TabPane>

                    <TabPane tab="Phiếu bảo dưỡng" key="1">
                        <ActiveMaintenanceTabView isActived={tabActive === 1} />
                    </TabPane>

                    <TabPane tab="Phiếu bảo hành" key="2">
                        <ActiveWarrantyTabView isActived={tabActive === 2} />

                    </TabPane>

                    <TabPane tab="Phiếu xuất kho" key="4">
                        <StoreTicketTabView isActived={tabActive === 4} />

                    </TabPane>

                </Tabs>

            </div>

            <ActiveWarranityModal visible={isWarrantyModal} title="Kích hoạt bảo hành"
                onCancel={() => setIsWarrantyModal(false)} width={900}
                okModalFnc={() => { setIsWarrantyModal(false); GetWarranties(true); }}
            />

            <MaintainModal warrantyOrder={activeWarranty} visible={isMaintanModal} title="Tạo phiếu bảo trì bảo hành cho khách"
                onCancel={() => setIsMaintanModal(false)}
                okModalFnc={() => setIsMaintanModal(false)}
            />

            <DecisionModal title="Bảo trì" visible={isShowDecision}
                okModalFnc={() => setIsShowDecision(false)}
                onCancel={() => setIsShowDecision(false)}
            />

            <CreateOldWarrantyModal visible={oldWarrantyModal}
                AfterCreateFnc={() => { GetWarranties(); }}
                title="Thêm bảo hành cũ" onCancel={() => setoldWarrantyModal(false)} />

            <CustomerModal okModalFunc={AfterCreateCustomerHandle}
                onCancel={() => setCustomerModalVisible(false)}
                cities={cities}
                visible={customerModalVisible} />
        </>
    )
}


function ExpanderView(props: { content: WarrantyViewModel }) {
    const { content } = props;

    const [maintaints, setMaintaints] = useState<maintenancesResponse[]>([]);


    useEffect(() => {
        if (content) {
            GetMaintenancesByProductIdApi(content.productId, (res: any) => {
                setMaintaints(res.items);
            })
        }
    }, [content]);



    const activeMaintenances = (data: maintenancesResponse) => {
        return () => {
            confirm({
                title: 'Xác nhận bảo trì?',
                icon: <ExclamationCircleOutlined />,
                content: 'Bạn có muốn kích hoạt gói bảo trì?',
                onOk() {

                    if (data.maintenanceStage == null) {
                        CreateMaintenancePlanApi(data.id, (res: any) => {

                            const dataDecision = {
                                maintenanceDetails: [{
                                    maintenanceId: data.id
                                }],
                                reason: "Đã sẳn sàng bảo trì",
                                maintenanceStage: 0
                            }


                            DecideMaintenenceRequestApi(dataDecision, (res: maintenancesResponse) => {
                                message.info("Phiếu bảo trì đã được kích hoạt");
                            })

                        });
                    }

                }
            });

        }
    }

    //0-> Request, 1-> Approved, 2 -> Declined
    const GetStatusItem = (item: maintenancesResponse): JSX.Element => {
        if (item) {
            //Preparing = 3, Maintaining = 4,Completed = 5
            if (item.maintenanceAction == null) {
                if (item.maintenanceStage == null) {
                    return (<Tooltip title="Lên kế hoạch bảo trì">
                        <BellOutlined onClick={activeMaintenances(item)} />
                    </Tooltip>)

                } else if (item.maintenanceStage === 0) {
                    return (<Tag icon={<CheckOutlined />} color="#55acee">
                        Đã yêu cầu
                    </Tag>)
                }
                else if (item.maintenanceStage === 1) {
                    return (<Tag icon={<CheckOutlined />} color="#55acee">
                        Được chấp nhận
                    </Tag>)
                }
                else if (item.maintenanceStage === 2) {
                    return (<Tag color="red">
                        Bị từ chối
                    </Tag>)
                }
            } else if (item.maintenanceAction === 3) { //Preparing
                return (<Tag color="orange">
                    Đang chuẩn bị
                </Tag>)
            }
            else if (item.maintenanceAction === 4) { //Maintaning
                return (<Tag color="orange">
                    Đang bảo trì
                </Tag>)
            }
            else if (item.maintenanceAction === 5) { //complete
                return (<Tag icon={<CheckOutlined />} color="green">
                    Hoàn thành
                </Tag>)
            }

        }
        return (<div></div>);
    }

    const GetMaintenantItem = (item: maintenancesResponse): JSX.Element => {
        if (item) {
            //Yêu cầu của khách hàng
            if (item.isHighPriority) {
                return (<Space direction="horizontal">
                    <DollarCircleOutlined style={{ color: "green" }} />
                    Khách hàng yêu cầu
                    {GetStatusItem(item)}
                </Space>)
            } else {
                return (<Space direction="horizontal">
                    <ClearOutlined style={{ color: "orange" }} />
                    Bảo trì miễn phí

                    {GetStatusItem(item)}
                </Space>)
            }
        }

        return (<div></div>);
    }

    return (

        <List dataSource={maintaints}
            renderItem={item => (
                <List.Item>
                    {GetMaintenantItem(item)}
                </List.Item>
            )}
        >

        </List>

    )
}