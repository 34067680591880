import { AxiosResponse } from "axios";
import { InventoryStatusEnum, InventoryStatusEnumCommand } from "../common/type-define";
import { BalanceInventoryCommand, DecisionInventoryCommand, InventoryCheck, InventoryCreateItem, InventoryResponse } from "../Model/InventoryModel";
import { AxiosRequest } from "./Axios/AxiosRequest";
import { RequestHandler } from "./RequestAPI";
import { SERVER_API } from "./UrlDefination";


export class InventoryApi {
    static async getById(inventoryId: string): Promise<AxiosResponse<InventoryResponse, any>> {
        return RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.INVENTORY.GET_BY_ID(inventoryId)}`));
    }

    static async getInventories(inventoryStatuses?: InventoryStatusEnum[]): Promise<AxiosResponse<IListItems<InventoryResponse>, any>> {
        let queryParams = '';
        if (inventoryStatuses !== undefined) {
            queryParams += '?';
            queryParams = inventoryStatuses.map(status => `Statuses=${status}`).join('&');
        }
        return RequestHandler.handleRequest(AxiosRequest.get(`${SERVER_API.HOST}/${SERVER_API.INVENTORY.GET}${queryParams}`));
    }

    static async createInventory(newInventory: InventoryCreateItem): Promise<AxiosResponse<InventoryResponse, any>> {
        return RequestHandler.handleRequest(AxiosRequest.post(`${SERVER_API.HOST}/${SERVER_API.INVENTORY.CREATE}`, newInventory));
    }

    static async checkInventory(inventoryId: string, checkItem: InventoryCheck): Promise<AxiosResponse<InventoryResponse, any>> {
        return RequestHandler.handleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.INVENTORY.CHECK(inventoryId)}`, checkItem));
    }

    static async decisionInventory(inventoryId: string, decision: InventoryStatusEnumCommand): Promise<AxiosResponse<InventoryResponse, any>> {
        const postData: DecisionInventoryCommand = {
            status: decision
        }
        return RequestHandler.handleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.INVENTORY.DECISION(inventoryId)}`, postData));
    }

    static async balanceInventory(inventoryId: string): Promise<AxiosResponse<InventoryResponse, any>> {
        const postData: BalanceInventoryCommand = { inventoryId };
        return RequestHandler.handleRequest(AxiosRequest.put(`${SERVER_API.HOST}/${SERVER_API.INVENTORY.BALANCE(inventoryId)}`, postData));
    }

}